import React from "react"
import { Images } from "../../Shared/images"
const NcashPage = () => {
  return (
    <div className="ncash-page">
      <div className="home-page">
        {/* Header */}
        <main className="header">
          <div className="header__content">
            <div className="header__content--image">
              <picture>
                <source
                  media="(max-width: 560px)"
                  srcSet={Images.NCashCoinSmaller}
                />
                <img src={Images.NCashCoin} alt="Ncash Coin" />
              </picture>
              {/* <img src={Images.NCashCoin} alt="Ncash Coin" /> */}
            </div>
            <div className="header__content--text  p-small">
              <h1>The NCash Token</h1>
              <p>
                NCash is an AVAX native cryptocurrency and the protocol token of
                the Nitro Network. It rewards Nitrogens for NFM staking, Nitro’s
                native staking, Farming on DEXs, Genesis Staking, Private
                network building and IoT adoption. In the Nitro ecosystem, NCash
                enables users to monitor, measure, and manage their everyday
                living through IoT to enhance the quality of their lives.
              </p>
            </div>
          </div>
        </main>
        {/* Header End */}

        <section id="quick-takes" className="quick-take">
          <div className="container-home">
            <h2 className="heading-secondary mb-small">NCash Quick take</h2>
            <div className="grid-4 quick-take__boxes">
              <div className="quick-take__boxes-box">
                <img
                  src={Images.quick1}
                  alt="Ncash Tour"
                  className="quick-take__boxes-box-image"
                />
                <p className="text-">Avalanche-based token</p>
              </div>
              <div className="quick-take__boxes-box">
                <img
                  src={Images.quick2}
                  alt="Ncash Tour"
                  className="quick-take__boxes-box-image"
                />
                <p className="text-">Follows burn and mint equilibrium</p>
              </div>
              <div className="quick-take__boxes-box">
                <img
                  src={Images.quick3}
                  alt="Ncash Tour"
                  className="quick-take__boxes-box-image"
                />
                <p className="text-">
                  Minted to incentivize community for choosing better, smart and
                  futuristic lifestyle
                </p>
              </div>
              <div className="quick-take__boxes-box">
                <img
                  src={Images.quick4}
                  alt="Ncash Tour"
                  className="quick-take__boxes-box-image"
                />
                <p className="text-">
                  Burned to create more value for the community
                </p>
              </div>
            </div>
          </div>
        </section>

        <section id="ncashAllocation" className="ncashAllocation">
          <div className="container-home">
            <h2 className="heading-secondary mb-small">NCASH Allocation</h2>

            <div className="ncash-allocation">
              <div className="ncash-allocation__graph ">
                <img src={Images.Chart} alt="Chart" />
              </div>
              <div className="ncash-allocation__text">
                <p>
                  NCash is minted monthly, and is utilized for three major
                  purposes; to build network infrastructure, to use the network
                  and drive IoT utility, and to incentivise the team and
                  investors.
                </p>
                <ul>
                  <li>
                    The allocated percentage of tokens to the three segments
                    changes over time
                  </li>
                  <li>
                    To increase IoT utility and network usage, higher
                    percentages have been allocated to the data network at a
                    later stage.
                  </li>
                  <li>NCash is minted and burned monthly</li>
                </ul>

                <a
                  href="https://docs.nitro.network/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn-brand btn-active btn-sm mx-w">
                  Read More
                </a>
              </div>
            </div>
          </div>
        </section>

        <section id="ncashDistribution" className="ncashDistribution">
          <div className="container-home">
            <h2 className="heading-secondary mb-small">NCASH Distribution</h2>

            <div className="ncashDistribution__table">
              <div className="ncash__distribution">
                <div className="ncash__distribution--allocation bg-purple-grad">
                  <h3>Allocation</h3>
                </div>
                <div className="ncash__distribution--description bg-white-grad">
                  <h3 className="">Distribution</h3>
                </div>
              </div>
              <div className="ncash__distribution">
                <div className="ncash__distribution--allocation">
                  <h3>41% Infrastructure Reward</h3>
                </div>
                <div className="ncash__distribution--description">
                  <ul>
                    <li>
                      <span className="bold-text">53%</span> - Network
                      Infrastructure Mining Reward​: For network assets
                      financing backbone (NFM holders and Nesters)
                    </li>
                    <li>
                      <span className="bold-text">14.6%</span> - Network
                      Coverage Validation reward​: For nesters (network creator
                      & watcher) and early adopters of Nitro’s network and IoT
                      use cases
                    </li>
                    <li>
                      <span className="bold-text">32.40%</span> - Staking
                      reward​: For staking and pool farming on Nitro & its
                      partners ecosystem
                    </li>
                  </ul>
                </div>
              </div>
              <div className="ncash__distribution">
                <div className="ncash__distribution--allocation">
                  <h3>24% Data Network Reward</h3>
                </div>
                <div className="ncash__distribution--description">
                  <ul>
                    <li>
                      <span className="bold-text">Upto 50% </span> - Network
                      Data Transfer: For network user, nester and early IoT
                      adopter ​
                    </li>
                    <li>
                      <span className="bold-text">Upto 50% </span> - Data,
                      Security and Network Governance​: For governance, network
                      monitoring and business development.
                    </li>
                  </ul>
                </div>
              </div>

              <div className="ncash__distribution">
                <div className="ncash__distribution--allocation">
                  <h3>35% Team and Investors</h3>
                </div>
                <div className="ncash__distribution--description">
                  <ul>
                    <li>
                      <span className="bold-text">4% </span> - Genesis Node
                      rewards​: For loyal and early supporter of the Nitro
                      Network
                    </li>
                    <li>
                      <span className="bold-text">96% </span> - Ecosystem
                      rewards: For companies and people that helped make this
                      project a reality
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="burnMintCycle" className="burnMintCycle">
          <div className="container-home">
            <h2 className="heading-secondary mb-small">Burn and Mint Cycle</h2>

            <div className="ncash-utility">
              <div className="ncash-utility__image ">
                <img src={Images.MintCycle} alt="Mint Cycle" />
              </div>
            </div>
          </div>
        </section>

        <section id="ncashUtility" className="ncashUtility">
          <div className="container-home">
            <h2 className="heading-secondary mb-small">NCash Utility</h2>

            <div className="ncash-utility transparent-box p-small">
              <div className="ncash-utility__image ">
                <picture>
                  <source
                    srcSet={Images.NcashUtilityLarge}
                    media="(min-width: 800px)"
                  />
                  <img src={Images.NcashUtility} alt="" />
                </picture>
                {/* <img src={Images.NcashUtility} alt="Chart" /> */}
              </div>
            </div>
          </div>
        </section>

        <section id="whereToBuy" className="whereToBuy">
          <div className="container-home">
            <h2 className="heading-secondary mb-small">
              Where to buy/sell Ncash?
            </h2>

            <p className="desc-text">
              Ncash is available on the leading Centralized Exchange, Huobi and
              Decentralized Exchanges such as Pangolin. Below are some of the
              platforms where you can purchase NCash.
            </p>

            <div className="whereToBuy__options">
              <a
                href="https://www.huobi.com/en-us/exchange/ncash_btc/"
                target="_blank"
                rel="noopener noreferrer"
                className="whereToBuy__option">
                <img src={Images.huobi} alt="Huobi" />
              </a>
              <a
                href="https://app.pangolin.exchange/#/swap"
                target="_blank"
                rel="noopener noreferrer"
                className="whereToBuy__option">
                <img src={Images.pangolinNcash} alt="Pangolin" />
              </a>
            </div>
          </div>
        </section>

        <section id="ourMedium" className="ourMedium">
          <div className="container-home">
            <h2 className="heading-secondary mb-small">Our Medium Articles</h2>
            <div className="grid-3 ourMedium__articles">
              <a
                href="https://nitronetwork.medium.com/september-ncash-burn-and-mint-all-you-need-to-know-eddda4ed9bbc"
                target="_blank"
                rel="noopener noreferrer"
                className="ourMedium__article">
                <img
                  src={Images.medium1}
                  alt="Ncash Tour"
                  loading="lazy"
                  className="ourMedium__article-image"
                />
              </a>
              <a
                href="https://nitronetwork.medium.com/nitro-network-tokenomics-update-bba7ea50367d"
                target="_blank"
                rel="noopener noreferrer"
                className="ourMedium__article">
                <img
                  src={Images.medium2}
                  alt="Ncash Tour"
                  loading="lazy"
                  className="ourMedium__article-image"
                />
              </a>
              <a
                href="https://nitronetwork.medium.com/introducing-niot-nitro-internet-of-things-73125041d314"
                target="_blank"
                rel="noopener noreferrer"
                className="ourMedium__article">
                <img
                  src={Images.medium3}
                  alt="Ncash Tour"
                  loading="lazy"
                  className="ourMedium__article-image"
                />
              </a>
            </div>

            <div className="mx-50rem terms-text">
              <p>*Terms and conditions</p>
              <ul>
                <li>
                  Current model is in its nascent stage and is subject to change
                </li>
                <li>
                  Minting of NCash tokens is subject to market conditions and
                  factors which may have adverse impact for the community and
                  company. Under certain conditions, minting and distribution of
                  NCash will be controlled by Nitro Network, keeping every
                  stakeholder’s benefits into consideration.
                </li>
              </ul>
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}

export default NcashPage
