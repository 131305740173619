import moment from "moment"
import axios from "axios"
import * as turf from "@turf/turf"
import { BaseUrl } from "./utils/config"
import { defaultPageMetaData, pageMetaDataArr } from "./Shared"

import blogArticles from "./assets/htmlPages/blog-articles.json"
import blogLibraries from "./assets/htmlPages/blog-library.json"
import blogNews from "./assets/htmlPages/blog-news.json"
import usecasesData from "./assets/htmlPages/usecasesData"

export function debounce(func, wait, immediate) {
  var timeout
  return function () {
    var context = this,
      args = arguments
    clearTimeout(timeout)
    timeout = setTimeout(function () {
      timeout = null
      if (!immediate) func.apply(context, args)
    }, wait)
    if (immediate && !timeout) func.apply(context, args)
  }
}

export function isMobile() {
  if (window) {
    return window.matchMedia(`(max-width: 767px)`).matches
  }
  return false
}

export function isMdScreen() {
  if (window) {
    return window.matchMedia(`(max-width: 1199px)`).matches
  }
  return false
}

function currentYPosition() {
  if (!window) {
    return
  }
  // Firefox, Chrome, Opera, Safari
  if (window.pageYOffset) return window.pageYOffset
  // Internet Explorer 6 - standards mode
  if (document.documentElement && document.documentElement.scrollTop)
    return document.documentElement.scrollTop
  // Internet Explorer 6, 7 and 8
  if (document.body.scrollTop) return document.body.scrollTop
  return 0
}

function elmYPosition(elm) {
  var y = elm.offsetTop
  var node = elm
  while (node.offsetParent && node.offsetParent !== document.body) {
    node = node.offsetParent
    y += node.offsetTop
  }
  return y
}

export function scrollTo(scrollableElement, elmID) {
  var elm = document.getElementById(elmID)
  if (!elmID || !elm) {
    return
  }
  var startY = currentYPosition()
  var stopY = elmYPosition(elm)
  var distance = stopY > startY ? stopY - startY : startY - stopY
  if (distance < 100) {
    scrollTo(0, stopY)
    return
  }
  var speed = Math.round(distance / 50)
  if (speed >= 20) speed = 20
  var step = Math.round(distance / 25)
  var leapY = stopY > startY ? startY + step : startY - step
  var timer = 0
  if (stopY > startY) {
    for (var i = startY; i < stopY; i += step) {
      setTimeout(
        (function (leapY) {
          return () => {
            scrollableElement.scrollTo(0, leapY)
          }
        })(leapY),
        timer * speed
      )
      leapY += step
      if (leapY > stopY) leapY = stopY
      timer++
    }
    return
  }
  for (let i = startY; i > stopY; i -= step) {
    setTimeout(
      (function (leapY) {
        return () => {
          scrollableElement.scrollTo(0, leapY)
        }
      })(leapY),
      timer * speed
    )
    leapY -= step
    if (leapY < stopY) leapY = stopY
    timer++
  }
  return false
}

export function getTimeDifference(date) {
  let difference =
    moment(new Date(), "DD/MM/YYYY HH:mm:ss").diff(
      moment(date, "DD/MM/YYYY HH:mm:ss")
    ) / 1000

  if (difference < 60) return `${Math.floor(difference)} seconds`
  else if (difference < 3600) return `${Math.floor(difference / 60)} minutes`
  else if (difference < 86400) return `${Math.floor(difference / 3660)} hours`
  else if (difference < 86400 * 30)
    return `${Math.floor(difference / 86400)} days`
  else if (difference < 86400 * 30 * 12)
    return `${Math.floor(difference / 86400 / 30)} months`
  else return `${(difference / 86400 / 30 / 12).toFixed(1)} years`
}

export function generateRandomId() {
  let tempId = Math.random().toString()
  let uid = tempId.substr(2, tempId.length - 1)
  return uid
}

export function getQueryParam(prop) {
  var params = {}
  var search = decodeURIComponent(
    window.location.href.slice(window.location.href.indexOf("?") + 1)
  )
  var definitions = search.split("&")
  definitions.forEach(function (val, key) {
    var parts = val.split("=", 2)
    params[parts[0]] = parts[1]
  })
  return prop && prop in params ? params[prop] : params
}

export function classList(classes) {
  return Object.entries(classes)
    .filter(entry => entry[1])
    .map(entry => entry[0])
    .join(" ")
}

export const capitalize = string => {
  if (string && string.length)
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
  else return ""
}

export const MockApi = (requestData, responseType) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      let responseData = null
      switch (responseType) {
        case "success":
          responseData = {
            data: {
              data: {
                isPromoCodeValid: true,
                discount: 300,
                message: "Discount applied on Premium and Top Location"
              }
            },
            status: 200
          }
          resolve(responseData)
          break
        case "failure":
          responseData = {
            data: {
              data: {
                isPromoCodeValid: false,
                discount: 0,
                message: "Something went wrong"
              }
            },
            status: 500
          }
          reject(responseData)
          break
      }
    }, 500)
  })
}

export function isFloat(n) {
  return Number(n) === n && n % 1 !== 0
}

export function ArrayForRange(start, end) {
  return Array(end - start + 1)
    .fill()
    .map((val, index) => start + index)
}

// ----------------------------------------
// Calculate new Lat/Lng from original points
// on a distance and bearing (angle)
// ----------------------------------------
export const llFromDistance = function (
  latitude,
  longitude,
  distance,
  bearing
) {
  const R = 6378.1 // Radius of the Earth
  const brng = (bearing * Math.PI) / 180 // Convert bearing to radian
  let lat = (latitude * Math.PI) / 180 // Current coords to radians
  let lon = (longitude * Math.PI) / 180

  // Do the math magic
  lat = Math.asin(
    Math.sin(lat) * Math.cos(distance / R) +
      Math.cos(lat) * Math.sin(distance / R) * Math.cos(brng)
  )
  lon += Math.atan2(
    Math.sin(brng) * Math.sin(distance / R) * Math.cos(lat),
    Math.cos(distance / R) - Math.sin(lat) * Math.sin(lat)
  )

  // Coords back to degrees and return
  return [(lat * 180) / Math.PI, (lon * 180) / Math.PI]
}

// pointOnMap(lat, long, distance, points, bearing)
// New Delhi (lat, long) => (28.613895, 77.209006)
// Distance: 300 (in KMs)
// Number of points: 5
// Bearing (in degrees): 90

export const pointsOnMap = function (
  latitude,
  longitude,
  distance,
  numPoints,
  bearing
) {
  const points = []
  for (let i = 1; i < numPoints + 1; i++) {
    // const bearing = Math.round((360 / numPoints) * i);
    // console.log(bearing, i);
    const newPoints = llFromDistance(latitude, longitude, distance * i, bearing)
    points.push(newPoints)
  }
  return points
}

export const generateGeoJSON = points => {
  let geoJSON = {
    type: "FeatureCollection",
    features: []
  }
  points.forEach(p => {
    geoJSON.features.push({
      type: "Feature",
      properties: {
        id: Math.round(Math.random() * (100 - 50) + 50)
      },
      geometry: {
        type: "Point",
        coordinates: [p[1], p[0]]
      }
    })
  })
  return geoJSON
}

export const hexPointsForCenter = ([lat, lng], distance) => {
  let hexPoints = []
  for (let i = 0; i < 6; i++) {
    let bearing = i * 60
    hexPoints.push(llFromDistance(lat, lng, distance, bearing))
  }
  return hexPoints
}

let typeArr = ["available", "occupied", "blocked"]

export const centerToHexGeoJSON = (geoJSON, radius) => {
  let features = geoJSON?.features ?? []
  let hexFeatures = features.map(feature => {
    let hexFeature = JSON.parse(JSON.stringify(feature))
    hexFeature.geometry.type = "Polygon"
    hexFeature.properties = {
      type: typeArr[Math.floor(Math.random() * 3)]
    }
    hexFeature.geometry.coordinates = [
      hexPointsForCenter(feature.geometry.coordinates, radius)
    ]
    return hexFeature
  })

  return {
    type: "FeatureCollection",
    features: hexFeatures
  }
}

export const centerToHexGeoJSONTurf = (geoJSON, radius) => {
  let features = geoJSON?.features ?? []
  let hexFeatures = features.map(feature => {
    // let hexFeature = JSON.parse(JSON.stringify(feature));
    // hexFeature.geometry.type = "Polygon";
    let hexFeature = turf.circle(feature.geometry.coordinates, radius, {
      steps: 6
    })
    hexFeature.properties = {
      type: typeArr[Math.floor(Math.random() * 3)]
    }
    return hexFeature
  })

  return {
    type: "FeatureCollection",
    features: hexFeatures
  }
}

// console.log(turf.circle([78.251225, 17.46615], 5, { steps: 6 }));

export const convertPolyPointsToGeoData = polyPoints => {
  if (!polyPoints.geometries?.length ?? 0) return null
  let coordinatesArr = polyPoints.geometries[0].coordinates
  let polygonFeatures = coordinatesArr.map(arr => turf.polygon(arr))
  return turf.featureCollection(polygonFeatures)
}

export const convertCurrency = async (from = "usd", to = "usd", amount = 1) => {
  const response = await axios.get(
    `https://cdn.jsdelivr.net/gh/fawazahmed0/currency-api@1/latest/currencies/${from}/${to}.json`
  )
  return response.data[to] * amount
}

export const graphByDay = data => {
  console.log("datedata coming", data)
  let labels = Array.from({ length: 23 }, (_, index) => index + 1)
  let values = data.map(obj => {
    return obj.value
  })

  return { labels, values }
}

export const graphByWeek = data => {}

export const graphByMonth = data => {}

export const graphByYear = data => {}

export const checkSessionStatus = async token => {
  if (!token) return false
  try {
    const res = await axios.get(`${BaseUrl}/getUserInfo`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    if (res.status === 200) return true
  } catch (error) {
    if (error?.response?.status === 401) {
      return false
    }
  }
}

export const getPageMetaData = () => {
  const currentPageRoute = window.location.pathname
  return (
    pageMetaDataArr.find(iMetaData => iMetaData?.route === currentPageRoute) ||
    defaultPageMetaData
  )
}

//get all usecases and blogs routes
const useCaseRoutes = usecasesData.map(data => `/usecase/${data.slug}`)
const blogLRoutes = blogLibraries.map(data => `/blog/${data.slug}`)
const blogARoutes = blogArticles.map(data => `/blog/${data.slug}`)
const blogNRoutes = blogNews.map(data => `/blog/${data.slug}`)
export const allUsecasesRoutes = [
  "/usecases",
  ...useCaseRoutes,
  "/blogs",
  ...blogARoutes,
  ...blogLRoutes,
  ...blogNRoutes
]
// console.log(allUsecasesRoutes)
