import React, { useState, useEffect } from "react"
import Footer from "../components/footer"
import { createGlobalStyle } from "styled-components"
import Menu from "./menu"
import { BaseUrl } from "../../utils/config"
import { tabs } from "../../Shared"
import { useNavigate, useParams, useLocation } from "@reach/router"
import axios from "axios"
import PendingPayments from "./pendingPayments"
import MyCollectionTab from "./MyCollectionTab"
import Rewards from "./rewards"
import Mynest from "./myNest"
import Activity from "./nitroActivity"
import DeviceManagement from "./DeviceManagement"
import { updateUserDetailsRedux } from "../../redux/actions"
// Styles and Images
import "../../assets/css/pendingPayments.css"
import mineGlobally from "../../assets/images/mineglobalwhite.png"
import weHostYouMine from "../../assets/images/minerwhites.png"
import effortlessEarning from "../../assets/images/effortlesswhite.png"
import collectible from "../../assets/images/collectiblewts.png"
import { setDefaultBreakpoints } from "react-socks"
import { connect, useDispatch, useSelector } from "react-redux"
import swal from "sweetalert"
import localStorage from "redux-persist/es/storage"
import Sidebar from "../menu/navbar"
import EMIpayments from "./EMIpayments"
import Analytics from "./iot/AnalyticsFile"
import Cart from "./iot/Cart"
import MyOrders from "./iot/MyOrders"
import { useLogout } from "../../hooks"
import Refer from "./refer"

const GlobalStyles = createGlobalStyle`
  header#myHeader.navbar.white {
    background: #212428;
  }
`

setDefaultBreakpoints([{ xs: 0 }, { l: 1099 }, { xl: 1200 }])

const MyAccount = ({ userDetails }) => {
  const { tab: myAccountTab = "mycollection" } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()
  const logoutHandler = useLogout()

  const [allproducts, setAllproducts] = useState([])
  const [paid, setPaid] = useState(false)
  const [error, setError] = useState(null)
  const [showSidebar, setShowSidebar] = useState(false)
  const paypalRef = React.useRef()
  // const history = useHistory();
  const loginUserId = localStorage.getItem("loginUserId")
  const loginUserMail = localStorage.getItem("loginUserMail")
  const loginUserToken = userDetails["loginUserToken"]
  const isPaymentPending = userDetails?.loginUserpending ?? false
  const iotTokens = useSelector(state => state.common.iotTokens)

  const fetchUserInfo = () => {
    if (userDetails?.loginUserToken) {
      axios
        .get(`${BaseUrl}/getUserInfo`, {
          headers: {
            Authorization: `Bearer ${loginUserToken}`
          }
        })
        .then(response => {
          if (response.status === 200) {
            localStorage.setItem(
              "loginUserpending",
              response.data.data.is_payment_pending
            )

            dispatch(
              updateUserDetailsRedux({
                loginUser: response.data.data.name,
                loginUserId: response.data.data.id,
                loginUserMail: response.data.data.email,
                loginUserpending: response.data.data.is_payment_pending,
                loginUserPreminer: response.data.data.is_preminer,
                loginUserToken,
                referralId: response.data.data.referralId
              })
            )
          } else if (response.status == 401) {
            logoutHandler()
          } else if (response.status == 404) {
            swal({
              title: "Error!",
              text: "Page not found. Please try again later.",
              icon: "error"
            })
          } else if (response.status == 500) {
            swal({
              title: "Error!",
              text: "Something went wrong. Please try again later.",
              icon: "error"
            })
          } else if (response.status == 503) {
            swal({
              title: "Error!",
              text: "Something went wrong. Please try again later.",
              icon: "error"
            })
          }
        })
        .catch(error => {
          if (error?.response?.status === 401) {
            logoutHandler()
          }
        })
    } else {
      navigate("/login")
    }
  }

  useEffect(() => {
    updateBackground()
    fetchUserInfo()
  }, [])

  const continuePay = () => {
    const fObj = {
      user_id: loginUserId,
      payer_email: loginUserMail,
      amount: allproducts?.pending_amount,
      product_id: allproducts?.id
    }

    axios
      .post(`${BaseUrl}/createcharge/${allproducts?.id}`, fObj, {
        headers: {
          // 'Content-Type': 'application/json',
          Authorization: `Bearer ${loginUserToken}`
        }
      })
      .then(response => {
        if (response) {
          window.open(response.data.data.host_url, "_blank")
        } else if (response.status == 404) {
          swal({
            title: "Error!",
            text: "Page not found. Please try again later.",
            icon: "error"
          })
        } else if (response.status == 500) {
          swal({
            title: "Error!",
            text: "Something went wrong. Please try again later.",
            icon: "error"
          })
        } else if (response.status == 503) {
          swal({
            title: "Error!",
            text: "Something went wrong. Please try again later.",
            icon: "error"
          })
        } else {
          swal({
            title: "Error!",
            text: "Something went wrong. Please try again later.",
            icon: "error"
          })
        }
      })
      .catch(error => {
        if (error?.response?.status == 401) {
          logoutHandler()
        } else
          swal({
            title: "Error!",
            text: "Something went wrong. Please try again later.",
            icon: "error"
          })
      })
  }
  const onCancel = data => {
    // User pressed 'cancel' or closed the PayPal popup window
  }

  const onError = err => {
    // The main PayPal 's script can't be loaded
  }

  const tabClickHandler = value => {
    navigate("/myaccount/" + value)
  }

  let env = "sandbox" // you can set here to 'production' for production
  let currency = "USD" // or you can set this value from your props or state
  let total = 10 // same as above, this is the total amount (based on currency) to be
  let locale = "en_US"
  const client = {
    sandbox:
      "ATdVQH74kQnbWjjveWumaKL1bziq3XfAE5GzCFaCHqorLRz2ihxBb5FhBYxrnz405a_s8nae7FFO8ke9"
    // id:"AXOuInfLyUn3p8-Dn5MFQlMaldituFtqiQOZ7oW_XVegDK10NN4Ahq_GMm6ZErYGaT7C4L42F6in9Y1J"
  }

  const subTabs = [
    { image: mineGlobally, label: "Mine Globally", class: "first" },
    { image: weHostYouMine, label: "We host. You Mine", class: "second" },
    { image: effortlessEarning, label: "Effortless Earning", class: "third" },
    { image: collectible, label: "Collectible", class: "fourth" }
  ]

  const tabComponent = {
    // NFM screens
    pendingpayments: <PendingPayments fetchUserInfo={fetchUserInfo} />,
    emipayments: <EMIpayments />,
    mycollection: <MyCollectionTab />,
    myactivity: <Activity />,
    // rewards: <Rewards />,
    mynest: <Mynest />,

    // Iot screens
    device: <DeviceManagement />,
    pendingOrders: <Cart />,
    iotorders: <MyOrders />,
    analytics: <Analytics />,

    // Refer screens
    referral: <Refer />,
    rewards: <Rewards />
  }

  const headings = {
    pendingpayments: "Pending Payments",
    emipayments: "EMI Payments",
    mycollection: "My Collection",
    myactivity: "My Activity",
    rewards: " Rewards",
    mynest: "My Nest",
    device: "Device Management",
    pendingOrders: "Pending Orders",
    iotorders: "My Orders"
    // referral: "Refer and Earn"
  }

  const Tabs = React.memo(() => {
    return tabs.map(tab => {
      if (tab.value === "pendingpayments" && !isPaymentPending) return null
      return null
    })
  })

  const handleSidebarShow = () => {
    setShowSidebar(!showSidebar)
  }
  return (
    <>
      <div className="my-account">
        {/* Mobile View Icon */}
        <div className="mobile-view-sidebar-open" onClick={handleSidebarShow}>
          <i className="fa-solid fa-chevron-right"></i>
        </div>
        {/* <GlobalStyles/> */}
        <Sidebar
          sidebarShow={showSidebar}
          handleSidebarShow={handleSidebarShow}
        />

        <div className="my-account-expanded-view">
          {/* <div className="no-bottom no-top" id="content"> */}
          {/* <div id="top"></div> */}
          <div className="container">
            <section id="nitro-nft-miner" style={{ paddingBottom: 0 }}>
              <div
                className="top-links"
                style={{ overflowX: "auto", cursor: "default" }}>
                <Tabs />
              </div>
              <div className="col-12">
                <h1 className="text-center my-account__heading">
                  {headings[myAccountTab]}
                </h1>
              </div>
              <div
                className={
                  location.pathname === "/myaccount/pendingpayments"
                    ? `custom-nfm-calculatr`
                    : ""
                }>
                <div className="expanded-component">
                  {tabComponent[myAccountTab]}
                </div>

                {/* {location.pathname === "/myaccount/pendingpayments" && (
                    <div className="nfm-calculator">
                      <h5>NFM Calculator</h5>

                      <div className="dfjb">
                        <p>Select NFM Type</p>
                        <select name="nfm-type" id="">
                          <option value="iconic">Iconic</option>
                          <option value="Premium">Premium</option>
                          <option value="Top">Top</option>
                        </select>
                      </div>

                      <div className="dfjb">
                        <p>No. of NFM</p>
                        <select name="nfm-type" id="">
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                        </select>
                      </div>
                      <div className="dfjb">
                        <p>Stake Duration</p>
                        <select name="nfm-type" id="">
                          <option value="4">32 Months</option>
                          <option value="5">64 Months</option>
                          <option value="6">128 Months</option>
                        </select>
                      </div>
                      <div className="dfjb">
                        <p>Ncash Value</p>
                        <input
                          type="number"
                          name="ncashValue"
                          id="ncashValue"
                          placeholder="0.0005"
                          value={0.005}
                        />
                      </div>

                      <div className="text-center">
                        <button className="btn-brand btn-active">
                          Calculate
                        </button>
                      </div>
                    </div>
                  )} */}
              </div>
            </section>
          </div>
          {/* </div> */}
        </div>
        {/* <Footer /> */}
      </div>
    </>
  )
}

const mapStateToProps = state => {
  return {
    userDetails: state.common.userDetails
  }
}

export default connect(mapStateToProps)(MyAccount)

function updateBackground() {
  let body = document.querySelector("body")
  body.style.backgroundImage = "unset"
  body.style.backgroundColor = "rgb(22, 29, 48)"
}

function addHoverListeners() {
  const imageBox = document.querySelector(".image-box")
  const headBox = document.querySelector(".head-box")
  const textBox = document.querySelector(".text-box")
  const fourth = document.querySelector(".fourth")
  const third = document.querySelector(".third")
  const second = document.querySelector(".second")
  const first = document.querySelector(".first")

  fourth.addEventListener("mouseover", function () {
    imageBox.src = collectible
    headBox.innerHTML = "Collectible"
    textBox.innerHTML = "Rewards are collectible into your account!"
  })
  third.addEventListener("mouseover", function () {
    imageBox.src = effortlessEarning
    headBox.innerHTML = "Effortless Earning"
    textBox.innerHTML = "You Dont have to work for that, thats effortless!"
  })
  second.addEventListener("mouseover", function () {
    imageBox.src = weHostYouMine
    headBox.innerHTML = "We Host You Mine"
    textBox.innerHTML = "We are hosting , Mining Reward you will receive"
  })
  first.addEventListener("mouseover", function () {
    imageBox.src = mineGlobally
    headBox.innerHTML = "Mine Globally"
    textBox.innerHTML =
      "Your NFT Miner is universal and borderless. Mine anywhere in the world"
  })
}

function removeHoverListeners() {
  const imageBox = document.querySelector(".image-box")
  const headBox = document.querySelector(".head-box")
  const textBox = document.querySelector(".text-box")
  const fourth = document.querySelector(".fourth")
  const third = document.querySelector(".third")
  const second = document.querySelector(".second")
  const first = document.querySelector(".first")

  fourth.removeEventListener("mouseover", function () {
    imageBox.src = "./images/collectiblewts.png"
    headBox.innerHTML = "Collectible"
    textBox.innerHTML = "Rewards are collectible into your account!"
  })
  third.removeEventListener("mouseover", function () {
    imageBox.src = "./images/effortlesswhite.png"
    headBox.innerHTML = "Effortless Earning"
    textBox.innerHTML = "You Dont have to work for that, thats effortless!"
  })
  second.removeEventListener("mouseover", function () {
    imageBox.src = "./images/minerwhites.png"
    headBox.innerHTML = "We Host You Mine"
    textBox.innerHTML = "We are hosting , Mining Reward you will receive"
  })
  first.removeEventListener("mouseover", function () {
    // first.firstElementChild.src = './images/mineglobal.png';
    imageBox.src = "./images/mineglobal.png"
    headBox.innerHTML = "Mine Globally"
    textBox.innerHTML =
      "Your NFT Miner is universal and borderless. Mine anywhere in the world"
  })
}

const TempRewards = () => {
  return (
    <div className="thank-you-page container" style={{ marginTop: "-80px" }}>
      <h1>Coming soon!</h1>
      <h2>
        NFM Staked Rewards are being accumulated per epoch, you can start
        claiming them starting 1st May 2022.
      </h2>
    </div>
  )
}
