import React, { useState, useEffect } from "react"
import {
  Form,
  Tooltip
  // , Upload, Button, Input
} from "antd"
import * as FaIcons from "react-icons/fa"
import { Images } from "../../Shared/images"
import "../../assets/css/kyc.css"
import { useNavigate, useLocation } from "@reach/router"
import swal from "sweetalert"
import { BaseUrl } from "../../utils/config"
import { useSelector, useDispatch } from "react-redux"
import axios from "axios"
import {
  saveKYCForm,
  updateUserDetailsRedux,
  setConnectedWallet
} from "../../redux/actions"

// Images
import idfront from "./images/id.svg"
import idback from "./images/idback.svg"

const KYCSecond = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()
  const userDetails = useSelector(state => state.common.userDetails)

  const acceptableFormats = ".jpg, .jpeg, .png, .pdf"

  const idOptions = [
    {
      value: "",
      label: "Select Document",
      disabled: true
    },
    {
      value: "dl",
      label: "Driving License",
      disabled: false
    },
    {
      value: "nid",
      label: "National ID Card",
      disabled: false
    },
    {
      value: "passport",
      label: "Passport",
      disabled: false
    }
  ]

  const addressOptions = [
    {
      value: "",
      label: "Select Document",
      disabled: true
    },
    {
      value: "bas",
      label: "Bank Account Statement",
      disabled: false
    },
    {
      value: "ub",
      label: "Utility Bill",
      disabled: false
    },
    {
      value: "aggrement",
      label: "Rent Agreement / Sale deed of the property",
      disabled: false
    }
  ]

  const [idProofOptions, setIdProofOptions] = useState(idOptions)
  const [addressProofOptions, setAddressProofOptions] = useState(addressOptions)
  const [disabledForm, setDisabledForm] = useState(false)

  const [fields, setFields] = useState({
    id: [
      {
        category: "id proof",
        documentType: "",
        documents: []
      }
    ],
    address: [
      {
        category: "address proof",
        documentType: "",
        documents: []
      }
    ],
    selfie: [
      {
        category: "selfie proof",
        documentType: "selfie",
        documents: []
      }
    ]
  })

  const [values, setValues] = useState([])

  const addField = category => {
    let chosenDocTypes = fields[category].map(field => field.documentType)
    if (category === "id") {
      setIdProofOptions(prevState => {
        let modifiedState = JSON.parse(JSON.stringify(prevState))
        modifiedState = modifiedState.map(option => ({
          ...option,
          disabled:
            chosenDocTypes.includes(option.value) || option.value === ""
              ? true
              : false
        }))
        return modifiedState
      })
    } else if (category === "address") {
      setAddressProofOptions(prevState => {
        let modifiedState = JSON.parse(JSON.stringify(prevState))
        modifiedState = modifiedState.map(option => ({
          ...option,
          disabled:
            chosenDocTypes.includes(option.value) || option.value === ""
              ? true
              : false
        }))
        return modifiedState
      })
    }

    setFields(prevState => {
      return {
        ...prevState,
        [category]: [
          ...prevState[category],
          {
            category: category + " proof",
            documentType: category === "selfie" ? "selfie" : "",
            documents: []
          }
        ]
      }
    })
  }

  const deleteField = (category, index) => {
    let deletedFieldDocType = fields[category][index].documentType
    if (deletedFieldDocType) {
      if (category === "id") {
        setIdProofOptions(prevState => {
          let modifiedState = JSON.parse(JSON.stringify(prevState))
          modifiedState = modifiedState.map(option => ({
            ...option,
            disabled:
              deletedFieldDocType === option.value ? false : option.disabled
          }))
          return modifiedState
        })
      } else if (category === "address") {
        setAddressProofOptions(prevState => {
          let modifiedState = JSON.parse(JSON.stringify(prevState))
          modifiedState = modifiedState.map(option => ({
            ...option,
            disabled:
              deletedFieldDocType === option.value ? false : option.disabled
          }))
          return modifiedState
        })
      }
    }

    setFields(prevState => {
      if (prevState[category].length <= 1) {
        swal({
          title: `At least one ${category} proof is required`,
          icon: "error"
        })
        return prevState
      } else {
        let categoryArr = [...prevState[category]]

        categoryArr.splice(index, 1)

        return {
          ...prevState,
          [category]: categoryArr
        }
      }
    })
  }

  const handleDocTypeChange = (category, index, type) => {
    setFields(prevState => {
      let categoryArr = [...prevState[category]]
      categoryArr[index].documentType = type
      return {
        ...prevState,
        [category]: categoryArr
      }
    })
  }

  const handleFileChange = (
    category,
    documentType,
    fieldIndex,
    fileIndex,
    file
  ) => {
    if (!file) return
    if (file && file.name && file.name.length) {
      let selectedFileFormat =
        file.name.split(".")[file.name.split(".").length - 1]
      if (!acceptableFormats.includes(selectedFileFormat)) {
        swal({
          text: "The file extension is not allowed!! Please upload the documents with extensions JPEG, JPG, PNG and PDF.",
          icon: "warning"
        })
        return
      }
    }
    if (!documentType) {
      swal({
        title: "Please select type of document",
        icon: "error"
      })
      return
    }

    setFields(prevState => {
      let categoryArr = [...prevState[category]]
      let chosenField = categoryArr[fieldIndex]
      let docArr = [...chosenField.documents]

      docArr[fileIndex] = {
        type: `${documentType}_${fileIndex === 0 ? "front" : "back"}`,
        doc: file
      }
      chosenField = { ...chosenField, documents: docArr }
      categoryArr[fieldIndex] = chosenField

      return {
        ...prevState,
        [category]: categoryArr
      }
    })
  }

  const backButtonHandler = () => {
    navigate("/kyc", {
      state: {
        place: location.state && location.state.place
      }
    })
  }

  const submitForm = async () => {
    let idValues = values.filter(value => value.category.includes("id"))
    let addressValues = values.filter(value =>
      value.category.includes("address")
    )
    let selfieValues = values.filter(value => value.category.includes("selfie"))

    let minIdDocs = idValues.some(value => value.type.includes("passport"))
      ? 1
      : 2

    let minDocValidationFailed =
      idValues.length < minIdDocs ||
      addressValues.length < 1 ||
      selfieValues.length < 1

    if (
      !values.length ||
      values.includes(null) ||
      values.some(value => !value.doc) ||
      minDocValidationFailed
    ) {
      swal({
        title: "Incomplete Form",
        text: "Please upload all the files required",
        icon: "warning"
      })
      return
    }
    if (!location.state) {
      swal({
        title: "Missing Info",
        text: "Place details for KYC is missing. Please restart the KYC process",
        icon: "warning"
      })
      return
    }
    try {
      let apiObj = new FormData()
      apiObj.append("place_id", location.state.place.place_id)
      apiObj.append("token", location.state.place.token)

      for (let i = 0; i < values.length; i++) {
        if (values[i]) {
          apiObj.append("category", values[i].category)
          apiObj.append("documentType", values[i].type)
          apiObj.append("userFiles", values[i].doc)
        }
      }

      let response = await axios.post(`${BaseUrl}/uploadKycFiles`, apiObj, {
        headers: {
          Authorization: `Bearer ${userDetails.loginUserToken}`,
          "Content-Type": "multipart/form-data"
        }
      })

      if (response.status === 200 && response.data.status) {
        dispatch(saveKYCForm(null))
        navigate("/kyc-form-success")
      } else {
        throw new Error(response.data?.message)
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        dispatch(updateUserDetailsRedux({}))
        dispatch(setConnectedWallet(""))
        localStorage.clear()
        navigate("/login")
      } else {
        swal({
          title: "Error!",
          text: error?.message ?? "Something went wrong, please try again",
          icon: "error"
        })
      }
    }
  }

  // useEffect for creating values array for API data whenever form field updates
  useEffect(() => {
    let valuesArr = []
    Object.keys(fields).forEach(category => {
      for (let i = 0; i < fields[category].length; i++) {
        let updatedDocsArr = fields[category][i].documents.map(docObj => ({
          ...docObj,
          category
        }))
        valuesArr = valuesArr.concat(updatedDocsArr)
      }
    })
    setValues(valuesArr)
  }, [fields])

  //DidMount useEffect for populating values
  useEffect(() => {
    let documentTypes = [
      "passport",
      "dl",
      "nid",
      "bas",
      "ub",
      "aggrement",
      "selfie"
    ]
    if (
      location.state.place &&
      (location.state.place || {})?.hasOwnProperty("userFiles")
    ) {
      let userFiles = JSON.parse(location.state.place.userFiles) || []

      let fieldsValue = {
        id: [],
        address: [],
        selfie: []
      }

      Object.keys(fields).forEach(key => {
        let categoryDocuments = []

        // filtering all files category(id, address, selfie) wise
        let categoryFiles = userFiles.filter(file => file.category === key)

        // filtering all files of a single category based on document type(documentTypes Array)
        for (let documentType of documentTypes) {
          let tempArr = categoryFiles.filter(doc =>
            doc.documentType.startsWith(documentType)
          )
          tempArr.length && categoryDocuments.push(tempArr)
        }

        // array of arrays
        // Each array inside this array will have document type specific files

        let tempArr = []
        for (let categoryDoc of categoryDocuments) {
          tempArr.push({
            category: key + " proof",
            documentType: categoryDoc[0].documentType.split("_")[0],
            documents: categoryDoc.map((document, index) => ({
              type: document.documentType,
              doc: document.file
            }))
          })
        }
        if (!tempArr.length) {
          tempArr.push({
            category: key + " proof",
            documentType: key === "selfie" ? "selfie" : "",
            documents: []
          })
        }
        fieldsValue[key] = tempArr
        setFields(fieldsValue)
      })
    }
    if (
      location.state &&
      location.state.place &&
      location.state.place.verification_status === "Approved"
    ) {
      setDisabledForm(true)
    }
  }, [])

  return (
    <div className="kyc-page PT-90px">
      <div className="container">
        <div className="row">
          <h2 style={{ textAlign: "left" }}>KYC Registration Form</h2>

          <div>
            <div id="kyc-hr"></div>
            <div className="step-indicator-container">
              <div className="step-indicator"></div>
              <div className="step-indicator active"></div>
            </div>
          </div>
          <div className="step-name-container">
            <div className="step-name">Registration</div>
            <div className="step-name active">Upload documents</div>
          </div>

          <div className="col-12 form-container" id="kyc-form">
            <Form onFinish={submitForm}>
              <div
                className="kyc-second-page form-sections-container"
                id="second-step">
                {/* ID Proof Container */}
                <div className="upload-info-box box-style">
                  <h3>Upload Personal Information</h3>

                  <div className="spacer-single"></div>

                  <div className="kyc-id-container">
                    <div className="text-section">
                      <p className="title mb-0">
                        ID Proof Type{" "}
                        <Tooltip title="(Passport / Driver's License / National ID Card)">
                          <FaIcons.FaExclamationCircle />
                        </Tooltip>
                      </p>
                      <p className="subtitle mb-2">
                        <small>
                          (Passport / Driver's License / National ID Card)
                        </small>
                      </p>
                    </div>

                    {/* ID: Field Mapping */}
                    {fields.id.map((field, index) => (
                      <div
                        key={field.documentType + index}
                        className="kyc-doc-row">
                        {/* Id doc dropdown */}
                        <div className="choose-doc">
                          <p>Choose ID card :</p>
                          <select
                            name="doc"
                            id="doc"
                            onChange={e =>
                              handleDocTypeChange("id", index, e.target.value)
                            }
                            disabled={disabledForm}
                            value={field.documentType}>
                            {idProofOptions.map((option, index) => (
                              <option
                                key={option.value + index}
                                value={option.value}
                                disabled={option.disabled}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                        </div>

                        {field.documentType === "passport" ? (
                          <div className="upload-both adderss-upload-proof">
                            <div className="upload first-doc">
                              <p>
                                Upload File <img src={idfront} alt="" />
                              </p>
                              <span>
                                <small>
                                  {" "}
                                  (Files allowed: {acceptableFormats})
                                </small>
                              </span>

                              {/* File Input */}
                              <input
                                id={"id" + index}
                                disabled={!field.documentType || disabledForm}
                                type="file"
                                accept={acceptableFormats}
                                value={undefined}
                                onChange={e =>
                                  field.documentType
                                    ? handleFileChange(
                                        "id",
                                        field.documentType,
                                        index,
                                        0,
                                        e.target.files[0]
                                      )
                                    : () => {
                                        swal({
                                          title:
                                            "Please select type of document",
                                          icon: "error"
                                        })
                                      }
                                }
                                style={{ visibility: "hidden" }}
                              />
                              <label
                                className="custom-choose-file"
                                htmlFor={"id" + index}
                                style={
                                  !field.documentType || disabledForm
                                    ? {
                                        filter: "brightness(0.65)",
                                        cursor: "not-allowed"
                                      }
                                    : null
                                }>
                                Choose File
                              </label>

                              <span>
                                {field.documents.length ? (
                                  typeof field.documents[0].doc !== "string" ? (
                                    field.documents[0].doc.name
                                  ) : (
                                    <a
                                      style={{ textDecoration: "underline" }}
                                      target="_blank"
                                      href={field.documents[0].doc}
                                      download
                                      rel="noreferrer">
                                      View Uploaded File
                                    </a>
                                  )
                                ) : (
                                  "No File Chosen"
                                )}
                              </span>
                            </div>
                          </div>
                        ) : (
                          <div className="upload-both">
                            <div className="upload first-doc">
                              <p>
                                Upload Front Side <img src={idfront} alt="" />
                              </p>
                              <span>
                                <small>
                                  {" "}
                                  (Files allowed: {acceptableFormats})
                                </small>
                              </span>

                              {/* File Input */}
                              <input
                                id={"id-front" + index}
                                disabled={!field.documentType || disabledForm}
                                type="file"
                                accept={acceptableFormats}
                                value={undefined}
                                onChange={e =>
                                  field.documentType
                                    ? handleFileChange(
                                        "id",
                                        field.documentType,
                                        index,
                                        0,
                                        e.target.files[0]
                                      )
                                    : swal({
                                        title: "Please select type of document",
                                        icon: "error"
                                      })
                                }
                                style={{ visibility: "hidden" }}
                              />
                              <label
                                className="custom-choose-file"
                                htmlFor={"id-front" + index}
                                style={
                                  !field.documentType || disabledForm
                                    ? {
                                        filter: "brightness(0.65)",
                                        cursor: "not-allowed"
                                      }
                                    : null
                                }>
                                Choose File
                              </label>
                              <span>
                                {field.documents.length ? (
                                  typeof field.documents[0].doc !== "string" ? (
                                    field.documents[0].doc.name
                                  ) : (
                                    <a
                                      style={{ textDecoration: "underline" }}
                                      target="_blank"
                                      href={field.documents[0].doc}
                                      download
                                      rel="noreferrer">
                                      View Uploaded File
                                    </a>
                                  )
                                ) : (
                                  "No File Chosen"
                                )}
                              </span>
                            </div>
                            <div className="upload second-doc">
                              <p>
                                Upload Back Side <img src={idback} alt="" />
                              </p>
                              <span>
                                <small>
                                  {" "}
                                  (Files allowed: {acceptableFormats})
                                </small>
                              </span>

                              {/* File Input */}
                              <input
                                id={"id-back" + index}
                                disabled={!field.documentType || disabledForm}
                                type="file"
                                accept={acceptableFormats}
                                value={undefined}
                                onChange={e =>
                                  field.documentType
                                    ? handleFileChange(
                                        "id",
                                        field.documentType,
                                        index,
                                        1,
                                        e.target.files[0]
                                      )
                                    : swal({
                                        title: "Please select type of document",
                                        icon: "error"
                                      })
                                }
                                style={{ visibility: "hidden" }}
                              />
                              <label
                                className="custom-choose-file"
                                htmlFor={"id-back" + index}
                                style={
                                  !field.documentType || disabledForm
                                    ? {
                                        filter: "brightness(0.65)",
                                        cursor: "not-allowed"
                                      }
                                    : null
                                }>
                                Choose File
                              </label>
                              <span>
                                {field.documents.length > 1 ? (
                                  typeof field.documents[1].doc !== "string" ? (
                                    field.documents[1].doc.name
                                  ) : (
                                    <a
                                      style={{ textDecoration: "underline" }}
                                      target="_blank"
                                      href={field.documents[1].doc}
                                      download
                                      rel="noreferrer">
                                      {" "}
                                      View Uploaded File
                                    </a>
                                  )
                                ) : (
                                  "No File Chosen"
                                )}
                              </span>
                            </div>
                          </div>
                        )}

                        <div className="action">
                          <button
                            type="button"
                            className="btn-brand btn-active mx-2"
                            onClick={() => deleteField("id", index)}>
                            Delete
                          </button>
                        </div>
                      </div>
                    ))}
                    <div className="text-right">
                      <button
                        type="button"
                        className="add-more-id"
                        onClick={() => addField("id")}>
                        + Add more ID
                      </button>
                    </div>
                  </div>
                </div>
                {/* End Upload personal information container */}

                {/* Address Proof Container */}
                <div className="upload-info-box box-style">
                  <h3>Upload Address Information</h3>

                  <div className="spacer-single"></div>

                  <div className="kyc-id-container">
                    <div className="text-section">
                      <p className="title mb-0">
                        Address Proof Type{" "}
                        <Tooltip title="(Rental Aggrement / Utility bill / Proof of ownership)">
                          <FaIcons.FaExclamationCircle />
                        </Tooltip>
                      </p>
                      <p className="subtitle">
                        <small>
                          (Rental Aggrement / Utility bill / Proof of ownership)
                        </small>
                      </p>
                    </div>

                    {/* Address: Field Mapping */}
                    {fields.address.map((field, index) => (
                      <div
                        key={field.documentType + index}
                        className="kyc-doc-row">
                        {/* Address doc dropdown */}
                        <div className="choose-doc">
                          <p>Choose ID card :</p>
                          <select
                            name="doc"
                            id="doc"
                            disabled={disabledForm}
                            onChange={e =>
                              handleDocTypeChange(
                                "address",
                                index,
                                e.target.value
                              )
                            }
                            value={field.documentType}>
                            {addressProofOptions.map((option, index) => (
                              <option
                                key={option.value + index}
                                value={option.value}
                                disabled={option.disabled}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                        </div>

                        <div className="upload-both adderss-upload-proof">
                          <div className="upload first-doc">
                            <p>
                              Upload File <img src={idfront} alt="" />
                            </p>
                            <span>
                              <small>
                                {" "}
                                (Files allowed: {acceptableFormats})
                              </small>
                            </span>

                            {/* File Input */}
                            <input
                              id={"address" + index}
                              disabled={!field.documentType || disabledForm}
                              type="file"
                              accept={acceptableFormats}
                              value={undefined}
                              onChange={e =>
                                field.documentType
                                  ? handleFileChange(
                                      "address",
                                      field.documentType,
                                      index,
                                      0,
                                      e.target.files[0]
                                    )
                                  : swal({
                                      title: "Please select type of document",
                                      icon: "error"
                                    })
                              }
                              style={{ visibility: "hidden" }}
                            />
                            <label
                              className="custom-choose-file"
                              htmlFor={"address" + index}
                              style={
                                !field.documentType || disabledForm
                                  ? {
                                      filter: "brightness(0.65)",
                                      cursor: "not-allowed"
                                    }
                                  : null
                              }>
                              Choose File
                            </label>
                            <span>
                              {field.documents.length ? (
                                typeof field.documents[0].doc !== "string" ? (
                                  field.documents[0].doc.name
                                ) : (
                                  <a
                                    style={{ textDecoration: "underline" }}
                                    target="_blank"
                                    href={field.documents[0].doc}
                                    download
                                    rel="noreferrer">
                                    View Uploaded File
                                  </a>
                                )
                              ) : (
                                "No File Chosen"
                              )}
                            </span>
                          </div>
                        </div>
                        <div className="action">
                          <button
                            type="button"
                            className="btn-brand btn-active mx-2"
                            onClick={() => deleteField("address", index)}>
                            {" "}
                            Delete
                          </button>
                        </div>
                      </div>
                    ))}
                    <div className="text-right">
                      <button
                        type="button"
                        className="add-more-id"
                        onClick={() => addField("address")}>
                        + Add more ID
                      </button>
                    </div>
                  </div>
                </div>
                {/* End Upload address information container */}

                {/* Selfie Proof Container  */}
                <div className="upload-info-box box-style">
                  <h3>KYC Person Selfie </h3>

                  <div className="spacer-single"></div>

                  <div className="kyc-id-container">
                    <div className="text-section">
                      <p className="title mb-0">Selfie holding a photo ID </p>
                      <p className="subtitle">
                        <small>(upload a selfie holding a photo ID)</small>
                      </p>
                    </div>

                    {/* Selfie: Field Mapping */}
                    {fields.selfie.map((field, index) => (
                      <div key={field.category + index} className="kyc-doc-row">
                        <div className="upload-both adderss-upload-proof">
                          <div className="upload first-doc">
                            <p>
                              Upload File <img src={idfront} alt="" />
                            </p>
                            <span>
                              <small>
                                {" "}
                                (Files allowed: {acceptableFormats})
                              </small>
                            </span>

                            {/* File Input */}
                            <input
                              id={"selfie" + index}
                              disabled={!field.documentType || disabledForm}
                              type="file"
                              accept={acceptableFormats}
                              value={undefined}
                              onChange={e =>
                                handleFileChange(
                                  "selfie",
                                  "selfie",
                                  index,
                                  0,
                                  e.target.files[0]
                                )
                              }
                              style={{ visibility: "hidden" }}
                            />
                            <label
                              className="custom-choose-file"
                              htmlFor={"selfie" + index}
                              style={
                                !field.documentType || disabledForm
                                  ? {
                                      filter: "brightness(0.65)",
                                      cursor: "not-allowed"
                                    }
                                  : null
                              }>
                              Choose File
                            </label>
                            <span>
                              {field.documents.length ? (
                                typeof field.documents[0].doc !== "string" ? (
                                  field.documents[0].doc.name
                                ) : (
                                  <a
                                    style={{ textDecoration: "underline" }}
                                    target="_blank"
                                    href={field.documents[0].doc}
                                    download
                                    rel="noreferrer">
                                    View Uploaded File
                                  </a>
                                )
                              ) : (
                                "No File Chosen"
                              )}
                            </span>
                          </div>
                        </div>
                        <div className="action">
                          <button
                            type="button"
                            className="btn-brand btn-active mx-2"
                            onClick={() => deleteField("selfie", index)}>
                            {" "}
                            Delete
                          </button>
                        </div>
                      </div>
                    ))}
                    <div className="text-right">
                      <button
                        type="button"
                        className="add-more-id"
                        onClick={() => addField("selfie")}>
                        + Add more Selfie
                      </button>
                    </div>
                  </div>
                </div>
                {/* End Upload Selfie  */}
              </div>

              {/* NExt Prev Buttons */}
              <div className="cta-container">
                <div className="buttons-container">
                  <button
                    onClick={backButtonHandler}
                    type="button"
                    className="btn-brand btn-active btn-sm mx-w ">
                    <img src={Images.backwardArrowIcon} alt="backward arrow" />{" "}
                    Back
                  </button>

                  <button
                    type="submit"
                    id="confirm-button"
                    className="btn-brand btn-active btn-sm mx-w ">
                    Confirm
                  </button>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="notes">
          <div className="note">
            <h5>Note :</h5>
            <p>
              Nitro Network currently accepts the following Nester locations in
              Phase 1. We will be updating you when more locations are added. Do
              follow us on our social media channels for the latest from us.
            </p>

            {/* <p>
              1. USA (California, New York, Florida) <br /> 2. India (Bangaluru,
              Mumbai, Chennai, New Delhi, Hyderabad){" "}
            </p> */}
            <p>1. USA (Miami, New York, Los Angeles)</p>
            <p>2. India (Hyderabad, Bangalore, Chennai, Bombay, Delhi) </p>
          </div>
          <div className="note">
            <h5>$100 Refundable deposit</h5>
            <p>
              In the event the applicant is selected to be a Nester, they will
              be required to remit to the Company an amount of USD 100 as
              performance security. This deposit will be staked, and in the case
              of a refund, it will be refunded in NCash only. The said
              performance security will be refunded as per the terms of the
              nesting agreement.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default KYCSecond
