import React, { useState } from "react"
import { Form, Input } from "antd"
import { BaseUrl } from "../../utils/config"
import axios from "axios"
import swal from "sweetalert"
// import { useHistory, useLocation } from "react-router-dom"
import { useNavigate } from "@reach/router"
import { connect, useDispatch } from "react-redux"
import { updateUserDetailsRedux, setConnectedWallet } from "../../redux/actions"

const ChangePassword = ({ updateUserDetails }) => {
  const [isOpass, setIsOpass] = useState(null)
  const [isPass, setIsPass] = useState(null)
  // const [userId, setUserId] = useState(null)
  const [isCPass, setIsCPass] = useState(null)
  // const [passErrorMsg, setPassErrorMsg] = useState(null)
  // const [token, setToken] = useState(null)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const loginUserMail = localStorage.getItem("loginUserMail")
  const loginUserToken = localStorage.getItem("loginUserToken")

  // const history = useHistory()
  // const location = useLocation()
  const [form] = Form.useForm()

  // useEffect(() => {
  //   let path = location.pathname
  // }, [])

  // const verifyPassWord = () => {
  //   if (isPass != null && isCPass != null) {
  //     isPass != isCPass
  //       ? setPassErrorMsg("The two passwords that you entered do not match!")
  //       : setPassErrorMsg(null)
  //   }
  // }

  const onFinish = values => {
    const Fobj = {
      email: loginUserMail,
      old_password: values.oldPassword,
      new_password: values.cpassword
    }
    if (
      values.password == null ||
      values.cpassword == null ||
      values.cpassword != values.password ||
      values.password == "" ||
      values.cpassword == ""
    ) {
      swal({
        // title: "Invalid Token!",
        text: "Please enter the Credentials",
        icon: "warning"
      })
    } else {
      axios
        .put(`${BaseUrl}/changepassword`, Fobj, {
          headers: {
            // 'Content-Type': 'application/json',
            Authorization: `Bearer ${loginUserToken}`
          }
        })
        .then(response => {
          if (response?.data?.status) {
            swal({
              title: "Success!",
              text: "User Password updated successfully",
              icon: "success"
            })
            localStorage.clear()
            updateUserDetails({})
            navigate("/login")
          } else if (response.status == 404) {
            swal({
              title: "Error!",
              text: "Page not found. Please try again later.",
              icon: "error"
            })
          } else if (response.status == 500) {
            swal({
              title: "Error!",
              text: "Something went wrong. Please try again later.",
              icon: "error"
            })
          } else if (response.status == 503) {
            swal({
              title: "Error!",
              text: "Something went wrong. Please try again later.",
              icon: "error"
            })
          } else {
            swal({
              title: "Invalid Credentials",
              text: "Please try again",
              icon: "error"
            })
          }
        })
        .catch(error => {
          if (error?.response?.status === 401) {
            dispatch(updateUserDetailsRedux({}))
            dispatch(setConnectedWallet(""))
            localStorage.clear()
            navigate("/login")
          }
        })
    }
  }

  const toProfilepage = () => {
    navigate("/settings")
  }

  return (
    <div className="Edit-profile PT-90px">
      <div className="profile-section mt-4">
        <h1 className="text-center">Change Password</h1>

        <Form
          name="contactForm"
          id="contact_form"
          action=""
          autoComplete="off"
          form={form}
          // name="control-hooks"
          onFinish={onFinish}>
          <div className="field-set">
            <Form.Item
              name="oldPassword"
              rules={[
                {
                  required: true,
                  message: "Please enter your old password!"
                }
              ]}>
              <Input.Password
                className="form-control"
                size="large"
                type="password"
                placeholder="Old Password"
                onChange={e => {
                  setIsOpass(e.target.value)
                }}
                //    onMouseOut={()=>{verifyPassWord()}}
              />
            </Form.Item>
            {/* <input type="password" name="password" id="password" placeholder="New Password" /> */}
          </div>

          <div className="field-set">
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your new password!"
                },
                {
                  validator: (_, value) =>
                    !value.includes(" ")
                      ? Promise.resolve()
                      : Promise.reject(
                          new Error("Password should not contain space")
                        )
                },
                {
                  min: 6,
                  message: "Password should be in between 6 and 15 characters."
                },
                {
                  max: 15,
                  message: "Password should be in between 6 and 15 characters."
                }
              ]}>
              <Input.Password
                className="form-control"
                size="large"
                type="password"
                placeholder="New Password"
                onChange={e => {
                  setIsPass(e.target.value)
                }}
              />
            </Form.Item>
            {/* <input type="password" name="password" id="password" placeholder="New Password" /> */}
          </div>
          <div className="field-set">
            <Form.Item
              name="cpassword"
              dependencies={["password"]}
              rules={[
                {
                  required: true,
                  message: "Please confirm your password!"
                },
                formItemProps => ({
                  validator: (_, value) => {
                    if (
                      !value ||
                      formItemProps.getFieldValue("password") === value
                    )
                      return Promise.resolve()
                    else
                      return Promise.reject(new Error("Passwords do not match"))
                  }
                })
              ]}>
              <Input.Password
                className="form-control"
                size="large"
                type="password"
                placeholder="Confirm Password"
                onChange={e => {
                  setIsCPass(e.target.value)
                }}
              />
            </Form.Item>
          </div>

          <div id="submit" className="d-flex">
            <input
              type="submit"
              id="send_message"
              disabled={isOpass && isPass && isCPass ? false : true}
              value="Change Password"
              className="btn-brand btn-active btn-sm mx-w mr-4"
            />
            <input
              type="button"
              onClick={toProfilepage}
              id="send_message"
              value="Cancel"
              className="btn-brand btn-active btn-sm mx-w"
            />
            <div className="clearfix"></div>

            <div className="spacer-single"></div>
          </div>
        </Form>
      </div>

      {/* <Footer /> */}
    </div>
  )
}

const mapDispatchToProps = dispatch => {
  return {
    updateUserDetails: payload => dispatch(updateUserDetailsRedux(payload))
  }
}

export default connect(null, mapDispatchToProps)(ChangePassword)
