import { useParams } from "@reach/router"
import React, { useEffect, useState } from "react"
import { blogsData } from "../../Shared"
import DocumentHead from "../components/DocumentHead"

function WPBlogDetail() {
  const { slug } = useParams()
  const [pageObj, setPageObj] = useState(null)

  const fallbackHTML =
    "<h2 style='text-align: center;'>Oops! Blog not found</h2>"

  useEffect(() => {
    if (slug) {
      const _pageObj = blogsData.find(p => p.slug === slug)
      if (!_pageObj) {
        setPageObj({ content: fallbackHTML })
        return
      }
      setPageObj(_pageObj)
    }
  }, [slug])

  document.addEventListener("DOMContentLoaded", function () {
    document.querySelector(".responsive")?.slick({
      infinite: true,
      autoplay: true,
      slidesToShow: 3,
      slidesToScroll: 3,
      arrows: true,
      responsive: [
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 2
          }
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1
          }
        }
      ]
    })
    document.querySelector(".slider")?.slick({
      infinite: true,
      autoplay: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      responsive: [
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 1,
            arrows: false
          }
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
            arrows: false
          }
        }
      ]
    })
    document.querySelector(".slider2")?.slick({
      infinite: true,
      autoplay: true,
      dots: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      responsive: [
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 1,
            dots: false
          }
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
            dots: false
          }
        }
      ]
    })
  })

  return (
    <div className="cms-page cms-blog-page">
      <DocumentHead pageObj={pageObj} />
      <div className=" cms-blog__detail">
        <div className="cms-blog__title">
          <h1 className="cms-blog__heads">{pageObj?.title ?? ""}</h1>
          <p className="dfb">
            <span>
              {pageObj?.date ?? ""} {" | "}
            </span>
            <strong className="ml-2">Nitro Network</strong>
          </p>
        </div>
        <div
          className="cms-blog__left"
          dangerouslySetInnerHTML={{
            __html: pageObj?.content ?? fallbackHTML
          }}></div>
      </div>
    </div>
  )
}

export default WPBlogDetail
