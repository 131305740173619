import { useNavigate } from "@reach/router"
import { React, useState, useEffect } from "react"
import swal from "sweetalert"
import axios from "axios"
import { BaseUrl } from "../../utils/config"
import {
  updateUserDetailsRedux,
  setPendingPayment,
  setActiveMenu
} from "../../redux/actions"
import { useSelector, useDispatch } from "react-redux"
import addCommas from "add-commas"
import constant from "../../constants/constants"
import nitroPaymentAbi from "../../assets/blockchain/nitroPaymentABI.json"
import erc20Abi from "../../assets/blockchain/erc20Abi.json"
import BigNumber from "bignumber.js"
import { setConnectedWallet as setConnectedWalletRedux } from "../../redux/actions"
import { Form } from "antd"
// widget
import payBoxBackgroundGradient from "../../assets/images/pay-bg.png"
import * as FaIcons from "react-icons/fa"

import { Tooltip } from "antd"
import { checkSessionStatus, isFloat } from "../../utils"
// Styles

// import "./styles/paymentpage.css"

// blockchain - start
import Web3 from "web3"
import Cookies from "universal-cookie"
import { useLogout } from "../../hooks"
const cookies = new Cookies()
// blockchain - end

function PaymentPage() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const logoutHandler = useLogout()
  const [form] = Form.useForm()
  const userDetails = useSelector(state => state.common.userDetails)
  const pendingPayment =
    useSelector(state => state.common.pendingPayment) || null

  const [TNCAgreed, setTNCAgreed] = useState(false)
  const [promoCode, setPromoCode] = useState("")
  const [discountObj, setDiscountObj] = useState(null)
  const [balance, setBalance] = useState(0)
  const [paymentInProgress, setPaymentInProgress] = useState(false)
  // blockchain
  let [web3, setWeb3] = useState(null)
  let [isWalletConnected, setWalletConnected] = useState(false)
  let [connectedWallet, setConnectedWallet] = useState(false)
  let [selectedToken, setSelectedToken] = useState(0)
  let [cryptoPaymentAmount, setCryptoPaymentAmountToPay] = useState(0)
  let [paymentTokenToshow, setPaymentTokenToShow] = useState("WBTC")
  let [paymentAmountToShow, setPaymentAmountToShow] = useState(0)
  let [isAlreadyApproved, setIsAlreadyApproved] = useState(false)
  let [isApprovedTxAvailable, setIsApprovedTxAvailable] = useState(false)
  let [approvedTx, setApprovedTx] = useState(null)
  let [payToken, setPayToken] = useState(null)
  let [ncashPayToken, setNcashPayToken] = useState(null)
  let [isPayTxAvailable, setIsPayTxAvailable] = useState(false)
  let [payTx, setPayTx] = useState(null)
  let [amountPaid, setAmountPaid] = useState(0)
  let [amountToPayInUSD, setAmountToPayInUSD] = useState(0)
  let [amountToPayWrapped, setAmoutForWrapped] = useState(0)
  let [NCashBalance, setNCashBalance] = useState(0)
  let [totalNcash, setTotalNcash] = useState(0)
  let [nCashRate, setNcashRate] = useState(0)

  const loginUserMail = localStorage.getItem("loginUserMail")
  let _nCashSelectedToken = constant.NCASH
  let _ncash_token = "NCASH.E"
  const [ncashCryptoPaymentAmountToPay, setNcashCryptoPaymentAmountToPay] =
    useState(false)

  let categorizedOrders = {
    Iconic: [],
    Premium: [],
    "Top Location": []
  }

  let data =
    pendingPayment && Array.isArray(pendingPayment.data)
      ? pendingPayment.data
      : []

  if (data?.length) {
    Object.keys(categorizedOrders).forEach((key, index) => {
      categorizedOrders[key] = data.filter(
        orderItem => parseInt(orderItem.product_id) - 1 === index
      )
    })
  }

  const getBalance = () => {
    let strikeOffAmount = 0
    let balanceAmount = 0

    ;(pendingPayment?.data ?? []).forEach(order => {
      const isPreMiner = (order?.payment_gross ?? 0) > 0
      if (isPreMiner) {
        strikeOffAmount +=
          order?.device_count * (order?.unit_price + 75) - order?.payment_gross
        balanceAmount +=
          order?.device_count * (order?.unit_price) - order?.payment_gross
      } else {
        strikeOffAmount +=
          order?.device_count * (order?.unit_price * 2) - order?.payment_gross
        balanceAmount +=
          order?.device_count * order?.unit_price - order?.payment_gross
      }
    })

    return { strikeOffAmount, balanceAmount }
  }

  const applyPromoCode = () => {
    let apiObj = {
      email: userDetails?.loginUserMail,
      promo_code: promoCode
    }

    axios
      .post(`${BaseUrl}/validatePromoCode`, apiObj, {
        headers: {
          Authorization: `Bearer ${userDetails?.loginUserToken}`
        }
      })
      .then(response => {
        if (response.status === 200) {
          setDiscountObj(response?.data)
        }
      })
      .catch(error => {
        if (error?.response?.status === 401) {
          dispatch(updateUserDetailsRedux({}))
          localStorage.clear()
          navigate("/login")
        } else if (error?.response?.status === 406) {
          setDiscountObj({ ...error.response.data, balance: 0 })
        }
      })
  }

  const removeDiscount = () => {
    setPromoCode("")
    setDiscountObj(null)
    form.resetFields()
  }

  const payWithCoinbase = () => {
    if (!TNCAgreed) {
      swal({
        text: "Please agree to terms and conditions",
        icon: "warning"
      })
      return
    }
    console.log("paywithcoinbase runnign")

    const apiObj = {
      user_id: userDetails?.loginUserId ?? "",
      payer_email: userDetails?.loginUserMail ?? "",
      amount: discountObj?.status
        ? balance - discountObj?.amount ?? 0
        : balance,
      tokens: Array.isArray(pendingPayment?.data)
        ? (pendingPayment?.data ?? []).map(orderItem => orderItem?.token ?? "")
        : [],
      promo_code: promoCode
    }

    ;(!promoCode.length || !(discountObj?.balance ?? 0)) &&
      delete apiObj.promo_code

    axios
      .post(`${BaseUrl}/createchargegroup/${pendingPayment?.token}`, apiObj, {
        headers: {
          // 'Content-Type': 'application/json',
          Authorization: `Bearer ${userDetails?.loginUserToken}`
        }
      })
      .then(response => {
        if (response.status === 200) {
          window.open(response?.data?.data?.host_url, "_blank")
          getPendingPayments()
        }
      })
      .catch(error => {
        if (error?.response?.status === 401) {
          localStorage.clear()
          dispatch(updateUserDetailsRedux({}))
          navigate("/login")
        }
      })
  }

  const payWithFiat = () => {
    if (!TNCAgreed) {
      swal({
        text: "Please agree to terms and conditions",
        icon: "warning"
      })
      return
    }

    const apiObj = {
      user_id: userDetails?.loginUserId ?? "",
      payer_email: userDetails?.loginUserMail ?? "",
      amount: discountObj?.status
        ? balance - discountObj?.amount ?? 0
        : balance,
      tokens: Array.isArray(pendingPayment?.data)
        ? (pendingPayment?.data ?? []).map(orderItem => orderItem?.token ?? "")
        : [],
      promo_code: promoCode
    }

    ;(!promoCode.length || !(discountObj?.balance ?? 0)) &&
      delete apiObj.promo_code
    axios
      .post(
        `${BaseUrl}/createChargeGroupStripe/${pendingPayment?.token}`,
        apiObj,
        {
          headers: {
            // 'Content-Type': 'application/json',
            Authorization: `Bearer ${userDetails?.loginUserToken}`
          }
        }
      )
      .then(response => {
        if (response.status === 200) {
          window.open(response?.data?.data?.host_url, "_blank")
          getPendingPayments()
        }
      })
      .catch(error => {
        if (error?.response?.status === 401) {
          localStorage.clear()
          dispatch(updateUserDetailsRedux({}))
          navigate("/login")
        }
      })
  }

  const getPendingPayments = () => {
    if (userDetails?.loginUserToken) {
      axios
        .get(`${BaseUrl}/mypurchase/${userDetails?.loginUserId}`, {
          headers: {
            Authorization: `Bearer ${userDetails?.loginUserToken}`
          }
        })
        .then(response => {
          let distructedResponse = response?.data ?? null
          if (response.status === 200) {
            if (distructedResponse?.is_payment_in_progress) {
              setPaymentInProgress(distructedResponse?.message ?? "")
            } else if (
              distructedResponse?.is_payment_completed &&
              !localStorage.getItem("buyNitroToken")
            ) {
              navigate("/myaccount/mycollection")
              dispatch(setActiveMenu("NFM"))
            }
            dispatch(setPendingPayment(response?.data ?? null))
          } else if (response?.status == 404) {
            swal({
              title: "Error!",
              text: "Page not found. Please try again later.",
              icon: "error"
            })
          } else if (response?.status == 500) {
            swal({
              title: "Error!",
              text: "Something went wrong. Please try again later.",
              icon: "error"
            })
          } else if (response?.status == 503) {
            swal({
              title: "Error!",
              text: "Something went wrong. Please try again later.",
              icon: "error"
            })
          } else {
            dispatch(setPendingPayment(null))
          }
        })
        .catch(err => {
          dispatch(setPendingPayment(null))
          if (err?.response?.status === 401) {
            dispatch(updateUserDetailsRedux({}))
            dispatch(setConnectedWalletRedux(""))
            localStorage.clear()
            navigate("/login")
          }
        })
    } else {
      navigate("/login")
    }
  }

  const connectToWallet = async () => {
    console.log(
      `[nft][src][components][components][pages][paymentpage][connectToWallet] connectToWallet`
    )
    if (!TNCAgreed) {
      swal({
        text: "Please agree to terms and conditions",
        icon: "warning"
      })
      return
    }
    if (typeof window.ethereum !== "undefined") {
      let accounts = await window.ethereum.request({
        method: "eth_requestAccounts"
      })
      window.web3 = new Web3(window.ethereum)
      setWeb3(window.web3)

      let chainId = await window.web3.eth.getChainId()

      if (chainId !== parseInt(constant.CHAIN_ID)) {
        swal({
          title: "Invalid Network!",
          text: `Switch your network to ${constant.NETWORK_NAME}`,
          icon: "error",
          confirmButtonText: "Ok"
        })
        return
      }

      cookies.set("wallet", accounts[0], { path: "/" })
      cookies.set("wallet_status", 1, { path: "/" })
      setWalletConnected(true)
      setConnectedWallet(accounts[0])
      // connect to wallet api

      let apiData = {
        wallet_address: accounts[0]
      }
      axios
        .put(
          `${BaseUrl}/addwallet?user_id=${userDetails.loginUserId}`,
          apiData,
          {
            headers: {
              Authorization: `Bearer ${userDetails.loginUserToken}`
            }
          }
        )
        .then(response => {
          if (response.status === 200) {
          }
        })
        .catch(error => {
          if (error?.response?.status === 401) {
            dispatch(updateUserDetailsRedux({}))
            dispatch(setConnectedWallet(""))
            localStorage.clear()
            navigate("/login")
          }
        })

      setWeb3(window.web3)
    } else {
      cookies.set("wallet", null, { path: "/" })
      cookies.set("wallet_status", 0, { path: "/" })
      setWalletConnected(false)
      setConnectedWallet(null)
      setWeb3(null)
      swal({
        title: "Unsupported Browser/Plugin not installed",
        text: `Please Install Metamask wallet and Set network to ${constant.NETWORK_NAME}`,
        icon: "error",
        confirmButtonText: "Ok"
      })
      console.log("Unsupported Browser/Plugin not installed")
    }
  }

  const disconnectWallet = () => {
    axios
      .delete(`${BaseUrl}/removeWallet/${userDetails?.loginUserId}`, {
        headers: {
          Authorization: `Bearer ${userDetails?.loginUserToken}`
        }
      })
      .then(response => {
        cookies.set("wallet", null, { path: "/" })
        cookies.set("wallet_status", 0, { path: "/" })
        setWalletConnected(false)
        dispatch(setConnectedWallet(""))
        setWeb3(null)
        window.location.reload(false)
      })
      .catch(error => {
        if (error?.response?.status == 401) {
          dispatch(updateUserDetailsRedux({}))
          dispatch(setConnectedWallet(""))
          localStorage.clear()
          navigate("/login")
        }
      })
  }

  const checkIfWalletAlreadyConnected = async () => {
    let walletStatus = cookies.get("wallet_status")
    let connectedWallet = cookies.get("wallet")
    if (parseInt(walletStatus) == 1) {
      console.log(
        `[nft][src][components][components][pages][PaymentPage][checkIfWalletAlreadyConnected] walletStatus 1 ${walletStatus}`
      )
      if (connectedWallet !== null || connectedWallet !== undefined) {
        cookies.set("wallet", connectedWallet, { path: "/" })
        cookies.set("wallet_status", 1, { path: "/" })
        setWalletConnected(true)
        setConnectedWallet(connectedWallet)
        setWeb3(web3)
      }
    } else {
      console.log(
        `[nft][src][components][components][pages][PaymentPage][checkIfWalletAlreadyConnected] walletStatus 2 ${walletStatus}`
      )
      cookies.set("wallet", null, { path: "/" })
      cookies.set("wallet_status", 0, { path: "/" })
      setWalletConnected(false)
      setConnectedWallet(null)
      setWeb3(null)
    }
  }

  const getDiscountedPaymentAmountInUsd = async () => {
    let amount = (await discountObj?.status)
      ? balance - discountObj?.amount ?? 0
      : balance
    return Math.round(parseFloat(amount))
  }

  const selectThePaymentToken = async __selectedToken => {
    setSelectedToken(__selectedToken)
    try {
      if (isWalletConnected == true) {
        if (typeof window.ethereum !== "undefined") {
          window.web3 = new Web3(window.ethereum)
          setWeb3(window.web3)
          let NitroPayment = await new window.web3.eth.Contract(
            nitroPaymentAbi,
            constant.NITRO_PAYMENT_CONTRACT
          )

          let _paymentToPay = 0
          let _paymentToShow = 0
          let _token = null
          let __amount = discountObj?.status
            ? balance - discountObj?.amount ?? 0
            : balance
          let _paymentToPayUSD = Math.round(parseFloat(__amount))
          setAmoutForWrapped(_paymentToPayUSD)
          if (__selectedToken == "1") {
            _paymentToPay = await NitroPayment.methods
              .getPriceToPayBTCUSD(_paymentToPayUSD)
              .call()
            _paymentToShow = _paymentToPay / 100000000
            _token = "WBTC.e"
          } else if (__selectedToken == "2") {
            _paymentToPay = await NitroPayment.methods
              .getPriceToPayETHUSD(_paymentToPayUSD)
              .call()
            _paymentToShow = await window.web3.utils.fromWei(
              _paymentToPay,
              "ether"
            )
            _token = "WETH.e"
          } else if (__selectedToken == "3") {
            console.log("avax option selected")
            _paymentToPay = await NitroPayment.methods
              .getPriceToPayAVAXUSD(_paymentToPayUSD)
              .call()
            console.log(_paymentToPay, "_paymentToPay")
            _paymentToShow = await window.web3.utils.fromWei(
              _paymentToPay,
              "ether"
            )
            _token = "WAVAX"
          } else if (__selectedToken == "4") {
            _paymentToPay = await window.web3.utils.toWei(
              _paymentToPayUSD.toString(),
              "ether"
            )
            _paymentToShow = discountObj?.status
              ? balance - discountObj?.amount ?? 0
              : balance
            _token = "DAI.e"
          } else if (__selectedToken == "5") {
            let _temp = BigNumber(_paymentToPayUSD)
            _paymentToPay = _temp.multipliedBy("1000000").toString()
            _paymentToShow = discountObj?.status
              ? balance - discountObj?.amount ?? 0
              : balance
            _token = "USDC.e"
          } else if (__selectedToken == "6") {
            let _temp = BigNumber(_paymentToPayUSD)
            _paymentToPay = _temp.multipliedBy("1000000").toString()
            _paymentToShow = discountObj?.status
              ? balance - discountObj?.amount ?? 0
              : balance
            _token = "USDT.e"
          } else {
            return
          }
          setAmountToPayInUSD(
            discountObj?.status ? balance - discountObj?.amount ?? 0 : balance
          )
          setCryptoPaymentAmountToPay(_paymentToPay.toString())
          setPaymentTokenToShow(_token)
          setPaymentAmountToShow(_paymentToShow)
          // console.log(_token);
          isNFTPaymentContractApproved(_token, _paymentToPay)
          // isNcashNFTPaymentContractApproved(_ncash_token, _paymentToPay);
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getWeb3 = () => {
    if (window.ethereum) {
      try {
        window.web3 = new Web3(window.ethereum)
        setWeb3(window.web3)
      } catch (error) {
        console.log(
          `[nft][src][components][components][modal][getWeb3] Web3 Connection Error`,
          error
        )
      }
    } else {
      setWeb3(null)
    }
  }

  const handleSelectToken = event => {
    try {
      selectThePaymentToken(event.target.value)
      getWeb3()
    } catch (error) {
      console.log(error)
    }
  }

  const isNFTPaymentContractApproved = async (
    selectedToken,
    amountToApprove
  ) => {
    console.log(
      `[nft][src][components][components][modal][isNFTPaymentContractApproved] selectedToken`,
      selectedToken
    )
    await getWeb3()

    let _selectedToken = null

    if (selectedToken == "WBTC.e") {
      _selectedToken = constant.WBTC
    } else if (selectedToken == "WETH.e") {
      _selectedToken = constant.WETH
    } else if (selectedToken == "WAVAX") {
      _selectedToken = constant.WAVAX
    } else if (selectedToken == "DAI.e") {
      _selectedToken = constant.DAI
    } else if (selectedToken == "USDC.e") {
      _selectedToken = constant.USDC
    } else if (selectedToken == "USDT.e") {
      _selectedToken = constant.USDT
    } else {
      _selectedToken = "0x0000000000000000000000000000000000000000"
    }

    setPayToken(_selectedToken)

    if (
      _selectedToken != "null" ||
      _selectedToken != "0x0000000000000000000000000000000000000000"
    ) {
      let erc20 = await new window.web3.eth.Contract(erc20Abi, _selectedToken)

      // balance to Pay
      let balanceToPay = new BigNumber(amountToApprove)

      connectToWallet()

      try {
        if (isWalletConnected == true) {
          if (connectToWallet != null) {
            let isApproved = await erc20.methods
              .allowance(connectedWallet, constant.NITRO_PAYMENT_CONTRACT)
              .call()

            let decimal = await erc20.methods.decimals().call()

            let baseBN = new BigNumber(10)
            let decimalBN = new BigNumber(decimal)

            let exponentiate = baseBN.exponentiatedBy(decimalBN)

            let isApprovedBN = new BigNumber(isApproved)
            let balanceToPayBN = new BigNumber(balanceToPay).multipliedBy(
              exponentiate
            )

            let res = isApprovedBN.comparedTo(balanceToPayBN)
            console.log(
              `[nft][src][components][components][modal][isNFTPaymentContractApproved] res`,
              res
            )

            if (res === 0 || res == 1) {
              setIsAlreadyApproved(true)
            } else if (res == -1) {
              setIsAlreadyApproved(false)
            }
            console.log(
              `[nft][src][components][components][modal][isNFTPaymentContractApproved] Is Approved`,
              isAlreadyApproved
            )
          }
        } else {
          setIsAlreadyApproved(false)
        }
      } catch (error) {
        console.log(error)
      }
    } else {
      setIsAlreadyApproved(false)
    }
  }
  const isNcashNFTPaymentContractApproved = async (
    selectedToken,
    amountToApprove
  ) => {
    console.log(
      `[nft][src][components][components][modal][isNcashNFTPaymentContractApproved] selectedToken`,
      selectedToken
    )
    await getWeb3()

    let _selectedToken = null
    _selectedToken = constant.NCASH
    setNcashPayToken(_selectedToken)

    if (
      _selectedToken != "null" ||
      _selectedToken != "0x0000000000000000000000000000000000000000"
    ) {
      let erc20 = await new window.web3.eth.Contract(erc20Abi, _selectedToken)

      // balance to Pay
      let balanceToPay = new BigNumber(amountToApprove)

      connectToWallet()

      try {
        if (isWalletConnected == true) {
          if (connectToWallet != null) {
            let isApproved = await erc20.methods
              .allowance(connectedWallet, constant.NITRO_PAYMENT_CONTRACT)
              .call()

            let decimal = await erc20.methods.decimals().call()

            let baseBN = new BigNumber(10)
            let decimalBN = new BigNumber(decimal)

            let exponentiate = baseBN.exponentiatedBy(decimalBN)

            let isApprovedBN = new BigNumber(isApproved)
            let balanceToPayBN = new BigNumber(balanceToPay).multipliedBy(
              exponentiate
            )

            let res = isApprovedBN.comparedTo(balanceToPayBN)
            console.log(
              `[nft][src][components][components][modal][isNFTPaymentContractApproved] res`,
              res
            )

            if (res === 0 || res == 1) {
              setIsAlreadyApproved(true)
            } else if (res == -1) {
              setIsAlreadyApproved(false)
            }
            console.log(
              `[nft][src][components][components][modal][isNFTPaymentContractApproved] Is Approved`,
              isAlreadyApproved
            )
          }
        } else {
          setIsAlreadyApproved(false)
        }
      } catch (error) {
        console.log(error)
      }
    } else {
      setIsAlreadyApproved(false)
    }
  }

  const approveNFTPaymentContract = async () => {
    const isSessionActive = await checkSessionStatus(
      userDetails?.loginUserToken
    )

    if (!isSessionActive) {
      logoutHandler()
      return
    }
    if (!TNCAgreed) {
      swal({
        text: "Please agree to terms and conditions",
        icon: "warning"
      })
      return
    }
    if (isWalletConnected == true) {
      let web3 = new Web3(window.ethereum)
      let accounts = await web3.eth.getAccounts()

      console.log(
        "[nft][src][components][components][modal][approveNFTPaymentContract] Initiated"
      )
      console.log(
        "[nft][src][components][components][modal][approveNFTPaymentContract] Selected Token to Pay",
        payToken
      )

      if (payToken != null) {
        let contractToApprove = constant.NITRO_PAYMENT_CONTRACT
        let valueToApprove =
          "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff"

        let ERC20 = await new web3.eth.Contract(erc20Abi, payToken)

        try {
          await ERC20.methods
            .approve(contractToApprove, valueToApprove)
            .send({ from: accounts[0] })
            .on("transactionHash", function (hash) {
              setIsApprovedTxAvailable(true)
              console.log(
                "[nft][src][components][components][modal][approveNFTPaymentContract] Approve Tx Hash",
                hash
              )
              let txHash = `${constant.EXPLORER_URL}/tx/${hash}`
              setApprovedTx(txHash)

              swal({
                icon: "info",
                title: "In progress",
                text: `Transaction Initiated, Please wait!`,
                closeOnClickOutside: false,
                buttons: ["View Transaction"],
                cancel: false,
                confirm: false
              }).then(function () {
                window.location = `${constant.EXPLORER_URL}/tx/${hash}`
              })

              waitTransactionReceipt(
                hash,
                "approve",
                0,
                function (err, receipt) {
                  if (err != null) {
                    return
                  }
                }
              )
            })
            .on("error", function (error, receipt) {
              console.log(
                "[nft][src][components][components][modal][approveNFTPaymentContract] Approve Tx Error",
                error
              )
              swal({
                icon: "error",
                title: "Failed",
                text: "Transaction cancelled. Please try again!",
                showConfirmButton: true,
                showCancelButton: false,
                closeOnClickOutside: true
              })
              setIsAlreadyApproved(false)
              return
            })
        } catch (error) {
          console.log(
            "[nft][src][components][components][modal][approveNFTPaymentContract] Catch Error",
            error
          )
          swal({
            icon: "error",
            title: "Failed",
            text: "Something went wrong. Please try again!",
            showConfirmButton: true,
            showCancelButton: false,
            closeOnClickOutside: true
          })
          setIsAlreadyApproved(false)
        }
      } else {
        swal({
          text: "Please select your currency to proceed",
          icon: "error"
        })
      }
    } else {
      await getWeb3()
      await connectToWallet()
      swal({
        title: "Failed",
        text: `Please connect your wallet.`,
        icon: "error",
        confirmButtonText: "Ok"
      })
    }
  }

  const approveNcashNFTPaymentContract = async () => {
    // if (!TNCAgreed) {
    //   swal({
    //     text: "Please agree to terms and conditions",
    //     icon: "warning",
    //   });
    //   return;
    // }
    if (isWalletConnected == true) {
      let web3 = new Web3(window.ethereum)
      let accounts = await web3.eth.getAccounts()

      console.log(
        "[nft][src][components][components][modal][approveNcashNFTPaymentContract] Initiated"
      )
      console.log(
        "[nft][src][components][components][modal][approveNcashNFTPaymentContract] Selected Token to Pay",
        _nCashSelectedToken
      )

      if (_nCashSelectedToken != null) {
        let contractToApprove = constant.NITRO_PAYMENT_CONTRACT
        let valueToApprove =
          "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff"

        let ERC20 = await new web3.eth.Contract(erc20Abi, _nCashSelectedToken)

        try {
          await ERC20.methods
            .approve(contractToApprove, valueToApprove)
            .send({ from: accounts[0] })
            .on("transactionHash", function (hash) {
              setIsApprovedTxAvailable(true)
              console.log(
                "[nft][src][components][components][modal][approveNFTPaymentContract] Approve Tx Hash",
                hash
              )
              let txHash = `${constant.EXPLORER_URL}/tx/${hash}`
              setApprovedTx(txHash)

              swal({
                icon: "info",
                title: "In progress",
                text: `Transaction Initiated, Please wait!`,
                closeOnClickOutside: false,
                buttons: ["View Transaction"],
                cancel: false,
                confirm: false
              }).then(function () {
                window.location = `${constant.EXPLORER_URL}/tx/${hash}`
              })

              waitTransactionReceipt(
                hash,
                "approve",
                0,
                function (err, receipt) {
                  if (err != null) {
                    return
                  }
                }
              )
            })
            .on("error", function (error, receipt) {
              console.log(
                "[nft][src][components][components][modal][approveNFTPaymentContract] Approve Tx Error",
                error
              )
              swal({
                icon: "error",
                title: "Failed",
                text: "Transaction cancelled. Please try again!",
                showConfirmButton: true,
                showCancelButton: false,
                closeOnClickOutside: true
              })
              setIsAlreadyApproved(false)
              return
            })
        } catch (error) {
          console.log(
            "[nft][src][components][components][modal][approveNFTPaymentContract] Catch Error",
            error
          )
          swal({
            icon: "error",
            title: "Failed",
            text: "Something went wrong. Please try again!",
            showConfirmButton: true,
            showCancelButton: false,
            closeOnClickOutside: true
          })
          setIsAlreadyApproved(false)
        }
      } else {
        swal({
          text: "Please select your currency to proceed",
          icon: "error"
        })
      }
    } else {
      await getWeb3()
      await connectToWallet()
      swal({
        title: "Failed",
        text: `Please connect your wallet.`,
        icon: "error",
        confirmButtonText: "Ok"
      })
    }
  }

  const waitTransactionReceipt = async (
    transactionHash,
    actionType,
    timeOut,
    callback
  ) => {
    console.log(
      "[nft][src][components][components][modal][waitTransactionReceipt] transactionHash",
      transactionHash
    )
    var web3 = new Web3(window.ethereum)
    let self = this

    let timeOutCounter = 0
    let err = {
      message: ""
    }

    let isWaitRunning = false

    let getReceiptTimerId = setInterval(function () {
      if (isWaitRunning == false) {
        isWaitRunning = true
        web3.eth
          .getTransaction(transactionHash)
          .then(function (tx) {
            if (tx == null) {
              clearInterval(getReceiptTimerId)
              console.log(
                `[nft][src][components][components][modal][waitTransactionReceipt] transactionHash ${transactionHash} is not found`
              )
              err.message = "Transaction hash is not found."
              isWaitRunning = false
              if (callback) {
                callback(err, null)
              }
              return
            }

            if (tx.blockNumber == null) {
              if (timeOut > 0) {
                timeOutCounter++
                if (timeOutCounter >= timeOut) {
                  clearInterval(getReceiptTimerId)
                  isWaitRunning = false
                  err.message = "Timeout while waiting transaction to confirm."
                  if (callback) {
                    callback(err, null)
                  }
                  return
                } else {
                  console.log(
                    `[nft][src][components][components][modal][waitTransactionReceipt] transactionHash 1 ${transactionHash} is pending`
                  )
                  isWaitRunning = false
                  return
                }
              } else {
                console.log(
                  `[nft][src][components][components][modal][waitTransactionReceipt] transactionHash 2 ${transactionHash} is pending`
                )
                isWaitRunning = false
                return
              }
            }

            // Transaction confirm
            web3.eth
              .getTransactionReceipt(transactionHash)
              .then(async function (web3TxReceipt) {
                clearInterval(getReceiptTimerId)
                isWaitRunning = false
                if (web3TxReceipt == null) {
                  console.log(
                    `[nft][src][components][components][modal][waitTransactionReceipt] transactionHash 2 ${transactionHash} is not found`
                  )
                  swal({
                    icon: "error",
                    title: "Failed",
                    text: "Transaction Failed. Please try again!",
                    showConfirmButton: true,
                    showCancelButton: false,
                    closeOnClickOutside: true
                  })
                  setIsAlreadyApproved(false)
                }
                console.log(
                  `[nft][src][components][components][modal][waitTransactionReceipt] Transaction receipt ${transactionHash}: ${web3TxReceipt}`
                )
                if (web3TxReceipt.status != false) {
                  if (actionType == "approve") {
                    swal({
                      icon: "success",
                      title: "Approved",
                      text: "Transaction Successful",
                      showConfirmButton: true,
                      showCancelButton: false,
                      closeOnClickOutside: true
                    })
                    setIsAlreadyApproved(true)
                  } else if (actionType == "pay") {
                    //⚠️ ⚠️ ⚠️ Payment Update API to be done here! ⚠️ ⚠️ ⚠️//

                    let apiObj = {
                      tx_hash: transactionHash,
                      user_email: loginUserMail,
                      user_wallet: connectedWallet,
                      amount_paid: amountToPayInUSD,
                      paid_token: payToken,
                      NFTs_count: 1,
                      user_id: userDetails?.loginUserId ?? "",
                      amount: discountObj?.status
                        ? balance - discountObj?.amount ?? 0
                        : balance,
                      tokens: Array.isArray(pendingPayment?.data)
                        ? (pendingPayment?.data ?? []).map(
                            orderItem => orderItem?.token ?? ""
                          )
                        : [],
                      promo_code: promoCode
                    }

                    ;(!promoCode.length || !(discountObj?.balance ?? 0)) &&
                      delete apiObj.promo_code

                    axios
                      .post(`${BaseUrl}/payWithWallet`, apiObj, {
                        headers: {
                          Authorization: `Bearer ${userDetails?.loginUserToken}`
                        }
                      })
                      .then(response => {
                        if (response) {
                          swal({
                            icon: "success",
                            title: "Success",
                            text: "Payment Successful",
                            showConfirmButton: true,
                            showCancelButton: false,
                            allowOutsideClick: true
                          }).then(() => {
                            setIsPayTxAvailable(true)
                            refreshPage()
                          })
                        }
                      })
                    navigate("/thank-you")
                  }
                }
              })
              .catch(function (error) {
                if (error?.response?.status == 401) {
                  dispatch(updateUserDetailsRedux({}))
                  dispatch(setConnectedWallet(""))
                  localStorage.clear()
                  navigate("/login")
                }
                clearInterval(getReceiptTimerId)
                isWaitRunning = false
                console.log(
                  `[nft][src][components][components][modal][waitTransactionReceipt] Error on transaction receipt 1 ${transactionHash}: ${error}`
                )
                swal({
                  icon: "error",
                  title: "Cancelled",
                  text: error.message,
                  showConfirmButton: true,
                  showCancelButton: false,
                  closeOnClickOutside: true
                })
                setIsAlreadyApproved(false)
              })
          })
          .catch(function (error) {
            clearInterval(getReceiptTimerId)
            console.log(
              `[nft][src][components][components][modal][waitTransactionReceipt] Error on transaction receipt 2 ${transactionHash}: ${error}`
            )
            swal({
              icon: "error",
              title: "Cancelled",
              text: error.message,
              showConfirmButton: true,
              showCancelButton: false,
              closeOnClickOutside: true
            })
            setIsAlreadyApproved(false)
          })
      }
    }, 1000)
  }

  const waitNcashTransactionReceipt = async (
    transactionHash,
    actionType,
    timeOut,
    callback
  ) => {
    console.log(
      "[nft][src][components][components][modal][waitNcashTransactionReceipt] transactionHash",
      transactionHash
    )
    var web3 = new Web3(window.ethereum)
    let self = this

    let timeOutCounter = 0
    let err = {
      message: ""
    }

    let isWaitRunning = false

    let getReceiptTimerId = setInterval(function () {
      if (isWaitRunning == false) {
        isWaitRunning = true
        web3.eth
          .getTransaction(transactionHash)
          .then(function (tx) {
            if (tx == null) {
              clearInterval(getReceiptTimerId)
              console.log(
                `[nft][src][components][components][modal][waitTransactionReceipt] transactionHash ${transactionHash} is not found`
              )
              err.message = "Transaction hash is not found."
              isWaitRunning = false
              if (callback) {
                callback(err, null)
              }
              return
            }

            if (tx.blockNumber == null) {
              if (timeOut > 0) {
                timeOutCounter++
                if (timeOutCounter >= timeOut) {
                  clearInterval(getReceiptTimerId)
                  isWaitRunning = false
                  err.message = "Timeout while waiting transaction to confirm."
                  if (callback) {
                    callback(err, null)
                  }
                  return
                } else {
                  console.log(
                    `[nft][src][components][components][modal][waitTransactionReceipt] transactionHash 1 ${transactionHash} is pending`
                  )
                  isWaitRunning = false
                  return
                }
              } else {
                console.log(
                  `[nft][src][components][components][modal][waitTransactionReceipt] transactionHash 2 ${transactionHash} is pending`
                )
                isWaitRunning = false
                return
              }
            }

            // Transaction confirm
            web3.eth
              .getTransactionReceipt(transactionHash)
              .then(async function (web3TxReceipt) {
                clearInterval(getReceiptTimerId)
                isWaitRunning = false
                if (web3TxReceipt == null) {
                  console.log(
                    `[nft][src][components][components][modal][waitTransactionReceipt] ncashTransactionHash 2 ${transactionHash} is not found`
                  )
                  swal({
                    icon: "error",
                    title: "Failed",
                    text: "Transaction Failed. Please try again!",
                    showConfirmButton: true,
                    showCancelButton: false,
                    closeOnClickOutside: true
                  })
                  setIsAlreadyApproved(false)
                }
                console.log(
                  `[nft][src][components][components][modal][waitTransactionReceipt] Transaction receipt ${transactionHash}: ${web3TxReceipt}`
                )
                if (web3TxReceipt.status != false) {
                  if (actionType == "approve") {
                    swal({
                      icon: "success",
                      title: "Approved",
                      text: "Transaction Successful",
                      showConfirmButton: true,
                      showCancelButton: false,
                      closeOnClickOutside: true
                    })
                    setIsAlreadyApproved(true)
                  } else if (actionType == "pay") {
                    //⚠️ ⚠️ ⚠️ Payment Update API to be done here! ⚠️ ⚠️ ⚠️//

                    let apiObj = {
                      tx_hash: transactionHash,
                      user_email: loginUserMail,
                      user_wallet: connectedWallet,
                      amount_paid: amountToPayInUSD,
                      paid_token: ncashPayToken,
                      NFTs_count: 1,
                      user_id: userDetails?.loginUserId ?? "",
                      amount: balance,
                      tokens: Array.isArray(pendingPayment?.data)
                        ? (pendingPayment?.data ?? []).map(
                            orderItem => orderItem?.token ?? ""
                          )
                        : [],
                      promo_code: ""
                    }

                    ;(!promoCode.length || !(discountObj?.balance ?? 0)) &&
                      delete apiObj.promo_code

                    axios
                      .post(`${BaseUrl}/payWithNcash`, apiObj, {
                        headers: {
                          Authorization: `Bearer ${userDetails?.loginUserToken}`
                        }
                      })
                      .then(response => {
                        if (response) {
                          swal({
                            icon: "success",
                            title: "Success",
                            text: "Payment Successful",
                            showConfirmButton: true,
                            showCancelButton: false,
                            allowOutsideClick: true
                          }).then(() => {
                            setIsPayTxAvailable(true)
                            refreshPage()
                          })
                        }
                      })
                    navigate("/thank-you")
                  }
                }
              })
              .catch(function (error) {
                if (error?.response?.status == 401) {
                  dispatch(updateUserDetailsRedux({}))
                  dispatch(setConnectedWallet(""))
                  localStorage.clear()
                  navigate("/login")
                }
                clearInterval(getReceiptTimerId)
                isWaitRunning = false
                console.log(
                  `[nft][src][components][components][modal][waitTransactionReceipt] Error on transaction receipt 1 ${transactionHash}: ${error}`
                )
                swal({
                  icon: "error",
                  title: "Cancelled",
                  text: error.message,
                  showConfirmButton: true,
                  showCancelButton: false,
                  closeOnClickOutside: true
                })
                setIsAlreadyApproved(false)
              })
          })
          .catch(function (error) {
            clearInterval(getReceiptTimerId)
            console.log(
              `[nft][src][components][components][modal][waitTransactionReceipt] Error on transaction receipt 2 ${transactionHash}: ${error}`
            )
            swal({
              icon: "error",
              title: "Cancelled",
              text: error.message,
              showConfirmButton: true,
              showCancelButton: false,
              closeOnClickOutside: true
            })
            setIsAlreadyApproved(false)
          })
      }
    }, 1000)
  }

  const payWithNcashWallet = async () => {
    if (!TNCAgreed) {
      swal({
        text: "Please agree to terms and conditions",
        icon: "warning"
      })
      return
    }

    console.log(
      `[nft][src][components][components][modal][payWithNcashWallet] Initiated`
    )
    console.log(
      `[nft][src][components][components][modal][payWithNcashWallet] Selected Token`
      // nca
    )

    await connectToWallet()

    let web3 = new Web3(window.ethereum)
    let accounts = await web3.eth.getAccounts()
    let ERC20 = await new web3.eth.Contract(erc20Abi, _nCashSelectedToken)
    let userBalance = await ERC20.methods.balanceOf(connectedWallet).call()
    let userBalanceBN = new BigNumber(userBalance)
    let _amountToPay = await web3.utils.toWei(NCashBalance.toString(), "ether")
    let _amountToPayBN = new BigNumber(_amountToPay)
    console.log(_amountToPayBN)

    // let balanceToPay = new BigNumber(ncashCryptoPaymentAmountToPay);
    let res = userBalanceBN.comparedTo(_amountToPayBN)
    setAmountPaid(_amountToPayBN)

    if (res === -1) {
      swal({
        icon: "error",
        title: "Failed",
        text: "Insufficient Funds. Please topup your Balance to proceed for payment",
        showConfirmButton: true,
        showCancelButton: false,
        closeOnClickOutside: true
      })
      return
    }

    try {
      let NitroPayment = await new web3.eth.Contract(
        nitroPaymentAbi,
        constant.NITRO_PAYMENT_CONTRACT
      )

      if (true) {
        // pay with ERC20

        console.log(NCashBalance, "NCashBalance")
        let _amountToPay = await web3.utils.toWei(
          NCashBalance.toString(),
          "ether"
        )
        let _amountToPayBN = _amountToPay.toString()
        console.log(_amountToPayBN)
        await NitroPayment.methods
          .payWithStableCoins(constant.NCASH, _amountToPayBN, 1)
          .send({ from: accounts[0] })
          .on("transactionHash", function (hash) {
            console.log(
              `[nft][src][components][components][modal][payWithWallet] transactionHash`,
              hash
            )
            setIsPayTxAvailable(true)
            let txHash = `${constant.EXPLORER_URL}/tx/${hash}`
            setPayTx(txHash)

            swal({
              icon: "info",
              title: "In progress",
              text: `Transaction Initiated, Please wait!`,
              closeOnClickOutside: false,
              buttons: ["View Transaction"],
              cancel: false,
              confirm: false
            }).then(function () {
              window.location = `${constant.EXPLORER_URL}/tx/${hash}`
            })

            waitNcashTransactionReceipt(
              hash,
              "pay",
              0,
              function (err, receipt) {
                if (err != null) {
                  return
                }
              }
            )
          })
          .on("error", function (error, receipt) {
            console.log(
              `[nft][src][components][components][modal][payWithWallet] transactionHash error 1`,
              error
            )
            swal({
              icon: "error",
              title: "Failed",
              text: "Transaction cancelled. Please try again!",
              showConfirmButton: true,
              showCancelButton: false,
              closeOnClickOutside: true
            })
            return
          })
      }
    } catch (error) {
      console.log(
        `[nft][src][components][components][modal][payWithWallet] transactionHash error 2`,
        error
      )
      swal({
        icon: "error",
        title: "Failed",
        text: "Something went wrong. Please try again!",
        showConfirmButton: true,
        showCancelButton: false,
        closeOnClickOutside: true
      })
    }
  }
  const payWithWallet = async () => {
    if (!TNCAgreed) {
      swal({
        text: "Please agree to terms and conditions",
        icon: "warning"
      })
      return
    }

    console.log(
      `[nft][src][components][components][modal][payWithWallet] Initiated`
    )
    console.log(
      `[nft][src][components][components][modal][payWithWallet] Selected Token`,
      payToken
    )

    await connectToWallet()

    let web3 = new Web3(window.ethereum)
    let accounts = await web3.eth.getAccounts()
    let ERC20 = await new web3.eth.Contract(erc20Abi, payToken)
    let userBalance = await ERC20.methods.balanceOf(connectedWallet).call()
    let userBalanceBN = new BigNumber(userBalance)
    let balanceToPay = new BigNumber(cryptoPaymentAmount)
    let res = userBalanceBN.comparedTo(balanceToPay)
    setAmountPaid(cryptoPaymentAmount)

    if (res === -1) {
      swal({
        icon: "error",
        title: "Failed",
        text: "Insufficient Funds. Please topup your Balance to proceed for payment",
        showConfirmButton: true,
        showCancelButton: false,
        closeOnClickOutside: true
      })
      return
    }

    try {
      let NitroPayment = await new web3.eth.Contract(
        nitroPaymentAbi,
        constant.NITRO_PAYMENT_CONTRACT
      )

      if (selectedToken == 1 || selectedToken == 2 || selectedToken == 3) {
        // pay with ERC20

        console.log(amountToPayWrapped, "amountToPayWrapped")
        await NitroPayment.methods
          .payPending(payToken, amountToPayWrapped.toString(), 1)
          .send({ from: accounts[0] })
          .on("transactionHash", function (hash) {
            console.log(
              `[nft][src][components][components][modal][payWithWallet] transactionHash`,
              hash
            )
            setIsPayTxAvailable(true)
            let txHash = `${constant.EXPLORER_URL}/tx/${hash}`
            setPayTx(txHash)

            swal({
              icon: "info",
              title: "In progress",
              text: `Transaction Initiated, Please wait!`,
              closeOnClickOutside: false,
              buttons: ["View Transaction"],
              cancel: false,
              confirm: false
            }).then(function () {
              window.location = `${constant.EXPLORER_URL}/tx/${hash}`
            })

            waitTransactionReceipt(hash, "pay", 0, function (err, receipt) {
              if (err != null) {
                return
              }
            })
          })
          .on("error", function (error, receipt) {
            console.log(
              `[nft][src][components][components][modal][payWithWallet] transactionHash error 1`,
              error
            )
            swal({
              icon: "error",
              title: "Failed",
              text: "Transaction cancelled. Please try again!",
              showConfirmButton: true,
              showCancelButton: false,
              closeOnClickOutside: true
            })
            return
          })
      } else if (
        selectedToken == 4 ||
        selectedToken == 5 ||
        selectedToken == 6
      ) {
        // pay with stable coin
        await NitroPayment.methods
          .payWithStableCoins(payToken, cryptoPaymentAmount.toString(), 1)
          .send({ from: accounts[0] })
          .on("transactionHash", function (hash) {
            console.log(
              `[nft][src][components][components][modal][payWithWallet] transactionHash`,
              hash
            )
            setIsPayTxAvailable(true)
            let txHash = `${constant.EXPLORER_URL}/tx/${hash}`
            setPayTx(txHash)

            swal({
              icon: "info",
              title: "In progress",
              text: `Transaction Initiated, Please wait!`,
              closeOnClickOutside: false,
              buttons: ["View Transaction"],
              cancel: false,
              confirm: false
            }).then(function () {
              window.location = `${constant.EXPLORER_URL}/tx/${hash}`
            })

            waitTransactionReceipt(hash, "pay", 0, function (err, receipt) {
              if (err != null) {
                return
              }
            })
          })
          .on("error", function (error, receipt) {
            console.log(
              `[nft][src][components][components][modal][payWithWallet] transactionHash error 1`,
              error
            )
            swal({
              icon: "error",
              title: "Failed",
              text: "Transaction cancelled. Please try again!",
              showConfirmButton: true,
              showCancelButton: false,
              closeOnClickOutside: true
            })
            return
          })
      }
    } catch (error) {
      console.log(
        `[nft][src][components][components][modal][payWithWallet] transactionHash error 2`,
        error
      )
      swal({
        icon: "error",
        title: "Failed",
        text: "Something went wrong. Please try again!",
        showConfirmButton: true,
        showCancelButton: false,
        closeOnClickOutside: true
      })
    }
  }

  const refreshPage = () => {
    window.location.reload()
  }

  const getNCashAmount = balanceAmt => {
    axios
      .get(
        "https://api.coingecko.com/api/v3/simple/price?ids=nucleus-vision&vs_currencies=usd"
      )
      .then(response => {
        if (response.status === 200) {
          setNcashRate(response.data["nucleus-vision"].usd)

          setCryptoPaymentAmountToPay(
            Math.trunc(
              (balance - balance * 0.15) / response.data["nucleus-vision"].usd
            ).toString()
          )
          setNcashCryptoPaymentAmountToPay(
            Math.trunc(
              (balanceAmt - balanceAmt * 0.15) /
                response.data["nucleus-vision"].usd
            ).toString()
          )
          setNCashBalance(
            Math.trunc(
              (balanceAmt - balanceAmt * 0.15) /
                response.data["nucleus-vision"].usd
            )
          )
          setTotalNcash(
            Math.trunc(balanceAmt / response.data["nucleus-vision"].usd)
          )
          selectThePaymentToken(constant.NCASH, NCashBalance)
        }
      })
  }

  const getDeviceCount = () => {
    return (pendingPayment?.data ?? [])
      .map(orderItem => orderItem?.device_count ?? 0)
      .reduce((pval, cval) => pval + cval, 0)
  }

  const renderDeviceCount = () => {
    const totalDeviceCount = getDeviceCount()

    const categorizedOrdersList = Object.entries(categorizedOrders).map(
      (entry, index) => {
        if (entry[1].length) {
          return (
            <li key={entry[0] + index}>
              {`${entry[0]} -
      ${entry[1].reduce((pval, cval) => pval + cval?.device_count ?? 0, 0)}`}
            </li>
          )
        } else return null
      }
    )

    return (
      <>
        <h1 className="payment-bar-info">
          <span className="mrp-cross">Null</span>

          {totalDeviceCount}
        </h1>
        <ul>{categorizedOrdersList}</ul>
      </>
    )
  }

  const renderTotalDueAmount = () => {
    let totalDueAmount = (pendingPayment?.data ?? [])
      .map(
        orderItem =>
          (orderItem?.payment_gross ?? 0) + (orderItem?.pending_amount ?? 0)
      )
      .reduce((pval, cval) => pval + cval, 0)
    totalDueAmount = totalDueAmount
    return isFloat(totalDueAmount) ? totalDueAmount : addCommas(totalDueAmount)
  }

  const renderPaidAmount = () => {
    let paidAmount = (pendingPayment?.data ?? [])
      .map(orderItem => orderItem?.payment_gross ?? 0)
      .reduce((pval, cval) => pval + cval, 0)
    paidAmount = paidAmount
    return isFloat(paidAmount) ? paidAmount : addCommas(paidAmount)
  }

  const renderBalanceAmount = () => {
    const { strikeOffAmount, balanceAmount } = getBalance()
    return (
      <>
        <span className="mrp-cross visible">
          $
          {isFloat(strikeOffAmount)
            ? strikeOffAmount
            : addCommas(strikeOffAmount)}
        </span>
        ${isFloat(balanceAmount) ? balanceAmount : addCommas(balanceAmount)}
      </>
    )
  }

  useEffect(() => {
    getPendingPayments()
    checkIfWalletAlreadyConnected()
  }, [])

  useEffect(() => {
    getNCashAmount(balance)
  }, [balance])

  useEffect(() => {
    if (pendingPayment) {
      const { balanceAmount } = getBalance()
      setBalance(balanceAmount)
    } else {
      setBalance(0)
    }
  }, [JSON.stringify(pendingPayment)])

  useEffect(() => {
    handleSelectToken({
      target: {
        value: selectedToken
      }
    })
  }, [JSON.stringify(discountObj)])

  // console.log(NCashBalance, "ncash balance");

  return (
    <div>
      <div className="summary-payment mt-120pxx container">
        <h2 className="content-head-pending-payment ">Order Summary</h2>
        <div className="payment-bar mt-2">
          <div>
            <p>
              Device count &nbsp;
              <Tooltip title="Number of devices ordered">
                <FaIcons.FaExclamationCircle />
              </Tooltip>
            </p>
            {renderDeviceCount()}
          </div>
          <div>
            <p>
              Total Due &nbsp;{" "}
              <Tooltip title="Total cost">
                <FaIcons.FaExclamationCircle />
              </Tooltip>
            </p>
            <h1 className="payment-bar-info">
              <span className="mrp-cross">Null</span>${renderTotalDueAmount()}
            </h1>
          </div>
          <div>
            <p>
              Paid &nbsp;{" "}
              <Tooltip title="Total paid amount at the time of pre-order">
                <FaIcons.FaExclamationCircle />
              </Tooltip>
            </p>
            <h1 className="payment-bar-info">
              <span className="mrp-cross">Null</span>${renderPaidAmount()}
            </h1>
          </div>
          <div>
            <p>
              Balance &nbsp;
              <Tooltip title="Total outstanding payable amount">
                <FaIcons.FaExclamationCircle />
              </Tooltip>
            </p>
            <h1
              style={{ textAlign: "left" }}
              className=" payment-bar-info text-left">
              {renderBalanceAmount()}
            </h1>
          </div>
        </div>
      </div>

      {/* PAYMENT METHODS */}
      <div className="pending-pay-page container">
        <h2>Select Payment Method</h2>
        <div className="pay-details-boxes">
          <div className="pay-box">
            <h3 className="mb-2">Apply Coupon</h3>

            <div className="promo-bill-section">
              <div className="promo-section">
                <Form
                  onFinish={discountObj ? removeDiscount : applyPromoCode}
                  autoComplete="off"
                  form={form}
                  layout="inline">
                  <div className="apply-promo mb-2">
                    <Form.Item
                      name="promoCode"
                      normalize={e => e.trim().toUpperCase()}
                      rules={
                        discountObj
                          ? []
                          : [
                              {
                                required: true,
                                message: "Please enter promo code to apply"
                              }
                            ]
                      }>
                      <input
                        type="text"
                        placeholder="Enter Promo Code"
                        className="input-style"
                        onChange={e =>
                          setPromoCode(e.target.value.trim().toUpperCase())
                        }
                        disabled={discountObj}
                      />
                    </Form.Item>

                    <button
                      type="submit"
                      id="pay-now-b"
                      style={{ maxHeight: "36px" }}
                      className="btn-brand btn-active">
                      {discountObj ? "Remove" : "Apply"}
                    </button>
                  </div>
                </Form>

                <p
                  className="line-height1 text-center"
                  style={{ fontWeight: "bold" }}>
                  <small style={{ fontSize: "17px" }}>
                    {discountObj?.message ?? ""}
                  </small>
                  <br />
                  <br />
                  <br />
                  <small className="light mt-2 mb-0">
                    (Promo code discount is applicable ONLY on Premium and Top
                    NFM purchases)
                  </small>
                </p>
              </div>
              {/* BILL DETAILS */}
              <div className="bill-detail-section">
                <h4 className="mb-2">Bill Details</h4>
                <div className="dfjb">
                  <p className="mb-0 light-weight">Total Amount</p>
                  <p className="mb-0">
                    ${!isFloat(balance) ? addCommas(balance || 0) : balance}
                  </p>
                </div>
                <div className="dfjb">
                  <p className="light-weight">Discount</p>
                  <p>
                    -$
                    {!isFloat(discountObj?.amount ?? 0)
                      ? addCommas(discountObj?.amount ?? 0)
                      : discountObj?.amount ?? 0}
                  </p>
                </div>
                <div className="dfjb borded-tb py-2">
                  <p className="mb-0 light-weight">Payable Amount</p>
                  <p className="mb-0">
                    $
                    {!isFloat(balance - (discountObj?.amount ?? 0))
                      ? addCommas(balance - (discountObj?.amount ?? 0))
                      : balance - (discountObj?.amount ?? 0)}
                  </p>
                </div>
              </div>
            </div>
            {/* Terms */}
            <div className="d-flex align-items-center justify-content-center margin-terms">
              <input
                type="checkbox"
                checked={TNCAgreed}
                onChange={() => setTNCAgreed(prevState => !prevState)}
              />
              <p className="mb-0 ml-2">
                I agree to{" "}
                <a href="/terms" target="_blank" className="color-primary">
                  Terms & Conditions
                </a>
              </p>
            </div>
            <h3 className="mb-2">Payment methods</h3>

            {/* PAY WITH CRYPTO */}

            <div className="all-three-payment">
              {/* Pay with Coinbase */}
              {/* <div className="one-section-payment d-flex align-items-center justify-content-between">
                <div className="text-left flex-1">
                  <h6 className="mb-1">Pay with Crypto</h6>
                  <p className="max-width-paragraph">
                    <small className="light-weight">
                      (BTC, BCH, ETH, DAI, DOGE, LTC)
                    </small>
                    <br></br>
                    <small className="light-weight">
                      If paying using USDC,
                    </small>
                    <br />
                    <small className="light-weight">
                      please send through ETH network only.
                    </small>
                    <br />
                    <br />

                    <small className="light-weight">
                      Powered by <b>coinbase</b>
                    </small>
                  </p>
                </div>
                <div className="">
                  <button
                    className="pay-now-small less-padding-button"
                    id="pay-now-btn"
                    // style={paymentInProgress ? { filter: "brightness(0.65)" } : null}
                    onClick={payWithCoinbase}>
                    Pay Now
                  </button>
                </div>
              </div> */}

              {/* PAY WITH FIAT */}
              {/* <div className="one-section-payment d-flex align-items-center justify-content-between">
                <div className="text-left">
                  <h6 className="mb-1">Pay with Fiat</h6>
                  <p>
                    <small className="light-weight">
                      (Pay with Credit/Debit cards)
                    </small>
                  </p>
                </div>
                <div className="">
                  <button
                    className="pay-now-small less-padding-button"
                    id="pay-now-btn"
                    // style={paymentInProgress ? { filter: "brightness(0.65)" } : null}
                    onClick={payWithFiat}>
                    Pay Now
                  </button>
                </div>
              </div> */}
              {/* PAY WITH METAMASK WALLET NEW */}
              <div className="one-section-pay">
                <div className="pay-metamask">
                  <div className=" left-side">
                    <h6 className="mb-2">Pay with Crypto</h6>
                    <div className="use-network text-left">
                      <p
                        className="mb-1"
                        style={
                          {
                            // display: "fle",
                            // justifyContent: "space-between",
                            // width: "100%",
                            // margin: "auto",
                          }
                        }>
                        <small className="light-weight">
                          (Use Avalanche network)
                          <br />
                          {/* (Connect with Metamask) */}
                        </small>
                        <small
                          style={{
                            maxWidth: "50%",
                            wordBreak: "break-word"
                            // marginLeft: "10px",
                          }}>
                          {connectedWallet != "undefined" &&
                          connectedWallet?.length ? (
                            <>
                              {"Connected : " +
                                connectedWallet.slice(0, 5) +
                                ".".repeat(3) +
                                connectedWallet.slice(-4)}
                              &nbsp;&nbsp;
                              <span
                                title="Disconnect Wallet"
                                style={{
                                  display: "inline-block",
                                  color: "white",
                                  cursor: "pointer",
                                  backgroundColor: "#161d30",
                                  width: "25px",
                                  height: "25px",
                                  fontSize: "15px",
                                  borderRadius: "50%",
                                  textAlign: "center"
                                }}
                                onClick={disconnectWallet}>
                                x
                              </span>
                            </>
                          ) : (
                            "(Connect with Metamask)"
                          )}
                        </small>
                      </p>
                    </div>
                  </div>

                  <div className="right-side">
                    <div>
                      {/* {connectedWallet != "undefined" && */}
                      {/* connectedWallet?.length > 0 && ( */}
                      {/* <p className="mb-0">
                        <small>Please Choose currency :</small>
                      </p> */}
                      {/* // )} */}
                      {connectedWallet != "undefined" &&
                        connectedWallet?.length > 0 && (
                          <div className="dfj left-text">
                            <p className="mb-0">
                              <small>Please Choose currency :</small>
                            </p>
                            <select
                              className="currency-dropdown"
                              value={selectedToken}
                              onChange={handleSelectToken}>
                              <option value={0} defaultChecked>
                                - select -
                              </option>
                              <option value={1}>WBTC.e</option>
                              <option value={2}>WETH.e</option>
                              <option value={3}>WAVAX</option>
                              <option value={4}>DAI.e</option>
                              <option value={5}>USDC.e</option>
                              <option value={6}>USDT.e</option>
                            </select>
                          </div>
                        )}
                    </div>
                    {/* AMOUNT TO SHOW WHEN SELECTED  */}
                    <div className="mt-3 ">
                      {isWalletConnected == true
                        ? selectedToken != 0 && (
                            <span className="d-block mb ">
                              {paymentAmountToShow} {paymentTokenToshow}
                            </span>
                          )
                        : null}
                    </div>
                  </div>
                </div>
                <div className=" text-right flex-end-style approve-btn">
                  {isWalletConnected == true && isAlreadyApproved == true && (
                    <>
                      <button
                        className="btn-brand btn-active mx-w  "
                        // id="pay-now-btn"
                        onClick={payWithWallet}>
                        Pay with wallet
                      </button>
                    </>
                  )}

                  {isWalletConnected == true &&
                    isAlreadyApproved == false &&
                    connectedWallet != "undefined" && (
                      <>
                        <button
                          className="btn-brand btn-active mx-w  "
                          // id="pay-now-btn"
                          onClick={approveNFTPaymentContract}>
                          Approve
                        </button>
                      </>
                    )}

                  {(connectedWallet == "undefined" ||
                    isWalletConnected == false) && (
                    <button
                      className="btn-brand btn-active mx-w "
                      // id="pay-now-btn"
                      onClick={connectToWallet}>
                      Connect Wallet
                    </button>
                  )}
                </div>
              </div>
              {/* PAY WITH EMI  */}
              {/* <div className="one-section-pay">
                <div className="pay-metamask">
                  <div className=" left-side">
                    <h6 className="mb-2">Pay with EMI</h6>
                    <div className="use-network text-left">
                      <p className="mb-1">
                        <small className="light-weight">
                          (Buy NFM with 6 months smart contract)
                          <br />
                        </small>
                        <small
                          style={{
                            maxWidth: "50%",
                            wordBreak: "break-word"
                            // marginLeft: "10px",
                          }}>
                          {connectedWallet != "undefined" &&
                          connectedWallet?.length ? (
                            <>
                              {"Connected : " +
                                connectedWallet.slice(0, 5) +
                                ".".repeat(3) +
                                connectedWallet.slice(-4)}
                              &nbsp;&nbsp;
                              <span
                                title="Disconnect Wallet"
                                style={{
                                  display: "inline-block",
                                  color: "white",
                                  cursor: "pointer",
                                  backgroundColor: "#161d30",
                                  width: "25px",
                                  height: "25px",
                                  fontSize: "15px",
                                  borderRadius: "50%",
                                  textAlign: "center"
                                }}
                                onClick={disconnectWallet}>
                                x
                              </span>
                            </>
                          ) : (
                            "(Connect with Metamask)"
                          )}
                        </small>
                      </p>
                    </div>
                  </div>
                </div>
                <div className=" text-right flex-end-style approve-btn">
                  {isWalletConnected == true && isAlreadyApproved == true && (
                    <>
                      <button
                        className="btn-brand btn-active mx-w  "
                        // id="pay-now-btn"
                        onClick={payWithWallet}>
                        Pay with wallet
                      </button>
                    </>
                  )}

                  {isWalletConnected == true &&
                    isAlreadyApproved == false &&
                    connectedWallet != "undefined" && (
                      <>
                        <button
                          className="btn-brand btn-active mx-w  "
                          // id="pay-now-btn"
                          onClick={approveNFTPaymentContract}>
                          Approve
                        </button>
                      </>
                    )}

                  {(connectedWallet == "undefined" ||
                    isWalletConnected == false) && (
                    <button
                      className="btn-brand btn-active mx-w  "
                      // id="pay-now-btn"
                      onClick={connectToWallet}>
                      Connect Wallet
                    </button>
                  )}
                </div>
              </div> */}

              {/* PAY WITH NCash */}
              {/* <div className="ncash-pay-box">
                <div className="ncash-pay">
                  <div className="text-left">
                    <h6 className="mb-2">Pay with NCash</h6>
                    <p
                      className="mb-1"
                    >
                      <small className="light-weight">
                        (Use Avalanche network)
                        <br />
                        
                      </small>
                      <small
                        style={{
                          maxWidth: "50%",
                          wordBreak: "break-word",
                          // marginLeft: "10px",
                        }}
                      >
                        {connectedWallet != "undefined" &&
                        connectedWallet?.length ? (
                          <>
                            {"Connected : " +
                              connectedWallet.slice(0, 5) +
                              ".".repeat(3) +
                              connectedWallet.slice(-4)}
                            &nbsp;&nbsp;
                            <span
                              title="Disconnect Wallet"
                              style={{
                                display: "inline-block",
                                color: "white",
                                cursor: "pointer",
                                backgroundColor: "#161d30",
                                width: "25px",
                                height: "25px",
                                fontSize: "15px",
                                borderRadius: "50%",
                                textAlign: "center",
                              }}
                              onClick={disconnectWallet}
                            >
                              x
                            </span>
                          </>
                        ) : (
                          "(Connect with Metamask)"
                        )}
                      </small>
                    </p>
                  </div>

                  <div className="right-side-ncash-details">
                    <div className="dfj" style={{ color: "white" }}>
                      <p className="mb-0">
                        <span>Total Ncash :</span>
                        <span>{Math.trunc(balance / nCashRate)}</span>
                      </p>
                      <p className="mb-0">
                        <span>Discount : </span>
                        <span> - 15%</span>
                      </p>
                      <p className="mb-0">
                        <span> NCash : </span>
                        <span>
                          {" "}
                          {Math.trunc((balance - balance * 0.15) / nCashRate)}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="text-right flex-end-style approve-btn">
                  {isWalletConnected == true && isAlreadyApproved == true && (
                    <>
                      <button
                        className="pay-now-small less-padding-button "
                        id="pay-now-btn"
                         onClick={payWithNcashWallet}
                      >
                        Pay with Ncash
                      </button>
                    </>
                  )}
                  {isWalletConnected == true &&
                    isAlreadyApproved == false &&
                    connectedWallet != "undefined" && (
                      <>
                        <button
                          className="pay-now-small less-padding-button "
                          id="pay-now-btn"
                          onClick={approveNcashNFTPaymentContract}
                        >
                          Approve
                        </button>
                      </>
                    )}
                  {(connectedWallet == "undefined" ||
                    isWalletConnected == false) && (
                    <button
                      className="pay-now-small less-padding-button "
                      id="pay-now-btn"
                      onClick={connectToWallet}
                    >

                      Connect wallet

                    </button>
                  )}
                </div>
              </div> */}
            </div>
            {/* <marquee
              vspace="100"
              style={{
                fontWeight: "bold",
                color: "white",
                fontSize: 18,
                fontWeight: 300,
                paddingTop: "10px",
              }}
            >
              Pay with NCash to avail 15% discount.
              <span style={{ display: "inline-block", width: "10vw" }}></span>
              Pay with NCash to avail 15% discount.
              <span style={{ display: "inline-block", width: "10vw" }}></span>
              Pay with NCash to avail 15% discount.
              <span style={{ display: "inline-block", width: "10vw" }}></span>
            </marquee> */}
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  )
}

export default PaymentPage
