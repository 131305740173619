import React from "react"
import "bootstrap/dist/css/bootstrap.min.css"
import { Link } from "@reach/router"
import "../../assets/style.css"
// import { useNavigate } from "@reach/router"
// import { useDispatch } from "react-redux"
// import { Form } from "antd"
import { Images } from "../../Shared/images"
import "../../assets/css/headerfooter.css"
import { IoTUrl } from "../../utils/config"

const Footer = () => {
  // const navigate = useNavigate()
  // const dispatch = useDispatch()
  // const [isEmail, setIsEmail] = useState(false)
  // const [form] = Form.useForm()
  // const onFinish = values => {
  //   const Fobj = {
  //     email: values.email
  //   }

  //   if (!isEmail) {
  //     swal({
  //       // title: "User does not exist!",
  //       text: "Please enter the Credentials",
  //       icon: "error"
  //     })
  //   } else {
  //     var axios = require("axios")
  //     var data = JSON.stringify({
  //       email: values.email
  //     })

  //     var config = {
  //       method: "post",
  //       url: `${BaseUrl}/addToNewsletterList`,
  //       headers: {
  //         "Content-Type": "application/json"
  //       },
  //       data: data
  //     }

  //     axios(config)
  //       .then(function (response) {
  //         if (response.status == 200) {
  //           swal({
  //             title: "Success!",
  //             text: "Congrats! You have successfully subscribed to the Nitro Newsletter.",
  //             icon: "success"
  //           })
  //         } else if (response.status == 404) {
  //           swal({
  //             title: "Error!",
  //             text: "Page not found. Please try again later.",
  //             icon: "error"
  //           })
  //         } else if (response.status == 500) {
  //           swal({
  //             title: "Error!",
  //             text: "Something went wrong. Please try again later.",
  //             icon: "error"
  //           })
  //         } else if (response.status == 503) {
  //           swal({
  //             title: "Error!",
  //             text: "Something went wrong. Please try again later.",
  //             icon: "error"
  //           })
  //         } else {
  //           swal({
  //             title: "Failed",
  //             text: "Your attempt to subscribe to the Nitro Newsletter has failed. Please try again.",
  //             icon: "warning"
  //           })
  //         }
  //         form.resetFields()
  //       })
  //       .catch(function (error) {
  //         if (error?.response?.status == 401) {
  //           dispatch(updateUserDetailsRedux({}))
  //           dispatch(setConnectedWallet(""))
  //           localStorage.clear()
  //           navigate("/login")
  //           return
  //         } else
  //           swal({
  //             // title: "User Already  exist!",
  //             text: error.response.data.message,
  //             icon: "warning"
  //           })
  //       })
  //   }
  // }
  // const toLinks = item => {
  //   window.open(item)
  // }

  // const toSignup = () => {
  //   navigate("/signup")
  // }

  const knowMore = [
    {
      title: "Documentation",
      url: "https://docs.nitro.network/"
    },
    {
      title: "Terms & Conditions",
      url: "/terms"
    },
    {
      title: "Preorder Agreement",
      url: "/privacy"
    },
    {
      title: "Litepaper",
      url: "https://docsend.com/view/pn69hwzgi8hfqs8m"
    },
    {
      title: "Whitepaper",
      url: "https://docsend.com/view/69wukxyj6vin9bvy"
    },
    {
      title: "Resources",
      url: IoTUrl + "/resources"
    }
  ]

  const products = [
    {
      title: "IOT Marketplace",
      url: IoTUrl + "/marketplace"
    },
    {
      title: "NFM Marketplace",
      url: "/buy-nfm"
    },
    {
      title: "Burn Portal",
      url: "/burn"
    },
    {
      title: "Genesis Node",
      url: "https://buy.nitro.network/genesis-node"
    },
    {
      title: "EMI Smart Contract",
      url: "https://buy.nitro.network/EMI"
    }
  ]
  const socialLinks = [
    {
      icon: "fab fa-facebook fa-lg",
      url: "https://www.facebook.com/NitroNetworkHQ/"
    },
    {
      icon: "fab fa-instagram-square",
      url: "https://www.instagram.com/nitro_hq"
    },
    {
      icon: "fab fa-linkedin",
      url: "https://www.linkedin.com/company/nitronetwork"
    },
    {
      icon: "fab fa-twitter",
      url: "https://twitter.com/Nitro_HQ"
    },
    {
      icon: "fab fa-medium",
      url: "https://nitronetwork.medium.com/"
    },

    // {
    //   icon: "fab fa-reddit",
    //   url: "https://www.reddit.com/r/NucleusVision/"
    // },
    {
      icon: "fab fa-telegram",
      url: "https://t.me/NucleusVision_ann"
    },
    {
      icon: "fab fa-youtube",
      url: "https://www.youtube.com/channel/UCJ4LIGyMee0HnAx4rDvX0nw"
    },
    {
      icon: "fab fa-discord",
      url: "https://discord.com/invite/8kQknUCFHs"
    },
    {
      icon: "fab fa-git-square",
      url: "https://github.com/nucleusvision"
    }
  ]

  return (
    <footer className="footer-nitro">
      <div className="footer-nitro__container">
        <div className="footer-nitro__logo-text">
          <Link to="/">
            <img
              style={{ cursor: "pointer" }}
              // onClick={() => window.open("https://nitro.network")}
              src={Images.logoBlack}
              alt="nitro logo"
            />
          </Link>
          <p>
            Nitro Network is building the largest decentralized private
            communication network powered by IoT combined with LoRaWAN and
            3G/4G/5G. This hyperconnected world will enable unlimited use cases
            in our everyday life. Participants of Nitro’s decentralized PCN are
            part of an inclusive ecosystem that they own, operate and earn from.
            Nitro’s PCN will be a game-changer and has its proprietary token,
            $NCash, at its core. On Nitro Network, everybody wins!
          </p>
        </div>

        <div className="footer-nitro__about-links">
          <div className="links products">
            <h2 className="footer-links-head">Our Products</h2>
            {products.map((obj, index) => {
              return (
                <p key={index}>
                  <a href={obj.url} target="_blank" rel="noopener noreferrer">
                    {obj.title}
                  </a>
                </p>
              )
            })}
          </div>
          <div className="links know-more">
            <h2 className="footer-links-head">Know More</h2>
            {knowMore.map((obj, index) => {
              return (
                <p key={index}>
                  <a href={obj.url} target="_blank" rel="noopener noreferrer">
                    {obj.title}
                  </a>
                </p>
              )
            })}
          </div>
        </div>
        <div className="footer-nitro__contact-footer">
          <h3 className="footer-links-head">CONTACT US</h3>
          <p>
            Email:
            <span className="green">
              <a href="mailto:info@nitro.network">info@nitro.network</a>
            </span>
          </p>

          <div className="social-icons">
            {socialLinks.map((link, index) => {
              return (
                <p key={index}>
                  <a
                    href={link.url}
                    aria-label={`Connect us on  our Social Media Handles`}
                    target="_blank"
                    rel="noreferrer">
                    <i className={`${link.icon} fa-lg`}></i>
                  </a>
                </p>
              )
            })}
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
