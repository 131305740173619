import React, { useEffect, useState } from "react"
import { setDefaultBreakpoints } from "react-socks"
import { Link, useNavigate, useLocation } from "@reach/router"
// import useOnclickOutside from "react-cool-onclickoutside"
import swal from "sweetalert"
import { connect, useDispatch } from "react-redux"
import {
  setActiveMenu,
  setConnectedWallet,
  setPages,
  updateUserDetailsRedux
} from "../../redux/actions"
import Cookies from "universal-cookie"
import { defaultPageMetaData, publicRoutes } from "../../Shared"
import "../../assets/css/headerfooter.css"
import { Images } from "../../Shared/images"
import { useLogout } from "../../hooks"
import { IoTUrl, nftURL } from "../../utils/config"
import { allUsecasesRoutes, getPageMetaData } from "../../utils"
import { useRef } from "react"
import { Helmet } from "react-helmet"

const cookies = new Cookies()

setDefaultBreakpoints([{ xs: 0 }, { l: 1199 }, { xl: 1200 }])

export const NavLink = props => (
  <Link
    {...props}
    getProps={({ isCurrent }) => {
      // the object returned here is passed to the
      // anchor element's props
      return {
        className: `${props?.buttonclass ?? ""} ${
          isCurrent ? "active" : "non-active"
        }`
      }
    }}
  />
)

const Header = function ({
  userDetails,
  updateUserDetails,
  setConnectedWalletRedux,
  ...props
}) {
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const logoutHandler = useLogout()

  const [openMenu, setOpenMenu] = React.useState(false)
  // const [openMenu1, setOpenMenu1] = React.useState(false)
  const [openMenu2, setOpenMenu2] = React.useState(false)
  // const [openMenu3, setOpenMenu3] = React.useState(false)
  const [openSubMenu, setOpenSubMenu] = React.useState(null)
  const [isWPRoutes, setIsWPRoutes] = useState(false)
  // const [background, setBackground] = useState("transparent")
  const loginUserpending = localStorage.getItem("loginUserpending")
  // const loginDetails = useSelector(state => state.common.userDetails)
  // const wordPressPages = useSelector(state => state.wordpress.pages)
  const pageMetaData = useRef(defaultPageMetaData)

  const moniterNetworkStatus = () => {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", accountsChanged => {
        cookies.set("wallet", null, { path: "/" })
        cookies.set("wallet_status", 0, { path: "/" })
        window.location.reload()
      })
      window.ethereum.on("chainChanged", chainId => {
        cookies.set("wallet", null, { path: "/" })
        cookies.set("wallet_status", 0, { path: "/" })
        window.location.reload()
      })
    }
  }

  const handleBtnClick2 = () => {
    setOpenMenu2(!openMenu2)
  }
  // const handleBtnClick3 = () => {
  //   setOpenMenu3(!openMenu3)
  // }
  const closeMenu = () => {
    setOpenMenu(false)
  }
  const closeMenu2 = () => {
    setOpenMenu2(false)
  }

  // const getWordPressPages = async () => {
  //   try {
  //     let response = await fetch(
  //       "https://cms.lorvensofttech.com/wp-json/wp/v2/pages"
  //     )
  //     response = await response.json()
  //     let pages = response.map(pageObj => ({
  //       slug: pageObj.slug,
  //       link: pageObj.link,
  //       id: pageObj.id,
  //       html: pageObj.content.rendered
  //     }))
  //     dispatch(setPages(pages))
  //   } catch (error) {
  //     console.log(error, "error")
  //   }
  // }

  const [user, setUser] = useState(userDetails?.loginUser)
  const [showmenu, btn_icon] = useState(false)

  useEffect(() => {
    setUser(userDetails?.loginUser)
  }, [userDetails])

  useEffect(() => {
    pageMetaData.current = getPageMetaData()
    if (location.pathname !== "/") {
      if (
        !publicRoutes.some(routeWord =>
          location.pathname.includes(routeWord)
        ) &&
        !userDetails?.loginUserToken
      ) {
        navigate("/login")
      }
    }
    // location.pathname === "/nest"
    //  ? setBackground("#161d30")s
    //  : setBackground("transparent");
  }, [location.pathname])

  useEffect(() => {
    let filteredLocationPathname = location.pathname

    //filter location path if there is "/" in last of url -> /water/ to /water
    if (
      /[^\w\s]/.test(
        filteredLocationPathname.charAt(filteredLocationPathname.length - 1)
      )
    ) {
      filteredLocationPathname = filteredLocationPathname.replace(
        /[^\w\s]$/,
        ""
      )
    }

    if (allUsecasesRoutes.includes(filteredLocationPathname)) {
      setIsWPRoutes(true)
    }
  }, [location.pathname])

  useEffect(() => {
    moniterNetworkStatus()
    const header = document.querySelector("header")
    const totop = document.getElementById("scroll-to-top")
    const sticky = header?.offsetTop
    const scrollCallBack = window.addEventListener("scroll", () => {
      btn_icon(false)

      if (!header || !totop) return
      if (window.pageYOffset > sticky) {
        header?.classList.add("sticky")
        totop?.classList.add("show")
      } else {
        header?.classList.remove("sticky")
        totop?.classList.remove("show")
      }
      if (window.pageYOffset > sticky) {
        closeMenu()
      }
    })
    const btn = document.querySelector(".icons")
    const nav = document.querySelector(".nav-collapse")
    const fa = document.querySelector(".fa-solid")

    btn?.addEventListener("click", function () {
      if (!nav) return

      nav.classList.toggle("open")
      if (fa.classList.contains("fa-bars")) {
        fa.classList.remove("fa-bars")
        fa.classList.add("fa-xmark")
      } else {
        fa.classList.remove("fa-xmark")
        fa.classList.add("fa-bars")
      }

      document.querySelector("#nav-ul").addEventListener("click", () => {
        if (!nav) return
        if (nav.classList.contains("open")) {
          nav.classList.toggle("open")
          fa.classList.remove("fa-xmark")
          fa.classList.add("fa-bars")
        }
      })
    })

    return () => {
      window.removeEventListener("scroll", scrollCallBack)

      document.querySelector("#nav-ul")?.removeEventListener("click", () => {
        if (nav.classList.contains("open")) {
          nav.classList.toggle("open")
          fa.classList.remove("fa-xmark")
          fa.classList.add("fa-bars")
        }
      })
    }
  }, [])

  const onLogout = () => {
    logoutHandler()
    swal({
      title: "Success!",
      text: "Logged out successfully",
      icon: "success"
    })
    navigate("/")
    // window.location.reload();
  }
  // const toLinks = item => {
  //   window.open(item)
  // }

  if (location.pathname === "/heatmap") return null

  const headerLinks = [
    {
      id: 1,
      title: "Home",
      route: "/",
      submenus: []
    },
    {
      id: 2,
      title: "IoT",
      // route: "https://iot.lorvensofttech.com",
      submenus: [
        {
          title: "Marketplace",
          route: IoTUrl + "/marketplace",
          openInNewTab: true
        },
        {
          title: "Setup Guide",
          route: "/gateway",
          openInNewTab: true
        },
        {
          title: "Sensor Resources",
          route: IoTUrl + "/resources",
          openInNewTab: true
        }
      ]
    },
    {
      id: 3,
      title: "NCash",
      // route: "https://iot.lorvensofttech.com",
      submenus: [
        {
          title: "Buy Ncash",
          route: "/ncash"
        },
        {
          title: "Genesis Node",
          route: "https://buy.nitro.network/genesis-node",
          openInNewTab: true
        },
        {
          title: "Burn",
          route: "/burn"
        },
        {
          title: "Stake",
          route: "https://staking.nitro.network/",
          openInNewTab: true
        },
        {
          title: "Swap",
          route: "https://swap.nitro.network/",
          openInNewTab: true
        }
      ]
    },
    {
      id: 4,
      title: "NFT",
      // route: "https://iot.lorvensofttech.com",
      submenus: [
        {
          title: (
            <p className="new-collection">
              Nitro Bot Army{" "}
              <img
                src={Images.newCollectionGif}
                alt="New collection"
                width={90}
              />{" "}
            </p>
          ),
          route: nftURL,
          openInNewTab: true
        },
        {
          title: "NFM (Origin NFTs)",
          route: "/buy-nfm"
        },
        {
          title: "Buy NFM with Wallet",
          route: "https://buy.nitro.network/buy-nfm",
          openInNewTab: true
        },
        {
          title: "Buy NFM With EMI",
          route: "https://buy.nitro.network/EMI",
          openInNewTab: true
        }
      ]
    },
      {
      id: 5,
      title: "Usecases",
      route: "/usecases",
      submenus: []
    },
    {
      id: 6,
      title: "Blogs",
      route: "/blogs",
      submenus: []
    }
  ]

  const renderSubMenuLink = (submenu, subIndex) => {
    let linkProps = {
      key: subIndex,
      rel: "noreferrer"
    }
    if (submenu.openInNewTab) {
      linkProps = {
        ...linkProps,
        href: submenu.route,
        target: "_blank"
      }
      return <a {...linkProps}>{submenu.title}</a>
    }
    linkProps = {
      ...linkProps,
      to: submenu.route,
      target: "_self"
    }
    return <Link {...linkProps}>{submenu.title} </Link>
  }

  return (
    <div className="header-fixed">
      <Helmet>
        <title>{pageMetaData.current.title}</title>
        <meta name="description" content={pageMetaData.current.description} />
        <meta content="Ncash, Nitro Network" name="keywords" />
      </Helmet>

      {/* This is only for usecases and blog pages */}
      {isWPRoutes && (
        <Helmet>
          {/* <!-- Wordpress CDN --> */}

          <link
            rel="stylesheet"
            id="elementor-icons-css"
            href="https://usecasesqa.nitro.network/wp-content/plugins/elementor/assets/lib/eicons/css/elementor-icons.min.css?ver=5.18.0"
            media="all"
          />
          <link
            rel="stylesheet"
            id="elementor-common-css"
            href="https://usecasesqa.nitro.network/wp-content/plugins/elementor/assets/css/common.min.css?ver=3.11.1"
            media="all"
          />
          <link
            rel="stylesheet"
            href="https://usecasesqa.nitro.network/wp-content/plugins/elementor/assets/css/frontend-lite.min.css?ver=3.11.1"
            media="all"
          />
          {/* <!-- Wordpress CDN --> */}

          <script
            src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js"
            integrity="sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM"
            crossorigin="anonymous"
            async></script>

          <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.6.3/jquery.min.js"></script>

          {/* Slick */}
          <link
            rel="stylesheet"
            type="text/css"
            charset="UTF-8"
            href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick.min.css"
          />
          <link
            rel="stylesheet"
            type="text/css"
            href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick-theme.min.css"
          />

          <script
            src="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick.min.js"
            integrity="sha512-XtmMtDEcNz2j7ekrtHvOVR4iwwaD6o/FUJe6+Zq+HgcCsk3kj4uSQQR8weQ2QVj1o0Pk6PwYLohm206ZzNfubg=="
            crossorigin="anonymous"
            referrerpolicy="no-referrer"></script>

          {/* Script Wordpress */}
          <script src="https://usecasesqa.nitro.network/wp-content/plugins/elementor/assets/js/frontend.min.js?ver=3.11.1"></script>

          {/* Slick CDN */}
        </Helmet>
      )}

      <header>
        {openMenu2 ? <div id="backdrop" onClick={closeMenu2}></div> : null}
        <div className="container mx-width">
          <Link to="/" className="logo">
            <img src={Images.logoBlack} alt="Nitro Network" />
          </Link>
          <nav className="nav-collapse">
            <ul className="dflex" id="nav-ul">
              {headerLinks.map((headerLink, index) => {
                return (
                  <li
                    className="menu-item"
                    key={index}
                    onMouseEnter={() => setOpenSubMenu(index)}
                    onMouseLeave={() => setOpenSubMenu(null)}>
                    {/* If there are submenu open in <p>Tag  */}
                    {headerLink?.route && headerLink.submenus < 1 ? (
                      <NavLink
                        to={headerLink?.route}
                        className="menu-item-link">
                        {headerLink.title}
                      </NavLink>
                    ) : (
                      <p className="header__navlink-text">
                        {" "}
                        {headerLink.title}
                        {headerLink.submenus.length < 1 ? null : (
                          <i className="fa fa-caret-down"></i>
                        )}
                      </p>
                    )}

                    {/* Dropdown Popup */}
                    {headerLink.submenus.length < 1 ? null : (
                      <div
                        className={`header__navlink-relative ${
                          openSubMenu === index ? "active" : ""
                        }`}>
                        {headerLink?.submenus.map((submenu, subIndex) =>
                          renderSubMenuLink(submenu, subIndex)
                        )}
                      </div>
                    )}
                  </li>
                )
              })}
            </ul>
          </nav>

          <div className="mobile-view-nav">
            {userDetails.loginUserToken ? (
              <div className="navbar-item navbar-item1 relative">
                {/* <div ref={ref2}> */}
                <div
                  className="dropdo text-right-mobile"
                  onClick={handleBtnClick2}
                  id="username"
                  style={{ textAlign: "right" }}>
                  <div className="menu-item d-flex">
                    <span className="text-right">Hi {user}</span>
                    <span>
                      <i className="fa fa-caret-down"></i>
                    </span>
                  </div>
                </div>

                {openMenu2 && (
                  <div className="item-dropdown">
                    <div className="dropdown" onClick={closeMenu2}>
                      <NavLink
                        to={
                          loginUserpending == "true"
                            ? "/myaccount/pendingpayments"
                            : "/myaccount/mycollection"
                        }
                        onClick={() => dispatch(setActiveMenu("NFM"))}>
                        {" "}
                        <i className="fa fa-location-arrow"></i> My Account{" "}
                      </NavLink>
                      <NavLink to="/settings">
                        <i className="fa fa-user"></i> My Profile{" "}
                      </NavLink>

                      <NavLink to="/" onClick={onLogout}>
                        <i
                          className="fas fa-sign-out-alt"
                          aria-hidden="true"></i>{" "}
                        Logout
                      </NavLink>
                    </div>
                  </div>
                )}
                {/* </div> */}
              </div>
            ) : (
              <ul className="btns-header mb-0 pl-0" style={{ paddingLeft: 0 }}>
                <li className="menu-item mr-in-desktop">
                  <NavLink
                    to="/login"
                    buttonclass="btn-brand btn-sm btn-transparent">
                    Sign In
                  </NavLink>
                </li>
                <li className="menu-item ml-2">
                  <NavLink
                    buttonclass="btn-brand btn-active btn-sm"
                    to="/verifyEmail">
                    Sign Up
                  </NavLink>
                </li>
              </ul>
            )}
            {!userDetails.loginUserToken ? (
              <div
                className="border mt-0 hide-it"
                style={{ display: "none !important" }}>
                {/* <img src={Images.GlowLine} alt="Glowing Border" /> */}
              </div>
            ) : null}
            <div className="icons">
              {/* <i className="fa fa-bars"></i> --> */}
              <i className="fa-solid fa-bars"></i>
              {/* <i className="fa-solid fa-xmark"></i> */}
            </div>
          </div>
        </div>
      </header>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    userDetails: state.common.userDetails
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updateUserDetails: payload => dispatch(updateUserDetailsRedux(payload)),
    setConnectedWalletRedux: payload => dispatch(setConnectedWallet(payload))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)
