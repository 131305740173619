import React, { useState, useEffect } from "react"
import Footer from "../components/footer"
import "../styles/table.css"
import * as FaIcons from "react-icons/fa"
import { Form, Input, Modal, Select, Tooltip } from "antd"
import { createGlobalStyle } from "styled-components"
import Menu from "./menu"
import { BaseUrl } from "../../utils/config"
import axios from "axios"
import moment from "moment"
import { connect, useDispatch } from "react-redux"
import { updateUserDetailsRedux } from "../../redux/actions"
import { useNavigate } from "@reach/router"
import { nestDurationOptions } from "../../Shared"
import Dropdown from "../components/dropdown"
import { capitalize, isFloat } from "../../utils"
import swal from "sweetalert"
import addCommas from "add-commas"
import { setConnectedWallet } from "../../redux/actions"

const GlobalStyles = createGlobalStyle`
	header#myHeader.navbar.white {
		background: #212428;
	}
`

const customStyles = {
  option: (base, state) => ({
    ...base,
    background: "#212428",
    color: "#fff",
    borderRadius: state.isFocused ? "0" : 0,
    "&:hover": {
      background: "#16181b"
    }
  }),
  menu: base => ({
    ...base,
    background: "#212428 !important",
    borderRadius: 0,
    marginTop: 0
  }),
  menuList: base => ({
    ...base,
    padding: 0
  }),
  control: (base, state) => ({
    ...base,
    padding: 2
  })
}

const NitroActivity = ({ updateUserDetails }) => {
  const [allproducts, setAllproducts] = useState([])
  const loginUserToken = localStorage.getItem("loginUserToken")
  const [tableData, setTableData] = useState([])
  const [duration, setDuration] = useState(nestDurationOptions[3].value)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  useEffect(() => {
    loadTable()
  }, [])

  useEffect(() => {
    if (allproducts && allproducts?.length) {
      let tempArr = [...allproducts]
      tempArr.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
      setAllproducts(tempArr)
    }
  }, [tableData])

  const loadTable = () => {
    const isTemp = localStorage.getItem("loginUserId")
    axios
      .get(`${BaseUrl}/getpayments/${isTemp}`, {
        headers: {
          // 'Content-Type': 'application/json',
          Authorization: `Bearer ${loginUserToken}`
        }
      })
      .then(response => {
        if (response && response.message != "No payments") {
          setAllproducts(response.data.data)
          setTableData(response.data.data)
        } else if (response.status == 401) {
          dispatch(updateUserDetailsRedux({}))
          dispatch(setConnectedWallet(""))
          localStorage.clear()
          navigate("/login")
        } else if (response.status == 404) {
          swal({
            title: "Error!",
            text: "Page not found. Please try again later.",
            icon: "error"
          })
        } else if (response.status == 500) {
          swal({
            title: "Error!",
            text: "Something went wrong. Please try again later.",
            icon: "error"
          })
        } else if (response.status == 503) {
          swal({
            title: "Error!",
            text: "Something went wrong. Please try again later.",
            icon: "error"
          })
        } else {
        }
      })
      .catch(error => {
        if (error?.response?.status === 401) {
          localStorage.clear()
          updateUserDetails({})
          navigate("/login")
        }
      })
  }

  const filtertabledates = value => {
    setDuration(value)

    const startingdate =
      value == 1
        ? moment().subtract(1, "d").format("YYYY-MM-DD")
        : value == 2
        ? moment().subtract(7, "d").format("YYYY-MM-DD")
        : value == 3
        ? moment().subtract(30, "d").format("YYYY-MM-DD")
        : moment().format("YYYY-MM-DD")
    const temData = []
    if (value != 4 && tableData) {
      tableData.map(item => {
        moment(item.createdAt).isAfter(startingdate) && temData.push(item)
      })
    } else {
      setAllproducts(tableData)
    }
    temData.length > 0 ? setAllproducts(temData) : setAllproducts([])
    value == 4 && setAllproducts(tableData)
  }
  return (
    <div className="My-activity-page">
      <div className="sorting">
        <Dropdown
          name="duration"
          id="duration"
          options={nestDurationOptions}
          handleChange={e => {
            filtertabledates(e.target.value)
          }}
          value={duration}
        />
      </div>

      <div className=" no-padding-in-mobile">
        <table className="back-white table-scss table de-table table-rank table-font-size-reduce table-responsive">
          <thead>
            <tr>
              {/* <!-- Added new Class --> */}
              {/* <th scope="col" className="first-head">Collection</th> */}
              <th scope="col" className="right">
                Source{" "}
                <Tooltip title="Method used to make the payment">
                  <FaIcons.FaExclamationCircle />
                </Tooltip>
              </th>

              <th scope="col" className="right">
                Transaction Type{" "}
                <Tooltip title="Currency type used to make the payment">
                  <FaIcons.FaExclamationCircle />
                </Tooltip>
              </th>

              <th scope="col" className="right">
                Transaction ID{" "}
                {/* <Tooltip title="Transaction ID">
                      <FaIcons.FaExclamationCircle />
                    </Tooltip> */}
              </th>

              <th scope="col" className="right">
                Timestamp (UTC)
                {/* <Tooltip title="Timestamp">
                      <FaIcons.FaExclamationCircle />
                    </Tooltip> */}
              </th>

              <th scope="col" className="right">
                Amount (USD)
                <Tooltip title="Transaction amount">
                  <FaIcons.FaExclamationCircle />
                </Tooltip>
              </th>

              <th scope="col" className="right">
                Status{" "}
                <Tooltip title="Payment confirmation status">
                  <FaIcons.FaExclamationCircle />
                </Tooltip>
              </th>
            </tr>
          </thead>
          <tbody>
            {allproducts && allproducts.length > 0 ? (
              allproducts.map((item, i) => {
                return (
                  <tr key={i}>
                    <td scope="row" data-label="Source">
                      {/* <div className="coll_list_pp">
                                              <img className="lazy" src="images/author/author-1.jpg" alt="" />
                                              <i className="fa fa-check"></i>
                                          </div>   */}
                      {/* <!-- Addedd Span tag and class --> */}
                      <span className="address-text-table">
                        {capitalize(item?.source)}
                      </span>
                    </td>
                    {/* <!-- added class --> */}
                    <td className="right" data-label="Transaction Type">
                      {(item?.source ?? "").toLowerCase() === "paypal" ||
                      (item?.source ?? "").toLowerCase() === "stripe"
                        ? "Fiat"
                        : "Crypto"}
                    </td>
                    {/* <td className="d-plus right" data-label="lat">{item.source}</td> */}
                    <td
                      className="d-plus right overflow-text"
                      data-label="Transaction ID">
                      <span className="trx-id">
                        {(item?.charge_id ?? "").length
                          ? item.charge_id
                          : item?.payment_id ?? ""}
                      </span>
                    </td>
                    <td className="right max-time" data-label="Timestamp (UTC)">
                      <span className="time-font">
                        {moment(item.createdAt).format(
                          "DD-MMMM-YYYY hh:mm:ss A"
                        )}
                      </span>
                    </td>
                    <td className="right" data-label="Amount (USD)">
                      ${" "}
                      {!isFloat(item?.amount ?? 0)
                        ? addCommas(item?.amount ?? 0)
                        : item?.amount ?? 0}
                    </td>
                    <td className="right" data-label="Status">
                      {item.payment_status}
                    </td>
                  </tr>
                )
              })
            ) : (
              //  <tr ><h4 className='form-head'></h4></tr>}
              <td colSpan={6} className="text-center">
                No Data{" "}
              </td>
            )}
            {/* <tr ><h2 className='alignCenter '>No Data</h2>  </tr>} */}
          </tbody>
        </table>

        <div className="spacer-double"></div>
      </div>
    </div>
  )
}

const mapDispatchToProps = dispatch => {
  return {
    updateUserDetails: payload => dispatch(updateUserDetailsRedux(payload))
  }
}

export default connect(null, mapDispatchToProps)(NitroActivity)
