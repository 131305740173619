import React, { useEffect, useState } from "react"
import { Images } from "../../Shared/images"
import { Link } from "@reach/router"
import { useDispatch, useSelector } from "react-redux"
import {
  updateUserDetailsRedux,
  setConnectedWallet,
  setActiveMenu
} from "../../redux/actions"
import swal from "sweetalert"

const Sidebar = ({ sidebarShow, handleSidebarShow }) => {
  const dispatch = useDispatch()
  const userDetails = useSelector(state => state.common.userDetails)

  const activeMenu = useSelector(state => state.common?.activeMenu) || "NFM"
  const initialMenuItems = [
    {
      menu: "NFM",
      image: Images.nfmIcon,
      subMenus: [
        {
          route: "/myaccount/mynest",
          title: "My Nest"
        },
        {
          route: "/myaccount/mycollection",
          title: "My Collection"
        },
        {
          route: "/myaccount/myactivity",
          title: "My Activity"
        }
      ]
    },
    {
      menu: "IOT",
      image: Images.homeIcon,
      subMenus: [
        {
          route: "/myaccount/iotorders",
          title: "My Orders"
        },
        {
          route: "/myaccount/pendingOrders",
          title: "Pending Orders"
        },
        {
          route: "/myaccount/analytics",
          title: "Sensor Analytics"
        }
      ]
    },
    {
      menu: "Referral",
      image: Images.referIcon,
      route: "/myaccount/referral"
    },
    {
      menu: "Rewards",
      image: Images.myRewardIcon,
      route: "/myaccount/rewards"
    }
  ]

  const [menuItems, setMenuItems] = useState(initialMenuItems)
  const [viewSubMenu, setViewSubMenu] = React.useState(false)

  useEffect(() => {
    if (userDetails && userDetails.loginUserpending) {
      console.log("running")
      const _menuItems = JSON.parse(JSON.stringify(menuItems))

      _menuItems[0].subMenus = [
        {
          route: "/myaccount/pendingpayments",
          title: "Pending Payment"
        },
        ...initialMenuItems[0].subMenus
      ]
      setMenuItems(_menuItems)
    }
  }, [userDetails])

  return (
    // <div>
    <div
      className={
        sidebarShow ? " active sidebar-floating " : " sidebar-floating BOX"
      }
      //  className="sidebar active sidebar-floating BOX"
    >
      <div className="close-sidebar" onClick={handleSidebarShow}>
        <i className="fa-solid fa-xmark"></i>
      </div>
      <ul className="menus">
        {menuItems.map((menuItem, i) => {
          return (
            <li
              className="nest-menu"
              key={menuItem.menu + i}
              onClick={() => {
                setViewSubMenu(false)
              }}>
              {/* To Run When there are submenu's */}
              {menuItem?.subMenus ? (
                <>
                  <div
                    className={`menu dfjb dfac${
                      activeMenu === menuItem.menu ? " active" : ""
                    }`}
                    onClick={() => dispatch(setActiveMenu(menuItem.menu))}>
                    <div className="left dfac">
                      <p>
                        <img src={menuItem.image} alt="Icons" />
                      </p>
                      <p>{menuItem.menu}</p>
                    </div>
                    <div className="right">
                      <i className="fa-solid fa-angle-down"></i>
                    </div>
                  </div>

                  {menuItem.subMenus.map((submenu, j) => (
                    <div className="submenu" key={submenu.title + i + j}>
                      <Link
                        to={submenu.route}
                        className={`dfjb dfac${
                          window.location.pathname === submenu.route
                            ? " active"
                            : ""
                        }`}>
                        <p className="mb-0">{submenu.title}</p>
                      </Link>
                    </div>
                  ))}
                </>
              ) : (
                <>
                  {/* to Run When there is only Link */}
                  {/* <div> */}

                  <Link
                    to={menuItem.route}
                    className={`menu dfac${
                      activeMenu === menuItem.menu ? " active" : ""
                    }`}
                    onClick={() => dispatch(setActiveMenu(menuItem.menu))}>
                    <div className="left dfac">
                      <p>
                        <img src={menuItem.image} alt="Icons" />
                      </p>
                    </div>
                    <p className="mb-0 w-100">{menuItem.menu}</p>
                  </Link>
                  {/* </div> */}
                </>
              )}

              {/* <Link
                    to={menuItem.route}
                    className={`menu dfjb dfac${
                        window.location.pathname === menuItem.route
                            ? " active"
                            : ""
                    }`}
                >
                    <div className="left dfac">
                        <p>
                            <img src={menuItem.image} alt="Icons" />
                        </p>
                        <p>{menuItem.title}</p>
                    </div>
                    <div className="right">
                        <i className="fa-solid fa-angle-right" ></i>
                    </div>
                </Link> */}
            </li>
          )
        })}
      </ul>

      <div className="logout " style={{ paddingTop: "-1rem" }}>
        <Link
          to="/login"
          onClick={() => {
            dispatch(updateUserDetailsRedux())
            dispatch(setConnectedWallet())
            window.localStorage.clear()
            swal({
              title: "Success!",
              text: "Logged out successfully",
              icon: "success"
            })
          }}
          className="dfac">
          {" "}
          <p>
            <img src={Images.logoutIcon} alt="logout icon" />
            &nbsp; Logout
          </p>
        </Link>
      </div>
    </div>
    // </div>
  )
}

export default Sidebar
