import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import { useLogout } from "../../../hooks"
import { useNavigate } from "@reach/router"
import { IoTAPIUrl, BaseUrl } from "../../../utils/config"

import swal from "sweetalert"
import axios from "axios"

//⚠️ ⚠️ ⚠️ Blockchain Imports Start ⚠️ ⚠️ ⚠️//
import Web3 from "web3"
import Cookies from "universal-cookie"
import BigNumber from "bignumber.js"
import constant from "../../../constants/constants"
import erc20Abi from "../../../assets/blockchain/erc20Abi.json"
import nitroPaymentAbi from "../../../assets/blockchain/nitroPaymentABI.json"
import { convertCurrency } from "../../../utils"

const cookies = new Cookies()

//⚠️ ⚠️ ⚠️ Blockchain Imports End ⚠️ ⚠️ ⚠️//

function PaymentDetails({
  country = null,
  promoCode = "",
  requestOptions = null,
  orderSummary = null,
  selectedAddress = null,
  amountPayable = 0,
  setSelectedAddIdx = () => {},
  setCurrentStepIndex = () => {}
}) {
  const navigate = useNavigate()
  const logoutHandler = useLogout()
  const userDetails = useSelector(state => state.common.userDetails)
  const [isTnCAgreed, setTnCAgreed] = useState(false)

  //⚠️ ⚠️ ⚠️ Blockchain States Declaration Start ⚠️ ⚠️ ⚠️//
  const [web3, setWeb3] = useState(null)
  const [payTx, setPayTx] = useState(null)
  const [payToken, setPayToken] = useState(null)
  const [amountPaid, setAmountPaid] = useState(0)
  const [approvedTx, setApprovedTx] = useState(null)
  const [selectedToken, setSelectedToken] = useState(0)
  const [connectedWallet, setConnectedWallet] = useState("")
  const [amountToPayInUSD, setAmountToPayInUSD] = useState(0)
  const [amountToPayWrapped, setAmoutForWrapped] = useState(0)
  const [isPayTxAvailable, setIsPayTxAvailable] = useState(false)
  const [paymentAmountToShow, setPaymentAmountToShow] = useState(0)
  const [isAlreadyApproved, setIsAlreadyApproved] = useState(false)
  const [paymentTokenToshow, setPaymentTokenToShow] = useState("WBTC")
  const [cryptoPaymentAmount, setCryptoPaymentAmountToPay] = useState(0)
  const [isApprovedTxAvailable, setIsApprovedTxAvailable] = useState(false)

  //⚠️ ⚠️ ⚠️ Blockchain States Declaration End ⚠️ ⚠️ ⚠️//

  const validatePayment = () => {
    if (!selectedAddress) {
      throw new Error("Please choose an address first")
    }
    if (!isTnCAgreed) {
      throw new Error(
        "Sorry, unless you agree to the terms & conditions, the next step is unavailable"
      )
    }
  }

  const handlePayment = (cb = () => {}) => {
    try {
      validatePayment()
      cb()
    } catch (error) {
      if (error?.response?.status === 401) {
        logoutHandler()
      } else {
        swal({
          title: "Error!",
          text: error?.response
            ? error?.response?.data?.message
            : error.message,
          icon: "error"
        })
      }
    }
  }

  const payWithFiat = async () => {
    try {
      const requestData = {
        userId: userDetails?.loginUserId,
        amountPayable,
        country: country?.value,
        currencyCode: country?.currencyCode,
        promoCode,
        paymentMethod: "Stripe",
        tokens: orderSummary?.tokens
      }

      const response = await axios.post(
        `${IoTAPIUrl}/payment`,
        requestData,
        requestOptions
      )
      if (response.status === 200) {
        window.open(response.data.data.paymentUrl, "_blank")
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        logoutHandler()
      } else {
        swal({
          title: "Error!",
          text: error?.response
            ? error?.response?.data?.message
            : error.message,
          icon: "error"
        })
      }
    }
  }

  const payWithCoinbase = async () => {
    try {
      const requestData = {
        userId: userDetails?.loginUserId ?? "",
        amountPayable,
        country: country?.value,
        currencyCode: country?.currencyCode,
        promoCode,
        paymentMethod: "Coinbase",
        tokens: orderSummary?.tokens
      }

      const response = await axios.post(
        `${IoTAPIUrl}/payment`,
        requestData,
        requestOptions
      )
      if (response.status === 200) {
        window.open(response.data.data.paymentUrl, "_blank")
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        logoutHandler()
      } else {
        swal({
          title: "Error!",
          text: error?.response
            ? error?.response?.data?.message
            : error.message,
          icon: "error"
        })
      }
    }
  }

  //⚠️ ⚠️ ⚠️ Blockchain Usage Methods Start  ⚠️ ⚠️ ⚠️//
  const isWalletConnected =
    connectedWallet && connectedWallet.length ? true : false

  const approveNFTPaymentContract = async () => {
    if (isWalletConnected == true) {
      let web3 = new Web3(window.ethereum)
      let accounts = await web3.eth.getAccounts()

      console.log(
        "[nft][src][components][components][modal][approveNFTPaymentContract] Initiated"
      )
      console.log(
        "[nft][src][components][components][modal][approveNFTPaymentContract] Selected Token to Pay",
        payToken
      )

      if (payToken != null) {
        let contractToApprove = constant.NITRO_PAYMENT_CONTRACT
        let valueToApprove =
          "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff"

        let ERC20 = await new web3.eth.Contract(erc20Abi, payToken)

        try {
          await ERC20.methods
            .approve(contractToApprove, valueToApprove)
            .send({ from: accounts[0] })
            .on("transactionHash", function (hash) {
              setIsApprovedTxAvailable(true)
              console.log(
                "[nft][src][components][components][modal][approveNFTPaymentContract] Approve Tx Hash",
                hash
              )
              let txHash = `${constant.EXPLORER_URL}/tx/${hash}`
              setApprovedTx(txHash)

              swal({
                icon: "info",
                title: "In progress",
                text: `Transaction Initiated, Please wait!`,
                closeOnClickOutside: false,
                buttons: ["View Transaction"],
                cancel: false,
                confirm: false
              }).then(function () {
                window.location = `${constant.EXPLORER_URL}/tx/${hash}`
              })

              waitTransactionReceipt(
                hash,
                "approve",
                0,
                function (err, receipt) {
                  if (err != null) {
                    return
                  }
                }
              )
            })
            .on("error", function (error, receipt) {
              console.log(
                "[nft][src][components][components][modal][approveNFTPaymentContract] Approve Tx Error",
                error
              )
              swal({
                icon: "error",
                title: "Failed",
                text: "Transaction cancelled. Please try again!",
                showConfirmButton: true,
                showCancelButton: false,
                closeOnClickOutside: true
              })
              setIsAlreadyApproved(false)
              return
            })
        } catch (error) {
          console.log(
            "[nft][src][components][components][modal][approveNFTPaymentContract] Catch Error",
            error
          )
          swal({
            icon: "error",
            title: "Failed",
            text: "Something went wrong. Please try again!",
            showConfirmButton: true,
            showCancelButton: false,
            closeOnClickOutside: true
          })
          setIsAlreadyApproved(false)
        }
      } else {
        swal({
          text: "Please select your currency to proceed",
          icon: "error"
        })
      }
    } else {
      await getWeb3()
      await connectToWallet()
      swal({
        title: "Failed",
        text: `Please connect your wallet.`,
        icon: "error",
        confirmButtonText: "Ok"
      })
    }
  }

  const isNFTPaymentContractApproved = async (
    selectedToken,
    amountToApprove
  ) => {
    console.log(
      `[nft][src][components][components][modal][isNFTPaymentContractApproved] selectedToken`,
      selectedToken
    )
    await getWeb3()

    let _selectedToken = null

    if (selectedToken == "WBTC.e") {
      _selectedToken = constant.WBTC
    } else if (selectedToken == "WETH.e") {
      _selectedToken = constant.WETH
    } else if (selectedToken == "WAVAX") {
      _selectedToken = constant.WAVAX
    } else if (selectedToken == "DAI.e") {
      _selectedToken = constant.DAI
    } else if (selectedToken == "USDC.e") {
      _selectedToken = constant.USDC
    } else if (selectedToken == "USDT.e") {
      _selectedToken = constant.USDT
    } else {
      _selectedToken = "0x0000000000000000000000000000000000000000"
    }

    setPayToken(_selectedToken)

    if (
      _selectedToken != "null" ||
      _selectedToken != "0x0000000000000000000000000000000000000000"
    ) {
      let erc20 = await new window.web3.eth.Contract(erc20Abi, _selectedToken)

      // balance to Pay
      let balanceToPay = new BigNumber(amountToApprove)

      connectToWallet()

      try {
        if (isWalletConnected == true) {
          if (connectToWallet != null) {
            let isApproved = await erc20.methods
              .allowance(connectedWallet, constant.NITRO_PAYMENT_CONTRACT)
              .call()

            let decimal = await erc20.methods.decimals().call()

            let baseBN = new BigNumber(10)
            let decimalBN = new BigNumber(decimal)

            let exponentiate = baseBN.exponentiatedBy(decimalBN)

            let isApprovedBN = new BigNumber(isApproved)
            let balanceToPayBN = new BigNumber(balanceToPay).multipliedBy(
              exponentiate
            )

            let res = isApprovedBN.comparedTo(balanceToPayBN)
            console.log(
              `[nft][src][components][components][modal][isNFTPaymentContractApproved] res`,
              res
            )

            if (res === 0 || res == 1) {
              setIsAlreadyApproved(true)
            } else if (res == -1) {
              setIsAlreadyApproved(false)
            }
            console.log(
              `[nft][src][components][components][modal][isNFTPaymentContractApproved] Is Approved`,
              isAlreadyApproved
            )
          }
        } else {
          setIsAlreadyApproved(false)
        }
      } catch (error) {
        console.log(error)
      }
    } else {
      setIsAlreadyApproved(false)
    }
  }

  const connectToWallet = async () => {
    console.log(
      `[nft][src][components][components][pages][paymentpage][connectToWallet] connectToWallet`
    )

    if (typeof window.ethereum !== "undefined") {
      let accounts = await window.ethereum.request({
        method: "eth_requestAccounts"
      })
      window.web3 = new Web3(window.ethereum)
      setWeb3(window.web3)

      let chainId = await window.web3.eth.getChainId()

      if (chainId !== parseInt(constant.CHAIN_ID)) {
        swal({
          title: "Invalid Network!",
          text: `Switch your network to ${constant.NETWORK_NAME}`,
          icon: "error",
          confirmButtonText: "Ok"
        })
        return
      }

      cookies.set("wallet", accounts[0], { path: "/" })
      cookies.set("wallet_status", 1, { path: "/" })
      setConnectedWallet(accounts[0])
      // connect to wallet api

      let apiData = {
        wallet_address: accounts[0]
      }
      axios
        .put(
          `${BaseUrl}/addwallet?user_id=${userDetails.loginUserId}`,
          apiData,
          {
            headers: {
              Authorization: `Bearer ${userDetails.loginUserToken}`
            }
          }
        )
        .then(response => {
          if (response.status === 200) {
          }
        })
        .catch(error => {
          if (error?.response?.status === 401) {
            logoutHandler()
          }
        })

      setWeb3(window.web3)
    } else {
      cookies.set("wallet", null, { path: "/" })
      cookies.set("wallet_status", 0, { path: "/" })
      setConnectedWallet("")
      setWeb3(null)
      swal({
        title: "Unsupported Browser/Plugin not installed",
        text: `Please Install Metamask wallet and Set network to ${constant.NETWORK_NAME}`,
        icon: "error",
        confirmButtonText: "Ok"
      })
      console.log("Unsupported Browser/Plugin not installed")
    }
  }

  const disconnectWallet = () => {
    axios
      .delete(`${BaseUrl}/removeWallet/${userDetails?.loginUserId}`, {
        headers: {
          Authorization: `Bearer ${userDetails?.loginUserToken}`
        }
      })
      .then(response => {
        cookies.set("wallet", null, { path: "/" })
        cookies.set("wallet_status", 0, { path: "/" })
        setConnectedWallet("")
        setWeb3(null)
        window.location.reload(false)
      })
      .catch(error => {
        if (error?.response?.status == 401) {
          logoutHandler()
        }
      })
  }

  const selectThePaymentToken = async __selectedToken => {
    setSelectedToken(__selectedToken)
    const exchangeRateAPI = "https://exchange-rates.abstractapi.com/v1/live"
    const exchangeRateAPIKey = "5768169547824e0980ea78d78ed5bed5"
    try {
      if (isWalletConnected == true) {
        if (typeof window.ethereum !== "undefined") {
          window.web3 = new Web3(window.ethereum)
          setWeb3(window.web3)
          let NitroPayment = await new window.web3.eth.Contract(
            nitroPaymentAbi,
            constant.NITRO_PAYMENT_CONTRACT
          )

          let _paymentToPay = 0
          let _paymentToShow = 0
          let _token = null
          let __amount = amountPayable
          let _paymentToPayUSD = Math.round(parseFloat(__amount))
          if (country?.currencyCode !== "usd") {
            const res = await axios.get(
              `${exchangeRateAPI}/?api_key=${exchangeRateAPIKey}&base=${country?.currencyCode?.toUpperCase()}&target=USD`
            )
            const exchangeRate = res.data.exchange_rates.USD
            __amount = exchangeRate * amountPayable
            _paymentToPayUSD = Math.round(parseFloat(__amount))
          }
          setAmoutForWrapped(_paymentToPayUSD)
          if (__selectedToken == "1") {
            _paymentToPay = await NitroPayment.methods
              .getPriceToPayBTCUSD(_paymentToPayUSD)
              .call()
            _paymentToShow = _paymentToPay / 100000000
            _token = "WBTC.e"
          } else if (__selectedToken == "2") {
            _paymentToPay = await NitroPayment.methods
              .getPriceToPayETHUSD(_paymentToPayUSD)
              .call()
            _paymentToShow = await window.web3.utils.fromWei(
              _paymentToPay,
              "ether"
            )
            _token = "WETH.e"
          } else if (__selectedToken == "3") {
            console.log("avax option selected")
            _paymentToPay = await NitroPayment.methods
              .getPriceToPayAVAXUSD(_paymentToPayUSD)
              .call()
            console.log(_paymentToPay, "_paymentToPay")
            _paymentToShow = await window.web3.utils.fromWei(
              _paymentToPay,
              "ether"
            )
            _token = "WAVAX"
          } else if (__selectedToken == "4") {
            _paymentToPay = await window.web3.utils.toWei(
              _paymentToPayUSD.toString(),
              "ether"
            )
            _paymentToShow = __amount
            _token = "DAI.e"
          } else if (__selectedToken == "5") {
            let _temp = BigNumber(_paymentToPayUSD)
            _paymentToPay = _temp.multipliedBy("1000000").toString()
            _paymentToShow = __amount
            _token = "USDC.e"
          } else if (__selectedToken == "6") {
            let _temp = BigNumber(_paymentToPayUSD)
            _paymentToPay = _temp.multipliedBy("1000000").toString()
            _paymentToShow = __amount
            _token = "USDT.e"
          } else {
            return
          }
          // const usdAmount = await convertCurrency(
          //   country?.currencyCode,
          //   "usd",
          //   amountPayable
          // )
          setAmountToPayInUSD(__amount)
          setCryptoPaymentAmountToPay(_paymentToPay.toString())
          setPaymentTokenToShow(_token)
          setPaymentAmountToShow(_paymentToShow)
          isNFTPaymentContractApproved(_token, _paymentToPay)
          // isNcashNFTPaymentContractApproved(_ncash_token, _paymentToPay);
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const payWithWallet = async () => {
    console.log(
      `[nft][src][components][components][modal][payWithWallet] Initiated`
    )
    console.log(
      `[nft][src][components][components][modal][payWithWallet] Selected Token`,
      payToken
    )

    await connectToWallet()

    let web3 = new Web3(window.ethereum)
    let accounts = await web3.eth.getAccounts()
    let ERC20 = await new web3.eth.Contract(erc20Abi, payToken)
    let userBalance = await ERC20.methods.balanceOf(connectedWallet).call()
    let userBalanceBN = new BigNumber(userBalance)
    let balanceToPay = new BigNumber(cryptoPaymentAmount)
    let res = userBalanceBN.comparedTo(balanceToPay)
    setAmountPaid(cryptoPaymentAmount)

    if (res === -1) {
      swal({
        icon: "error",
        title: "Failed",
        text: "Insufficient Funds. Please topup your Balance to proceed for payment",
        showConfirmButton: true,
        showCancelButton: false,
        closeOnClickOutside: true
      })
      return
    }

    try {
      let NitroPayment = await new web3.eth.Contract(
        nitroPaymentAbi,
        constant.NITRO_PAYMENT_CONTRACT
      )

      if (selectedToken == 1 || selectedToken == 2 || selectedToken == 3) {
        // pay with ERC20

        console.log(amountToPayWrapped, "amountToPayWrapped")
        await NitroPayment.methods
          .payPending(payToken, amountToPayWrapped.toString(), 1)
          .send({ from: accounts[0] })
          .on("transactionHash", function (hash) {
            console.log(
              `[nft][src][components][components][modal][payWithWallet] transactionHash`,
              hash
            )
            setIsPayTxAvailable(true)
            let txHash = `${constant.EXPLORER_URL}/tx/${hash}`
            setPayTx(txHash)

            swal({
              icon: "info",
              title: "In progress",
              text: `Transaction Initiated, Please wait!`,
              closeOnClickOutside: false,
              buttons: ["View Transaction"],
              cancel: false,
              confirm: false
            }).then(function () {
              window.location = `${constant.EXPLORER_URL}/tx/${hash}`
            })

            waitTransactionReceipt(hash, "pay", 0, function (err, receipt) {
              if (err != null) {
                return
              }
            })
          })
          .on("error", function (error, receipt) {
            console.log(
              `[nft][src][components][components][modal][payWithWallet] transactionHash error 1`,
              error
            )
            swal({
              icon: "error",
              title: "Failed",
              text: "Transaction cancelled. Please try again!",
              showConfirmButton: true,
              showCancelButton: false,
              closeOnClickOutside: true
            })
            return
          })
      } else if (
        selectedToken == 4 ||
        selectedToken == 5 ||
        selectedToken == 6
      ) {
        // pay with stable coin
        await NitroPayment.methods
          .payWithStableCoins(payToken, cryptoPaymentAmount.toString(), 1)
          .send({ from: accounts[0] })
          .on("transactionHash", function (hash) {
            console.log(
              `[nft][src][components][components][modal][payWithWallet] transactionHash`,
              hash
            )
            setIsPayTxAvailable(true)
            let txHash = `${constant.EXPLORER_URL}/tx/${hash}`
            setPayTx(txHash)

            swal({
              icon: "info",
              title: "In progress",
              text: `Transaction Initiated, Please wait!`,
              closeOnClickOutside: false,
              buttons: ["View Transaction"],
              cancel: false,
              confirm: false
            }).then(function () {
              window.location = `${constant.EXPLORER_URL}/tx/${hash}`
            })

            waitTransactionReceipt(hash, "pay", 0, function (err, receipt) {
              if (err != null) {
                return
              }
            })
          })
          .on("error", function (error, receipt) {
            console.log(
              `[nft][src][components][components][modal][payWithWallet] transactionHash error 1`,
              error
            )
            swal({
              icon: "error",
              title: "Failed",
              text: "Transaction cancelled. Please try again!",
              showConfirmButton: true,
              showCancelButton: false,
              closeOnClickOutside: true
            })
            return
          })
      }
    } catch (error) {
      console.log(
        `[nft][src][components][components][modal][payWithWallet] transactionHash error 2`,
        error
      )
      swal({
        icon: "error",
        title: "Failed",
        text: "Something went wrong. Please try again!",
        showConfirmButton: true,
        showCancelButton: false,
        closeOnClickOutside: true
      })
    }
  }

  const refreshPage = () => {
    window.location.reload()
  }

  const waitTransactionReceipt = async (
    transactionHash,
    actionType,
    timeOut,
    callback
  ) => {
    console.log(
      "[nft][src][components][components][modal][waitTransactionReceipt] transactionHash",
      transactionHash
    )
    var web3 = new Web3(window.ethereum)
    let self = this

    let timeOutCounter = 0
    let err = {
      message: ""
    }

    let isWaitRunning = false

    let getReceiptTimerId = setInterval(function () {
      if (isWaitRunning == false) {
        isWaitRunning = true
        web3.eth
          .getTransaction(transactionHash)
          .then(function (tx) {
            if (tx == null) {
              clearInterval(getReceiptTimerId)
              console.log(
                `[nft][src][components][components][modal][waitTransactionReceipt] transactionHash ${transactionHash} is not found`
              )
              err.message = "Transaction hash is not found."
              isWaitRunning = false
              if (callback) {
                callback(err, null)
              }
              return
            }

            if (tx.blockNumber == null) {
              if (timeOut > 0) {
                timeOutCounter++
                if (timeOutCounter >= timeOut) {
                  clearInterval(getReceiptTimerId)
                  isWaitRunning = false
                  err.message = "Timeout while waiting transaction to confirm."
                  if (callback) {
                    callback(err, null)
                  }
                  return
                } else {
                  console.log(
                    `[nft][src][components][components][modal][waitTransactionReceipt] transactionHash 1 ${transactionHash} is pending`
                  )
                  isWaitRunning = false
                  return
                }
              } else {
                console.log(
                  `[nft][src][components][components][modal][waitTransactionReceipt] transactionHash 2 ${transactionHash} is pending`
                )
                isWaitRunning = false
                return
              }
            }

            // Transaction confirm
            web3.eth
              .getTransactionReceipt(transactionHash)
              .then(async function (web3TxReceipt) {
                clearInterval(getReceiptTimerId)
                isWaitRunning = false
                if (web3TxReceipt == null) {
                  console.log(
                    `[nft][src][components][components][modal][waitTransactionReceipt] transactionHash 2 ${transactionHash} is not found`
                  )
                  swal({
                    icon: "error",
                    title: "Failed",
                    text: "Transaction Failed. Please try again!",
                    showConfirmButton: true,
                    showCancelButton: false,
                    closeOnClickOutside: true
                  })
                  setIsAlreadyApproved(false)
                }
                console.log(
                  `[nft][src][components][components][modal][waitTransactionReceipt] Transaction receipt ${transactionHash}: ${web3TxReceipt}`
                )
                if (web3TxReceipt.status != false) {
                  if (actionType == "approve") {
                    swal({
                      icon: "success",
                      title: "Approved",
                      text: "Transaction Successful",
                      showConfirmButton: true,
                      showCancelButton: false,
                      closeOnClickOutside: true
                    })
                    setIsAlreadyApproved(true)
                  } else if (actionType == "pay") {
                    //⚠️ ⚠️ ⚠️ Payment Update API to be done here! ⚠️ ⚠️ ⚠️//

                    let apiObj = {
                      userId: userDetails?.loginUserId,
                      country: country?.value,
                      currencyCode: country?.currencyCode,
                      amountPayable,
                      promoCode,
                      tokens: orderSummary?.tokens,
                      txnHash: transactionHash,
                      userWallet: connectedWallet,
                      tokensPaid: payToken,
                      tokenName: paymentTokenToshow
                    }

                    axios
                      .post(`${IoTAPIUrl}/payWithWallet`, apiObj, {
                        headers: {
                          Authorization: `Bearer ${userDetails?.loginUserToken}`
                        }
                      })
                      .then(response => {
                        if (response) {
                          swal({
                            icon: "success",
                            title: "Success",
                            text: "Payment Successful",
                            showConfirmButton: true,
                            showCancelButton: false,
                            allowOutsideClick: true
                          }).then(() => {
                            setIsPayTxAvailable(true)
                            refreshPage()
                          })
                        }
                      })
                    navigate("/myaccount/iotorders")
                  }
                }
              })
              .catch(function (error) {
                if (error?.response?.status == 401) {
                  logoutHandler()
                }
                clearInterval(getReceiptTimerId)
                isWaitRunning = false
                console.log(
                  `[nft][src][components][components][modal][waitTransactionReceipt] Error on transaction receipt 1 ${transactionHash}: ${error}`
                )
                swal({
                  icon: "error",
                  title: "Cancelled",
                  text: error.message,
                  showConfirmButton: true,
                  showCancelButton: false,
                  closeOnClickOutside: true
                })
                setIsAlreadyApproved(false)
              })
          })
          .catch(function (error) {
            clearInterval(getReceiptTimerId)
            console.log(
              `[nft][src][components][components][modal][waitTransactionReceipt] Error on transaction receipt 2 ${transactionHash}: ${error}`
            )
            swal({
              icon: "error",
              title: "Cancelled",
              text: error.message,
              showConfirmButton: true,
              showCancelButton: false,
              closeOnClickOutside: true
            })
            setIsAlreadyApproved(false)
          })
      }
    }, 1000)
  }

  const renderCryptoPaymentButton = () => {
    console.log(isWalletConnected, "isWalletConnected")
    if (!isWalletConnected) {
      return (
        <button
          className="btn-brand btn-active mx-w btn-sm"
          type="button"
          onClick={() => handlePayment(connectToWallet)}>
          Connect Wallet
        </button>
      )
    } else if (!isAlreadyApproved) {
      return (
        <button
          className="btn-brand btn-active mx-w btn-sm"
          type="button"
          onClick={() => handlePayment(approveNFTPaymentContract)}>
          Approve
        </button>
      )
    } else {
      return (
        <button
          className="btn-brand btn-active mx-w btn-sm"
          type="button"
          onClick={() => handlePayment(payWithWallet)}>
          Pay with Wallet
        </button>
      )
    }
  }

  const getWeb3 = () => {
    if (window.ethereum) {
      try {
        window.web3 = new Web3(window.ethereum)
        setWeb3(window.web3)
      } catch (error) {
        console.log(
          `[nft][src][components][components][modal][getWeb3] Web3 Connection Error`,
          error
        )
      }
    } else {
      setWeb3(null)
    }
  }

  const handleSelectToken = event => {
    try {
      selectThePaymentToken(event.target.value)
      getWeb3()
    } catch (error) {
      console.log(error)
    }
  }
  //⚠️ ⚠️ ⚠️ Blockchain Usage Methods End ⚠️ ⚠️ ⚠️//

  return (
    <>
      <div className="card-ship">
        <div className="row">
          <div className="col-md-12">
            <h4>Shipping Details</h4>
            <div className="ship-address">
              <label>
                <div className="address-information">
                  <div className="address-persnol">
                    <span className="text-md" style={{ marginLeft: 0 }}>
                      {selectedAddress?.name ?? ""}
                    </span>
                    <span className="badge badge-primary-light ">
                      {selectedAddress?.type ?? ""}
                    </span>
                    <span className="text-md ">
                      {`${selectedAddress?.mobileNumber ?? ""}${
                        selectedAddress?.alternateMobileNumber
                          ? ", " + selectedAddress?.alternateMobileNumber
                          : ""
                      }`}
                    </span>
                  </div>
                  <p className="pt-2 text-sm address-field">
                    {selectedAddress?.address ??
                      selectedAddress?.addressLine1 ??
                      ""}{" "}
                    {selectedAddress?.pincode
                      ? `PIN: ${selectedAddress?.pincode}`
                      : ""}
                  </p>
                  <button
                    className="btn-brand btn-transparent color-primary text-uppercase fw-600 mt-3"
                    type="button"
                    onClick={() => {
                      setSelectedAddIdx(-1)
                      setCurrentStepIndex(1)
                    }}>
                    Change Address
                  </button>
                </div>
              </label>
            </div>
          </div>
        </div>
      </div>

      <div className="card-ship">
        <input
          type="checkbox"
          checked={isTnCAgreed}
          onChange={() => setTnCAgreed(!isTnCAgreed)}
        />
        <lable className="text-sm">
          I agree to{" "}
          <a
            href="/terms"
            rel="noreffer noopener"
            target="_blank"
            className="color-primary">
            terms & conditions
          </a>
        </lable>
      </div>

      <div className="card-ship">
        <div className="row">
          <div className="col-lg-12">
            <h4>Pay with Fiat</h4>
            <p>
              <small className="text-xs">(Pay with Credit/Debit cards)</small>{" "}
            </p>
            <div className="row">
              <div className="col-lg-12  pt-5 text-right">
                <button
                  className="btn-brand btn-active mx-w"
                  type="button"
                  onClick={() => handlePayment(payWithFiat)}>
                  Pay Now
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="card-ship">
        <div className="row">
          <div className="col-lg-12">
            <h4>Pay with Crypto</h4>
            <p>
              <small className="text-xs">(BTC, BCH, ETH, DAI, DOGE, LTC)</small>
              <br />
              <small className="text-xs">If paying using USDC,</small>
              <br />
              <small className="text-xs">
                please send through ETH network only.
              </small>
            </p>

            <div className="row">
              <div className="col-lg-12  pt-5 text-right">
                <button
                  className="btn-brand btn-active mx-w"
                  type="button"
                  onClick={() => handlePayment(payWithCoinbase)}>
                  Pay Now
                </button>
              </div>
            </div>
            <p>
              <small className="text-xs">
                Powered by <b>coinbase</b>
              </small>
            </p>
          </div>
        </div>
      </div>

      <div className="card-ship">
        <div className="row">
          <div className="col-lg-12">
            <h4>Pay with Crypto</h4>
            <p>
              <small className="text-xs">(Use Avalanche network)</small>
              <br />

              {isWalletConnected ? (
                <>
                  <small className="text-xs">{`Connected: ${connectedWallet.slice(
                    0,
                    5
                  )}${".".repeat(3)}${connectedWallet.slice(-4)}  `}</small>
                  <span
                    title="Disconnect Wallet"
                    style={{
                      display: "inline-block",
                      color: "white",
                      cursor: "pointer",
                      backgroundColor: "#161d30",
                      width: "25px",
                      height: "25px",
                      fontSize: "15px",
                      borderRadius: "50%",
                      textAlign: "center"
                    }}
                    onClick={disconnectWallet}>
                    x
                  </span>
                </>
              ) : (
                <small className="text-xs">(Connect with Metamask)</small>
              )}
            </p>

            <p>
              {isWalletConnected ? (
                <select
                  className="currency-dropdown"
                  value={selectedToken}
                  onChange={handleSelectToken}>
                  <option value={0} defaultChecked>
                    - select -
                  </option>
                  <option value={1}>WBTC.e</option>
                  <option value={2}>WETH.e</option>
                  <option value={3}>WAVAX</option>
                  <option value={4}>DAI.e</option>
                  <option value={5}>USDC.e</option>
                  <option value={6}>USDT.e</option>
                </select>
              ) : null}
            </p>

            {isWalletConnected ? (
              <p>
                <small className="text-xs">
                  {paymentAmountToShow} {paymentTokenToshow}
                </small>
              </p>
            ) : null}

            <div className="row">
              <div className="col-lg-12  pt-5 text-right">
                {renderCryptoPaymentButton()}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PaymentDetails
