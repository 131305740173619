// This component is meant to be rendered as a child of myAccount Route's component.

import React, { useState, useEffect } from "react"
import { BaseUrl } from "../../utils/config"
import { useNavigate } from "@reach/router"
import { connect, useSelector, useDispatch } from "react-redux"
import {
  updateUserDetailsRedux,
  setPendingPayment,
  setConnectedWallet,
  setActiveMenu
} from "../../redux/actions"
import axios from "axios"
import swal from "sweetalert"
//Style
import "../../assets/css/pendingPayments.css"

//Images
import mineGlobally from "../../assets/images/mineglobalwhite.png"
import weHostYouMine from "../../assets/images/minerwhites.png"
import effortlessEarning from "../../assets/images/effortlesswhite.png"
import collectible from "../../assets/images/collectiblewts.png"
import * as FaIcons from "react-icons/fa"

import Modal from "../components/Modal"

// ⚠️⚠️⚠️ Attention - Please don't make any changes⚠️⚠️⚠️
// ⚠️⚠️⚠️ Blockchain Integration Start ⚠️⚠️⚠️ //

import nftAbi from "../../assets/blockchain/nftAbi.json"
import constant from "../../constants/constants"
import Web3 from "web3"
import Cookies from "universal-cookie"
import { Tooltip } from "antd"
import { isFloat } from "../../utils"
import Popup from "../components/Popup"
const cookies = new Cookies()

// ⚠️⚠️⚠️ Attention - Please don't make any changes ⚠️⚠️⚠️
// ⚠️⚠️⚠️ Blockchain Integration End ⚠️⚠️⚠️ //

function PendingPayments({
  updateUserDetails,
  updateConnectedWallet,
  fetchUserInfo
}) {
  var addCommas = require("add-commas")
  const loginUserId = localStorage.getItem("loginUserId")
  const loginUserToken = localStorage.getItem("loginUserToken")
  const userDetails = useSelector(state => state.common.userDetails) || {}
  const pendingPayment =
    useSelector(state => state.common.pendingPayment) || null

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [order, setOrder] = useState(
    Array.isArray(pendingPayment?.data) ? pendingPayment?.data : []
  )

  const subTabs = [
    { image: mineGlobally, label: "Mine Globally", class: "first" },
    { image: weHostYouMine, label: "We Host You Mine", class: "second" },
    { image: effortlessEarning, label: "Effortless Earning", class: "third" },
    { image: collectible, label: "Collectible", class: "fourth" }
  ]
  const [paymentInProgress, setPaymentInProgress] = useState("")
  const [payButtonClicked, setPayButtonClicked] = useState(false)
  const [openPopup, setOpenPopup] = useState(false)

  const [nfmTypeIdx, setNFMTypeIdx] = useState(0)
  const [noOfNFM, setNoOfNFM] = useState(0)
  const [stakeDuration, setStakeDuration] = useState(0)
  const [nCashValue, setNCashValue] = useState("0.0")
  const [returnsPercent, setReturnsPercent] = useState(0)
  const [rewardsEarned, setRewardsEarned] = useState(0)

  // ⚠️⚠️⚠️ Attention - Please don't make any changes ⚠️⚠️⚠️
  // ⚠️⚠️⚠️ Blockchain Integration Start ⚠️⚠️⚠️ //

  let [web3, setWeb3] = useState(null)
  let [isWalletConnected, setWalletConnected] = useState(false)
  let [connectedWallet, setConnectedWallet] = useState(null)
  let [viewModal, setViewModal] = useState(false)
  let modalDropdown = [
    { value: "", label: "Select Token" },
    { value: "2", label: "2" }
  ]
  let [token, setToken] = useState(modalDropdown[0].value)

  const connectToWallet = async () => {
    console.log(
      `[nft][src][components][components][pages][pendingPayments][connectToWallet] connectToWallet`
    )
    if (typeof window.ethereum !== "undefined") {
      let accounts = await window.ethereum.request({
        method: "eth_requestAccounts"
      })
      window.web3 = new Web3(window.ethereum)
      setWeb3(window.web3)
      let chainId = await window.web3.eth.getChainId()
      // if (chainId !== parseInt(constant.CHAIN_ID)) {
      //   swal({
      //     title: "Invalid Network!",
      //     text: `Switch your network to ${constant.NETWORK_NAME}`,
      //     icon: "error",
      //     confirmButtonText: "Ok",
      //   });
      //   return;
      // }
      cookies.set("wallet", accounts[0], { path: "/" })
      cookies.set("wallet_status", 1, { path: "/" })
      setWalletConnected(true)
      setConnectedWallet(accounts[0])
      setWeb3(window.web3)
    } else {
      cookies.set("wallet", null, { path: "/" })
      cookies.set("wallet_status", 0, { path: "/" })
      setWalletConnected(false)
      setConnectedWallet(null)
      setWeb3(null)
      // swal({
      //   title: "Unsupported Browse/Plugin not installed",
      //   text: `Please use Google Chrome with Metamask Plugin Installed`,
      //   icon: "error",
      //   confirmButtonText: "Ok",
      // });
      console.log("Unsupported Browser/Plugin not installed")
    }
  }

  const toggleModalView = () => {
    setViewModal(prevState => !prevState)
  }

  const checkIfWalletAlreadyConnected = async () => {
    let walletStatus = cookies.get("wallet_status")
    let connectedWallet = cookies.get("wallet")
    if (parseInt(walletStatus) == 1) {
      console.log(
        `[nft][src][components][components][pages][pendingPayments][checkIfWalletAlreadyConnected] walletStatus 1 ${walletStatus}`
      )
      if (connectedWallet !== null || connectedWallet !== undefined) {
        cookies.set("wallet", connectedWallet, { path: "/" })
        cookies.set("wallet_status", 1, { path: "/" })
        setWalletConnected(true)
        setConnectedWallet(connectedWallet)
        setWeb3(web3)
      }
    } else {
      console.log(
        `[nft][src][components][components][pages][pendingPayments][checkIfWalletAlreadyConnected] walletStatus 2 ${walletStatus}`
      )
      cookies.set("wallet", null, { path: "/" })
      cookies.set("wallet_status", 0, { path: "/" })
      setWalletConnected(false)
      setConnectedWallet(null)
      setWeb3(null)
    }
  }

  // ⚠️⚠️⚠️ Attention - Please don't make any changes ⚠️⚠️⚠️
  // ⚠️⚠️⚠️ Blockchain Integration End ⚠️⚠️⚠️ //

  const nfmOptions = [
    { label: "Choose..", monthlyReward: 0, price: 0 },
    { label: "Iconic", monthlyReward: 137793.6, price: 375 },
    { label: "Premium", monthlyReward: 115200.0, price: 325 },
    { label: "Top", monthlyReward: 34819.2, price: 250 }
  ]

  const getPendingPayments = () => {
    if (userDetails?.loginUserToken) {
      axios
        .get(`${BaseUrl}/mypurchase/${loginUserId}`, {
          headers: {
            Authorization: `Bearer ${loginUserToken}`
          }
        })
        .then(response => {
          let distructedResponse = response?.data ?? null
          if (response.status === 200) {
            if (distructedResponse?.is_payment_in_progress) {
              setPaymentInProgress(distructedResponse?.message ?? "")
            } else if (
              distructedResponse?.is_payment_completed &&
              !localStorage.getItem("buyNitroToken")
            ) {
              navigate("/myaccount/mycollection")
              dispatch(setActiveMenu("NFM"))
            }
            dispatch(setPendingPayment(response?.data ?? null))
          } else if (response?.status == 401) {
            dispatch(updateUserDetailsRedux({}))
            dispatch(setConnectedWallet(""))
            localStorage.clear()
            navigate("/login")
          } else if (response?.status == 404) {
            swal({
              title: "Error!",
              text: "Page not found. Please try again later.",
              icon: "error"
            })
          } else if (response?.status == 500) {
            swal({
              title: "Error!",
              text: "Something went wrong. Please try again later.",
              icon: "error"
            })
          } else if (response?.status == 503) {
            swal({
              title: "Error!",
              text: "Something went wrong. Please try again later.",
              icon: "error"
            })
          } else {
            dispatch(setPendingPayment(null))
          }
        })
        .catch(err => {
          dispatch(setPendingPayment(null))
          if (err?.response?.status === 401) {
            localStorage.clear()
            updateUserDetails({})
            navigate("/login")
          }
        })
    } else {
      navigate("/login")
    }
  }

  const updateCount = (orderId, action) => {
    let updatedOrders = []
    switch (action) {
      case "plus":
        updatedOrders = order.map(order =>
          order.order_id === orderId
            ? { ...order, device_count: order.device_count + 1 }
            : { ...order }
        )
        break
      case "minus":
        updatedOrders = order.map(order =>
          order.order_id === orderId
            ? { ...order, device_count: order.device_count - 1 }
            : { ...order }
        )
        break
    }
    setOrder(updatedOrders)
  }

  const updateOrder = (count, id) => {
    let data = {
      qty: count,
      order_id: id
    }

    axios
      .post(`${BaseUrl}/addorderquatity`, data, {
        headers: {
          // 'Content-Type': 'application/json',
          Authorization: `Bearer ${loginUserToken}`
        }
      })
      .then(response => {
        if (response.status == 200) {
          getPendingPayments()
          fetchUserInfo()
          swal({
            title: "Success!",
            text: "Order updated successfully",
            icon: "success"
          })
        }
      })
      .catch(error => {
        if (error?.response?.status === 401) {
          localStorage.clear()
          updateUserDetails({})
          updateConnectedWallet("")
          navigate("/login")
        } else if (error?.response?.status == 500) {
          swal({
            title: "Error!",
            text: error.message,
            icon: "error"
          })
        } else {
          swal({
            title: "Error!",
            text: "Something went wrong. Please try again later.",
            icon: "error"
          })
        }
      })
  }

  useEffect(() => {
    addHoverListeners()
    checkIfWalletAlreadyConnected()
    getPendingPayments()
    return () => {
      removeHoverListeners()
    }
  }, [])

  const calculateReturnsAndRewards = () => {
    const monthlyReward = nfmOptions[nfmTypeIdx].monthlyReward
    const typePrice = nfmOptions[nfmTypeIdx].price

    const calculatorValueKeys = [
      "NFM Type",
      "NFM Type",
      "Number of NFMs",
      "Stake Duration",
      "NCash Value"
    ]
    let keyIndex = -1

    const isCalculatorDataValid = ![
      monthlyReward,
      typePrice,
      noOfNFM,
      stakeDuration,
      nCashValue
    ].some((v, i) => {
      keyIndex = i
      return !v
    })

    if (!isCalculatorDataValid && keyIndex >= 0) {
      swal({
        title: `Invalid ${calculatorValueKeys[keyIndex]}`,
        text: `Please fill the form with valid ${calculatorValueKeys[keyIndex]}`,
        icon: "error"
      })
      return
    }

    const _returnsPercent = (
      (stakeDuration * parseFloat(nCashValue) * monthlyReward * 100) /
      typePrice
    ).toFixed(3)

    const _rewardsEarned = noOfNFM * stakeDuration * monthlyReward

    setReturnsPercent(_returnsPercent)
    setRewardsEarned(_rewardsEarned)
    setNFMTypeIdx(0)
    setNoOfNFM(0)
    setStakeDuration(0)
    setNCashValue("")
  }

  useEffect(() => {
    setOrder(Array.isArray(pendingPayment?.data) ? pendingPayment?.data : [])
  }, [pendingPayment])

  let data =
    pendingPayment && Array.isArray(pendingPayment.data)
      ? pendingPayment.data
      : []

  let categorizedOrders = {
    Iconic: [],
    Premium: [],
    "Top Location": []
  }

  if (data?.length) {
    Object.keys(categorizedOrders).forEach((key, index) => {
      categorizedOrders[key] = data.filter(
        orderItem => parseInt(orderItem.product_id) - 1 === index
      )
    })
  }

  const getDeviceCount = () => {
    return (pendingPayment?.data ?? [])
      .map(orderItem => orderItem?.device_count ?? 0)
      .reduce((pval, cval) => pval + cval, 0)
  }

  const renderDeviceCount = () => {
    const totalDeviceCount = getDeviceCount()

    const categorizedOrdersList = Object.entries(categorizedOrders).map(
      (entry, index) => {
        if (entry[1].length) {
          return (
            <li key={entry[0] + index}>
              {`${entry[0]} -
      ${entry[1].reduce((pval, cval) => pval + cval?.device_count ?? 0, 0)}`}
            </li>
          )
        } else return null
      }
    )

    return (
      <>
        <h1 className="payment-bar-info">
          <span className="mrp-cross">Null</span>

          {totalDeviceCount}
        </h1>
        <ul>{categorizedOrdersList}</ul>
      </>
    )
  }

  const renderTotalDueAmount = () => {
    let totalDueAmount = (pendingPayment?.data ?? [])
      .map(
        orderItem =>
          (orderItem?.payment_gross ?? 0) + (orderItem?.pending_amount ?? 0)
      )
      .reduce((pval, cval) => pval + cval, 0)
    return isFloat(totalDueAmount) ? totalDueAmount : addCommas(totalDueAmount)
  }

  const renderPaidAmount = () => {
    let paidAmount = (pendingPayment?.data ?? [])
      .map(orderItem => orderItem?.payment_gross ?? 0)
      .reduce((pval, cval) => pval + cval, 0)
    return isFloat(paidAmount) ? paidAmount : addCommas(paidAmount)
  }

  const getBalance = () => {
    let strikeOffAmount = 0
    let balanceAmount = 0

    ;(pendingPayment?.data ?? []).forEach(order => {
      const isPreMiner = (order?.payment_gross ?? 0) > 0
      if (isPreMiner) {
        strikeOffAmount +=
          order?.device_count * (order?.unit_price + 75) - order?.payment_gross
        balanceAmount +=
          order?.device_count * order?.unit_price - order?.payment_gross
      } else {
        strikeOffAmount +=
          order?.device_count * (order?.unit_price * 2) - order?.payment_gross
        balanceAmount +=
          order?.device_count * order?.unit_price - order?.payment_gross
      }
    })

    return { strikeOffAmount, balanceAmount }
  }

  const renderBalanceAmount = () => {
    const { strikeOffAmount, balanceAmount } = getBalance()
    return (
      <>
        <span className="mrp-cross visible">
          $
          {isFloat(strikeOffAmount)
            ? strikeOffAmount
            : addCommas(strikeOffAmount)}
        </span>
        ${isFloat(balanceAmount) ? balanceAmount : addCommas(balanceAmount)}
      </>
    )
  }

  const renderCategorizedPendingAmount = orderItem => {
    const totalPriceByUnit =
      (orderItem?.unit_price ?? 0) * (orderItem?.device_count ?? 0)
    const pendingAmount = orderItem?.pending_amount ?? 0
    let finalValue = orderItem.payment_gross ? pendingAmount : totalPriceByUnit
    return isFloat(finalValue) ? finalValue : addCommas(finalValue)
  }

  return (
    <>
      {pendingPayment && Array.isArray(pendingPayment?.data) ? ( // UI for successful information fetch
        <div className="pending-payment-page">
          {/* Order Summary */}
          <div className="order-summary-nfm">
            <h2 className="content-head-pending-payment">Order Summary</h2>
            <div className="payment-bar mt-2">
              <div>
                <p>
                  Device count &nbsp;
                  <Tooltip title="Number of devices ordered">
                    <FaIcons.FaExclamationCircle />
                  </Tooltip>
                </p>
                {renderDeviceCount()}
              </div>
              <div>
                <p>
                  Total Due &nbsp;{" "}
                  <Tooltip title="Total cost">
                    <FaIcons.FaExclamationCircle />
                  </Tooltip>
                </p>
                <h1 className="payment-bar-info">
                  <span className="mrp-cross">Null</span>$
                  {renderTotalDueAmount()}
                </h1>
              </div>
              <div>
                <p>
                  Paid &nbsp;{" "}
                  <Tooltip title="Total paid amount at the time of pre-order">
                    <FaIcons.FaExclamationCircle />
                  </Tooltip>
                </p>
                <h1 className="payment-bar-info">
                  <span className="mrp-cross">Null</span>${renderPaidAmount()}
                </h1>
              </div>
              <div>
                <p>
                  Balance &nbsp;
                  <Tooltip title="Total outstanding payable amount">
                    <FaIcons.FaExclamationCircle />
                  </Tooltip>
                </p>
                <div className="row-style">
                  <h1 className=" payment-bar-info">{renderBalanceAmount()}</h1>
                  <div id="payment-btns">
                    <a
                      onClick={() => {
                        navigate("/payment")
                      }}
                      className="btn-brand btn-active px-0">
                      Pay Now
                    </a>
                  </div>
                </div>
              </div>

              <Modal
                showModal={viewModal}
                toggleModalView={toggleModalView}
                balance={getBalance().balanceAmount}
                deviceCount={getDeviceCount()}
                setPayButtonClicked={setPayButtonClicked}
                paymentInProgress={paymentInProgress}
              />
            </div>
          </div>

          {/* Order Details And NFM Calculator */}
          <div className="pending-payments-nfm">
            <div className="order-details-nfm">
              <h2 className="content-head-pending-payment">Order Details</h2>
              <table
                id="order-table"
                className="table de-table table-rank table-font-size-reduce table-responsive">
                <thead>
                  <tr>
                    {/* <th scope="col" className="first-head">
                      Order ID
                    </th> */}
                    <th scope="col" className="right">
                      Device Count
                    </th>
                    <th scope="col" className="right">
                      Tier
                    </th>
                    {/* <th scope="col" className="right">
                      Order Date
                    </th> */}
                    <th scope="col" className="right">
                      Pending Amount
                    </th>
                    <th scope="col" className="right">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {order.map((orderItem, index) => {
                    return (
                      <tr key={orderItem?.order_id + index}>
                        {/* <td className="right" data-label="Order ID">
                          {orderItem?.order_id}
                        </td> */}
                        <td className="right" data-label="Device Count">
                          <div className="counter in-mobile-right">
                            {!orderItem.payment_gross ? (
                              <p
                                className={`minus${
                                  !orderItem.device_count ? " disabled" : ""
                                }`}
                                onClick={
                                  orderItem.device_count
                                    ? () =>
                                        updateCount(orderItem.order_id, "minus")
                                    : () => {}
                                }>
                                -
                              </p>
                            ) : null}
                            &nbsp;&nbsp;
                            {orderItem.device_count}&nbsp;&nbsp;
                            {!orderItem.payment_gross ? (
                              <p
                                className="plus"
                                onClick={() =>
                                  updateCount(orderItem.order_id, "plus")
                                }>
                                +
                              </p>
                            ) : null}
                          </div>
                        </td>
                        <td
                          className="d-plus right color-primary text-uppercase"
                          data-label="Tier">
                          {
                            {
                              1: "Iconic",
                              2: "Premium",
                              3: "Top"
                            }[orderItem?.product_id]
                          }
                        </td>
                        <td className="right" data-label="Pending Amount">
                          ${renderCategorizedPendingAmount(orderItem)}
                        </td>
                        <td className="right" data-label="Action">
                          {!orderItem.payment_gross ? (
                            <button
                              style={{
                                padding: "6px auto 6px",
                                textAlign: "center",
                                fontWeight: "normal",
                                width: "max-content"
                              }}
                              className="btn-brand btn-transparent px-05"
                              onClick={() =>
                                updateOrder(
                                  orderItem?.device_count,
                                  orderItem?.order_id
                                )
                              }>
                              Update Qty
                            </button>
                          ) : (
                            "Payment is already made and so, this order is not editable."
                          )}
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
            <div>
              <div className="nfm-calculator">
                <h5>NFM Calculator</h5>

                <div className=" nfm-calculator__inputs">
                  <p>Select NFM Type*</p>
                  <select
                    name="nfm-type"
                    onChange={e => setNFMTypeIdx(Number(e.target.value))}
                    value={nfmTypeIdx}>
                    {nfmOptions.map((nfmOption, i) => (
                      <option key={nfmOption.label} value={i}>
                        {nfmOption.label}
                      </option>
                    ))}
                  </select>
                </div>

                <div className=" nfm-calculator__inputs">
                  <p>No. of NFM*</p>
                  <input
                    type="number"
                    name="noOfNFM"
                    id="noOfNFM"
                    placeholder="e.g. 4"
                    onChange={e => setNoOfNFM(Number(e.target.value))}
                    value={noOfNFM || ""}
                  />
                </div>
                <div className=" nfm-calculator__inputs">
                  <p>Stake Duration (Months)*</p>
                  <input
                    type="number"
                    name="stakeDuration"
                    id="stakeDuration"
                    placeholder="e.g. 10"
                    onChange={e => setStakeDuration(Number(e.target.value))}
                    value={stakeDuration || ""}
                  />
                </div>
                <div className=" nfm-calculator__inputs">
                  <p>Ncash Value*</p>
                  <input
                    type="number"
                    name="ncashValue"
                    id="ncashValue"
                    placeholder="e.g. 0.0005"
                    onChange={e => setNCashValue(e.target.value)}
                    // onChange={e => setNCashValue(Number(e.target.value))}
                    value={nCashValue}
                  />
                </div>
                {/* {console.log(typeof nCashValue)} */}
                <div className="text-center">
                  <button
                    className="btn-brand btn-active"
                    onClick={calculateReturnsAndRewards}>
                    Calculate
                  </button>
                </div>

                <div className="light-bb"></div>

                <div className="return-on-investment">
                  <h5 className="d-block mt-4">Return on Investment</h5>

                  <div className="dfjb">
                    <p className="mb-2">Returns in %</p>
                    <p className="mb-2">{returnsPercent}%</p>
                  </div>
                  <div className="dfjb">
                    <p className="mb-2">Reward Earned</p>
                    <p className="mb-2">
                      {isFloat(rewardsEarned)
                        ? rewardsEarned
                        : addCommas(rewardsEarned)}{" "}
                      Ncash
                    </p>
                  </div>
                </div>
              </div>

              <div className="nfm-calculator disclaimer mt-4">
                <p className="">
                  <strong>Disclaimer:</strong> The ROI calculator on this
                  website is for informational purposes only. It does not
                  provide financial or investment advice and is based on
                  historical data. Cryptocurrency values are highly volatile and
                  subject to market fluctuations. Investing in them carries a
                  high level of risk. Do thorough research and consult a
                  financial advisor before making any investment decisions. We
                  do not guarantee any specific results or returns.
                </p>
              </div>
            </div>
          </div>

          {payButtonClicked || paymentInProgress ? (
            <p
              className="text-left"
              style={{
                display: "block",
                color: "white",
                margin: `15px ${viewModal ? "0" : "auto"}`,
                textAlign: "center",
                maxWidth: "70%",
                fontWeight: "bold",
                fontSize: "1.01rem"
              }}>
              Payment is under process. Upon network confirmation & confirmation
              from payment processor, mystery box(s) will show up under "My
              Collection". In case of failure of payment, please wait for some
              time until "Pay Now" buttons get re-activated, to retry the
              payment.
            </p>
          ) : null}
        </div>
      ) : null}
      {/* <div>
        <p className="text-center mb-0 mt-4">(Powered by Federal)</p>
      </div> */}

      {openPopup && (
        <Popup openPopup={openPopup} setOpenPopup={setOpenPopup}>
          <div className="offer-miss-out">
            <h2 className="text-center mb-0">Miss out on deal? Sure?</h2>
            <div className="order-summary-nfm">
              <h2 className="content-head-pending-payment">Order Summary</h2>
              <div className="payment-bar mt-2">
                <div>
                  <p>
                    NFM count &nbsp;
                    {/* <Tooltip title="Number of devices ordered">
                    <FaIcons.FaExclamationCircle />
                  </Tooltip> */}
                  </p>
                  <h2 className="payment-bar-info">
                    <span className="mrp-cross">Null</span>
                    {getDeviceCount()}
                  </h2>
                </div>
                <div>
                  <p>
                    Total &nbsp;{" "}
                    {/* <Tooltip title="Total cost">
                    <FaIcons.FaExclamationCircle />
                  </Tooltip> */}
                  </p>
                  <h2 className="payment-bar-info">
                    <span className="mrp-cross">Null</span>${" "}
                    {getBalance().strikeOffAmount}
                  </h2>
                </div>
                <div>
                  <p>
                    Savings &nbsp;{" "}
                    {/* <Tooltip title="Total paid amount at the time of pre-order">
                    <FaIcons.FaExclamationCircle />
                  </Tooltip> */}
                  </p>
                  <h2 className="payment-bar-info">
                    <span className="mrp-cross">Null</span>-${" "}
                    {getBalance().strikeOffAmount - getBalance().balanceAmount}
                  </h2>
                </div>
                <div>
                  <p>
                    Grand Total &nbsp;
                    {/* <Tooltip title="Total outstanding payable amount">
                    <FaIcons.FaExclamationCircle />
                  </Tooltip> */}
                  </p>
                  <div className="row-style">
                    <h2 className=" payment-bar-info">
                      <span className="mrp-cross">Null</span>${" "}
                      {getBalance().balanceAmount}
                    </h2>
                  </div>
                </div>
              </div>

              {/* CTA BUTTONS */}
              <div className="text-center two-center-button mt-4">
                <button className="btn-brand btn-transparent ">Cancel</button>

                <button
                  className="btn-brand btn-active "
                  onClick={() => {
                    navigate("/payment")
                  }}>
                  Proceed to pay
                </button>
              </div>
            </div>
          </div>
        </Popup>
      )}
    </>
  )
}

const mapDispatchToProps = dispatch => {
  return {
    updateUserDetails: payload => dispatch(updateUserDetailsRedux(payload)),
    updateConnectedWallet: payload => dispatch(setConnectedWallet(payload))
  }
}

export default connect(null, mapDispatchToProps)(PendingPayments)

function addHoverListeners() {
  const imageBox = document.querySelector(".image-box")
  const headBox = document.querySelector(".head-box")
  const textBox = document.querySelector(".text-box")
  const fourth = document.querySelector(".fourth")
  const third = document.querySelector(".third")
  const second = document.querySelector(".second")
  const first = document.querySelector(".first")

  fourth &&
    fourth.addEventListener("mouseover", function () {
      imageBox.src = collectible
      headBox.innerHTML = "Collectible"
      textBox.innerHTML =
        "Your NFM not only unlocks mining rewards but is also a generative art collectible which is rare and sought after. So, hold on to it!"
    })

  third &&
    third.addEventListener("mouseover", function () {
      imageBox.src = effortlessEarning
      headBox.innerHTML = "Effortless Earning"
      textBox.innerHTML =
        "Buy your Nitro NFM from the marketplace, find your Nest (host) and mine away. It’s effortlessly earning, equitable reward distribution and with no hassles of network congestion!"
    })

  second &&
    second.addEventListener("mouseover", function () {
      imageBox.src = weHostYouMine
      headBox.innerHTML = "We Host You Mine"
      textBox.innerHTML =
        "Nitro Nesters (those who host the network) are present in strategic locations across the world. Nitro platform creates the ideal match for your miner to the Nester so you can earn in the best way."
    })

  first &&
    first.addEventListener("mouseover", function () {
      imageBox.src = mineGlobally
      headBox.innerHTML = "Mine Globally"
      textBox.innerHTML =
        "Your NFM is limitless and borderless. Build the Nitro Network and mine effortlessly, anywhere in the world!"
    })
}

function removeHoverListeners() {
  const imageBox = document.querySelector(".image-box")
  const headBox = document.querySelector(".head-box")
  const textBox = document.querySelector(".text-box")
  const fourth = document.querySelector(".fourth")
  const third = document.querySelector(".third")
  const second = document.querySelector(".second")
  const first = document.querySelector(".first")

  fourth &&
    fourth.removeEventListener("mouseover", function () {
      imageBox.src = "./images/collectiblewts.png"
      headBox.innerHTML = "Collectible"
      textBox.innerHTML = "Rewards are collectible into your account!"
    })
  third &&
    third.removeEventListener("mouseover", function () {
      imageBox.src = "./images/effortlesswhite.png"
      headBox.innerHTML = "Effortless Earning"
      textBox.innerHTML = "You Dont have to work for that, thats effortless!"
    })

  second &&
    second.removeEventListener("mouseover", function () {
      imageBox.src = "./images/minerwhites.png"
      headBox.innerHTML = "We Host You Mine"
      textBox.innerHTML = "We are hosting , Mining Reward you will receive"
    })
  first &&
    first.removeEventListener("mouseover", function () {
      // first.firstElementChild.src = './images/mineglobal.png';
      imageBox.src = "./images/mineglobal.png"
      headBox.innerHTML = "Mine Globally"
      textBox.innerHTML =
        "Your NFT Miner is universal and borderless. Mine anywhere in the world"
    })
}
