import React from "react"
import "../../assets/css/marketplace.css"

import huobi from "./images/huobi-global.svg"
import pangolin from "./images/Pangolin.svg"
const GetYourNcashHere = () => {
  return (
    <>
      {/* GET EXCHANGE HERE */}
      <div className="max-width-55">
        <div className="exchnage-section container">
          <h3>Get your NCash here</h3>
          {/* <div className="max-width-55"> */}
          <div className="exhanges-box">
            <a href="https://bitbns.com/trade/#/ncash" className="huobi-box">
              <img src={huobi} alt="Hobo" />
              <p>Click here to get NCash</p>
            </a>
            <a
              href="https://app.pangolin.exchange/#/swap"
              className="huobi-box">
              <img src={pangolin} alt="Hobo" />
              <p>Click here to get NCash</p>
            </a>
          </div>
          {/* </div> */}
        </div>
      </div>
    </>
  )
}

export default GetYourNcashHere
