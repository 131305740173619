import React from "react"

const ConnectWalletBtn = ({
  connectedWallet,
  disconnectWallet,
  connectToWallet
}) => {
  return (
    <div className="btn-brand btn-active btn-connect-wallet">
      {connectedWallet.length ? (
        <>
          <p
            className="border-field text-white"
            // href="javascript:void(0)"
          >
            {connectedWallet?.length
              ? connectedWallet.slice(0, 5) +
                ".".repeat(3) +
                connectedWallet.slice(-4)
              : ""}
          </p>
          <a className="disconnect-btn">
            <span onClick={disconnectWallet}>x</span>
          </a>
        </>
      ) : (
        <p
          className="border-field text-white text-center"
          // href="javascript:void(0)"
          onClick={connectToWallet}>
          <span className="bold-text">Connect to Wallet</span> (METAMASK)
        </p>
      )}
    </div>
  )
}

export default ConnectWalletBtn
