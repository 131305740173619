import React, { createRef, useEffect, useState } from "react"
import Moment from "react-moment"
import moment from "moment"
import {
  CategoryScale,
  Chart,
  LinearScale,
  LineElement,
  LineController,
  PointElement,
  Filler,
  Tooltip,
  Title
} from "chart.js"

function AnalyticsChart({ title, lastReading, graphData }) {
  Chart.register(
    CategoryScale,
    LinearScale,
    LineController,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Title
  )
  const timelines = ["day", "week", "month", "year"]
  const timelineDataKeyMap = {
    day: "hour",
    week: "day",
    month: "day",
    year: "month"
  }

  const [timeline, setTimeline] = useState(timelines[1])
  const [values, setValues] = useState([])

  const chartRef = createRef()

  const getGradient = () => {
    const chartContext = chartRef.current.getContext("2d")
    const { height: graphHeight } = chartContext.canvas
    const chartGradient = chartContext.createLinearGradient(
      0,
      0,
      0,
      graphHeight * 1.7
    )
    chartGradient.addColorStop(0, "rgba(222,46,240,1)")
    chartGradient.addColorStop(1, "rgba(222,46,240,0)")
    return chartGradient
  }

  const getLabels = () => {
    const labelArr = graphData[timeline].map(
      dataObj => dataObj[timelineDataKeyMap[timeline]]
    )

    switch (timeline) {
      case "day":
        return labelArr.map(label => moment(label, 'H').format("h:mm A"))
      case "year":
        return labelArr.map(label =>
          moment()
            .month(label - 1)
            .format("MMM")
        )
      case "week":
      case "month":
        return labelArr.map(label => moment(label).format("DD/MM/YYYY"))
      default:
        return []
    }
  }

  const resetChart = () => {
    const obsoleteChart = Chart.getChart("analyticsGraph")
    obsoleteChart !== undefined && obsoleteChart.destroy()
  }

  const createChart = (_labels, _values) => {
    resetChart()
    if (!_values.length) return
    const chartContext = chartRef.current.getContext("2d")
    getGradient()
    new Chart(chartContext, {
      type: "line",
      data: {
        labels: _labels,
        datasets: [
          {
            label: title,
            data: _values,
            fill: true,
            backgroundColor: getGradient(),
            pointBackgroundColor: "rgba(222,46,240, 1)"
          }
        ]
      },
      options: {
        responsive: true,
        scales: {
          y: {
            beginAtZero: true,
            grid: {
              display: _values.length ? true : false
            }
          }
        }
      }
    })
  }

  useEffect(() => {
    if (!graphData) return
    const _values = graphData[timeline].map(dataObj => dataObj.value)
    const _labels = getLabels()
    resetChart()
    createChart(_labels, _values)

    return () => resetChart()
  }, [])

  useEffect(() => {
    if (!graphData) return
    const _values = graphData[timeline].map(dataObj => dataObj.value)
    const _labels = getLabels()
    setValues(_values)
    createChart(_labels, _values)
  }, [timeline, graphData])

  return (
    <>
      <div className="row charts-four pl-2">
        <div className="chart col-12">
          <div className="chartHead">{title}</div>
          <div id="chart-trx">
            <div className="css-cljdej">
              <div className="css-rb6w15">
                <div role="group" className="chakra-button__group css-1crqwgw">
                  {timelines.map((time, index) => {
                    return (
                      <button
                        key={index}
                        type="button"
                        className={`chakra-button css-vkhaod timeline-btn text-capitalize ${
                          timeline === time ? "active" : ""
                        }`}
                        onClick={() => {
                          setTimeline(time)
                        }}>
                        {time}
                      </button>
                    )
                  })}
                </div>
              </div>
            </div>
            <canvas id="analyticsGraph" ref={chartRef}></canvas>
            <p
              className="text-center"
              style={{
                color: "black",
                display: !values.length ? "block" : "none",
                fontWeight: "500",
                fontSize: "1.2rem",
                marginTop: "-45px"
              }}>
              No Data found for {timeline} filter
            </p>
          </div>
          <Moment format="llll">{lastReading}</Moment>
        </div>
      </div>
    </>
  )
}

export default AnalyticsChart
