import { ACTION_TYPES } from "../actions"
import { REHYDRATE } from "redux-persist"
const initialState = {
  soldNFTs: []
}

function MarketplaceReducer(state = initialState, action) {
  switch (action.type) {
    case ACTION_TYPES.SET_SOLD_NFTS:
      return {
        ...state,
        soldNFTs: action.payload
      }
    case REHYDRATE:
      return {
        ...initialState,
        ...((action.payload || {}).marketplace || {})
      }
    default:
      return state
  }
}

export default MarketplaceReducer
