import React from "react"

function Privacy() {
  return (
    <div className="term-page">
      <div className="container-term">
        <div className="terms">
          <h1 className="text-center">Privacy Policy</h1>
          {/*<h1 className="text-center">Preorder Agreement & Privacy Policy</h1>*/}
          <div className="static first-privacy">
            {/*<h3 className="blue">PREORDER AGREEMENT</h3>*/}
            {/*<p>*/}
            {/*  Your Nitro ION miner PREORDER AGREEMENT (the “Agreement”) is made*/}
            {/*  up of the following documents:{" "}*/}
            {/*</p>*/}
            {/*<ol>*/}
            {/*  <li>*/}
            {/*    <b>Miner Configuration:</b> The Miner Configuration will be*/}
            {/*    confirmed with you at a later date. It will describe the Miner*/}
            {/*    that you configure, including pricing (excluding taxes and*/}
            {/*    official or government fees).{" "}*/}
            {/*  </li>*/}
            {/*  <li>*/}
            {/*    <b>Final Price Sheet:</b> The Final Price Sheet will be provided*/}
            {/*    to you as your delivery date nears. It will include final*/}
            {/*    pricing based on your miner Configuration and will include taxes*/}
            {/*    and official or governmental fees.*/}
            {/*  </li>*/}
            {/*  <li>*/}
            {/*    <b>Terms & Conditions:</b> These Terms & Conditions are*/}
            {/*    effective as of the date you place your pre-order and make your*/}
            {/*    Pre-Order Payment (the “Pre-Order Date”).*/}
            {/*  </li>*/}
            {/*</ol>*/}
            {/*<p>*/}
            {/*  <b>Agreement to Purchase:</b> You agree to pre-order the Miner*/}
            {/*  (the “Miner”) that you configured when you made your Pre-Order*/}
            {/*  Payment and by taking delivery, completing the transaction when*/}
            {/*  the Miner is ready for delivery from Nitro Network or its*/}
            {/*  affiliate (“we,” “us” or “our”), pursuant to the terms and*/}
            {/*  conditions of this Agreement. Your Miner is priced and configured*/}
            {/*  based on features and options available at the time of order and*/}
            {/*  you can confirm availability with a Nitro representative. Options,*/}
            {/*  features or hardware released after you place your order may not*/}
            {/*  be included in or available for your Miner.{" "}*/}
            {/*</p>*/}
            {/*<p>*/}
            {/*  <b>Pre-Order Price, Taxes and Official Fees:</b> The pre-order*/}
            {/*  price of the Miner will be confirmed in your Miner Configuration*/}
            {/*  and Final Price Sheet. As you may have only configured part of*/}
            {/*  your Miner, any pre-order price provided to you in advance of the*/}
            {/*  Final Price Sheet is only being offered to you as an estimate and*/}
            {/*  is subject to change. Any pre-order price listed in the Miner*/}
            {/*  Configuration will not include taxes and official or government*/}
            {/*  fees, which could amount to up to 10% or more of the Miner*/}
            {/*  pre-order price. Because these taxes and fees are constantly*/}
            {/*  changing and will depend on many factors, such as where you*/}
            {/*  register the Miner, they will be calculated closer to the time of*/}
            {/*  delivery and indicated on your Final Price Sheet. You are*/}
            {/*  responsible for paying these additional taxes and fees. If you*/}
            {/*  present a check for any payment, we may process the payment as a*/}
            {/*  normal check transaction, or we may use information from your*/}
            {/*  check to make a one-time electronic fund transfer from your*/}
            {/*  account, in which case your bank account will reflect this*/}
            {/*  transaction as an Electronic Fund Transfer.*/}
            {/*</p>*/}
            {/*<p>*/}
            {/*  <b>Pre-Order Process, Cancellation, Changes:</b> After you submit*/}
            {/*  your completed pre-order and the options you selected become*/}
            {/*  available in production, we will invite you to complete the*/}
            {/*  configuration of your Miner. We will then issue you the Miner*/}
            {/*  Configuration and Final Price Sheet based on the base price of the*/}
            {/*  model and any options included or that you select. Your Pre-Order*/}
            {/*  Payment covers the cost of these activities and other processing*/}
            {/*  costs and is not a deposit for the Miner. If you make changes to*/}
            {/*  the configuration of the Miner, you may be subject to potential*/}
            {/*  price increases for any pricing adjustments made since your*/}
            {/*  original Pre-Order Date. If you wish to cancel your order at any*/}
            {/*  time, There will be no refund given. Any changes made by you to*/}
            {/*  your Miner Configuration, including changes to the delivery*/}
            {/*  location or estimated delivery date, will be reflected in a*/}
            {/*  subsequent Miner Configuration that will form part of this*/}
            {/*  Agreement. When you take delivery of the Miner, we will provide a*/}
            {/*  credit to the final pre-order price of your Miner equivalent to*/}
            {/*  the amount of the Pre-Order Payment you paid in NCash. This*/}
            {/*  Pre-Order Payment and this Agreement are not made or entered into*/}
            {/*  in anticipation of or pending any conditional sale contract.{" "}*/}
            {/*</p>*/}
            {/*<p>*/}
            {/*  <b>Delivery:</b> The Miner will be delivered once the product is*/}
            {/*  ready to be shipped and the payment processing is completed. You*/}
            {/*  understand that Nitro Network may not have completed the*/}
            {/*  development of The Miner or begun manufacturing The Miner at the*/}
            {/*  time you entered into this Agreement and so we do not guarantee*/}
            {/*  when your Miner will actually be delivered. Your actual delivery*/}
            {/*  date is dependent on many factors, including your Miner’s*/}
            {/*  Configuration and manufacturing availability. To secure your final*/}
            {/*  payment and performance under the terms of this Agreement, we will*/}
            {/*  retain a security interest in the Miner and all proceeds therefrom*/}
            {/*  until your Configuration have been fulfilled.*/}
            {/*</p>*/}
            {/*<p>&nbsp;</p>*/}
            {/*<h3 className="blue text">Privacy Policy</h3>*/}

            <p>
              This Privacy Policy explains how we collect, use, retain,
              transfer, disclose, dispose information, including your personal
              information.
            </p>
            <p>
              By using the website, you consent to the data practices described
              in this Privacy Policy. On occasion, we may revise this Privacy
              Policy to reflect changes in law or our Personal Data collection
              and use practices. If material changes are made to this Privacy
              Policy, the changes will be announced by posting on the website
              for visitors. We will ask for your consent before using your
              Personal Data for any purpose that is not covered in this Privacy
              Policy.
            </p>
            <h3>COLLECTION OF INFORMATION</h3>
            <p>We May Collect the Following Information</p>
            <p>
              Information provided by you, when using the Website, subscribing
              to the Website updates, carrying out any development work,
              responding to a survey or when filling enquiry forms on the
              Website, creating Website login and when corresponding by phone,
              e-mail or other communication services, you may be asked to
              provide certain information. The information may include Personal
              Data such as name, address, e-mail address, phone number,
              financial and/or credit card information, personal description,
              device identifiers, ID, date of birth, location information or
              other data which can be used for personal identification purposes.
            </p>
            <p>
              <b>Information collected automatically by us</b> with regard to
              each visit to the Website, will automatically collect the
              following:
            </p>
            <ol className="ul">
              <li>
                Technical information, including the Internet protocol (IP)
                address used to connect your computer to the Internet, the User
                login information, browser type and version, time zone setting,
                browser plug-in types and versions, operating system and
                platform; information about the visits, including the full
                Uniform Resource Locators (URL), clickstream to, through and
                from the Website (including date and time), products viewed or
                searched for, page response times, download errors, length of
                visits to certain pages, page interaction information (such as
                scrolling, clicks, and mouse-overs), methods used to browse away
                from the page, and any phone number used to call our customer
                service number.
              </li>
              <li>
                Information may be collected by Cookies and other tracking.
                Cookies are small data files that are stored on your device when
                visiting a website, which enable us to collect information about
                your device identifiers, web browsers used to access the
                services, pages or features viewed, time spent on pages, mobile
                app performance and links clicked. Web beacons (or pixel tags)
                are electronic images that may be used to help deliver cookies,
                count website visits, understand usage and determine the
                effectiveness of email marketing campaigns.
              </li>
            </ol>
            <p className="xs-mt-16">
              You are advised that if you wish to deny the use and saving of
              cookies from this Website on your computer’s hard drive you should
              take necessary steps within your web browsers security settings to
              block all cookies from this Website.
            </p>
            <p>
              <b>Information, which is received from third-parties</b>,
              including, but not limited to our partners, sub-contractors in
              technical, payment services, advertising networks, or other third
              parties who may provide information, for the purpose of fulfilling
              the services to you, or to comply with legal requirements. We
              shall at all times, endeavour that our partners, sub-contractors
              in technical, payment services, advertising networks, or other
              third parties are to provide the same level of data protection and
              that they shall only carry out their contractual obligations
              towards us or upon our instructions and not for any other
              purposes.
            </p>
            <p>
              <b>In case of using a location-enabled device</b> with the
              Website, we may collect location data or use various means to
              determine the location.
            </p>
            {/*<h3>STORAGE AND RETENTION OF PERSONAL INFORMATION</h3>*/}
            {/*<p>*/}
            {/*  We shall keep your personal information that is adequate,*/}
            {/*  relevant, and limited to what is necessary in relation to the*/}
            {/*  purposes for which it is processed. We shall apply encryption to*/}
            {/*  your information to preserve the confidentiality, integrity of*/}
            {/*  your personal information.{" "}*/}
            {/*</p>*/}
            {/*<p>*/}
            {/*  We strive to keep your information accurate, and, up to date. We*/}
            {/*  shall take reasonable steps to ensure that information is*/}
            {/*  accurate, having regard to the purposes for which it is processed,*/}
            {/*  and any inaccurate Personal Data shall be erased or rectified*/}
            {/*  without undue delay as soon we become aware of such inadequacy.*/}
            {/*</p>*/}
            {/*<h3>RETENTION OF PERSONAL DATA</h3>*/}
            {/*<p>*/}
            {/*  We ensure that the Personal Data will not be kept for longer than*/}
            {/*  is necessary and only kept for the purposes for which it is*/}
            {/*  processed. Retention periods may vary as required by applicable*/}
            {/*  law or court orders.*/}
            {/*</p>*/}
            {/*<h3>RIGHTS OF ACCESS BY DATA SUBJECTS</h3>*/}
            {/*<p>*/}
            {/*  You will have the right to approach us to access, rectify, update,*/}
            {/*  or, object to further processing of your information by us.{" "}*/}
            {/*</p>*/}
            {/*<p>*/}
            {/*  Where you have any questions, or wish to make additional requests,*/}
            {/*  you may write to our Privacy Office at info@nitro.network. We*/}
            {/*  strive to respond to any requests made with the specific exercise*/}
            {/*  of data protection rights available under various applicable data*/}
            {/*  protection laws*/}
            {/*</p>*/}
            <h3>DATA PORTABILITY</h3>
            <p>
              You shall have the right to receive, upon request, a copy of the
              Personal Data you provided to us in a structured, commonly used
              and machine-readable format and to transmit it to another
              Controller, for free. We shall endeavour to ensure that such
              requests are processed within one month, subject that it is not
              excessive and does not affect the rights of other individuals’
              Personal Data.
            </p>
            {/*<h3>DISPOSAL OF PERSONAL DATA</h3>*/}
            {/*<p>*/}
            {/*  When we receive requests to dispose of Personal Data records by*/}
            {/*  Data Subjects, we shall ensure that these requests are handled*/}
            {/*  within a reasonable time frame. We shall keep a record, including*/}
            {/*  a log, of these requests.*/}
            {/*</p>*/}
            {/*<p>*/}
            {/*  We ensure that any archived Personal Data is disposed of by*/}
            {/*  adequate disposal mechanisms on expiry of retention period. Any*/}
            {/*  hard copies of Personal Data that we might have obtained from you*/}
            {/*  shall be physically destroyed when no longer relevant.{" "}*/}
            {/*</p>*/}
            {/*<p>*/}
            {/*  <b>Use of Information</b>*/}
            {/*</p>*/}
            {/*<ul className="ul">*/}
            {/*  <li>*/}
            {/*    Process your registration and interaction with our Website;*/}
            {/*  </li>*/}
            {/*  <li>To communicate with you;</li>*/}
            {/*  <li>*/}
            {/*    Perform analytics and customer research, to improve our service,*/}
            {/*    including the user experience and interface; measure site*/}
            {/*    performance;*/}
            {/*  </li>*/}
            {/*  <li>*/}
            {/*    To offer certain types of services, free trials, other*/}
            {/*    applicable offers basis your eligibility; To protect our users*/}
            {/*    against automated abuse such as spam, phishing, and DDoS*/}
            {/*    attacks;*/}
            {/*  </li>*/}
            {/*</ul>*/}
            {/*<h3 className="xs-mt-16">SHARING AND DISCLOSURE OF INFORMATION</h3>*/}
            {/*<p>*/}
            {/*  While we are the recipient of your information, we may allow*/}
            {/*  access to [insert categories of entities] for the processing and*/}
            {/*  storage of information, provision of account to you, provision of*/}
            {/*  customer support, content development and delivery, and other*/}
            {/*  purposes as identified in the use of information collected by us*/}
            {/*  section to this Policy.{" "}*/}
            {/*</p>*/}
            {/*<p>*/}
            {/*  We also engage various service providers to perform services on*/}
            {/*  behalf of us enabling us to provide you services better. Such*/}
            {/*  services may include, communications, advertising, infrastructure*/}
            {/*  and IT, customer support, data analytics, conduct customer*/}
            {/*  surveys. It is our constant endeavor to ensure that such service*/}
            {/*  providers adhere to a similar standard of data protection and*/}
            {/*  security that we have agreed to under this Policy. At times, when*/}
            {/*  we offer promotional offers in collaboration with third party*/}
            {/*  entities, we will not be responsible for the privacy practices of*/}
            {/*  such third-party entities.*/}
            {/*</p>*/}
            {/*<p>*/}
            {/*  We and our service providers may be required to disclose and*/}
            {/*  otherwise use your personal and other information where we or our*/}
            {/*  service providers reasonably believe that there exists the need to*/}
            {/*  disclose to{" "}*/}
            {/*</p>*/}
            {/*<ul className="ul">*/}
            {/*  <li>*/}
            {/*    Meet or satisfy any applicable law, regulation, governmental*/}
            {/*    request, or, legal process;*/}
            {/*  </li>*/}
            {/*  <li>Enforce the agreed Terms of Use;</li>*/}
            {/*  <li>*/}
            {/*    Address and investigate any illegal activities, which may also*/}
            {/*    include financial frauds, any security or technical issues;*/}
            {/*  </li>*/}
            {/*  <li>*/}
            {/*    Protect itself against any harm, misuse, of the property or*/}
            {/*    safety of our users and the public, as required or permitted by*/}
            {/*    law.*/}
            {/*  </li>*/}
            {/*</ul>*/}
            {/*<p className="xs-mt-16">*/}
            {/*  In the event where there is any restructuring, reorganization,*/}
            {/*  merger or sale, or any other transfer of assets, we shall transfer*/}
            {/*  such information, including your personal information, subject to*/}
            {/*  an agreement by the other party in respect to your personal*/}
            {/*  information in a manner which is consistent with this Policy.*/}
            {/*</p>*/}
            {/*<p>*/}
            {/*  Where you use any social plugin or social media application*/}
            {/*  operated by other social media networks, you shall be subject to*/}
            {/*  the terms of use and privacy policies of such entities themselves.{" "}*/}
            {/*</p>*/}
            {/*<p>*/}
            {/*  When we learn of a suspected or actual Personal Data breach, we*/}
            {/*  shall perform an internal investigation and take appropriate*/}
            {/*  remedial measures in a timely manner. Where there is any risk to*/}
            {/*  the rights and freedoms of our users, we will notify the relevant*/}
            {/*  supervisory authorities without undue delay and, when possible,*/}
            {/*  within 72 hours, or such requisite time period, from when we learn*/}
            {/*  of such breach.*/}
            {/*</p>*/}
            <h3>COOKIES AND ADVERTISING</h3>
            <p>
              We and our service providers use cookies and other technologies,
              such as web beacons, as well as several advertising identifiers
              for several purposes. This is done to understand the users better
              and offer you with tailored marketing and advertising services. If
              you do not consent to the use of cookies, use of similar
              technologies by us, you may at any time adjust your browser
              settings and delete or disable such cookies. However, this may
              result in the absence of tailored or user interest based
              advertisements.{" "}
            </p>
            <h3>DATA SECURITY</h3>
            <p>
              We understand that we must safeguard your personal information
              available with us. We use and employ reasonable technical,
              organizational, logical, physical, administrative measures to
              protect your personal information against any unauthorized access,
              modification, or, use. Changes in Privacy Policy
            </p>
            <p>
              We may update this Privacy Policy from time to time. Your
              continued use of our Platform constitutes in your acceptance of
              our Terms of Use and this Privacy Policy. We encourage you to
              review this section periodically to review any changes made. If
              you do not wish to accept any changes made to this Privacy Policy,
              you may cancel your use of our services.{" "}
            </p>
            <h3>GOVERNING LAW</h3>
            <p>
              This Privacy Policy is intended to comply with the laws and
              regulations in the place of establishment and of the country in
              which we operate. In the event of any conflict between this
              Privacy Policy and applicable laws and regulations, the latter
              shall prevail.
            </p>
            <h3>CONTACT US</h3>
            <p>
              We are committed to respecting the freedoms and rights of all our
              visitors, users and customers who have placed their trust in our
              service. If you have any questions about this Privacy Policy, the
              practices of this website, or your dealings with this website,
              requests, complaints or inquiries relating to processing and
              protection of Personal Data can be sent to the e-mail address{" "}
              <a href="mailto:info@nitro.network">info@nitro.network</a>
            </p>
            <p>
              By mere use of the Website, you expressly consent to our use and
              disclosure of your personal information in accordance with this
              Privacy Policy.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Privacy
