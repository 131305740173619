export const ACTION_TYPES = {
  //COMMON
  START_LOADER: "START_LOADER",
  STOP_LOADER: "STOP_LOADER",
  SET_STYLE_TYPE: "SET_STYLE_TYPE",
  SAVE_KYC_FORM: "SAVE_KYC_FORM",

  UPDATE_USER_DETAILS: "UPDATE_USER_DETAILS",
  SET_PENDING_PAYMENT: "SET_PENDING_PAYMENT",
  SET_COLLECTION_ITEMS: "SET_COLLECTION_ITEMS",
  UPDATE_ORDER_COUNT: "UPDATE_ORDER_COUNT",
  SET_FILTER_CHANGED: "SET_FILTER_CHANGED",

  //COLLECTION
  SET_CONNECTED_WALLET: "SET_CONNECTED_WALLET",
  SET_TOKEN_DATA: "SET_TOKEN_DATA",
  SET_STAKE_TOKEN_DATA: "SET_STAKE_TOKEN_DATA",
  SET_CLICKED_NFM: "SET_CLICKED_NFM",

  //MARKETPLACE
  SET_SOLD_NFTS: "SET_SOLD_NFTS",

  //IOT
  SET_IOT_TOKENS: "SET_IOT_TOKENS",
  SET_ACTIVE_MENU: "SET_ACTIVE_MENU",

  //WORDPRESS
  SET_PAGES: "SET_PAGES"
}
