import { useState, useEffect } from "react"
import { useNavigate } from "@reach/router"
import { useSelector, useDispatch } from "react-redux"
import { setConnectedWallet } from "../../redux/actions"
import { BaseUrl } from "../../utils/config"
import axios from "axios"
import moment from "moment"
import "../../assets/css/marketplace.css"
import Web3 from "web3"
import Cookies from "universal-cookie"
import { updateUserDetailsRedux } from "../../redux/actions"

const cookies = new Cookies()

function MyCollectionWithStake({ collectionItem = null }) {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  let [web3, setWeb3] = useState(null)
  let [isWalletConnected, setWalletConnected] = useState(false)

  const connectedWallet =
    useSelector(state => state.common.connectedWallet) || ""

  const userDetails = useSelector(state => state.common.userDetails)

  useEffect(() => {
    if (userDetails?.loginUserToken) {
      // Connected Wallet API hit
      if (connectedWallet.length) {
        let apiData = {
          wallet_address: connectedWallet
        }
        axios
          .put(
            `${BaseUrl}/addwallet?user_id=${userDetails.loginUserId}`,
            apiData,
            {
              headers: {
                Authorization: `Bearer ${userDetails.loginUserToken}`
              }
            }
          )
          .then(response => {
            if (response.status === 200) {
            }
          })
          .catch(error => {
            if (error?.response?.status == 401) {
              dispatch(updateUserDetailsRedux({}))
              dispatch(setConnectedWallet(""))
              localStorage.clear()
              navigate("/login")
            }
          })
      }
    } else {
      navigate("/login")
    }
  }, [])

  const connectToWallet = async () => {
    console.log(
      `[nft][src][components][components][pages][pendingPayments][connectToWallet] connectToWallet`
    )
    if (typeof window.ethereum !== "undefined") {
      let accounts = await window.ethereum.request({
        method: "eth_requestAccounts"
      })
      window.web3 = new Web3(window.ethereum)
      setWeb3(window.web3)

      let chainId = await window.web3.eth.getChainId()
      cookies.set("wallet", accounts[0], { path: "/" })
      cookies.set("wallet_status", 1, { path: "/" })
      setWalletConnected(true)
      dispatch(setConnectedWallet(accounts[0]))

      setWeb3(window.web3)
    } else {
      cookies.set("wallet", null, { path: "/" })
      cookies.set("wallet_status", 0, { path: "/" })
      setWalletConnected(false)
      dispatch(setConnectedWallet(""))
      setWeb3(null)

      console.log("Unsupported Browser/Plugin not installed")
    }
  }

  const disconnectWallet = () => {
    axios
      .delete(`${BaseUrl}/removeWallet/${userDetails.loginUserId}`, {
        headers: {
          Authorization: `Bearer ${userDetails.loginUserToken}`
        }
      })
      .then(response => {
        cookies.set("wallet", null, { path: "/" })
        cookies.set("wallet_status", 0, { path: "/" })
        setWalletConnected(false)
        dispatch(setConnectedWallet(""))
        setWeb3(null)
      })
      .catch(error => {})
  }

  return (
    <div>
      <div
        className="container"
        id="product-detail-container"
        style={{ marginTop: "5px" }}>
        <section id="nitro-nft-miner" className="ptm-0 padding-0-in-mobile">
          <div className="nitro-hero-section pending-one" id="product-details">
            {collectionItem !== null && Object.keys(collectionItem).length ? (
              <>
                <div
                  className="d-flex justify-content-space-around align-items-center"
                  style={{
                    minWidth: "60%",
                    margin: "auto"
                  }}>
                  <div className="nitro-img">
                    <img
                      src={collectionItem?.image}
                      alt={collectionItem?.name}
                    />
                  </div>

                  <div
                    className="nitro-text small-width stake-sell-option"
                    id="text-detail">
                    {/* Text BOX */}
                    <div>
                      <h1 className="pink-text">{collectionItem?.name}</h1>
                      <div className="line"></div>
                      <p>{collectionItem?.description}</p>
                      <p className="purple-text">
                        <span className="text-white text-bold">
                          #{collectionItem?.id} Series
                        </span>
                      </p>

                      <div
                        className="d-flex justify-content-between flex-wrap"
                        style={{ width: "70%" }}>
                        {collectionItem.miner_type ? (
                          <p className="purple-text">
                            Miner Type :{" "}
                            <span className="text-white text-bold">
                              {collectionItem?.miner_type}
                            </span>
                          </p>
                        ) : null}
                        {collectionItem.tier ? (
                          <p className="purple-text">
                            Tier :{" "}
                            <span className="text-white">
                              {collectionItem?.tier}
                            </span>
                          </p>
                        ) : null}
                      </div>
                      <div className="d-flex justify-content-between flex-wrap maxwidth-40em">
                        <p className="purple-text">
                          Collection Date :{" "}
                          <span className="text-white">
                            {moment(
                              new Date(Number(collectionItem?.collection_date))
                            ).format("DD-MMMM-YYYY")}
                          </span>
                        </p>
                      </div>
                      {collectionItem.price ? (
                        <div className="d-flex justify-content-between flex-wrap maxwidth-40em">
                          <p className="purple-text">
                            Value :{" "}
                            <span className="text-white text-bold">
                              ${collectionItem?.price ?? 0}
                            </span>
                          </p>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>

                <div
                  style={{ width: "50vw", margin: "auto", marginTop: "15vh" }}>
                  <table className="table de-table table-rank table-font-size-reduce table-responsive">
                    <thead>
                      <tr>
                        {/* <!-- Added new Class --> */}
                        {/* <th scope="col" className="first-head">Collection</th> */}
                        <th scope="col" className="right">
                          Trait{" "}
                        </th>

                        <th scope="col" className="right">
                          Value{" "}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {collectionItem?.attributes?.length ?? 0 > 0 ? (
                        collectionItem.attributes.map((item, i) => {
                          return (
                            <tr key={i}>
                              <td scope="row" data-label="Trait">
                                {/* <!-- Addedd Span tag and class --> */}
                                <span className="address-text-table">
                                  {item?.trait_type}
                                </span>
                              </td>
                              {/* <!-- added class --> */}
                              <td className="right" data-label="Value">
                                {item?.value}
                              </td>
                            </tr>
                          )
                        })
                      ) : (
                        //  <tr ><h4 className='form-head'></h4></tr>}
                        <td colSpan={6} className="text-center">
                          No Data{" "}
                        </td>
                      )}
                      {/* <tr ><h2 className='alignCenter '>No Data</h2>  </tr>} */}
                    </tbody>
                  </table>
                  <div className="spacer-double"></div>
                </div>
              </>
            ) : (
              <div className="nitro-text">
                <h1 className="pink-text text-center">
                  {collectionItem === null
                    ? "Loading..."
                    : "Product not found. Please retry"}
                </h1>
              </div>
            )}
          </div>
        </section>
      </div>

      {/* <div className="artibute-section">
        <section className="attributes-key">
          <div>
            <h4>Attributes tables</h4>

            <div className="attributes">
              <div className="attributes-box">
                <h6>CAVMAN - BEARD</h6>
                <p>Brown</p>
                <p>1% have this trait </p>
              </div>
              <div className="attributes-box">
                <h6>CAVMAN - BEARD</h6>
                <p>Brown</p>
                <p>1% have this trait </p>
              </div>
              <div className="attributes-box">
                <h6>CAVMAN - BEARD</h6>
                <p>Brown</p>
                <p>1% have this trait </p>
              </div>
              <div className="attributes-box">
                <h6>CAVMAN - BEARD</h6>
                <p>Brown</p>
                <p>1% have this trait </p>
              </div>
              <div className="attributes-box">
                <h6>CAVMAN - BEARD</h6>
                <p>Brown</p>
                <p>1% have this trait </p>
              </div>
              <div className="attributes-box">
                <h6>CAVMAN - BEARD</h6>
                <p>Brown</p>
                <p>1% have this trait </p>
              </div>
            </div>
          </div>
          <div className="key">
            <h4>Key Values</h4>
            <div>
              <div className="dfjb">
                <p>Contract Address</p>
                <p>0x298052056174BEE4524V</p>
              </div>
              <div className="dfjb">
                <p>Token ID</p>
                <p>525162349651060</p>
              </div>
              <div className="dfjb">
                <p>Token Standard</p>
                <p>ERC - 1155</p>
              </div>
              <div className="dfjb">
                <p>Blockchain</p>
                <p>Polygon</p>
              </div>
              <div className="dfjb">
                <p>Metadata</p>
                <p>Centralized</p>
              </div>
            </div>
          </div>
        </section>

        <div className="table-claim collection-table-stake">
          <h4>History</h4>
          <div className="user-table mt-6" style={{ overflowX: "auto" }}>
            <table className="table table-fluid" id="myTable">
              <thead>
                <tr>
                  <th className="left-field" scope="col">
                    Event
                  </th>
                  <th className="max-width" scope="col">
                    Price
                  </th>
                  <th className="right-field" scope="col">
                    From
                  </th>
                  <th className="right-field" scope="col">
                    To
                  </th>

                  <th className="right-field" scope="col">
                    Date
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td className="left-field" data-label="Event">
                    {" "}
                    <i className="fas fa-exchange"></i> &nbsp; Trasnsfer
                  </td>
                  <td className="max-width" data-label="Price">
                    15 ETH
                  </td>
                  <td className="right-field" data-label="From">
                    <a href="#">
                      Athleta Ai <i className="fas fa-check-circle"></i>
                    </a>
                  </td>
                  <td className="right-field" data-label="To">
                    <p className="bold-table-point">15674851</p>
                  </td>
                  <td className="right-field" data-label="Date">
                    <a href="#">
                      2 Month ago <i className="fas fa-external-link-alt"></i>
                    </a>
                  </td>
                </tr>

                <tr>
                  <td className="left-field" data-label="Event">
                    <i className="fas fa-exchange"></i> &nbsp; Trasnsfer
                  </td>
                  <td className="max-width" data-label="Price">
                    15 ETH
                  </td>
                  <td className="right-field" data-label="From">
                    <a href="#">
                      Athleta Ai <i className="fas fa-check-circle"></i>
                    </a>
                  </td>
                  <td className="right-field" data-label="To">
                    <p className="bold-table-point">15674851</p>
                  </td>
                  <td className="right-field" data-label="Date">
                    <a href="#">
                      2 Month ago <i className="fas fa-external-link-alt"></i>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div> */}
    </div>
  )
}

export default MyCollectionWithStake
