import React, { useState, useEffect } from "react"
import { Images } from "../../Shared/images"
import { burnData } from "../../Shared/burnData"

const BurnPage = () => {
  const [totalAmountBurn, setTotalAmountBurn] = useState(0)
  const [currentMonthBurn, seCurrentMonthBurn] = useState(0)
  const [currentMonthName, seCurrentMonthName] = useState(null)
  // const [currentPage, setCurrentPage] = useState(1)

  // const burndataPerPage = 8
  // const indexofLastBurn = currentPage * burndataPerPage
  // const indexofFirstBurn = indexofLastBurn - burndataPerPage

  const burnTableColumn = [
    // { dataField: "index", text: "Index" },
    { dataField: "dateOfBurn", text: "Burn date" },
    { dataField: "burnedAmount", text: "Burned amount (ncash)" },
    { dataField: "burnTrx", text: "Burn Transaction #" }
  ]

  const getTotalBurnedAmount = () => {
    //get sum of all burned amount data excluding erc20
    let total = 0

    burnData.map(data => {
      if (!data.isErc) {
        total += data.burnedAmount
      }
      return total
    })

    setTotalAmountBurn(total)
  }

  const getCurrentMonthBurn = () => {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ]

    const lastMonthBurnDate = burnData[0].dateOfBurn //oct-13-2022

    const lastBurndate = new Date(lastMonthBurnDate)

    // name of month eg/ September
    const lastBurnMonth = monthNames[lastBurndate.getMonth()]

    seCurrentMonthName(lastBurnMonth)

    //filter last exist month
    const currentMonthBurnData = burnData.filter(
      data => data.dateOfBurn === lastMonthBurnDate
    )

    // console.log(currentMonthBurnData);

    let totalCurrentMonthStats = 0

    currentMonthBurnData.map(data => {
      if (!data.isErc) {
        totalCurrentMonthStats += data.burnedAmount
      }
      return totalCurrentMonthStats
    })

    // console.log(totalCurrentMonthStats);
    seCurrentMonthBurn(totalCurrentMonthStats)
  }

  useEffect(() => {
    getCurrentMonthBurn()
    getTotalBurnedAmount()
  }, [totalAmountBurn])

  return (
    <div className="burn-page">
      <div className="header">
        <div className="header-container">
          {/* <div className="image-absolute">
          <img src={images.HeaderBack} alt="Burn Ncash" />
        </div> */}

          <div className="text-side">
            <div className="text-header">
              <h1 className="purple-text color-primary first">
                <span>Ncash</span>
              </h1>
              <h1 className="green fw-900 second">Big</h1>
              <h1 className="green fw-800 third">Burn</h1>

              <div className="buy-ncash">
                <a
                  href="https://app.pangolin.exchange/#/swap"
                  className="btn-buy-ncash btn-active btn-brand mt-4">
                  Buy NCash
                </a>
              </div>
            </div>

            <div className="coin-images">
              <img src={Images.CoinNcash} alt="Burned N cooin" />
            </div>
          </div>
        </div>
      </div>

      <div className="real-time-burn">
        <div className="real-time-container">
          <h3 className="headH3">REAL TIME BURN</h3>
          <div className="grid-boxes">
            <div className="box">
              <p>
                Burned this month <br /> {`(${currentMonthName})`}
              </p>
              <div>
                <h2 className="text-white">
                  {currentMonthBurn.toLocaleString("en-US")} NCash
                </h2>
                {/* <h2>$476,479.21</h2> */}
              </div>
            </div>
            <div className="box">
              <p className="text-black">Total burned </p>
              <div>
                <h2 className="text-black">
                  {totalAmountBurn.toLocaleString("en-US")} NCash
                </h2>
                {/* <h2>$22,476,479.21</h2> */}
              </div>
            </div>
          </div>
        </div>
        <div className="burn-history">
          <h3 className="headH3">Burn history</h3>
          {/* Tables */}
          <div className="table-burn">
            <table id="order-table">
              <thead>
                <tr>
                  {burnTableColumn.map((item, index) => {
                    return (
                      <th scope="col" key={index} className="right">
                        {item.text}
                      </th>
                    )
                  })}
                </tr>
              </thead>
              <tbody>
                {/* Change to current Burn for pagination */}
                {burnData.map((data, index) => {
                  return (
                    <tr key={index}>
                      {/* <td className="right" data-label="Index">
                    <span>{data.index}</span>
                  </td> */}
                      <td className="right" data-label="Burn Date">
                        <span>{data.dateOfBurn}</span>
                      </td>
                      <td className="right" data-label="Burned amount (ncash)">
                        <span>
                          {data.burnedAmount.toLocaleString("en-US")} <br />
                          <span style={{ paddingTop: "0.2rem" }}>
                            <small>
                              {data.isErc ? "(Ethereum ERC20 Ncash)" : null}
                            </small>
                          </span>
                        </span>
                      </td>
                      <td className="right" data-label="Burn Transaction #">
                        <a
                          href={data.burnTrx}
                          target="_blank"
                          rel="noreferrer"
                          className="max-width-250">
                          {data.burnTrx}
                        </a>
                      </td>
                      {/* <td className="right" data-label="Gas Fee">
                    <span>{data.gasFee}</span>
                  </td> */}
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BurnPage
