import { useState, useEffect } from "react"
import { useLocation } from "@reach/router"
import { useSelector, useDispatch } from "react-redux"
import { startLoader, stopLoader } from "../../redux/actions"
import moment from "moment"
import "../../assets/css/marketplace.css"
import "../../assets/css/collectiondetails.css"
import swal from "sweetalert"
import Web3 from "web3"
import Cookies from "universal-cookie"
import { capitalize } from "../../utils"
import constants from "../../constants/constants"
import nfmStakingAbi from "../../assets/blockchain/nfmStakingAbi.json"
import nftAbi from "../../assets/blockchain/nftAbi.json"

const cookies = new Cookies()

function SoldNFMDetailPage() {
  const location = useLocation()
  const dispatch = useDispatch()
  const [nfmItem, setNfmItem] = useState(null)
  const [fromPublic, setFromPublic] = useState(false)
  const [nfmContract, setNFMContract] = useState(null)
  const [nfmTokenId, setNFMTokenId] = useState(null)
  const [isApprovedTxAvailable, setIsApprovedTxAvailable] = useState(false)
  const [approvedTx, setApprovedTx] = useState(null)
  const collectionItem =
    useSelector(state => state.collection.clickedNFM) || null
  // 0-allowed to stake, 1-staked, 2-not owner
  const [stakeStatus, setStakeStatus] = useState(2)

  // 0-not approved, 1- approved
  const [isNFMContractApproved, setIsNFMContractApproved] = useState(0)

  const isNFTStaked = async () => {
    console.log("[nft][src][components][pages][isNFTStaked] Initiated")
    try {
      let web3 = new Web3(window.ethereum)
      console.log("[nft][src][components][pages][isNFTStaked] web3", web3)
      if (web3 != null) {
        // user wallet
        let accounts = await web3.eth.getAccounts()
        let wallet = accounts[0]
        console.log(
          "[nft][src][components][pages][isNFTStaked] Logged In user",
          accounts[0]
        )

        // Is the NFM owner logged in user?
        let NFMContract = await new web3.eth.Contract(
          nftAbi,
          collectionItem.contract_address
        )

        let isContractApproved = await NFMContract.methods
          .isApprovedForAll(wallet, constants.NFMSTAKING)
          .call()

        if (isContractApproved === true) {
          setIsNFMContractApproved(1)
        } else {
          setIsNFMContractApproved(0)
        }

        let NFMCurrentOwner = await NFMContract.methods
          .ownerOf(collectionItem.edition)
          .call()
        console.log(
          "[nft][src][components][pages][isNFTStaked] NFM Current Owner",
          NFMCurrentOwner
        )

        // NFMStaking contract
        let NFMStaking = await new web3.eth.Contract(
          nfmStakingAbi,
          constants.NFMSTAKING
        )

        let NFMCurrentStatus = await NFMStaking.methods
          .getNFMStakeStatus(
            accounts[0],
            collectionItem.contract_address,
            collectionItem.edition
          )
          .call()
        console.log(
          "[nft][src][components][pages][isNFTStaked] NFM Current Staking Status",
          NFMCurrentStatus
        )

        if (NFMCurrentStatus === true) {
          setStakeStatus(1)
          console.log(
            "[nft][src][components][pages][isNFTStaked] Stake Status:: Already Staked",
            stakeStatus
          )
        } else {
          if (NFMCurrentOwner.toLowerCase() == wallet.toLowerCase()) {
            console.log(
              "[nft][src][components][pages][isNFTStaked] Stake Status:: Allowed to Stake",
              stakeStatus
            )
            setStakeStatus(0)
          } else {
            console.log(
              "[nft][src][components][pages][isNFTStaked] Stake Status:: Not owner can't stake",
              stakeStatus
            )
            setStakeStatus(2)
          }
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const approveNFTStakingContract = async () => {
    console.log(
      "[nft][src][components][pages][approveNFTStakingContract] Initiated"
    )
    try {
      let web3 = new Web3(window.ethereum)
      if (web3 != null) {
        // user walelt
        let accounts = await web3.eth.getAccounts()
        let wallet = accounts[0]

        console.log(
          "[nft][src][components][pages][approveNFTStakingContract] Logged In user",
          wallet
        )

        let NFMContract = await new web3.eth.Contract(
          nftAbi,
          collectionItem.contract_address
        )

        await NFMContract.methods
          .setApprovalForAll(constants.NFMSTAKING, true)
          .send({ from: wallet })
          .on("transactionHash", function (hash) {
            swal({
              icon: "info",
              title: "Please wait",
              text: "Transaction initiated, Please wait for the transaction to confirm",
              showConfirmButton: true,
              showCancelButton: false,
              allowOutsideClick: true
            }).then(() => {
              refreshPage()
            })
            let txHash = `${constants.EXPLORER_URL}/tx/${hash}`
            waitTransactionReceipt(hash, "approve", 0, function (err, receipt) {
              if (err != null) {
                return
              }
            })
          })
          .on("error", function (error, receipt) {
            console.log(
              "[nft][src][components][pages][approveNFTStakingContract] Approve Tx Error",
              error
            )
            swal({
              icon: "error",
              title: "Failed",
              text: "Transaction cancelled. Please try again!",
              showConfirmButton: true,
              showCancelButton: false,
              allowOutsideClick: true
            }).then(() => {
              refreshPage()
            })
            setIsApprovedTxAvailable(false)
            return
          })
      }
    } catch (error) {
      console.log(error)
    }
  }

  const stakeNFM = async () => {
    console.log("[nft][src][components][pages][stakeNFM] Initiated")
    try {
      let web3 = new Web3(window.ethereum)
      if (web3 != null) {
        // user walelt
        let accounts = await web3.eth.getAccounts()
        let wallet = accounts[0]

        console.log(
          "[nft][src][components][pages][stakeNFM] Logged In user",
          wallet
        )

        let NFMStaking = await new web3.eth.Contract(
          nfmStakingAbi,
          constants.NFMSTAKING
        )

        await NFMStaking.methods
          .stake(collectionItem.contract_address, collectionItem.edition)
          .send({ from: wallet })
          .on("transactionHash", function (hash) {
            swal({
              icon: "info",
              title: "Please wait",
              text: "Transaction initiated, Please wait for the transaction to confirm",
              showConfirmButton: true,
              showCancelButton: false,
              allowOutsideClick: true
            })
            let txHash = `${constants.EXPLORER_URL}/tx/${hash}`
            waitTransactionReceipt(hash, "stake", 0, function (err, receipt) {
              if (err != null) {
                return
              }
            })
          })
          .on("error", function (error, receipt) {
            console.log(
              "[nft][src][components][pages][stakeNFM] Approve Tx Error",
              error
            )
            swal({
              icon: "error",
              title: "Failed",
              text: "Transaction cancelled. Please try again!",
              showConfirmButton: true,
              showCancelButton: false,
              allowOutsideClick: true
            }).then(() => {
              refreshPage()
            })
            setIsApprovedTxAvailable(false)
            return
          })
      }
    } catch (error) {
      console.log(error)
    }
  }

  const unStakeNFM = async () => {
    console.log("[nft][src][components][pages][unStakeNFM] Initiated")
    try {
      let web3 = new Web3(window.ethereum)
      if (web3 != null) {
        // user walelt
        let accounts = await web3.eth.getAccounts()
        let wallet = accounts[0]

        console.log(
          "[nft][src][components][pages][unStakeNFM] Logged In user",
          wallet
        )

        let NFMStaking = await new web3.eth.Contract(
          nfmStakingAbi,
          constants.NFMSTAKING
        )

        await NFMStaking.methods
          .unStake(collectionItem.contract_address, collectionItem.edition)
          .send({ from: wallet })
          .on("transactionHash", function (hash) {
            swal({
              icon: "info",
              title: "Please wait",
              text: "Transaction initiated, Please wait for the transaction to confirm",
              showConfirmButton: true,
              showCancelButton: false,
              allowOutsideClick: true
            })
            let txHash = `${constants.EXPLORER_URL}/tx/${hash}`
            waitTransactionReceipt(hash, "unstake", 0, function (err, receipt) {
              if (err != null) {
                return
              }
            })
          })
          .on("error", function (error, receipt) {
            console.log(
              "[nft][src][components][pages][unStakeNFM] Approve Tx Error",
              error
            )
            swal({
              icon: "error",
              title: "Failed",
              text: "Transaction cancelled. Please try again!",
              showConfirmButton: true,
              showCancelButton: false,
              allowOutsideClick: true
            }).then(() => {
              refreshPage()
            })
            setIsApprovedTxAvailable(false)
            return
          })
      }
    } catch (error) {
      console.log(error)
    }
  }

  const waitTransactionReceipt = async (
    transactionHash,
    actionType,
    timeOut,
    callback
  ) => {
    console.log(
      "[nft][src][components][pages][waitTransactionReceipt] transactionHash",
      transactionHash
    )
    var web3 = new Web3(window.ethereum)
    let self = this

    let timeOutCounter = 0
    let err = {
      message: ""
    }

    let isWaitRunning = false

    let getReceiptTimerId = setInterval(function () {
      if (isWaitRunning == false) {
        isWaitRunning = true
        web3.eth
          .getTransaction(transactionHash)
          .then(function (tx) {
            if (tx == null) {
              clearInterval(getReceiptTimerId)
              console.log(
                `[nft][src][components][pages][waitTransactionReceipt] transactionHash ${transactionHash} is not found`
              )
              err.message = "Transaction hash is not found."
              isWaitRunning = false
              if (callback) {
                callback(err, null)
              }
              return
            }

            if (tx.blockNumber == null) {
              if (timeOut > 0) {
                timeOutCounter++
                if (timeOutCounter >= timeOut) {
                  clearInterval(getReceiptTimerId)
                  isWaitRunning = false
                  err.message = "Timeout while waiting transaction to confirm."
                  if (callback) {
                    callback(err, null)
                  }
                  return
                } else {
                  console.log(
                    `[nft][src][components][pages][waitTransactionReceipt] transactionHash 1 ${transactionHash} is pending`
                  )
                  isWaitRunning = false
                  return
                }
              } else {
                console.log(
                  `[nft][src][components][pages][waitTransactionReceipt] transactionHash 2 ${transactionHash} is pending`
                )
                isWaitRunning = false
                return
              }
            }

            // Transaction confirm
            web3.eth
              .getTransactionReceipt(transactionHash)
              .then(async function (web3TxReceipt) {
                clearInterval(getReceiptTimerId)
                isWaitRunning = false
                if (web3TxReceipt == null) {
                  console.log(
                    `[nft][src][components][pages][waitTransactionReceipt] transactionHash 2 ${transactionHash} is not found`
                  )
                  swal({
                    icon: "error",
                    title: "Failed",
                    text: "Transaction Failed. Please try again!",
                    showConfirmButton: true,
                    showCancelButton: false,
                    allowOutsideClick: true
                  }).then(() => {
                    refreshPage()
                  })
                }
                console.log(
                  `[nft][src][components][pages][waitTransactionReceipt] Transaction receipt ${transactionHash}: ${web3TxReceipt}`
                )

                if (actionType == "approve") {
                  swal({
                    icon: "success",
                    title: "Approved",
                    text: "Transaction Successful",
                    showConfirmButton: true,
                    showCancelButton: false,
                    allowOutsideClick: true
                  }).then(() => {
                    refreshPage()
                  })
                } else if (actionType == "stake") {
                  swal({
                    icon: "success",
                    title: "Success",
                    text: "Staked Successful",
                    showConfirmButton: true,
                    showCancelButton: false,
                    allowOutsideClick: true
                  }).then(() => {
                    refreshPage()
                    dispatch(startLoader())
                  })
                } else if (actionType == "unstake") {
                  swal({
                    icon: "success",
                    title: "Success",
                    text: "Unstaked Successful",
                    showConfirmButton: true,
                    showCancelButton: false,
                    allowOutsideClick: true
                  }).then(() => {
                    refreshPage()
                    dispatch(stopLoader())
                  })
                }
              })
              .catch(function (error) {
                clearInterval(getReceiptTimerId)
                isWaitRunning = false
                console.log(
                  `[nft][src][components][components][modal][waitTransactionReceipt] Error on transaction receipt 1 ${transactionHash}: ${error}`
                )
                swal({
                  icon: "error",
                  title: "Cancelled",
                  text: error.message,
                  showConfirmButton: true,
                  showCancelButton: false,
                  allowOutsideClick: true
                }).then(() => {
                  refreshPage()
                })
              })
          })
          .catch(function (error) {
            clearInterval(getReceiptTimerId)
            console.log(
              `[nft][src][components][components][modal][waitTransactionReceipt] Error on transaction receipt 2 ${transactionHash}: ${error}`
            )
            swal({
              icon: "error",
              title: "Cancelled",
              text: error.message,
              showConfirmButton: true,
              showCancelButton: false,
              allowOutsideClick: true
            }).then(() => {
              refreshPage()
            })
          })
      }
    }, 1000)
  }

  const refreshPage = () => {
    window.location.reload()
  }

  useEffect(() => {
    if (collectionItem != null) {
      setNFMContract(collectionItem.contract_address)
      setNFMTokenId(collectionItem.edition)
      isNFTStaked()
    }
    setNfmItem(location.state?.soldNFM ?? collectionItem)
    setFromPublic(location.state?.fromPublic ?? fromPublic)
  }, [location.state])

  // console.log(nfmItem, "nfmItem");

  console.log(stakeStatus, "stakeStatus")

  return (
    <div>
      {/* New Page */}
      <div className="mt-120pxx container">
        <div className="nfm-detail-page">
          <div className="image-nfm">
            <img src={nfmItem?.image} alt="" />
          </div>
          <div className="nfm-detail-text">
            <div className="d-flex flex-column">
              <div>
                <h2 className="pink-text">{nfmItem?.name}</h2>
                {nfmItem?.edition ? (
                  <h3 className="pink-text">Edition : {nfmItem?.edition}</h3>
                ) : null}
                <p>{nfmItem?.description}</p>
              </div>

              {/* Allowed to stake & Contract Approved to Stake */}
              {stakeStatus === 0 && isNFMContractApproved === 1 && (
                <div
                  className="box-det"
                  style={{
                    maxWidth: "170px",
                    marginTop: "auto",
                    cursor: "pointer"
                  }}>
                  <div className="gradient-box">
                    <div className="field active">
                      <h5 className="border-field" onClick={stakeNFM}>
                        Stake NFM
                      </h5>
                    </div>
                  </div>
                </div>
              )}

              {/* Allowed to stake & Contract is not Approved to Stake */}
              {stakeStatus === 0 && isNFMContractApproved === 0 && (
                <div
                  className="box-det"
                  style={{
                    maxWidth: "170px",
                    marginTop: "auto",
                    cursor: "pointer"
                  }}>
                  <div className="gradient-box">
                    <div className="field active">
                      <h5
                        className="border-field"
                        onClick={approveNFTStakingContract}>
                        Approve to Stake
                      </h5>
                    </div>
                  </div>
                </div>
              )}

              {/* Already Staked - Unstake NFM */}
              {stakeStatus === 1 && !fromPublic && (
                <div
                  className="box-det"
                  style={{
                    maxWidth: "170px",
                    marginTop: "auto",
                    cursor: "pointer"
                  }}>
                  <div className="gradient-box">
                    <div className="field active">
                      <h5 className="border-field" onClick={unStakeNFM}>
                        Unstake NFM
                      </h5>
                    </div>
                  </div>
                </div>
              )}

              {/* Already Staked */}
              {/*{stakeStatus === 2 && (*/}
              {/*  // <div*/}
              {/*  //   class="box-det"*/}
              {/*  //   style={{ maxWidth: "170px", marginTop: "auto" }}*/}
              {/*  // >*/}
              {/*  //   <div class="gradient-box">*/}
              {/*  //     <div class="field active">*/}
              {/*  //       <h5 class="border-field" disabled>*/}
              {/*  //         -NA-*/}
              {/*  //       </h5>*/}
              {/*  //     </div>*/}
              {/*  //   </div>*/}
              {/*  // </div>*/}
              {/*// )}*/}

              <div className="stake-nfm-btn"></div>
            </div>
            <div className="key-values">
              <h3>Details:</h3>
              {/* {nfmItem?.dna ? (
                <div className="dfjb">
                  <p>DNA</p>
                  <p style={{ maxWidth: "15vw", wordWrap: "break-word" }}>
                    {nfmItem?.dna}
                  </p>
                </div>
              ) : null} */}
              {nfmItem?.tier ? (
                <div className="dfjb">
                  <p>Tier</p>
                  <p>{nfmItem?.tier}</p>
                </div>
              ) : null}
              {nfmItem?.compiler ? (
                <div className="dfjb">
                  <p>Compiler</p>
                  <p>{nfmItem?.compiler}</p>
                </div>
              ) : null}
              {nfmItem?.price ? (
                <div className="dfjb">
                  <p>Value</p>
                  <p>$ 750</p>
                </div>
              ) : null}
              {nfmItem?.collection_date ? (
                <div className="dfjb">
                  <p>Collection Date</p>
                  <p>
                    {moment(new Date(Number(nfmItem?.collection_date))).format(
                      "DD-MM-YYYY"
                    )}
                  </p>
                </div>
              ) : null}
            </div>
          </div>
        </div>

        {/* Attributes */}
        <div className="attributes-nfm-details">
          <h3 className="pink-text my-3">Attribute value</h3>
          <div className="attributes-boxes">
            {(nfmItem?.attributes ?? []).map((attribute, i) => (
              <div className="att-box" key={i}>
                <p>{capitalize(attribute?.trait_type)}</p>
                <p>{attribute?.value}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default SoldNFMDetailPage
