import React from "react"
import { Images } from "../../Shared"
const JoinCommunity = () => {
  const communityLinks = [
    {
      name: "Discord",
      image: Images.discordGateway,
      link: "https://discord.com/invite/8kQknUCFHs"
    },
    {
      name: "Twitter",
      image: Images.twitterGateway,
      link: "https://twitter.com/Nitro_HQ"
    },
    {
      name: "Linkedin",
      image: Images.linkedinGateway,
      link: "https://www.linkedin.com/company/nitronetwork/"
    },
    {
      name: "Facebook",
      image: Images.facebookGateway,
      link: "https://www.facebook.com/NitroNetworkHQ/"
    },
    {
      name: "Medium",
      image: Images.mediumGateway,
      link: "https://nitronetwork.medium.com/"
    }
  ]
  return (
    <div className="join-community">
      <h2 className="head-second">
        Become a Member of a <br />{" "}
        <span className="color-primary">Thriving IoT Community</span>{" "}
      </h2>

      {/* Community ICON */}
      <div className="community-icons">
        {communityLinks.map(link => {
          return (
            <a
              key={link.name}
              href={link.link}
              target="_blank"
              rel="noopener noreferrer"
              className="gateway-icon">
              <img src={link.image} alt={link.name} />
              <p>{link.name}</p>
            </a>
          )
        })}
      </div>
    </div>
  )
}

export default JoinCommunity
