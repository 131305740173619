import React from "react"
import useCasesData from "../../assets/htmlPages/usecasesData.js"
import { useNavigate } from "@reach/router"
// import $ from "jquery"
function WPUseCases() {
  const navigate = useNavigate()
  const filterdUsecasesData = useCasesData.filter(val => val?.hidden !== true)

  return (
    <>
      <div className="usecases-page PT-90px">
        <h2 className="heading-secondary">Usecases</h2>

        <p>Explore our wide range of use cases for IoT.</p>

        <div className="usecases__boxes">
          {filterdUsecasesData.map(useCase => (
            <div key={useCase.slug} className="usecases__box">
              <img src={useCase.image} alt={useCase.title} />
              <div className="usecases__text">
                <h3>{useCase.title}</h3>
                <p>{useCase?.description}</p>
                <button
                  className="btn-brand btn-white mx-w "
                  onClick={() => navigate(`/usecase/${useCase.slug}`)}>
                  View Details
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default WPUseCases
