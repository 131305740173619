// Default B2C
import swimmingB2C from "../htmlPages/usecasesContent/swimming-pool-monitoring.json"
import swimmingB2B from "../htmlPages/usecasesContent/swimming-pool-monitoring-enterprise.json"

import coldStorageB2C from "../htmlPages/usecasesContent/cold-storage-monitoring.json"
import coldStorageB2B from "../htmlPages/usecasesContent/cold-storage-monitoring-enterprise.json"

import smartWasteManageB2C from "../htmlPages/usecasesContent/smart-waste-management.json"
import smartWasteManageB2B from "../htmlPages/usecasesContent/smart-waste-management-enterprise.json"

import waterTankMonitorB2C from "../htmlPages/usecasesContent/water-tank-monitoring.json"
import waterTankMonitorB2B from "../htmlPages/usecasesContent/water-tank-monitoring-enterprise.json"

const usecasesData = [
  //Water Tank
  {
    title: "Water Tank Monitoring",
    description:
      "Nitro Water Tank Monitoring is the one stop shop solution for all your tank monitoring. With the help...",
    slug: "water-tank-monitoring",
    image: `https://nitroimages.s3.ap-south-1.amazonaws.com/usecases/water-tank-management-landing.png`,
    content: waterTankMonitorB2C.content
  },
  {
    title: "Water Tank Monitoring",
    slug: "water-tank-monitoring-enterprise",
    image: `https://nitroimages.s3.ap-south-1.amazonaws.com/blogs/2023/01/Frame-226.png`,
    content: waterTankMonitorB2B.content,
    hidden: true
  },
  //Smart Waste Management B2B B2B
  {
    title: "Smart Waste Management",
    slug: "smart-waste-management",
    description:
      "Smart waste management is an innovative approach to handling and collecting waste. ",
    image: `https://nitroimages.s3.ap-south-1.amazonaws.com/blogs/2023/01/image-11.png`,
    content: smartWasteManageB2C.content
  },
  {
    title: "Smart Waste Management",
    slug: "smart-waste-management-enterprise",
    image: `https://nitroimages.s3.ap-south-1.amazonaws.com/blogs/2023/01/image-11.png`,
    content: smartWasteManageB2B.content,
    hidden: true
  },
  //Cold Storage B2C B2B
  {
    title: "Cold Storage Monitoring",
    slug: "cold-storage-monitoring",
    description:
      "With the help of Nitro Cold Storage Monitoring you can monitor and get real-time updates...",
    content: coldStorageB2C.content,
    image:
      "https://nitroimages.s3.ap-south-1.amazonaws.com/usecases/cold-storage-monitoring-landing.png"
  },
  {
    title: "Cold Storage Monitoring",
    slug: "cold-storage-monitoring-enterprise",
    content: coldStorageB2B.content,
    image:
      "https://nitroimages.s3.ap-south-1.amazonaws.com/blogs/2023/01/Mask2.png",
    hidden: true
  },
  //Swimming pools B2C, B2B
  {
    title: "Swimming Pool Monitoring",
    slug: "swimming-pool-monitoring",
    description: "We love swimming, but we love safety even more.",
    content: swimmingB2C.content,
    image:
      "https://nitroimages.s3.ap-south-1.amazonaws.com/blogs/2023/01/box-img1.png"
  },
  {
    title: "Swimming Pool Enterprise",
    slug: "swimming-pool-monitoring-enterprise",
    content: swimmingB2B.content,
    image:
      "https://nitroimages.s3.ap-south-1.amazonaws.com/blogs/2023/01/box-img1.png",
    hidden: true
  }
]

export default usecasesData
