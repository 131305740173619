import React, { useState, useEffect, createRef, useRef } from "react"
import Footer from "../components/footer"
import { createGlobalStyle } from "styled-components"
import { Form, Input, Modal, Select, Button } from "antd"
import { BaseUrl } from "../../utils/config"
import { useCookies } from "react-cookie"
import axios from "axios"
import swal from "sweetalert"
import { useNavigate } from "@reach/router"
import { createBrowserHistory, History } from "history"
import { Link, useHistory, useLocation } from "react-router-dom"
import { connect, useSelector, useDispatch } from "react-redux"
import {
  setActiveMenu,
  setConnectedWallet,
  setIoTTokens,
  setStakeTokenData,
  setTokenData,
  updateUserDetailsRedux
} from "../../redux/actions"
import ReCAPTCHA from "react-google-recaptcha"
import { IoTAPIUrl } from "../../utils/config"
import { useLogout } from "../../hooks"

const GlobalStyles = createGlobalStyle`
  header#myHeader.navbar.white {
    background: #212428;
  }
`

const Logintwo = ({ updateUserDetails }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const logoutHandler = useLogout()

  const userDetails = useSelector(state => state.common.userDetails)
  const iotTokens = useSelector(state => state.common.iotTokens) || []
  const history = createBrowserHistory()
  const [isPass, SetIsPass] = useState(false)
  const [isEmail, setIsEmail] = useState(false)
  const [isTemp, setTemp] = useState(false)
  const location = useLocation()
  const [error, setError] = useState("")
  const [captchaToken, setCatpchaToken] = useState("")

  const [cookies, setCookie, removeCookie] = useCookies(["user"])
  // window.navigateState(null, document.title, window.location.href);
  const locationFrom = localStorage.getItem("locationFrom")
  const locationFromPId = localStorage.getItem("locationFromPId")

  const recaptchaRef = createRef()
  const ranOnce = useRef(false)
  const captchaSiteKey = "6Lehjh8eAAAAABkj0_A1-xndH8NVsLUe1763sN5v"

  // Getting IoT tokens string
  let QIotTokens = new URLSearchParams(window.location.search)?.get("iotTokens")
  // Sanitizing Tokens string
  QIotTokens = QIotTokens?.replace(/"|'/gim, "")
  // Converting tokens to array
  QIotTokens = QIotTokens ? QIotTokens.split(",") : []

  useEffect(() => {
    if (userDetails?.loginUserToken && iotTokens.length && !ranOnce.current) {
      assignOrdersToUser(
        userDetails?.loginUserId,
        userDetails?.loginUserMail,
        userDetails?.loginUserToken
      )

      ranOnce.current = true
    }
  }, [iotTokens])

  useEffect(async () => {
    !iotTokens.length && dispatch(setIoTTokens(QIotTokens))
    if (userDetails.loginUserToken) {
      if (
        userDetails.loginUserpending ||
        localStorage.getItem("buyNitroToken") === ""
      ) {
        navigate("/myaccount/pendingpayments")
      } else {
        navigate("/myaccount/mycollection")
      }
    }
    let path = location.pathname
    let temp = path.substring(path.lastIndexOf("/") + 1)

    // localStorage.clear();
    const params = new URLSearchParams(window.location.search) // id=123
    let id = params.get("token")

    id && window.localStorage.setItem("nestToken", id)
    temp === "login"
      ? setTemp(localStorage.getItem("nestToken"))
      : pushLogin(id)
    pushLogin(id)
    removeCookie("user")
  }, [])

  const pushLogin = id => {
    const queryParams = new URLSearchParams(location.search)
    if (queryParams.has("token")) {
      setError("There was a problem.")
      queryParams.delete("token")
      history.replace({
        search: queryParams.toString()
      })
    }
  }

  const storeUserDetails = response => {
    window.localStorage.setItem("loginUser", response.data.data.name)
    window.localStorage.setItem("loginUserId", response.data.data.id)
    window.localStorage.setItem("loginUserMail", response.data.data.email)
    window.localStorage.setItem(
      "loginUserpending",
      response.data.data.is_payment_pending
    )
    window.localStorage.setItem(
      "loginUserPreminer",
      response.data.data.is_preminer
    )
    window.localStorage.setItem("loginUserToken", response.data.data.token)
  }

  const assignOrdersToUser = (id, email, token) => {
    const requestData = {
      userId: id,
      email: email,
      orderTokens: iotTokens
    }

    axios
      .post(`${IoTAPIUrl}/assignOrderToUser`, requestData, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then(orderResponse => {
        if (orderResponse.status === 200) {
          console.log("assigned order to user", requestData)
          dispatch(setActiveMenu("IOT"))
          dispatch(setIoTTokens([]))
          navigate("/myaccount/pendingOrders")
        }
      })
      .catch(error => {
        if (error?.response?.status === 401) {
          logoutHandler()
        } else {
          dispatch(setActiveMenu("NFM"))
        }
      })
  }

  const onFinish = values => {
    const Fobj = {
      email: values.email,
      password: values.password,
      token: captchaToken
    }

    if (
      values.email == null ||
      values.password == null ||
      values.password == ""
    ) {
      swal({
        // title: "Good job!",
        text: "Please enter the Credentials",
        icon: "warning"
      })
    } else {
      // dispatch(getLogin(values));
      axios
        .post(`${BaseUrl}/login`, Fobj)
        .then(response => {
          if (response.status == 200) {
            dispatch(setConnectedWallet(""))
            dispatch(setTokenData([]))
            dispatch(setStakeTokenData([]))
            storeUserDetails(response)

            updateUserDetails({
              loginUser: response.data.data.name,
              loginUserId: response.data.data.id,
              loginUserMail: response.data.data.email,
              loginUserpending: response.data.data.is_payment_pending,
              loginUserPreminer: response.data.data.is_preminer,
              loginUserToken: response.data.data.token
            })
            isTemp &&
              callForNest(
                values.email,
                response.data.data.id,
                response.data.data.token
              )

            if (localStorage.getItem("buyNitroToken")) {
              let buyOrderToken = localStorage.getItem("buyNitroToken")
              axios
                .get(`${BaseUrl}/addorder/${buyOrderToken}`, {
                  headers: {
                    Authorization: `Bearer ${response.data.data.token}`
                  }
                })
                .then(resp => {
                  if (resp.status === 200) {
                    updateUserDetails({
                      loginUser: response.data.data.name,
                      loginUserId: response.data.data.id,
                      loginUserMail: response.data.data.email,
                      loginUserpending: true,
                      loginUserPreminer: response.data.data.is_preminer,
                      loginUserToken: response.data.data.token
                    })
                    localStorage.setItem("buyNitroToken", "")
                  }
                })
            }

            if (localStorage.getItem("placesToken")) {
              let placesToken = localStorage.getItem("placesToken")
              axios
                .post(
                  `${BaseUrl}/addplace/${placesToken}`,
                  {
                    email: response.data.data.email,
                    user_id: response.data.data.id
                  },
                  {
                    headers: {
                      Authorization: `Bearer ${response.data.data.token}`
                    }
                  }
                )
                .then(response => {
                  if (response) {
                    localStorage.setItem("placesToken", null)
                  }
                })
            }

            if (iotTokens?.length) {
              assignOrdersToUser(
                response.data.data.id,
                response.data.data.email,
                response.data.data.token
              )
            } else {
              dispatch(setActiveMenu("NFM"))
            }

            if (
              response?.data?.data?.name &&
              !localStorage.getItem("placesToken") &&
              !iotTokens.length
            ) {
              response.data.data.is_payment_pending ||
              Boolean(localStorage.getItem("buyNitroToken"))
                ? navigate("/myaccount/pendingpayments")
                : locationFrom === "productDetails"
                ? navigate({
                    pathname: `/productDetails`,
                    state: { selectedId: locationFromPId }
                  })
                : navigate("/myaccount/mycollection")
            } else if (localStorage.getItem("placesToken")) {
              navigate("/myaccount/mynest")
            } else {
              dispatch(setActiveMenu("IOT"))
              navigate("/myaccount/pendingOrders")
            }

            swal({
              title: "Success!",
              text: "Welcome! You are logged in successfully.",
              icon: "success"
            })
          } else if (response.status == 401) {
            dispatch(updateUserDetailsRedux({}))
            dispatch(setConnectedWallet(""))
            localStorage.clear()
            navigate("/login")
            recaptchaRef.current.reset()
            setCatpchaToken("")
          } else if (response.status == 404) {
            swal({
              title: "Error!",
              text: "Page not found. Please try again later.",
              icon: "error"
            })
            recaptchaRef.current.reset()
            setCatpchaToken("")
          } else if (response.status == 500) {
            swal({
              title: "Error!",
              text: "Something went wrong. Please try again later.",
              icon: "error"
            })
            recaptchaRef.current.reset()
            setCatpchaToken("")
          } else if (response.status == 503) {
            swal({
              title: "Error!",
              text: "Something went wrong. Please try again later.",
              icon: "error"
            })
            recaptchaRef.current.reset()
            setCatpchaToken("")
          } else {
            swal({
              title: "Invalid credentials",
              text: "Please try again.",
              icon: "error"
            })
            recaptchaRef.current.reset()
            setCatpchaToken("")
          }
        })
        .catch(error => {
          if (error?.response?.status === 401) {
            swal({
              title: "Invalid credentials",
              text: "Please try again.",
              icon: "error"
            })
            dispatch(updateUserDetailsRedux({}))
            dispatch(setConnectedWallet(""))
            localStorage.clear()
          } else {
            swal({
              title: "Error!",
              text: "Something went wrong. Please try again!",
              icon: "error"
            })
            recaptchaRef.current?.reset()
            setCatpchaToken("")
          }
        })
    }
  }
  const callForNest = (email, userid, userToken) => {
    const Fobj = {
      email: email,
      user_id: userid
    }
    axios
      .post(`${BaseUrl}/addplace/${isTemp}`, Fobj, {
        headers: {
          // 'Content-Type': 'application/json',
          Authorization: `Bearer ${userToken}`
        }
      })
      .then(response => {
        if (response) {
        } else {
        }
      })
      .catch(error => {
        swal({
          title: "Invalid credentials",
          text: "Please try again",
          icon: "error"
        })
      })
  }

  const toLogin = () => {
    // navigate("/login")
  }

  const toSignup = () => {
    navigate("/verifyEmail")
  }
  const toforgot = () => {
    navigate("/forgotPassword")
  }

  return (
    <div className="Login-page">
      <div className="Login-page__box">
        <h3 className="mb-3">Sign in</h3>
        {/* <p>Login using an existing account or create a new account <span onClick={toSignup}>here</span>.</p> */}

        <Form
          name="contactForm"
          id="contact_form"
          className=""
          action=""
          autoComplete="off"
          onFinish={onFinish}>
          <div className="field-set">
            <Form.Item
              name="email"
              rules={[
                {
                  type: "email",
                  message: "The input is not valid Email!"
                },
                {
                  required: true,
                  message: "Email is required."
                }
              ]}>
              <Input
                id="email"
                placeholder="Enter email"
                className="form-control"
                onChange={e => {
                  setIsEmail(e.target.value)
                }}
              />
            </Form.Item>
          </div>
          <div className="field-set pass-field">
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: "Password is required."
                }
              ]}>
              <Input.Password
                className="form-control"
                type="password"
                placeholder="Enter Password"
                onChange={e => {
                  SetIsPass(e.target.value)
                }}
              />
            </Form.Item>
          </div>

          <div className="field-set">
            <Form.Item
              name="captcha"
              rules={[
                {
                  validator: (_, value) =>
                    captchaToken.length
                      ? Promise.resolve()
                      : Promise.reject(new Error("Captcha is required."))
                }
              ]}>
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={captchaSiteKey}
                onChange={val => setCatpchaToken(val)}
              />
            </Form.Item>
          </div>

          <div id="submit">
            <input
              type="submit"
              id="send_message"
              value="Sign in"
              disabled={isEmail && isPass ? false : true}
              className="btn-brand btn-active color-primary"
            />
          </div>

          <div className="dfjb mobile-issue-fix">
            <p>
              {" "}
              <a className="colorPurple" onClick={toforgot}>
                {" "}
                Forgot Password?
              </a>
            </p>
            <p>
              New user?{" "}
              <a className="colorPurple" onClick={toSignup}>
                Sign up
              </a>
            </p>
          </div>
        </Form>
      </div>

      {/* <Footer /> */}
    </div>
  )
}

const mapDispatchToProps = dispatch => {
  return {
    updateUserDetails: payload => dispatch(updateUserDetailsRedux(payload))
  }
}

export default connect(null, mapDispatchToProps)(Logintwo)
