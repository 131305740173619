import React from "react"
import { HashLink as Link } from "react-router-hash-link"
// import "../../assets/css/style.css";
// import "../../assets/css/stake.css"
import "../../assets/css/landing.css"
import "../../assets/css/responsive.css"
import "../../assets/css/pendingPayments.css"
// import HeaderNewBuy from "./headerNewBuy";
import { Images } from "../../Shared/images"
import Footer from "../components/footer"
import { Country } from "country-state-city"
import SelectDropDown from "../components/SelectDropdown"
import ColumnGroup from "antd/lib/table/ColumnGroup"
import { useEffect, useState } from "react"
import Checkbox from "antd/lib/checkbox/Checkbox"
import { BaseUrl } from "../../utils/config"
import axios from "axios"
import swal from "sweetalert"
import { useNavigate } from "@reach/router"
import { useSelector, useDispatch } from "react-redux"
import addCommas from "add-commas"
import { isFloat } from "../../utils"
import {
  setActiveMenu,
  setConnectedWallet,
  updateUserDetailsRedux
} from "../../redux/actions"
import nitroImage from "../../assets/images/NITROBANNER.JPG"

function BuyNitro(props) {
  let country = Country?.getAllCountries()
  let countries = country?.map(value => value)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const userData = useSelector(state => state.common.userDetails)
  const tierOptions = [
    {
      label: "ICONIC (TIER 1) - $750",
      value: 750,
      id: 1,
      data: "ICONIC (TIER 1)",
      quantity: 1
    },
    {
      label: "PREMIUM (TIER 2) - $650",
      value: 650,
      id: 2,
      data: "PREMIUM (TIER 2)",
      quantity: 1
    },
    {
      label: "TOP LOCATION (TIER 3) - $450",
      value: 450,
      id: 3,
      data: "TOP LOCATION (TIER 3)",
      quantity: 1
    }
  ]

  const [totalAmount, setTotalAmount] = useState(0)

  const [tierDropdownData, setTierDropDownData] = useState([])
  const [checkedTerms, setCheckedTerms] = useState(false)
  const [tierOneData, setTierOneData] = useState({
    price: 750,
    quantity: 0,
    disabled: false
  })
  const [tierTwoData, setTierTwoData] = useState({
    price: 650,
    quantity: 1,
    disabled: false
  })

  const [tierThreeData, setTierThreeData] = useState({
    price: 450,
    quantity: 0,
    disabled: false
  })

  const [isDisabled, setIsDisabled] = useState(true)
  const scrollToBottom = () => {
    window.scrollTo(0, 1)
  }

  const decrementHandler = (value, index) => {
    let currentQuantity = value?.quantity
    if (!(currentQuantity == 1)) {
      let tempArr = tierDropdownData?.slice()
      tempArr[index].quantity = --currentQuantity
      setTierDropDownData(tempArr)
    }
  }

  const incrementHandler = (value, index) => {
    let currentQuantity = value?.quantity
    let tempArr = tierDropdownData?.slice()
    tempArr[index].quantity = ++currentQuantity
    setTierDropDownData(tempArr)
  }

  let sum = 0

  useEffect(() => {
    document.title = "Buy Nitro NFM"
    return () => {
      document.title = "Nitro Network"
    }
  }, [])

  useEffect(() => {
    if (tierDropdownData && tierDropdownData?.length) {
      let tempArr = [...tierDropdownData]
      let total = tempArr.map(value =>
        Object.assign({}, { sum: value.value * value.quantity })
      )
    }
    setTotalAmount(sum / 2)
  }, [tierDropdownData])

  useEffect(() => {
    let tierOneTotal = tierOneData.quantity * tierOneData.price
    let tierTwoTotal = tierTwoData.quantity * tierTwoData.price
    let tierThreeTotal = tierThreeData.quantity * tierThreeData.price
    let totalAmount = tierOneTotal + tierTwoTotal + tierThreeTotal
    setTotalAmount(totalAmount / 2)
  }, [tierOneData, tierTwoData, tierThreeData])
  const [counter, setCounter] = useState(1)
  const onChangeCheck = e => {
    setCheckedTerms(e.target.checked)
  }
  const toLinks = item => {
    window.open(item)
  }

  const proceedFunction = () => {
    let loginToken = userData?.loginUserToken
    let data = {
      tier: {
        "tier-1": tierOneData?.quantity,
        "tier-2": tierTwoData?.quantity,
        "tier-3": tierThreeData?.quantity
      }
    }
    var config = {
      method: "post",
      url: `${BaseUrl}/preorder`,
      headers: {
        "Content-Type": "application/json"
      },
      data: data
    }
    if (totalAmount == 0) {
      swal({
        title: "Warning!",
        text: "Please select atleast one item to move forward.",
        icon: "warning"
      })
    } else if (!checkedTerms) {
      swal({
        title: "Warning!",
        text: "Please agree to terms and conditions.",
        icon: "warning"
      })
    } else {
      axios(config)
        .then(response => {
          if (response.status == 200) {
            if (loginToken) {
              axios
                .get(`${BaseUrl}/addorder/${response.data.token}`, {
                  headers: {
                    Authorization: `Bearer ${loginToken}`
                  }
                })
                .then(addOrderResponse => {
                  if (addOrderResponse.status == 200) {
                    navigate("/myaccount/pendingpayments")
                    dispatch(setActiveMenu("NFM"))
                  } else {
                    swal({
                      title: "Error!",
                      text: "Something went wrong. Please try again later.",
                      icon: "error"
                    })
                  }
                })
                .catch(error => {
                  if (error?.response?.status === 401) {
                    dispatch(updateUserDetailsRedux({}))
                    localStorage.clear()
                    dispatch(setConnectedWallet(""))
                    localStorage.setItem("buyNitroToken", response.data.token)
                    navigate("/login")
                  }
                })
            } else {
              localStorage.clear()
              localStorage.setItem("buyNitroToken", response.data.token)
              navigate("/login")
            }
          } else if (response.status == 401) {
            dispatch(updateUserDetailsRedux({}))
            dispatch(setConnectedWallet(""))
            localStorage.clear()
            localStorage.setItem("buyNitroToken", response.data.token)
            navigate("/login")
          } else if (response.status == 404) {
            swal({
              title: "Error!",
              text: "Page not found. Please try again later.",
              icon: "error"
            })
          } else if (response.status == 500) {
            swal({
              title: "Error!",
              text: "Something went wrong. Please try again later.",
              icon: "error"
            })
          } else if (response.status == 503) {
            swal({
              title: "Error!",
              text: "Something went wrong. Please try again later.",
              icon: "error"
            })
          } else {
            swal({
              title: "Failed",
              text: "Something went wrong.",
              icon: "error"
            })
          }
        })
        .catch(error => {
          if (error?.response?.status === 401) {
            dispatch(updateUserDetailsRedux({}))
            localStorage.clear()
            dispatch(setConnectedWallet(""))
            navigate("/login")
          } else
            swal({
              title: "Failed",
              text: "Something went wrong.",
              icon: "error"
            })
        })
    }
  }

  return (
    <div>
      {/* <HeaderNewBuy /> */}
      <div className="main-page-section">
        <div className="main-page container">
          <div className="text-side">
            <div className="logo" />
            <h1>Introducing Non-Fungible Miners On The Nitro Network</h1>
            {/* <a
              href="#form"
              className="pre-order-btn"
              onClick={() => scrollToBottom()}
            >
              Preorder Now
            </a> */}

            <Link smooth to="/buyNitro/#form" className="pre-order-btn">
              Preorder Now
            </Link>
          </div>
          <div className="image-side">
            <img src={nitroImage} alt="" />
          </div>
        </div>
      </div>
      <div className="what-nfm-section container">
        <h1 className="text-center">What's a Non-Fungible Miner?</h1>
        <div className="what-nfm">
          <div className="what-nfm-text-side">
            <p>
              Rather than using the terms digital miner or NFT, Nitro Network
              has pivoted to the term Non-Fungible Miner (NFM). NFMs have some
              of the most original and innovative properties in blockchain. They
              are a synthesis of digital miners and NFTs, making them original
              and dynamic. NFMs are also far more accessible and cost-effective
              than the mining protocols on legacy networks. Distinguishing it
              from a digital miner or NFT is necessary, given the Non-Fungible
              Miner’s distinctive and innovative properties.
            </p>
          </div>
          <div className="what-nfm-image-side">
            <div className="image">
              <img src={Images.whatNfm} alt="#" />
            </div>
            {/* <a href="#form" className="pre-order-btn yellow">
              Preorder Now{" "}
            </a> */}
            <Link smooth to="/buyNitro/#form" className="pre-order-btn yellow">
              Preorder Now
            </Link>
          </div>
        </div>
      </div>
      <div className="what-nfm-section container">
        <h1 className="text-center">How Non-Fungible Miner Rewards Work</h1>
        <div className="what-nfm reverse">
          <div className="what-nfm-text-side">
            <p>
              Nitro mines NCash each EPOCH (thirty minutes interval), so you are
              rewarded every thirty minutes.
            </p>
            <p>
              It’s as simple as that. Earnings and rewards are relative to which
              tier you sign up for.
            </p>
          </div>
          <div className="what-nfm-image-side">
            <div className="image">
              <img src={Images.mining} alt="#" />
            </div>
          </div>
        </div>
        {/* <a href="#form" className="pre-order-btn green">
          Preorder Now{" "}
        </a> */}
        <Link smooth to="/buyNitro/#form" className="pre-order-btn green">
          Preorder Now
        </Link>
      </div>
      <div className="p-section container">
        <p>
          Non-fungible miners have arrived at the perfect time in the course of
          blockchain history. DeFi and NFTs have catapulted into the mainstream.
          The non-fungible miner (NFM) is created from the synthesis of
          decentralized finance and non-fungible tokens, the two most
          influential spaces in blockchain. The NFM ecosystem, with its IoT
          solutions, harnesses the limitless energy of these exciting fields.
          And with its ethos of fair play, anyone who participates wins.
        </p>
      </div>

      <div className="buy-section" id="form">
        <h1 className="text-center">Place your order</h1>

        {/* <form> */}
        {/* <div className="form-field">
             <label for="tier">Tier</label> 
             <select name="tier" id="tier">
              <option value="none">Select Tier</option>
              <option value="t1">ICONIC (TIER 1) - $750</option>
              <option value="t2">PREMIUM (TIER 2) - $650</option>
              <option value="t3">TOP LOCATION (TIER 3) - $450</option>
            </select> *
            <SelectDropDown
              options={tierOptions}
              className="form-field"
              isSearchable={true}
              isClearable={true}
              isMulti={true}
              name="Tier"
              style={{ color: "black" }}
              changeHandler={(e) => {
                setTierDropDownData(e);
              }}
            />
          </div> */}

        {/* <div className="half-fields">
        <div className="form-field"> */}
        {/* <label for="fnmae">First Name</label> */}
        {/* <input type="text" placeholder="First Name" id="fname" />
        </div>
        <div className="form-field"> */}
        {/* <label for="fnmae">Last Name</label> */}
        {/* <input type="text" placeholder="Last Name" id="lname" />
        </div>
      </div> */}

        {/* <div className="half-fields">
        <div className="form-field"> */}
        {/* <label for="email">Email</label> */}
        {/* <input type="text" placeholder="Email Address" id="email" />
        </div> */}
        {/* <div className="form-field">
           <label for="country">Country</label> 
          <SelectDropDown
            id="tier"
            options={countries?.map((t) => ({
              value: t.isoCode,
              label: t.name,
            }))}
            className="form-field"
            isSearchable={true}
            isClearable={true}
            name="Country"
            style={{ color: "black" }}
            changeHandler={(e) => {
              changeHandler(e);
              props.setFieldValue("Country", e);
            }}
          />
        </div>  */}
        <div className="three-boxes-nfts">
          {/* onebox */}
          <div className="box-nft-buy">
            <h2>Iconic</h2>
            <div className="buy-nft-image">
              <img
                src={tierOneData.disabled ? Images.sold : Images.secondnft}
                alt="Tier 1"
              />
            </div>
            <h3>
              <strike>$ 750</strike>&nbsp;$ 375
            </h3>
            <div className="counter">
              <p
                className={"minus" + (tierOneData.disabled ? " disabled" : "")}
                onClick={
                  tierOneData.disabled
                    ? () => {}
                    : () => {
                        let data = { ...tierOneData }
                        let quantity = data.quantity
                        if (!(quantity == 0)) {
                          data.quantity = --quantity
                          setTierOneData(data)
                        }
                      }
                }>
                <i className="fa fa-minus"></i>
              </p>
              <input
                type="number"
                name="update"
                id="number-update"
                min="11"
                value={tierOneData.quantity}
              />
              <p
                className={"plus" + (tierOneData.disabled ? " disabled" : "")}
                onClick={
                  tierOneData.disabled
                    ? () => {}
                    : () => {
                        let data = { ...tierOneData }
                        let quantity = data.quantity
                        data.quantity = ++quantity
                        setTierOneData(data)
                      }
                }>
                <i className="fa fa-plus"></i>
              </p>
            </div>
          </div>
          {/* onebox end */}

          <div className="box-nft-buy">
            <h2>Premium</h2>
            <div className="buy-nft-image">
              <img
                src={tierTwoData.disabled ? Images.sold : Images.firstnft}
                alt="Tier 1"
              />
            </div>

            <h3>
              <strike>$ 650</strike>&nbsp;$ 325
            </h3>
            <div className="counter">
              <p
                className={"minus" + (tierTwoData.disabled ? " disabled" : "")}
                onClick={
                  tierTwoData.disabled
                    ? () => {}
                    : () => {
                        let data = { ...tierTwoData }
                        let quantity = data.quantity
                        if (!(quantity == 0)) {
                          data.quantity = --quantity
                          setTierTwoData(data)
                        }
                      }
                }>
                <i className="fa fa-minus"></i>
              </p>
              <input
                type="number"
                name="update"
                id="number-update"
                min="11"
                value={tierTwoData?.quantity}
              />
              <p
                className={"plus" + (tierTwoData.disabled ? " disabled" : "")}
                onClick={
                  tierTwoData.disabled
                    ? () => {}
                    : () => {
                        let data = { ...tierTwoData }
                        let quantity = data.quantity
                        data.quantity = ++quantity
                        setTierTwoData(data)
                      }
                }>
                <i className="fa fa-plus"></i>
              </p>
            </div>
          </div>
          <div className="box-nft-buy">
            <h2>Top</h2>
            <div className="buy-nft-image">
              <img
                src={tierThreeData.disabled ? Images.sold : Images.thirdnft}
                alt="Tier 1"
              />
            </div>

            <h3>
              <strike>$ 450</strike>&nbsp;$ 225
            </h3>
            <div className="counter">
              <p
                className={
                  "minus" + (tierThreeData.disabled ? " disabled" : "")
                }
                onClick={
                  tierThreeData.disabled
                    ? () => {}
                    : () => {
                        let data = { ...tierThreeData }
                        let quantity = data.quantity
                        if (!(quantity == 0)) {
                          data.quantity = --quantity
                          setTierThreeData(data)
                        }
                      }
                }>
                <i className="fa fa-minus"></i>
              </p>
              <input
                type="number"
                name="update"
                id="number-update"
                min="11"
                value={tierThreeData?.quantity}
              />
              <p
                className={"plus" + (tierThreeData.disabled ? " disabled" : "")}
                onClick={
                  tierThreeData.disabled
                    ? () => {}
                    : () => {
                        let data = { ...tierThreeData }
                        let quantity = data.quantity
                        data.quantity = ++quantity
                        setTierThreeData(data)
                      }
                }>
                <i className="fa fa-plus"></i>
              </p>
            </div>
          </div>
        </div>
        <div className="text-center">
          <h3>{`Subtotal : $${
            !isFloat(totalAmount) ? addCommas(totalAmount || 0) : totalAmount
          }`}</h3>

          <div className="checkbox mt-3 d-flex justify-content-center">
            <Checkbox
              onChange={onChangeCheck}
              checked={checkedTerms}></Checkbox>

            <label for="terms">
              {" "}
              &nbsp;&nbsp; <span className="colorWhite">I agree to </span>
              <a
                className="purple-color-link"
                onClick={() => toLinks("/terms")}>
                {" "}
                Terms & Conditions
              </a>
            </label>
          </div>
        </div>
      </div>

      {/* {tierDropdownData && tierDropdownData?.length
          ? tierDropdownData?.map((value, index) => (
              <div id="crypto-btn" className="pre-order-btn">
                {value.data}
                <button
                  id="crypto-btn"
                  className="btn-submit"
                  type="button"
                  onClick={() => decrementHandler(value, index)}
                >
                  -
                </button>
                {value.quantity}
                <button
                  id="crypto-btn"
                  className="btn-submit"
                  type="button"
                  onClick={() => incrementHandler(value, index)}
                >
                  +
                </button>
              </div>
            ))
          : null} */}

      {/* <div className="half-fields mt-4">
          <a href="#" id="crypto-btn" className="pre-order-btn">
            {`Pay With Crypto    $${totalAmount}`}
          </a> */}
      {/* <a href="#" id="crypto-btn" className="pre-order-btn">
              Pay With Fiat
            </a> */}
      {/* </div> */}
      {/* </form> */}

      <div className="text-center">
        <button
          style={{ border: "none", outline: "none" }}
          className="pre-order-btn"
          onClick={() => proceedFunction()}>
          Proceed to pay
        </button>
        <br />
        <br />
        <span
          style={{ color: "white", display: "inline-block", maxWidth: "65vw" }}>
          Pre-orders with initial deposits (of $50/$75/$100) are entitled for an
          Iconic Tier -1 NFM upon completing the purchase with the remaining
          amount. Please signup/login for completing the pending payment.
        </span>

        <br />
        <div style={{ margin: "auto" }}>
          <span style={{ color: "white", fontSize: "15px" }}>
            (Powered by Federal)
          </span>
        </div>
      </div>

      <br />
      {/* </div> */}

      {/* Footer */}
      {/* <footer>
        <div className="container">
          <div className="row reverse-order">
            <div className="col-md-4 xs-center relative margin-on-mb">
              <div className="widget mt-2">
                <ul>
                  <li>
                    <a href="https://docs.nitro.network/" target="_blank">
                      Documentation
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://nitro.network/terms-and-conditions"
                      target="_blank"
                    >
                      Terms &amp; Conditions
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://nitro.network/pre-order-agreement-and-privacy-policy"
                      target="_blank"
                    >
                      {" "}
                      Pre-Order Agreement &amp; Privacy Policy
                    </a>
                  </li>
                </ul>
              </div>
              <div className="social-icons">
                <a
                  href="https://www.facebook.com/NitroNetworkHQ"
                  target="_blank"
                >
                  <span>
                    <i className="fa fa-facebook fa-lg" />
                  </span>
                </a>
                <a href="https://twitter.com/Nitro_HQ" target="_blank">
                  <span>
                    <i className="fa fa-twitter fa-lg" />
                  </span>
                </a>
                <a
                  href="https://www.linkedin.com/company/nitronetwork"
                  target="_blank"
                >
                  <span>
                    <i className="fa fa-linkedin fa-lg" />
                  </span>
                </a>
                <a href="https://nitronetwork.medium.com/" target="_blank">
                  <span>
                    <i className="fa fa-medium fa-lg" />
                  </span>
                </a>
                <a
                  href="https://www.reddit.com/r/NucleusVision/"
                  target="_blank"
                >
                  <span>
                    <i className="fa fa-reddit fa-lg" />
                  </span>
                </a>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 col-xs-1" />
            <div className="col-md-4 col-sm-6 col-xs-1">
              <div className="newsletter">
                <div className>
                  <p className="mb-0 fz13px">
                    Signup for our newsletter to get the latest news in your
                    inbox.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row d-flex align-items-center">
            <div className="col-12 mt-4">
              <p className="copy">
                © Copyright 2022 - <span> Nitro Network </span>
              </p>
            </div>
          </div>
        </div>
      </footer> */}
      {/*  SCRIPT FILES LIBRARY */}
      {/* <Footer /> */}
    </div>
  )
}

export default BuyNitro

{
  /* const plus = document.querySelector(".plus");
 const minus = document.querySelector(".minus");
 let formField = document.querySelector("#number-update");
 const formInput = document.querySelector("#number-update").value;

 let value = parseInt(formInput);
array.forEach(element => { */
}

{
  /* 
// plus.addEventListener("click", function ()  */
}
{
  /* //   value++;
//   formField.value = value;
// }); */
}

{
  /* // minus.addEventListener("click", function () {
//   if (value == 0) {
//     alert("Can't Go Negative");
//   } else {
//     value--;
//     formField.value = value;
//     // value.valueAsNumber = value;
//     // storeItem(value);
//   }
// }); */
}
