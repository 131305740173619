import React, { useState, useEffect, useRef } from "react"
import {
  BrowserRouter as Router,
  Link,
  useHistory,
  useLocation
} from "react-router-dom"
import constant from "../../constants/constants"
import Carousel from "react-spring-3d-carousel"

import { config } from "react-spring"
import { useNavigate } from "@reach/router"
import { Pagination, Input } from "antd"
import { BaseUrl } from "../../utils/config"
import { createGlobalStyle } from "styled-components"
import {
  availabilityOptions,
  categoryOptions,
  soldNFMTiers,
  statusOptions
} from "../../Shared"
import GetYourNcashHere from "./GetYourNcashHere"
import Footer from "../components/footer"
import Menu from "./menu"
import Dropdown from "../components/dropdown"
import axios from "axios"
import { useSelector, useDispatch } from "react-redux"
import { Images } from "../../Shared/images"
import { ArrayForRange, capitalize } from "../../utils"
import { setActiveMenu, setFilterChanged } from "../../redux/actions"
import addCommas from "add-commas"
import { updateUserDetailsRedux, setPendingPayment } from "../../redux/actions"
import nitroPaymentAbi from "../../assets/blockchain/nitroPaymentABI.json"
import erc20Abi from "../../assets/blockchain/erc20Abi.json"
import BigNumber from "bignumber.js"

//Styles
import "../styles/table.css"
import "../../assets/css/marketplace.css"

//Images
import clickit from "./images/clickit.gif"
import swal from "sweetalert"
import { setSoldNFTs } from "../../redux/actions/marketplace"
import { Form } from "antd"
import { isFloat } from "../../utils"

// blockchain - start
import Web3 from "web3"
import Cookies from "universal-cookie"
const cookies = new Cookies()
// blockchain - end

function NewBuyNfmPage(props) {
  const [allProducts, setAllProducts] = useState([])
  const [getData, setGetData] = useState([])
  const pageItems = 12
  const [viewProduct, setViewProduct] = useState(false)
  const [current, setCurrent] = useState(1)
  const [pagesDiv, setPagesDiv] = useState(0)
  const [tData, setTData] = useState([])
  const history = useHistory()
  const [state, setState] = useState({
    goToSlide: 0,
    offsetRadius: 2,
    showNavigation: true,
    config: config.gentle
  })
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [discountObj, setDiscountObj] = useState(null)
  let [selectedToken, setSelectedToken] = useState(0)
  const [TNCAgreed, setTNCAgreed] = useState(false)
  const [promoCode, setPromoCode] = useState("")
  const [balance, setBalance] = useState(0)
  const [form] = Form.useForm()
  let _nCashSelectedToken = constant.NCASH
  const [ncashCryptoPaymentAmountToPay, setNcashCryptoPaymentAmountToPay] =
    useState(false)
  let [NCashBalance, setNCashBalance] = useState(0)
  let [totalNcash, setTotalNcash] = useState(0)

  const navigateToUrl = useNavigate()

  const userDetails = useSelector(state => state.common.userDetails)
  const userData = useSelector(state => state.common.userDetails)
  const filterChanged = useSelector(state => state.common.filterChanged)
  const soldNFTs = useSelector(state => state.marketplace.soldNFTs)
  const soldNFTAPIUrl = process.env.REACT_APP_COLLECTION_URL
  const [filteredProduct, setFilteredProduct] = useState(null)
  const pendingPayment =
    useSelector(state => state.common.pendingPayment) || null
  const totalPages = 1249
  const [clickedIndex, setClickedIndex] = useState(-1)
  const [lastElement, setLastElement] = useState(null)
  const [loading, setLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(0)
  let [isWalletConnected, setWalletConnected] = useState(false)
  let [connectedWallet, setConnectedWallet] = useState(false)
  let [paymentTokenToshow, setPaymentTokenToShow] = useState("WBTC")
  let [paymentAmountToShow, setPaymentAmountToShow] = useState(0)
  let [isAlreadyApproved, setIsAlreadyApproved] = useState(false)
  const [paymentInProgress, setPaymentInProgress] = useState(false)
  let [isApprovedTxAvailable, setIsApprovedTxAvailable] = useState(false)
  let [approvedTx, setApprovedTx] = useState(null)
  let [amountToPayInUSD, setAmountToPayInUSD] = useState(0)
  let [isPayTxAvailable, setIsPayTxAvailable] = useState(false)
  let [payTx, setPayTx] = useState(null)
  let [amountPaid, setAmountPaid] = useState(0)
  let [payToken, setPayToken] = useState(null)
  let [amountToPayWrapped, setAmoutForWrapped] = useState(0)
  let [cryptoPaymentAmount, setCryptoPaymentAmountToPay] = useState(0)
  const loginUserMail = localStorage.getItem("loginUserMail")

  let [web3, setWeb3] = useState(null)

  const refreshPage = () => {
    window.location.reload()
  }

  const initialCategory =
    props.location.state?.initialCategory ?? categoryOptions[0].value
  const [category, setCategory] = useState(initialCategory)
  const [soldNFMTier, setSoldNFMTier] = useState(soldNFMTiers[0].value)

  const [status, setStatus] = useState(statusOptions[0].value)

  const [currentTier, setCurrentTier] = useState(
    categoryOptions.find(el => el.value === initialCategory)?.tier ?? ""
  )

  const [filteredProducts, setFilteredProducts] = useState([])

  const [tierQuantity, setTierQuantity] = useState({
    "tier-1": 0,
    "tier-2": 0,
    "tier-3": 0
  })

  const payWithCoinbase = () => {
    if (!TNCAgreed) {
      swal({
        text: "Please agree to terms and conditions",
        icon: "warning"
      })
      return
    }

    let values = [tierQuantity.tier - 2, tierQuantity.tier - 3]
    if (values.includes(0)) {
      swal({
        text: "Please select atleast one item to proceed",
        icon: "warning"
      })
      return
    }

    const apiObj = {
      payment_source: "coinbase",
      wallet_address: connectedWallet,
      promo_code: promoCode,
      tiers: tierQuantity
    }

    ;(!promoCode.length || !(discountObj?.balance ?? 0)) &&
      delete apiObj.promo_code

    axios
      .post(`${BaseUrl}/createNewOrder`, apiObj, {})
      .then(response => {
        if (response?.data?.status) {
          navigateToUrl(response?.data?.data?.host_url)
          // getPendingPayments();
        }
      })
      .catch(error => {
        if (error.response?.status === 401) {
          localStorage.clear()
          dispatch(updateUserDetailsRedux({}))
          navigate("/login")
        }
      })
  }

  const fetchProducts = (changed = "") => {
    let apiCategory = category ? category : null

    let url = `${BaseUrl}/product/get?page=${
      changed !== "currentPage" ? 0 : currentPage
    }${apiCategory ? `&categoryId=${apiCategory}` : ""}`

    changed !== "currentPage" && setCurrentPage(0)

    setLoading(true)
    axios
      .get(url)
      .then(response => {
        if (response.status === 200) {
          setFilteredProducts(prevFilteredProducts =>
            changed === "currentPage" && apiCategory
              ? [...new Set([...prevFilteredProducts, ...response.data.data])]
              : response.data.data
          )
          if (apiCategory) {
            setFilteredProduct(response.data.data[0])
          } else {
            setAllProducts(response.data.data)
            setFilteredProduct(null)
          }
        } else if (response.status == 404) {
          swal({
            title: "Error!",
            text: "Page not found. Please try again later.",
            icon: "error"
          })
        } else if (response.status == 500) {
          swal({
            title: "Error!",
            text: "Something went wrong. Please try again later.",
            icon: "error"
          })
        } else if (response.status == 503) {
          swal({
            title: "Error!",
            text: "Something went wrong. Please try again later.",
            icon: "error"
          })
        } else {
          setFilteredProducts([])
          setAllProducts(prevProducts =>
            changed === "currentPage"
              ? [...new Set([...prevProducts, ...response.data.data])]
              : response.data.data
          )
          setAllProducts([])
          setFilteredProduct(null)

          swal({
            title: "Error!",
            text: "Something went wrong. Please try again later.",
            icon: "error"
          })
        }
      })
      .catch(error => {
        if (error?.response?.status == 401) {
          dispatch(updateUserDetailsRedux({}))
          dispatch(setConnectedWallet(""))
          localStorage.clear()
          navigate("/login")
        } else
          swal({
            title: "Error!",
            text: "Something went wrong. Please try again later.",
            icon: "error"
          })
      })
    setLoading(false)
  }

  const onviewproduct = id => {
    setViewProduct(true)
    navigate("/productdetails", {
      state: { selectedId: id, type: "marketplace" }
    })
  }

  const removeDiscount = () => {
    setPromoCode("")
    setDiscountObj(null)
    form.resetFields()
  }

  const toLogin = () => {
    history.push("/login")
  }

  const onSearch = e => {
    const searchedData = []
    e != ""
      ? allProducts.map(item => {
          item.title.includes(e) && searchedData.push(item)
        })
      : setTData(getData.slice(0, pageItems))
    e != "" && setTData(searchedData)

    // searchedData.length<=1 && setTData(getData.slice(0, pageItems));
  }

  const goPagination = val => {
    setCurrent(val)
    const passval = (val - 1) * pageItems
    setTData(getData.slice(passval, passval + pageItems))
  }

  //Change Handlers
  const categoryHandler = e => {
    dispatch(setFilterChanged(true))
    setCategory(Number(e.target.value))
  }

  // Observer for Infinite Scroll
  const observer = useRef(
    new IntersectionObserver(entries => {
      const domElement = entries[0]
      if (domElement.isIntersecting) {
        setCurrentPage(prevPage => prevPage + 1)
      }
    })
  )

  const applyPromoCode = () => {
    let apiObj = {
      tiers: tierQuantity,
      promo_code: promoCode,
      wallet_address: connectedWallet
    }

    axios
      .post(`${BaseUrl}/applyPromoCode`, apiObj, {
        headers: {
          Authorization: `Bearer ${userDetails?.loginUserToken}`
        }
      })
      .then(response => {
        if (response.status === 200) {
          setDiscountObj(response?.data)
        }
      })
      .catch(error => {
        if (error?.response?.status === 401) {
          dispatch(updateUserDetailsRedux({}))
          dispatch(setConnectedWallet(""))
          localStorage.clear()
          navigate("/login")
        } else if (error?.response?.status === 406) {
          setDiscountObj({ ...error.response.data, balance: 0 })
        }
      })
  }

  const fetchSoldNFMs = () => {
    let tierIds = {
      v1: [0, 1, 2],
      v2: [0, 1],
      v3: [0, 1, 2, 3, 4]
    }

    let promiseArr = []

    Object.entries(tierIds).forEach(async ([tier, indexArr]) => {
      for (let i = 0; i < indexArr.length; i++) {
        let item = axios.get(
          `${soldNFTAPIUrl}/${tier}/nft/metadata/${indexArr[i]}`,
          { timeout: 30000 }
        )
        promiseArr.push(item)
      }
    })

    axios
      .all(promiseArr)
      .then(
        axios.spread((...responses) => {
          dispatch(setSoldNFTs(responses.map(response => response.data)))
        })
      )
      .catch(err => {
        if (err.response.status === 401) {
          dispatch(updateUserDetailsRedux({}))
          dispatch(setConnectedWallet(""))
          localStorage.clear()
          navigate("/login")
        }
        console.log(err, "error")
      })
  }

  const soldNfts = [
    {
      product_id: 1,
      mystery: "Iconic series 1 - 0001",
      product_desc:
        "A part of Iconic collection of NFT from Nitro network with the perpetual reward potential and an ever appreciating value with re-sale or hodling opportunity ! Good treasure to hold ! ",
      product_sequence_id: "23",
      product_code: "3434",
      product_image: Images.tier11,
      availableStatus: 1,
      group_name: "Gold",
      category_name: "Tier-1",
      price: 750
    },
    {
      product_id: 2,
      mystery: "Iconic series 1 - 0002",
      product_desc:
        "A part of Iconic collection of NFT from Nitro network with the perpetual reward potential and an ever appreciating value with re-sale or hodling opportunity ! Good treasure to hold ! ",
      product_sequence_id: "23",
      product_code: "3435",
      product_image: Images.tier12,
      availableStatus: 1,
      group_name: "Gold",
      category_name: "Tier-1",
      price: 750
    },
    {
      product_id: 3,
      mystery: "Iconic series 1 - 0003",
      product_desc:
        "A part of Iconic collection of NFT from Nitro network with the perpetual reward potential and an ever appreciating value with re-sale or hodling opportunity ! Good treasure to hold ! ",
      product_sequence_id: "23",
      product_code: "3435",
      product_image: Images.tier13,
      availableStatus: 1,
      group_name: "Gold",
      category_name: "Tier-1",
      price: 750
    },
    {
      product_id: 4,
      mystery: "Premium series 1 - 1111",
      product_desc:
        "A part of Premium collection of NFT from Nitro network with the perpetual reward potential and an ever appreciating value with re-sale or hodling opportunity ! Good treasure to hold ! ",
      product_sequence_id: "23",
      product_code: "3435",
      product_image: Images.tier21,
      availableStatus: 1,
      group_name: "Gold",
      category_name: "Tier-2",
      price: 650
    },
    {
      product_id: 5,
      mystery: "Premium series 1 - 1112",
      product_desc:
        "A part of Premium collection of NFT from Nitro network with the perpetual reward potential and an ever appreciating value with re-sale or hodling opportunity ! Good treasure to hold ! ",
      product_sequence_id: "23",
      product_code: "3435",
      product_image: Images.tier22,
      availableStatus: 1,
      group_name: "Gold",
      category_name: "Tier-2",
      price: 650
    },
    {
      product_id: 6,
      mystery: "Premium series 1 - 1113",
      product_desc:
        "A part of Premium collection of NFT from Nitro network with the perpetual reward potential and an ever appreciating value with re-sale or hodling opportunity ! Good treasure to hold ! ",
      product_sequence_id: "23",
      product_code: "3435",
      product_image: Images.tier23,
      availableStatus: 1,
      group_name: "Gold",
      category_name: "Tier-2",
      price: 650
    },
    {
      product_id: 7,
      mystery: "Premium series 1 - 1114",
      product_desc:
        "A part of Premium collection of NFT from Nitro network with the perpetual reward potential and an ever appreciating value with re-sale or hodling opportunity ! Good treasure to hold ! ",
      product_sequence_id: "23",
      product_code: "3435",
      product_image: Images.tier24,
      availableStatus: 1,
      group_name: "Gold",
      category_name: "Tier-2",
      price: 650
    },
    {
      product_id: 8,
      mystery: "Premium series 1 - 1115",
      product_desc:
        "A part of Premium collection of NFT from Nitro network with the perpetual reward potential and an ever appreciating value with re-sale or hodling opportunity ! Good treasure to hold ! ",
      product_sequence_id: "23",
      product_code: "3435",
      product_image: Images.tier25,
      availableStatus: 1,
      group_name: "Gold",
      category_name: "Tier-2",
      price: 650
    },
    {
      product_id: 9,
      mystery: "Premium series 1 - 1116",
      product_desc:
        "A part of Premium collection of NFT from Nitro network with the perpetual reward potential and an ever appreciating value with re-sale or hodling opportunity ! Good treasure to hold ! ",
      product_sequence_id: "23",
      product_code: "3435",
      product_image: Images.tier26,
      availableStatus: 1,
      group_name: "Gold",
      category_name: "Tier-2",
      price: 650
    },
    {
      product_id: 10,
      mystery: "Premium series 1 - 1117",
      product_desc:
        "A part of Premium collection of NFT from Nitro network with the perpetual reward potential and an ever appreciating value with re-sale or hodling opportunity ! Good treasure to hold ! ",
      product_sequence_id: "23",
      product_code: "3435",
      product_image: Images.tier27,
      availableStatus: 1,
      group_name: "Gold",
      category_name: "Tier-2",
      price: 650
    },
    {
      product_id: 11,
      mystery: "Top series 1 - 2221",
      product_desc:
        "A part of Top collection of NFT from Nitro network with the perpetual reward potential and an ever appreciating value with re-sale or hodling opportunity ! Good treasure to hold ! ",
      product_sequence_id: "23",
      product_code: "3435",
      product_image: Images.tier31,
      availableStatus: 1,
      group_name: "Gold",
      category_name: "Tier-3",
      price: 450
    },
    {
      product_id: 12,
      mystery: "Top series 1 - 2222",
      product_desc:
        "A part of Top collection of NFT from Nitro network with the perpetual reward potential and an ever appreciating value with re-sale or hodling opportunity ! Good treasure to hold ! ",
      product_sequence_id: "23",
      product_code: "3435",
      product_image: Images.tier32,
      availableStatus: 1,
      group_name: "Gold",
      category_name: "Tier-3",
      price: 450
    },
    {
      product_id: 13,
      mystery: "Top series 1 - 2223",
      product_desc:
        "A part of Top collection of NFT from Nitro network with the perpetual reward potential and an ever appreciating value with re-sale or hodling opportunity ! Good treasure to hold ! ",
      product_sequence_id: "23",
      product_code: "3435",
      product_image: Images.tier33,
      availableStatus: 1,
      group_name: "Gold",
      category_name: "Tier-3",
      price: 450
    }
  ]

  // Use Effects
  useEffect(() => {
    const currentElement = lastElement
    const currentObserver = observer.current

    if (currentElement) {
      //null check
      currentObserver.observe(currentElement)
    }
    return () => {
      if (currentElement) {
        currentObserver.unobserve(currentElement)
      }
    }
  }, [lastElement])

  useEffect(() => {
    fetchSoldNFMs()
  }, [])

  useEffect(() => {
    if (currentPage <= totalPages) {
      fetchProducts("currentPage") // current page changed
    }
  }, [currentPage])

  // useEffect(() => {
  //   fetchProducts("category"); // category changed
  //   let newTier =
  //     categoryOptions.find((el) => el.value === category)?.tier ?? "";
  //   setCurrentTier(newTier);
  //   setTierQuantity({
  //     ...{
  //       "tier-1": 0,
  //       "tier-2": 0,
  //       "tier-3": 0,
  //     },
  //     [newTier]: 1,
  //   });
  // }, [category]);

  const placeOrder = () => {
    let loginToken = userData?.loginUserToken
    let data = {
      tier: tierQuantity
    }
    var config = {
      method: "post",
      url: `${BaseUrl}/preorder`,
      headers: {
        "Content-Type": "application/json"
      },
      data: data
    }
    axios(config)
      .then(response => {
        if (response.status == 200) {
          if (loginToken) {
            axios
              .get(`${BaseUrl}/addorder/${response.data.token}`, {
                headers: {
                  Authorization: `Bearer ${loginToken}`
                }
              })
              .then(addOrderResponse => {
                if (addOrderResponse.status == 200) {
                  navigate("/myaccount/pendingpayments")
                  dispatch(setActiveMenu("NFM"))
                  setTierQuantity({
                    "tier-1": 0,
                    "tier-2": 0,
                    "tier-3": 0
                  })
                }
              })
              .catch(error => {
                if (error?.response?.status === 401) {
                  dispatch(updateUserDetailsRedux({}))
                  dispatch(setConnectedWallet(""))
                  localStorage.clear()
                  localStorage.setItem("buyNitroToken", response.data.token)
                  navigate("/login")
                }
              })
          } else {
            localStorage.clear()
            localStorage.setItem("buyNitroToken", response.data.token)
            navigate("/login")
          }
        } else if (response.status == 401) {
          dispatch(updateUserDetailsRedux({}))
          dispatch(setConnectedWallet(""))
          localStorage.clear()
          navigate("/login")
          dispatch(updateUserDetailsRedux({}))
          localStorage.clear()
          navigate("/login")
        } else if (response.status == 404) {
          swal({
            title: "Error!",
            text: "Page not found. Please try again later.",
            icon: "error"
          })
        } else if (response.status == 500) {
          swal({
            title: "Error!",
            text: "Something went wrong. Please try again later.",
            icon: "error"
          })
        } else if (response.status == 503) {
          swal({
            title: "Error!",
            text: "Something went wrong. Please try again later.",
            icon: "error"
          })
        } else {
          console.log("response")

          swal({
            title: "Failed",
            text: "Something went wrong.",
            icon: "error"
          })
        }
      })
      .catch(error => {
        swal({
          title: "Failed",
          text: "Something went wrong.",
          icon: "error"
        })
      })
  }

  const payWithFiat = () => {
    if (!TNCAgreed) {
      swal({
        text: "Please agree to terms and conditions",
        icon: "warning"
      })
      return
    }

    let values = [tierQuantity.tier - 2, tierQuantity.tier - 3]
    if (values.includes(0)) {
      swal({
        text: "Please select atleast one item to proceed",
        icon: "warning"
      })
      return
    }

    const apiObj = {
      payment_source: "stripe",
      wallet_address: connectedWallet,
      promo_code: "",
      tiers: tierQuantity
    }

    ;(!promoCode.length || !(discountObj?.balance ?? 0)) &&
      delete apiObj.promo_code
    axios
      .post(`${BaseUrl}/createNewOrder`, apiObj, {})
      .then(response => {
        if (response?.data?.status) {
          navigateToUrl(response?.data?.data?.host_url)
          // getPendingPayments();
        }
      })
      .catch(error => {
        if (error.response?.status === 401) {
          localStorage.clear()
          dispatch(updateUserDetailsRedux({}))
          navigate("/login")
        }
      })
  }

  const getPendingPayments = () => {
    if (userDetails?.loginUserToken) {
      axios
        .get(`${BaseUrl}/mypurchase/${userDetails?.loginUserId}`, {
          headers: {
            Authorization: `Bearer ${userDetails?.loginUserToken}`
          }
        })
        .then(response => {
          let distructedResponse = response?.data ?? null
          if (response.status === 200) {
            if (distructedResponse?.is_payment_in_progress) {
              setPaymentInProgress(distructedResponse?.message ?? "")
            } else if (
              distructedResponse?.is_payment_completed &&
              !localStorage.getItem("buyNitroToken")
            ) {
              navigate("/myaccount/mycollection")
              dispatch(setActiveMenu("NFM"))
            }
            dispatch(setPendingPayment(response?.data ?? null))
          } else if (response?.status == 401) {
            dispatch(updateUserDetailsRedux({}))
            dispatch(setConnectedWallet(""))
            localStorage.clear()
            navigate("/login")
          } else if (response?.status == 404) {
            swal({
              title: "Error!",
              text: "Page not found. Please try again later.",
              icon: "error"
            })
          } else if (response?.status == 500) {
            swal({
              title: "Error!",
              text: "Something went wrong. Please try again later.",
              icon: "error"
            })
          } else if (response?.status == 503) {
            swal({
              title: "Error!",
              text: "Something went wrong. Please try again later.",
              icon: "error"
            })
          } else {
            dispatch(setPendingPayment(null))
          }
        })
        .catch(err => {
          dispatch(setPendingPayment(null))
          if (err?.response?.status === 401) {
            localStorage.clear()
            dispatch(updateUserDetailsRedux({}))
            navigate("/login")
          }
        })
    } else {
      navigate("/login")
    }
  }

  const connectToWallet = async () => {
    console.log(
      `[nft][src][components][components][pages][paymentpage][connectToWallet] connectToWallet`
    )
    if (typeof window.ethereum !== "undefined") {
      let accounts = await window.ethereum.request({
        method: "eth_requestAccounts"
      })
      window.web3 = new Web3(window.ethereum)
      setWeb3(window.web3)

      let chainId = await window.web3.eth.getChainId()

      if (chainId !== parseInt(constant.CHAIN_ID)) {
        swal({
          title: "Invalid Network!",
          text: `Switch your network to ${constant.NETWORK_NAME}`,
          icon: "error",
          confirmButtonText: "Ok"
        })
        return
      }

      cookies.set("wallet", accounts[0], { path: "/" })
      cookies.set("wallet_status", 1, { path: "/" })
      setWalletConnected(true)
      setConnectedWallet(accounts[0])
      // connect to wallet api

      let apiData = {
        wallet_address: accounts[0]
      }
      axios
        .put(
          `${BaseUrl}/addwallet?user_id=${userDetails.loginUserId}`,
          apiData,
          {
            headers: {
              Authorization: `Bearer ${userDetails.loginUserToken}`
            }
          }
        )
        .then(response => {
          if (response.status === 200) {
          }
        })
        .catch(error => {
          if (error?.response?.status === 401) {
            dispatch(updateUserDetailsRedux({}))
            dispatch(setConnectedWallet(""))
            localStorage.clear()
            navigate("/login")
          }
        })

      setWeb3(window.web3)
    } else {
      cookies.set("wallet", null, { path: "/" })
      cookies.set("wallet_status", 0, { path: "/" })
      setWalletConnected(false)
      setConnectedWallet(null)
      setWeb3(null)
      console.log("Unsupported Browser/Plugin not installed")
    }
  }

  const disconnectWallet = () => {
    cookies.set("wallet", null, { path: "/" })
    cookies.set("wallet_status", 0, { path: "/" })
    setWalletConnected(false)
    setConnectedWallet("")
    setWeb3(null)
    window.location.reload(false)
    axios
      .delete(`${BaseUrl}/removeWallet/${userDetails?.loginUserId}`, {
        headers: {
          Authorization: `Bearer ${userDetails?.loginUserToken}`
        }
      })
      .then(response => {
        cookies.set("wallet", null, { path: "/" })
        cookies.set("wallet_status", 0, { path: "/" })
        setWalletConnected(false)
        dispatch(setConnectedWallet(""))
        setWeb3(null)
        window.location.reload(false)
      })
      .catch(error => {
        if (error?.response?.status == 401) {
          dispatch(updateUserDetailsRedux({}))
          dispatch(setConnectedWallet(""))
          localStorage.clear()
          navigate("/login")
        }
      })
  }

  const payWithWallet = async () => {
    if (!TNCAgreed) {
      swal({
        text: "Please agree to terms and conditions",
        icon: "warning"
      })
      return
    }

    console.log(
      `[nft][src][components][components][modal][payWithWallet] Initiated`
    )
    console.log(
      `[nft][src][components][components][modal][payWithWallet] Selected Token`,
      payToken
    )

    await connectToWallet()

    let web3 = new Web3(window.ethereum)
    let accounts = await web3.eth.getAccounts()
    let ERC20 = await new web3.eth.Contract(erc20Abi, payToken)
    let userBalance = await ERC20.methods.balanceOf(connectedWallet).call()
    let userBalanceBN = new BigNumber(userBalance)
    let balanceToPay = new BigNumber(cryptoPaymentAmount)
    let res = userBalanceBN.comparedTo(balanceToPay)
    setAmountPaid(cryptoPaymentAmount)

    if (res === -1) {
      swal({
        icon: "error",
        title: "Failed",
        text: "Insufficient Funds. Please topup your Balance to proceed for payment",
        showConfirmButton: true,
        showCancelButton: false,
        closeOnClickOutside: true
      })
      return
    }

    try {
      let NitroPayment = await new web3.eth.Contract(
        nitroPaymentAbi,
        constant.NITRO_PAYMENT_CONTRACT
      )

      if (selectedToken == 1 || selectedToken == 2 || selectedToken == 3) {
        // pay with ERC20

        console.log(amountToPayWrapped, "amountToPayWrapped")
        await NitroPayment.methods
          .payPending(payToken, amountToPayWrapped.toString(), 1)

          .send({ from: accounts[0] })
          .on("transactionHash", function (hash) {
            console.log(
              `[nft][src][components][components][modal][payWithWallet] transactionHash`,
              hash
            )
            setIsPayTxAvailable(true)
            let txHash = `${constant.EXPLORER_URL}/tx/${hash}`
            setPayTx(txHash)

            swal({
              icon: "info",
              title: "In progress",
              text: `Transaction Initiated, Please wait!`,
              buttons: true,
              closeOnClickOutside: false,
              buttons: ["View Transaction"],
              cancel: false,
              confirm: false
            }).then(function () {
              window.location = `${constant.EXPLORER_URL}/tx/${hash}`
            })

            waitTransactionReceipt(hash, "pay", 0, function (err, receipt) {
              if (err != null) {
                return
              }
            })
          })
          .on("error", function (error, receipt) {
            console.log(
              `[nft][src][components][components][modal][payWithWallet] transactionHash error 1`,
              error
            )
            swal({
              icon: "error",
              title: "Failed",
              text: "Transaction cancelled. Please try again!",
              showConfirmButton: true,
              showCancelButton: false,
              closeOnClickOutside: true
            })
            return
          })
      } else if (
        selectedToken == 4 ||
        selectedToken == 5 ||
        selectedToken == 6
      ) {
        // pay with stable coin
        await NitroPayment.methods
          .payWithStableCoins(payToken, cryptoPaymentAmount.toString(), 1)
          .send({ from: accounts[0] })
          .on("transactionHash", function (hash) {
            console.log(
              `[nft][src][components][components][modal][payWithWallet] transactionHash`,
              hash
            )
            setIsPayTxAvailable(true)
            let txHash = `${constant.EXPLORER_URL}/tx/${hash}`
            setPayTx(txHash)

            swal({
              icon: "info",
              title: "In progress",
              text: `Transaction Initiated, Please wait!`,
              buttons: true,
              closeOnClickOutside: false,
              buttons: ["View Transaction"],
              cancel: false,
              confirm: false
            }).then(function () {
              window.location = `${constant.EXPLORER_URL}/tx/${hash}`
            })

            waitTransactionReceipt(hash, "pay", 0, function (err, receipt) {
              if (err != null) {
                return
              }
            })
          })
          .on("error", function (error, receipt) {
            console.log(
              `[nft][src][components][components][modal][payWithWallet] transactionHash error 1`,
              error
            )
            swal({
              icon: "error",
              title: "Failed",
              text: "Transaction cancelled. Please try again!",
              showConfirmButton: true,
              showCancelButton: false,
              closeOnClickOutside: true
            })
            return
          })
      }
    } catch (error) {
      console.log(
        `[nft][src][components][components][modal][payWithWallet] transactionHash error 2`,
        error
      )
      swal({
        icon: "error",
        title: "Failed",
        text: "Something went wrong. Please try again!",
        showConfirmButton: true,
        showCancelButton: false,
        closeOnClickOutside: true
      })
    }
  }

  const handleSelectToken = event => {
    try {
      selectThePaymentToken(event.target.value)
      getWeb3()
    } catch (error) {
      console.log(error)
    }
  }

  const getWeb3 = () => {
    if (window.ethereum) {
      try {
        window.web3 = new Web3(window.ethereum)
        setWeb3(window.web3)
      } catch (error) {
        console.log(
          `[nft][src][components][components][modal][getWeb3] Web3 Connection Error`,
          error
        )
      }
    } else {
      setWeb3(null)
    }
  }

  const waitTransactionReceipt = async (
    transactionHash,
    actionType,
    timeOut,
    callback
  ) => {
    console.log(
      "[nft][src][components][components][modal][waitTransactionReceipt] transactionHash",
      transactionHash
    )
    var web3 = new Web3(window.ethereum)
    let self = this

    let timeOutCounter = 0
    let err = {
      message: ""
    }

    let isWaitRunning = false

    let getReceiptTimerId = setInterval(function () {
      if (isWaitRunning == false) {
        isWaitRunning = true
        web3.eth
          .getTransaction(transactionHash)
          .then(function (tx) {
            if (tx == null) {
              clearInterval(getReceiptTimerId)
              console.log(
                `[nft][src][components][components][modal][waitTransactionReceipt] transactionHash ${transactionHash} is not found`
              )
              err.message = "Transaction hash is not found."
              isWaitRunning = false
              if (callback) {
                callback(err, null)
              }
              return
            }

            if (tx.blockNumber == null) {
              if (timeOut > 0) {
                timeOutCounter++
                if (timeOutCounter >= timeOut) {
                  clearInterval(getReceiptTimerId)
                  isWaitRunning = false
                  err.message = "Timeout while waiting transaction to confirm."
                  if (callback) {
                    callback(err, null)
                  }
                  return
                } else {
                  console.log(
                    `[nft][src][components][components][modal][waitTransactionReceipt] transactionHash 1 ${transactionHash} is pending`
                  )
                  isWaitRunning = false
                  return
                }
              } else {
                console.log(
                  `[nft][src][components][components][modal][waitTransactionReceipt] transactionHash 2 ${transactionHash} is pending`
                )
                isWaitRunning = false
                return
              }
            }

            // Transaction confirm
            web3.eth
              .getTransactionReceipt(transactionHash)
              .then(async function (web3TxReceipt) {
                clearInterval(getReceiptTimerId)
                isWaitRunning = false
                if (web3TxReceipt == null) {
                  console.log(
                    `[nft][src][components][components][modal][waitTransactionReceipt] transactionHash 2 ${transactionHash} is not found`
                  )
                  swal({
                    icon: "error",
                    title: "Failed",
                    text: "Transaction Failed. Please try again!",
                    showConfirmButton: true,
                    showCancelButton: false,
                    closeOnClickOutside: true
                  })
                  setIsAlreadyApproved(false)
                }
                console.log(
                  `[nft][src][components][components][modal][waitTransactionReceipt] Transaction receipt ${transactionHash}: ${web3TxReceipt}`
                )
                if (web3TxReceipt.status != false) {
                  if (actionType == "approve") {
                    swal({
                      icon: "success",
                      title: "Approved",
                      text: "Transaction Successful",
                      showConfirmButton: true,
                      showCancelButton: false,
                      closeOnClickOutside: true
                    })
                    setIsAlreadyApproved(true)
                  } else if (actionType == "pay") {
                    //⚠️ ⚠️ ⚠️ Payment Update API to be done here! ⚠️ ⚠️ ⚠️//

                    let apiObj = {
                      tx_hash: transactionHash,
                      user_email: loginUserMail,
                      user_wallet: connectedWallet,
                      amount_paid: amountToPayInUSD,
                      paid_token: payToken,
                      NFTs_count: 1,
                      user_id: userDetails?.loginUserId ?? "",
                      amount: discountObj?.status
                        ? balance - discountObj?.amount ?? 0
                        : balance,
                      tokens: Array.isArray(pendingPayment?.data)
                        ? (pendingPayment?.data ?? []).map(
                            orderItem => orderItem?.token ?? ""
                          )
                        : [],
                      promo_code: promoCode
                    }

                    ;(!promoCode.length || !(discountObj?.balance ?? 0)) &&
                      delete apiObj.promo_code

                    axios
                      .post(`${BaseUrl}/payWithWallet`, apiObj, {
                        headers: {
                          Authorization: `Bearer ${userDetails?.loginUserToken}`
                        }
                      })
                      .then(response => {
                        if (response) {
                          swal({
                            icon: "success",
                            title: "Success",
                            text: "Payment Successful",
                            showConfirmButton: true,
                            showCancelButton: false,
                            allowOutsideClick: true
                          }).then(() => {
                            setIsPayTxAvailable(true)
                            refreshPage()
                          })
                        }
                      })
                    navigate("/thank-you")
                  }
                }
              })
              .catch(function (error) {
                if (error?.response?.status === 401) {
                  localStorage.clear()
                  dispatch(updateUserDetailsRedux({}))
                  navigate("/login")
                }
                clearInterval(getReceiptTimerId)
                isWaitRunning = false
                console.log(
                  `[nft][src][components][components][modal][waitTransactionReceipt] Error on transaction receipt 1 ${transactionHash}: ${error}`
                )
                swal({
                  icon: "error",
                  title: "Cancelled",
                  text: error.message,
                  showConfirmButton: true,
                  showCancelButton: false,
                  closeOnClickOutside: true
                })
                setIsAlreadyApproved(false)
              })
          })
          .catch(function (error) {
            clearInterval(getReceiptTimerId)
            console.log(
              `[nft][src][components][components][modal][waitTransactionReceipt] Error on transaction receipt 2 ${transactionHash}: ${error}`
            )
            swal({
              icon: "error",
              title: "Cancelled",
              text: error.message,
              showConfirmButton: true,
              showCancelButton: false,
              closeOnClickOutside: true
            })
            setIsAlreadyApproved(false)
          })
      }
    }, 1000)
  }

  const approveNcashNFTPaymentContract = async () => {
    if (!TNCAgreed) {
      swal({
        text: "Please agree to terms and conditions",
        icon: "warning"
      })
      return
    }
    if (isWalletConnected == true) {
      let web3 = new Web3(window.ethereum)
      let accounts = await web3.eth.getAccounts()

      console.log(
        "[nft][src][components][components][modal][approveNcashNFTPaymentContract] Initiated"
      )
      console.log(
        "[nft][src][components][components][modal][approveNcashNFTPaymentContract] Selected Token to Pay",
        _nCashSelectedToken
      )

      if (_nCashSelectedToken != null) {
        let contractToApprove = constant.NITRO_PAYMENT_CONTRACT
        let valueToApprove =
          "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff"

        let ERC20 = await new web3.eth.Contract(erc20Abi, _nCashSelectedToken)

        try {
          await ERC20.methods
            .approve(contractToApprove, valueToApprove)
            .send({ from: accounts[0] })
            .on("transactionHash", function (hash) {
              setIsApprovedTxAvailable(true)
              console.log(
                "[nft][src][components][components][modal][approveNFTPaymentContract] Approve Tx Hash",
                hash
              )
              let txHash = `${constant.EXPLORER_URL}/tx/${hash}`
              setApprovedTx(txHash)

              swal({
                icon: "info",
                title: "In progress",
                text: `Transaction Initiated, Please wait!`,
                closeOnClickOutside: false,
                buttons: ["View Transaction"],
                cancel: false,
                confirm: false
              }).then(function () {
                window.location = `${constant.EXPLORER_URL}/tx/${hash}`
              })

              waitTransactionReceipt(
                hash,
                "approve",
                0,
                function (err, receipt) {
                  if (err != null) {
                    return
                  }
                }
              )
            })
            .on("error", function (error, receipt) {
              console.log(
                "[nft][src][components][components][modal][approveNFTPaymentContract] Approve Tx Error",
                error
              )
              swal({
                icon: "error",
                title: "Failed",
                text: "Transaction cancelled. Please try again!",
                showConfirmButton: true,
                showCancelButton: false,
                closeOnClickOutside: true
              })
              setIsAlreadyApproved(false)
              return
            })
        } catch (error) {
          console.log(
            "[nft][src][components][components][modal][approveNFTPaymentContract] Catch Error",
            error
          )
          swal({
            icon: "error",
            title: "Failed",
            text: "Something went wrong. Please try again!",
            showConfirmButton: true,
            showCancelButton: false,
            closeOnClickOutside: true
          })
          setIsAlreadyApproved(false)
        }
      } else {
        swal({
          text: "Please select your currency to proceed",
          icon: "error"
        })
      }
    } else {
      await getWeb3()
      await connectToWallet()
      swal({
        title: "Failed",
        text: `Please connect your wallet.`,
        icon: "error",
        confirmButtonText: "Ok"
      })
    }
  }

  const selectThePaymentToken = async __selectedToken => {
    setSelectedToken(__selectedToken)
    try {
      if (isWalletConnected == true) {
        if (typeof window.ethereum !== "undefined") {
          window.web3 = new Web3(window.ethereum)
          setWeb3(window.web3)
          let NitroPayment = await new window.web3.eth.Contract(
            nitroPaymentAbi,
            constant.NITRO_PAYMENT_CONTRACT
          )

          let _paymentToPay = 0
          let _paymentToShow = 0
          let _token = null
          let __amount = discountObj?.status
            ? balance - discountObj?.amount ?? 0
            : balance
          let _paymentToPayUSD = Math.round(parseFloat(__amount))
          setAmoutForWrapped(_paymentToPayUSD)
          if (__selectedToken == "1") {
            _paymentToPay = await NitroPayment.methods
              .getPriceToPayBTCUSD(_paymentToPayUSD)
              .call()
            _paymentToShow = _paymentToPay / 100000000
            _token = "WBTC.e"
          } else if (__selectedToken == "2") {
            _paymentToPay = await NitroPayment.methods
              .getPriceToPayETHUSD(_paymentToPayUSD)
              .call()
            _paymentToShow = await window.web3.utils.fromWei(
              _paymentToPay,
              "ether"
            )
            _token = "WETH.e"
          } else if (__selectedToken == "3") {
            console.log("avax option selected")
            _paymentToPay = await NitroPayment.methods
              .getPriceToPayAVAXUSD(_paymentToPayUSD)
              .call()
            console.log(_paymentToPay, "_paymentToPay")
            _paymentToShow = await window.web3.utils.fromWei(
              _paymentToPay,
              "ether"
            )
            _token = "WAVAX"
          } else if (__selectedToken == "4") {
            _paymentToPay = await window.web3.utils.toWei(
              _paymentToPayUSD.toString(),
              "ether"
            )
            _paymentToShow = discountObj?.status
              ? balance - discountObj?.amount ?? 0
              : balance
            _token = "DAI.e"
          } else if (__selectedToken == "5") {
            let _temp = BigNumber(_paymentToPayUSD)
            _paymentToPay = _temp.multipliedBy("1000000").toString()
            _paymentToShow = discountObj?.status
              ? balance - discountObj?.amount ?? 0
              : balance
            _token = "USDC.e"
          } else if (__selectedToken == "6") {
            let _temp = BigNumber(_paymentToPayUSD)
            _paymentToPay = _temp.multipliedBy("1000000").toString()
            _paymentToShow = discountObj?.status
              ? balance - discountObj?.amount ?? 0
              : balance
            _token = "USDT.e"
          } else {
            return
          }
          setAmountToPayInUSD(
            discountObj?.status ? balance - discountObj?.amount ?? 0 : balance
          )
          setCryptoPaymentAmountToPay(_paymentToPay.toString())
          setPaymentTokenToShow(_token)
          setPaymentAmountToShow(_paymentToShow)
          // console.log(_token);
          isNFTPaymentContractApproved(_token, _paymentToPay)
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const isNFTPaymentContractApproved = async (
    selectedToken,
    amountToApprove
  ) => {
    console.log(
      `[nft][src][components][components][modal][isNFTPaymentContractApproved] selectedToken`,
      selectedToken
    )
    await getWeb3()

    let _selectedToken = null

    if (selectedToken == "WBTC.e") {
      _selectedToken = constant.WBTC
    } else if (selectedToken == "WETH.e") {
      _selectedToken = constant.WETH
    } else if (selectedToken == "WAVAX") {
      _selectedToken = constant.WAVAX
    } else if (selectedToken == "DAI.e") {
      _selectedToken = constant.DAI
    } else if (selectedToken == "USDC.e") {
      _selectedToken = constant.USDC
    } else if (selectedToken == "USDT.e") {
      _selectedToken = constant.USDT
    } else {
      _selectedToken = "0x0000000000000000000000000000000000000000"
    }

    setPayToken(_selectedToken)

    if (
      _selectedToken != "null" ||
      _selectedToken != "0x0000000000000000000000000000000000000000"
    ) {
      let erc20 = await new window.web3.eth.Contract(erc20Abi, _selectedToken)

      // balance to Pay
      let balanceToPay = new BigNumber(amountToApprove)

      connectToWallet()

      try {
        if (isWalletConnected == true) {
          if (connectToWallet != null) {
            let isApproved = await erc20.methods
              .allowance(connectedWallet, constant.NITRO_PAYMENT_CONTRACT)
              .call()

            let decimal = await erc20.methods.decimals().call()

            let baseBN = new BigNumber(10)
            let decimalBN = new BigNumber(decimal)

            let exponentiate = baseBN.exponentiatedBy(decimalBN)

            let isApprovedBN = new BigNumber(isApproved)
            let balanceToPayBN = new BigNumber(balanceToPay).multipliedBy(
              exponentiate
            )

            let res = isApprovedBN.comparedTo(balanceToPayBN)
            console.log(
              `[nft][src][components][components][modal][isNFTPaymentContractApproved] res`,
              res
            )

            if (res === 0 || res == 1) {
              setIsAlreadyApproved(true)
            } else if (res == -1) {
              setIsAlreadyApproved(false)
            }
            console.log(
              `[nft][src][components][components][modal][isNFTPaymentContractApproved] Is Approved`,
              isAlreadyApproved
            )
          }
        } else {
          setIsAlreadyApproved(false)
        }
      } catch (error) {
        console.log(error)
      }
    } else {
      setIsAlreadyApproved(false)
    }
  }

  const approveNFTPaymentContract = async () => {
    if (!TNCAgreed) {
      swal({
        text: "Please agree to terms and conditions",
        icon: "warning"
      })
      return
    }
    if (isWalletConnected == true) {
      let web3 = new Web3(window.ethereum)
      let accounts = await web3.eth.getAccounts()

      console.log(
        "[nft][src][components][components][modal][approveNFTPaymentContract] Initiated"
      )
      console.log(
        "[nft][src][components][components][modal][approveNFTPaymentContract] Selected Token to Pay",
        payToken
      )

      if (payToken != null) {
        let contractToApprove = constant.NITRO_PAYMENT_CONTRACT
        let valueToApprove =
          "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff"

        let ERC20 = await new web3.eth.Contract(erc20Abi, payToken)

        try {
          await ERC20.methods
            .approve(contractToApprove, valueToApprove)
            .send({ from: accounts[0] })
            .on("transactionHash", function (hash) {
              setIsApprovedTxAvailable(true)
              console.log(
                "[nft][src][components][components][modal][approveNFTPaymentContract] Approve Tx Hash",
                hash
              )
              let txHash = `${constant.EXPLORER_URL}/tx/${hash}`
              setApprovedTx(txHash)

              swal({
                icon: "info",
                title: "In progress",
                text: `Transaction Initiated, Please wait!`,
                buttons: true,
                closeOnClickOutside: false,
                buttons: ["View Transaction"],
                cancel: false,
                confirm: false
              }).then(function () {
                window.location = `${constant.EXPLORER_URL}/tx/${hash}`
              })

              waitTransactionReceipt(
                hash,
                "approve",
                0,
                function (err, receipt) {
                  if (err != null) {
                    return
                  }
                }
              )
            })
            .on("error", function (error, receipt) {
              console.log(
                "[nft][src][components][components][modal][approveNFTPaymentContract] Approve Tx Error",
                error
              )
              swal({
                icon: "error",
                title: "Failed",
                text: "Transaction cancelled. Please try again!",
                showConfirmButton: true,
                showCancelButton: false,
                closeOnClickOutside: true
              })
              setIsAlreadyApproved(false)
              return
            })
        } catch (error) {
          console.log(
            "[nft][src][components][components][modal][approveNFTPaymentContract] Catch Error",
            error
          )
          swal({
            icon: "error",
            title: "Failed",
            text: "Something went wrong. Please try again!",
            showConfirmButton: true,
            showCancelButton: false,
            closeOnClickOutside: true
          })
          setIsAlreadyApproved(false)
        }
      } else {
        swal({
          text: "Please select your currency to proceed",
          icon: "error"
        })
      }
    } else {
      await getWeb3()
      await connectToWallet()
      swal({
        title: "Failed",
        text: `Please connect your wallet.`,
        icon: "error",
        confirmButtonText: "Ok"
      })
    }
  }
  const payWithNcashWallet = async () => {
    if (!TNCAgreed) {
      swal({
        text: "Please agree to terms and conditions",
        icon: "warning"
      })
      return
    }

    console.log(
      `[nft][src][components][components][modal][payWithNcashWallet] Initiated`
    )
    console.log(
      `[nft][src][components][components][modal][payWithNcashWallet] Selected Token`
      // nca
    )

    await connectToWallet()

    let web3 = new Web3(window.ethereum)
    let accounts = await web3.eth.getAccounts()
    let ERC20 = await new web3.eth.Contract(erc20Abi, _nCashSelectedToken)
    let userBalance = await ERC20.methods.balanceOf(connectedWallet).call()
    let userBalanceBN = new BigNumber(userBalance)
    let _amountToPay = await web3.utils.toWei(NCashBalance.toString(), "ether")
    let _amountToPayBN = new BigNumber(_amountToPay)
    console.log(_amountToPayBN)

    // let balanceToPay = new BigNumber(ncashCryptoPaymentAmountToPay);
    let res = userBalanceBN.comparedTo(_amountToPayBN)
    setAmountPaid(_amountToPayBN)

    if (res === -1) {
      swal({
        icon: "error",
        title: "Failed",
        text: "Insufficient Funds. Please topup your Balance to proceed for payment",
        showConfirmButton: true,
        showCancelButton: false,
        closeOnClickOutside: true
      })
      return
    }

    try {
      let NitroPayment = await new web3.eth.Contract(
        nitroPaymentAbi,
        constant.NITRO_PAYMENT_CONTRACT
      )

      if (true) {
        // pay with ERC20

        console.log(NCashBalance, "NCashBalance")
        let _amountToPay = await web3.utils.toWei(
          NCashBalance.toString(),
          "ether"
        )
        let _amountToPayBN = _amountToPay.toString()
        console.log(_amountToPayBN)
        await NitroPayment.methods
          .payWithStableCoins(constant.NCASH, _amountToPayBN, 1)
          .send({ from: accounts[0] })
          .on("transactionHash", function (hash) {
            console.log(
              `[nft][src][components][components][modal][payWithWallet] transactionHash`,
              hash
            )
            setIsPayTxAvailable(true)
            let txHash = `${constant.EXPLORER_URL}/tx/${hash}`
            setPayTx(txHash)

            swal({
              icon: "info",
              title: "In progress",
              text: `Transaction Initiated, Please wait!`,
              closeOnClickOutside: false,
              buttons: ["View Transaction"],
              cancel: false,
              confirm: false
            }).then(function () {
              window.location = `${constant.EXPLORER_URL}/tx/${hash}`
            })

            waitTransactionReceipt(hash, "pay", 0, function (err, receipt) {
              if (err != null) {
                return
              }
            })
          })
          .on("error", function (error, receipt) {
            console.log(
              `[nft][src][components][components][modal][payWithWallet] transactionHash error 1`,
              error
            )
            swal({
              icon: "error",
              title: "Failed",
              text: "Transaction cancelled. Please try again!",
              showConfirmButton: true,
              showCancelButton: false,
              closeOnClickOutside: true
            })
            return
          })
      }
    } catch (error) {
      console.log(
        `[nft][src][components][components][modal][payWithWallet] transactionHash error 2`,
        error
      )
      swal({
        icon: "error",
        title: "Failed",
        text: "Something went wrong. Please try again!",
        showConfirmButton: true,
        showCancelButton: false,
        closeOnClickOutside: true
      })
    }
  }

  useEffect(() => {
    setBalance(
      allProducts.reduce(
        (pval, cval) => pval + cval?.price * tierQuantity[cval?.group_name],
        0
      )
    )
  }, [tierQuantity])

  console.log(connectedWallet)

  return (
    <>
      <div className="dark-scheme">
        <div className="tr mt-90pxx">
          <img src={Images.topBanner} width="100%" />
          <h1
            className="negative"
            style={{
              textAlign: "center",
              marginTop: "20px",
              fontSize: "35px"
            }}>
            Buy NFM
          </h1>

          {connectedWallet ? (
            <button
              className="pay-now-small less-padding-button negative-margin-48"
              id="pay-now-btn"
              onClick={connectToWallet}
              style={{ display: "flex", margin: "0 auto" }}>
              {"Connected : " +
                connectedWallet.slice(0, 5) +
                ".".repeat(3) +
                connectedWallet.slice(-4)}
            </button>
          ) : (
            <button
              className="pay-now-small less-padding-button negative-margin-48"
              id="pay-now-btn"
              onClick={connectToWallet}
              style={{ display: "flex", margin: "0 auto" }}>
              Connect to Wallet
            </button>
          )}
        </div>
        <section className="backgroundColordark ">
          <div className="row wow fade-in collection-boxes center-align-marketplacelist-buy">
            {filteredProduct ? (
              <div
                className="container product-detail-marketplace-space"
                style={{ marginBottom: 0, width: "80%" }}>
                <section id="nitro-nft-miner" style={{ margin: "auto" }}>
                  <div
                    className="nitro-hero-section pending-one"
                    id="product-details">
                    <div className="nitro-img">
                      <img
                        src={filteredProduct?.product_image}
                        alt={filteredProduct?.mystery}
                      />
                    </div>
                    <div className="nitro-text small-width" id="text-detail">
                      <h1 className="pink-text">{filteredProduct?.mystery}</h1>
                      <div className="line"></div>

                      <p>{filteredProduct?.product_desc}</p>

                      <div className=" justify-content-between flex-wrap max-width-40em">
                        <p className="purple-text">
                          Category :{" "}
                          <span className="text-white">
                            {filteredProduct?.category_name}
                          </span>
                        </p>
                        <p className="purple-text">
                          Group Name :{" "}
                          <span className="text-white">
                            {capitalize(filteredProduct?.group_name)}
                          </span>
                        </p>
                      </div>
                      <div className="justify-content-between align-items-center flex-wrap max-width-40em">
                        <p className="purple-text">
                          Purchased price :{" "}
                          <span className="text-white text-bold">
                            <strike>${filteredProduct?.price * 2}</strike>&nbsp; $
                            {filteredProduct?.price}
                          </span>
                        </p>

                        <div className="d-flex align-items-center justify-content-around">
                          <div className="counter">
                            <p
                              className="minus"
                              onClick={
                                tierQuantity[currentTier]
                                  ? () => {
                                      setTierQuantity(prevState => ({
                                        ...prevState,
                                        [currentTier]:
                                          prevState[currentTier] - 1
                                      }))
                                    }
                                  : () => {}
                              }>
                              <i className="fa fa-minus"></i>
                            </p>
                            <input
                              type="number"
                              name="update"
                              id="number-update"
                              min="11"
                              value={tierQuantity[currentTier]}
                              onChange={() => {}}
                            />
                            <p
                              className="plus"
                              onClick={() => {
                                setTierQuantity(prevState => ({
                                  ...prevState,
                                  [currentTier]: prevState[currentTier] + 1
                                }))
                              }}>
                              <i className="fa fa-plus"></i>
                            </p>
                          </div>
                          <p className="purple-text">
                            <button
                              onClick={
                                tierQuantity[currentTier]
                                  ? placeOrder
                                  : () => {}
                              }
                              id="pay-now-btn"
                              style={
                                !tierQuantity[currentTier]
                                  ? {
                                      filter: "brightness(0.65)"
                                    }
                                  : null
                              }>
                              Buy NFM
                            </button>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            ) : allProducts.length ? (
              allProducts.map((nft, index) => (
                <div
                  key={index}
                  id="marketplace"
                  className="d-item col-lg-3 col-md-6 col-sm-6 col-xs-12">
                  <div
                    className={`nft__item${
                      clickedIndex === index ? " mobile__view" : ""
                    }`}>
                    <div className="nft__item_wrap">
                      <a>
                        <img
                          src={nft?.product_image}
                          className="lazy nft__item_preview"
                          alt={nft?.mystery}
                        />
                      </a>
                      <img
                        src={clickit}
                        id="iconclick"
                        onClick={() => {
                          setClickedIndex(index)
                        }}
                        alt="Click Animation"
                      />
                    </div>

                    <div
                      className="nft__item_info row-justify-custom"
                      style={{ minHeight: 34 }}>
                      <div
                        className="text-center d-flex justify-content-center align-items-center"
                        style={{ margin: "auto" }}>
                        <span className="qty">Qty:&nbsp;&nbsp;&nbsp;</span>

                        <div className="counter">
                          <p
                            className="minus"
                            onClick={
                              tierQuantity[nft?.group_name]
                                ? () => {
                                    setTierQuantity(prevState => ({
                                      ...prevState,
                                      [nft?.group_name]:
                                        prevState[nft?.group_name] - 1
                                    }))
                                  }
                                : () => {}
                            }>
                            <i className="fa fa-minus"></i>
                          </p>
                          <input
                            type="number"
                            name="update"
                            id="number-update"
                            min="11"
                            value={tierQuantity[nft?.group_name]}
                            onChange={() => {}}
                          />
                          <p
                            className="plus"
                            onClick={() => {
                              setTierQuantity(prevState => ({
                                ...prevState,
                                [nft?.group_name]:
                                  prevState[nft?.group_name] + 1
                              }))
                            }}>
                            <i className="fa fa-plus"></i>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style={{ marginLeft: "24px" }}>
                    <h3 className="price_div">
                      Price: &nbsp;
                      <strike>
                        $
                        {(tierQuantity[nft?.group_name]
                          ? nft?.price * tierQuantity[nft?.group_name]
                          : nft?.price) * 2}
                      </strike>
                      &nbsp; $
                      {(tierQuantity[nft?.group_name]
                        ? nft?.price * tierQuantity[nft?.group_name]
                        : nft?.price)}
                    </h3>
                    <h3>
                      {nft?.category_name?.toUpperCase()}(
                      {capitalize(nft?.group_name).split("-").join(" ")})
                    </h3>
                    <p style={{ fontSize: "16px" }}>{nft?.product_desc}</p>
                  </div>
                </div>
              ))
            ) : null}
          </div>
        </section>
        {/* <Footer /> */}
        <div className="pending-pay-page container">
          <h2>Select Payment Method</h2>
          <div className="pay-details-boxes">
            <div className="pay-box">
              <h3 className="mb-2">Apply Coupon</h3>

              <div className="promo-bill-section">
                <div className="promo-section">
                  <Form
                    onFinish={discountObj ? removeDiscount : applyPromoCode}
                    autoComplete="off"
                    form={form}
                    layout="inline">
                    <div className="apply-promo mb-2">
                      <Form.Item
                        name="promoCode"
                        normalize={e => e.trim().toUpperCase()}
                        rules={
                          discountObj
                            ? []
                            : [
                                {
                                  required: true,
                                  message: "Please enter promo code to apply"
                                }
                              ]
                        }>
                        <input
                          type="text"
                          placeholder="Enter Promo Code"
                          className="purple bordered-button"
                          onChange={e =>
                            setPromoCode(e.target.value.trim().toUpperCase())
                          }
                          style={{
                            backgroundColor: "#23324e"
                          }}
                          disabled={discountObj}
                        />
                      </Form.Item>

                      <button
                        type="submit"
                        id="pay-now-btn"
                        disabled={!connectedWallet.length}
                        style={
                          !connectedWallet.length
                            ? {
                                filter: "brightness(0.64)",
                                cursor: "not-allowed"
                              }
                            : { maxHeight: "36px" }
                        }
                        className="bor  ered-button ">
                        {discountObj ? "Remove" : "Apply"}
                      </button>
                    </div>
                  </Form>

                  <p
                    className="line-height1 text-center"
                    style={{ fontWeight: "bold" }}>
                    <small style={{ fontSize: "17px" }}>
                      {discountObj?.message ?? ""}
                    </small>
                    <br />
                    <br />
                    <br />
                    <small className="light mt-2 mb-0">
                      (Promo code discount is applicable ONLY on Premium and Top
                      NFM purchases)
                    </small>
                  </p>
                </div>
                {/* BILL DETAILS */}
                <div className="bill-detail-section">
                  <h4 className="mb-2">Bill Details</h4>
                  <div className="dfjb">
                    <p className="mb-0 light-weight">Total Amount</p>
                    <p className="mb-0">
                      ${!isFloat(balance) ? addCommas(balance || 0) : balance}
                    </p>
                  </div>
                  <div className="dfjb">
                    <p className="light-weight">Discount</p>
                    <p>
                      -$
                      {!isFloat(discountObj?.amount ?? 0)
                        ? addCommas(discountObj?.amount ?? 0)
                        : discountObj?.amount ?? 0}
                    </p>
                  </div>
                  <div className="dfjb borded-tb py-2">
                    <p className="mb-0 light-weight">Payable Amount</p>
                    <p className="mb-0">
                      $
                      {!isFloat(balance - (discountObj?.amount ?? 0))
                        ? addCommas(balance - (discountObj?.amount ?? 0))
                        : balance - (discountObj?.amount ?? 0)}
                    </p>
                  </div>
                </div>
              </div>

              <div className="d-flex align-items-center justify-content-center margin-terms">
                <input
                  type="checkbox"
                  checked={TNCAgreed}
                  onChange={() => setTNCAgreed(prevState => !prevState)}
                />
                <p className="mb-0 ml-2">
                  I agree to{" "}
                  <a
                    href="https://buy.nitro.network/terms-and-conditions"
                    target="_blank"
                    className="link-color" rel="noreferrer">
                    Terms & Conditions
                  </a>
                </p>
              </div>
              <h3 className="mb-2">Payment methods</h3>

              {/* PAY WITH CRYPTO */}

              <div className="all-three-payment">
                {/* Pay with Coinbase */}
                {/* <div className="one-section-payment d-flex align-items-center justify-content-between">
                  <div className="text-left flex-1">
                    <h6 className="mb-1">Pay with Crypto</h6>
                    <p className="max-width-paragraph">
                      <small className="light-weight">
                        (BTC, BCH, ETH, DAI, DOGE, LTC)
                      </small>
                      <br></br>
                      <small className="light-weight">
                        If paying using USDC,
                      </small>
                      <br />
                      <small className="light-weight">
                        please send through ETH network only.
                      </small>
                      <br />
                      <br />

                      <small className="light-weight">
                        Powered by <b>coinbase</b>
                      </small>
                    </p>
                  </div>
                  <div className="">
                    <button
                      className="pay-now-small less-padding-button"
                      id="pay-now-btn"
                      // style={paymentInProgress ? { filter: "brightness(0.65)" } : null}
                      onClick={payWithCoinbase}>
                      Pay Now
                    </button>
                  </div>
                </div> */}

                {/* PAY WITH FIAT */}
                {/* <div className="one-section-payment d-flex align-items-center justify-content-between">
                  <div className="text-left">
                    <h6 className="mb-1">Pay with Fiat</h6>
                    <p>
                      <small className="light-weight">
                        (Pay with Credit/Debit cards)
                      </small>
                    </p>
                  </div>
                  <div className="">
                    <button
                      className="pay-now-small less-padding-button"
                      id="pay-now-btn"
                      // style={paymentInProgress ? { filter: "brightness(0.65)" } : null}
                      onClick={payWithFiat}>
                      Pay Now
                    </button>
                  </div>
                </div> */}
                {/* PAY WITH WALLET NEW */}
                <div className="one-section-payment d-flx align-items-center justify-conte w-100">
                  <div className="text-left w-100">
                    <div className="dfjb align-items-center">
                      <h6 className="mb-1">Pay with Crypto</h6>
                      <div>
                        {/* {connectedWallet != "undefined" && */}
                        {/* connectedWallet?.length > 0 && ( */}
                        {/* <p className="mb-0">
                        <small>Please Choose currency :</small>
                      </p> */}
                        {/* // )} */}
                        {connectedWallet != "undefined" &&
                          connectedWallet?.length > 0 && (
                            <div className="dfj">
                              <p className="mb-0">
                                <small>Please Choose currency :</small>
                              </p>
                              <select
                                className="currency-dropdown"
                                value={selectedToken}
                                onChange={handleSelectToken}>
                                <option value={0} defaultChecked>
                                  - select -
                                </option>
                                <option value={1}>WBTC.e</option>
                                <option value={2}>WETH.e</option>
                                <option value={3}>WAVAX</option>
                                <option value={4}>DAI.e</option>
                                <option value={5}>USDC.e</option>
                                <option value={6}>USDT.e</option>
                              </select>
                            </div>
                          )}
                      </div>
                    </div>
                    {/* <h6 className="mb-1">Pay with Crypto</h6> */}
                    <div className="dfjb align-items-end">
                      <p
                        className="mb-1"
                        style={
                          {
                            // display: "fle",
                            // justifyContent: "space-between",
                            // width: "100%",
                            // margin: "auto",
                          }
                        }>
                        <small className="light-weight">
                          (Use Avalanche network)
                          <br />
                          {/* (Connect with Metamask) */}
                        </small>
                        <small
                          style={{
                            maxWidth: "50%",
                            wordBreak: "break-word"
                            // marginLeft: "10px",
                          }}>
                          {connectedWallet != "undefined" &&
                          connectedWallet?.length ? (
                            <>
                              {"Connected : " +
                                connectedWallet.slice(0, 5) +
                                ".".repeat(3) +
                                connectedWallet.slice(-4)}
                              &nbsp;&nbsp;
                              <span
                                title="Disconnect Wallet"
                                style={{
                                  display: "inline-block",
                                  color: "white",
                                  cursor: "pointer",
                                  backgroundColor: "#161d30",
                                  width: "25px",
                                  height: "25px",
                                  fontSize: "15px",
                                  borderRadius: "50%",
                                  textAlign: "center"
                                }}
                                onClick={disconnectWallet}>
                                x
                              </span>
                            </>
                          ) : (
                            "(Connect with Metamask)"
                          )}
                        </small>
                      </p>
                      <div>
                        {isWalletConnected == true
                          ? selectedToken != 0 && (
                              <span className="d-block mb-2 text-white">
                                {paymentAmountToShow} {paymentTokenToshow}
                              </span>
                            )
                          : null}
                      </div>
                    </div>

                    <div className=" text-right flex-end-style mt-2">
                      {isWalletConnected == true &&
                        isAlreadyApproved == true && (
                          <>
                            <button
                              className="pay-now-small less-padding-button mt-3"
                              id="pay-now-btn"
                              onClick={payWithWallet}>
                              Pay with wallet
                            </button>
                          </>
                        )}

                      {isWalletConnected == true &&
                        isAlreadyApproved == false &&
                        connectedWallet != "undefined" && (
                          <>
                            <button
                              className="pay-now-small less-padding-button "
                              id="pay-now-btn"
                              onClick={approveNFTPaymentContract}>
                              Approve
                            </button>
                          </>
                        )}

                      {(connectedWallet == "undefined" ||
                        isWalletConnected == false) && (
                        <button
                          className="pay-now-small less-padding-button "
                          id="pay-now-btn"
                          onClick={connectToWallet}>
                          Connect Wallet
                        </button>
                      )}
                    </div>
                  </div>
                </div>
                {/* PAY WITH NCash */}
                <div className="one-section-payment d-flex align-items-center justify-content-between">
                  <div className="text-left">
                    <h6 className="mb-1">Pay with NCash</h6>
                    <p>
                      <small className="light-weight">
                        (Use Avalanche Network)
                      </small>
                      <br />
                      <small
                        style={{
                          maxWidth: "50%",
                          wordBreak: "break-word"
                          // marginLeft: "10px",
                        }}>
                        {connectedWallet != "undefined" &&
                        connectedWallet?.length ? (
                          <>
                            {"Connected : " +
                              connectedWallet.slice(0, 5) +
                              ".".repeat(3) +
                              connectedWallet.slice(-4)}
                            &nbsp;&nbsp;
                            <span
                              title="Disconnect Wallet"
                              style={{
                                display: "inline-block",
                                color: "white",
                                cursor: "pointer",
                                backgroundColor: "#161d30",
                                width: "25px",
                                height: "25px",
                                fontSize: "15px",
                                borderRadius: "50%",
                                textAlign: "center"
                              }}
                              onClick={disconnectWallet}>
                              x
                            </span>
                          </>
                        ) : (
                          "(Connect with Metamask)"
                        )}
                      </small>
                      <br></br>
                    </p>
                  </div>
                  <div className="dfjb" style={{ color: "white" }}>
                    Total NCash : {totalNcash} <br />
                    Discount : 15% <br />
                    NCash : {NCashBalance}
                  </div>
                  <div className="">
                    {isWalletConnected == true && isAlreadyApproved == true && (
                      <>
                        <button
                          className="pay-now-small less-padding-button "
                          id="pay-now-btn"
                          onClick={payWithNcashWallet}>
                          Pay with wallet
                        </button>
                      </>
                    )}

                    {isWalletConnected == true &&
                      isAlreadyApproved == false &&
                      connectedWallet != "undefined" && (
                        <>
                          <button
                            className="pay-now-small less-padding-button "
                            id="pay-now-btn"
                            onClick={approveNcashNFTPaymentContract}>
                            Approve
                          </button>
                        </>
                      )}

                    {(connectedWallet == "undefined" ||
                      isWalletConnected == false) && (
                      <button
                        className="pay-now-small less-padding-button "
                        id="pay-now-btn"
                        onClick={connectToWallet}>
                        Connect Wallet
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <marquee
              vspace="100"
              style={{ fontWeight: "bold", color: "red", fontSize: 18 }}>
              Pay with NCash to avail 15% discount
              <span style={{ display: "inline-block", width: "10vw" }}></span>
              Pay with NCash to avail 15% discount
              <span style={{ display: "inline-block", width: "10vw" }}></span>
              Pay with NCash to avail 15% discount
              <span style={{ display: "inline-block", width: "10vw" }}></span>
            </marquee>
          </div>
        </div>

        {/* GET YOUR NCASH HRER */}
        <GetYourNcashHere />
      </div>
    </>
  )
}

export default NewBuyNfmPage
