import React, { useEffect, useState } from "react"
import axios from "axios"
import SensorComponent from "./sensorComponent"
import StatusBar from "./StatusBar"
import AnalyticsChart from "./AnalyticsChart"

const SensorDashboard = ({
  deviceEuid,
  sensorName,
  isGatewayActive,
  setShowAnalytics
}) => {
  const [activeProp, SetActiveProp] = useState("")
  const [lastReadingTimestamp, setLastReadingTimestamp] = useState("")
  const loginUserToken = localStorage.getItem("loginUserToken")
  const [sensorAnalytics, setAnalytics] = useState([])
  const [isSensorActive, setIsSensorActive] = useState(false)

  const [graphData, setGraphData] = useState(null)

  const onAtive = (item, datetime) => {
    SetActiveProp(item)
    setLastReadingTimestamp(datetime)
  }

  const fetchSensorData = async () => {
    await axios
      .get(
        `${process.env.REACT_APP_IOT_API_URL}/getSensorAnalytics/${deviceEuid}`,
        {
          headers: {
            // 'Content-Type': 'application/json',
            Authorization: `Bearer ${loginUserToken}`
          }
        }
      )
      .then(res => {
        const { data } = res
        const {
          analyticsData,
          sensorData: { isSensorActive }
        } = data
        SetActiveProp(analyticsData[0]?.name || "")
        setLastReadingTimestamp(analyticsData[0]?.datetime)
        setAnalytics(data?.analyticsData || [])
        setIsSensorActive(isSensorActive)
        fetchGraphData(analyticsData[0]?.key)
      })
      .catch(err => {})
  }

  const fetchGraphData = async key => {
    await axios
      .get(
        `${process.env.REACT_APP_IOT_API_URL}/getSensorAnalyticsGraph/${deviceEuid}/${key}`,
        {
          headers: {
            Authorization: `Bearer ${loginUserToken}`
          }
        }
      )
      .then(res => {
        if (res.status !== 200) throw new Error("Something went wrong")
        const { data } = res

        if (data) {
          setGraphData({ ...data.data })
        }
      })
      .catch(err => {})
  }

  useEffect(() => {
    fetchSensorData()
  }, [deviceEuid])

  return (
    <>
      <div>
        <div className="mb-3">
          <StatusBar
            showAnalytics={true}
            isGatewayActive={isGatewayActive}
            sensorName={sensorName}
            isSensorActive={isSensorActive}
          />
        </div>
        <div className="back_icon">
          <i
            className="fa fa-arrow-left"
            aria-hidden="true"
            onClick={() => {
              setShowAnalytics(false)
            }}></i>
          <h1 className="analytics-head">{sensorName}</h1>
        </div>
        <div className="row">
          <div className=" col-xl-4 col-lg-3 col-md-3 col-sm-12 col-xs-12">
            <div className="row sensors-box">
              {sensorAnalytics && sensorAnalytics.length !== 0 ? (
                sensorAnalytics.map(data => {
                  return (
                    <div className="col-6 col-md-12 col-lg-12 col-xl-6">
                      <SensorComponent
                        key={data?.key}
                        activeProp={activeProp}
                        onAtive={onAtive}
                        name={data?.name || ""}
                        image={data?.image}
                        datetime={data?.datetime}
                        value={`${String(data?.value) || ""} ${
                          data?.units || ""
                        }`}
                        textColor={`${data?.color}`}
                        clickFunc={() => {
                          fetchGraphData(data?.key)
                        }}
                      />
                    </div>
                  )
                })
              ) : (
                <p>No Data Found</p>
              )}
            </div>
          </div>
          <div className="col-xl-8 col-lg-9 col-md-9 col-sm-12 col-xs-12">
            {/* <ChartComponent title={activeProp} graphData={graphData} /> */}
            <AnalyticsChart
              lastReading={lastReadingTimestamp}
              title={activeProp}
              graphData={graphData}
            />
          </div>
        </div>
      </div>
    </>
  )
}
export default SensorDashboard
