export const burnData = [
  {
    index: 19,
    dateOfBurn: "May-6-2023",
    burnedAmount: 176945000,
    isErc: false,
    burnTrx:
      "https://snowtrace.io/tx/0x42ce164c2532a2776e2dfaf5f31aef85a9ed4e82ab04dbaaf03ce72571cf6a39"
  },
  {
    index: 18,
    dateOfBurn: "Apr-6-2023",
    burnedAmount: 345963349,
    isErc: false,
    burnTrx:
      "https://snowtrace.io/tx/0x40a9cc687bfd9bb1ee36077b38ca4baae07feb36162914b61aa97d933b374eaf"
  },
  {
    index: 17,
    dateOfBurn: "Mar-6-2023",
    burnedAmount: 300791045,
    isErc: false,
    burnTrx:
      "https://snowtrace.io/tx/0x4b3384d0d541e4278e34fec85c73a367ed6b683dbffd6bab1e70dc22d0642371"
  },
  {
    index: 16,
    dateOfBurn: "Feb-7-2023",
    burnedAmount: 354708663,
    isErc: false,
    burnTrx:
      "https://snowtrace.io/tx/0x86a0bb037bca750dbecfea93cc429dd99606123b2308bd11be4a0e2beb22fb1c"
  },
  {
    index: 15,
    dateOfBurn: "Jan-5-2023",
    burnedAmount: 560085500,
    isErc: false,
    burnTrx:
      "https://snowtrace.io/tx/0x9e8616019facc65cf7b168e30cb20599fce5776cb5161e1cadd7c789b7d7c5af"
  },
  {
    index: 14,
    dateOfBurn: "Dec-5-2022",
    burnedAmount: 533851000,
    isErc: false,
    burnTrx:
      "https://snowtrace.io/tx/0xe98a6b71863690b7cda680dded848d836428b0ea27ea0dbca1b2cdb9db730839"
  },
  {
    index: 13,
    dateOfBurn: "Nov-14-2022",
    burnedAmount: 669000000,
    isErc: false,
    burnTrx:
      "https://snowtrace.io/tx/0x1023f3715c9a6e80449e8a12d5695a26ac950548c09e0a12d7612900feda996e"
  },
  {
    index: 12,
    dateOfBurn: "Nov-14-2022",
    burnedAmount: 8841,
    isErc: false,
    burnTrx:
      "https://snowtrace.io/tx/0xc247ddd584e93b5ae83903637a68860d44592768f6fa51e917f683f431221c62"
  },
  {
    index: 11,
    dateOfBurn: "Oct-13-2022",
    burnedAmount: 72417374,
    isErc: false,
    burnTrx:
      "https://snowtrace.io/tx/0x70f5ce75da8b484569b8d61f0b7f6035f0342a05802cb5edd39a5ceefc8ea98d"
  },
  {
    index: 10,
    dateOfBurn: "Oct-13-2022",
    burnedAmount: 100000,
    isErc: false,
    burnTrx:
      "https://snowtrace.io/tx/0x8b6f189539290d3403e410bd0f3132c14e3fad6c5d9561a8f8bbf0e0b8d21aa9"
  },
  {
    index: 9,
    dateOfBurn: "Oct-11-2022",
    burnedAmount: 10000,
    isErc: false,
    burnTrx:
      "https://snowtrace.io/tx/0x3d20b994cd329760ae9404ecf17a135e1da9ac2f87802b5003bf6d9cbe3abfd8"
  },
  {
    index: 8,
    dateOfBurn: "Sep-01-2022",
    burnedAmount: 42139401,
    isErc: false,
    burnTrx:
      "https://snowtrace.io/tx/0x32b08dae21c374b36975aef84084b417f7718f39e050488553cbeb4b8bf5d019"
  },
  {
    index: 7,
    dateOfBurn: "Sep-01-2022",
    burnedAmount: 2000000,
    isErc: false,
    burnTrx:
      "https://snowtrace.io/tx/0x82d64261f34d4588323ed339f44ff41be814ad4c2e0e239faecc26c6be6d70ba"
  },
  {
    index: 6,
    dateOfBurn: "Sep-01-2022",
    burnedAmount: 1000000,
    isErc: false,
    burnTrx:
      "https://snowtrace.io/tx/0xeac487e5b9c186901a8e32f382a374609d69b8ec505748b84c4d9b9b458c3995"
  },
  {
    index: 5,
    dateOfBurn: "Jul-31-2022",
    burnedAmount: 45000000,
    isErc: false,
    burnTrx:
      "https://snowtrace.io/tx/0xb3ae316fc738fbbc2b7ed02c746dcdf05897a63619d867058b3f4d4260b51363"
  },
  {
    index: 4,
    dateOfBurn: "Jul-29-2022",
    burnedAmount: 1000306469,
    isErc: true,
    burnTrx:
      "https://etherscan.io/tx/0x4268e4ac3822a351de6d0e9c3912e1728735d1bb6042cc93036a89bcfd05ba38"
  },
  {
    index: 3,
    dateOfBurn: "Jul-29-2022",
    burnedAmount: 200000000,
    isErc: true,
    burnTrx:
      "https://etherscan.io/tx/0xb82ab704ab2d91f008090cf2033222424286f6549008d8a5ecd140816509047a"
  },
  {
    index: 2,
    dateOfBurn: "Jun-30-2022",
    burnedAmount: 50000,
    isErc: false,
    burnTrx:
      "https://snowtrace.io/tx/0x7bf1c9baef8dd3d4fcfa2e1da3a926e0509de20a6ae0d87d0faae3eb9692ff26"
  },
  {
    index: 1,
    dateOfBurn: "Jun-30-2022",
    burnedAmount: 23500000,
    isErc: false,
    burnTrx:
      "https://snowtrace.io/tx/0xbda4a669ff21be7a7d675f29829d02f18d71bad861a8d979650120a769d84b5b"
  }
]
