/* eslint-disable */
import React, { useRef, useState, useEffect } from "react"
import "mapbox-gl/dist/mapbox-gl.css"
import mapboxgl from "mapbox-gl"
mapboxgl.workerClass =
  // eslint-disable-next-line import/no-webpack-loader-syntax
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default

import { useNavigate, Link } from "@reach/router"
import { Tooltip } from "antd"
import * as FaIcons from "react-icons/fa"
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder"
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css"
import "../../Home.css"

import { mapStyleOptions } from "../../../../../../Shared"
import { useSelector, useDispatch } from "react-redux"
import { setStyleType } from "../../../../../../redux/actions"
const MAPBOX_TOKEN =
  "pk.eyJ1IjoiYmFsamVldHJlYWN0IiwiYSI6ImNreW54ZmI2ZjNieGUybnFwNnd1aXRudTEifQ.a0Yo3o" +
  "2Cy1mID1653pidRA"

export default function Maphomedisplay(props) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const mapContainer = useRef(null)
  const map = useRef(null)

  const style = useSelector(state => state.common.style)

  useEffect(() => {
    mapboxgl.accessToken = MAPBOX_TOKEN
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style,
      center: [props.Longitude, props.latitude],
      zoom: props.zoom
    })

    // to disable right click map rotation
    map.current.dragRotate.disable()
    // to disable touch/pinch zoom map rotation
    map.current.touchZoomRotate.disableRotation()

    const geolocate1 = new MapboxGeocoder({
      accessToken: MAPBOX_TOKEN,
      mapboxgl: mapboxgl,
      getItemValue: ({ center, place_name }) => {
        fetch(
          "https://api.mapbox.com/geocoding/v5/mapbox.places/" +
            center[0] +
            "," +
            center[1] +
            ".json?place_type=address&access_token=" +
            MAPBOX_TOKEN
        )
          .then(response => {
            if (response.status >= 400 && response.status < 600) {
              throw new Error("Bad response from server")
            }
            return response.json()
          })
          .then(returnedResponse => {
            navigate("/miner", {
              state: {
                lat: returnedResponse.query[1],
                lng: returnedResponse.query[0],
                id: returnedResponse.features[0].place_name,
                address: returnedResponse.features[0].place_name
              }
            })
          })
          .catch(error => {})
        return place_name
      }
    })

    map.current.addControl(geolocate1, "top-left")

    const geolocate = new mapboxgl.GeolocateControl({
      positionOptions: {
        enableHighAccuracy: true
      },

      trackUserLocation: true,

      showUserHeading: true
    })

    // var popup = new mapboxgl
    //     .Popup({closeOnClick: false})
    //     .setLngLat([props.Longitude, props.latitude])
    //     .setHTML('<h1>Hello World!</h1>')
    //     .addTo(map.current);

    const marker = new mapboxgl.Marker()
    fetch(
      "https://api.mapbox.com/geocoding/v5/mapbox.places/" +
        props.Longitude +
        "," +
        props.latitude +
        ".json?place_type=address&access_token=" +
        MAPBOX_TOKEN
    )
      .then(response => {
        if (response.status >= 400 && response.status < 600) {
          throw new Error("Bad response from server")
        }
        return response.json()
      })
      .then(returnedResponse => {
        popup.setText(returnedResponse.features[0].place_name)
      })
      .catch(error => {})
    // marker
    //     .setLngLat([props.Longitude, props.latitude])
    //     .addTo(map.current);

    map.current.addControl(geolocate, "top-left")

    geolocate.on("geolocate", () => {
      if (document.location.pathname === "/nest") {
        fetch(
          "https://api.mapbox.com/geocoding/v5/mapbox.places/" +
            geolocate._accuracyCircleMarker._lngLat.lng +
            "," +
            geolocate._accuracyCircleMarker._lngLat.lat +
            ".json?place_type=address&access_token=" +
            MAPBOX_TOKEN
        )
          .then(response => {
            if (response.status >= 400 && response.status < 600) {
              throw new Error("Bad response from server")
            }
            return response.json()
          })
          .then(returnedResponse => {
            let state = {
              lat: returnedResponse.query[1],
              lng: returnedResponse.query[0],
              id: returnedResponse.features[0].place_name,
              address: returnedResponse.features[0].place_name
            }
            navigate("/miner", {
              state,
              replace: true
            })
          })
          .catch(error => {})
      }
    })
    const nav = new mapboxgl.NavigationControl({ visualizePitch: true })
    map.current.addControl(nav, "bottom-right")
  }, [props.Longitude, props.latitude, props.zoom])

  useEffect(() => {
    map.current.setStyle(style)
  }, [style])

  useEffect(() => {
    document.title = "Select Nesting Location - Nitro Nesting"
    return () => {
      document.title = "Nitro Network"
    }
  }, [])

  const styleHandler = e => {
    dispatch(setStyleType(e.target.value))
  }

  return (
    <>
      <Tooltip
        id="locate-tooltip"
        title="Click here to select your current location where you want to nest your Nitro miner and continue with your Nesting registration process."
        placement="bottom">
        <FaIcons.FaExclamationCircle />
      </Tooltip>
      <Tooltip
        id="search-tooltip"
        title="Search the address on the map where you want to nest your Nitro miner by clicking here and continue with your Nesting registration process."
        placement="bottom">
        <FaIcons.FaExclamationCircle />
      </Tooltip>
      <div ref={mapContainer} className="map-container map-container-wrap" />
      <div
        onChange={styleHandler}
        className="style-container"
        style={{
          bottom: "3%",
          left: "1%"
        }}>
        {mapStyleOptions.map((styleObj, index) => (
          <span key={styleObj.id + index}>
            <input
              type="radio"
              id={styleObj.id}
              value={styleObj.value}
              checked={styleObj.value === style}
            />
            <label htmlFor={styleObj.id}>{styleObj.label}</label>
          </span>
        ))}
      </div>
      <Tooltip title="Your registered nesting address">
        <FaIcons.FaExclamationCircle />
      </Tooltip>
    </>
  )
}
