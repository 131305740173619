import { Form, Input } from "antd"
import axios from "axios"
import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import swal from "sweetalert"
import { useLogout } from "../../../hooks"
import { IoTAPIUrl, IPAPI_TOKEN } from "../../../utils/config"
import "./css/my-styles.css"
import PaymentDetails from "./PaymentDetails"
import PriceDetails from "./PriceDetails"
import ShippingDetails from "./ShippingDetails"
import { IoTCountryOptions } from "../../../Shared/constants"
import SelectDropDown from "../../components/SelectDropdown"

function Cart() {
  const logoutHandler = useLogout()
  const [currentStepIndex, setCurrentStepIndex] = useState(0)
  const [orderSummary, setOrderSummary] = useState(null)
  const [additionalCharges, setAddtionalCharges] = useState(null)
  const [addresses, setAddresses] = useState([])
  const [selectedAddIdx, setSelectedAddIdx] = useState(-1)

  // Promocode
  const [promoCode, setPromoCode] = useState("")
  const [promoDiscount, setPromoDiscount] = useState(0)
  const [promoCodeMsg, setPromoCodeMsg] = useState("")
  const [country, setCountry] = useState(null)
  const userDetails = useSelector(state => state.common.userDetails)

  const [form] = Form.useForm()

  const formHeadings = ["ORDER SUMMARY", "SHIPPING DETAILS", "PAYMENT DETAILS"]
  const requestOptions = {
    headers: {
      Authorization: `Bearer ${userDetails?.loginUserToken}`
    }
  }

  // const getFormStepClass = stepIndex => {
  //   let className = ""
  //   if (stepIndex === currentStepIndex) className = "form-stepper-active"
  //   else if (stepIndex < currentStepIndex) className = "form-stepper-completed"
  //   else className = "form-stepper-unfinished"
  //   return className
  // }

  const getFormStepClassActiveClass = stepIndex => {
    let className = ""
    if (stepIndex === currentStepIndex) className = "active"
    else if (stepIndex < currentStepIndex) className = "active completed"
    else className = "unfinish"
    return className
  }

  const updateFormStep = (type = "") => {
    if (
      (type === "prev" && currentStepIndex === 0) ||
      (type === "next" && currentStepIndex === forms.length - 1)
    )
      return
    // If on choose address step and clicked next without choosing address
    if (currentStepIndex === 1 && type === "next" && selectedAddIdx < 0) {
      swal({
        title: "Warning",
        text: "Please choose an address first",
        icon: "warning"
      })
      return
    }
    setCurrentStepIndex(currentStepIndex + (type === "prev" ? -1 : 1))
  }

  const handleFormStepClick = index => {
    if (!orderSummary) return
    // If going to payment page but haven't selected address yet
    if (index === 2 && selectedAddIdx < 0) {
      swal({
        title: "Warning",
        text: "Please choose an address first",
        icon: "warning"
      })
      setCurrentStepIndex(1)
      return
    }
    setCurrentStepIndex(index)
  }

  const getOrderSummary = async () => {
    if (!country) {
      swal({
        title: "Warning!",
        text: "Please choose country first",
        icon: "warning"
      })
      return
    }
    try {
      const response = await axios.get(
        `${IoTAPIUrl}/getOrderSummary/${userDetails?.loginUserId}/${country?.value}`,
        requestOptions
      )
      if (response.status === 200 && response.data.data.length) {
        setOrderSummary(response.data.data[0])
      } else {
        setOrderSummary(null)
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        logoutHandler()
      }
    }
  }

  const getAddresses = async (type = "") => {
    try {
      const response = await axios.get(
        `${IoTAPIUrl}/getAddress/${userDetails?.loginUserId}`,
        requestOptions
      )

      if (response.status === 200) {
        // if (type === "newAddress") {
        //   setSelectedAddIdx(response.data.data.length - 1);
        //   setCurrentStepIndex(forms.length - 1);
        // }
        setAddresses(response.data.data)
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        logoutHandler()
      }
    }
  }

  const applyPromoCode = async () => {
    if (!promoCode) return
    const requestData = {
      userId: userDetails?.loginUserId,
      promoCode: promoCode,
      country: country?.value
    }
    try {
      const response = await axios.post(
        `${IoTAPIUrl}/applyPromoCode`,
        requestData,
        requestOptions
      )
      if (response.status === 200) {
        setPromoDiscount(response.data.discountAmount)
        setPromoCodeMsg(response.data.message)
      } else {
        throw new Error(response.data.message)
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        logoutHandler()
      } else {
        setPromoCodeMsg(error.response?.data.message)
      }
    }
  }

  const getUserCountry = async () => {
    if (country) return
    try {
      let response = await axios.get(
        `https://ipapi.co/country?key=${IPAPI_TOKEN}`
      )
      if (response.status !== 200) {
        throw new Error("Something went wrong")
      }
      setCountry(
        IoTCountryOptions.find(country => country.countryCode === response.data)
      )
    } catch (error) {
      console.log("Cannot auto-detect the user's country")
    }
  }

  const getAdditionalCharges = async state => {
    const requestData = {
      country: country?.value,
      amount: orderSummary?.totalAmount,
      state
    }

    try {
      const response = await axios.post(
        `${IoTAPIUrl}/getTaxAndDeliveryCharges`,
        requestData
      )
      setAddtionalCharges(response.data?.data)
    } catch (error) {
      console.log(error.message)
    }
  }

  const getFill = () => {
    let fillWidth = (100 / (formHeadings.length - 1)) * currentStepIndex
    return fillWidth
  }

  const resetPromoCode = () => {
    form.resetFields()
    setPromoCode("")
    setPromoCodeMsg("")
    setPromoDiscount(0)
  }

  let amountPayable =
    (orderSummary?.totalAmount ?? 0) +
    (currentStepIndex > 0 && selectedAddIdx > -1
      ? (additionalCharges?.deliveryCharges ?? 0) +
        (additionalCharges?.tax ?? 0)
      : 0) -
    promoDiscount

  const forms = [
    <PriceDetails
      requestOptions={requestOptions}
      country={country}
      orderSummary={orderSummary}
      getOrderSummary={getOrderSummary}
    />,
    <ShippingDetails
      country={country}
      requestOptions={requestOptions}
      addresses={addresses}
      getAddresses={getAddresses}
      selectedAddIdx={selectedAddIdx}
      setSelectedAddIdx={setSelectedAddIdx}
      getAdditionalCharges={getAdditionalCharges}
    />,
    <PaymentDetails
      requestOptions={requestOptions}
      country={country}
      promoCode={promoCode}
      selectedAddress={selectedAddIdx < 0 ? null : addresses[selectedAddIdx]}
      orderSummary={orderSummary}
      amountPayable={amountPayable}
      setSelectedAddIdx={setSelectedAddIdx}
      setCurrentStepIndex={setCurrentStepIndex}
    />
  ]

  useEffect(() => {
    getUserCountry()
  }, [])

  useEffect(() => {
    setCurrentStepIndex(0)
    setSelectedAddIdx(-1)
    if (!country) {
      setOrderSummary(null)
      setAddresses([])
      return
    }
    getOrderSummary()
    getAddresses()
  }, [country])

  useEffect(() => {
    resetPromoCode()
  }, [orderSummary])

  return (
    <div className="iot-cart-checkout Pending-orders-page">
      <section className="" id="cart-page-container">
        <div id="multi-step-form-container">
          <div className="iot-country-section">
            <SelectDropDown
              value={country}
              className="iot-country-dropdown"
              changeHandler={val => setCountry(val)}
              options={IoTCountryOptions}
              isClearable={true}
              placeholder="Choose Country"
              formatOptionLabel={option => (
                <div>
                  <img
                    style={{
                      borderRadius: "50%",
                      width: "25px",
                      height: "25px",
                      marginRight: "8px"
                    }}
                    src={
                      option.value === "EU Region"
                        ? "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b7/Flag_of_Europe.svg/383px-Flag_of_Europe.svg.png"
                        : `https://cdn.ipregistry.co/flags/emojitwo/${option.countryCode.toLowerCase()}.svg`
                    }
                    alt={option.countryCode}
                  />
                  <span style={{ fontWeight: 450, fontSize: "1rem" }}>
                    {option.label}
                  </span>
                </div>
              )}
            />
          </div>
          <br />
          <div className="form-steps-iot">
            <div className="form-steps_links">
              {formHeadings.map((heading, i) => (
                <div
                  className={`${getFormStepClassActiveClass(
                    i
                  )} form-step__link`}
                  //  className="form-step__link active"
                  step={i + 1}
                  key={heading + i}
                  onClick={() => handleFormStepClick(i)}>
                  {heading}
                </div>
              ))}
            </div>
            {/* (100 / multiForm.length) * (page + 1) */}
            <div
              className="absolute-line"
              style={{ "--fill-blue": `${getFill()}%` }}></div>
          </div>

          <div className="row iot-order-summary">
            <div className="col-lg-7">
              <div id="userAccountSetupForm">
                {/* Forms Container */}
                <div id={`step-${currentStepIndex + 1}`} className="form-step">
                  {forms[currentStepIndex]}
                </div>
              </div>
            </div>
            {orderSummary ? (
              <div className="col-lg-5">
                <div className="card-ship">
                  <p className="">
                    <b>Price Details</b>
                  </p>
                  <hr />
                  <div className="row">
                    <div className="dfjb">
                      <p className="mb-1 ">Price Details</p>
                      <p className="mb-1">
                        {country?.currencySymbol ?? "$"}
                        {(orderSummary?.totalAmount ?? 0)?.toFixed(2)}
                      </p>
                    </div>
                    <div className="dfjb">
                      <p className=" mb-1">Discount</p>
                      <p className="mb-1">
                        {country?.currencySymbol ?? "$"}
                        {(promoDiscount || 0)?.toFixed(2)}
                      </p>
                    </div>
                    {currentStepIndex >= 1 && selectedAddIdx > -1 ? (
                      <>
                        <div className="dfjb">
                          <p className=" mb-1">Delivery Charges</p>
                          <p className="mb-1">
                            {country?.currencySymbol ?? "$"}
                            {(additionalCharges?.deliveryCharges ?? 0)?.toFixed(
                              2
                            )}
                          </p>
                        </div>

                        <div className="dfjb">
                          <p className="">Tax</p>
                          <p>
                            {country?.currencySymbol ?? "$"}
                            {(additionalCharges?.tax ?? 0)?.toFixed(2)}
                          </p>
                        </div>

                        <p className="mb-0" style={{ fontSize: "0.8rem" }}>
                          <small>
                            Discount is not applicable on Delivery charges
                          </small>
                        </p>
                      </>
                    ) : null}
                  </div>
                  <Form
                    className="input-group  pt-3 iot-promo-form"
                    form={form}
                    onFinish={promoDiscount ? resetPromoCode : applyPromoCode}>
                    <Form.Item
                      name="promoCode"
                      rules={[
                        {
                          required: true,
                          message: "Please enter a valid promo code"
                        }
                      ]}
                      normalize={e => e.trim().toUpperCase()}>
                      <Input
                        type="text"
                        className="form-control fix-height-input"
                        placeholder="Promocode"
                        value={promoCode}
                        onChange={e =>
                          setPromoCode(e.target.value.toUpperCase())
                        }
                      />
                    </Form.Item>
                    <button
                      className=" btn-light fix-height-input input-group-append btn-brand btn-active iot-apply-promo-button"
                      // disabled={!promoCode}
                    >
                      {promoDiscount ? "Remove" : "Apply"}
                    </button>
                  </Form>
                  <span className="">{promoCodeMsg}</span>
                  <div className="row border-bottom-op border-top pt-4">
                    <div className="dfjb">
                      <p className="mb-1 ">Total</p>
                      <p className="mb-1">
                        {country?.currencySymbol}
                        {(amountPayable || 0)?.toFixed(2)}
                      </p>
                    </div>
                  </div>
                </div>

                {/* Form Navigation Buttons */}
                <div className="text-right">
                  {country ? (
                    <div className="mt-3 right-floated-btns">
                      {currentStepIndex === 0 ? null : (
                        <button
                          className="btn-brand btn-active"
                          onClick={() => updateFormStep("prev")}
                          type="button">
                          &larr; Back
                        </button>
                      )}
                      {currentStepIndex === forms.length - 1 ||
                      !orderSummary ? null : (
                        <button
                          className="btn-brand btn-active"
                          type="button"
                          style={{ marginLeft: "10px" }}
                          onClick={() => updateFormStep("next")}>
                          Next &rarr;
                        </button>
                      )}
                    </div>
                  ) : null}
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </section>
      <p style={{ marginTop: orderSummary ? 0 : "20vh", textAlign: "right" }}>
        Shipping and Taxes are subject to address chosen. They will be added to
        the amount on address selection, if applicable.
      </p>
    </div>
  )
}

export default Cart
