import React from "react"

const EditSensorNameModal = ({
  setEditPopup,
  renamedSensorName,
  setRenamedSensorName,
  updateUserDeviceName
}) => {
  return (
    <>
      {/* Edit Screen Popup */}
      <div className="popup backlayer">
        <div className="popup__box">
          <div className="popup__head">
            <h6>Edit </h6>
            <span
              className="popup__close-btn"
              onClick={() => setEditPopup(false)}>
              X
            </span>
          </div>

          <div className="popup__body">
            <div className="popup__input input-onerow">
              <label htmlFor="rename">Rename Sensor : </label>
              <input
                type="text"
                name="rename"
                id="rename"
                className="input-box"
                value={renamedSensorName}
                onChange={e => setRenamedSensorName(e.target.value)}
                placeholder="ex: Kitchen"
              />
            </div>

            <div className="text-right">
              <button
                className="btn-brand btn-active "
                onClick={updateUserDeviceName}
                style={{
                  opacity: renamedSensorName.trim().length < 3 ? 0.5 : 1
                }}
                disabled={renamedSensorName.trim().length < 3 ? true : false}>
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default EditSensorNameModal
