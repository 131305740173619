import React from "react"
import Moment from "react-moment"
const SensorComponent = ({
  name,
  subname,
  image,
  value,
  textColor,
  activeProp,
  datetime,
  onAtive,
  clickFunc
}) => {
  return (
    <>
      <div
        className={`dash-box-u3 ${activeProp === name ? "active" : ""}`}
        onClick={() => {
          onAtive(name, datetime)
          clickFunc()
        }}>
        <div className="energydetail-u3">
          <div className="textSmall">{name}</div>
          <img className="snesorImg" src={image} alt="" />
          <p
            className="sensor-value"
            style={{
              color: textColor
            }}>
            {value}
          </p>
        </div>

        <div className="timestamp-u3">
          <p className="small-u3">
            <Moment format="llll">{datetime}</Moment>
          </p>
        </div>
      </div>
    </>
  )
}
export default SensorComponent
