export const defaultPageMetaData = {
  title: "Nitro Network",
  description:
    "Nitro is the gold standard in PCN fueled by IoT with LoRaWAN/3G/4G and 5G, delivering decentralized wireless infrastructure at an effective cost structure.",
  route: "/"
}

export const pageMetaDataArr = [
  defaultPageMetaData,
  {
    title: "Nest",
    description: "Test Description",
    route: "/myaccount/mynest"
  },
  {
    page: "IoT pending orders",
    title:
      "Nitro Network IoT - View Your Pending Orders and Manage Your Account",
    description:
      "Access your pending orders and manage your Nitro Network IoT account with ease. Our user-friendly platform allows you to view your pending orders, track their progress, and make any necessary changes. Sign in to your account today and experience seamless IoT device management.",
    route: "/myaccount/pendingOrders"
  },
  {
    page: "Marketplace",
    title: "Nitro Network IoT Marketplace - Buy and Sell Connected Devices",
    description:
      "Discover the Nitro Network IoT Marketplace, where you can buy and sell a variety of connected devices. Our decentralized platform allows for secure and transparent transactions, while offering rewards for using our ecosystem. Join the future of IoT today and explore our marketplace.",
    route: "/marketplace"
  },
  {
    page: "Ncash",
    title: "NCash Token - AVAX Native Cryptocurrency | Nitro Network",
    description:
      "NCash is the protocol token of the Nitro Network and is an AVAX native cryptocurrency. It allows Nitrogen holders to earn rewards for staking, farming, and IoT adoption, while enabling users to monitor and manage their everyday living through IoT.",
    route: "/ncash"
  },
  {
    page: "Burn",
    title: "NCash Token Burn - AVAX Native Cryptocurrency | Nitro Network    ",
    description:
      "Learn about NCash Token burn, the process of reducing the circulating supply of Nitro Network's AVAX native cryptocurrency. Nitro's burn program aims to improve the value and stability of NCash while enhancing its benefits for users",
    route: "/burn"
  },
  {
    page: "Buy NFM",
    title: "Buy Non-Fungible Miners (NFMs) on Nitro Network Marketplace    ",
    description:
      "Explore Nitro Network’s Non-Fungible Miners (NFMs), the unique synthesis of digital miners and NFTs with distinctive dynamic properties. Accessible and cost-effective, NFMs are the perfect addition to your blockchain portfolio. Buy NFMs on Nitro Network Marketplace today.",
    route: "/buy-nfm"
  },
  {
    page: "Blogs",
    title:
      "Nitro Network Blogs - Stay Up-to-Date with the Latest in IoT and  Blockchain Technology",
    description:
      "Keep up with the latest trends and developments in the blockchain space with Nitro Network’s blogs. From decentralized finance to non-fungible tokens to IoT, our blogs offer valuable insights and updates for blockchain enthusiasts",
    route: "/buy-nfm"
  },
  {
    page: "Terms ",
    title:
      "Nitro Network Terms and Conditions - Your Guide to Using Our Platform",
    description:
      "Before using Nitro Network’s blockchain platform, make sure to read our terms and conditions. From Non-Fungible Miners (NFMs) to IoT solutions, Nitro Network offers a wide range of blockchain products and services for everyone.",
    route: "/terms"
  },
  {
    page: "Privacy ",
    title: " Nitro Network Privacy Policy - Your Data is Safe with Us",
    description:
      "At Nitro Network, we take your privacy seriously. Read our privacy policy to learn how we collect, use, and protect your data while using our blockchain platform.",
    route: "/privacy"
  },
  // Logins
  {
    page: "Login",
    title:
      "Nitro Network Login - Access Your Account and Explore Blockchain Opportunities",
    description:
      "Access your Nitro Network account and explore the limitless opportunities of blockchain technology. From Non-Fungible Miners (NFMs) to IoT solutions, Nitro Network offers a wide range of blockchain products and services for everyone.",
    route: "/login"
  },
  {
    page: "Login By IoT",
    title:
      "Nitro Network IoT - Secure Login for Your IoT Device Management Needs",
    description:
      "Sign in securely to your Nitro Network IoT account to manage your connected devices. Our platform offers easy-to-use tools for efficient IoT device management, from monitoring and tracking to device configuration and updates. Login today and experience the power of Nitro Network IoT for your business needs.",
    route: "/login?"
  },
  {
    page: "Login",
    title:
      "Nitro Network Login - Access Your Account and Explore Blockchain Opportunities",
    description:
      "Access your Nitro Network account and explore the limitless opportunities of blockchain technology. From Non-Fungible Miners (NFMs) to IoT solutions, Nitro Network offers a wide range of blockchain products and services for everyone.",
    route: "/loginTwo"
  },
  {
    page: "Login",
    title:
      "Nitro Network Login - Access Your Account and Explore Blockchain Opportunities",
    description:
      "Access your Nitro Network account and explore the limitless opportunities of blockchain technology. From Non-Fungible Miners (NFMs) to IoT solutions, Nitro Network offers a wide range of blockchain products and services for everyone.",
    route: "/signup"
  },
  {
    page: "Email Verification",
    title:
      "Nitro Network Email Verification - Secure Your Account and Explore Blockchain Opportunities",
    description:
      "Secure your Nitro Network account with email verification and explore the limitless opportunities of blockchain technology. From Non-Fungible Miners (NFMs) to IoT solutions, Nitro Network offers a wide range of blockchain products and services for everyone.",
    route: "/verifyEmail"
  }
]
