import React, { useState, useEffect, useRef } from "react"
import {
  BrowserRouter as Router,
  useHistory,
  useLocation
} from "react-router-dom"
import { useNavigate } from "@reach/router"
import { BaseUrl, nftURL } from "../../utils/config"
import {
  // availabilityOptions,
  categoryOptions,
  soldNFMTiers,
  statusOptions
} from "../../Shared"

// import Menu from "./menu"
import Dropdown from "../components/dropdown"
import axios from "axios"
import { useSelector, useDispatch } from "react-redux"
import { Images } from "../../Shared/images"
import { capitalize } from "../../utils"
import {
  setActiveMenu,
  setConnectedWallet,
  setFilterChanged,
  updateUserDetailsRedux
} from "../../redux/actions"

//Styles
import "../styles/table.css"
import "../../assets/css/marketplace.css"

//Images
import clickit from "./images/clickit.gif"
import swal from "sweetalert"
import { setSoldNFTs } from "../../redux/actions/marketplace"

const MarketPlaceList = props => {
  const user = localStorage.getItem("loginUser")
  const [allProducts, setAllProducts] = useState([])
  const [getData, setGetData] = useState([])
  const [availableBots, setAvailableBots] = useState([])
  const pageItems = 12
  const [viewProduct, setViewProduct] = useState(false)
  const [current, setCurrent] = useState(1)
  const [pagesDiv, setPagesDiv] = useState(0)
  const [tData, setTData] = useState([])
  const history = useHistory()
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const userData = useSelector(state => state.common.userDetails)
  const filterChanged = useSelector(state => state.common.filterChanged)
  const soldNFTs = useSelector(state => state.marketplace.soldNFTs)
  const soldNFTAPIUrl = process.env.REACT_APP_COLLECTION_URL
  const [filteredProduct, setFilteredProduct] = useState(null)

  const totalPages = 1249
  const [clickedIndex, setClickedIndex] = useState(-1)
  const [lastElement, setLastElement] = useState(null)
  const [loading, setLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(0)

  const initialCategory =
    props.location.state?.initialCategory ?? categoryOptions[0].value
  const [category, setCategory] = useState(initialCategory)
  const [soldNFMTier, setSoldNFMTier] = useState(soldNFMTiers[0].value)

  const [status, setStatus] = useState(statusOptions[0].value)

  const [currentTier, setCurrentTier] = useState(
    categoryOptions.find(el => el.value === initialCategory)?.tier ?? ""
  )

  const [filteredProducts, setFilteredProducts] = useState([])

  const [tierQuantity, setTierQuantity] = useState({
    "tier-1": 0,
    "tier-2": 0,
    "tier-3": 0
  })

  const availableBotImages = {
    Iconic: Images.iconicMarketplace,
    Premium: Images.premiumMarketplace,
    Top: Images.topMarketplace
  }

  const fetchProducts = (changed = "") => {
    let apiCategory = category ? category : null

    let url = `${BaseUrl}/product/get?page=${
      changed !== "currentPage" ? 0 : currentPage
    }${apiCategory ? `&categoryId=${apiCategory}` : ""}`

    changed !== "currentPage" && setCurrentPage(0)

    setLoading(true)
    axios
      .get(url)
      .then(response => {
        if (response.status === 200) {
          setFilteredProducts(prevFilteredProducts =>
            changed === "currentPage" && apiCategory
              ? [...new Set([...prevFilteredProducts, ...response.data.data])]
              : response.data.data
          )
          if (apiCategory) {
            setFilteredProduct(response.data.data[0])
          } else {
            setAllProducts(response.data.data)
            setFilteredProduct(null)
          }
        } else if (response.status == 401) {
          dispatch(updateUserDetailsRedux({}))
          dispatch(setConnectedWallet(""))
          localStorage.clear()
          navigate("/login")
        } else if (response.status == 404) {
          swal({
            title: "Error!",
            text: "Page not found. Please try again later.",
            icon: "error"
          })
        } else if (response.status == 500) {
          swal({
            title: "Error!",
            text: "Something went wrong. Please try again later.",
            icon: "error"
          })
        } else if (response.status == 503) {
          swal({
            title: "Error!",
            text: "Something went wrong. Please try again later.",
            icon: "error"
          })
        } else {
          setFilteredProducts([])
          setAllProducts(prevProducts =>
            changed === "currentPage"
              ? [...new Set([...prevProducts, ...response.data.data])]
              : response.data.data
          )
          setAllProducts([])
          setFilteredProduct(null)

          swal({
            title: "Error!",
            text: "Something went wrong. Please try again later.",
            icon: "error"
          })
        }
      })
      .catch(error => {
        if (error?.response?.status == 401) {
          dispatch(updateUserDetailsRedux({}))
          dispatch(setConnectedWallet(""))
          localStorage.clear()
          navigate("/login")
        } else
          swal({
            title: "Error!",
            text: "Something went wrong. Please try again later.",
            icon: "error"
          })
      })
    setLoading(false)
  }

  const onviewproduct = id => {
    setViewProduct(true)
    navigate("/productdetails", {
      state: { selectedId: id, type: "marketplace" }
    })
  }

  const toLogin = () => {
    history.push("/login")
  }

  const onSearch = e => {
    const searchedData = []
    e != ""
      ? allProducts.map(item => {
          item.title.includes(e) && searchedData.push(item)
        })
      : setTData(getData.slice(0, pageItems))
    e != "" && setTData(searchedData)

    // searchedData.length<=1 && setTData(getData.slice(0, pageItems));
  }

  const goPagination = val => {
    setCurrent(val)
    const passval = (val - 1) * pageItems
    setTData(getData.slice(passval, passval + pageItems))
  }

  //Change Handlers
  const categoryHandler = e => {
    dispatch(setFilterChanged(true))
    setCategory(Number(e.target.value))
  }

  // Observer for Infinite Scroll
  const observer = useRef(
    new IntersectionObserver(entries => {
      const domElement = entries[0]
      if (domElement.isIntersecting) {
        setCurrentPage(prevPage => prevPage + 1)
      }
    })
  )

  const fetchSoldNFMs = () => {
    let tierIds = {
      v1: [0, 1, 2],
      v2: [0, 1],
      v3: [0, 1, 2, 3, 4]
    }

    let promiseArr = []

    Object.entries(tierIds).forEach(async ([tier, indexArr]) => {
      for (let i = 0; i < indexArr.length; i++) {
        let item = axios.get(
          `${soldNFTAPIUrl}/${tier}/nft/metadata/${indexArr[i]}`,
          { timeout: 30000 }
        )
        promiseArr.push(item)
      }
    })

    axios
      .all(promiseArr)
      .then(
        axios.spread((...responses) => {
          dispatch(setSoldNFTs(responses.map(response => response.data)))
        })
      )
      .catch(err => {
        if (err.response.status === 401) {
          dispatch(updateUserDetailsRedux({}))
          dispatch(setConnectedWallet(""))
          localStorage.clear()
          navigate("/login")
        }
        console.log(err, "error")
      })
  }

  const fetchAvailableBotsCount = async function () {
    try {
      const response = await axios.get(`${BaseUrl}/getNfmTraitsCount`)
      setAvailableBots(response?.data?.data ?? [])
    } catch (error) {
      if (error?.response?.status === 401) {
        dispatch(updateUserDetailsRedux({}))
        dispatch(setConnectedWallet(""))
        window.localStorage.clear()
        navigate("/login")
      }
    }
  }

  // Use Effects
  useEffect(() => {
    const currentElement = lastElement
    const currentObserver = observer.current

    if (currentElement) {
      //null check
      currentObserver.observe(currentElement)
    }
    return () => {
      if (currentElement) {
        currentObserver.unobserve(currentElement)
      }
    }
  }, [lastElement])

  useEffect(() => {
    fetchSoldNFMs()
    fetchAvailableBotsCount()
  }, [])

  useEffect(() => {
    if (currentPage <= totalPages) {
      fetchProducts("currentPage") // current page changed
    }
  }, [currentPage])

  const placeOrder = () => {
    let loginToken = userData?.loginUserToken
    let data = {
      tier: tierQuantity
    }
    var config = {
      method: "post",
      url: `${BaseUrl}/preorder`,
      headers: {
        "Content-Type": "application/json"
      },
      data: data
    }
    axios(config)
      .then(response => {
        if (response.status == 200) {
          if (loginToken) {
            axios
              .get(`${BaseUrl}/addorder/${response.data.token}`, {
                headers: {
                  Authorization: `Bearer ${loginToken}`
                }
              })
              .then(addOrderResponse => {
                if (addOrderResponse.status == 200) {
                  navigate("/myaccount/pendingpayments")
                  dispatch(setActiveMenu("NFM"))
                  setTierQuantity({
                    "tier-1": 0,
                    "tier-2": 0,
                    "tier-3": 0
                  })
                }
              })
              .catch(error => {
                if (error?.response?.status === 401) {
                  dispatch(updateUserDetailsRedux({}))
                  dispatch(setConnectedWallet(""))
                  localStorage.clear()
                  localStorage.setItem("buyNitroToken", response.data.token)
                  navigate("/login")
                }
              })
          } else {
            localStorage.clear()
            localStorage.setItem("buyNitroToken", response.data.token)
            navigate("/login")
          }
        } else if (response.status == 401) {
          dispatch(updateUserDetailsRedux({}))
          localStorage.clear()
          navigate("/login")
        } else if (response.status == 404) {
          swal({
            title: "Error!",
            text: "Page not found. Please try again later.",
            icon: "error"
          })
        } else if (response.status == 500) {
          swal({
            title: "Error!",
            text: "Something went wrong. Please try again later.",
            icon: "error"
          })
        } else if (response.status == 503) {
          swal({
            title: "Error!",
            text: "Something went wrong. Please try again later.",
            icon: "error"
          })
        } else {
          console.log("response")

          swal({
            title: "Failed",
            text: "Something went wrong.",
            icon: "error"
          })
        }
      })
      .catch(error => {
        swal({
          title: "Failed",
          text: "Something went wrong.",
          icon: "error"
        })
      })
  }

  //Slider Options
  const sliderBanner = {
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1
  }

  return (
    <div className="">
      {/* Maketplace Banner */}

      {/* <div className="marketplace-banner mt-90pxx">
        <Slider {...sliderBanner}>
          <img src={Images.banner2Marketplace} alt="Banner Markertplace 2" />
          <img src={Images.bannerMarketplace} alt="Banner Markertplace" />
        </Slider>
      </div> */}

      {/* Get YOur Ncash Here */}
      <div className="payment-section">
        <div className="col-12 marketplace-text-box">
          <h1>Marketplace</h1>

          <p>
            Nitro Network’s Non-Fungible Miners are a synthesis of digital
            miners and NFTs, making them some of the most original, innovative
            developments in the blockchain space. They can be distinguished from
            traditional digital miners and NFTs due to their distinctive,
            dynamic properties. In addition, NFMs are far more accessible and
            cost-effective than the mining protocols of legacy networks.
          </p>
          <br />
        </div>

        {/* <div className="mint-banner"> */}
        <a
          href={nftURL}
          className="mint-banner"
          target="_blank"
          rel="noopener noreferrer">
          <img src={Images.mintBanner} alt="Mint Banner" />
        </a>
        {/* </div> */}

        {/* AVAILABLE  NFM BOTS */}
        <div className="available-nfm-bot-section">
          <div className="max-width">
            <h2 className="uppercase">AVAILABLE nfm bots</h2>
            <div className="available-nfm-bots">
              {/* First Bot */}
              {availableBots.map((bot, i) => (
                <div className="available-bot" key={i}>
                  <div className="available-bot__image border-sky">
                    <img
                      src={availableBotImages[bot.type]}
                      alt={`${bot.type} NFM`}
                    />
                  </div>
                  <h2>{bot.total_count}</h2>
                  <p className="available-bot__category white-btn-bg">
                    {(bot?.type ?? "").toUpperCase()}
                  </p>

                  {/* lEFT NFMS  */}
                  {/* <div className="left-nfm-stats">
                    <div className="dfac dfjb">
                      <p className="fw-400">Rare</p>
                      <p className="fw-400">{bot.rare}</p>
                    </div>
                    <div className="dfac dfjb">
                      <p className="fw-400">Standard</p>
                      <p className="fw-400">{bot.standard}</p>
                    </div>
                    <div className="dfac dfjb">
                      <p className="fw-400">Ultra Rare</p>
                      <p className="fw-400">{bot.ultra_rare}</p>
                    </div>
                  </div> */}
                </div>
              ))}
            </div>
          </div>
        </div>

        <div>
          <div className="row collection-boxes center-align-marketplacelist-buy justify-content-center">
            {filteredProduct ? (
              <div
                className="container product-detail-marketplace-space"
                style={{ marginBottom: 0, width: "80%" }}>
                <section id="nitro-nft-miner" style={{ margin: "auto" }}>
                  <div
                    className="nitro-hero-section pending-one"
                    id="product-details">
                    <div className="nitro-img">
                      <img
                        src={filteredProduct?.product_image}
                        alt={filteredProduct?.mystery}
                      />
                    </div>
                    <div className="nitro-text small-width" id="text-detail">
                      <h1 className="pink-text">{filteredProduct?.mystery}</h1>
                      <div className="line"></div>

                      <p>{filteredProduct?.product_desc}</p>

                      <div className=" justify-content-between flex-wrap max-width-40em">
                        <p className="purple-text">
                          Category :{" "}
                          <span className="text-white">
                            {filteredProduct?.category_name}
                          </span>
                        </p>
                        <p className="purple-text">
                          Group Name :{" "}
                          <span className="text-white">
                            {capitalize(filteredProduct?.group_name)}
                          </span>
                        </p>
                      </div>
                      <div className="justify-content-between align-items-center flex-wrap max-width-40em">
                        <p className="purple-text">
                          Purchased price :{" "}
                          <span className="text-white text-bold">
                            ${filteredProduct?.price}
                          </span>
                        </p>

                        <div className="d-flex align-items-center justify-content-around">
                          <div className="counter">
                            <p
                              className="minus"
                              onClick={
                                tierQuantity[currentTier]
                                  ? () => {
                                      setTierQuantity(prevState => ({
                                        ...prevState,
                                        [currentTier]:
                                          prevState[currentTier] - 1
                                      }))
                                    }
                                  : () => {}
                              }>
                              <i className="fa fa-minus"></i>
                            </p>
                            <input
                              type="number"
                              name="update"
                              id="number-update"
                              min="11"
                              value={tierQuantity[currentTier]}
                              onChange={() => {}}
                            />
                            <p
                              className="plus"
                              onClick={() => {
                                setTierQuantity(prevState => ({
                                  ...prevState,
                                  [currentTier]: prevState[currentTier] + 1
                                }))
                              }}>
                              <i className="fa fa-plus"></i>
                            </p>
                          </div>
                          <p className="purple-text">
                            <button
                              onClick={
                                tierQuantity[currentTier]
                                  ? placeOrder
                                  : () => {}
                              }
                              id="pay-now-btn"
                              style={
                                !tierQuantity[currentTier]
                                  ? {
                                      filter: "brightness(0.65)"
                                    }
                                  : null
                              }>
                              Buy NFM
                            </button>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            ) : allProducts.length ? (
              allProducts.map((nft, index) => (
                <div
                  key={index}
                  id="marketplace"
                  className="d-item col-lg-3 col-md-6 col-sm-6 col-xs-12">
                  <div
                    className={`max-nft-item-220 nft__item${
                      clickedIndex === index ? " mobile__view" : ""
                    }`}>
                    <div className="nft__item_wrap  max-wh-200">
                      <a>
                        <img
                          src={nft?.product_image}
                          className="lazy nft__item_preview"
                          alt={nft?.mystery}
                        />
                      </a>
                      <img
                        src={clickit}
                        id="iconclick"
                        onClick={() => {
                          setClickedIndex(index)
                        }}
                        alt="Click Animation"
                      />
                    </div>

                    <div
                      className="nft__item_info row-justify-custom"
                      style={{ minHeight: 34 }}>
                      {/* <div>
                      <p className="purple-text">{nft?.mystery}</p>
                    </div> */}

                      {/* Hide Plus Minus Quantity */}
                      {/* {nft?.group_name ? (
                        <div
                          className="qty-container text-center d-flex justify-content-center align-items-center"
                          style={{ margin: "auto" }}>
                          <span className="qty">Qty:&nbsp;&nbsp;&nbsp;</span>

                          <div className="counter">
                            <p
                              className="minus text-white"
                              style={{ padding: "0 3px" }}
                              onClick={
                                tierQuantity[nft?.group_name]
                                  ? () => {
                                      setTierQuantity(prevState => ({
                                        ...prevState,
                                        [nft?.group_name]:
                                          prevState[nft?.group_name] - 1
                                      }))
                                    }
                                  : () => {}
                              }>
                              <i className="fa fa-minus"></i>
                            </p>
                            <input
                              type="number"
                              name="update"
                              id="number-update"
                              min="11"
                              value={tierQuantity[nft?.group_name]}
                              onChange={() => {}}
                            />
                            <p
                              className="plus text-white"
                              style={{ padding: "0 3px" }}
                              onClick={() => {
                                setTierQuantity(prevState => ({
                                  ...prevState,
                                  [nft?.group_name]:
                                    prevState[nft?.group_name] + 1
                                }))
                              }}>
                              <i className="fa fa-plus"></i>
                            </p>
                          </div>
                        </div>
                      ) : null} */}
                    </div>
                  </div>
                  <div style={{ marginLeft: "66px" }}>
                    <h3 className="mb-1">
                      {nft?.category_name?.toUpperCase()}(
                      {capitalize(nft?.group_name).split("-").join(" ")})
                    </h3>
                    <p className="mb-1" style={{ fontSize: "14px" }}>
                      {nft?.product_desc}
                    </p>
                    <div className="d-flex">
                      <h3 className="price_div">Price:</h3>&nbsp;
                      {/* TEMP CHANGES IN PRICE (STATIC) */}
                      {/* <p className="text-line-through">
                        ${nft?.price * 2}
                        <span className="line"></span>
                      </p> */}
                      &nbsp;
                      {/* TEMP:   price * 2 */}
                      <h3 className="price_div">${nft?.price * 2}</h3>
                    </div>
                  </div>
                </div>
              ))
            ) : null}

            <div className="text-center" id="marketplace-summary">
              <h2>
                Sub Total: $
                {allProducts.reduce(
                  (pval, cval) =>
                    pval + cval?.price * tierQuantity[cval?.group_name],
                  0
                )}
              </h2>
              <button
                disabled
                // TEMP DISABLED

                // onClick={
                //   allProducts.reduce(
                //     (pval, cval) =>
                //       pval + cval?.price * tierQuantity[cval?.group_name],
                //     0
                //   )
                //     ? placeOrder
                //     : () => {}
                // }
                // style={
                //   allProducts.reduce(
                //     (pval, cval) =>
                //       pval + cval?.price * tierQuantity[cval?.group_name],
                //     0
                //   )
                //     ? null
                //     : {
                //         filter: "brightness(0.85)"
                //       }
                // }
                style={{
                  filter: "brightness(0.85)",
                  cursor: "not-allowed"
                }}
                className="btn-active btn-brand mx-w text-white fw-600">
                Buy Now
              </button>
            </div>
            {/* <div className="text-center">
            Please check your order in account section to{" "}
            <a href="/myaccount/pendingpayments">pay</a>
          </div> */}
          </div>

          {/* Sold NFM's */}
          {soldNFTs.length ? (
            <div className="container">
              <h1 className="sold_section_header text-center">Sold NFMs</h1>
              <div className="text-right ">
                <Dropdown
                  name="soldNFM-tier-filter"
                  value={soldNFMTier}
                  id="soldNFM-tier"
                  options={soldNFMTiers}
                  handleChange={e => {
                    setSoldNFMTier(e.target.value)
                  }}
                />
              </div>
              <div className="row wow fade-in collection-boxes sold-nfts-marketplace">
                {(soldNFMTier
                  ? soldNFTs.filter(
                      soldNFTItem => soldNFTItem?.tier === soldNFMTier
                    )
                  : soldNFTs
                ).map((nft, index) => (
                  <div
                    key={index}
                    // onClick={() => {
                    //   navigate("/soldnfmdetail", {
                    //     state: {
                    //       soldNFM: nft,
                    //     },
                    //   });
                    // }}
                    className="d-item col-lg-3 col-md-6 col-sm-6 col-xs-12">
                    <div
                      className={`nft__item${
                        clickedIndex === index ? " mobile__view" : ""
                      }`}>
                      <div className="nft__item_wrap">
                        <a>
                          <img
                            src={nft?.image}
                            className="lazy nft__item_preview"
                            alt={nft?.name}
                          />
                        </a>
                        <img
                          src={clickit}
                          id="iconclick"
                          onClick={() => {
                            setClickedIndex(index)
                          }}
                          alt="Click Animation"
                        />
                      </div>
                      <div className="nft__item_info row-justify-custom">
                        <div>
                          <p className="purple-text">{nft?.name}</p>
                          {/* <p>P #152412</p> */}
                        </div>
                        {nft?.price ? (
                          <div className="text-right">
                            {/* <p>450 $</p> */}
                            <p className="pink-text">Price: ${nft?.price}</p>
                          </div>
                        ) : null}
                      </div>
                    </div>
                    {/* <NftCard nftObject={nft} /> */}
                  </div>
                ))}
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  )
}
export default MarketPlaceList
