import { REHYDRATE } from "redux-persist"
import { ACTION_TYPES } from "../actions"

const initialState = {
  tokenData: [],
  stakeTokenData: [],
  clickedNFM: null
}

const CollectionReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.SET_TOKEN_DATA:
      return {
        ...state,
        tokenData: action.payload || []
      }
    case ACTION_TYPES.SET_STAKE_TOKEN_DATA:
      return {
        ...state,
        stakeTokenData: action.payload || []
      }
    case ACTION_TYPES.SET_CLICKED_NFM:
      return {
        ...state,
        clickedNFM: action.payload || {}
      }
    case REHYDRATE:
      return {
        ...initialState,
        ...((action.payload || {}).collection || {})
      }
    default:
      return state
  }
}

export default CollectionReducer
