import { REHYDRATE } from "redux-persist"
import { mapStyleOptions } from "../../Shared"
import { ACTION_TYPES } from "../actions"

const initialState = {
  userDetails: {},
  pendingPayment: null,
  collectionItems: [],
  filterChanged: false,
  connectedWallet: "",
  loading: false,
  style: mapStyleOptions[0].value,
  kycForm: null,
  iotTokens: [],
  activeMenu: "NFM" // NFM || IOT
}

const CommonReducer = (state = { ...initialState }, action) => {
  switch (action.type) {
    case ACTION_TYPES.UPDATE_USER_DETAILS:
      return {
        ...state,
        userDetails: action.payload || {}
      }

    case ACTION_TYPES.SET_PENDING_PAYMENT:
      return {
        ...state,
        pendingPayment: action.payload || null
      }

    case ACTION_TYPES.SET_COLLECTION_ITEMS:
      return {
        ...state,
        collectionItems: action.payload || []
      }

    case ACTION_TYPES.UPDATE_ORDER_COUNT:
      return {
        ...state,
        pendingPayment: { ...state.pendingPayment, data: action.payload || [] }
      }

    case ACTION_TYPES.SET_FILTER_CHANGED:
      return {
        ...state,
        filterChanged: action.payload || false
      }

    case ACTION_TYPES.SET_CONNECTED_WALLET:
      return {
        ...state,
        connectedWallet: action.payload || ""
      }

    case ACTION_TYPES.SET_STYLE_TYPE:
      return {
        ...state,
        style: action.payload || ""
      }

    case ACTION_TYPES.SAVE_KYC_FORM:
      return {
        ...state,
        kycForm: action.payload
      }
    case ACTION_TYPES.START_LOADER:
      return {
        ...state,
        loading: true
      }
    case ACTION_TYPES.STOP_LOADER:
      return {
        ...state,
        loading: false
      }

    case ACTION_TYPES.SET_IOT_TOKENS:
      return {
        ...state,
        iotTokens: action.payload
      }

    case ACTION_TYPES.SET_ACTIVE_MENU:
      return {
        ...state,
        activeMenu: action.payload
      }
    case REHYDRATE:
      return {
        ...initialState,
        ...((action.payload || {}).common || {}),
        loading: false
      }

    default:
      return {
        ...state
      }
  }
}

export default CommonReducer
