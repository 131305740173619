import React from "react"
import { Helmet } from "react-helmet"

function DocumentHead({ pageObj = null }) {
  if (!pageObj) return <></>
  return (
    <Helmet>
      <title>{pageObj?.title ?? ""} - Nitro Blog's</title>
      <meta name="description" content={pageObj?.description ?? ""} />
      <link rel="canonical" href={window.location.href} />
      <meta name="robots" content="max-image-preview:large" />

      {/* OG */}
      <meta property="og:title" content={pageObj?.title ?? ""} />
      <meta property="og:type" content="Nitro.network" />
      <meta property="og:url" content={window.location.href} />
      <meta property="og:image" content={pageObj?.image ?? ""} />
      <meta property="og:description" content={pageObj?.description ?? ""} />
      <meta property="og:site_name" content="Nitro Network" />

      {/* Twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@nitro.network" />
      <meta name="twitter:title" content={pageObj?.title ?? ""} />
      <meta name="twitter:description" content={pageObj?.description ?? ""} />
      <meta name="twitter:image" content={pageObj?.image ?? ""} />
      <meta name="twitter:image:src" content={pageObj?.image ?? ""} />

      <meta name="author" content="Nitro Network" />
      <meta name="keywords" content={pageObj?.keywords ?? ""} />
    </Helmet>
  )
}

export default DocumentHead
