import React, { useState, useRef, useEffect } from "react"
import "./Confirmation.css"
import "../css/nest.css"
import "../css/neststyle.css"
import Confirmationmap from "./Confirmationmap"
import Left from "../../images/left-arrow.png"
import Right from "../../images/right-arrow.png"
import { useLocation, useNavigate, Link } from "@reach/router"
import { connect, useSelector, useDispatch } from "react-redux"
import {
  setActiveMenu,
  setConnectedWallet,
  updateUserDetailsRedux
} from "../../../../redux/actions"
import { Checkbox } from "antd"
import axios from "axios"
import { BaseUrl } from "../../../../utils/config"
import swal from "sweetalert"
import { useLogout } from "../../../../hooks"
const MAPBOX_TOKEN =
  "pk.eyJ1IjoiYmFsamVldHJlYWN0IiwiYSI6ImNreW54ZmI2ZjNieGUybnFwNnd1aXRudTEifQ.a0Yo3o" +
  "2Cy1mID1653pidRA"

export const ConfirmMiner = ({ updateUserDetails = () => {} }) => {
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const logoutHandler = useLogout()

  const [address, setAddress] = useState()
  const [minertype, setMinertype] = useState()
  const [lat, setlatitude] = useState("")
  const [lon, setlongitude] = useState("")
  const [error, setError] = useState(null)
  const [checkedTerms, setCheckedTerms] = useState(false)
  const userDetails = useSelector(state => state.common.userDetails) || null
  const [viewpoint, setViewpoint] = useState({
    Longitude: -95.712891,
    latitude: 37.09024,
    zoom: 6
  })

  const [addressData, setAddressData] = useState([])
  const [placesToken, setPlacesToken] = useState("")

  //   const loginUserToken = localStorage.getItem("loginUserToken");
  const loginUserToken = userDetails?.loginUserToken ?? undefined

  useEffect(() => {
    document.title = "Confirm Nesting Details - Nitro Nesting"
    if (location.state != undefined) {
      setAddress(location.state.address)
      setMinertype(location.state.minertype)
      fetch(
        "https://api.mapbox.com/geocoding/v5/mapbox.places/" +
          location.state.lng +
          "," +
          location.state.lat +
          ".json?access_token=" +
          MAPBOX_TOKEN
      )
        .then(response => {
          if (response.status >= 400 && response.status < 600) {
            throw new Error("Bad response from server")
          }
          return response.json()
        })
        .then(returnedResponse => {
          setAddressData(returnedResponse?.features)
          setlongitude(returnedResponse.query[0])
          setlatitude(returnedResponse.query[1])
          setViewpoint({
            Longitude: returnedResponse.query[0],
            latitude: returnedResponse.query[1],
            zoom: 15
          })
        })
        .catch(error => {})
    } else {
      navigate("/nest")
    }
    return () => {
      document.title = "Nitro Network"
    }
  }, [location.state])

  const userData = {
    email: userDetails?.loginUserMail ?? "",
    user_id: userDetails?.loginUserId ?? ""
  }

  const proceedsubmit = address => {
    console.log(addressData, "addressData")
    console.log(address, "address")
    console.log(
      addressData?.find(ele => ele?.id.includes("place"))?.place_name ??
        address.split(",")?.[0] ??
        ""
    )
    const dataObj = {
      place_name:
        addressData?.find(ele => ele?.id.includes("place"))?.place_name ??
        address.split(",")?.[0] ??
        "",
      miner_name: minertype,
      miner_type: minertype,
      address: address,
      address1: address,
      city:
        addressData?.find(ele => ele?.id.includes("place"))?.text ??
        addressData?.find(ele => ele?.id.includes("locality"))?.text ??
        addressData?.find(ele => ele?.id.includes("region"))?.text ??
        "",
      state:
        addressData?.find(ele => ele?.id.includes("region"))?.text ??
        addressData?.find(ele => ele?.id.includes("place"))?.text ??
        "",
      zip: addressData?.find(ele => ele?.id.includes("postcode"))?.text ?? "",
      country:
        addressData?.find(ele => ele?.id.includes("country"))?.text ?? "",
      status: "Pending",
      lat: lat,
      lang: lon
    }

    if (checkedTerms) {
      axios
        .post(`${BaseUrl}/addaddress`, dataObj, {
          headers: {
            Authorization: `Bearer ${loginUserToken}`
          }
        })
        .then(response => {
          if (response) {
            localStorage.setItem("placesToken", response.data.data[0].token)
            setPlacesToken(response.data.data[0].token)
            let userToken = userDetails?.loginUserToken ?? undefined
            if (userToken) {
              axios
                .post(
                  `${BaseUrl}/addplace/${response.data.data[0].token}`,
                  userData,
                  {
                    headers: {
                      Authorization: `Bearer ${loginUserToken}`
                    }
                  }
                )
                .then(response => {
                  if (!response.data.status) {
                    swal({
                      text: response.data.message,
                      icon: "error"
                    })
                  } else {
                    swal({
                      text: "Nesting address added successfully",
                      icon: "success"
                    })
                    navigate("/myaccount/mynest")
                    dispatch(setActiveMenu("NFM"))
                  }
                })
                .catch(err => {
                  if (err.response.status === 401) {
                    localStorage.setItem(
                      "placesToken",
                      response.data.data[0].token
                    )
                    logoutHandler()
                  }
                })
            } else {
              localStorage.setItem("placesToken", response.data.data[0].token)
              logoutHandler()
            }
          }
        })
        .catch(error => {
          if (error?.response?.status === 401) {
            logoutHandler()
          } else {
            swal({
              title: "",
              text: error?.message,
              icon: "warning"
            })
          }
        })
    } else {
      swal({
        text: "Please accept terms and conditions",
        icon: "warning"
      })
      //   && setError("please check terms & conditions");
    }
    setTimeout(() => {
      setError("")
    }, 2000)
    return
  }

  const backsubmit = address => {
    navigate("/selectminer", {
      state: {
        id: location.state.id,
        minertype: minertype,
        lat: lat,
        lng: lon,
        address
      },
      replace: true
    })
  }
  const onChangeCheck = e => {
    setCheckedTerms(e.target.checked)
  }
  const toLinks = item => {
    window.open(item)
  }
  return (
    <div className="no-bottom no-top dark-scheme" id="content">
      <div id="top"></div>
      <div className="">
        <div className="row">
          <div className="col-md-12">
            <div className="confirm-details-page">
              <div className="confirm-details-sidebar">
                <h4 className="center-in-mobile center">
                  Your Nesting Location
                </h4>
                {/* {error ? (
                  <div
                    className="error"
                    style={{
                      display: "inline-block",
                      margin: "0 0 1rem 0",
                      float: "right",
                    }}
                  >
                    <div
                      className="error1"
                      id="slide-error"
                      style={{
                        color: "#fff",
                        background: "red",
                        padding: "10px 15px",
                        border: "1px solid red",
                      }}
                    >
                      {error}
                    </div>
                  </div>
                ) : null} */}

                <div className="box-det">
                  <h6>Nesting Address :</h6>
                  <div className="gradient-box">
                    <div className="field">
                      <p
                        className="border-field max-width-full"
                        style={{ wordWrap: "break-word" }}>
                        {address}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="box-det margin-0">
                  <h6>Miner Type :</h6>
                  <div className="gradient-box">
                    <div className="field">
                      <p className="border-field">{minertype}</p>
                    </div>
                  </div>
                </div>
                <div className="checkbox mt-3">
                  <Checkbox
                    onChange={onChangeCheck}
                    checked={checkedTerms}></Checkbox>

                  <label for="terms">
                    {" "}
                    &nbsp;&nbsp; <span className="colorWhite">I agree to </span>
                    <a
                      className="purple-color-text"
                      onClick={() =>
                        toLinks(
                          "/terms"
                        )
                      }>
                      {" "}
                      Terms & Conditions
                    </a>
                  </label>
                </div>
                <div
                  className="two-nest-btn"
                  style={{
                    backgroundSize: "cover"
                  }}>
                  <a
                    onClick={() => backsubmit(address)}
                    className="submit-btn first-submit-btn">
                    <i>
                      <img src={Left} />
                    </i>
                    <p className="gradeint-text">Back</p>
                  </a>
                  <a
                    onClick={() => proceedsubmit(address)}
                    className="submit-btn">
                    <p className="gradeint-text">Submit</p>
                    <i>
                      <img src={Right} />
                    </i>
                  </a>
                </div>
              </div>

              <div className="earth">
                <Confirmationmap {...viewpoint} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapDispatchToProps = dispatch => {
  return {
    updateUserDetails: payload => dispatch(updateUserDetailsRedux(payload))
  }
}

export default connect(null, mapDispatchToProps)(ConfirmMiner)
