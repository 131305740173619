import React from "react"

const Popup = props => {
  const { setOpenPopup, heading = "" } = props
  return (
    <div className="popup backlayer">
      <div className="popup__box">
        <span className="popup__close-btn" onClick={() => setOpenPopup(false)}>
          X
        </span>
        <div className="popup__head">
          {" "}
          <h6>{heading} </h6>
        </div>
        {props.children}
      </div>
    </div>
  )
}

export default Popup
