import React, { useState, useEffect, useRef } from "react"
import { Form, Input, Select, DatePicker } from "antd"
import { countries, countryCodes } from "../../Shared"
import { Images } from "../../Shared/images"
import "../../assets/css/kyc.css"
import { useNavigate, useLocation } from "@reach/router"
import axios from "axios"
import { BaseUrl } from "../../utils/config"
import { useSelector, useDispatch } from "react-redux"
import swal from "sweetalert"
import {
  saveKYCForm,
  setConnectedWallet,
  updateUserDetailsRedux
} from "../../redux/actions"
import moment from "moment"

const MAPBOX_TOKEN =
  "pk.eyJ1IjoiYmFsamVldHJlYWN0IiwiYSI6ImNreW54ZmI2ZjNieGUybnFwNnd1aXRudTEifQ.a0Yo3o" +
  "2Cy1mID1653pidRA"

const KYC = () => {
  const { Option } = Select
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()
  const userDetails = useSelector(state => state.common.userDetails) || {}
  const storedFormValues = useSelector(state => state.common.kycForm) || {}
  const [form] = Form.useForm()
  const [kycDetails, setKYCDetails] = useState(null)
  const [state, updateState] = useState(false)
  const isFirstUpdate = useRef(true)
  const [disabledForm, setDisabledForm] = useState(false)
  const [userCountry, setUserCountry] = useState("")
  const prefixSelector = (
    <Form.Item
      name="prefix"
      noStyle
      rules={[
        {
          required: true,
          message: "Country Code is required"
        }
      ]}>
      <Select
        showSearch
        style={{
          minWidth: 100
        }}
        onChange={() => updateState(!state)}
        value={storedFormValues?.["prefix"]}
        disabled={disabledForm}>
        {countryCodes.map((option, index) => (
          <Option key={index} value={option.dial_code}>
            {option.dial_code}{" "}
            <img
              width="30"
              height="30"
              src={`https://cdn.ipregistry.co/flags/emojitwo/${option.code.toLowerCase()}.svg`}
              alt={option.flag}
            />
          </Option>
        ))}
      </Select>
    </Form.Item>
  )

  // Event Handlers
  const submitForm = async values => {
    let placeId =
      location.state && location.state.place && location.state.place?.place_id

    try {
      //data modifications
      values.mobileNumber = values.prefix + values.mobileNumber
      delete values.prefix
      values.dob = values.dob.toISOString()
      let response = await axios.put(
        `${BaseUrl}/uploadKycDetails/${placeId}`,
        values,
        {
          headers: {
            Authorization: `Bearer ${userDetails.loginUserToken}`
          }
        }
      )

      if (response.status === 200 && response.data.status) {
        navigate("/kyc-step-2", {
          state: {
            place: kycDetails
              ? { ...kycDetails, token: response.data.token }
              : { ...location.state.place, token: response.data.token }
          }
        })
      } else {
        throw new Error(response.data?.message)
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        dispatch(updateUserDetailsRedux({}))
        dispatch(setConnectedWallet(""))
        localStorage.clear()
        navigate("/login")
      }
    }
  }

  function disableDate(current) {
    let dateStart = moment().subtract(300, "years")
    let dateEnd = moment().subtract(16, "years")
    return !(dateStart.isSameOrBefore(current) && dateEnd.isAfter(current))
  }

  async function fetchKYCDetails(placeId) {
    if (!placeId) return
    else {
      try {
        let response = await axios.get(
          `${BaseUrl}/admin/getKycDetails/${placeId}`
        )
        if (response.status === 200) {
          setKYCDetails(response.data.data)
          let formFields = form.getFieldsValue()
          let value = {}
          for (let key in formFields) {
            if (key === "dob") {
              value[key] =
                moment(response.data.data[key]) ||
                moment().subtract(18, "years")
            } else {
              value[key] = response.data.data[key]
            }
          }
          let dialCode =
            countryCodes.find(countryCode =>
              response.data.data.mobileNumber.startsWith(countryCode.dial_code)
            )?.dial_code ?? "+1"
          value.prefix = dialCode
          value.mobileNumber = value.mobileNumber.split(dialCode).join("")
          console.log("setting backend stored values")
          form.setFieldsValue(value)
        }
      } catch (error) {
        if (error?.response?.status === 401) {
          dispatch(updateUserDetailsRedux({}))
          dispatch(setConnectedWallet(""))
          localStorage.clear()
          navigate("/login")
        }
        console.log(error, "error")
      }
    }
  }

  //useEffects

  useEffect(() => {
    let fetchDetailStatuses = ["In Progress", "Approved"]
    if (isFirstUpdate.current) {
      isFirstUpdate.current = false
      if (!location.state.place) {
        console.log("setting stored form values")
        form.setFieldsValue(storedFormValues)
      } else if (
        fetchDetailStatuses.includes(location.state.place.verification_status)
      ) {
        fetchKYCDetails(location.state.place.place_id)
      }

      if (
        location.state &&
        location.state.place &&
        location.state.place.verification_status === "Approved"
      ) {
        setDisabledForm(true)
      }
      return
    } else {
      dispatch(saveKYCForm(form.getFieldsValue()))
    }
  }, [state])

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          let url =
            "https://api.mapbox.com/geocoding/v5/mapbox.places/" +
            position.coords.longitude +
            "," +
            position.coords.latitude +
            ".json?types=country&access_token=" +
            MAPBOX_TOKEN
          axios
            .get(url)
            .then(response => {
              if (response.status == 401) {
                dispatch(updateUserDetailsRedux({}))
                dispatch(setConnectedWallet(""))
                localStorage.clear()
                navigate("/login")
              } else if (response.status === 200) {
                setUserCountry(
                  response.data.features.length &&
                    response.data.features[0]?.place_name
                    ? response.data.features[0]?.place_name
                    : ""
                )
                if (
                  !location.state.place ||
                  !location.state.place.verification_status ||
                  location.state.place_name.verification_status === "pending"
                ) {
                  console.log(
                    countryCodes.find(
                      code =>
                        code.name.toLowerCase() ===
                        (response.data.features.length &&
                        response.data.features[0]?.place_name
                          ? response.data.features[0]?.place_name
                          : "United States"
                        ).toLowerCase()
                    )?.dial_code ?? "+1",
                    "dial code"
                  )

                  form.setFieldsValue({
                    countryOfResidence:
                      response.data.features.length &&
                      response.data.features[0]?.place_name
                        ? response.data.features[0]?.place_name
                        : "United States",
                    prefix:
                      countryCodes.find(
                        code =>
                          code.name.toLowerCase() ===
                          (response.data.features.length &&
                          response.data.features[0]?.place_name
                            ? response.data.features[0]?.place_name
                            : "United States"
                          ).toLowerCase()
                      )?.dial_code ?? "+1"
                  })
                }
              }
            })
            .catch(error => {
              console.log(error.message)
            })
        },
        error => {
          console.log(error.message, "error")
        },
        {
          enableHighAccuracy: true
        }
      )
    }
  }, [])

  console.log(
    countries.find(
      country => country.name.toLowerCase() === userCountry.toLowerCase()
    ),
    "userCountry"
  )
  return (
    <div className="kyc-page pt-120pxx">
      <div className="container">
        <div className="row ">
          {/* <div className="spacer-double"></div> */}
          <h2 style={{ textAlign: "left" }}>KYC Registration Form</h2>
          {/* <div className="spacer-single"></div> */}
          <div>
            <div id="kyc-hr"></div>
            <div className="step-indicator-container">
              <div className="step-indicator active"></div>
              <div className="step-indicator"></div>
            </div>
          </div>
          <div className="step-name-container">
            <div className="step-name active">Registration</div>
            <div className="step-name">Upload documents</div>
          </div>

          {/* <div className="spacer-single"></div> */}
          <div className="col-12 form-container" id="kyc-form">
            <Form onFinish={submitForm} form={form}>
              <div className=" form-sections-container first-row">
                <div className="col form-section one">
                  <h3>Personal Information</h3>
                  <div className="spacer-half"></div>

                  <h5>
                    Your Full Name<sup style={{ color: "red" }}>*</sup>
                  </h5>

                  {/* First Name Field */}
                  <div className="form-field-container">
                    <Form.Item
                      name="firstName"
                      rules={[
                        {
                          required: true,
                          message: "First Name is required"
                        }
                      ]}
                      initialValue={
                        userDetails &&
                        userDetails?.loginUser &&
                        userDetails?.loginUser?.length
                          ? userDetails?.loginUser?.split(" ")?.[0]
                          : ""
                      }>
                      <Input
                        onChange={() => updateState(!state)}
                        type="text"
                        disabled={disabledForm}
                        placeholder="First Name*"
                      />
                    </Form.Item>
                  </div>

                  {/* Last Name Field */}
                  <div className="form-field-container">
                    <Form.Item
                      name="lastName"
                      rules={[
                        {
                          required: true,
                          message: "Last Name is required"
                        }
                      ]}
                      initialValue={
                        userDetails &&
                        userDetails?.loginUser &&
                        userDetails?.loginUser?.length
                          ? userDetails?.loginUser?.split(" ")[
                              userDetails?.loginUser?.split(" ").length - 1
                            ]
                          : ""
                      }>
                      <Input
                        type="text"
                        placeholder="Last Name*"
                        disabled={disabledForm}
                        onChange={() => updateState(!state)}
                      />
                    </Form.Item>
                  </div>
                  <div className="spacer-half"></div>

                  {/* DOB Field */}
                  <h5>
                    Date of Birth (min. age 16)
                    <sup style={{ color: "red" }}>*</sup>
                  </h5>
                  <Form.Item
                    name="dob"
                    id="kyc-dob"
                    rules={[
                      {
                        required: true,
                        message: "Date of Birth is required"
                      }
                    ]}>
                    <DatePicker
                      disabled={disabledForm}
                      placeholder="DD-MM-YYYY*"
                      format="DD-MM-YYYY"
                      disabledDate={disableDate}
                      defaultPickerValue={moment().subtract(18, "years")}
                      onChange={() => updateState(!state)}
                    />
                  </Form.Item>
                  <div className="spacer-half"></div>

                  {/* Country Field */}
                  <h5>
                    Country of Residence<sup style={{ color: "red" }}>*</sup>
                  </h5>
                  <div className="form-field-container">
                    <Form.Item
                      name="countryOfResidence"
                      rules={[
                        {
                          required: true,
                          message: "Country of Residence is required"
                        }
                      ]}
                      initialValue={
                        countries.find(
                          country =>
                            country.name.toLowerCase() ===
                            userCountry.toLowerCase()
                        )?.name ?? "United States"
                      }>
                      <Select
                        showSearch
                        disabled={disabledForm}
                        className="dropdown"
                        onChange={() => updateState(!state)}
                        value={storedFormValues?.["countryOfResidence"]}>
                        {countries.map((country, index) => (
                          <Option
                            key={country.code + index}
                            value={country.name}
                            className="dropdown-option">
                            {country.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>

                  <div className="spacer-half"></div>
                  {/* Phone Number Field */}
                  <h5>
                    Mobile Number<sup style={{ color: "red" }}>*</sup>
                  </h5>
                  <div className="form-field-container">
                    <Form.Item
                      name="mobileNumber"
                      rules={[
                        {
                          required: true,
                          message: "Phone number is required"
                        },
                        {
                          min: 4,
                          message: "Minimum 4 digits are required"
                        },
                        {
                          max: 15,
                          message: "Maximum 15 digits are allowed"
                        }
                      ]}>
                      <Input
                        type="number"
                        disabled={disabledForm}
                        placeholder="Mobile Number*"
                        onChange={() => updateState(!state)}
                        addonBefore={prefixSelector}
                        value={storedFormValues?.["mobileNumber"]}
                        style={{
                          width: "100%"
                        }}
                      />
                    </Form.Item>
                  </div>
                </div>

                {/* Second Column : Address */}
                <div className="col form-section two">
                  <h3>Address Information</h3>
                  <div className="spacer-half"></div>

                  <h5>
                    Registered Address of hosting location
                    <sup style={{ color: "red" }}>*</sup>
                  </h5>
                  <div className="form-field-container">
                    <Form.Item
                      name="doorOrFlatNumber"
                      rules={[
                        {
                          required: true,
                          message: "Door No/Flat/Block no is required"
                        }
                      ]}
                      initialValue={
                        location.state.place
                          ? location.state.place?.address
                          : storedFormValues?.["doorOrFlatNumber"]
                      }>
                      <Input
                        type="text"
                        disabled={disabledForm}
                        placeholder="Door No/Flat/Block no*"
                        onChange={() => updateState(!state)}
                      />
                    </Form.Item>
                  </div>
                  <div className="form-field-container">
                    <Form.Item
                      name="apartmentOrAreaName"
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: "Apartment / Street / Area is required",
                      //   },
                      // ]}
                      initialValue={
                        storedFormValues &&
                        storedFormValues?.["apartmentOrAreaName"]
                          ? storedFormValues?.["apartmentOrAreaName"]
                          : ""
                      }>
                      <Input
                        type="text"
                        disabled={disabledForm}
                        placeholder="Apartment / Street / Area"
                        onChange={() => updateState(!state)}
                      />
                    </Form.Item>
                  </div>
                  <div className="form-field-container">
                    <Form.Item
                      name="landmark"
                      rules={[
                        {
                          required: true,
                          message: "Landmark/Direction is required"
                        }
                      ]}
                      initialValue={
                        location.state.place
                          ? location.state.place?.place_name
                          : storedFormValues?.["landmark"]
                      }>
                      <Input
                        type="text"
                        disabled={disabledForm}
                        placeholder="Landmark/Direction to reach*"
                        onChange={() => updateState(!state)}
                      />
                    </Form.Item>
                  </div>
                  <div className="form-field-container">
                    <Form.Item
                      name="districtAndState"
                      rules={[
                        {
                          required: true,
                          message: "State & City / District is required*"
                        }
                      ]}
                      initialValue={
                        location.state.place
                          ? location.state.place?.city
                          : storedFormValues?.["districtAndState"]
                      }>
                      <Input
                        type="text"
                        disabled={disabledForm}
                        placeholder="State & City / District*"
                        onChange={() => updateState(!state)}
                      />
                    </Form.Item>
                  </div>
                  <div className="form-field-container">
                    <Form.Item
                      name="pincode"
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: "Pincode is required",
                      //   },
                      //   {
                      //     min: 4,
                      //     message: "Minimum 4 digits are required",
                      //   },
                      //   {
                      //     max: 12,
                      //     message: "Maximum 12 digits are allowed",
                      //   },
                      // ]}
                      initialValue={
                        location.state.place
                          ? location.state.place?.zip
                          : storedFormValues?.["pincode"]
                      }>
                      <Input
                        disabled={disabledForm}
                        type="number"
                        placeholder="Pincode"
                        onChange={() => updateState(!state)}
                      />
                    </Form.Item>
                  </div>
                  <div className="form-field-container">
                    <Form.Item
                      name="country"
                      rules={[
                        {
                          required: true,
                          message: "Country is required"
                        }
                      ]}
                      initialValue={
                        location.state.place
                          ? location.state.place?.country
                          : storedFormValues?.["country"]
                      }>
                      <Input
                        type="text"
                        disabled={disabledForm}
                        placeholder="Country"
                        onChange={() => updateState(!state)}
                      />
                    </Form.Item>
                  </div>
                </div>
                <div className="col form-section three">
                  <button className="btn-brand btn-active btn-sm mx-w ">
                    Next{" "}
                    <img src={Images.forwardArrowIcon} alt="forward arrow" />
                  </button>
                </div>
              </div>
            </Form>
            <p className=" mt-4 detail-match-text">
              * Details mentioned here should match the ID proof
            </p>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="notes">
          <div className="note">
            <h5>Note :</h5>
            <p>
              Nitro Network currently accepts the following Nester locations in
              Phase 1. We will be updating you when more locations are added. Do
              follow us on our social media channels for the latest from us.
            </p>
            <p>1. USA (Miami, New York, Los Angeles)</p>
            <p>2. India (Hyderabad, Bangalore, Chennai, Bombay, Delhi) </p>
          </div>
          <div className="note">
            <h5>$100 Refundable deposit</h5>
            <p>
              In the event the applicant is selected to be a Nester, they will
              be required to remit to the Company an amount of USD 100 as
              performance security. This deposit will be staked, and in the case
              of a refund, it will be refunded in NCash only. The said
              performance security will be refunded as per the terms of the
              nesting agreement.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default KYC
