import axios from "axios"
import React, { useState, useEffect } from "react"
import moment from "moment"
import { useSelector } from "react-redux"
import { useLogout } from "../../../hooks"
import addCommas from "add-commas"

import { IoTAPIUrl } from "../../../utils/config"
import { isFloat } from "../../../utils"
import ViewDetailPopup from "./ViewDetail"

function MyOrders() {
  const logoutHandler = useLogout()
  const userDetails = useSelector(state => state.common.userDetails)
  const [orders, setOrders] = useState([])
  const [showViewDetail, setshowViewDetail] = useState(false)
  const [orderItems, setOrderItems] = useState([])

  const getOrderHistory = async () => {
    try {
      const response = await axios.get(
        `${IoTAPIUrl}/getOrderHistory/${userDetails?.loginUserId}`,
        {
          headers: {
            Authorization: `Bearer ${userDetails?.loginUserToken}`
          }
        }
      )

      if (response.status === 200) {
        setOrders(response.data.data)
      } else {
        throw new Error(response.data.message)
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        logoutHandler()
      }
    }
  }

  const fetchOrderDetail = async orderId => {
    if (orderId < 0) return
    try {
      const response = await axios.get(
        `${IoTAPIUrl}/viewOrderDetails/${userDetails?.loginUserId}/${orderId}`,
        {
          headers: {
            Authorization: `Bearer ${userDetails?.loginUserToken}`
          }
        }
      )
      if (response.status === 200) {
        setOrderItems(response.data.data)
        setshowViewDetail(true)
      } else {
        setOrderItems([])
        throw new Error(response?.data?.message)
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        logoutHandler()
      }
    }
  }

  useEffect(() => {
    getOrderHistory()
  }, [])

  return (
    <>
      {showViewDetail ? (
        <ViewDetailPopup
          orderItems={orderItems}
          setshowViewDetail={setshowViewDetail}
        />
      ) : null}
      <div className="iot-my-orders">
        <div className="card-ship no-padding-in-mobile">
          <table className="back-white table de-table table-rank table-font-size-reduce table-responsive">
            <thead>
              <tr>
                {/* <!-- Added new Class --> */}
                {/* <th scope="col" className="first-head">Collection</th> */}
                <th scope="col" className="right">
                  Transaction Date{" "}
                </th>

                <th scope="col" className="right">
                  Transaction Type{" "}
                </th>

                <th scope="col" className="right">
                  Order ID{" "}
                </th>

                <th scope="col" className="right">
                  Amount (USD)
                </th>

                <th scope="col" className="right">
                  Status{" "}
                </th>
                <th scope="col" className="right">
                  Details{" "}
                </th>
              </tr>
            </thead>
            <tbody>
              {orders && orders.length > 0 ? (
                orders.map((order, index) => {
                  return (
                    <tr key={index}>
                      <td scope="row" data-label="Transaction Date">
                        <span className="address-text-table">
                          {moment(order?.createdAt).format(
                            "DD-MMMM-YYYY hh:mm:ss A"
                          )}
                        </span>
                      </td>
                      <td className="right" data-label="Transaction Type">
                        {order?.paymentMethod}
                      </td>
                      {/* <td className="d-plus right" data-label="lat">{item.source}</td> */}
                      <td
                        className="d-plus right overflow-text"
                        data-label="Order ID">
                        <span className="trx-id">
                          {(order?.chargeId ?? "").length
                            ? order.chargeId
                            : order?.paymentid ?? ""}
                        </span>
                      </td>

                      <td className="right" data-label="Order Amount">
                        {order?.currencyCode === "inr"
                          ? "₹"
                          : order?.currencyCode === "usd"
                          ? "$"
                          : "€"}
                        {!isFloat(order?.amountPayable ?? 0)
                          ? addCommas(order?.amountPayable ?? 0)
                          : order?.amountPayable ?? 0}
                      </td>
                      <td className="right" data-label="Order Status">
                        {/* {item.payment_status} */}
                        {order?.paymentStatus}
                      </td>

                      {/* Button for opening popup */}
                      <td className="right" data-label="Details">
                        <button
                          style={{
                            padding: "2px 8px",
                            borderRadius: "5px",
                            color: "#000"
                          }}
                          onClick={() => fetchOrderDetail(order?.id)}
                          className="btn-brand btn-transparent px-05">
                          View Details
                        </button>
                      </td>
                    </tr>
                  )
                })
              ) : (
                <td colSpan={6} className="text-center">
                  No Data{" "}
                </td>
              )}

              {/* <tr ><h2 className='alignCenter '>No Data</h2>  </tr>} */}
            </tbody>
          </table>

          <div className="spacer-double"></div>
          <p>
            * Once your payment confirmation is received from the payment
            processor, your order status will be updated. Ignore, if order is
            successfully placed and order is confirmed.
          </p>
        </div>
      </div>
    </>
  )
}

export default MyOrders
