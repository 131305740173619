import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"

import "../styles/table.css"
// import "./styles/rewards.css"
import moment from "moment"
import { useDispatch } from "react-redux"
import { BaseUrl } from "../../utils/config"
import {
  setConnectedWallet,
  setTokenData,
  setStakeTokenData,
  startLoader,
  stopLoader
} from "../../redux/actions"
import Web3 from "web3"
import Cookies from "universal-cookie"
import axios from "axios"

import nfmClaimRewardsAbi from "../../assets/blockchain/nfmClaimRewardsAbi.json"
import nfmClaim from "../../assets/blockchain/nfmClaim.json"
import constants from "../../constants/constants"
import swal from "sweetalert"
import { useLogout } from "../../hooks"
import ConnectWalletBtn from "./components/ConnectWalletBtn"

const cookies = new Cookies()
const Rewards = () => {
  const dispatch = useDispatch()
  const logoutHandler = useLogout()
  const [rewardType, setRewardType] = useState("")
  const [nfmTransactions, setNFMTransactions] = useState([])
  const [iotTransactions, setIoTTransactions] = useState([])
  const [nextClaimTime, setNextClaimTime] = useState(0)
  const [nextClaimTimeString, setNextClaimTimeString] = useState("")
  const [ifClaimDateArrived, setIfClaimDateArrived] = useState(false)
  const [claimAble, setClaimAble] = useState(0)
  const [claimedAmount, setClaimedAmount] = useState(0)

  const [web3, setWeb3] = useState(null)
  const [isWalletConnected, setWalletConnected] = useState(false)

  const userDetails = useSelector(state => state.common.userDetails)
  const connectedWallet =
    useSelector(state => state.common.connectedWallet) || ""

  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${userDetails.loginUserToken}`
  }

  const connectToWallet = async () => {
    console.log(
      `[nft][src][components][components][pages][rewards][connectToWallet] connectToWallet`
    )
    if (typeof window.ethereum !== "undefined") {
      let accounts = await window.ethereum.request({
        method: "eth_requestAccounts"
      })
      window.web3 = new Web3(window.ethereum)
      setWeb3(window.web3)

      let chainId = await window.web3.eth.getChainId()
      cookies.set("wallet", accounts[0], { path: "/" })
      cookies.set("wallet_status", 1, { path: "/" })
      setWalletConnected(true)
      dispatch(setConnectedWallet(accounts[0]))
      setWeb3(window.web3)
      let apiData = {
        wallet_address: accounts[0]
      }
      axios
        .put(
          `${BaseUrl}/addwallet?user_id=${userDetails?.loginUserId}`,
          apiData,
          {
            headers
          }
        )
        .then(response => {
          if (response.status === 200) {
          }
        })
        .catch(err => {
          if (err.response.status === 401) {
            logoutHandler()
          }
        })
    } else {
      cookies.set("wallet", null, { path: "/" })
      cookies.set("wallet_status", 0, { path: "/" })
      setWalletConnected(false)
      dispatch(setConnectedWallet(""))
      setWeb3(null)
      console.log("Unsupported Browser/Plugin not installed")
    }
  }

  const disconnectWallet = () => {
    axios
      .delete(`${BaseUrl}/removeWallet/${userDetails?.loginUserId}`, {
        headers
      })
      .then(response => {
        cookies.set("wallet", null, { path: "/" })
        cookies.set("wallet_status", 0, { path: "/" })
        setWalletConnected(false)
        dispatch(setConnectedWallet(""))
        dispatch(setTokenData([]))
        dispatch(setStakeTokenData([]))
        setNFMTransactions([])
        setIoTTransactions([])
        setClaimAble(0)
        setClaimedAmount(0)
        localStorage.removeItem("tokenData")
        localStorage.removeItem("stakeTokenData")
        setWeb3(null)
      })
      .catch(error => {
        if (error?.response?.status == 401) {
          logoutHandler()
        }
      })
  }

  const refreshPage = () => {
    window.location.reload()
  }

  const getClaimRewardsSignature = async () => {
    try {
      var axios = require("axios")
      if (typeof window.ethereum !== "undefined") {
        let accounts = await window.ethereum.request({
          method: "eth_requestAccounts"
        })
        window.web3 = new Web3(window.ethereum)
        setWeb3(window.web3)

        if (accounts.length == 0) {
          swal({
            icon: "error",
            title: "Failed",
            text: "Connect your wallet to proceed",
            showConfirmButton: true,
            showCancelButton: false,
            allowOutsideClick: true
          }).then(() => {
            refreshPage()
          })
          return
        }

        var data = JSON.stringify({
          wallet_address: accounts[0],
          amount: claimAble
        })

        var config = {
          method: "post",
          url: `${BaseUrl}/generateNfmRewardsClaimSignature`,
          headers,
          data: data
        }

        axios(config)
          .then(async function (response) {
            if (response.status == 200) {
              let result = response.data
              let NFMClaimRewards = await new window.web3.eth.Contract(
                nfmClaimRewardsAbi,
                constants.NFMCLAIMREWARDS
              )
              // return;
              let __v = result?.signedData.v
              let v = null

              if (__v == "0x1b") {
                v = 27
              } else if (__v == "0x1c") {
                v = 28
              }

              await NFMClaimRewards.methods
                .claimRewards(
                  result?.amount,
                  result?.nonce,
                  v,
                  (result?.signedData ?? {})?.r ?? "",
                  (result?.signedData ?? {})?.s ?? ""
                )
                .send({ from: accounts[0] })
                .on("transactionHash", function (hash) {
                  // update transaction hash - 1 with false
                  swal({
                    icon: "info",
                    title: "Please wait",
                    text: "Transaction initiated, Please wait for the transaction to confirm",
                    button: false,
                    showCancelButton: false,
                    closeOnClickOutside: false,
                    closeOnEsc: false
                  }).then(() => {
                    refreshPage()
                  })
                  waitTransactionReceipt(
                    hash,
                    "claim",
                    0,
                    function (err, receipt) {
                      if (err != null) {
                        return
                      }
                    }
                  )
                })
                .on("error", function (error, receipt) {
                  console.log(
                    "[nft][src][components][pages][rewards] Approve Tx Error",
                    error
                  )
                  swal({
                    icon: "error",
                    title: "Failed",
                    text: "Transaction cancelled. Please try again!",
                    showConfirmButton: true,
                    showCancelButton: false,
                    allowOutsideClick: true
                  }).then(() => {
                    refreshPage()
                  })
                  return
                })
            } else {
              swal({
                icon: "error",
                title: "Failed",
                text: "Something went wrong, Please try again",
                showConfirmButton: true,
                showCancelButton: false,
                allowOutsideClick: true
              })
            }
          })
          .catch(function (error) {
            console.log(error)
          })
      } else {
        console.log("[nft][src][components][pages]:: Unsupported Browser")
      }
    } catch (error) {
      console.log(error)
    }
  }

  const waitTransactionReceipt = async (
    transactionHash,
    actionType,
    timeOut,
    callback
  ) => {
    console.log(
      "[nft][src][components][pages][rewards][waitTransactionReceipt] transactionHash",
      transactionHash
    )
    var web3 = new Web3(window.ethereum)
    let self = this

    let timeOutCounter = 0
    let err = {
      message: ""
    }

    let isWaitRunning = false

    let getReceiptTimerId = setInterval(function () {
      if (isWaitRunning == false) {
        isWaitRunning = true
        web3.eth
          .getTransaction(transactionHash)
          .then(function (tx) {
            if (tx == null) {
              clearInterval(getReceiptTimerId)
              console.log(
                `[nft][src][components][pages][rewards][waitTransactionReceipt] transactionHash ${transactionHash} is not found`
              )
              err.message = "Transaction hash is not found."
              isWaitRunning = false
              if (callback) {
                callback(err, null)
              }
              return
            }

            if (tx.blockNumber == null) {
              if (timeOut > 0) {
                timeOutCounter++
                if (timeOutCounter >= timeOut) {
                  clearInterval(getReceiptTimerId)
                  isWaitRunning = false
                  err.message = "Timeout while waiting transaction to confirm."
                  if (callback) {
                    callback(err, null)
                  }
                  return
                } else {
                  console.log(
                    `[nft][src][components][pages][rewards][waitTransactionReceipt] transactionHash 1 ${transactionHash} is pending`
                  )
                  isWaitRunning = false
                  return
                }
              } else {
                console.log(
                  `[nft][src][components][pages][rewards][waitTransactionReceipt] transactionHash 2 ${transactionHash} is pending`
                )
                isWaitRunning = false
                return
              }
            }

            // Transaction confirm
            web3.eth
              .getTransactionReceipt(transactionHash)
              .then(async function (web3TxReceipt) {
                clearInterval(getReceiptTimerId)
                isWaitRunning = false
                if (web3TxReceipt == null) {
                  console.log(
                    `[nft][src][components][pages][rewards][waitTransactionReceipt] transactionHash 2 ${transactionHash} is not found`
                  )
                  swal({
                    icon: "error",
                    title: "Failed",
                    text: "Transaction Failed. Please try again!",
                    showConfirmButton: true,
                    showCancelButton: false,
                    allowOutsideClick: true
                  }).then(() => {
                    refreshPage()
                  })
                }
                if (web3TxReceipt.status != false) {
                  if (actionType == "approve") {
                    swal({
                      icon: "success",
                      title: "Approved",
                      text: "Transaction Successful",
                      showConfirmButton: true,
                      showCancelButton: false,
                      allowOutsideClick: true
                    }).then(() => {
                      refreshPage()
                    })
                  } else if (actionType == "stake") {
                    swal({
                      icon: "success",
                      title: "Success",
                      text: "Staked Successful",
                      showConfirmButton: true,
                      showCancelButton: false,
                      allowOutsideClick: true
                    }).then(() => {
                      refreshPage()
                    })
                  } else if (actionType == "unstake") {
                    swal({
                      icon: "success",
                      title: "Success",
                      text: "Unstaked Successful",
                      showConfirmButton: true,
                      showCancelButton: false,
                      allowOutsideClick: true
                    }).then(() => {
                      refreshPage()
                    })
                  } else if (actionType == "claim") {
                    // update transaction hash - 2 with true

                    axios
                      .post(
                        `${BaseUrl}/claimTotalRewards`,
                        {
                          wallet_address: connectedWallet
                        },
                        {
                          headers
                        }
                      )
                      .then(function (response) {
                        dispatch(stopLoader())
                        refreshPage()
                      })
                      .catch(function (error) {
                        if (error?.response?.status === 401) {
                          logoutHandler()
                        }
                      })
                    swal({
                      icon: "success",
                      title: "Success",
                      text: "Rewards claimed Successful",
                      showConfirmButton: true,
                      showCancelButton: false,
                      allowOutsideClick: true
                    }).then(() => {
                      dispatch(startLoader())
                    })
                  }
                }
              })
              .catch(function (error) {
                if (error?.response?.status == 401) {
                  logoutHandler()
                }
                clearInterval(getReceiptTimerId)
                isWaitRunning = false
                console.log(
                  `[nft][src][components][components][modal][waitTransactionReceipt] Error on transaction receipt 1 ${transactionHash}: ${error}`
                )
                swal({
                  icon: "error",
                  title: "Cancelled",
                  text: error.message,
                  showConfirmButton: true,
                  showCancelButton: false,
                  allowOutsideClick: true
                }).then(() => {
                  refreshPage()
                })
              })
          })
          .catch(function (error) {
            clearInterval(getReceiptTimerId)
            console.log(
              `[nft][src][components][components][modal][waitTransactionReceipt] Error on transaction receipt 2 ${transactionHash}: ${error}`
            )
            swal({
              icon: "error",
              title: "Cancelled",
              text: error.message,
              showConfirmButton: true,
              showCancelButton: false,
              allowOutsideClick: true
            }).then(() => {
              refreshPage()
            })
          })
      }
    }, 1000)
  }

  const checkNextClaim = async () => {
    window.web3 = new Web3(window.ethereum)
    setWeb3(window.web3)
    let NFMClaimRewards = await new window.web3.eth.Contract(
      nfmClaim,
      constants.NFMCLAIMREWARDS
    )
    var nextAllowedClaim = await NFMClaimRewards.methods
      .nextAllowedClaim(connectedWallet)
      .call()
    nextAllowedClaim = parseInt(nextAllowedClaim)
    setNextClaimTime(nextAllowedClaim)
    let currentTime = moment().utc().unix()
    if (currentTime > nextAllowedClaim) {
      setIfClaimDateArrived(true)
    } else {
      setNextClaimTimeString(new Date(nextAllowedClaim * 1000).toUTCString())
    }
  }

  const addWalletToServer = () => {
    let apiData = {
      wallet_address: connectedWallet
    }
    axios
      .put(
        `${BaseUrl}/addwallet?user_id=${userDetails?.loginUserId}`,
        apiData,
        {
          headers
        }
      )
      .then(response => {})
      .catch(err => {
        if (err.response.status === 401) {
          logoutHandler()
        }
      })
  }

  const getRewardsData = () => {
    axios
      .post(`${BaseUrl}/getRewardsList`, {
        wallet_address: connectedWallet,
        userId: userDetails?.loginUserId
        // wallet_address: "0xfC0e57e23520E62E5249217b1aAa34279991ae08",
        // wallet_address: "0x0bdefF2d7fbA0cd9da59Ebc2D2C4F1284D4eBc76", Have Reward Claim
      })
      .then(function (response) {
        if (response.status === 200) {
          setNFMTransactions(response?.data?.data?.nfmStakes)
          setIoTTransactions(response?.data?.data?.iotReferrals)
          setClaimAble(response?.data?.totalClaimableAmount)
          setClaimedAmount(response?.data?.totalClaimedAmount)
        } else {
          throw new Error("Someting went wrong")
        }
      })
      .catch(function (error) {
        if (error?.response?.status === 401) {
          logoutHandler()
        }
      })
  }

  useEffect(() => {
    if (connectedWallet.length) {
      checkNextClaim()
      // add wallet address
      checkNextClaim()
      if (userDetails?.loginUserToken ?? "") {
        addWalletToServer()
        getRewardsData()
      }
      // fetch rewards data
    }
  }, [connectedWallet])

  return (
    <div className="mt-3 container reward-page-new Reward-page">
      <br />
      <div className="text-center w-100 rewards-drop-wallet">
        <div className="box-det mx-auto margin-10px connect-wallet-box">
          <div
            className="gradient-bo "
            style={{ maxWidth: "200px", margin: "auto" }}>
            <ConnectWalletBtn
              connectedWallet={connectedWallet}
              disconnectWallet={disconnectWallet}
              connectToWallet={connectToWallet}
            />

          </div>
        </div>

        {/* Dropdown Rewards */}
        {/* <div className="dropdown-rewards">
          <select name="rewardType" id="rewardType">
            <option value="nfm">NFM</option>
            <option value="genesis">Genesis Node</option>
            <option value="iot">IoT</option>
            <option value="staking">Staking</option>
            <option value="gleamtask">Gleam Task Ncash</option>
          </select>
        </div> */}
      </div>

      <br />
      <br />

      <div className="rewards-box-section">
        <h3>Reward Status</h3>

        <div className="rewards-boxes-claim">
          {/* //Rewards BOxes */}
          <div className="rewards-boxes">
            <div className="reward-box">
              <p className="mb-2">Claimable Rewards</p>
              <h2>
                <span>{claimAble || 0} </span> NCash
              </h2>
            </div>
            <div className="reward-box">
              <p className="mb-2">Claimed Rewards</p>
              <h2>
                <span>{claimedAmount || 0} </span> NCash
              </h2>
            </div>
          </div>
          {/* Claim button */}
          <div className="claim-button">
            {claimAble < 1 || !ifClaimDateArrived ? null : (
              <button
                onClick={getClaimRewardsSignature}
                className="gradient-button">
                Claim Rewards
              </button>
            )}
            {!ifClaimDateArrived && connectedWallet.length > 0 && (
              <p>The rewards can next be claimed from {nextClaimTimeString}</p>
            )}
          </div>
        </div>
      </div>

      <div className="rewards-table">
        <div className="dfjb align-items-center">
          <h3 className="mb-0">NFM Reward Transactions</h3>
        </div>

        {nfmTransactions?.length ?? 0 ? (
          <table
            id="order-table"
            className=" table-scss table de-table table-rank table-font-size-reduce table-responsive">
            <thead>
              <tr>
                <th scope="col" className="first-head">
                  Tier
                </th>
                <th scope="col" className="right">
                  Total Claimed Amount
                </th>
                <th scope="col" className="right">
                  Staked Time
                </th>

                <th scope="col" className="right">
                  <p className="mb-0 lh-1">
                    Claimable Amount <br /> <small>(NCash)</small>
                  </p>
                </th>
              </tr>
            </thead>
            <tbody>
              {nfmTransactions.map((product, i) => (
                <tr key={i}>
                  <td className="right" data-label="Tier">
                    {product?.tier}
                  </td>
                  <td
                    className="right staked-by-tbl"
                    data-label="Total Claimed Amount">
                    <span> {product?.claimedAmount || 0} NCash</span>
                  </td>
                  <td className=" right " data-label="Staked Time">
                    {moment(product?.stakeTime).format("DD-MMM-YYYY hh:mm:ss")}
                  </td>
                  <td className="right" data-label="Claimable Amount">
                    {product?.claimableAmount} NCash
                  </td>
                </tr>
              ))}

              {/* ))} */}
            </tbody>
          </table>
        ) : null}
        {/* ) : null} */}
      </div>

      {/* IOT REWARDS TRANSACTION'S */}

      {/* USE API PARAMETER's */}

      <div className="rewards-table">
        <div className="dfjb align-items-center">
          <h3 className="mb-0">IoT Reward Transactions</h3>
        </div>
        {iotTransactions?.length ?? 0 ? (
          <table
            id="order-table"
            className="table de-table table-rank table-font-size-reduce table-responsive">
            <thead>
              <tr>
                <th scope="col" className="first-head">
                  Email
                </th>
                <th scope="col" className="right">
                  Reward Amount
                </th>
                <th scope="col" className="right">
                  Reward Claimed
                </th>

                <th scope="col" className="right">
                  <p className="mb-0 lh-1">Credited on</p>
                </th>
              </tr>
            </thead>
            <tbody>
              {iotTransactions?.map((transaction, i) => (
                <tr key={i}>
                  <td className="right" data-label="Email">
                    {transaction?.email}
                  </td>
                  <td
                    className="right staked-by-tbl"
                    data-label="Reward Amount">
                    <span>{transaction?.rewardAmount}</span>
                  </td>
                  <td className="right" data-label="Reward Claimed">
                    {transaction?.isRewardClaimed ? "Yes" : "No"}
                  </td>
                  <td className=" right " data-label="Credited on">
                    {moment(transaction?.createdAt).format(
                      "DD-MMM-YYYY hh:mm:ss"
                    )}
                  </td>
                </tr>
              ))}

              {/* ))} */}
            </tbody>
          </table>
        ) : null}
        {/* ) : null} */}
      </div>

      <p className="text-right mt-3 mb-1">
        Rewards are calculated and shown till the previous night midnight.
        Staking done today, will start seeing rewards from midnight tonight.
      </p>
      <p className="text-right ">
        Please use Avalanche network on metamask to claim & Rewards can be
        claimed after 30 days from last claim.
      </p>
    </div>
  )
}
export default Rewards
