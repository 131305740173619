import { ACTION_TYPES } from "."

export const setConnectedWallet = payload => {
  return {
    type: ACTION_TYPES.SET_CONNECTED_WALLET,
    payload
  }
}

export const setTokenData = payload => {
  return {
    type: ACTION_TYPES.SET_TOKEN_DATA,
    payload
  }
}

export const setStakeTokenData = payload => {
  return {
    type: ACTION_TYPES.SET_STAKE_TOKEN_DATA,
    payload
  }
}

export const setClickedNFM = payload => {
  return {
    type: ACTION_TYPES.SET_CLICKED_NFM,
    payload
  }
}
