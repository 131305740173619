// export const BaseUrl= "https://rewards.federal.money/api/user";

export const BaseUrl = process.env.REACT_APP_API_URL
// https://nestbuy.lorvensofttech.com/api/v1/
export const GatewayUrl = process.env.REACT_APP_GATEWAY_URL
export const IoTAPIUrl = process.env.REACT_APP_IOT_API_URL
export const IoTUrl = process.env.REACT_APP_IOT_URL
export const IPAPI_TOKEN = process.env.REACT_APP_IPAPI_TOKEN

export const NcashUrl = process.env.REACT_APP_NCASH_URL
export const GenesisURL = process.env.REACT_APP_GENESIS_URL
export const SwapURL = process.env.REACT_APP_SWAP_URL
export const StakeURL = process.env.REACT_APP_STAKE_URL
export const nftURL = process.env.REACT_APP_NFT_URL
export const usecaseURL = process.env.REACT_APP_USECASE_URL
export const blogURL = process.env.REACT_APP_BLOG_URL

export const isNestEnabled = false
export const LightTheme = true
