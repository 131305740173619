import React, { useState, useEffect, createRef } from "react"
import { Form, Input } from "antd"
import "../styles/style.css"
import { useNavigate } from "@reach/router"
import swal from "sweetalert"
import "antd/dist/antd.css"
import { useHistory } from "react-router-dom"
import { BaseUrl } from "../../utils/config"
// import * as FiIcons from "react-icons/fi"
import { useDispatch, useSelector } from "react-redux"
import { setIoTTokens } from "../../redux/actions"
// import {
//   loadCaptchaEnginge,
//   LoadCanvasTemplate,
//   LoadCanvasTemplateNoReload,
//   validateCaptcha,
// } from "react-simple-captcha";

import ReCAPTCHA from "react-google-recaptcha"
import { useLogout } from "../../hooks"

const Signup = () => {
  const [isEmail, setIsEmail] = useState(false)
  // const [isTemp, setTemp] = useState(false)
  // const [captchaError, SetCaptchaError] = useState(null)
  const [isCaptcha, setIsCaptcha] = useState(false)
  const dispatch = useDispatch()
  const [captchaToken, setCatpchaToken] = useState("")

  const navigate = useNavigate()
  const history = useHistory()
  // const location = useLocation()
  const logoutHandler = useLogout()
  const iotTokens = useSelector(state => state.common.iotTokens) || []
  // const [refferal, setRefferal] = useState("")

  const [form] = Form.useForm()
  const recaptchaRef = createRef()
  const captchaSiteKey = "6Lehjh8eAAAAABkj0_A1-xndH8NVsLUe1763sN5v"

  // Getting IoT tokens string
  let QIotTokens = new URLSearchParams(window.location.search)?.get("iotTokens")
  // Sanitizing Tokens string
  QIotTokens = QIotTokens?.replace(/"|'/gim, "")
  // Converting tokens to array
  QIotTokens = QIotTokens ? QIotTokens.split(",") : []

  // var bcrypt = require("bcryptjs");
  useEffect(async () => {
    !iotTokens.length && dispatch(setIoTTokens(QIotTokens))
    // loadCaptchaEnginge(6, "#11132800", "#ffffff");
    setIsCaptcha(false)
    // let path=location.pathname;
    // let temp = path.substr(path.lastIndexOf("/")+1);
    // temp==="verifyEmail"?setRefferal(""):setRefferal(Base64.decode(temp));
  }, [])

  const onFinish = values => {
    if (!isCaptcha) {
      swal({
        text: "Captcha does not match",
        icon: "error"
      })
    } else {
      var axios = require("axios")
      var data = JSON.stringify({
        email: values.email,
        token: captchaToken
      })

      var config = {
        method: "post",
        url: `${BaseUrl}/validateemail`,
        headers: {
          "Content-Type": "application/json"
        },
        data: data
      }

      axios(config)
        .then(function (response) {
          if (response.status == 200) {
            navigate("/signup-sucess")
          } else {
            recaptchaRef.current.reset()
            setCatpchaToken("")
            swal({
              title: "Invalid email",
              text: "Please try again with another email",
              icon: "warning"
            })
          }
        })
        .catch(function (error) {
          recaptchaRef.current.reset()
          setCatpchaToken("")

          if (
            error?.response?.status === 409 ||
            error?.response?.status === 429
          ) {
            swal({
              title: "Error!",
              text: error.response.data.message,
              icon: "error"
            })
          } else if (error?.response?.status == 401) {
            logoutHandler()
          } else if (error?.response?.status == 404) {
            swal({
              title: "Error!",
              text: "Page not found. Please try again later.",
              icon: "error"
            })
          } else if (error?.response?.status == 500) {
            swal({
              title: "Error!",
              text: "Something went wrong. Please try again later.",
              icon: "error"
            })
          } else if (error?.response?.status == 503) {
            swal({
              title: "Error!",
              text: "Something went wrong. Please try again later.",
              icon: "error"
            })
          }
        })
    }
  }

  // const doVerify = (value) => {
  //   SetCaptchaError(null);
  //   if (value.length > 5) {
  //     let user_captcha_value =
  //       document.getElementById("user_captcha_input").value;
  //     if (validateCaptcha(user_captcha_value, false) == true) {
  //       setIsCaptcha(true);
  //       SetCaptchaError(null);
  //     } else {
  //       setIsCaptcha(false);
  //       SetCaptchaError("Captcha does not match. Please try again.");
  //     }
  //   } else {
  //     setIsCaptcha(false);
  //   }
  // };

  // const reloadCaptcha = () => {
  //   document.getElementById("user_captcha_input").value = "";
  //   setIsCaptcha(false);
  //   SetCaptchaError(null);
  //   // loadCaptchaEnginge(6, "#11132800", "#ffffff");
  // };
  const toSignup = () => {
    history.push("/verifyEmail")
  }
  const toforgot = () => {
    history.push("/forgotPassword")
  }
  const toLogin = () => {
    navigate("/login")
  }

  const captchaHandler = value => {
    setCatpchaToken(value)
    setIsCaptcha(value ? true : false)
  }

  return (
    <div className="Login-page">
      {/* <GlobalStyles/> */}

      <div className="Login-page__box">
        <Form
          name="contactForm"
          id="contact_form"
          className="form-border "
          action=""
          autoComplete="off"
          onFinish={onFinish}>
          <h3>Sign up</h3>

          <div className="field-set mt-4">
            <Form.Item
              name="email"
              rules={[
                {
                  type: "email",
                  message: "The input is not valid email!"
                },
                {
                  required: true,
                  message: "Enter the email"
                }
              ]}>
              <Input
                id="email"
                placeholder="Enter email"
                onChange={e => {
                  setIsEmail(e.target.value)
                }}
                className="form-control"
              />
            </Form.Item>
          </div>
          {/* <div className="displayFelx">
                      <LoadCanvasTemplateNoReload />
                      <div className="captchaReload" onClick={reloadCaptcha}>
                        {" "}
                        <FiIcons.FiRefreshCw />
                      </div>
                    </div> */}
          <div className="field-set">
            <Form.Item
              name="captcha"
              rules={[
                {
                  validator: (_, value) =>
                    captchaToken.length
                      ? Promise.resolve()
                      : Promise.reject(new Error("Captcha is required."))
                }
              ]}>
              {/* <Input
                          placeholder="Enter Captcha"
                          id="user_captcha_input"
                          className="form-control"
                          name="user_captcha_input"
                          onChange={(e) => doVerify(e.target.value)}
                          type="text"
                        /> */}
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={captchaSiteKey}
                onChange={val => captchaHandler(val)}
              />
            </Form.Item>
            {/* {captchaError ? <p style={{ color: 'red', fontSize: 16, margin: '10px 0px 0px' }}>{captchaError}</p> : null} */}
          </div>

          <div id="submit">
            <input
              type="submit"
              id="send_message"
              value="Verify email"
              className="btn-brand btn-active color-primary"
            />

            <div className="clearfix"></div>
            <div className="spacer-single"></div>
          </div>

          <div className="dfjb">
            <p>
              Already existing user?{" "}
              <a className="colorPurple" onClick={toLogin}>
                <a> Sign in</a>
              </a>
            </p>
          </div>
        </Form>
      </div>

      {/* <Footer /> */}
    </div>
  )
}
export default Signup
