import React, { useState, useEffect } from "react"
import ReactDOM from "react-dom"
import "../../assets/css/faq.css"
import { createGlobalStyle } from "styled-components"
import { BaseUrl } from "../../utils/config"
import { useNavigate } from "@reach/router"
import { useHistory, useLocation } from "react-router-dom"
import axios from "axios"
import swal from "sweetalert"
import { useDispatch } from "react-redux"
import { setConnectedWallet, updateUserDetailsRedux } from "../../redux/actions"

const HowItWorks = () => {
  const user = localStorage.getItem("loginUser")
  const [allproducts, setAllproducts] = useState([])
  const [viewProduct, setViewProduct] = useState(false)
  const [activeTab, SetActiveTab] = useState(-1)
  const loginUserMail = localStorage.getItem("loginUserMail")
  const history = useHistory()
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const panels = [
    {
      label: "1. What is Nesting?",
      content: (
        <ul>
          <li>
            Nesting is equivalent to hosting a network. Whoever participates in
            nesting and becomes a verified host on Nitro Network is called the
            nester.{" "}
          </li>
          <li>
            {" "}
            The Nitro platform enables this process of nesting creating synergy
            of people hosting the network across the world, enabling them to
            earn from their location. Read more here: <br />
            <a
              href="https://nitronetwork.medium.com/nitro-nesting-an-effortless-way-to-earn-from-any-location-in-the-world-9df8777b8a1b"
              target="_blank"
              className="faq-link"
              rel="noreferrer">
              {" "}
              https://nitronetwork.medium.com/nitro-nesting-an-effortless-way-to-earn-from-any-location-in-the-world-9df8777b8a1b
            </a>
            <a
              href="https://nitronetwork.medium.com/why-nitro-nesting-ensures-everybody-wins-27f041d7c848"
              target="_blank"
              className="faq-link"
              rel="noreferrer">
              https://nitronetwork.medium.com/why-nitro-nesting-ensures-everybody-wins-27f041d7c848
            </a>
          </li>
        </ul>
      )
    },
    {
      label: "2. Why Nesting?",
      content: (
        <ul>
          <li>
            Nesting ensures that we have strong connectivity in a defined radius
            of coverage area with a minimum number of Nitro ION miners. It
            addressess the two prime issues that can stand in the way of earning
            from a decentralized network.{" "}
          </li>
          <li>
            {" "}
            Network congestion - Placement of miners are scattered and
            unoptimized. It creates network congestuion in areas which are
            densely populated with miners while there is weak coverage in other
            areas where there are less miners or no miner.
          </li>
          <li>
            Unequitable distribution of rewards- Due to uneven distribution of
            miners, multiple miners compete for a piece of the same pie when it
            comes to rewards. By confining the radius and number of miners per
            radius, we are not only optimizing the resource utilization but also
            making sure every participant get maximum rewards. <br />
            Read more here:{" "}
            <a
              href="https://nitronetwork.medium.com/why-nitro-nesting-ensures-everybody-wins-27f041d7c848"
              target="_blank"
              className="faq-link"
              rel="noreferrer">
              https://nitronetwork.medium.com/why-nitro-nesting-ensures-everybody-wins-27f041d7c848
            </a>
          </li>
        </ul>
      )
    },
    {
      label: "3. Who is a Nester?",
      content: (
        <ul>
          <li>
            Anybody who hosts the Nitro Network is a Nester. You can host your
            "nest" through the Nitro Platform anywhere in the world. You become
            a nester when your location/address is verified and approved by the
            Nitro Nework platform by fulfilling the required criteria.
          </li>
        </ul>
      )
    },
    {
      label: "4. Why should you become a Nester?",
      content: (
        <ul>
          <li>
            Becoming a Nester on Nitro Network has many benefits. 1) You can
            earn effortlessly 2) Your location is your source of wealth. 3) It
            requires minimum investment. All you requrie is a small, unutilized
            space in your home/office, good WiFi connectivity and power supply.
          </li>
        </ul>
      )
    },
    {
      label: "5. What are the steps for becoming a Nester?",
      content: (
        <>
          You can become a Nester in 4 simple steps:
          <ul>
            <li>
              Choose your preferred unused micro space in your home/office{" "}
            </li>
            <li>Register your location on Nitro platform </li>
            <li>Complete the verification process </li>
            <li>Become a nester </li>
          </ul>
        </>
      )
    },
    {
      label: "6. How will a Nester earn?",
      content: (
        <ul>
          <li>
            A nester will earn by participating actively in the Nitro Operating
            system (NOS), facilitating the hosting service and creating the
            private communication network infrastructure. Nesters will be
            required to keep Nitro Ion Miner active at all times in order to
            create wireless coverage and to respond to timely requests genearted
            by NOS. They can also take part in staking and other incentive
            programs to maximize their earning.
          </li>
        </ul>
      )
    },
    {
      label: "7. Do Nesters in specific locations have more benefits?",
      content: (
        <ul>
          <li>
            Nesting reward is based on location. We have categorized cities into
            a Tier structure based on certain parameters such as population,
            infrastructure, footfall etc. More precisely, cities are categorized
            into Tier 1(Iconic Tier), Tier 2(Premium Tier) and Tier 3(Top
            Location) in descending order. This means, if your current location
            falls in the Tier 1 category your rewards will be optimized as
            compared to Tier 2 or Tier 3 locations, simply by being in a prime
            location. There are rewards for Tier 2 or Tier 3 locations too! The
            tiers also earn based on the criteria of their locations. We have
            built the Tier structure in a way where everybody wins!
          </li>
        </ul>
      )
    },
    {
      label: "8. Can there be multiple Nesters in the same place?",
      content: (
        <ul>
          <li>No. There can be only 1 Nester in a radius of 1 km</li>
        </ul>
      )
    },
    {
      label: "9. How will I know my Tier?",
      content: (
        <ul>
          <li>
            All the locations from the cities across the globe will be
            categorized into different Tiers based on certain paramaters as
            decided by Nitro network. We will evaluate your location against
            these parameters and communicate the Tier in which your location
            falls when you get the nesting status.
          </li>
        </ul>
      )
    },
    {
      label: "10. What if I no longer want to be a Nester?",
      content: (
        <ul>
          <li>
            You can delist or choose to become an inactive nest. We will pick a
            different address from our queue and allocate the miner to a
            different address in the same defined radius.
          </li>
        </ul>
      )
    },
    {
      label:
        "11. I live in a rented premises. In case I change my address, can I still be a Nester?",
      content: (
        <ul>
          <li>
            The nesting status of your location is address dependent. Since
            there can only be one nester in a 1 km radius, we will evaluate the
            suitability of your new address against the Nitro parameters and
            communicate the same with you. If your new address falls in another
            radius, we will evaluate the availability of nesting slot for your
            new address. If the same is available, we will assign you the nest
            slot, else you will be in the waitlisted category for nesting in the
            area.
          </li>
        </ul>
      )
    }
  ]

  useEffect(() => {
    axios
      .get(`${BaseUrl}/product/get`)
      .then(response => {
        setAllproducts(response.data.data)
        if (response.status == 200) {
          setAllproducts(response.data.data)
        } else if (response.status == 401) {
          dispatch(updateUserDetailsRedux({}))
          dispatch(setConnectedWallet(""))
          localStorage.clear()
          navigate("/login")
        } else if (response.status == 404) {
          swal({
            title: "Error!",
            text: "Page not found. Please try again later.",
            icon: "error"
          })
        } else if (response.status == 500) {
          swal({
            title: "Error!",
            text: "Something went wrong. Please try again later.",
            icon: "error"
          })
        } else if (response.status == 503) {
          swal({
            title: "Error!",
            text: "Something went wrong. Please try again later.",
            icon: "error"
          })
        } else {
          swal({
            title: "Error!",
            text: "Something went wrong. Please try again later.",
            icon: "error"
          })
        }
      })
      .catch(error => {
        if (error?.response?.status === 401) {
          localStorage.clear()
          dispatch(updateUserDetailsRedux({}))
          navigate("/login")
        } else
          swal({
            title: "Error!",
            text: "Something went wrong. Please try again later.",
            icon: "error"
          })
      })
  }, [])

  const onviewproduct = id => {
    setViewProduct(true)
    // SetSelectedId(id);
    navigate("/productdetails", { state: { selectedId: id } })
  }
  const toLogin = () => {
    navigate("/login")
  }
  const toChangePage = item => {
    navigate(`/${item}`)
  }
  const activateTab = index => {
    activeTab === index ? SetActiveTab(-1) : SetActiveTab(index)
  }
  return (
    <div className="">
      {/* <GlobalStyles/> */}

      <section className="backgroundColordark ">
        <div className=" row  pt-md-4 d-flex justify-content-center">
          <div className="accordion w-100 remove-max-width" role="tablist">
            {panels.map((panel, index) => (
              <Panel
                key={index}
                activeTab={activeTab}
                index={index}
                {...panel}
                activateTab={activateTab.bind(null, index)}
              />
            ))}
          </div>
          {/* //------------------------------------------------------------------------------------------------------------ */}

          {/* <div className="accordion l-container">
                        <ul className="accordion-list">
                            <li className="accordion-item">
                                <div className="accordion-button"><span>1. What is Nesting?</span></div>
                                <div className="accordion-panel">
                                    <ul>
                                        <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Atque repellendus cupiditate corrupti, ratione odit velit obcaecati deleniti aspernatur! Explicabo eveniet nostrum numquam molestias delectus! Rem commodi dolor impedit assumenda dignissimos.</li>
                                    </ul>
                                </div>
                            </li>
                            <li className="accordion-item">
                                <div className="accordion-button"><span>2. Why Nesting?</span></div>
                                <div className="accordion-panel">
                                    <ul>
                                        <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Atque repellendus cupiditate corrupti, ratione odit velit obcaecati deleniti aspernatur! Explicabo eveniet nostrum numquam molestias delectus! Rem commodi dolor impedit assumenda dignissimos.</li>
                                    </ul>
                                </div>
                            </li>
                            <li className="accordion-item">
                                <div className="accordion-button"><span>3. Who is a Nester?</span></div>
                                <div className="accordion-panel">
                                    <ul>
                                        <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Atque repellendus cupiditate corrupti, ratione odit velit obcaecati deleniti aspernatur! Explicabo eveniet nostrum numquam molestias delectus! Rem commodi dolor impedit assumenda dignissimos.</li>
                                    </ul>
                                </div>
                            </li>
                            <li className="accordion-item">
                                <div className="accordion-button"><span>4. Why should you become a Nester?</span></div>
                                <div className="accordion-panel">
                                    <ul>
                                        <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Atque repellendus cupiditate corrupti, ratione odit velit obcaecati deleniti aspernatur! Explicabo eveniet nostrum numquam molestias delectus! Rem commodi dolor impedit assumenda dignissimos.</li>
                                    </ul>
                                </div>
                            </li>  <li className="accordion-item">
                                <div className="accordion-button"><span>5. What are the steps for becoming a Nester?</span></div>
                                <div className="accordion-panel">
                                    <ul>
                                        <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Atque repellendus cupiditate corrupti, ratione odit velit obcaecati deleniti aspernatur! Explicabo eveniet nostrum numquam molestias delectus! Rem commodi dolor impedit assumenda dignissimos.</li>
                                    </ul>
                                </div>
                            </li>  <li className="accordion-item">
                                <div className="accordion-button"><span>6. How will a Nester earn?</span></div>
                                <div className="accordion-panel">
                                    <ul>
                                        <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Atque repellendus cupiditate corrupti, ratione odit velit obcaecati deleniti aspernatur! Explicabo eveniet nostrum numquam molestias delectus! Rem commodi dolor impedit assumenda dignissimos.</li>
                                    </ul>
                                </div>
                            </li>  <li className="accordion-item">
                                <div className="accordion-button"><span>7. Do Nesters in specific locations have more benefits?</span></div>
                                <div className="accordion-panel">
                                    <ul>
                                        <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Atque repellendus cupiditate corrupti, ratione odit velit obcaecati deleniti aspernatur! Explicabo eveniet nostrum numquam molestias delectus! Rem commodi dolor impedit assumenda dignissimos.</li>
                                    </ul>
                                </div>
                            </li>  <li className="accordion-item">
                                <div className="accordion-button"><span>8. Can there be multiple Nesters in the same place?</span></div>
                                <div className="accordion-panel">
                                    <ul>
                                        <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Atque repellendus cupiditate corrupti, ratione odit velit obcaecati deleniti aspernatur! Explicabo eveniet nostrum numquam molestias delectus! Rem commodi dolor impedit assumenda dignissimos.</li>
                                    </ul>
                                </div>
                            </li>  <li className="accordion-item">
                                <div className="accordion-button"><span>9. How will I know my Tier?</span></div>
                                <div className="accordion-panel">
                                    <ul>
                                        <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Atque repellendus cupiditate corrupti, ratione odit velit obcaecati deleniti aspernatur! Explicabo eveniet nostrum numquam molestias delectus! Rem commodi dolor impedit assumenda dignissimos.</li>
                                    </ul>
                                </div>
                            </li>
                            <li className="accordion-item">
                                <div className="accordion-button"><span>10. What if I no longer want to be a Nester?</span></div>
                                <div className="accordion-panel">
                                    <ul>
                                        <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Atque repellendus cupiditate corrupti, ratione odit velit obcaecati deleniti aspernatur! Explicabo eveniet nostrum numquam molestias delectus! Rem commodi dolor impedit assumenda dignissimos.</li>
                                    </ul>
                                </div>
                            </li>
                            <li className="accordion-item">
                                <div className="accordion-button"><span>11. I live in a rented premises. In case I change my address, can I still be a Nester?</span></div>
                                <div className="accordion-panel">
                                    <ul>
                                        <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Atque repellendus cupiditate corrupti, ratione odit velit obcaecati deleniti aspernatur! Explicabo eveniet nostrum numquam molestias delectus! Rem commodi dolor impedit assumenda dignissimos.</li>
                                    </ul>
                                </div>
                            </li>
                        </ul>
        </div>
               

           
                    */}

          <div className="spacer-double"></div>

          {/* <ul className="pagination justify-content-center ">
                <li className="active"><span>1 - 20</span></li>
                <li><span>21 - 40</span></li>
                <li><span>41 - 60</span></li>
            </ul>  */}
        </div>
      </section>
    </div>
  )
}
export default HowItWorks

class Panel extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      height: 0
    }
  }

  componentDidMount() {
    window.setTimeout(() => {
      const el = ReactDOM.findDOMNode(this)
      const height = el.querySelector(".panel__inner").scrollHeight
      this.setState({
        height
      })
    }, 333)
  }

  render() {
    const { label, content, activeTab, index, activateTab } = this.props
    const { height } = this.state
    const isActive = activeTab === index
    const innerStyle = {
      height: `${isActive ? height : 0}px`
    }

    return (
      <div className="panel" role="tabpanel" aria-expanded={isActive}>
        <button className="panel__label" role="tab" onClick={activateTab}>
          {label}
        </button>
        <div
          className="panel__inner"
          style={innerStyle}
          aria-hidden={!isActive}>
          <p className="panel__content">{content}</p>
        </div>
      </div>
    )
  }
}

class Accordion extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      activeTab: 0
    }

    this.activateTab = this.activateTab.bind(this)
  }

  activateTab(index) {
    this.setState(prev => ({
      activeTab: prev.activeTab === index ? -1 : index
    }))
  }

  render() {
    const { panels } = this.props
    const { activeTab } = this.state
    return (
      <div className="accordion" role="tablist">
        {panels.map((panel, index) => (
          <Panel
            key={index}
            activeTab={activeTab}
            index={index}
            {...panel}
            activateTab={this.activateTab.bind(null, index)}
          />
        ))}
      </div>
    )
  }
}
