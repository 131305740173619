import React, { useState, useEffect } from "react"
import Footer from "../components/footer"
import { createGlobalStyle } from "styled-components"
import Menu from "./menu"
import { BaseUrl } from "../../utils/config"
import { HowItWorkstabs } from "../../Shared"
import { useNavigate, useParams } from "@reach/router"
import axios from "axios"
import PendingPayments from "./pendingPayments"
import MyCollectionTab from "./MyCollectionTab"
import Mynest from "./myNest"
import Activity from "./nitroActivity"
import HowitworksNest from "./howItWorksNest"
import HowitworksMiner from "./howItWworksMiner"
import HowitworksMarketplace from "./howItWorksMarket"
// Styles and Images
import "../../assets/css/pendingPayments.css"
import mineGlobally from "../../assets/images/mineglobalwhite.png"
import weHostYouMine from "../../assets/images/minerwhites.png"
import effortlessEarning from "../../assets/images/effortlesswhite.png"
import collectible from "../../assets/images/collectiblewts.png"
import Breakpoint, {
  BreakpointProvider,
  setDefaultBreakpoints
} from "react-socks"
import swal from "sweetalert"
import { updateUserDetailsRedux, setConnectedWallet } from "../../redux/actions"
import { useDispatch } from "react-redux"

const GlobalStyles = createGlobalStyle`
  header#myHeader.navbar.white {
    background: #212428;
  }
`

setDefaultBreakpoints([{ xs: 0 }, { l: 1099 }, { xl: 1200 }])

const HowItWorks = props => {
  const { tab: HowItWorksTab = "mycollection" } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [allproducts, setAllproducts] = useState([])
  const [paid, setPaid] = useState(false)
  const [error, setError] = useState(null)
  const paypalRef = React.useRef()
  // const history = useHistory();
  // const location = useLocation();
  const loginUserId = localStorage.getItem("loginUserId")
  const loginUserMail = localStorage.getItem("loginUserMail")
  const loginUserToken = localStorage.getItem("loginUserToken")

  useEffect(() => {
    updateBackground()
    // axios.get(`${BaseUrl}/mypurchase/${loginUserId}`, {
    //   headers: {
    //     // 'Content-Type': 'application/json',
    //     'Authorization': `Bearer ${loginUserToken}`,
    //   },
    // })
    //   .then((response) => {
    //     setAllproducts(response.data.data);
    //     if (response) {

    //       setPaid(!response.data.data.is_payment_completed)
    //       response.data.data.is_payment_completed ? setError(response.data.message) : setAllproducts(response.data.data[0]);

    //     }
    //   })
    //   .catch((error) => {
    // if (error?.response?.status === 401) {
    //   dispatch(updateUserDetailsRedux({}));
    //   dispatch(setConnectedWallet(""));
    //   localStorage.clear();
    //   navigate("/login");
    // }
    //   });
  }, [])

  const continuePay = () => {
    const fObj = {
      user_id: loginUserId,
      payer_email: loginUserMail,
      amount: allproducts?.pending_amount,
      product_id: allproducts?.id
    }
    axios
      .post(`${BaseUrl}/createcharge/${allproducts?.id}`, fObj, {
        headers: {
          // 'Content-Type': 'application/json',
          Authorization: `Bearer ${loginUserToken}`
        }
      })
      .then(response => {
        if (response) {
          window.open(response.data.data.host_url, "_blank")
        } else if (response.status == 401) {
          dispatch(updateUserDetailsRedux({}))
          dispatch(setConnectedWallet(""))
          localStorage.clear()
          navigate("/login")
        } else if (response.status == 404) {
          swal({
            title: "Error!",
            text: "Page not found. Please try again later.",
            icon: "error"
          })
        } else if (response.status == 500) {
          swal({
            title: "Error!",
            text: "Something went wrong. Please try again later.",
            icon: "error"
          })
        } else if (response.status == 503) {
          swal({
            title: "Error!",
            text: "Something went wrong. Please try again later.",
            icon: "error"
          })
        } else {
          swal({
            title: "Error!",
            text: "Something went wrong. Please try again later.",
            icon: "error"
          })
        }
      })
      .catch(error => {
        if (error?.response?.status === 401) {
          localStorage.clear()
          dispatch(updateUserDetailsRedux({}))
          navigate("/login")
        } else
          swal({
            title: "Error!",
            text: "Something went wrong. Please try again later.",
            icon: "error"
          })
      })
  }
  const onCancel = data => {
    // User pressed 'cancel' or closed the PayPal popup window
  }

  const onError = err => {
    // The main PayPal 's script can't be loaded
  }

  const tabClickHandler = value => {
    navigate("/howitworks/" + value)
  }

  let env = "sandbox" // you can set here to 'production' for production
  let currency = "USD" // or you can set this value from your props or state
  let total = 10 // same as above, this is the total amount (based on currency) to be
  let locale = "en_US"
  const client = {
    sandbox:
      "ATdVQH74kQnbWjjveWumaKL1bziq3XfAE5GzCFaCHqorLRz2ihxBb5FhBYxrnz405a_s8nae7FFO8ke9"
    // id:"AXOuInfLyUn3p8-Dn5MFQlMaldituFtqiQOZ7oW_XVegDK10NN4Ahq_GMm6ZErYGaT7C4L42F6in9Y1J"
  }

  const tabComponent = {
    howitworksnest: <HowitworksNest />,
    howitworksminer: <HowitworksMiner />,
    howitworksmarketplace: <HowitworksMarketplace />
  }

  return (
    <div>
      {/* <GlobalStyles/> */}
      <section
        className="jumbotron breadcumb no-bg"
        style={{
          minHeight: "unset"
        }}>
        <div className="mainbreadcumb">
          <div className="container">
            <div className="row m-10-hor">
              <div className="col-12">
                <h1 className="text-center">How It Works</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section className='backgroundColordark'>
        < Menu acteved={"pendingpayments"} />
        <div className="row ">
          <div className='spacer-double'></div>
          {paid ?
            <div className="tab-content mx alignCenter">
              <h2 >Check what's on with your payment and what isn't</h2>
              <div className="pending-page">

                <div className="summary">


                  <div className="collect-boxes">
                    <div className="details-mycollection-box">
                      <img src="https://img.icons8.com/pastel-glyph/60/7366F0/shopping-basket-2--v2.png" />
                      <div>
                        <h4>2</h4>
                        <p>Devices Purchased</p>
                      </div>
                    </div>
                    <div className="details-mycollection-box">
                      <img src="https://img.icons8.com/ios/60/7366F0/like--v1.png" />
                      <div>

                        <h4>$ {allproducts.total_amount}</h4>
                        <p>Total Amount</p>
                      </div>
                    </div>
                    <div className="details-mycollection-box">
                      <img src="https://img.icons8.com/ios/60/7366F0/paid--v1.png" />
                      <div>

                        <h4>$ {allproducts.payment_gross}</h4>
                        <p>Amount Paid </p>
                      </div>
                    </div>
                    <div className="details-mycollection-box">
                      <img src="https://img.icons8.com/external-kiranshastry-lineal-kiranshastry/60/7366F0/external-balance-advertising-kiranshastry-lineal-kiranshastry.png" />
                      <div>

                        <h4>$ {allproducts.pending_amount}</h4>
                        <p>Balance Amount</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="pay-summary" id="pay-summary">
                  <h1>USD {allproducts.pending_amount}</h1>

                  <div id='submit' onClick={continuePay} className="paybuttonOnPayments">
                    <input type='submit' id='send_message' value='Pay' className="btn btn-main color-2 mt-3" />

                  </div>
                </div>
              </div>
            </div> : <h2 className="alignCenter">{error}</h2>
          }
        </div>
      </section> */}
      <div className="no-bottom no-top" id="content">
        <div id="top"></div>
        <div className="container">
          <section id="nitro-nft-miner">
            {/* <Menu acteved={activeTab} menuItems={tabs} clickHandler={(newActiveTab) => {
              setActiveTab(newActiveTab);
              navigate(`/${newActiveTab}`);
            }} /> */}
            <div
              className="top-links hit-tab"
              style={{ overflowX: "auto", cursor: "default" }}>
              {HowItWorkstabs.map((tab, index) => (
                <div
                  className="box-det"
                  key={tab.value + index}
                  style={{ cursor: "pointer" }}
                  onClick={() => tabClickHandler(tab.value)}>
                  <div className="gradient-box">
                    <div
                      className={`field${
                        HowItWorksTab === tab.value ? " active" : ""
                      }`}>
                      <h5 className="border-field">{tab.label}</h5>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            {tabComponent[HowItWorksTab]}
          </section>
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  )
}
export default HowItWorks

function updateBackground() {
  let body = document.querySelector("body")
  body.style.backgroundImage = "unset"
  body.style.backgroundColor = "rgb(22, 29, 48)"
}

function addHoverListeners() {
  const imageBox = document.querySelector(".image-box")
  const headBox = document.querySelector(".head-box")
  const textBox = document.querySelector(".text-box")
  const fourth = document.querySelector(".fourth")
  const third = document.querySelector(".third")
  const second = document.querySelector(".second")
  const first = document.querySelector(".first")

  fourth.addEventListener("mouseover", function () {
    imageBox.src = collectible
    headBox.innerHTML = "Collectible"
    textBox.innerHTML = "Rewards are collectible into your account!"
  })
  third.addEventListener("mouseover", function () {
    imageBox.src = effortlessEarning
    headBox.innerHTML = "Effortless Earning"
    textBox.innerHTML = "You Dont have to work for that, thats effortless!"
  })
  second.addEventListener("mouseover", function () {
    imageBox.src = weHostYouMine
    headBox.innerHTML = "We Host You Mine"
    textBox.innerHTML = "We are hosting , Mining Reward you will receive"
  })
  first.addEventListener("mouseover", function () {
    imageBox.src = mineGlobally
    headBox.innerHTML = "Mine Globally"
    textBox.innerHTML =
      "Your NFT Miner is universal and borderless. Mine anywhere in the world"
  })
}

function removeHoverListeners() {
  const imageBox = document.querySelector(".image-box")
  const headBox = document.querySelector(".head-box")
  const textBox = document.querySelector(".text-box")
  const fourth = document.querySelector(".fourth")
  const third = document.querySelector(".third")
  const second = document.querySelector(".second")
  const first = document.querySelector(".first")

  fourth.removeEventListener("mouseover", function () {
    imageBox.src = "./images/collectiblewts.png"
    headBox.innerHTML = "Collectible"
    textBox.innerHTML = "Rewards are collectible into your account!"
  })
  third.removeEventListener("mouseover", function () {
    imageBox.src = "./images/effortlesswhite.png"
    headBox.innerHTML = "Effortless Earning"
    textBox.innerHTML = "You Dont have to work for that, thats effortless!"
  })
  second.removeEventListener("mouseover", function () {
    imageBox.src = "./images/minerwhites.png"
    headBox.innerHTML = "We Host You Mine"
    textBox.innerHTML = "We are hosting , Mining Reward you will receive"
  })
  first.removeEventListener("mouseover", function () {
    // first.firstElementChild.src = './images/mineglobal.png';
    imageBox.src = "./images/mineglobal.png"
    headBox.innerHTML = "Mine Globally"
    textBox.innerHTML =
      "Your NFT Miner is universal and borderless. Mine anywhere in the world"
  })
}
