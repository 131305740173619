import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import { Images } from "../../Shared"
import { IoTUrl } from "../../utils/config"
import axios from "axios"

const Refer = () => {
  const { loginUserId, loginUserToken } = useSelector(
    state => state.common.userDetails
  )
  const userDetails = useSelector(state => state.common.userDetails)

  const [copyValue, setcopyValue] = useState("")
  const [copyMessage, setcopyMessage] = useState("Copy Link")
  const [succesfulBuy, setSuccesfulBuy] = useState(0)
  const [creditEarned, setCreditEarned] = useState(0)

  const referLink = `${IoTUrl}?referralID=${userDetails?.referralId}`

  const handleCopyToClipboard = async e => {
    e.preventDefault()
    if ("clipboard" in navigator) {
      await navigator.clipboard.writeText(copyValue)
      setcopyMessage("Copied")
    } else {
      document.execCommand("copy", true, copyValue)
      setcopyMessage("Copied")
    }
  }

  const fetchUserRewards = async () => {
    await axios
      .get(
        `${process.env.REACT_APP_IOT_API_URL}/getRewardsEarnedByUser/${loginUserId}`,
        {
          headers: {
            // 'Content-Type': 'application/json',
            Authorization: `Bearer ${loginUserToken}`
          }
        }
      )
      .then(res => {
        if (res.status === 200) {
          setSuccesfulBuy(res?.data?.data?.purchases)
          setCreditEarned(parseInt(res?.data?.data?.totalRewards) || 0)
          // console.log("user rewards" + res?.data?.data)
        } else {
          throw new Error("Something went wrong")
        }
      })
      .catch(err => {})
  }

  useEffect(() => {
    if (copyMessage === "Copied") {
      setTimeout(() => {
        setcopyMessage("Copy Link")
      }, 5000)
    }
    setcopyValue(referLink)
  }, [copyMessage])

  useEffect(() => {
    fetchUserRewards()
  }, [])

  const sharingMessage = `Hi, I just invited you to start a smart lifestyle with IoT devices and earn crypto with Nitro Network!

  Step 1: Use my link to make a purchase at the IoT marketplace
  Step 2: Make a purchase of at least  $200 to receive a free gateway (worth more than $500)
  Step 3: Receive 2000 NCash for your every purchase. Start earning huge IoT adoption reward from smart devices
  
  Nitro Network is bringing real-world utility with IoT and creating a smarter world. It rewards you at every step! Join me in taking advantage of this opportunity by making your move now!  ${referLink}`

  const twitterMessage = `Join Nitro Network and earn NCash for a smart lifestyle! Use my link, make a purchase of $200+ & get a free gateway. Get 2000 NCash using my referral. Start earning rewards now!
  `
  const socialLinks = [
    // {
    //   icon: Images.googleSvg,
    //   title: "Import Contacts",
    //   url: "https://plus.google.com/share?url=https://nitro.network/token"
    // },
    {
      icon: Images.facebookSvg,
      title: "Facebook",
      url: `https://www.facebook.com/sharer/sharer.php?u=${referLink}&quote=${twitterMessage}`
    },
    {
      icon: Images.twitterSvg,
      title: "Twitter",
      url: `https://twitter.com/intent/tweet?url=${referLink}&text=${twitterMessage}&via=`
    },
    {
      icon: Images.linkedinSvg,
      title: "Linkedin",
      url: `https://www.linkedin.com/shareArticle?url=${referLink}&title=${twitterMessage}`
    },
    {
      icon: Images.emailSvg,
      title: "Email",
      url: `https://mail.google.com/mail/?view=cm&su=Refer%20and%20Earn&body=${sharingMessage}`
    },
    {
      icon: Images.whatsappSvg,
      title: "Whatsapp",
      // url: `https://api.whatsapp.com/send?text=${sharingMessage}`
      url: `https://wa.me/?text=Hi,%20I%20just%20invited%20you%20to%20start%20a%20smart%20lifestyle%20with%20IoT%20devices%20and%20earn%20crypto%20with%20Nitro%20Network!%0A%0A%20%20Step%201:%20Use%20my%20link%20to%20make%20a%20purchase%20at%20the%20IoT%20marketplace%0A%20%20Step%202:%20Make%20a%20purchase%20of%20at%20least%20%20$200%20to%20receive%20a%20free%20gateway%20(worth%20more%20than%20$500)%0A%20%20Step%203:%20Receive%202000%20NCash%20for%20your%20every%20purchase.%20Start%20earning%20huge%20IoT%20adoption%20reward%20from%20smart%20devices%0A%20%20%0A%20%20Nitro%20Network%20is%20bringing%20real-world%20utility%20with%20IoT%20and%20creating%20a%20smarter%20world.%20It%20rewards%20you%20at%20every%20step!%20Join%20me%20in%20taking%20advantage%20of%20this%20opportunity%20by%20making%20your%20move%20now!%20%0A${referLink}`
    }
  ]

  return (
    <div className="referral Referral-page">
      <div className="referral__box">
        <div className="referral__box--header">
          <div className="referral__box--header-text">
            <h2>Refer Friends & Earn NCash</h2>
            {/* <p>
              Introduce a friend to Nitro & you’ll be credited NCash 120,000 to
              your account
            </p> */}
          </div>

          <img
            src={Images.NCashCoinSmaller}
            className="referral__box--header-image"
            alt="Ncash Coin"
          />
        </div>

        <div className="referral__box--form">
          <p>Share my referral links with friends</p>

          <form>
            <input
              className="referral__box--input"
              type="text"
              name="refer-link"
              id="refer-link"
              //   placeholder="https://nitro.network/ncash-referrals"
              value={copyValue}
              disabled
            />
            <button
              className="referral__box--submit"
              onClick={handleCopyToClipboard}
              type="submit">
              {copyMessage}
            </button>
          </form>

          <div className="referral__social-links">
            {socialLinks.map(socialLink => {
              return (
                <a
                  href={socialLink.url}
                  target="_blank"
                  rel="nofollow noopener"
                  className="referral__social-link"
                  key={socialLink.title}>
                  <span className="referral__social-icon">
                    <img src={socialLink.icon} alt={socialLink.title} />
                  </span>
                  <span className="referral__social-link-title">
                    {socialLink.title}
                  </span>
                </a>
              )
            })}
          </div>
        </div>
      </div>

      <div className="referral__stats">
        {/* <div className="referral__stats--box">
          <div className="referral__stats--box-icon">
            <img src={Images.referSignup} alt="Refer Icon" />
            <span>Referral Signups</span>
          </div>
          <p className="big-text">54</p>
        </div> */}
        <div className="referral__stats--box">
          <div className="referral__stats--box-icon">
            <img src={Images.referBuy} alt="Refer Icon" />
            <span>Successful Buy</span>
          </div>
          <p className="big-text">{succesfulBuy}</p>
        </div>
        <div className="referral__stats--box">
          <div className="referral__stats--box-icon">
            <img src={Images.referCreditEarned} alt="Refer Icon" />
            <span>Credits Earned</span>
          </div>
          <p className="big-text">{creditEarned}</p>
        </div>
      </div>

      <div className="terms text-left">
        Please note: On successful purchase from your friend, ncash rewards will
        be credited to your account and your friends account and you can
        view/claim them under rewards section. Thank you.
      </div>
    </div>
  )
}

export default Refer
