import React, { useState, useEffect } from "react"
import "../../assets/css/modal.css"
import BigNumber from "bignumber.js"
import swal from "sweetalert"
import erc20Abi from "../../assets/blockchain/erc20Abi.json"
import nftAbi from "../../assets/blockchain/nftAbi.json"
import constant from "../../constants/constants"
import Web3 from "web3"
import Cookies from "universal-cookie"
import axios from "axios"
import { BaseUrl } from "../../utils/config"
import { useSelector } from "react-redux"
import { useNavigate } from "@reach/router"
import { useDispatch } from "react-redux"
import { updateUserDetailsRedux } from "../../redux/actions"
import { isFloat } from "../../utils"
import addCommas from "add-commas"

const cookies = new Cookies()
// eslint-disable-next-line react-hooks/exhaustive-deps
function Modal({
  showModal,
  toggleModalView,
  children,
  balance,
  deviceCount,
  setPayButtonClicked,
  paymentInProgress
}) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const userDetails = useSelector(state => state.common.userDetails)
  const pendingPayment = useSelector(state => state.common.pendingPayment)
  const [TNCAgreed, setTNCAgreed] = useState(false)
  const [promoCode, setPromoCode] = useState("")
  const [discountObj, setDiscountObj] = useState(null)

  const updateUserDetails = payload => dispatch(updateUserDetailsRedux(payload))
  const getInitialState = () => {
    const payToken = "0x0000000000000000000000000000000000000000" // Zero Address
    return payToken
  }

  let [payToken, setPayToken] = useState(getInitialState)
  let [isAlreadyApproved, setIsAlreadyApproved] = useState(false)
  let [isApprovedTxAvailable, setIsApprovedTxAvailable] = useState(false)
  let [isPayTxAvailable, setIsPayTxAvailable] = useState(false)
  let [approvedTx, setApprovedTx] = useState(null)
  let [payTx, setPayTx] = useState(null)
  let [web3, setWeb3] = useState(null)
  let [isWalletConnected, setWalletConnected] = useState(false)
  let [connectedWallet, setConnectedWallet] = useState(null)
  let [amountPaid, setAmountPaid] = useState(0)

  const loginUserToken = localStorage.getItem("loginUserToken")
  const loginUserMail = localStorage.getItem("loginUserMail")

  const approveNFTContract = async () => {
    if (isWalletConnected == true) {
      await connectToWallet()

      let web3 = new Web3(window.ethereum)
      let accounts = await web3.eth.getAccounts()

      console.log(
        "[nft][src][components][components][modal][approveNFTContract] Initiated"
      )
      console.log(
        "[nft][src][components][components][modal][approveNFTContract] Selected Token to Pay",
        payToken
      )

      let contractToApprove = constant.NFT_CONTRACT_ADDRESS
      let valueToApprove =
        "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff"

      let ERC20 = await new web3.eth.Contract(erc20Abi, payToken)

      try {
        await ERC20.methods
          .approve(contractToApprove, valueToApprove)
          .send({ from: accounts[0] })
          .on("transactionHash", function (hash) {
            setIsApprovedTxAvailable(true)
            console.log(
              "[nft][src][components][components][modal][approveNFTContract] Approve Tx Hash",
              hash
            )
            let txHash = `${constant.EXPLORER_URL}/tx/${hash}`
            setApprovedTx(txHash)
            waitTransactionReceipt(hash, 0, function (err, receipt) {
              if (err != null) {
                return
              }
            })
          })
          .on("error", function (error, receipt) {
            console.log(
              "[nft][src][components][components][modal][approveNFTContract] Approve Tx Error",
              error
            )
            swal({
              icon: "error",
              title: "Failed",
              text: "Transaction cancelled. Please try again!",
              showConfirmButton: true,
              showCancelButton: false,
              allowOutsideClick: true
            })
            setIsAlreadyApproved(false)
            return
          })
      } catch (error) {
        console.log(
          "[nft][src][components][components][modal][approveNFTContract] Catch Error",
          error
        )
        swal({
          icon: "error",
          title: "Failed",
          text: "Something went wrong. Please try again!",
          showConfirmButton: true,
          showCancelButton: false,
          allowOutsideClick: true
        })
        setIsAlreadyApproved(false)
      }
    } else {
      await getWeb3()
      await connectToWallet()
      swal({
        title: "Failed",
        text: `Please connect your wallet.`,
        icon: "error",
        confirmButtonText: "Ok"
      })
    }
  }

  const waitTransactionReceipt = async (
    transactionHash,
    actionType,
    timeOut,
    callback
  ) => {
    console.log(
      "[nft][src][components][components][modal][waitTransactionReceipt] transactionHash",
      transactionHash
    )
    var web3 = new Web3(window.ethereum)
    let self = this

    let timeOutCounter = 0
    let err = {
      message: ""
    }

    let isWaitRunning = false

    let getReceiptTimerId = setInterval(function () {
      if (isWaitRunning == false) {
        isWaitRunning = true
        web3.eth
          .getTransaction(transactionHash)
          .then(function (tx) {
            if (tx == null) {
              clearInterval(getReceiptTimerId)
              console.log(
                `[nft][src][components][components][modal][waitTransactionReceipt] transactionHash ${transactionHash} is not found`
              )
              err.message = "Transaction hash is not found."
              isWaitRunning = false
              if (callback) {
                callback(err, null)
              }
              return
            }

            if (tx.blockNumber == null) {
              if (timeOut > 0) {
                timeOutCounter++
                if (timeOutCounter >= timeOut) {
                  clearInterval(getReceiptTimerId)
                  isWaitRunning = false
                  err.message = "Timeout while waiting transaction to confirm."
                  if (callback) {
                    callback(err, null)
                  }
                  return
                } else {
                  console.log(
                    `[nft][src][components][components][modal][waitTransactionReceipt] transactionHash 1 ${transactionHash} is pending`
                  )
                  isWaitRunning = false
                  return
                }
              } else {
                console.log(
                  `[nft][src][components][components][modal][waitTransactionReceipt] transactionHash 2 ${transactionHash} is pending`
                )
                isWaitRunning = false
                return
              }
            }

            // Transaction confirm
            web3.eth
              .getTransactionReceipt(transactionHash)
              .then(async function (web3TxReceipt) {
                clearInterval(getReceiptTimerId)
                isWaitRunning = false
                if (web3TxReceipt == null) {
                  console.log(
                    `[nft][src][components][components][modal][waitTransactionReceipt] transactionHash 2 ${transactionHash} is not found`
                  )
                  swal({
                    icon: "error",
                    title: "Failed",
                    text: "Transaction Failed. Please try again!",
                    showConfirmButton: true,
                    showCancelButton: false,
                    allowOutsideClick: true
                  })
                  setIsAlreadyApproved(false)
                }
                console.log(
                  `[nft][src][components][components][modal][waitTransactionReceipt] Transaction receipt ${transactionHash}: ${web3TxReceipt}`
                )

                if (actionType == "approve") {
                  swal({
                    icon: "success",
                    title: "Approved",
                    text: "Transaction Successful",
                    showConfirmButton: true,
                    showCancelButton: false,
                    allowOutsideClick: true
                  })
                  setIsAlreadyApproved(true)
                } else if (actionType == "pay") {
                  //⚠️ ⚠️ ⚠️ Payment Update API to be done here! ⚠️ ⚠️ ⚠️//
                  axios
                    .post(
                      `${BaseUrl}/pendingpayment`,
                      {
                        tx_hash: transactionHash,
                        user_email: loginUserMail,
                        user_wallet: connectedWallet,
                        amount_paid: balance,
                        paid_token: payToken,
                        NFTs_count: deviceCount
                      },
                      {
                        headers: {
                          Authorization: `Bearer ${loginUserToken}`
                        }
                      }
                    )
                    .then(response => {
                      if (response) {
                        swal({
                          icon: "success",
                          title: "Success",
                          text: "Payment Successful",
                          showConfirmButton: true,
                          showCancelButton: false,
                          allowOutsideClick: true
                        }).then(() => {
                          setIsPayTxAvailable(true)
                          refreshPage()
                        })
                      }
                    })
                }
              })
              .catch(function (error) {
                if (error?.response?.status === 401) {
                  localStorage.clear()
                  updateUserDetails({})
                  navigate("/login")
                }
                clearInterval(getReceiptTimerId)
                isWaitRunning = false
                console.log(
                  `[nft][src][components][components][modal][waitTransactionReceipt] Error on transaction receipt 1 ${transactionHash}: ${error}`
                )
                swal({
                  icon: "error",
                  title: "Cancelled",
                  text: error.message,
                  showConfirmButton: true,
                  showCancelButton: false,
                  allowOutsideClick: true
                })
                setIsAlreadyApproved(false)
              })
          })
          .catch(function (error) {
            clearInterval(getReceiptTimerId)
            console.log(
              `[nft][src][components][components][modal][waitTransactionReceipt] Error on transaction receipt 2 ${transactionHash}: ${error}`
            )
            swal({
              icon: "error",
              title: "Cancelled",
              text: error.message,
              showConfirmButton: true,
              showCancelButton: false,
              allowOutsideClick: true
            })
            setIsAlreadyApproved(false)
          })
      }
    }, 1000)
  }

  const payNow = async () => {
    debugger
    console.log(`[nft][src][components][components][modal][payNow] Initiated`)
    console.log(
      `[nft][src][components][components][modal][payNow] Selected Token`,
      payToken
    )

    await connectToWallet()

    let web3 = new Web3(window.ethereum)
    let accounts = await web3.eth.getAccounts()
    console.log("accounts", accounts)

    let ERC20 = await new web3.eth.Contract(erc20Abi, payToken)
    let userBalance = await ERC20.methods.balanceOf(connectedWallet).call()
    console.log("userBalance", userBalance)
    let userBalanceBN = new BigNumber(userBalance)
    console.log(userBalanceBN, "userBalanceBN")

    let decimal = await ERC20.methods.decimals().call()
    console.log("decimal", decimal)
    debugger
    let decimalBN = new BigNumber(decimal)
    console.log("decimalBN", decimalBN)

    let baseBN = new BigNumber(10)
    console.log("baseBN", baseBN)
    let balanceToPay = new BigNumber(balance)
    console.log("balanceToPay", balanceToPay)
    let exponentiate = baseBN.exponentiatedBy(decimalBN)
    console.log("exponentiate", exponentiate)

    let balanceToPayBN = new BigNumber(balanceToPay).multipliedBy(exponentiate)
    console.log("balanceToPayBN", balanceToPayBN)

    let res = userBalanceBN.comparedTo(balanceToPayBN)
    console.log("res", res)
    debugger
    setAmountPaid(balance)

    if (res === -1) {
      swal({
        icon: "error",
        title: "Failed",
        text: "Insufficient Funds. Please topup your Balance to proceed for payment",
        showConfirmButton: true,
        showCancelButton: false,
        allowOutsideClick: true
      })
      return
    }

    try {
      let NitroNFT = await new web3.eth.Contract(
        nftAbi,
        constant.NFT_CONTRACT_ADDRESS
      )

      await NitroNFT.methods
        .payPending(payToken, balanceToPayBN.toString())
        .send({ from: accounts[0] })
        .on("transactionHash", function (hash) {
          console.log(
            `[nft][src][components][components][modal][payNow] transactionHash`,
            hash
          )
          setIsPayTxAvailable(true)
          let txHash = `${constant.EXPLORER_URL}/tx/${hash}`
          setPayTx(txHash)
          waitTransactionReceipt(hash, "pay", 0, function (err, receipt) {
            if (err != null) {
              return
            }
          })
        })
        .on("error", function (error, receipt) {
          console.log(
            `[nft][src][components][components][modal][payNow] transactionHash error 1`,
            error
          )
          swal({
            icon: "error",
            title: "Failed",
            text: "Transaction cancelled. Please try again!",
            showConfirmButton: true,
            showCancelButton: false,
            allowOutsideClick: true
          })
          return
        })
    } catch (error) {
      console.log(
        `[nft][src][components][components][modal][payNow] transactionHash error 2`,
        error
      )
      swal({
        icon: "error",
        title: "Failed",
        text: "Something went wrong. Please try again!",
        showConfirmButton: true,
        showCancelButton: false,
        allowOutsideClick: true
      })
    }
  }

  const handlePayTokenChange = e => {
    let selectedToken = e.target.value
    setPayToken(selectedToken)
    isNFTContractApproved(selectedToken)
    console.log(
      `[nft][src][components][components][modal][handlePayTokenChange] selectedToken`,
      selectedToken
    )
  }

  const isNFTContractApproved = async selectedToken => {
    console.log(
      `[nft][src][components][components][modal][isNFTContractApproved] selectedToken`,
      selectedToken
    )
    if (
      selectedToken != "null" ||
      selectedToken != "0x0000000000000000000000000000000000000000"
    ) {
      let erc20 = await new web3.eth.Contract(erc20Abi, selectedToken)

      // balance to Pay
      let balanceToPay = new BigNumber(balance)

      let isApproved = await erc20.methods
        .allowance(connectedWallet, constant.NFT_CONTRACT_ADDRESS)
        .call()

      let decimal = await erc20.methods.decimals().call()

      let baseBN = new BigNumber(10)
      let decimalBN = new BigNumber(decimal)

      let exponentiate = baseBN.exponentiatedBy(decimalBN)

      let isApprovedBN = new BigNumber(isApproved)
      let balanceToPayBN = new BigNumber(balanceToPay).multipliedBy(
        exponentiate
      )

      let res = isApprovedBN.comparedTo(balanceToPayBN)
      console.log(
        `[nft][src][components][components][modal][isNFTContractApproved] res`,
        res
      )

      if (res === 0 || res == 1) {
        setIsAlreadyApproved(true)
      } else if (res == -1) {
        setIsAlreadyApproved(false)
      }
      console.log(
        `[nft][src][components][components][modal][isNFTContractApproved] Is Approved`,
        isAlreadyApproved
      )
    } else {
      setIsAlreadyApproved(false)
    }
  }

  const getWeb3 = async () => {
    if (window.ethereum) {
      try {
        let web3 = new Web3(window.ethereum)
        let accounts = await web3.eth.getAccounts()
        let chainId = await web3.eth.getChainId()
        if (chainId !== parseInt(constant.CHAIN_ID)) {
          // swal({
          //   title: "Invalid Network!",
          //   text: `Switch your network to ${constant.NETWORK_NAME}`,
          //   icon: "error",
          //   confirmButtonText: "Ok",
          // });
          return
        }
        cookies.set("wallet", accounts[0], { path: "/" })
        cookies.set("wallet_status", 1, { path: "/" })
        setWalletConnected(true)
        setConnectedWallet(accounts[0])
        setWeb3(web3)
      } catch (error) {
        console.log(
          `[nft][src][components][components][modal][getWeb3] Web3 Connection Error`,
          error
        )
      }
    } else {
      cookies.set("wallet", null, { path: "/" })
      cookies.set("wallet_status", 0, { path: "/" })
      setWalletConnected(false)
      setConnectedWallet(null)
      setWeb3(null)
      // swal({
      //   title: "Unsupported Browser/Plugin not installed",
      //   text: `Please use Google Chrome with Metamask Plugin Installed`,
      //   icon: "error",
      //   confirmButtonText: "Ok",
      // });
      console.log(
        `[nft][src][components][components][modal][getWeb3] WUnsupported Browser/Plugin not installed`
      )
    }
  }

  const refreshPage = () => {
    window.location.reload()
  }

  const connectToWallet = async () => {
    console.log(
      `[nft][src][components][components][pages][pendingPayments][connectToWallet] connectToWallet`
    )
    if (typeof window.ethereum !== "undefined") {
      let accounts = await window.ethereum.request({
        method: "eth_requestAccounts"
      })
      window.web3 = new Web3(window.ethereum)
      setWeb3(window.web3)
      let chainId = await window.web3.eth.getChainId()
      if (chainId !== parseInt(constant.CHAIN_ID)) {
        // swal({
        //   title: "Invalid Network!",
        //   text: `Switch your network to ${constant.NETWORK_NAME}`,
        //   icon: "error",
        //   confirmButtonText: "Ok",
        // });
        return
      }
      cookies.set("wallet", accounts[0], { path: "/" })
      cookies.set("wallet_status", 1, { path: "/" })
      setWalletConnected(true)
      setConnectedWallet(accounts[0])
      setWeb3(window.web3)
    } else {
      cookies.set("wallet", null, { path: "/" })
      cookies.set("wallet_status", 0, { path: "/" })
      setWalletConnected(false)
      setConnectedWallet(null)
      setWeb3(null)
      swal({
        title: "Unsupported Browser/Plugin not installed",
        text: `Please use Google Chrome with Metamask Plugin Installed`,
        icon: "error",
        confirmButtonText: "Ok"
      })
      console.log("Unsupported Browser/Plugin not installed")
    }
  }

  useEffect(() => {
    getWeb3()
  }, [])

  const payWithCoinbase = () => {
    if (!TNCAgreed) {
      swal({
        text: "Please agree to terms and conditions",
        icon: "warning"
      })
      return
    }
    setPayButtonClicked(true)
    const apiObj = {
      user_id: userDetails?.loginUserId ?? "",
      payer_email: userDetails?.loginUserMail ?? "",
      amount: discountObj?.status
        ? balance - discountObj?.amount ?? 0
        : balance,
      tokens: Array.isArray(pendingPayment?.data)
        ? (pendingPayment?.data ?? []).map(orderItem => orderItem?.token ?? "")
        : [],
      promo_code: promoCode
    }

    !promoCode.length && delete apiObj.promo_code

    axios
      .post(`${BaseUrl}/createchargegroup/${pendingPayment?.token}`, apiObj, {
        headers: {
          // 'Content-Type': 'application/json',
          Authorization: `Bearer ${loginUserToken}`
        }
      })
      .then(response => {
        if (response?.data?.status) {
          window.open(response?.data?.data?.host_url, "_blank")
        }
      })
      .catch(error => {
        if (error?.response?.status === 401) {
          localStorage.clear()
          updateUserDetails({})
          navigate("/login")
        } else {
          swal({
            title: "Error!",
            text: "Something went wrong",
            icon: "error"
          })
        }
      })
  }

  const payWithFiat = () => {
    if (!TNCAgreed) {
      swal({
        text: "Please agree to terms and conditions",
        icon: "warning"
      })
      return
    }
    setPayButtonClicked(true)

    const apiObj = {
      user_id: userDetails?.loginUserId ?? "",
      payer_email: userDetails?.loginUserMail ?? "",
      amount: discountObj?.status
        ? balance - discountObj?.amount ?? 0
        : balance,
      tokens: Array.isArray(pendingPayment?.data)
        ? (pendingPayment?.data ?? []).map(orderItem => orderItem?.token ?? "")
        : [],
      promo_code: promoCode
    }

    !promoCode.length && delete apiObj.promo_code
    axios
      .post(
        `${BaseUrl}/createChargeGroupStripe/${pendingPayment?.token}`,
        apiObj,
        {
          headers: {
            // 'Content-Type': 'application/json',
            Authorization: `Bearer ${loginUserToken}`
          }
        }
      )
      .then(response => {
        if (response?.data?.status) {
          window.open(response?.data?.data?.host_url, "_blank")
        }
      })
      .catch(error => {
        if (error?.response?.status === 401) {
          localStorage.clear()
          updateUserDetails({})
          navigate("/login")
        } else {
          swal({
            title: "Error!",
            text: "Something went wrong",
            icon: "error"
          })
        }
      })
  }

  const applyPromoCode = () => {
    if (!promoCode.length) {
      swal({
        text: "Please enter valid promo code",
        icon: "warning"
      })
      return
    } else if (promoCode.includes(" ")) {
      swal({
        text: "Please remove space from promo code",
        icon: "warning"
      })
      return
    }

    let apiObj = {
      email: userDetails?.loginUserMail,
      promo_code: promoCode
    }

    axios
      .post(`${BaseUrl}/validatePromoCode`, apiObj, {
        headers: {
          Authorization: `Bearer ${loginUserToken}`
        }
      })
      .then(response => {
        setDiscountObj(response?.data)
      })
      .catch(error => {
        if (error?.response?.status === 401) {
          localStorage.clear()
          updateUserDetails({})
          navigate("/login")
          return
        } else setDiscountObj(error.response?.data)
      })
  }

  console.log(
    `[nft][src][components][components][modal][showModal] ${children} :: ${showModal} :: ${balance}`
  )

  return showModal ? (
    <>
      <div className="backdrop" onClick={toggleModalView}></div>

      <div className="modal-container">
        <div className="close-modal">
          <button className="close-modal" onClick={toggleModalView}>
            <i className="fa fa-times mb-0"></i>
          </button>
        </div>

        <div className="modal-content">
          {/* NEW CONTENT DESIGN POPUP */}
          <div className="one-section-payment pt-1">
            <div className="dfjb">
              <h5>Pay Now</h5>
              {/* <p>Please select the method to Pay</p> */}
              {/* TOTAL BALANCE */}
              <div>
                <p className="mb-0">BALANCE</p>
                <h4 className="mt-0 text-right">
                  ${!isFloat(balance) ? addCommas(balance || 0) : balance}
                </h4>
              </div>
            </div>
            {/* APPLY PROMO CODE SECTION */}
            <div className=" apply-promo">
              <input
                type="text"
                placeholder="Enter Promo Code"
                className="purple bordered-button"
                value={promoCode}
                onChange={e =>
                  setPromoCode(e.target.value.trim().toUpperCase())
                }
              />
              <button
                type="submit"
                id="pay-now-btn"
                className="bor  ered-button "
                onClick={applyPromoCode}>
                Apply
              </button>
            </div>
            <div className="text-center mt-2">
              <p className="mb-1">
                <small className="light-weight">
                  {discountObj?.message ?? ""}
                </small>
              </p>
              <p className="line-height1">
                <small className="light- mt-0 mb-0">
                  (Promo code discount is applicable ONLY on Premium and Top NFM
                  purchases)
                </small>
              </p>
            </div>
            {/* BILL DETAILS */}
            <div>
              <h4 className="mb-2">Bill Details</h4>
              <div className="dfjb">
                <p className="mb-0 light-weight">Total</p>
                <p className="mb-0">
                  ${!isFloat(balance) ? addCommas(balance || 0) : balance}
                </p>
              </div>
              <div className="dfjb">
                <p className="light-weight">Discount</p>
                <p>
                  -$
                  {!isFloat(discountObj?.amount ?? 0)
                    ? addCommas(discountObj?.amount ?? 0)
                    : discountObj?.amount ?? 0}
                </p>
              </div>
              <div className="dfjb borded-tb py-2">
                <p className="mb-0 light-weight">Total Amount</p>
                <p className="mb-0">
                  $
                  {!isFloat(balance - (discountObj?.amount ?? 0))
                    ? addCommas(balance - (discountObj?.amount ?? 0))
                    : balance - (discountObj?.amount ?? 0)}
                </p>
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-center mt-3">
              <input
                type="checkbox"
                checked={TNCAgreed}
                onChange={() => setTNCAgreed(prevState => !prevState)}
              />
              <p className="mb-0 ml-2">
                I agree to{" "}
                <a href="/terms" target="_blank">
                  terms & conditions
                </a>
              </p>
              {/* <p className="mb-0">Pay with Wallet</p>
              <p>Metasmask : Avalanche Network</p>
              <div className="text-center">
                <button className="gradient-button" id="pay-now-btn">
                  Connect to wallet
                </button>
              </div>
              <div className="two-btns">
                <button className="gradient-button bordered-button">
                  Configure Avalanche network
                </button>
                <button className="gradient-button bordered-button">
                  Add ncash token to metamask
                </button>
              </div> */}
            </div>
          </div>
          {/* PAY WITH CRYPTO */}
          {/* <h3>Bill Details</h3> */}

          {/* Pay with Coinbase */}
          {/* <div className="one-section-payment d-flex align-items-center justify-content-between">
            <div className="text-left">
              <h6 className="mb-1">Pay with Crypto</h6>
              <p>
                <small className="light-weight">
                  (BTC, BCH, ETH, DAI, DOGE, LTC
                </small>
                <br></br>
                <small className="light-weight">If paying using USDC,</small>
                <br></br>
                <small className="light-weight">
                  please send through ETH network only.)
                </small>
              </p>
            </div>
            <div className="">
              <button
                className="pay-now-small less-padding-button"
                id="pay-now-btn"
                style={
                  paymentInProgress ? { filter: "brightness(0.65)" } : null
                }
                onClick={!paymentInProgress ? payWithCoinbase : () => {}}>
                Pay Now
              </button>
            </div>
          </div> */}

          {/* PAY WITH FIAT */}
          {/* <div className="one-section-payment d-flex align-items-center justify-content-between">
            <div className="text-left">
              <h6 className="mb-1">Pay with Fiat</h6>
              <p>
                <small className="light-weight">
                  (Pay with Credit/Debit cards)
                </small>
              </p>
            </div>
            <div className="">
              <button
                className="pay-now-small less-padding-button"
                id="pay-now-btn"
                style={
                  paymentInProgress ? { filter: "brightness(0.65)" } : null
                }
                onClick={!paymentInProgress ? payWithFiat : () => {}}>
                Pay Now
              </button>
            </div>
          </div> */}

          {/* <div className="select-items">
            <select
              name="selct-token"
              value={payToken}
              onChange={handlePayTokenChange}
            >
              <option value="0x0000000000000000000000000000000000000000">
                - Select Token -
              </option>
              <option value={constant.USDC}>TUSDC</option>
              <option value={constant.DAI}>TDAI</option>
            </select>
          </div> */}
          {/* <div className="btn-modal">
            {isAlreadyApproved == false && (
              <span>
                {isApprovedTxAvailable == true && (
                  <span>
                    <button
                      className="approve-modal"
                      onClick={approveNFTContract}
                    >
                      Approve transaction pending...
                    </button>
                    <a href={approvedTx} target="_blank">
                      View Transaction
                    </a>
                  </span>
                )}
                {isApprovedTxAvailable == false && (
                  <span>
                    <button
                      disabled
                      className="approve-modal"
                      onClick={approveNFTContract}
                    >
                      Approve
                    </button>
                    <p>Please click on Approve to proceed</p>
                  </span>
                )}
              </span>
            )}
            {isAlreadyApproved == true && (
              <span>
                <button className="approve-modal">Approved</button>
                <p>Transaction Approved</p>
              </span>
            )}
          </div>
          <div className="btn-modal">
            {isAlreadyApproved == true && (
              <span>
                <button className="Pay-now-modal" onClick={payNow}>
                  Pay now
                </button>
                {isPayTxAvailable == true && (
                  <a href={payTx} target="_blank">
                    View Transaction
                  </a>
                )}
                {isPayTxAvailable == false && (
                  <p>Please click on Pay to proceed</p>
                )}
              </span>
            )}
            {isAlreadyApproved == false && (
              <span>
                <button className="Pay-now-modal">Pay now</button>
                <p>Please Approve to Pay</p>
              </span>
            )}
          </div> */}
        </div>

        {/* {children} */}
      </div>
    </>
  ) : null
}

export default Modal
