import { React } from "react"
import { Images } from "../../Shared/images"
import { useDispatch } from "react-redux"
import { setActiveMenu } from "../../redux/actions"

function KYCFormSuccess() {
  const dispatch = useDispatch()

  return (
    <div>
      <div className="thank-you-page mt-90pxx container">
        <img
          src={Images.tick}
          alt="Success Tick"
          style={{ margin: "0 auto 12vh" }}
        />

        <h1>KYC Submitted Successfully.</h1>

        <div>
          <p>
            We will come back to you upon review. Stay tuned for more updates.
          </p>
          <p>
            For more information on Nesting please click{" "}
            <a
              href="/myaccount/mynest"
              className="purple-color-text"
              onClick={() => dispatch(setActiveMenu("NFM"))}>
              here
            </a>
            .
          </p>
        </div>
      </div>
    </div>
  )
}

export default KYCFormSuccess
