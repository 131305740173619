import { useNavigate } from "@reach/router"
import React, { useState, useEffect, useRef } from "react"
import { HashLink as Link } from "react-router-hash-link"
import {
  BrowserRouter as Router,
  // Link,
  useHistory,
  useLocation
} from "react-router-dom"
import { BaseUrl } from "../../utils/config"
import { Pagination, Input } from "antd"

import axios from "axios"
import { useSelector, useDispatch } from "react-redux"

//widget
import Footer from "../components/footer"

// Styles
import "../../assets/css/marketplace.css"
import "./styles/newstyles.css"
// import "./styles/landing.css"

import { Images } from "../../Shared/images"
//
const { Search } = Input

function MarketPlace(props) {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const marketPlaceCards = [
    {
      title: "Iconic NFM",
      image: Images.iconicCardImage,
      imageAlt: "Iconic",
      description:
        "You’ll love the way they look and what they stand for. The Iconic NFM series represents top location/city tiers, which are high-reward hubs on the Nitro Network. They are also cool collectibles."
    },
    {
      title: "Premium NFM",
      image: Images.tier21,
      imageAlt: "Premium NFM",
      description:
        "These smoking hot Bots of the Premium NFM series represent Tier 2 locations and help you mine rewards on our network. And they are unique collectibles to have!"
    },
    {
      title: "Top NFM",
      image: Images.topCardImage,
      imageAlt: "Top NFM",
      description:
        "Inspired by Yoda, these baby Nitro Bots from Tier 3 locations are must-haves. They, too, come with rewards and are delightful collectibles."
    }
  ]

  const QnAs = [
    {
      image: "https://img.icons8.com/ios/50/ffffff/where-what-quest.png",
      imageAlt: "Wallet",
      question: "WHY ARE NITRO BOT NFMs VALUABLE?",
      answer:
        "Nitro Bots represent miners with which you can effortlessly earn rewards on the Nitro Network. With a Nitro Bot NFM, you can mine anywhere in the world from your own location. Each Nitro Bot NFM is a unique collectible with intrinsic value, so it’s worth holding/collecting them!"
    },
    {
      // image:'https://img.icons8.com/pastel-glyph/50/000000/nft-collection.png',
      image: "https://img.icons8.com/ios/50/ffffff/when-quest.png",
      imageAlt: "Upcoming",
      question: "HOW DO I LEARN ABOUT UPCOMING DROPS?",
      answer:
        "We keep our community constantly updated. Sign up to receive our emails and follow our social media channels so that you don’t miss out on any announcements."
    },
    {
      image: "https://img.icons8.com/pastel-glyph/55/ffffff/smartphone-nft.png",
      imageAlt: "What if not delivered",
      question: "HOW WILL I KNOW ABOUT ADDITIONS TO THE SERIES?",
      answer:
        "You can check out our marketplace to see the latest in the series and follow us on social media for updates. Rest assured: We will be adding more!"
    }
    // {
    // 	image: rewards,
    // 	imageAlt: 'What if not delivered',
    // 	question: 'Your NFTs represent rewards',
    // 	answer:
    // 		'Your NFTs represent the key value points for your digital mining rewards.',
    // },
  ]

  const myRef = useRef(null)

  const executeScroll = () => myRef.current.scrollIntoView()

  return (
    <div>
      <div className="landing-page">
        <div className="landing-main  marketplace-landing">
          <div className="text-btn">
            <div>
              <h1>Nitro Marketplace</h1>
              <p>
                With DeFi and NFTs catapulted into the mainstream, Nitro
                Network’s Non-Fungible Miners, or NFMs, have arrived at the
                perfect moment in blockchain history. NFMs were created from the
                synthesis of decentralized finance and non-fungible tokens, the
                two most influential spheres in the blockchain space. The NFM
                ecosystem, with its IoT solutions, harnesses the limitless
                energy of these exciting fields. And with its ethos of fair
                play, everyone who participates wins.
              </p>
            </div>

            <div className="buttons-landing-main">
              {/* <Link smooth to="/marketplace/#whatnfm" className="pre-order-btn">
               What Are NFMs?
             </Link> */}
              <a href="/buy-nfm" className="explore-btn">
                Purchase Miner
              </a>
            </div>
          </div>
          <div className="icon ">
            <a onClick={executeScroll}>
              <img
                src="https://img.icons8.com/ios-filled/44/7167e8/down--v2.png"
                className="slide-bottom"
              />
            </a>
          </div>
        </div>

        <div className="nft-section-landing " id="bottom" ref={myRef}>
          <div className="container">
            <div className="row">
              {marketPlaceCards.map((card, index) => (
                <a
                  href="/buy-nfm"
                  key={card.imageAlt + index}
                  className="nft-card-landing col-md-4">
                  <div className="title text-center">
                    <h2>{card.title}</h2>
                  </div>
                  <div className="nft-image-landing">
                    <img src={card.image} alt={card.imageAlt} />
                  </div>

                  <h5>{card.description}</h5>
                </a>
              ))}
            </div>
          </div>
        </div>

        <div className="text-center mt-4">
          <a href="/buy-nfm" className="explore-btn">
            Purchase Miner
          </a>
        </div>
        <br id="whatnfm" />
        <br />
        <div className="what-nfm-section  changebg">
          <h1 className="text-center">What's a Non-Fungible Miner?</h1>
          <div className="what-nfm container">
            <div className="what-nfm-text-side">
              <p>
                Nitro Network’s Non-Fungible Miners are a synthesis of digital
                miners and NFTs, making them one of the most original and
                innovative developments in the blockchain space. They are
                distinguished from traditional digital miners and NFTs by their
                distinctive, dynamic properties. In addition, NFMs are far more
                accessible and cost-effective than the mining protocols of
                legacy networks.
              </p>
            </div>
            <div className="what-nfm-image-side">
              <div className="image">
                <img src={Images.whatNfm} alt="#" />
              </div>
            </div>
          </div>
        </div>
        <div className="what-nfm-section container">
          <h1 className="text-center">How Non-Fungible Miner Rewards Work</h1>
          <div className="what-nfm reverse">
            <div className="what-nfm-text-side">
              <p>
                Nitro NFMs mine $NCash, with rewards paid out at the end of each
                30-minute EPOCH.
              </p>
              <p>
                It’s as simple as that. Earnings and rewards are relative to the
                tier you sign up for.
              </p>
            </div>
            <div className="what-nfm-image-side">
              <div className="image">
                <img src={Images.mining} alt="#" />
              </div>
            </div>
          </div>
          {/* <a href="#form" className="pre-order-btn green">
          Preorder Now{" "}
        </a> */}
          {/* <Link smooth to="/buyNitro/#form" className="pre-order-btn green">
          Preorder Now
        </Link> */}
        </div>
        <div className="third-section-landing background-2">
          <div className="container">
            <h1>OPEN PACKS AND FIND EXCITING NFMs</h1>

            <div className="row">
              {QnAs.map((qna, index) => (
                <div className="dropboxes" key={index}>
                  <img src={qna.image} alt={qna.imageAlt} />
                  <h3>{qna.question}</h3>
                  <p>{qna.answer}</p>
                </div>
              ))}
            </div>
          </div>
          <div style={{ margin: "auto" }}>
            <span style={{ color: "white", fontSize: "15px" }}>
              (Powered by Federal)
            </span>
          </div>
        </div>

        {/* <Footer /> */}
      </div>
    </div>
  )
}

export default MarketPlace
