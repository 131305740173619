import { useNavigate } from "@reach/router"
import { Form } from "antd"
import "antd/dist/antd.css"
import React from "react"
// import { useHistory } from "react-router-dom"
// import { createGlobalStyle } from "styled-components"
import "../styles/style.css"

// import ReCAPTCHA from "react-google-recaptcha";
const SignupSucess = () => {
  const navigate = useNavigate()
  // const history = useHistory()

  // useEffect(async () => {}, [])

  const toSignup = () => {
    navigate("/verifyEmail")
  }

  return (
    <div className="Login-page">
      <div className="Login-page__box">
        <Form
          name="contactForm"
          id="contact_form"
          className="form-border "
          action=""
          autoComplete="off">
          <h3>Verify your email address</h3>

          <div className="dfjb">
            <p className="color-primary">
              Before proceeding, please check your email for a verification
              link.
            </p>
          </div>

          <div id="submit">
            <input
              type="button"
              onClick={toSignup}
              id="send_message"
              value="Go back"
              className="btn-brand btn-active color-primary"
            />
          </div>
        </Form>
      </div>

      {/* <Footer /> */}
    </div>
  )
}
export default SignupSucess
