import React, { useRef, useState, useEffect } from "react"
import "mapbox-gl/dist/mapbox-gl.css"
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css"
import mapboxgl from "mapbox-gl"
import { mapStyleOptions } from "../../../../Shared"
import { useSelector, useDispatch } from "react-redux"
import { setStyleType } from "../../../../redux/actions"

mapboxgl.workerClass =
  // eslint-disable-next-line import/no-webpack-loader-syntax
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default

const MAPBOX_TOKEN =
  "pk.eyJ1IjoiYmFsamVldHJlYWN0IiwiYSI6ImNreW54ZmI2ZjNieGUybnFwNnd1aXRudTEifQ.a0Yo3o" +
  "2Cy1mID1653pidRA"
export default function Mapdisplay(props) {
  const dispatch = useDispatch()
  const mapContainer = useRef(null)
  const map = useRef(null)

  const style = useSelector(state => state.common.style)

  useEffect(() => {
    mapboxgl.accessToken = MAPBOX_TOKEN
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style,
      center: [props.Longitude, props.latitude],
      zoom: props.zoom
    })

    // to disable right click map rotation
    map.current.dragRotate.disable()
    // to disable touch/pinch zoom map rotation
    map.current.touchZoomRotate.disableRotation()

    // map.current.addControl(
    //   new mapboxgl.GeolocateControl({
    //     positionOptions: {
    //       enableHighAccuracy: true,
    //     },

    //     trackUserLocation: true,

    //     showUserHeading: true,
    //   }),
    //   "bottom-left"
    // );

    var popup = new mapboxgl.Popup({ closeOnClick: false })
      .setLngLat([props.Longitude, props.latitude])
      .setHTML("<h1>Hello World!</h1>")
      .addTo(map.current)

    const marker = new mapboxgl.Marker()
    fetch(
      "https://api.mapbox.com/geocoding/v5/mapbox.places/" +
        props.Longitude +
        "," +
        props.latitude +
        ".json?place_type=address&access_token=" +
        MAPBOX_TOKEN
    )
      .then(response => {
        if (response.status >= 400 && response.status < 600) {
          throw new Error("Bad response from server")
        }
        return response.json()
      })
      .then(returnedResponse => {
        popup.setText(returnedResponse.features[0].place_name)
      })
      .catch(error => {})
    marker.setLngLat([props.Longitude, props.latitude]).addTo(map.current)

    function add_marker(event) {
      var coordinates = event.lngLat
      fetch(
        "https://api.mapbox.com/geocoding/v5/mapbox.places/" +
          coordinates.lng +
          "," +
          coordinates.lat +
          ".json?place_type=address&access_token=" +
          MAPBOX_TOKEN
      )
        .then(response => {
          if (response.status >= 400 && response.status < 600) {
            throw new Error("Bad response from server")
          }
          return response.json()
        })
        .then(returnedResponse => {
          popup.setText(returnedResponse.features[0].place_name)
          props.getplacename(returnedResponse.features[0].place_name)
          props.getlat(coordinates.lat)
          props.getlng(coordinates.lng)
        })
        .catch(error => {})

      marker.setLngLat(coordinates).setPopup(popup).addTo(map.current)
    }
    map.current.on("click", add_marker)
    const nav = new mapboxgl.NavigationControl({ visualizePitch: true })
    map.current.addControl(nav, "bottom-right")

    map.current.on("style.load", function () {
      map.current.addSource("route", {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features: [
            {
              type: "Feature",
              geometry: {
                type: "Polygon",
                coordinates: [
                  [
                    [76.71286232030292, 30.715313947720986],
                    [76.71365648635333, 30.71525858902214],
                    [76.71412869319391, 30.714852624260615],
                    [76.71365648635333, 30.714225220812622],
                    [76.7130769597751, 30.71416986148941],
                    [76.71241157740889, 30.7146680942587],
                    [76.71286232030292, 30.715313947720986]
                  ]
                ]
              },
              properties: {}
            },
            {
              type: "Feature",
              properties: {},
              geometry: {
                type: "Polygon",
                coordinates: [
                  [
                    [76.71533067424366, 30.716919336160686],
                    [76.71608191239932, 30.716974693906238],
                    [76.71661851108132, 30.716476473053618],
                    [76.71599605660987, 30.715922891309418],
                    [76.71545945792639, 30.71588598574742],
                    [76.71494432319099, 30.71643956770282],
                    [76.71533067424366, 30.716919336160686]
                  ]
                ]
              }
            },
            {
              type: "Feature",
              properties: {},
              geometry: {
                type: "Polygon",
                coordinates: [
                  [
                    [76.71331306319541, 30.713099574985236],
                    [76.71436479661429, 30.71321029482985],
                    [76.71494432319099, 30.712545973857004],
                    [76.71440772450899, 30.711992369551794],
                    [76.71337745503826, 30.711826287640676],
                    [76.71286232030292, 30.712398346353112],
                    [76.71331306319541, 30.713099574985236]
                  ]
                ]
              }
            },
            {
              type: "Feature",
              properties: {},
              geometry: {
                type: "Polygon",
                coordinates: [
                  [
                    [76.70921344925915, 30.716734810113337],
                    [76.71000761530951, 30.717066956744844],
                    [76.71125252425395, 30.71693778874605],
                    [76.71071592557195, 30.716015155154366],
                    [76.71000761530951, 30.71568300490017],
                    [76.70893441794402, 30.715830627376874],
                    [76.70921344925915, 30.716734810113337]
                  ]
                ]
              }
            }
          ]
        }
      })

      map.current.addLayer({
        id: "route",
        type: "line",
        source: "route",
        layout: {
          "line-join": "round",
          "line-cap": "round"
        },
        paint: {
          "line-color": "#5fb335",
          "line-width": 2
        }
      })
    })
  }, [props.Longitude, props.latitude, props.zoom])

  useEffect(() => {
    map.current.setStyle(style)
  }, [style])

  const styleHandler = e => {
    dispatch(setStyleType(e.target.value))
  }

  return (
    <>
      <div ref={mapContainer} className="map-container" />
      <div onChange={styleHandler} className="style-container">
        {mapStyleOptions.map((styleObj, index) => (
          <span key={styleObj.id + index}>
            <input
              type="radio"
              id={styleObj.id}
              value={styleObj.value}
              checked={styleObj.value === style}
            />
            <label htmlFor={styleObj.id}>{styleObj.label}</label>
          </span>
        ))}
      </div>
    </>
  )
}
