import React from "react"
import "../../assets/css/customModal.css"

function CustomModal({ showModal, toggleModalView, children }) {
  return showModal ? (
    <>
      <div className="backdrop" onClick={toggleModalView}></div>

      <div className="custom-modal-container">
        <div className="close-modal">
          <button
            className="close-modal"
            onClick={toggleModalView}
            style={{ border: "none" }}>
            <i style={{ color: "black" }} className="fa fa-times mb-0"></i>
          </button>
        </div>

        <div className="modal-content" style={{ fontWeight: "500" }}>
          {children}
        </div>
      </div>
    </>
  ) : null
}

export default CustomModal
