function Dropdown({
  name = "",
  id = "",
  className = "",
  value = "",
  options = [],
  handleChange = () => {}
}) {
  return (
    <>
      <select
        className={className}
        name={name}
        id={id}
        value={value}
        onChange={handleChange}>
        {options.map((option, index) => (
          <option key={String(option.value) + index} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </>
  )
}

export default Dropdown
