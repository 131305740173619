import logoBlack from "../assets/images/logoDark.png"
import nfmImage from "../assets/images/nfm.jpeg"
import arrowImage from "../assets/images/arrow.png"
import whatNfm from "../assets/images/whatnfm.jpeg"
import howNfm from "../assets/images/mining.jpg"
import backImages from "../assets/images/back.jpeg"
import firstnft from "../components/pages/images/iconic.jpeg"
import secondnft from "../components/pages/images/premium.jpeg"
import thirdnft from "../components/pages/images/toplocation.jpeg"
import mining from "../components/pages/images/mining.jpg"
import sold from "../assets/images/sold.jpeg"
import Logo from "../assets/images/Logo.png"
import GlowLine from "../assets/images/Glow.png"
import staking from "../assets/images/staking.jpg"
import swapping from "../assets/images/swapping.jpg"
import iconicCardImage from "../assets/images/Iconic.png"
import topCardImage from "../assets/images/Top.png"
import forwardArrowIcon from "../assets/images/forwardArrow.svg"
import backwardArrowIcon from "../assets/images/backwardArrow.svg"
import tick from "../assets/images/tick.svg"
import nitroLogo from "../components/pages/images/images/LOGO2.png"
import homeIcon from "../components/pages/images/images/icons/Home.png"
import nfmIcon from "../components/pages/images/images/icons/nfm.png"
import pendingpaymentIcon from "../components/pages/images/images/icons/pendingpayment.png"
import myActivityIcon from "../components/pages/images/images/icons/myactivity.png"
import myCollectionIcon from "../components/pages/images/images/icons/mycollection.png"
import cartIcon from "../components/pages/images/images/icons/cart.png"
import myNestIcon from "../components/pages/images/images/icons/mynest.png"
// import myRewardIcon from "../components/pages/images/images/icons/myreward.png"
import logoutIcon from "../components/pages/images/images/icons/Login.png"
import deviceManageIcon from "../components/pages/images/images/icons/deviceManagementIcon.png"
import emiIcon from "../components/pages/images/images/icons/Emi.png"
import referIcon from "../components/pages/images/images/icons/referIcon.png"
import referSignup from "../components/pages/images/images/icons/referIcons/ReferralSignups.png"
import referBuy from "../components/pages/images/images/icons/referIcons/SuccessfulBuy.png"
import referCreditEarned from "../components/pages/images/images/icons/referIcons/CreditsEarned.png"
import facebookSvg from "../components/pages/images/images/icons/referIcons/FacebookFill.svg"
import googleSvg from "../components/pages/images/images/icons/referIcons/GoogleFill.svg"
import linkedinSvg from "../components/pages/images/images/icons/referIcons/Linkedinfill.svg"
import whatsappSvg from "../components/pages/images/images/icons/referIcons/WhatsappFill.svg"
import emailSvg from "../components/pages/images/images/icons/referIcons/EmailFill.svg"
import outlookSvg from "../components/pages/images/images/icons/referIcons/OutlookFill.svg"
import twitterSvg from "../components/pages/images/images/icons/referIcons/TwitterFill.svg"
import myRewardIcon from "../components/pages/images/images/icons/referIcons/myRewards.png"

// Tier
import iconic from "../components/pages/images/images/iconic.jpeg"
import premium from "../components/pages/images/images/premium.jpeg"
import top from "../components/pages/images/images/top.jpeg"

import iconicMarketplace from "../components/pages/images/marketplace/iconic.png"
import premiumMarketplace from "../components/pages/images/marketplace/premium.png"
import topMarketplace from "../components/pages/images/marketplace/top.png"
import bannerMarketplace from "../components/pages/images/marketplace/banner.png"
import banner2Marketplace from "../components/pages/images/marketplace/banner2.png"

// User
import user from "../components/pages/images/images/user.jpg"
import newCollectionGif from "../components/pages/images/new-collection.gif"

// Sold NFM Images
import tier11 from "../assets/images/NFMs/tier11.png"
import tier12 from "../assets/images/NFMs/tier12.png"
import tier13 from "../assets/images/NFMs/tier13.png"
import tier21 from "../assets/images/NFMs/tier21.png"
import tier22 from "../assets/images/NFMs/tier22.png"
import tier23 from "../assets/images/NFMs/tier23.png"
import tier24 from "../assets/images/NFMs/tier24.png"
import tier25 from "../assets/images/NFMs/tier25.png"
import tier26 from "../assets/images/NFMs/tier26.png"
import tier27 from "../assets/images/NFMs/tier27.png"
import tier31 from "../assets/images/NFMs/tier31.png"
import tier32 from "../assets/images/NFMs/tier32.png"
import tier33 from "../assets/images/NFMs/tier33.png"

// Social Icons fb, insta, twitch, twitter, medium, discord
import facebook from "../assets/images/facebook.png"
import instagram from "../assets/images/instagram.png"
import twitch from "../assets/images/twitch.png"
import twitter from "../assets/images/twitter.png"
import discord from "../assets/images/discord.png"
import medium from "../assets/images/medium.png"

// Logos Media partners
import coinTelegraph from "../assets/images/cointelegraph.png"
import investorObserver from "../assets/images/investorObserver.png"
import yahooFinance from "../assets/images/yahooFinance.png"
import topBanner from "../assets/images/top_banner.jpeg"
import HexMapSidebarBackground from "../assets/images/HexMapSidebarBackground.png"

//homepage
// import offer1 from "../components/pages/images/home/offer1.png"
// import offer2 from "../components/pages/images/home/offer2.png"
// import offer3 from "../components/pages/images/home/offer3.png"
// import ourTechStack from "../components/pages/images/home/ourTechStack.png"
// import techStackMobile from "../components/pages/images/home/techStackMobile.png"
// import bot1 from "../components/pages/images/home/bot1.png"
// import bot2 from "../components/pages/images/home/bot2.png"
// import bot3 from "../components/pages/images/home/bot3.png"
// import bot4 from "../components/pages/images/home/bot4.png"
import litepaper from "../components/pages/images/home/Lite-paper.png"
import whitepaper from "../components/pages/images/home/White-paper.png"
import niotLitepaper from "../components/pages/images/home/niot-litepaper.png"
import nitrodoc from "../components/pages/images/home/Nitro-doc.png"
import iotWhitepaper from "../components/pages/images/home/iotWhitepaper.png"
// import ncashCircle from "../components/pages/images/home/ncashCircle.png"
import contactSvg from "../components/pages/images/home/contactSvg.webp"
import slide1 from "../components/pages/images/home/slides/slide1.png"
import slide2 from "../components/pages/images/home/slides/slide2.png"
import slide3 from "../components/pages/images/home/slides/slide3.png"
import slide4 from "../components/pages/images/home/slides/slide4.jpg"
import avalance from "../components/pages/images/home/Logos/avalance.png"
import pangolin from "../components/pages/images/home/Logos/pangolin.png"
import beefy from "../components/pages/images/home/Logos/beefy.png"
import redlight from "../components/pages/images/home/Logos/redlight.png"
import techm from "../components/pages/images/home/Logos/techm.png"
import cointelegraph2 from "../components/pages/images/home/Logos/cointelegraph.png"
import ibc from "../components/pages/images/home/Logos/ibc.png"
import investerobserver from "../components/pages/images/home/Logos/investerobserver.png"
import yahoo from "../components/pages/images/home/Logos/yahoo.png"
import tektelic from "../components/pages/images/home/Logos/tektelic.png"
import milesight from "../components/pages/images/home/Logos/milesight.png"
import dragino from "../components/pages/images/home/Logos/dragino.png"
import falconx from "../components/pages/images/home/Logos/falconx.png"
import amantya from "../components/pages/images/home/Logos/amantya.png"
import alliot from "../components/pages/images/home/Logos/alliot.png"
import enthu from "../components/pages/images/home/Logos/enthu.png"
// import huobiglobal from "../components/pages/images/home/Logos/huobiglobal.png"
import playIcon from "../components/pages/images/home/Logos/play-icon.png"
import leftArrow from "../components/pages/images/home/Logos/Left.png"
import rightArrow from "../components/pages/images/home/Logos/Right.png"
import openSea from "../components/pages/images/home/Logos/opensea.png"
// [Home - Worlds]
import theMisson from "../components/pages/images/home/theMission.webp"
import hero from "../components/pages/images/home/hero.webp"
import nitronft from "../components/pages/images/home/products/nitronft.png"
import nftMarketplace from "../components/pages/images/home/products/nft-marketplace.png"
import iotMarketplace from "../components/pages/images/home/products/iot-marketplace.png"
import enterprise from "../components/pages/images/home/products/enterprise.png"
import burn from "../components/pages/images/home/products/burn.png"
import emiNitro from "../components/pages/images/home/products/emi.png"
import ncashNitro from "../components/pages/images/home/products/ncash.png"
import genesisNode from "../components/pages/images/home/products/genesis-node.png"
import nitroAnalytics from "../components/pages/images/home/products/nitro-analytics.png"
import nitroIon from "../components/pages/images/home/products/nitro-ion.png"
import poolandfarm from "../components/pages/images/home/products/poolandfarm.png"
import stakingNitro from "../components/pages/images/home/products/staking.png"
import swappingNitro from "../components/pages/images/home/products/swapping.png"
// [Home - Possibilites]
import iot_home from "../components/pages/images/home/transform/home.png"
import iot_office from "../components/pages/images/home/transform/office.png"
import iot_security from "../components/pages/images/home/transform/security.png"
import iot_waste from "../components/pages/images/home/transform/waste.webp"
import iot_watertank from "../components/pages/images/home/transform/watertank.webp"
import iot_coldStore from "../components/pages/images/home/transform/coldStore.png"
import iot_pool from "../components/pages/images/home/transform/pool.png"

//Gateway aPage
import qrbanner from "../components/pages/images/gateway/qrbanner.png"
import setup1 from "../components/pages/images/gateway/setup1.png"
import setup2 from "../components/pages/images/gateway/setup2.png"
import setup3 from "../components/pages/images/gateway/setup3.png"
import facebookGateway from "../components/pages/images/gateway/facebook.png"
import twitterGateway from "../components/pages/images/gateway/twitter.png"
import discordGateway from "../components/pages/images/gateway/discord.png"
import linkedinGateway from "../components/pages/images/gateway/linkedin.png"
import mediumGateway from "../components/pages/images/gateway/medium.png"
// BURN PAGE
import CoinNcash from "../components/pages/images/burn/header/coinLight.png"

// Ncash PAGE
import Chart from "../components/pages/images/ncash/chartLight.png"
import MintCycle from "../components/pages/images/ncash/mintCycleLight.png"
import NcashUtility from "../components/pages/images/ncash/ncashUtilityLight.png"
import NcashUtilityLarge from "../components/pages/images/ncash/ncashUtilityLight.png"
import NCashCoin from "../components/pages/images/ncash/header/Ncash-small.png"
import NCashCoinLarge from "../components/pages/images/ncash/header/Ncash-large.png"
import NCashCoinSmaller from "../components/pages/images/ncash/header/Ncash-smaller.png"
import quick1 from "../components/pages/images/ncash/icons/quick1s.svg"
import quick2 from "../components/pages/images/ncash/icons/quick2s.svg"
import quick3 from "../components/pages/images/ncash/icons/quick3s.svg"
import quick4 from "../components/pages/images/ncash/icons/quick4s.svg"
import huobi from "../components/pages/images/ncash/icons/huobi.png"
import pangolinNcash from "../components/pages/images/ncash/icons/pangolin.png"
import medium1 from "../components/pages/images/ncash/icons/medium1.png"
import medium2 from "../components/pages/images/ncash/icons/medium2.png"
import medium3 from "../components/pages/images/ncash/icons/medium3.png"
import mintBanner from "../components/pages/images/mint-banner.png"

//BLogs
import nftBlog from "../components/pages/images/blogs/nft.png"
import ncashBlog from "../components/pages/images/blogs/ncash.png"
import amaBlog from "../components/pages/images/blogs/ama.png"
import iotBlog from "../components/pages/images/blogs/iot.png"

export const Images = {
  logoBlack,
  nfmImage,
  arrowImage,
  whatNfm,
  howNfm,
  backImages,
  thirdnft,
  secondnft,
  firstnft,
  mining,
  sold,
  staking,
  swapping,
  iconicCardImage,
  topCardImage,
  tick,
  HexMapSidebarBackground,

  //sold images
  tier11,
  tier12,
  tier13,
  tier21,
  tier22,
  tier23,
  tier24,
  tier25,
  tier26,
  tier27,
  tier31,
  tier32,
  tier33,
  Logo,
  GlowLine,
  forwardArrowIcon,
  backwardArrowIcon,
  //social icons
  facebook,
  instagram,
  twitch,
  twitter,
  medium,
  discord,

  //Logos
  coinTelegraph,
  investorObserver,
  yahooFinance,
  topBanner,
  nitroLogo,
  // Icons
  homeIcon,
  pendingpaymentIcon,
  myActivityIcon,
  myCollectionIcon,
  cartIcon,
  myNestIcon,
  myRewardIcon,
  logoutIcon,
  deviceManageIcon,
  user,
  emiIcon,
  nfmIcon,
  referIcon,
  referBuy,
  referCreditEarned,
  referSignup,
  facebookSvg,
  googleSvg,
  linkedinSvg,
  whatsappSvg,
  emailSvg,
  outlookSvg,
  twitterSvg,
  // Tier
  iconic,
  premium,
  top,
  // MArketplace
  iconicMarketplace,
  premiumMarketplace,
  topMarketplace,
  bannerMarketplace,
  banner2Marketplace,

  //homepage
  // offer1,
  // offer2,
  // offer3,
  // ourTechStack,
  // techStackMobile,
  // bot1,
  // bot2,
  // bot3,
  // bot4,
  litepaper,
  whitepaper,
  nitrodoc,
  iotWhitepaper,
  avalance,
  beefy,
  redlight,
  pangolin,
  techm,
  cointelegraph2,
  // ncashCircle,
  ibc,
  investerobserver,
  yahoo,
  tektelic,
  milesight,
  dragino,
  falconx,
  amantya,
  alliot,
  enthu,
  contactSvg,
  slide1,
  slide2,
  slide3,
  slide4,
  playIcon,
  openSea,
  // [Home - worlds]
  theMisson,
  hero,
  nitronft,
  nftMarketplace,
  iotMarketplace,
  enterprise,
  burn,
  emiNitro,
  ncashNitro,
  genesisNode,
  nitroAnalytics,
  nitroIon,
  poolandfarm,
  stakingNitro,
  swappingNitro,
  // [Home - Possibilities]
  iot_coldStore,
  iot_home,
  iot_office,
  iot_security,
  iot_waste,
  iot_watertank,
  iot_pool,

  // BURN
  CoinNcash,
  // NCASH

  NCashCoin,
  NCashCoinLarge,
  NCashCoinSmaller,
  quick1,
  quick2,
  quick3,
  quick4,
  Chart,
  NcashUtility,
  NcashUtilityLarge,
  huobi,
  pangolinNcash,
  MintCycle,
  medium1,
  medium2,
  medium3,
  leftArrow,
  rightArrow,
  niotLitepaper,
  //Gateway
  qrbanner,
  setup1,
  setup2,
  setup3,
  facebookGateway,
  twitterGateway,
  discordGateway,
  linkedinGateway,
  mediumGateway,
  newCollectionGif,

  //blog
  nftBlog,
  ncashBlog,
  iotBlog,
  amaBlog,
  mintBanner
}
