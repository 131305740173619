import React from "react"
import { useDispatch, useSelector } from "react-redux"
import axios from "axios"
import { IoTAPIUrl } from "../../../utils/config"
import swal from "sweetalert"
import { useLogout } from "../../../hooks"
import { startLoader, stopLoader } from "../../../redux/actions"

function PriceDetails({
  country = null,
  orderSummary = null,
  requestOptions = null,
  getOrderSummary = () => {}
}) {
  const logoutHandler = useLogout()
  const dispatch = useDispatch()

  const userDetails = useSelector(state => state.common.userDetails)

  const removeKit = async orderId => {
    try {
      const response = await axios.delete(
        `${IoTAPIUrl}/deleteKit/${userDetails?.loginUserId}/${orderId}`,
        requestOptions
      )
      if (response.status === 200) {
        getOrderSummary()
        swal({
          title: "Success!",
          text: response.data.message,
          icon: "success"
        })
      } else {
        throw new Error(response.data.message)
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        logoutHandler()
      } else {
        swal({
          title: "Error!",
          text: error.response.data.message,
          icon: "error"
        })
      }
    }
  }

  const updateNumberOfKits = async (opType, orderId, numberOfKits) => {
    if (numberOfKits === 1 && opType === "de") {
      removeKit(orderId)
      return
    }
    try {
      const requestData = {
        numberOfKits: numberOfKits + (opType === "de" ? -1 : 1)
      }
      dispatch(startLoader())
      const response = await axios.put(
        `${IoTAPIUrl}/updateOrderNumberOfKits/${userDetails?.loginUserId}/${orderId}`,
        requestData,
        requestOptions
      )
      if (response.status === 200) {
        getOrderSummary()
      } else {
        throw new Error(response.data.message)
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        logoutHandler()
      } else {
        swal({
          title: "Error!",
          text: error.response.data.message,
          icon: "error"
        })
      }
    } finally {
      dispatch(stopLoader())
    }
  }

  // console.log(orderSummary,'ordersummary')

  return (
    <>
      {orderSummary && orderSummary?.orderItems?.length ? (
        orderSummary.orderItems.map((orderItem, i) => (
          <React.Fragment key={orderItem?.orderId}>
            <div className="card-ship">
              <div className="row bb-head">
                <div className="col-lg-12 col-md-6">
                  <p className=" fl-lt">
                    <b>{orderItem?.kitName}</b>
                  </p>
                  <h4 className=" fl-rt">
                    <b>
                      {country?.currencySymbol ?? "$"}
                      {(orderItem?.kitPrice ?? 0)?.toFixed(2)}
                    </b>
                  </h4>
                </div>
              </div>
              <div className="row pt-2">
                <div className="col-md-4">
                  <img
                    src={orderItem?.kitImage ?? ""}
                    className="fl-lt"
                    style={{ height: "auto" }}
                    alt={orderItem?.kitName}
                  />
                </div>
                <div className="col-md-8 mb-4">
                  <div className="fl-lt d-flex flex-column h-100">
                    {/* Items Lists */}
                    <ul className="list-unstyled text-sm  pt-2 mb-0">
                      {(orderItem?.products ?? []).map((product, j) => (
                        <li key={product?.sensorId} className="pt-2 font-root">
                          <span className="text-sm">
                            {product?.quantity} x {product?.name}
                          </span>
                        </li>
                      ))}
                    </ul>

                    {/* Counter */}
                    <div className="mt-auto pt-4 max-width-120px d-flex justify-content-between align-items-center counter">
                      <button
                        type="button"
                        className="iot-counter-button color-primary text-lg minus"
                        onClick={() =>
                          updateNumberOfKits(
                            "de",
                            orderItem?.orderId,
                            orderItem?.numberOfKits
                          )
                        }>
                        <b>-</b>
                      </button>
                      <p className=" text-m  mb-0 px-3">
                        {orderItem?.numberOfKits}
                      </p>
                      <button
                        type="button"
                        className="iot-counter-button color-primary   text-lg plus"
                        onClick={() =>
                          updateNumberOfKits(
                            "in",
                            orderItem?.orderId,
                            orderItem?.numberOfKits
                          )
                        }>
                        <b>+</b>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 "></div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="fl-rt">
                    <button
                      className="btn-brand btn-transparent px-05"
                      type="button"
                      onClick={() => removeKit(orderItem?.orderId)}>
                      Remove
                    </button>
                    {/* &nbsp;
                    <button
                      className="btn btn-blue py-2 px-3"
                      type="submit"
                      onclick="openWin()"
                    >
                      Edit Kit
                    </button> */}
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        ))
      ) : (
        <div className="card-ship">
          <div className="row">
            <h2>
              {!country
                ? "Please choose the country"
                : !orderSummary
                ? "No Orders Found"
                : ""}
            </h2>
          </div>
        </div>
      )}
    </>
  )
}

export default PriceDetails
