import { combineReducers } from "redux"
import CommonReducer from "./common"
import CollectionReducer from "./collection"
import MarketplaceReducer from "./marketplace"
import WordPressReducer from "./wordpress"

const RootReducer = combineReducers({
  common: CommonReducer,
  collection: CollectionReducer,
  marketplace: MarketplaceReducer,
  wordpress: WordPressReducer,
})

export default RootReducer
