import React from "react"
import ReactDOM from "react-dom"
// import "./assets/animated.css"
// import "../node_modules/elegant-icons/style.css"
// import "../node_modules/et-line/style.css"
// import "../node_modules/bootstrap/dist/css/bootstrap.min.css"
// import "../node_modules/bootstrap/dist/js/bootstrap.js"
import "./assets/style.scss"
import "./assets/sass/main.scss"
import App from "./components/app"
import UnsecuredPage from "./components/pages/UnsecuredPage"
import * as serviceWorker from "./serviceWorker"
import "./App.css"

ReactDOM.render(
  window.self === window.top ? <App /> : <UnsecuredPage />,
  // <App />,
  document.getElementById("root")
)
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
