import React, { useState, useEffect } from "react"
import ReactDOM from "react-dom"
import "../../assets/css/faq.css"
import { createGlobalStyle } from "styled-components"
import { BaseUrl } from "../../utils/config"
import { useNavigate } from "@reach/router"
import {
  BrowserRouter as Router,
  Link,
  useHistory,
  useLocation
} from "react-router-dom"
import axios from "axios"
import { useDispatch } from "react-redux"
import { updateUserDetailsRedux } from "../../redux/actions"

const GlobalStyles = createGlobalStyle`
  header#myHeader.navbar.white {
    background: #212428;
  }
`

const HowItWorks = () => {
  const user = localStorage.getItem("loginUser")
  const [allproducts, setAllproducts] = useState([])
  const [viewProduct, setViewProduct] = useState(false)
  const [activeTab, SetActiveTab] = useState(-1)
  const loginUserMail = localStorage.getItem("loginUserMail")
  const history = useHistory()
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const panels = [
    {
      label: "1. What is mystery box?",
      content:
        "A mystery box is a Non Fungible Miner (NFM) with location as secret inside the box. It contains and represents the location of the Ion Miner where it will be hosted."
    },
    {
      label:
        "2. Why these mystery boxes have been priced differently? What's the difference?",
      content:
        "The difference in price is because of location of the NFMs, these mystery boxes are placed at. The reward earned by the Nitro Ion Miner of these NFMs varies as per the location."
    },
    {
      label: "3. How many mystery boxes one can buy?",
      content: "As many as one wishes to."
    },
    {
      label: "4. How to purchase mystery box?",
      content:
        "One can purchase mystery box from Nitro Network Miner interface which is currently live or from the Nitro marketplace which will be live soon."
    },
    {
      label: "5. When will the product be shipped?",
      content: "By the end of Q1, 2022."
    },
    {
      label: "6. What is the price of different Tier NFMs?",
      content: (
        <>
          Iconic (Tier 1) - &nbsp;<strike>$750</strike>&nbsp;$375
          <br />
          Premium (Tier 2) - &nbsp;<strike>$650</strike>&nbsp;$325
          <br />
          Top (Tier 3) - &nbsp;<strike>$450</strike>&nbsp;$225
        </>
      )
    },
    {
      label:
        "7. I have preordered a miner and also applied for nesting. Will I get 2 miners or will I nest and mine with the one?",
      content:
        "There is a nesting vetting process. When you signed up to become a nester, you have submitted an application with an address where you want the device to be installed. If your location is optimal and meets our specifications, we will be sending a device to you for deployment at the registered address. In regards to the miner that you pre-ordered, we will be providing updates about this in the days ahead. We are working on a very innovative solution that we are super excited about. Currently, we are finalizing these finer details, as we speak. Thanks for your support and being part of Nitro."
    },
    {
      label:
        "8. Does the deposit amount reflect on how many mystery boxes you reserved? Say I deposited $300, am I eligible for 3 miners?",
      content:
        "It depends upon the batch in which you pre-ordered the mystery boxes. If you ordered in the first batch, $300 will be considered as the advance for 6 mystery boxes ($50 each). Similarly, for the second and third batches, $300 will be considered for 4 mystery boxes ($75 each) and 3 mystery boxes ($100 each) respectively."
    },
    {
      label:
        "9. I have ordered a miner a few weeks ago but i didnt receive any confirmation email, is this always the case?",
      content: (
        <>
          Please mail your issue at{" "}
          <a href="mailto:info@nitro.network" className="faq-link">
            info@nitro.network
          </a>
          . We will resolve at the earliest.
        </>
      )
    },
    {
      label: "10. Do miners that we buy earn more than ones given by Nitro?",
      content:
        "No. Specification of current batch of Nitro ION Miners are same. But earning depends on multiple factors such as location, no. of devices getting connected to the miner, how the Nitro ION miner is responding to the NOS sentinel, active hours etc."
    },
    {
      label: "11. Will preordered miners go to the best paid tiers?",
      content:
        "Yes. They will get Iconic (Tier-1) NFM which is the highest paid among three tiers."
    },
    {
      label:
        "12. What about us who put money at the very beginning as a pre signup?",
      content:
        "You get Iconic (Tier 1) NFM at the price of Tier 3. And don't forget the discount you are already getting just because you pre-ordered."
    },
    {
      label:
        "13. What type of rewards? I ordered one, should I order a second?",
      content:
        "If you want to mine more then go for it. With Nitro network, you will literally be mining while sleeping."
    },
    {
      label: "14. How do I check my daily earnings?",
      content:
        "Using miner portal/cockpit. It will be released before mystery box unlocks. We will keep you updated."
    },
    {
      label: "15. Please explain - The ION miner uses the GPU of the nester.",
      content:
        "Since the miner is represented by the NFM that you purchase, that means you do not own a physical miner. The nesters are the people who are hosting the network via a router like device in their preferred locations. That being said, the virtual mining takes place by using the virtual miner using the GPU of the physical nester. Does this make sense ? "
    },
    {
      label:
        "16. When I pre signed up in November 2021, we would get 100% cashback in NCash for our miners. Is this still valid?",
      content: "Yes that’s still valid. First 1000 signups get 100% cashback."
    },
    {
      label: "17. How do we know which batch we are in?",
      content:
        "Depends on how much you paid for pre-order. First batch paid $50, Second batch paid $75 and Third Batch paid $100."
    }
  ]

  useEffect(() => {
    axios
      .get(`${BaseUrl}/product/get`)
      .then(response => {
        setAllproducts(response.data.data)
        if (response) {
          setAllproducts(response.data.data)
        }
      })
      .catch(error => {
        if (error?.response?.status === 401) {
          localStorage.clear()
          dispatch(updateUserDetailsRedux({}))
          navigate("/login")
        }
      })
  }, [])

  const onviewproduct = id => {
    setViewProduct(true)
    // SetSelectedId(id);
    navigate("/productdetails", { state: { selectedId: id } })
  }
  const toLogin = () => {
    navigate("/login")
  }
  const toChangePage = item => {
    navigate(`/${item}`)
  }
  const activateTab = index => {
    activeTab === index ? SetActiveTab(-1) : SetActiveTab(index)
  }
  return (
    <div className="">
      {/* <GlobalStyles/> */}

      <section className="backgroundColordark ">
        <div className=" row  pt-md-4 d-flex justify-content-center">
          <div className="accordion  w-100 remove-max-width" role="tablist">
            {panels.map((panel, index) => (
              <Panel
                key={index}
                activeTab={activeTab}
                index={index}
                {...panel}
                activateTab={activateTab.bind(null, index)}
              />
            ))}
          </div>
          {/* //------------------------------------------------------------------------------------------------------------ */}

          {/* <div className="accordion l-container">
                        <ul className="accordion-list">
                            <li className="accordion-item">
                                <div className="accordion-button"><span>1. What is Nesting?</span></div>
                                <div className="accordion-panel">
                                    <ul>
                                        <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Atque repellendus cupiditate corrupti, ratione odit velit obcaecati deleniti aspernatur! Explicabo eveniet nostrum numquam molestias delectus! Rem commodi dolor impedit assumenda dignissimos.</li>
                                    </ul>
                                </div>
                            </li>
                            <li className="accordion-item">
                                <div className="accordion-button"><span>2. Why Nesting?</span></div>
                                <div className="accordion-panel">
                                    <ul>
                                        <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Atque repellendus cupiditate corrupti, ratione odit velit obcaecati deleniti aspernatur! Explicabo eveniet nostrum numquam molestias delectus! Rem commodi dolor impedit assumenda dignissimos.</li>
                                    </ul>
                                </div>
                            </li>
                            <li className="accordion-item">
                                <div className="accordion-button"><span>3. Who is a Nester?</span></div>
                                <div className="accordion-panel">
                                    <ul>
                                        <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Atque repellendus cupiditate corrupti, ratione odit velit obcaecati deleniti aspernatur! Explicabo eveniet nostrum numquam molestias delectus! Rem commodi dolor impedit assumenda dignissimos.</li>
                                    </ul>
                                </div>
                            </li>
                            <li className="accordion-item">
                                <div className="accordion-button"><span>4. Why should you become a Nester?</span></div>
                                <div className="accordion-panel">
                                    <ul>
                                        <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Atque repellendus cupiditate corrupti, ratione odit velit obcaecati deleniti aspernatur! Explicabo eveniet nostrum numquam molestias delectus! Rem commodi dolor impedit assumenda dignissimos.</li>
                                    </ul>
                                </div>
                            </li>  <li className="accordion-item">
                                <div className="accordion-button"><span>5. What are the steps for becoming a Nester?</span></div>
                                <div className="accordion-panel">
                                    <ul>
                                        <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Atque repellendus cupiditate corrupti, ratione odit velit obcaecati deleniti aspernatur! Explicabo eveniet nostrum numquam molestias delectus! Rem commodi dolor impedit assumenda dignissimos.</li>
                                    </ul>
                                </div>
                            </li>  <li className="accordion-item">
                                <div className="accordion-button"><span>6. How will a Nester earn?</span></div>
                                <div className="accordion-panel">
                                    <ul>
                                        <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Atque repellendus cupiditate corrupti, ratione odit velit obcaecati deleniti aspernatur! Explicabo eveniet nostrum numquam molestias delectus! Rem commodi dolor impedit assumenda dignissimos.</li>
                                    </ul>
                                </div>
                            </li>  <li className="accordion-item">
                                <div className="accordion-button"><span>7. Do Nesters in specific locations have more benefits?</span></div>
                                <div className="accordion-panel">
                                    <ul>
                                        <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Atque repellendus cupiditate corrupti, ratione odit velit obcaecati deleniti aspernatur! Explicabo eveniet nostrum numquam molestias delectus! Rem commodi dolor impedit assumenda dignissimos.</li>
                                    </ul>
                                </div>
                            </li>  <li className="accordion-item">
                                <div className="accordion-button"><span>8. Can there be multiple Nesters in the same place?</span></div>
                                <div className="accordion-panel">
                                    <ul>
                                        <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Atque repellendus cupiditate corrupti, ratione odit velit obcaecati deleniti aspernatur! Explicabo eveniet nostrum numquam molestias delectus! Rem commodi dolor impedit assumenda dignissimos.</li>
                                    </ul>
                                </div>
                            </li>  <li className="accordion-item">
                                <div className="accordion-button"><span>9. How will I know my Tier?</span></div>
                                <div className="accordion-panel">
                                    <ul>
                                        <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Atque repellendus cupiditate corrupti, ratione odit velit obcaecati deleniti aspernatur! Explicabo eveniet nostrum numquam molestias delectus! Rem commodi dolor impedit assumenda dignissimos.</li>
                                    </ul>
                                </div>
                            </li>
                            <li className="accordion-item">
                                <div className="accordion-button"><span>10. What if I no longer want to be a Nester?</span></div>
                                <div className="accordion-panel">
                                    <ul>
                                        <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Atque repellendus cupiditate corrupti, ratione odit velit obcaecati deleniti aspernatur! Explicabo eveniet nostrum numquam molestias delectus! Rem commodi dolor impedit assumenda dignissimos.</li>
                                    </ul>
                                </div>
                            </li>
                            <li className="accordion-item">
                                <div className="accordion-button"><span>11. I live in a rented premises. In case I change my address, can I still be a Nester?</span></div>
                                <div className="accordion-panel">
                                    <ul>
                                        <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Atque repellendus cupiditate corrupti, ratione odit velit obcaecati deleniti aspernatur! Explicabo eveniet nostrum numquam molestias delectus! Rem commodi dolor impedit assumenda dignissimos.</li>
                                    </ul>
                                </div>
                            </li>
                        </ul>
        </div>
               

           
                    */}

          <div className="spacer-double"></div>

          {/* <ul className="pagination justify-content-center ">
                <li className="active"><span>1 - 20</span></li>
                <li><span>21 - 40</span></li>
                <li><span>41 - 60</span></li>
            </ul>  */}
        </div>
      </section>
    </div>
  )
}
export default HowItWorks

class Panel extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      height: 0
    }
  }

  componentDidMount() {
    window.setTimeout(() => {
      const el = ReactDOM.findDOMNode(this)
      const height = el.querySelector(".panel__inner").scrollHeight
      this.setState({
        height
      })
    }, 333)
  }

  render() {
    const { label, content, activeTab, index, activateTab } = this.props
    const { height } = this.state
    const isActive = activeTab === index
    const innerStyle = {
      height: `${isActive ? height : 0}px`
    }

    return (
      <div className="panel" role="tabpanel" aria-expanded={isActive}>
        <button className="panel__label" role="tab" onClick={activateTab}>
          {label}
        </button>
        <div
          className="panel__inner"
          style={innerStyle}
          aria-hidden={!isActive}>
          <p className="panel__content">{content}</p>
        </div>
      </div>
    )
  }
}

class Accordion extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      activeTab: 0
    }

    this.activateTab = this.activateTab.bind(this)
  }

  activateTab(index) {
    this.setState(prev => ({
      activeTab: prev.activeTab === index ? -1 : index
    }))
  }

  render() {
    const { panels } = this.props
    const { activeTab } = this.state
    return (
      <div className="accordion" role="tablist">
        {panels.map((panel, index) => (
          <Panel
            key={index}
            activeTab={activeTab}
            index={index}
            {...panel}
            activateTab={this.activateTab.bind(null, index)}
          />
        ))}
      </div>
    )
  }
}
