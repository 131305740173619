import React from "react"
import "../styles/table.css"
import { useSelector } from "react-redux"
import { IoTUrl } from "../../utils/config"
import { Link } from "@reach/router"

const Profile = () => {
  const userDetails = useSelector(state => state.common.userDetails)

  return (
    <div className="Edit-profile PT-90px">
      <div className="profile-section">
        <h1 className="text-center my-profile-text mt-4">My Profile</h1>

        <div className="profile-box">
          <div className="profile-image">
            {/* <img src="./images/profile.jpg" alt="" /> */}
            <h2>{userDetails?.loginUser?.charAt(0) ?? ""}</h2>
          </div>

          <h6>{userDetails?.loginUser}</h6>
          <h6>{userDetails?.loginUserMail}</h6>

          <div className="btns-profiles  my-4">
            <Link to="/editprofile" className="btn-brand btn-active mr-4">
              Edit Profile
            </Link>
            <Link to="/changepassword" className="btn-brand btn-active ml-2">
              Reset Password
            </Link>
          </div>
          <p className="mt-4">
            <a
              href={`${IoTUrl}${
                userDetails?.referralId
                  ? `?referralID=${userDetails?.referralId}`
                  : ""
              }`}
              className="color-primary"
              style={{ textDecoration: "underline" }}>
              Your Referral ID: {userDetails?.referralId}
            </a>
          </p>
        </div>
      </div>
    </div>
  )
}
export default Profile
