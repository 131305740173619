import { ACTION_TYPES } from "../actions";

const initialState = {
    pages: []
};

const WordPressReducer = (state = initialState, action) => {
    switch(action.type) {
        case ACTION_TYPES.SET_PAGES:
            state.pages = action.payload;
        default:
            return state;
    }
}

export default WordPressReducer;