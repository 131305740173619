import Select from "react-select"
import "../../assets/style.scss"

const SelectDropDown = ({
  defaultValue = undefined,
  isSearchable = false,
  isClearable = false,
  options = [],
  placeholder = "",
  changeHandler = () => {},
  className = "",
  style,
  isMulti,
  formatOptionLabel = <></>,
  ...props
}) => {
  // const [field, meta] = useField({...props, name: props?.name ?? ""});
  return (
    <>
      <Select
        name={props.name}
        defaultValue={defaultValue}
        onChange={changeHandler}
        options={options}
        isSearchable={isSearchable}
        // className={className}
        isClearable={true}
        placeholder={placeholder}
        className="react-select-dropmenu"
        styles={style}
        formatOptionLabel={formatOptionLabel}
        classNamePrefix="react-select"
        isMulti={isMulti}
        {...props}
      />
    </>
  )
}

export default SelectDropDown
