import React, { useState, useEffect } from "react"
import "../styles/table.css"
import Footer from "../components/footer"
import { createGlobalStyle } from "styled-components"
import Menu from "./menu"
import { Form, Input, Modal, Select, Button } from "antd"
import { BaseUrl } from "../../utils/config"
import { useNavigate } from "@reach/router"
import {
  BrowserRouter as Router,
  Link,
  useHistory,
  useLocation
} from "react-router-dom"
import axios from "axios"
import swal from "sweetalert"
import moment from "moment"
import { updateUserDetailsRedux, setConnectedWallet } from "../../redux/actions"
import { useDispatch } from "react-redux"

const GlobalStyles = createGlobalStyle`
  header#myHeader.navbar.white {
    background: #212428;
  }
`

const MyCollection = () => {
  const [allproducts, setAllproducts] = useState([])
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [isproducts, setIsProducts] = useState(false)
  const [isresponse, setIsresponse] = useState(false)
  const history = useHistory()
  const userId = localStorage.getItem("loginUserId")
  const loginUserToken = localStorage.getItem("loginUserToken")

  useEffect(() => {
    axios
      .get(`${BaseUrl}/mycollection/${userId}`, {
        headers: {
          // 'Content-Type': 'application/json',
          Authorization: `Bearer ${loginUserToken}`
        }
      })
      .then(response => {
        !response?.data?.data?.is_payment_completed &&
          setAllproducts(response?.data?.data)
        if (response) {
          setIsresponse(response?.data?.data?.is_payment_completed)

          response?.data?.data?.is_payment_completed
            ? setAllproducts(response.data.data.records)
            : setAllproducts(response?.data?.data)
          !response?.data?.data?.is_payment_completed &&
            setIsProducts(response?.data?.message)
        } else if (response.status == 401) {
          dispatch(updateUserDetailsRedux({}))
          dispatch(setConnectedWallet(""))
          localStorage.clear()
          navigate("/login")
        } else if (response.status == 404) {
          swal({
            title: "Error!",
            text: "Page not found. Please try again later.",
            icon: "error"
          })
        } else if (response.status == 500) {
          swal({
            title: "Error!",
            text: "Something went wrong. Please try again later.",
            icon: "error"
          })
        } else if (response.status == 503) {
          swal({
            title: "Error!",
            text: "Something went wrong. Please try again later.",
            icon: "error"
          })
        } else {
          swal({
            title: "Error!",
            text: "Something went wrong. Please try again later.",
            icon: "error"
          })
        }
      })
      .catch(error => {
        if (error?.response?.status == 401) {
          dispatch(updateUserDetailsRedux({}))
          dispatch(setConnectedWallet(""))
          localStorage.clear()
          navigate("/login")
        } else
          swal({
            title: "Error!",
            text: "Something went wrong. Please try again later.",
            icon: "error"
          })
      })
  }, [])

  return (
    <div className="My-collection-page">
      {/* <GlobalStyles/> */}
      <div className="">
        <Menu acteved={"myCollection"} />
        <div className="row ">
          <div className="col-lg-12 "></div>
          {allproducts.length > 0 ? (
            allproducts.map((item, i) => {
              return (
                <div
                  className="d-item col-lg-3 col-md-6 col-sm-6 col-xs-12 mb-4"
                  key={i}>
                  <div className="nft__item m-0">
                    <div className="author_list_pp"></div>
                    <div className="nft__item_wrap">
                      <span>
                        <img
                          src={item.imageUrl}
                          className="lazy nft__item_preview"
                          alt=""
                        />
                      </span>
                    </div>
                    <div className="nft__item_info">
                      <span>
                        <h4>{item.title}</h4>
                      </span>
                      <div className="nft__item_price">
                        QTY :<span>2</span>
                      </div>
                      {/* <div className="nft__item_action">
                            <span >Place a bid</span>
                        </div> */}
                      <div className="nft__item_like">
                        $ {item.total_amount}
                      </div>
                    </div>
                  </div>
                </div>
              )
            })
          ) : (
            <section className="jumbotron breadcumb no-bg">
              <div className="mainbreadcumb">
                <div className="container">
                  <div className="row m-10-hor">
                    <div className="col-12">
                      <h4 className="form-head">{isproducts}</h4>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )}
        </div>
      </div>

      {/* <Footer /> */}
    </div>
  )
}
export default MyCollection
