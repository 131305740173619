import React, { useState, useEffect } from "react"
import Footer from "../components/footer"
import { createGlobalStyle } from "styled-components"
import { Form, Input, Modal, Select, Button } from "antd"
import { BaseUrl } from "../../utils/config"
import axios from "axios"
import swal from "sweetalert"
import { createBrowserHistory, History } from "history"
import { Link, useHistory, useLocation } from "react-router-dom"
import { useNavigate } from "@reach/router"
import { useDispatch } from "react-redux"
import { setConnectedWallet, updateUserDetailsRedux } from "../../redux/actions"

const GlobalStyles = createGlobalStyle`
  header#myHeader.navbar.white {
    background: #212428;
  }
`

const ForgotPasswordFinal = () => {
  const [isEmail, setIsEmail] = useState(null)
  const [isPass, setIsPass] = useState(null)
  const [userId, setUserId] = useState(null)
  const [isCPass, setIsCPass] = useState(null)
  const [passErrorMsg, setPassErrorMsg] = useState(null)
  const [token, setToken] = useState(null)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const [form] = Form.useForm()

  useEffect(() => {
    let path = location.pathname
    let temp = path.substring(path.lastIndexOf("/") + 1)
    setToken(temp)
    callForPreParams(temp)
  }, [])

  const verifyPassWord = () => {
    if (isPass != null && isCPass != null) {
      isPass != isCPass
        ? setPassErrorMsg("Passwords do not match")
        : setPassErrorMsg(null)
    }
  }

  const callForPreParams = temp => {
    axios
      .get(`${BaseUrl}/resetpassword/${temp}`)
      .then(response => {
        if (response.status == 200) {
          swal({
            title: "Success!",
            text: "Email verified successfully. You can change your password now.",
            icon: "success"
          })
          setIsEmail(response?.data?.data?.email)
          setUserId(response?.data?.data?.id)
          form.setFieldsValue({
            email: response?.data?.data?.email
          })
        } else {
          swal({
            title: "Invalid Credentials",
            text: "Invalid token! Please try again.",
            icon: "warning"
          })
        }
      })
      .catch(error => {
        swal({
          title: "Invalid Token!",
          text: "Please try again",
          icon: "warning"
        })
      })
  }

  const onFinish = values => {
    const Fobj = {
      password: values.cpassword
    }
    if (
      values.password == null ||
      values.cpassword == null ||
      values.cpassword != values.password ||
      values.password == "" ||
      values.cpassword == ""
    ) {
      swal({
        text: "Please enter the Credentials",
        icon: "warning"
      })
    } else {
      axios
        .put(`${BaseUrl}/updatepassword/${userId}/${token}`, Fobj)
        .then(response => {
          if (response.status == 200) {
            swal({
              title: "Success",
              text: "Password updated successfully",
              icon: "success"
            })
            localStorage.clear()
            setTimeout(() => {
              navigate("/login")
            }, 3000)
          } else if (response.status == 401) {
            dispatch(updateUserDetailsRedux({}))
            dispatch(setConnectedWallet(""))
            localStorage.clear()
            navigate("/login")
          } else if (response.status == 404) {
            swal({
              title: "Error!",
              text: "Page not found. Please try again later.",
              icon: "error"
            })
          } else if (response.status == 500) {
            swal({
              title: "Error!",
              text: "Something went wrong. Please try again later.",
              icon: "error"
            })
          } else if (response.status == 503) {
            swal({
              title: "Error!",
              text: "Something went wrong. Please try again later.",
              icon: "error"
            })
          } else {
            swal({
              title: "Invalid Credentials",
              text: "Invalid credentials. Please try again.",
              icon: "error"
            })
          }
        })
        .catch(error => {
          if (error?.response?.status === 401) {
            dispatch(updateUserDetailsRedux({}))
            dispatch(setConnectedWallet(""))
            localStorage.clear()
            navigate("/login")
          } else {
            swal({
              title: "Invalid Credentials",
              text: "Please try again",
              icon: "warning"
            })
          }
        })
    }
  }
  const toLogin = () => {
    navigate("/login")
  }

  const toSignup = () => {
    navigate("/verifyEmail")
  }
  const toforgot = () => {
    navigate("/forgotPassword")
  }

  return (
    <div className="Login-page">
      {/* <GlobalStyles/> */}
      <div className="Login-page__box">
        {/* <h3>Forgot password</h3> */}
        <Form
          name="contactForm"
          id="contact_form"
          className="forgetfinal"
          action=""
          autoComplete="off"
          form={form}
          onFinish={onFinish}>
          <h3>Forgot Password</h3>

          <div className="field-set">
            <Form.Item
              name="email"
              rules={[
                {
                  type: "email",
                  message: "The input is not valid Email!"
                },
                {
                  required: true,
                  message: "Enter the email"
                }
              ]}>
              <Input
                id="email"
                placeholder="Enter email"
                disabled
                className="form-control"
                onChange={e => {
                  setIsEmail(e.target.value)
                }}
              />
            </Form.Item>
          </div>

          <div className="field-set">
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: "Enter the password."
                },
                {
                  max: 15,
                  message: "Password should be in between 6 and 15 characters."
                },
                {
                  min: 6,
                  message: "Password should be in between 6 and 15 characters."
                }
                // {
                //   validator: (_, value) => {
                //     !value.includes(" ")
                //       ? Promise.resolve()
                //       : Promise.reject(
                //           "Password should not contain space"
                //         );
                //   },
                // },
              ]}>
              <Input.Password
                className="form-control"
                type="password"
                placeholder="New Password"
                onChange={e => {
                  setIsPass(e.target.value)
                }}
                onMouseOut={() => {
                  verifyPassWord()
                }}
              />
            </Form.Item>
            {/* <input type="password" name="password" id="password" placeholder="New Password" /> */}
          </div>
          <div className="field-set">
            <Form.Item
              name="cpassword"
              dependencies={["password"]}
              rules={[
                {
                  required: true,
                  message: "Please confirm your password!"
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value)
                      return Promise.resolve()
                    else
                      return Promise.reject(new Error("Passwords do not match"))
                  }
                })
              ]}>
              <Input.Password
                className="form-control"
                type="password"
                placeholder="Confirm Password"
                onChange={e => {
                  setIsCPass(e.target.value)
                }}
                onMouseOut={() => {
                  verifyPassWord()
                }}
              />
            </Form.Item>
          </div>

          <div id="submit">
            <input
              type="submit"
              id="send_message"
              value="Submit"
              className="btn btn-main login-submit-btn color-2 mt-3"
              disabled={isEmail && isPass && isCPass ? false : true}
            />
            <div className="clearfix"></div>

            <div className="spacer-single"></div>
          </div>
        </Form>
      </div>

      {/* <Footer /> */}
    </div>
  )
}

export default ForgotPasswordFinal
