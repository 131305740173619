// import addCommas from "add-commas"
import axios from "axios"
import React from "react"
// import moment from "moment"
import Dropdown from "../components/dropdown"
// import { isFloat, capitalize } from "../../utils"
import { GatewayUrl } from "../../utils/config"
import { setConnectedWallet, updateUserDetailsRedux } from "../../redux/actions"
import { useDispatch } from "react-redux"
import { useNavigate } from "@reach/router"

function DeviceManagement() {
  const [devices, setDevices] = React.useState([])
  const [duration, setDuration] = React.useState("")

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const durationOptions = [{ value: "", label: "All Time" }]

  React.useEffect(() => {
    axios
      .post(`${GatewayUrl}/fetchUserGateways`, {
        email: "siddharth.dev101@gmail.com"
      })
      .then(response => {
        setDevices(response.data.data)
      })
      .catch(error => {
        if (error?.response?.status === 401) {
          dispatch(updateUserDetailsRedux({}))
          dispatch(setConnectedWallet(""))
          localStorage.clear()
          navigate("/login")
        }
      })
  }, [])

  return (
    <div className="device-manage-screen">
      <div className="sorting">
        <Dropdown
          name="duration"
          id="duration"
          options={durationOptions}
          handleChange={e => {
            setDuration(e.target.value)
          }}
          value={duration}
        />
      </div>

      <section className="backgroundColordark no-padding-in-mobile">
        <div className="row ">
          <div className="col-lg-12">
            <table className="table de-table table-rank table-font-size-reduce table-responsive">
              <thead>
                <tr>
                  <th scope="col" className="right">
                    Device Image{" "}
                    {/* <Tooltip title="Method used to make the payment">
                        <FaIcons.FaExclamationCircle />
                      </Tooltip> */}
                  </th>

                  <th scope="col" className="right">
                    Serial Number{" "}
                    {/* <Tooltip title="Currency type used to make the payment">
                        <FaIcons.FaExclamationCircle />
                      </Tooltip> */}
                  </th>

                  <th scope="col" className="right">
                    Gateway Name{" "}
                    {/* <Tooltip title="Transaction ID">
                      <FaIcons.FaExclamationCircle />
                    </Tooltip> */}
                  </th>

                  <th scope="col" className="right">
                    Power
                    {/* <Tooltip title="Timestamp">
                      <FaIcons.FaExclamationCircle />
                    </Tooltip> */}
                  </th>

                  <th scope="col" className="right">
                    Location
                    {/* <Tooltip title="Transaction amount">
                        <FaIcons.FaExclamationCircle />
                      </Tooltip> */}
                  </th>

                  <th scope="col" className="right">
                    Epochs
                    {/* <Tooltip title="Payment confirmation status">
                        <FaIcons.FaExclamationCircle />
                      </Tooltip> */}
                  </th>
                  <th scope="col" className="right">
                    Rewards
                    {/* <Tooltip title="Payment confirmation status">
                        <FaIcons.FaExclamationCircle />
                      </Tooltip> */}
                  </th>
                </tr>
              </thead>
              <tbody>
                {devices.length > 0 ? (
                  devices.map((device, i) => {
                    return (
                      <tr key={i}>
                        <td data-label="Device Image">
                          <span className="address-text-table">
                            Device Image
                          </span>
                        </td>
                        {/* <!-- added class --> */}
                        <td className="right" data-label="Serial Number">
                          <span className="max-width-90">
                            {device?.gatewayId ?? " "}
                          </span>
                        </td>
                        {/* <td className="d-plus right" data-label="lat">{item.source}</td> */}
                        <td
                          className="d-plus right overflow-text"
                          data-label="Gateway Name">
                          <span className="trx-id">
                            {device?.gatewayName ?? " "}
                          </span>
                        </td>
                        <td className="right max-time" data-label="Power">
                          <span className="time-font">
                            {device.active ? "Power On" : "Power Off"}
                          </span>
                        </td>
                        <td className="right max-time" data-label="Location">
                          <span className="time-font">
                            {device.location.latitude +
                              ", " +
                              device.location.longitude}
                          </span>
                        </td>
                        <td className="right" data-label="Epochs">
                          {device?.epochs ?? 0}
                        </td>
                        <td className="right" data-label="Rewards">
                          {device?.rewardAmount ?? 0} Ncash
                        </td>
                      </tr>
                    )
                  })
                ) : (
                  <td colSpan={6} className="text-center">
                    No Data{" "}
                  </td>
                )}
              </tbody>
            </table>

            <div className="spacer-double"></div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default DeviceManagement
