import { useState, useEffect } from "react"
import { useNavigate } from "@reach/router"
import { useSelector, useDispatch } from "react-redux"
import {
  setCollectionItems,
  updateUserDetailsRedux,
  setConnectedWallet,
  setTokenData,
  setStakeTokenData,
  setClickedNFM,
  stopLoader,
  startLoader
} from "../../redux/actions"
import { BaseUrl } from "../../utils/config"
import axios from "axios"
import moment from "moment"
import "../../assets/css/marketplace.css"
// import './styles/newstyles.css';
import clickit from "./images/clickit.gif"
import nftimage from "./images/rednft.png"
import stakedimage from "./images/staked.png"
import swal from "sweetalert"
import MyCollectionWithStake from "./myCollectionDetailWithStake"
import Web3 from "web3"
import Cookies from "universal-cookie"
// import constants from "../../constants/_prodconstants";
import constants from "../../constants/constants"
import nftAbi from "../../assets/blockchain/nftAbi.json"
import nfmStakingAbi from "../../assets/blockchain/nfmStakingAbi.json"
import SoldNFMDetailPage from "./SoldNFMDetailPage"
import ConnectWalletBtn from "./components/ConnectWalletBtn"
const cookies = new Cookies()

function MyCollectionTab() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const collectionItems =
    useSelector(state => state.common.collectionItems) || []

  const connectedWallet =
    useSelector(state => state.common.connectedWallet) || ""

  const tokenData = useSelector(state => state.collection.tokenData) || []
  const stakeTokenData =
    useSelector(state => state.collection.stakeTokenData) || []

  const demoCollectionItem = {
    mystery: "Demo Collection Item",
    product_desc: "Gold",
    product_sequence_id: "23",
    product_code: "3434",
    product_image: nftimage,
    availableStatus: 1,
    group_name: "Gold",
    category_name: "Tier-1",
    price: 450
  }

  const nftCards = [
    { title: "Tier 1 Iconic", price: "000" },
    { title: "Tier 1 Iconic", price: "000" },
    { title: "Tier 1 Iconic", price: "000" },
    { title: "Tier 1 Iconic", price: "000" },
    { title: "Tier 1 Iconic", price: "000" },
    { title: "Tier 1 Iconic", price: "000" },
    { title: "Tier 1 Iconic", price: "000" },
    { title: "Tier 1 Iconic", price: "000" },
    { title: "Tier 1 Iconic", price: "000" },
    { title: "Tier 1 Iconic", price: "000" },
    { title: "Tier 1 Iconic", price: "000" },
    { title: "Tier 1 Iconic", price: "000" }
  ]

  const [clickedIndex, setClickedIndex] = useState(-1)
  const [connectionStatus, setConnectionStatus] = useState(null)
  const [web3, setWeb3] = useState(null)
  const [isWalletConnected, setWalletConnected] = useState(false)

  const userDetails = useSelector(state => state.common.userDetails)

  const nftAPIBaseUrl = process.env.REACT_APP_COLLECTION_URL

  const connectToWallet = async () => {
    console.log(
      `[nft][src][components][components][pages][pendingPayments][connectToWallet] connectToWallet`
    )
    if (typeof window.ethereum !== "undefined") {
      let accounts = await window.ethereum.request({
        method: "eth_requestAccounts"
      })
      window.web3 = new Web3(window.ethereum)
      setWeb3(window.web3)

      let chainId = await window.web3.eth.getChainId()
      // if (chainId !== parseInt(constant.CHAIN_ID)) {
      //   swal({
      //     title: "Invalid Network!",
      //     text: `Switch your network to ${constant.NETWORK_NAME}`,
      //     icon: "error",
      //     confirmButtonText: "Ok",
      //   });
      //   return;
      // }
      cookies.set("wallet", accounts[0], { path: "/" })
      cookies.set("wallet_status", 1, { path: "/" })
      setWalletConnected(true)
      dispatch(setConnectedWallet(accounts[0]))
      dispatch(startLoader())
      await fetchNFTsOfUser(accounts[0])
      setWeb3(window.web3)
      let apiData = {
        wallet_address: accounts[0]
      }
      axios
        .put(
          `${BaseUrl}/addwallet?user_id=${userDetails.loginUserId}`,
          apiData,
          {
            headers: {
              Authorization: `Bearer ${userDetails.loginUserToken}`
            }
          }
        )
        .then(response => {
          if (response.status === 200) {
          }
        })
        .catch(error => {
          if (error?.response?.status === 401) {
            dispatch(updateUserDetailsRedux({}))
            dispatch(setConnectedWallet(""))
            localStorage.clear()
            navigate("/login")
          }
        })
    } else {
      cookies.set("wallet", null, { path: "/" })
      cookies.set("wallet_status", 0, { path: "/" })
      setWalletConnected(false)
      dispatch(setConnectedWallet(""))
      setWeb3(null)
      // swal({
      //   title: "Unsupported Browse/Plugin not installed",
      //   text: `Please use Google Chrome with Metamask Plugin Installed`,
      //   icon: "error",
      //   confirmButtonText: "Ok",
      // });
      console.log("Unsupported Browser/Plugin not installed")
    }
  }

  const fetchTokenInfo = async () => {
    let tempTokenData = JSON.parse(localStorage?.tokenData ?? "{}")
    let tempItem = {
      attributes: [
        { trait_type: "Background", value: "Light Green" },
        { trait_type: "Body", value: "Sizzlin" },
        { trait_type: "Shoes", value: "Yeezy Sneakers" },
        { trait_type: "Shirts", value: "Tank Top" },
        { trait_type: "Neck", value: "Bandana" },
        { trait_type: "Hand 2", value: "Egyptian Staff" },
        { trait_type: "Head", value: "Football Helmet" },
        { trait_type: "Eyewear", value: "Ski Goggles" },
        { trait_type: "Mouth", value: "Blade Of Wheat" },
        { trait_type: "Hand 1", value: "Bag Of Money" }
      ],
      description:
        "Missed out on wolf.game? Not to worry: we've got our very own Cops Vs. Bacons game, where you can stake your Bacon NFT's to earn $GREASE.",
      external_link: "https://www.grandtheftbacon.com",
      image: "https://api.grandtheftbacon.com/v1/nft/images/24.jpg",
      name: "Bacon #24"
    }

    let promiseArr = []
    Object.entries(tempTokenData).forEach(async ([tier, indexArr]) => {
      for (let i = 0; i < indexArr.length; i++) {
        let item = axios.get(
          `${nftAPIBaseUrl}/${tier}/nft/metadata/${indexArr[i]}`,
          { timeout: 30000 }
        )
        promiseArr.push(item)
      }
    })

    axios
      .all(promiseArr)
      .then(
        axios.spread((...responses) => {
          dispatch(setTokenData(responses.map(response => response.data)))
          localStorage.removeItem("tokenData")
        })
      )
      .catch(err => {
        if (err.response.status === 401) {
          dispatch(updateUserDetailsRedux({}))
          dispatch(setConnectedWallet(""))
          localStorage.clear()
          navigate("/login")
        }
        console.log(err, "error")
      })
  }

  const fetchStakeNFMInfo = async () => {
    let tempStakeTokenData = JSON.parse(localStorage?.stakeTokenData ?? "{}")
    let tempItem = {
      attributes: [
        { trait_type: "Background", value: "Light Green" },
        { trait_type: "Body", value: "Sizzlin" },
        { trait_type: "Shoes", value: "Yeezy Sneakers" },
        { trait_type: "Shirts", value: "Tank Top" },
        { trait_type: "Neck", value: "Bandana" },
        { trait_type: "Hand 2", value: "Egyptian Staff" },
        { trait_type: "Head", value: "Football Helmet" },
        { trait_type: "Eyewear", value: "Ski Goggles" },
        { trait_type: "Mouth", value: "Blade Of Wheat" },
        { trait_type: "Hand 1", value: "Bag Of Money" }
      ],
      description:
        "Missed out on wolf.game? Not to worry: we've got our very own Cops Vs. Bacons game, where you can stake your Bacon NFT's to earn $GREASE.",
      external_link: "https://www.grandtheftbacon.com",
      image: "https://api.grandtheftbacon.com/v1/nft/images/24.jpg",
      name: "Bacon #24"
    }

    let promiseArr = []
    Object.entries(tempStakeTokenData).forEach(async ([tier, indexArr]) => {
      for (let i = 0; i < indexArr.length; i++) {
        let item = axios.get(
          `${nftAPIBaseUrl}/${tier}/nft/metadata/${indexArr[i]}`,
          { timeout: 30000 }
        )
        promiseArr.push(item)
      }
    })

    axios
      .all(promiseArr)
      .then(
        axios.spread((...responses) => {
          dispatch(setStakeTokenData(responses.map(response => response.data)))
          localStorage.removeItem("stakeTokenData")
          dispatch(stopLoader())
        })
      )
      .catch(err => {
        if (err.response.status === 401) {
          dispatch(updateUserDetailsRedux({}))
          dispatch(setConnectedWallet(""))
          localStorage.clear()
          navigate("/login")
        }
      })
  }

  useEffect(() => {
    if (userDetails?.loginUserToken) {
      // axios
      //   .get(`${BaseUrl}/orderinfo/${userDetails.loginUserId}`, {
      //     headers: {
      //       Authorization: `Bearer ${userDetails.loginUserToken}`,
      //     },
      //   })
      //   .then((response) => {
      //     if (response.data.status) {
      //       dispatch(setCollectionItems(response.data.data));
      //     } else if (response.status === 401) {
      //     } else if (response.status == 401) {
      //       swal({
      //         title: "Error!",
      //         text: "Authorisation error. Please try again later.",
      //         icon: "error",
      //       });
      //       localStorage.clear();
      //       updateUserDetailsRedux({});
      //       navigate("/login");
      //     } else if (response.status == 404) {
      //       swal({
      //         title: "Error!",
      //         text: "Page not found. Please try again later.",
      //         icon: "error",
      //       });
      //     } else if (response.status == 500) {
      //       swal({
      //         title: "Error!",
      //         text: "Something went wrong. Please try again later.",
      //         icon: "error",
      //       });
      //     } else if (response.status == 503) {
      //       swal({
      //         title: "Error!",
      //         text: "Something went wrong. Please try again later.",
      //         icon: "error",
      //       });
      //     } else {
      //       setCollectionItems([]);
      //     }
      //   })
      //   .catch((err) => {
      //     if (err.response.status === 401) {
      //       swal({
      //         title: "Error!",
      //         text: "Authorisation error. Please try again later.",
      //         icon: "error",
      //       });
      //       localStorage.clear();
      //       updateUserDetailsRedux({});
      //       navigate("/login");
      //     }
      //     dispatch(setCollectionItems([]));
      //   });
      // Connected Wallet API hit
      if (connectedWallet.length) {
        let apiData = {
          wallet_address: connectedWallet
        }
        axios
          .put(
            `${BaseUrl}/addwallet?user_id=${userDetails.loginUserId}`,
            apiData,
            {
              headers: {
                Authorization: `Bearer ${userDetails.loginUserToken}`
              }
            }
          )
          .then(response => {
            if (response.status === 200) {
              // connectedWallet
              fetchNFTsOfUser(connectedWallet)
            }
          })
          .catch(error => {
            if (error?.response?.status == 401) {
              dispatch(updateUserDetailsRedux({}))
              dispatch(setConnectedWallet(""))
              localStorage.clear()
              navigate("/login")
            }
          })
      }
    } else {
      navigate("/login")
    }
  }, [])

  const walletConnectionStatus = async () => {
    // Check if user already connected to wallet
    let walletConnectionStatus = cookies.get("wallet_status")
    console.log(
      `[nft][src][components][components][pages][MyCollectionTab][walletConnectionStatus] wallet_status`,
      walletConnectionStatus
    )

    if (walletConnectionStatus == 1) {
      setConnectionStatus(true)

      /*
      let walletAddress = cookies.get("wallet");
      await fetchNFTsOfUser(walletAddress);
      */
      await fetchNFTsOfUser(connectedWallet)
    } else {
      setConnectionStatus(false)
    }

    await fetchNFTsOfUser(connectedWallet)
  }

  const fetchNFTsOfUser = async wallet => {
    console.log(
      `[nft][src][components][pages][MyCollectionTab][fetchNFTsOfUser] wallet`,
      wallet
    )
    try {
      let web3 = new Web3(window.ethereum)
      let Tier1 = await new web3.eth.Contract(
        nftAbi,
        constants.TIER_1_NFT_CONTRACT_ADDRESS
      )
      let Tier2 = await new web3.eth.Contract(
        nftAbi,
        constants.TIER_2_NFT_CONTRACT_ADDRESS
      )
      let Tier3 = await new web3.eth.Contract(
        nftAbi,
        constants.TIER_3_NFT_CONTRACT_ADDRESS
      )

      let tier1Balance = await Tier1.methods.balanceOf(wallet).call()
      let tier2Balance = await Tier2.methods.balanceOf(wallet).call()
      let tier3Balance = await Tier3.methods.balanceOf(wallet).call()

      let tier1NFTs = []
      let tier2NFTs = []
      let tier3NFTs = []

      if (tier1Balance > 0) {
        for (let i = 0; i < tier1Balance; i++) {
          let token = await Tier1.methods.tokenOfOwnerByIndex(wallet, i).call()
          tier1NFTs.push(token)
        }
      }

      if (tier2Balance > 0) {
        for (let i = 0; i < tier2Balance; i++) {
          let token = await Tier2.methods.tokenOfOwnerByIndex(wallet, i).call()
          tier2NFTs.push(token)
        }
      }

      if (tier3Balance > 0) {
        for (let i = 0; i < tier3Balance; i++) {
          let token = await Tier3.methods.tokenOfOwnerByIndex(wallet, i).call()
          tier3NFTs.push(token)
        }
      }
      console.log(
        `[nft][src][components][pages][MyCollectionTab][fetchNFTsOfUser] tier1NFTs tokenIds`,
        tier1NFTs
      )
      console.log(
        `[nft][src][components][pages][MyCollectionTab][fetchNFTsOfUser] tier2NFTs tokenIds`,
        tier2NFTs
      )
      console.log(
        `[nft][src][components][pages][MyCollectionTab][fetchNFTsOfUser] tier3NFTs tokenIds`,
        tier3NFTs
      )
      localStorage.setItem(
        "tokenData",
        JSON.stringify({
          v1: tier1NFTs,
          v2: tier2NFTs,
          v3: tier3NFTs
        })
      )
      fetchTokenInfo()
      await fetchStakedNFTsOfUser(wallet)
    } catch (error) {
      console.log(error, "error fetchNFT")
    }
  }

  const fetchStakedNFTsOfUser = async wallet => {
    console.log(
      `[nft][src][components][pages][MyCollectionTab][fetchStakedNFTsOfUser] wallet`,
      wallet
    )

    try {
      const web3 = new Web3(window.ethereum)

      let NFMStaking = await new web3.eth.Contract(
        nfmStakingAbi,
        constants.NFMSTAKING
      )

      let stakingTier1 = await NFMStaking.methods
        .getNoOfNFMsStakedByCollectionByUser(
          wallet,
          constants.TIER_1_NFT_CONTRACT_ADDRESS
        )
        .call()
      let stakingTier2 = await NFMStaking.methods
        .getNoOfNFMsStakedByCollectionByUser(
          wallet,
          constants.TIER_2_NFT_CONTRACT_ADDRESS
        )
        .call()
      let stakingTier3 = await NFMStaking.methods
        .getNoOfNFMsStakedByCollectionByUser(
          wallet,
          constants.TIER_3_NFT_CONTRACT_ADDRESS
        )
        .call()

      console.log(
        "[nft][src][components][pages][MyCollectionTab][fetchStakedNFTsOfUser]:: Tier1 Staked NFTs",
        stakingTier1
      )
      console.log(
        "[nft][src][components][pages][MyCollectionTab][fetchStakedNFTsOfUser]:: Tier2 Staked NFTs",
        stakingTier2
      )
      console.log(
        "[nft][src][components][pages][MyCollectionTab][fetchStakedNFTsOfUser]:: Tier3 Staked NFTs",
        stakingTier3
      )
      localStorage.setItem(
        "stakeTokenData",
        JSON.stringify({
          v1: stakingTier1,
          v2: stakingTier2,
          v3: stakingTier3
        })
      )
      fetchStakeNFMInfo()
    } catch (error) {
      console.log(error, "error fetchStakedNFTsOfUser")
    }
  }

  useEffect(() => {
    let tokenDataLS = localStorage?.tokenData ?? ""
    let stakeTokenDataLS = localStorage?.stakeTokenData ?? ""
    if (tokenDataLS && connectedWallet) {
      fetchTokenInfo()
    }
    if (stakeTokenDataLS && connectedWallet) {
      fetchStakeNFMInfo()
    }
  }, [connectedWallet])

  const setItem = (type, index) => {
    setClickedIndex(index)
    let data = type === "tokenData" ? tokenData[index] : stakeTokenData[index]
    dispatch(setClickedNFM(data))
  }

  const disconnectWallet = () => {
    axios
      .delete(`${BaseUrl}/removeWallet/${userDetails.loginUserId}`, {
        headers: {
          Authorization: `Bearer ${userDetails.loginUserToken}`
        }
      })
      .then(response => {
        cookies.set("wallet", null, { path: "/" })
        cookies.set("wallet_status", 0, { path: "/" })
        setWalletConnected(false)
        dispatch(setConnectedWallet(""))
        dispatch(setTokenData([]))
        dispatch(setStakeTokenData([]))
        setClickedIndex(-1)
        localStorage.removeItem("tokenData")
        localStorage.removeItem("stakeTokenData")
        setWeb3(null)
      })
      .catch(error => {
        if (error?.response?.status === 401) {
          dispatch(updateUserDetailsRedux({}))
          dispatch(setConnectedWallet(""))
          localStorage.clear()
          navigate("/login")
        }
      })
  }

  return (
    <div className="My-collection-page">
      <div
        className="row wow fade-in collection-boxes myct"
        style={{ marginTop: 0 }}>
        <div className="text-center w-100">
          <div
            className="box-det mx-auto margin-10px"
            style={{ maxWidth: "200px", margin: "auto" }}>
            <ConnectWalletBtn
              connectedWallet={connectedWallet}
              disconnectWallet={disconnectWallet}
              connectToWallet={connectToWallet}
            />
          </div>
        </div>
        <div className="text-center mt-3">
          {!connectedWallet.length ? (
            <a
              className="color-primary mb-3 mt-2"
              href="https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn"
              target="_blank"
              rel="noreferrer">
              Click here if you don't have MetaMask
            </a>
          ) : null}
        </div>

        {clickedIndex < 0 ? (
          <div className="row">
            {tokenData.filter(data => data).length && connectedWallet.length ? (
              tokenData
                .filter(data => data)
                .map((card, index) => {
                  if (!card) return null
                  return (
                    <div
                      className="d-item col-lg-3 col-md-6 col-sm-6 col-xs-12"
                      key={card?.name + index}
                      onClick={() => {
                        setItem("tokenData", index)
                      }}>
                      <div className="" key={card?.name + index}>
                        <div
                          className={`nft__item sold-out-nf${
                            clickedIndex === index ? " mobile__view" : ""
                          }`}>
                          <div className="nft__item_wrap">
                            <a>
                              <img
                                src={card?.image}
                                className="lazy nft__item_preview"
                                alt=""
                              />
                            </a>
                            <img
                              src={clickit}
                              id="iconclick"
                              onClick={() => {
                                setClickedIndex(index)
                              }}
                              alt=""
                            />

                            <div className="sold-mark">
                              <img src={stakedimage} alt="" />
                            </div>
                            {/* <p className="sold-mark">

                              <span>SOLD</span>
                            </p> */}
                          </div>
                          <div className="nft__item_info text-center ow-justify-custom">
                            <div>
                              <p
                                className="purple-text mb-0"
                                style={{ fontSize: "0.9rem" }}>
                                {card?.name}
                              </p>
                              {/* <p>P #152412</p> */}
                            </div>

                            {card?.price ? (
                              <div className="text-right">
                                {/* <p>450 $</p> */}
                                <p className="pink-text">
                                  Price: ${card?.price}
                                </p>
                              </div>
                            ) : null}
                          </div>

                          <div className="text-center mt-2">
                            <div
                              className="box-det"
                              style={{
                                maxWidth: "150px",
                                margin: "0 auto",
                                cursor: "pointer"
                              }}>
                              <div className="gradient-box">
                                <div className="field active">
                                  <h5 className="border-field">Stake</h5>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })
            ) : (
              <div className="nitro-text">
                <h1 className="pink-text text-center">No Items Found</h1>
                <div className="spacer-double"></div>
              </div>
            )}
            {stakeTokenData.length && connectedWallet.length ? (
              <>
                <br />
                <br />
                <h1>STAKED NFMs</h1>
                <br />
                <br />
              </>
            ) : null}

            {stakeTokenData.length && connectedWallet.length
              ? stakeTokenData
                  .filter(data => data)
                  .map((card, index) => {
                    if (!card) return null
                    return (
                      <div
                        className="d-item col-lg-3 col-md-6 col-sm-6 col-xs-12"
                        key={card?.name + index}
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setItem("stakeTokenData", index)
                        }}>
                        <div
                          className={`nft__item sold-out-nft${
                            clickedIndex === index ? " mobile__view" : ""
                          }`}>
                          <div className="nft__item_wrap">
                            <a>
                              <img
                                src={card?.image}
                                className="lazy nft__item_preview"
                                alt=""
                              />
                            </a>
                            <img src={clickit} id="iconclick" alt="" />
                            <div className="sold-mark">
                              <img src={stakedimage} alt="" />
                            </div>
                          </div>
                          <div className="nft__item_info row-justify-custom">
                            <div>
                              <p className="purple-text">{card?.name}</p>
                            </div>
                            {card?.price ? (
                              <div className="text-right">
                                <p className="pink-text">
                                  Price: ${card?.price}
                                </p>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    )
                  })
              : null}
          </div>
        ) : (
          <SoldNFMDetailPage />
        )}
      </div>
      {clickedIndex < 0 ? (
        <p className="text-center my-4 min-height-23vh">
          NFMs airdrop process has started. We are airdropping NFM to the
          connected wallet upon final payment within 24-48 hours. Available NFMs
          from your connected wallet will be shown here
        </p>
      ) : null}
    </div>
  )
}

export default MyCollectionTab
