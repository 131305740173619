import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import SensorDashboard from "./sensorDashboard"
import EditSensorNameModal from "../../components/EditSensorNameModal"
import StatusBar from "./StatusBar"
import axios from "axios"
import * as BsIcons from "react-icons/bs"
// import "../styles/analytics.css"
import "./css/popup.scss"
import { useLogout } from "../../../hooks"

const Analytics = () => {
  const { loginUserId, loginUserToken } = useSelector(
    state => state.common.userDetails
  )
  const logoutHandler = useLogout()

  const [userSensors, setUserSensors] = useState([])
  const [showAnalytics, setShowAnalytics] = useState(false)
  const [isGatewayActive, setIsGatewayActive] = useState(false)
  const [sensorName, setSensorName] = useState("")
  const [deviceEuid, setDeviceEuid] = useState("")
  const [editPopup, setEditPopup] = useState(false)
  const [renamedSensorName, setRenamedSensorName] = useState("")
  const [renamedStatus, setRenamedStatus] = useState(false)

  const fetchUserDevices = async () => {
    await axios
      .get(
        `${process.env.REACT_APP_IOT_API_URL}/getUserSensors/${loginUserId}`,
        {
          headers: {
            // 'Content-Type': 'application/json',
            Authorization: `Bearer ${loginUserToken}`
          }
        }
      )
      .then(res => {
        if (res.status === 200) {
          const {
            data: {
              data: { isGatewayActive, devices }
            }
          } = res
          setUserSensors(devices || [])
          setIsGatewayActive(isGatewayActive)
        } else {
          throw new Error("Something went wrong")
        }
      })
      .catch(err => {
        if (err?.response?.status === 401) {
          logoutHandler()
        }
      })
  }

  const updateUserDeviceName = async () => {
    await axios
      .put(
        `${process.env.REACT_APP_IOT_API_URL}/updateSensorName/${deviceEuid}`,
        { sensorName: renamedSensorName },
        {
          headers: {
            // 'Content-Type': 'application/json',
            Authorization: `Bearer ${loginUserToken}`
          }
        }
      )
      .then(res => {
        if (res.status === 200) {
          setRenamedStatus(true)
          setEditPopup(false)
          setRenamedSensorName("")
        } else {
          throw new Error("Something went wrong")
        }
      })
      .catch(err => {
        if (err?.response?.status === 401) {
          logoutHandler()
        }
      })
  }

  useEffect(() => {
    fetchUserDevices()
  }, [renamedStatus])

  return (
    <div className="analytics-page">
      {editPopup && (
        <EditSensorNameModal
          setEditPopup={setEditPopup}
          renamedSensorName={renamedSensorName}
          setRenamedSensorName={setRenamedSensorName}
          updateUserDeviceName={updateUserDeviceName}
        />
      )}

      {showAnalytics ? (
        <SensorDashboard
          isGatewayActive={isGatewayActive}
          sensorName={sensorName}
          deviceEuid={deviceEuid}
          setShowAnalytics={setShowAnalytics}
        />
      ) : (
        <div className="">
          <div className="container">
            {/* <div className="row">
                <StatusBar
                  isGatewayActive={isGatewayActive}
                  showAnalytics={showAnalytics}
                />
                <div
                  className="col-lg-4 col-md-12 col-sm-12 col-xs-12 d-flex justify-content-end"
                  style={{ visibility: "hidden" }}>
                  <div className="gateWayFilter">
                    {" "}
                    <span className="gateWayFiltertext">Filter</span>
                    <span className="gateWayFilterIcon">
                      <BsIcons.BsChevronDoubleDown />
                    </span>
                  </div>
                </div>
              </div> */}
            <div className="row mt-4">
              {userSensors && userSensors.length !== 0 ? (
                userSensors.map((sensor, index) => {
                  return (
                    <div
                      className="col-xs-12 col-md-6 col-sm-6 col-lg-4 col-xl-3 mb-4"
                      key={index}>
                      <div className="sensor-card">
                        <img
                          className="sensor-Img"
                          src={sensor?.sensorImage}
                          alt="sensor"
                          height={50}
                          width={50}
                        />

                        <p className="device__title">
                          <span className="device__name">
                            {sensor?.sensorName &&
                            sensor?.sensorName.length > 20
                              ? `${sensor.sensorName.substring(0, 20)}...`
                              : sensor.sensorName}
                          </span>

                          <span
                            className="device__edit-icon"
                            onClick={() => {
                              setEditPopup(true)
                              setDeviceEuid(sensor?.deviceEui)
                              setRenamedStatus(false)
                              setRenamedSensorName(sensor?.sensorName)
                            }}>
                            <i className="fa fa-edit" title="Rename Sensor"></i>
                          </span>
                        </p>

                        <button
                          className="text-center btn-brand btn-active mt-3"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setSensorName(sensor?.sensorName)
                            setDeviceEuid(sensor?.deviceEui)
                            setShowAnalytics(true)
                          }}>
                          {" "}
                          View Analytics{" "}
                        </button>
                      </div>
                    </div>
                  )
                })
              ) : (
                <p className="not_found-txt">No Data Found</p>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
export default Analytics
