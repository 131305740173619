import React from "react"

function StatusBar({
  isGatewayActive,
  showAnalytics,
  sensorName,
  isSensorActive
}) {
  return (
    <div className="col-lg-8 col-md-12 col-sm-12 col-xs-12 d-flex justify-content-start gateways">
      {/* <div className="gateWayBox">
        <div className="gateWayonsign">
          <img src={NitroImg} alt="sensor" height={50} width={50} />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            flexDirection: "column"
          }}>
          <div className="gatewayHead">Nitro Gateway</div>
          <div className="gatewaytext">
            Device Status :{" "}
            <span style={{ color: isGatewayActive ? "#00BC39" : "red" }}>
              {isGatewayActive ? "Active" : "Inactive"}
            </span>
          </div>
        </div>
      </div> */}
      {showAnalytics ? (
        <div className="gateWayBox">
          <div className="gatewayHead">
            {sensorName.length <= 15 ? sensorName : sensorName.substring(0, 15)}
            ...
          </div>
          <div className="gatwayOn">
            <div
              className="marginAuto"
              style={{
                width: "15px",
                height: "15px",
                borderRadius: "50%",
                backgroundColor: isSensorActive ? "#8bc34a" : "red"
              }}></div>
            <p
              className={`textMain ${
                isSensorActive ? "colorGreen" : "colorRed"
              }`}>
              {isSensorActive ? "ON" : "OFF"}
            </p>
          </div>
        </div>
      ) : null}
    </div>
  )
}

export default StatusBar
