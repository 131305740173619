import { useParams } from "@reach/router"
import { useState, useEffect } from "react"
import useCasesData from "../../assets/htmlPages/usecasesData.js"
import $ from "jquery"
import "slick-carousel"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

function WPUseCaseDetail() {
  const { slug } = useParams()
  const [html, setHtml] = useState(
    "<h2 style='text-align: center;'>Loading Use case....</h2>"
  )

  const jqueryCode = () => {
    document.addEventListener("DOMContentLoaded", () => {
      $(".slider2")
        .not(".slick-initialized")
        .slick({
          infinite: true,
          autoplay: true,
          dots: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          responsive: [
            {
              breakpoint: 991,
              settings: {
                slidesToShow: 1,
                dots: false
              }
            },
            {
              breakpoint: 767,
              settings: {
                slidesToShow: 1,
                dots: false
              }
            }
          ]
        })
      $(".responsive")
        .not(".slick-initialized")
        .slick({
          infinite: true,
          autoplay: true,
          slidesToShow: 3,
          slidesToScroll: 3,
          arrows: true,
          responsive: [
            {
              breakpoint: 991,
              settings: {
                slidesToShow: 2
              }
            },
            {
              breakpoint: 767,
              settings: {
                slidesToShow: 1
              }
            }
          ]
        })
      $(".slider")
        .not(".slick-initialized")
        .slick({
          infinite: true,
          autoplay: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
          responsive: [
            {
              breakpoint: 991,
              settings: {
                slidesToShow: 1,
                arrows: false
              }
            },
            {
              breakpoint: 767,
              settings: {
                slidesToShow: 1,
                arrows: false
              }
            }
          ]
        })
    })
  }

  useEffect(() => {
    if (slug) {
      const pageObj = useCasesData.find(p => p.slug === slug)
      if (!pageObj) {
        setHtml("<h2 style='text-align: center;'>Page content not found</h2>")
        return
      }
      setHtml(pageObj.content)
    }
  }, [slug])

  useEffect(() => {
    jqueryCode()
  }, [html])

  return (
    <div
      className="cms-page usecase-detail-page"
      style={{ marginTop: "var(--top-gap)" }}
      dangerouslySetInnerHTML={{ __html: html }}
    />
  )
}

export default WPUseCaseDetail
