import React from "react"
import { useState } from "react"
const EMIpayments = () => {
  const [showAccordian, setShowAccordian] = useState(true)

  return (
    <div className="emi-screen BOX">
      {/* TOP ESTIMATE */}
      <div className="emi-estimate-top">
        <div className="emi-estimate">
          <div>
            <div className="emi-estimate-box dfac dfjb">
              <h3>NFT's On EMI:</h3>
              <h3 className="bold fw-600">6</h3>
            </div>
            <div className="emi-estimate-box">
              <div className="dfjb dfac">
                <h3>Outstanding Amount:</h3>
                <h3 className="bold fw-600">$ 6,950</h3>
              </div>
              <div className="text-right">
                {/*OnClick -> EmiOutstandingPay.js Screen  */}
                <button className="gradient-button-2 fw-600">Pay Now</button>
              </div>
            </div>
          </div>

          <div className="emi-estimate-box grid-center">
            <p className="light mb-0">Estimated Rewards</p>
            <p className="bold fw-900 fz-big mb-0">11,000 Ncash</p>
          </div>
        </div>
      </div>

      {/* EMI ACCORDIAN  */}
      <div className="emi-all-tier">
        {/* For IConic */}
        <div className="emi-accordian">
          <div
            className="emi-accordian-button emi-estimate-box dfac dfjb"
            onClick={() => setShowAccordian(!showAccordian)}>
            <h5 className="mb-0">Iconic</h5>
            <h5 className="mb-0">
              <i className="fa-solid fa-caret-down"></i>
            </h5>
          </div>

          <div
            // className="emi-accordian-content"
            className={`emi-accordian-content ${
              showAccordian ? "" : "collapsed"
            }`}>
            <table className="table de-table table-rank table-font-size-reduce table-responsive">
              <thead>
                <tr>
                  <th scope="col" className="right">
                    S No.
                  </th>

                  <th scope="col" className="right">
                    EMI id
                  </th>

                  <th scope="col" className="right">
                    EMI Paid
                  </th>

                  <th scope="col" className="right">
                    Outstanding
                  </th>

                  <th scope="col" className="right">
                    Due Date
                  </th>

                  <th scope="col" className="right"></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td data-label="S No.">
                    <span className="address-text-table">
                      1{/* {capitalize(item?.source)} */}
                    </span>
                  </td>
                  {/* <!-- added class --> */}
                  <td className="right" data-label="EMI id">
                    000
                  </td>
                  {/* <td className="d-plus right" data-label="lat">{item.source}</td> */}
                  <td
                    className="d-plus right overflow-text"
                    data-label="EMI Paid">
                    <span className="trx-id">400</span>
                  </td>
                  <td className="right max-time" data-label="Outstanding">
                    <span className="time-font">
                      {/* {moment(item.createdAt).format(
                              "DD-MMMM-YYYY hh:mm:ss A"
                          )} */}
                      200
                    </span>
                  </td>
                  <td className="right" data-label="Due Date">
                    {/* {moment(item.createdAt).format(
                              "DD-MMMM-YYYY hh:mm:ss A"
                          )} */}
                    18-Sep-2022
                  </td>
                  <td className="right" data-label="Pay">
                    {/* {item.payment_status} */}

                    {/*OnClick -> EmiDueToPay.js Screen  */}

                    <button className="gradient-button-2 mt-0 cust-padding">
                      Pay Now
                    </button>
                  </td>
                </tr>
                <tr>
                  <td data-label="S No.">
                    <span className="address-text-table">
                      1{/* {capitalize(item?.source)} */}
                    </span>
                  </td>
                  {/* <!-- added class --> */}
                  <td className="right" data-label="EMI id">
                    000
                  </td>
                  {/* <td className="d-plus right" data-label="lat">{item.source}</td> */}
                  <td
                    className="d-plus right overflow-text"
                    data-label="EMI Paid">
                    <span className="trx-id">400</span>
                  </td>
                  <td className="right max-time" data-label="Outstanding">
                    <span className="time-font">
                      {/* {moment(item.createdAt).format(
                              "DD-MMMM-YYYY hh:mm:ss A"
                          )} */}
                      200
                    </span>
                  </td>
                  <td className="right" data-label="Due Date">
                    {/* {moment(item.createdAt).format(
                              "DD-MMMM-YYYY hh:mm:ss A"
                          )} */}
                    18-Sep-2022
                  </td>
                  <td className="right" data-label="Pay">
                    {/* {item.payment_status} */}
                    <button className="gradient-button-2 mt-0 cust-padding">
                      Pay Now
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        {/* For Premium */}
        <div className="emi-accordian ">
          <div className="emi-accordian-button emi-estimate-box dfac dfjb">
            <h5 className="mb-0">Premium</h5>
            <h5 className="mb-0">
              <i className="fa-solid fa-caret-down"></i>
            </h5>
          </div>

          <div className="emi-accordian-content">
            <table className="table de-table table-rank table-font-size-reduce table-responsive">
              <thead>
                <tr>
                  <th scope="col" className="right">
                    S No.
                  </th>

                  <th scope="col" className="right">
                    EMI id
                  </th>

                  <th scope="col" className="right">
                    EMI Paid
                  </th>

                  <th scope="col" className="right">
                    Outstanding
                  </th>

                  <th scope="col" className="right">
                    Due Date
                  </th>

                  <th scope="col" className="right"></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td data-label="S No.">
                    <span className="address-text-table">
                      1{/* {capitalize(item?.source)} */}
                    </span>
                  </td>
                  {/* <!-- added class --> */}
                  <td className="right" data-label="EMI id">
                    000
                  </td>
                  {/* <td className="d-plus right" data-label="lat">{item.source}</td> */}
                  <td
                    className="d-plus right overflow-text"
                    data-label="EMI Paid">
                    <span className="trx-id">400</span>
                  </td>
                  <td className="right max-time" data-label="Outstanding">
                    <span className="time-font">
                      {/* {moment(item.createdAt).format(
                              "DD-MMMM-YYYY hh:mm:ss A"
                          )} */}
                      200
                    </span>
                  </td>
                  <td className="right" data-label="Due Date">
                    {/* {moment(item.createdAt).format(
                              "DD-MMMM-YYYY hh:mm:ss A"
                          )} */}
                    18-Sep-2022
                  </td>
                  <td className="right" data-label="Pay">
                    {/* {item.payment_status} */}
                    <button className="gradient-button-2 mt-0 cust-padding">
                      Pay Now
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        {/* For Top */}
        <div className="emi-accordian ">
          <div className="emi-accordian-button emi-estimate-box dfac dfjb">
            <h5 className="mb-0">Top</h5>
            <h5 className="mb-0">
              <i className="fa-solid fa-caret-down"></i>
            </h5>
          </div>

          <div className="emi-accordian-content">
            <table className="table de-table table-rank table-font-size-reduce table-responsive">
              <thead>
                <tr>
                  <th scope="col" className="right">
                    S No.
                  </th>

                  <th scope="col" className="right">
                    EMI id
                  </th>

                  <th scope="col" className="right">
                    EMI Paid
                  </th>

                  <th scope="col" className="right">
                    Outstanding
                  </th>

                  <th scope="col" className="right">
                    Due Date
                  </th>

                  <th scope="col" className="right"></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td data-label="S No.">
                    <span className="address-text-table">
                      1{/* {capitalize(item?.source)} */}
                    </span>
                  </td>
                  {/* <!-- added class --> */}
                  <td className="right" data-label="EMI id">
                    000
                  </td>
                  {/* <td className="d-plus right" data-label="lat">{item.source}</td> */}
                  <td
                    className="d-plus right overflow-text"
                    data-label="EMI Paid">
                    <span className="trx-id">400</span>
                  </td>
                  <td className="right max-time" data-label="Outstanding">
                    <span className="time-font">
                      {/* {moment(item.createdAt).format(
                              "DD-MMMM-YYYY hh:mm:ss A"
                          )} */}
                      200
                    </span>
                  </td>
                  <td className="right" data-label="Due Date">
                    {/* {moment(item.createdAt).format(
                              "DD-MMMM-YYYY hh:mm:ss A"
                          )} */}
                    18-Sep-2022
                  </td>
                  <td className="right" data-label="Pay">
                    {/* {item.payment_status} */}
                    <button className="gradient-button-2 mt-0 cust-padding">
                      Pay Now
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EMIpayments
