import React, { useEffect } from "react"
// import { HashLink as Link } from "react-router-hash-link"
import { Form, Input } from "antd"
import axios from "axios"
import { useDispatch } from "react-redux"
import Slider from "react-slick"
import swal from "sweetalert"
import {
  setConnectedWallet,
  // setActiveMenu,
  // setPages
  updateUserDetailsRedux
} from "../../redux/actions"
import { BaseUrl, GenesisURL, StakeURL, SwapURL } from "../../utils/config"
import "./styles/ecosystem.css"
// import "slick-carousel"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

// Images
import { useNavigate } from "@reach/router"
import { Images } from "../../Shared/images"
import { IoTUrl } from "../../utils/config"
import { useLogout } from "../../hooks"
import JoinCommunity from "../components/JoinCommunity"
// import heroImage from "../pages/images/home/hero.webp"
// import { Helmet } from "react-helmet"
// import { Link, useNavigate, useLocation } from "@reach/router";

// import { Link } from "react-router-dom";

function HomepageNew() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const logoutHandler = useLogout()
  // const [openPopup, setOpenPopup] = useState(false)
  // const [popupContent, setPopupContent] = useState({})
  const [form] = Form.useForm()
  const [subscriptionForm] = Form.useForm()
  const submitContactForm = values => {
    const Fobj = {
      first_name: values.fName,
      last_name: values.lName,
      email: values.email,
      comment: values.comment
    }
    if (
      values.fName == null ||
      values.fName == "" ||
      values.lName == null ||
      values.email == null ||
      values.email == "" ||
      values.lName == "" ||
      values.comment == null ||
      values.comment == ""
    ) {
      swal({
        title: "",
        text: "Please enter the Credentials",
        icon: "error"
      })
    } else {
      axios
        .post(`${BaseUrl}/contactus`, Fobj)
        .then(response => {
          if (response) {
            // "Your Federal Account Created Successfully"
            // message.success(`Congratulations  Your Federal Account Created Successfully`,3)
            swal({
              title: "Success!",
              text: "Request submitted successfully, we will review and revert.",
              icon: "success"
            })
            // setTimeout(() => { navigate("/login"); }, 3000)
            form.resetFields()
          } else {
            swal({
              title: "Invalid Credentials",
              text: "Invalid credentials. Please try again.",
              icon: "error"
            })
          }
        })
        .catch(error => {
          if (error?.response?.status == 401) {
            dispatch(updateUserDetailsRedux({}))
            dispatch(setConnectedWallet(""))
            localStorage.clear()
            navigate("/login")
          }
        })
    }
  }

  // function PrevArrow(props) {
  //   const { className, style, onClick } = props
  //   return (
  //     <div
  //       className={className}
  //       style={{ ...style, display: "block", background: "green" }}
  //       onClick={onClick}>
  //       <img src={Images.PrevArrow} alt="arrow" />
  //     </div>
  //   )
  // }
  // function NextArrow(props) {
  //   const { className, style, onClick } = props
  //   return (
  //     <div
  //       className={className}
  //       style={{ ...style, display: "block", background: "green" }}
  //       onClick={onClick}>
  //       <img src={Images.NextArrow} alt="arrow" />
  //     </div>
  //   )
  // }
  //Slider Options
  const sliderBanner = {
    dots: false,
    arrows: true,
    infinite: true,
    // autoplay: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: "ondemand"
    // nextArrow: <NextArrow />,
    // prevArrow: <PrevArrow />
  }
  const sliderPossibilities = {
    dots: true,
    arrows: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 10000,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: "ondemand"

    // nextArrow: <NextArrow />,
    // prevArrow: <PrevArrow />
  }

  useEffect(() => {
    document.title = "Nitro's Ecosystem"
    return () => {
      document.title = "Nitro Network"
    }
  }, [])

  const nitroWorlds = [
    {
      title: "Nitro NFT",
      image: Images.nitronft,
      popup: {
        head: "Nitro NFT",
        text: "Discover the future of smart living with our NFTs. Our Nitro IoT bots bring real-world utility through the power of IoT. Each NFT is a unique collection of Nitro Bots and earn NCash upon staking. Upgrade your lifestyle today with our one-of-a-kind NFTs"
      },
      link: "/buy-nfm"
    },
    {
      title: "NFT Marketplace ",
      image: Images.nftMarketplace,
      popup: {
        head: "NFT Marketplace",
        text: "Our NFT marketplace, where you can buy and collect the latest Nitro IoT bots"
      },
      link: "/buy-nfm"
    },
    {
      title: "IoT Marketplace",
      image: Images.iotMarketplace,
      popup: {
        head: "IoT Marketplace",
        text: "At our IoT marketplace, you can find the latest technology to enhance your daily experiences. From smart home devices to industrial IoT solutions, we have it all. Browse our collection and find the perfect product to upgrade your lifestyle."
      },
      link: IoTUrl + "/marketplace"
    },
    {
      title: "Enterprise",
      image: Images.enterprise,
      popup: {
        head: "Enterprise Solutions",
        text: "Unlock the full potential of your business with our IoT solutions. Our cutting-edge technology combines advancements in IoT, mobility, sensing, cloud, analytics, and cybersecurity to deliver enhanced safety, reliability, efficiency, sustainability, and connectivity in your operations. With our help, you can achieve innovation at every level and take your business to new heights."
      },
      link: "/"
    },
    {
      title: "Ncash",
      image: Images.ncashNitro,
      popup: {
        head: "Ncash",
        text: "NCash is an AVAX native cryptocurrency and the protocol token of the Nitro Network. It rewards Nitrogens for NFM staking, Nitro’s native staking, Farming on DEXs, Genesis Staking, Private network building and IoT adoption"
      },
      link: "/ncash"
    },
    {
      title: "Staking",
      image: Images.stakingNitro,
      popup: {
        head: "Staking",
        text: "Earn a high annual percentage yield (APY) by staking your NCASH with us. Our platform is designed for easy staking and maximum returns. Join now and start earning with NCASH staking"
      },
      link: StakeURL
    },
    {
      title: "Genesis Node",
      image: Images.genesisNode,
      popup: {
        head: "Genesis Node Staking",
        text: "This is Nitro’s legacy program that is created to reward long-term NCash holders. The privileges available to a Genesis Node are numerous."
      },
      link: GenesisURL + "/genesis-node"
    },
    {
      title: "EMI",
      image: Images.emiNitro,
      popup: {
        head: "EMI",
        text: "Upgrade your NFT collection with our EMI feature. Now you can purchase your favourite NFTs with easy monthly payments. No more waiting, start collecting your dream NFTs today!"
      },
      link: GenesisURL + "/buy-nfm"
    },
    {
      title: "Swapping",
      image: Images.swappingNitro,
      popup: {
        head: "Swapping",
        text: "Upgrade your ERC 20 NCash with our swapping function. Easily exchange your old NCash tokens for Avalanche-based NCash. Make the switch now and enjoy the benefits of the latest technology."
      },
      link: SwapURL
    },
    {
      title: "Burn",
      image: Images.burn,
      popup: {
        head: "Burn",
        text: "Experience the value of our NCASH burns. Regularly burning tokens help create value for our stakeholders. Check out our live dashboard to see the burn in real time."
      },
      link: "/burn"
    },
    {
      title: "Pool and Farms",
      image: Images.poolandfarm,
      popup: {
        head: "Pool and Farm",
        text: "Maximize your returns with our Superfarm on Pangolin. Farm Nitro NFT from NCash   on NFTrade. Join us now for the best farming experience."
      },
      link: "/buy-nfm"
    },
    {
      title: "Nitro Analytics",
      image: Images.nitroAnalytics,
      popup: {
        head: "Nitro Analytics",
        text: "Say goodbye to raw and confusing sensor data, Nitro Analytics presents it in a clear and understandable format, enabling you to make informed decisions."
      },
      link: "/myaccount/analytics"
    },
    {
      title: "Nitro IoN Gateway",
      image: Images.nitroIon,
      popup: {
        head: "Nitro IoN Gateway",
        text: "Our LoRaWAN gateway communicates between end-node devices and the network and builds the private network"
      },
      link: "/buy-nfm"
    }
  ]

  const possibilities = [
    {
      title: "Smart Pool",
      text: "A smart pool system means that you can monitor and control your pool's temperature, pH levels, and chlorine levels from your phone.",
      image: Images.iot_pool
    },
    {
      title: "Smart Home",
      text: "A smart home system means that you can control and automate various aspects of your home such as lighting, heating, and security, all from your phone. ",
      image: Images.iot_home
    },

    {
      title: "Smart Office",
      text: "A smart office system means that you can control and automate various aspects of your office such as lighting, heating, and security, all from your phone, optimizing energy consumption and increasing productivity.",
      image: Images.iot_office
    },
    {
      title: "Smart Security",
      text: "Smart security systems have a range of features that make them more convenient to use and control. Features include motion detection, two-way talk and camera view, the ability to control the system remotely and real-time alerts.",
      image: Images.iot_security
    },

    {
      title: "Smart Waste Management ",
      text: "Smart waste management is a better, more efficient approach to managing waste. It helps to reduce costs, ensure environmentally friendly disposal, and improve efficiency. ",
      image: Images.iot_waste
    },
    {
      title: "Water Tank management",
      text: "Water Tank Management is a smart water tank management solution for commercial and domestic users. By monitoring the water levels, it provides alerts if there is a leak or if the tank is running low. It also helps to prevent water shortages by warning the user if the tank is full.",
      image: Images.iot_watertank
    },
    {
      title: "Cold storage Monitoring",
      text: "A cold storage monitoring system helps to monitor and control the temperature and humidity levels in a refrigerated storage facility, ensuring that the stored products are maintained at optimal conditions and preventing spoilage.",
      image: Images.iot_coldStore
    }
  ]

  const onSubscribe = values => {
    if (!values.email.length) {
      swal({
        // title: "User does not exist!",
        text: "Please enter a valid email",
        icon: "error"
      })
    } else {
      var data = {
        email: values.email
      }

      var config = {
        method: "post",
        url: `${BaseUrl}/addToNewsletterList`,
        headers: {
          "Content-Type": "application/json"
        },
        data: data
      }

      axios(config)
        .then(function (response) {
          if (response.status == 200) {
            swal({
              title: "Success!",
              text: "Congrats! You have successfully subscribed to the Nitro Newsletter.",
              icon: "success"
            })
          }
          subscriptionForm.resetFields()
        })
        .catch(function (error) {
          if (error?.response?.status == 401) {
            logoutHandler()
            return
          } else
            swal({
              // title: "User Already  exist!",
              text: error.response.data.message,
              icon: "warning"
            })
        })
    }
  }
  return (
    <div className="new-homePage PT-90px">
      {/* Hero Section */}
      <div className="hero-section">
        <div className="hero-section__text">
          <h1>
            Empowering the{" "}
            <span className="color-primary"> Next Generation</span> of Smart
            Living
          </h1>

          <p>
            Transform your lifestyle and earn as you make smart choices with
            Nitro Network's IoT marketplace
          </p>

          <div className="cta-buttonss">
            <a
              href="https://youtu.be/rOlN7hOsASs"
              target="_blank"
              aria-label="Play Video About Nitro IoT Lifestyle Transformation"
              rel="noopener noreferrer"
              className="btn-brand btn-active mx-w home-play-btn">
              <img src={Images.playIcon} alt="Play Icon" />
              <span>Play Video</span>
            </a>
            <a
              href={IoTUrl + "/marketplace"}
              aria-label="Checkout the Sensor in Nitro"
              className="btn-brand btn-transparent mx-w ml-3">
              Buy Sensors
            </a>
          </div>
        </div>

        <div className="hero-section__image">
          {/* <!-- Preload the LCP image with a high fetchpriority so it starts loading with the stylesheet. --> */}
          {/* <Helmet>
            <link
              rel="preload"
              // fetchpriority="high"
              as="image"
              // alt="Hero Image"
              href={heroImage}
              // type="image/webp"
            />
          </Helmet> */}
          <img src={Images.hero} alt="Hero Iot Section" />
        </div>
      </div>

      {/* Slider */}
      <div className="home-carousel">
        <Slider {...sliderBanner}>
          <a
            href="https://play.google.com/store/apps/details?id=nitro.network"
            target="_blank"
            aria-label="Download the nitro network app "
            rel="noopener noreferrer">
            <img src={Images.slide4} alt="Banner Markertplace" />
          </a>

          <a href="/buy-nfm" aria-label="Checkout Setup Of nitro Iot Devices">
            <img src={Images.slide1} alt="Banner Markertplace 2" />
          </a>

          <a
            href={IoTUrl + "/marketplace"}
            target="_blank"
            aria-label="Checkout New Offer"
            rel="noopener noreferrer">
            <img src={Images.slide2} alt="Banner Markertplace" />
          </a>

          <a
            href={IoTUrl + "/marketplace"}
            target="_blank"
            aria-label="Checkout Deal on NItro"
            rel="noopener noreferrer">
            <img src={Images.slide3} alt="Banner Markertplace" />
          </a>
        </Slider>
      </div>

      {/* The Mission */}
      <section className="the-mission">
        <h2 className="head-second text-center">
          The
          <span className="color-primary"> Mission</span>
        </h2>
        <div className="home-container the-mission__container">
          <div className="the-mission__text">
            <p className="big-bold">
              Our mission is to enhance the quality of life for individuals by
              creating an IoT ecosystem that empowers them to connect, control,
              and benefit from the technology in their daily lives.
            </p>

            <p>
              We strive to make it easy for people to earn from the technology
              they use by providing opportunities for them to monetize their
              data and smart devices. We want to create a future where
              technology is accessible and beneficial for all, and where people
              use it to live a productive and fulfilling life.
            </p>
          </div>
          <div className="the-mission__image">
            <img src={Images.theMisson} alt="The Misson Nitro " />
          </div>
        </div>
      </section>

      {/* Made For IoT */}
      <section className="made-for-iot">
        <h2 className="head-second text-center">
          Made for
          <span className="color-primary"> IoT Mass Adoption</span>
        </h2>

        <div className="made-for-iot__stats">
          <div className="made-for-iot__stat">
            <p className="big-bold">100K+</p>
            <p>Community strength</p>
          </div>
          <div className="made-for-iot__stat">
            <p className="big-bold">2500+</p>
            <p>Origin NFTs</p>
          </div>
          <div className="made-for-iot__stat">
            <p className="big-bold">40K+</p>
            <p>Use Cases</p>
          </div>
          <div className="made-for-iot__stat">
            <p className="big-bold">{(3127570173).toLocaleString("en-US")}</p>
            <p>NCash Burned</p>
          </div>
          <div className="made-for-iot__stat">
            <p className="big-bold">13+</p>
            <p>Partners</p>
          </div>
        </div>
      </section>

      {/* Transform Your World */}
      <section className="transform-world">
        <h2 className="head-second text-center">
          Unlock New Possibilities with IoT <br />
          <span className="color-primary"> Transform Your World</span>
        </h2>

        <div className="transform-world__slider">
          <Slider {...sliderPossibilities}>
            {possibilities.map(possibilitie => {
              return (
                <div
                  className="transform-world__possible"
                  key={possibilitie.title}>
                  <div className="transform-world__possible--text">
                    <p className="big-bold">{possibilitie.title}</p>

                    <p>{possibilitie.text}</p>
                  </div>
                  <div className="transform-world__possible--image">
                    <img
                      src={possibilitie.image}
                      alt={possibilitie.title}
                      loading="lazy"
                    />
                  </div>
                </div>
              )
            })}
          </Slider>
        </div>
      </section>

      {/* Discover Nitro */}
      <section className="discover-nitro">
        <h2 className="head-second text-center">
          Discover
          <span className="color-primary"> Nitro Network</span>
        </h2>

        <div className="discover-nitro__boxes">
          {nitroWorlds.map((nitroWorld, index) => {
            return (
              <React.Fragment key={index}>
                <a
                  href={nitroWorld.link}
                  target="_blank"
                  rel="noreferrer"
                  className="discover-nitro__box"
                  aria-label={`Read more about ${nitroWorld.title}`}
                  key={index}>
                  <img
                    src={nitroWorld.image}
                    alt={nitroWorld.title}
                    loading="lazy"
                  />
                  <p>{nitroWorld.title}</p>
                </a>
              </React.Fragment>
            )
          })}
        </div>
        {/* Popup For Options */}
        {/* {openPopup && (
          <Popup setOpenPopup={setOpenPopup} heading={popupContent.head}>
            <p>{popupContent.text}</p>
          </Popup>
        )} */}
      </section>

      {/* Resources */}
      <section className="resources">
        <div className="home-container ">
          <h2 className="head-second text-center">Resources</h2>

          <div className="resources__cards">
            <a
              href="https://docsend.com/view/ew2zhqczqgpvc8yh"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Read more about Nitro Litepapers"
              className="resources__card  p-small">
              <div className="resources__image">
                <img
                  src={Images.niotLitepaper}
                  alt="REsources Nitro"
                  loading="lazy"
                />
              </div>
              <p className="text-center">NIoT Litepaper</p>
            </a>
            <a
              href="https://docsend.com/view/69wukxyj6vin9bvy"
              target="_blank"
              aria-label="Read more about Nitro Whitepaper"
              rel="noopener noreferrer"
              className="resources__card  p-small">
              <div className="resources__image">
                <img
                  src={Images.whitepaper}
                  alt="REsources nitro whitepaper"
                  loading="lazy"
                />
              </div>
              <p className="text-center">NFM Whitepaper</p>
            </a>
            <a
              href="https://docs.nitro.network/"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Read more about Nitro Documentation"
              className="resources__card  p-small">
              <div className="resources__image">
                <img
                  src={Images.nitrodoc}
                  alt="REsources nitro docs"
                  loading="lazy"
                />
              </div>
              <p className="text-center">Documentation</p>
            </a>
          </div>
        </div>
      </section>

      {/* Our Crypto Partners */}
      <section className="media-partners">
        <div className="home-container">
          <h2 className="head-second text-center">Our Crypto Partners</h2>

          <div className="media-partners__logos">
            <div className="media-partners__logo">
              <img
                src={Images.cointelegraph2}
                alt="Media Partner"
                loading="lazy"
              />
            </div>
            <div className="media-partners__logo">
              <img src={Images.avalance} alt="Media Partner" loading="lazy" />
            </div>
            <div className="media-partners__logo">
              <img src={Images.pangolin} alt="Media Partner" loading="lazy" />
            </div>
            <div className="media-partners__logo">
              <img src={Images.beefy} alt="Media Partner" loading="lazy" />
            </div>
            <div className="media-partners__logo">
              <img src={Images.redlight} alt="Media Partner" loading="lazy" />
            </div>
            <div className="media-partners__logo">
              <img src={Images.techm} alt="Media Partner" loading="lazy" />
            </div>
            <div className="media-partners__logo">
              <img src={Images.openSea} alt="Media Partner" loading="lazy" />
            </div>
          </div>
        </div>
      </section>
      {/* Our IoT Partners */}
      <section className="media-partners">
        <div className="home-container">
          <h2 className="head-second text-center">Our IoT Partners</h2>

          <div className="media-partners__logos">
            <div className="media-partners__logo">
              <img src={Images.tektelic} alt="IoT Partner" />
            </div>
            <div className="media-partners__logo">
              <img src={Images.milesight} alt="IoT Partner" loading="lazy" />
            </div>
            <div className="media-partners__logo">
              <img src={Images.dragino} alt="IoT Partner" loading="lazy" />
            </div>
            <div className="media-partners__logo">
              <img src={Images.falconx} alt="IoT Partner" loading="lazy" />
            </div>
            <div className="media-partners__logo">
              <img src={Images.amantya} alt="IoT Partner" loading="lazy" />
            </div>
            <div className="media-partners__logo">
              <img src={Images.alliot} alt="IoT Partner" loading="lazy" />
            </div>
            <div className="media-partners__logo">
              <img src={Images.enthu} alt="IoT Partner" loading="lazy" />
            </div>
          </div>
        </div>
      </section>
      {/* Our Media Partners */}
      <section className="media-partners">
        <div className="home-container">
          <h2 className="head-second text-center">Our Media Partners</h2>

          <div className="media-partners__logos">
            <div className="media-partners__logo">
              <img src={Images.ibc} alt="Media Partner" loading="lazy" />
            </div>
            <div className="media-partners__logo">
              <img
                src={Images.investerobserver}
                alt="Media Partner"
                loading="lazy"
              />
            </div>
            <div className="media-partners__logo">
              <img src={Images.yahoo} alt="Media Partner" loading="lazy" />
            </div>
          </div>
        </div>
      </section>
      {/* Join Community */}
      <section className="join-community">
        <JoinCommunity />
      </section>

      {/* Stay Tuned  */}
      <section className="stay-updated">
        <h3 className="head-second text-center mb-0">Stay Updated!</h3>
        <p>Subscribe to our monthly news letter</p>

        <div className="">
          <Form
            name="subscriptionForm"
            id="subscription_form"
            className="subscribe-form"
            autoComplete="off"
            form={subscriptionForm}
            onFinish={onSubscribe}>
            {/* <div className="form-field "> */}
            <Form.Item
              name="email"
              className=""
              rules={[
                {
                  required: true,
                  message: "Email is required."
                },
                {
                  type: "email",
                  message: "The email is not valid."
                }
              ]}>
              <Input type="text" id="email" placeholder="Email Address" />
            </Form.Item>
            {/* </div> */}

            <button className="" type="submit">
              Subscribe
            </button>
          </Form>
        </div>
      </section>

      {/* Contact US  */}
      <div className="contact-section">
        <div className="contact">
          <div className="image-logo">
            <img
              src={Images.contactSvg}
              alt="Contact Svg Person using laptop"
            />
          </div>
          <div className="form-side">
            <h2 className="text-center">Contact Us</h2>
            <Form
              name="contactForm"
              id="contact_form"
              autoComplete="off"
              form={form}
              onFinish={submitContactForm}>
              {/* <form action=""> */}
              <div className="half-field">
                <div className="form-field">
                  <Form.Item
                    name="fName"
                    rules={[
                      {
                        required: true,
                        message: "First Name is required."
                      }
                    ]}>
                    <Input type="text" id="fname" placeholder="First Name" />
                  </Form.Item>
                  {/* <input type="text" id="fname" placeholder="First Name" /> */}
                </div>
                <div className="form-field">
                  <Form.Item
                    name="lName"
                    rules={[
                      {
                        required: true,
                        message: "Last Name is required."
                      }
                    ]}>
                    <Input type="text" id="lname" placeholder="Last Name" />
                  </Form.Item>
                  {/* <input type="text" id="lname" placeholder="Last Name" /> */}
                </div>
              </div>

              <div className="form-field ">
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Email is required."
                    },
                    {
                      type: "email",
                      message: "The email is not valid."
                    }
                  ]}>
                  <Input type="text" id="email" placeholder="Email Address" />
                </Form.Item>
              </div>

              <div className="form-field margin-0-after-error">
                <Form.Item
                  name="comment"
                  rules={[
                    {
                      required: true,
                      message: "Comment is required."
                    }
                  ]}>
                  <textarea
                    id="comment"
                    cols="30"
                    rows="5"
                    placeholder="Comment"></textarea>
                </Form.Item>
              </div>
              <div className="submit-btn-eco">
                <button
                  type="submit"
                  className="btn-brand btn-active text-uppercase"
                  style={{
                    border: "none"
                  }}>
                  Send Message
                </button>
                {/* <a href="#" className="learn-more-btn w-100 uppercase">
                  Send MEssage
                </a> */}
              </div>
              {/* </form> */}
            </Form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HomepageNew
