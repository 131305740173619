import React, { useState, useEffect, useRef } from "react"
import {
  BrowserRouter as Router,
  Link,
  useHistory,
  useLocation
} from "react-router-dom"
import { useNavigate } from "@reach/router"
import { Pagination, Input } from "antd"
import { BaseUrl } from "../../utils/config"
import { createGlobalStyle } from "styled-components"
import {
  availabilityOptions,
  categoryOptions,
  statusOptions
} from "../../Shared"
import Footer from "../components/footer"
import Menu from "./menu"
import Dropdown from "../components/dropdown"
import axios from "axios"
import { useSelector, useDispatch } from "react-redux"
import { Images } from "../../Shared/images"
import { capitalize } from "../../utils"
import { setActiveMenu, setFilterChanged } from "../../redux/actions"
import { setConnectedWallet, updateUserDetailsRedux } from "../../redux/actions"
//Styles
import "../styles/table.css"
import "../../assets/css/marketplace.css"

//Images
import clickit from "./images/clickit.gif"
import swal from "sweetalert"
const { Search } = Input

const GlobalStyles = createGlobalStyle`
  header#myHeader.navbar.white {
    background: #212428;
  }
`

const MyCollectionAll = props => {
  const user = localStorage.getItem("loginUser")
  const [allProducts, setAllProducts] = useState([])
  const [getData, setGetData] = useState([])
  const pageItems = 12
  const [viewProduct, setViewProduct] = useState(false)
  const [current, setCurrent] = useState(1)
  const [pagesDiv, setPagesDiv] = useState(0)
  const [tData, setTData] = useState([])
  const history = useHistory()
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const userData = useSelector(state => state.common.userDetails)
  const filterChanged = useSelector(state => state.common.filterChanged)

  const [filteredProduct, setFilteredProduct] = useState(null)

  const totalPages = 1249
  const [clickedIndex, setClickedIndex] = useState(-1)
  const [lastElement, setLastElement] = useState(null)
  const [loading, setLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(0)

  const initialCategory =
    props.location.state?.initialCategory ?? categoryOptions[0].value
  const [category, setCategory] = useState(initialCategory)

  const [status, setStatus] = useState(statusOptions[0].value)

  const [currentTier, setCurrentTier] = useState(
    categoryOptions.find(el => el.value === initialCategory)?.tier ?? ""
  )

  const [filteredProducts, setFilteredProducts] = useState([])

  const [tierQuantity, setTierQuantity] = useState({
    ...{
      "tier-1": 0,
      "tier-2": 0,
      "tier-3": 0
    },
    [categoryOptions.find(el => el.value === initialCategory)?.tier ?? ""]: 1
  })

  const fetchProducts = (changed = "") => {
    let apiCategory = category ? category : null

    let url = `${BaseUrl}/product/get?page=${
      changed !== "currentPage" ? 0 : currentPage
    }${apiCategory ? `&categoryId=${apiCategory}` : ""}`

    changed !== "currentPage" && setCurrentPage(0)

    setLoading(true)
    axios
      .get(url)
      .then(response => {
        if (response.status === 200) {
          // setFilteredProducts((prevFilteredProducts) =>
          //   changed === "currentPage" && apiCategory
          //     ? [...new Set([...prevFilteredProducts, ...response.data.data])]
          //     : response.data.data
          // );
          if (apiCategory) {
            setFilteredProduct(response.data.data[0])
          } else {
            setAllProducts(response.data.data)
            setFilteredProduct(null)
          }
        } else if (response.status == 401) {
          dispatch(updateUserDetailsRedux({}))
          dispatch(setConnectedWallet(""))
          localStorage.clear()
          navigate("/login")
        } else if (response.status == 404) {
          swal({
            title: "Error!",
            text: "Page not found. Please try again later.",
            icon: "error"
          })
        } else if (response.status == 500) {
          swal({
            title: "Error!",
            text: "Something went wrong. Please try again later.",
            icon: "error"
          })
        } else if (response.status == 503) {
          swal({
            title: "Error!",
            text: "Something went wrong. Please try again later.",
            icon: "error"
          })
        } else {
          // setFilteredProducts([]);
          // setAllProducts((prevProducts) =>
          //   changed === "currentPage"
          //     ? [...new Set([...prevProducts, ...response.data.data])]
          //     : response.data.data
          // );
          setAllProducts([])
          setFilteredProduct(null)
          console.log("else throwing error")
          swal({
            title: "Error!",
            text: "Something went wrong. Please try again later.",
            icon: "error"
          })
        }
      })
      .catch(error => {
        if (error?.response?.status == 401) {
          dispatch(updateUserDetailsRedux({}))
          dispatch(setConnectedWallet(""))
          localStorage.clear()
          navigate("/login")
        } else
          swal({
            title: "Error!",
            text: "Something went wrong. Please try again later.",
            icon: "error"
          })
      })
    setLoading(false)
  }

  const onviewproduct = id => {
    setViewProduct(true)
    navigate("/productdetails", {
      state: { selectedId: id, type: "marketplace" }
    })
  }

  const toLogin = () => {
    history.push("/login")
  }

  const onSearch = e => {
    const searchedData = []
    e != ""
      ? allProducts.map(item => {
          item.title.includes(e) && searchedData.push(item)
        })
      : setTData(getData.slice(0, pageItems))
    e != "" && setTData(searchedData)

    // searchedData.length<=1 && setTData(getData.slice(0, pageItems));
  }

  const goPagination = val => {
    setCurrent(val)
    const passval = (val - 1) * pageItems
    setTData(getData.slice(passval, passval + pageItems))
  }

  //Change Handlers
  const categoryHandler = e => {
    dispatch(setFilterChanged(true))
    setCategory(Number(e.target.value))
  }

  // Observer for Infinite Scroll
  const observer = useRef(
    new IntersectionObserver(entries => {
      const domElement = entries[0]
      if (domElement.isIntersecting) {
        setCurrentPage(prevPage => prevPage + 1)
      }
    })
  )

  const soldNfts = [
    {
      product_id: 1,
      mystery: "Iconic series 1 - 0001",
      product_desc:
        "A part of Iconic collection of NFT from Nitro network with the perpetual reward potential and an ever appreciating value with re-sale or hodling opportunity ! Good treasure to hold ! ",
      product_sequence_id: "23",
      product_code: "3434",
      product_image: Images.tier11,
      availableStatus: 1,
      group_name: "Gold",
      category_name: "Tier-1",
      price: 750
    },
    {
      product_id: 2,
      mystery: "Iconic series 1 - 0002",
      product_desc:
        "A part of Iconic collection of NFT from Nitro network with the perpetual reward potential and an ever appreciating value with re-sale or hodling opportunity ! Good treasure to hold ! ",
      product_sequence_id: "23",
      product_code: "3435",
      product_image: Images.tier12,
      availableStatus: 1,
      group_name: "Gold",
      category_name: "Tier-1",
      price: 750
    },
    {
      product_id: 3,
      mystery: "Iconic series 1 - 0003",
      product_desc:
        "A part of Iconic collection of NFT from Nitro network with the perpetual reward potential and an ever appreciating value with re-sale or hodling opportunity ! Good treasure to hold ! ",
      product_sequence_id: "23",
      product_code: "3435",
      product_image: Images.tier13,
      availableStatus: 1,
      group_name: "Gold",
      category_name: "Tier-1",
      price: 750
    },
    {
      product_id: 4,
      mystery: "Premium series 1 - 1111",
      product_desc:
        "A part of Premium collection of NFT from Nitro network with the perpetual reward potential and an ever appreciating value with re-sale or hodling opportunity ! Good treasure to hold ! ",
      product_sequence_id: "23",
      product_code: "3435",
      product_image: Images.tier21,
      availableStatus: 1,
      group_name: "Gold",
      category_name: "Tier-2",
      price: 650
    },
    {
      product_id: 5,
      mystery: "Premium series 1 - 1112",
      product_desc:
        "A part of Premium collection of NFT from Nitro network with the perpetual reward potential and an ever appreciating value with re-sale or hodling opportunity ! Good treasure to hold ! ",
      product_sequence_id: "23",
      product_code: "3435",
      product_image: Images.tier22,
      availableStatus: 1,
      group_name: "Gold",
      category_name: "Tier-2",
      price: 650
    },
    {
      product_id: 6,
      mystery: "Premium series 1 - 1113",
      product_desc:
        "A part of Premium collection of NFT from Nitro network with the perpetual reward potential and an ever appreciating value with re-sale or hodling opportunity ! Good treasure to hold ! ",
      product_sequence_id: "23",
      product_code: "3435",
      product_image: Images.tier23,
      availableStatus: 1,
      group_name: "Gold",
      category_name: "Tier-2",
      price: 650
    },
    {
      product_id: 7,
      mystery: "Premium series 1 - 1114",
      product_desc:
        "A part of Premium collection of NFT from Nitro network with the perpetual reward potential and an ever appreciating value with re-sale or hodling opportunity ! Good treasure to hold ! ",
      product_sequence_id: "23",
      product_code: "3435",
      product_image: Images.tier24,
      availableStatus: 1,
      group_name: "Gold",
      category_name: "Tier-2",
      price: 650
    },
    {
      product_id: 8,
      mystery: "Top series 1 - 2222",
      product_desc:
        "A part of Top collection of NFT from Nitro network with the perpetual reward potential and an ever appreciating value with re-sale or hodling opportunity ! Good treasure to hold ! ",
      product_sequence_id: "23",
      product_code: "3435",
      product_image: Images.tier31,
      availableStatus: 1,
      group_name: "Gold",
      category_name: "Tier-3",
      price: 450
    },
    {
      product_id: 9,
      mystery: "Top series 1 - 2223",
      product_desc:
        "A part of Top collection of NFT from Nitro network with the perpetual reward potential and an ever appreciating value with re-sale or hodling opportunity ! Good treasure to hold ! ",
      product_sequence_id: "23",
      product_code: "3435",
      product_image: Images.tier32,
      availableStatus: 1,
      group_name: "Gold",
      category_name: "Tier-3",
      price: 450
    },
    {
      product_id: 10,
      mystery: "Top series 1 - 2224",
      product_desc:
        "A part of Top collection of NFT from Nitro network with the perpetual reward potential and an ever appreciating value with re-sale or hodling opportunity ! Good treasure to hold ! ",
      product_sequence_id: "23",
      product_code: "3435",
      product_image: Images.tier33,
      availableStatus: 1,
      group_name: "Gold",
      category_name: "Tier-3",
      price: 450
    }
  ]
  // Use Effects
  useEffect(() => {
    const currentElement = lastElement
    const currentObserver = observer.current

    if (currentElement) {
      //null check
      currentObserver.observe(currentElement)
    }
    return () => {
      if (currentElement) {
        currentObserver.unobserve(currentElement)
      }
    }
  }, [lastElement])

  useEffect(() => {
    if (currentPage <= totalPages) {
      fetchProducts("currentPage") // current page changed
    }
  }, [currentPage])

  useEffect(() => {
    fetchProducts("category") // category changed
    let newTier = categoryOptions.find(el => el.value === category)?.tier ?? ""
    setCurrentTier(newTier)
    setTierQuantity({
      ...{
        "tier-1": 0,
        "tier-2": 0,
        "tier-3": 0
      },
      [newTier]: 1
    })
  }, [category])

  const placeOrder = () => {
    let loginToken = userData?.loginUserToken
    let data = {
      tier: tierQuantity
    }
    var config = {
      method: "post",
      url: `${BaseUrl}/preorder`,
      headers: {
        "Content-Type": "application/json"
      },
      data: data
    }
    axios(config)
      .then(response => {
        console.log("response", response)
        if (response.status == 200) {
          if (loginToken) {
            axios
              .get(`${BaseUrl}/addorder/${response.data.token}`, {
                headers: {
                  Authorization: `Bearer ${loginToken}`
                }
              })
              .then(response => {
                console.log(response, "new resp")
                if (response.status == 200) {
                  navigate("/myaccount/pendingpayments")
                  dispatch(setActiveMenu("NFM"))
                }
              })
          } else {
            localStorage.clear()
            localStorage.setItem("buyNitroToken", response.data.token)
            navigate("/login")
          }
        } else if (response.status == 401) {
          dispatch(updateUserDetailsRedux({}))
          dispatch(setConnectedWallet(""))
          localStorage.clear()
          navigate("/login")
        } else if (response.status == 404) {
          swal({
            title: "Error!",
            text: "Page not found. Please try again later.",
            icon: "error"
          })
        } else if (response.status == 500) {
          swal({
            title: "Error!",
            text: "Something went wrong. Please try again later.",
            icon: "error"
          })
        } else if (response.status == 503) {
          swal({
            title: "Error!",
            text: "Something went wrong. Please try again later.",
            icon: "error"
          })
        } else {
          console.log("response")

          swal({
            title: "Failed",
            text: "Something went wrong.",
            icon: "error"
          })
        }
      })
      .catch(error => {
        if (error?.response?.status == 401) {
          dispatch(updateUserDetailsRedux({}))
          dispatch(setConnectedWallet(""))
          localStorage.clear()
          navigate("/login")
        } else
          swal({
            title: "Failed",
            text: "Something went wrong.",
            icon: "error"
          })
      })
  }

  return (
    <div className="dark-scheme">
      {/* <GlobalStyles/> */}
      <section
        className="jumbotron breadcumb no-bg"
        style={{
          minHeight: "unset"
        }}>
        <div className="mainbreadcumb">
          <div className="container">
            <div className="row m-10-hor">
              <div className="col-12">
                <h1 className="text-center">Marketplace</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="backgroundColordark ">
        <div className="row wow fade-in collection-boxes">
          <div className="btn-connect-wallet">
            <button className="gradient-button">Connect to wallet</button>
          </div>
          <div className="row wow fade-in collection-boxes">
            {soldNfts.map((nft, index) => (
              <div
                key={index}
                onClick={() => {
                  navigate("/productdetails", {
                    state: {
                      selectedNFM: nft,
                      type: "marketplace"
                    }
                  })
                }}
                className="d-item col-lg-3 col-md-6 col-sm-6 col-xs-12">
                <div
                  className={`nft__item${
                    clickedIndex === index ? " mobile__view" : ""
                  }`}>
                  <div className="nft__item_wrap">
                    <a>
                      <img
                        src={nft?.product_image}
                        className="lazy nft__item_preview"
                        alt={nft?.mystery}
                      />
                    </a>
                    <img
                      src={clickit}
                      id="iconclick"
                      onClick={() => {
                        setClickedIndex(index)
                      }}
                      alt="Click Animation"
                    />
                  </div>
                  <div className="nft__item_info row-justify-custom">
                    <div>
                      <p className="purple-text">{nft?.mystery}</p>
                      {/* <p>P #152412</p> */}
                    </div>
                    <div className="text-right">
                      {/* <p>450 $</p> */}
                      <p className="pink-text">Price: ${nft?.price}</p>
                    </div>
                  </div>
                  <div className="nft__item_info row-justify-custom">
                    <div>
                      <p className="purple-text">$750</p>
                      {/* <p>P #152412</p> */}
                    </div>
                    <div className="text-right">
                      {/* <p>450 $</p> */}
                      <p className="pink-text">$1250</p>
                    </div>
                  </div>
                  <div className="w-100 my-3">
                    <button className="gradient-button w-100">
                      Mint Your NFM
                    </button>
                  </div>
                </div>
                {/* <NftCard nftObject={nft} /> */}
              </div>
            ))}
          </div>
        </div>
      </section>
      {/* <Footer /> */}
    </div>
  )
}
export default MyCollectionAll
