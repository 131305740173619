import React, { useState, useEffect } from "react"
import { Form, Input } from "antd"
import { BaseUrl } from "../../utils/config"
import { useLocation } from "react-router-dom"
import axios from "axios"
import swal from "sweetalert"
import { useNavigate } from "@reach/router"
import { updateUserDetailsRedux } from "../../redux/actions"
import { connect } from "react-redux"

const EditProfile = ({ userDetails = {}, updateUserDetails = () => {} }) => {
  // const history = useHistory()
  const navigate = useNavigate()
  // const dispatch = useDispatch()
  const location = useLocation()
  // const loginUserToken = localStorage.getItem("loginUserToken")
  const [isName, setIsName] = useState(null)
  const [isEmail, setIsEmail] = useState(null)
  const [changed, setChanged] = useState(false)
  // const loginUserId = localStorage.getItem("loginUserId")
  const loginUserMail = localStorage.getItem("loginUserMail")
  const loginUser = localStorage.getItem("loginUser")
  const [form] = Form.useForm()
  useEffect(() => {
    window.scrollTo(0, 0)
    let path = location.pathname
    let temp = path.substring(path.lastIndexOf("/") + 1)
    //  Base64.decode(this.props.match.params.id);

    if (location.state) {
      setIsEmail(location.state?.email1)
    }
    form.setFieldsValue({
      email: loginUserMail,
      name: loginUser
    })
    // }
  }, [location])

  const onFinish = values => {
    const Fobj = {
      name: values.name
    }

    if (
      values.email == null ||
      values.name == null ||
      !values.name.match(/^([A-Za-z]+ )+[A-Za-z]+$|^[A-Za-z]+$/)
    ) {
      swal({
        title: "",
        text: "Please enter valid name",
        icon: "error"
      })
    } else if (!changed) {
      swal({
        title: "",
        text: "Please update profile before submitting",
        icon: "error"
      })
    } else {
      axios
        .put(`${BaseUrl}/edituser/${userDetails.loginUserId}`, Fobj, {
          headers: {
            // 'Content-Type': 'application/json',
            Authorization: `Bearer ${userDetails.loginUserToken}`
          }
        })
        .then(response => {
          if (response.status == 200) {
            // "Your Federal Account Created Successfully"
            // message.success(`Congratulations  Your Federal Account Created Successfully`,3)
            updateUserDetails({
              ...userDetails,
              loginUser: values.name
            })
            swal({
              title: "Success!",
              text: "User name updated successfully",
              icon: "success"
            })
            navigate("/settings")
            window?.localStorage.setItem("loginUser", values.name)
          } else if (response.status == 404) {
            swal({
              title: "Error!",
              text: "Page not found. Please try again later.",
              icon: "error"
            })
          } else if (response.status == 500) {
            swal({
              title: "Error!",
              text: "Something went wrong. Please try again later.",
              icon: "error"
            })
          } else if (response.status == 503) {
            swal({
              title: "Error!",
              text: "Something went wrong. Please try again later.",
              icon: "error"
            })
          } else {
            swal({
              title: "Invalid Credentials",
              text: "Please try again",
              icon: "error"
            })
          }
        })
        .catch(error => {
          if (error?.response?.status === 401) {
            localStorage.clear()
            updateUserDetails({})
            navigate("/login")
          }
        })
    }
  }

  const toProfilepage = () => {
    navigate("/settings")
  }

  return (
    <div className="Edit-profile PT-90px">
      <div className="profile-section">
        <h1 className="text-center">Edit Profile</h1>

        <Form
          name="contactForm"
          id="contact_form"
          action=""
          autoComplete="off"
          form={form}
          // name="control-hooks"
          onFinish={onFinish}>
          <div className="field-set">
            <label for="email">Email</label>
            <Form.Item
              name="email"
              rules={[
                {
                  type: "email",
                  message: "The input is not valid Email!"
                },
                {
                  required: true,
                  message: "Enter the email"
                }
              ]}>
              <Input
                id="email"
                className="form-control"
                disabled
                placeholder="Email"
                onChange={e => {
                  setIsEmail(e.target.value)
                }}
              />
            </Form.Item>
          </div>
          <div className="field-set">
            <label for="email">Name</label>
            <Form.Item
              name="name"
              rules={[
                {
                  required: true,
                  message: "Name is required."
                },
                {
                  validator: (_, value) => {
                    if (value.match(/^([A-Za-z]+ )+[A-Za-z]+$|^[A-Za-z]+$/)) {
                      return Promise.resolve()
                    } else
                      return Promise.reject(
                        new Error(
                          "Name should not contain digits/numbers or space at the ends"
                        )
                      )
                  }
                }
              ]}>
              <Input
                id="name"
                placeholder="Enter name"
                className="form-control"
                onChange={e => {
                  setIsName(e.target.value)
                  setChanged(true)
                }}
              />
            </Form.Item>
          </div>

          <div id="submit" className="d-flex text-right">
            <input
              type="button"
              onClick={toProfilepage}
              id="send_message"
              value="Cancel"
              className="btn-brand btn-transparent btn-sm mx-w mr-4"
            />
            <input
              type="submit"
              id="send_message"
              value="Update profile"
              className="btn-brand btn-active btn-sm mx-w "
            />

            <div className="clearfix"></div>

            <div className="spacer-single"></div>
          </div>
        </Form>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    userDetails: state.common.userDetails
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updateUserDetails: payload => dispatch(updateUserDetailsRedux(payload))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditProfile)
