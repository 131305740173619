import { useNavigate } from "@reach/router"
import React, { useCallback, useEffect, useState } from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick-theme.css"
import "slick-carousel/slick/slick.css"
import { blogCategoryOptions } from "../../Shared"
import Dropdown from "../components/dropdown"
//Blog Data
import blogArticles from "../../assets/htmlPages/blog-articles.json"
import blogLibraries from "../../assets/htmlPages/blog-library.json"
import blogNews from "../../assets/htmlPages/blog-news.json"

const WordPressBlogs = () => {
  const navigate = useNavigate()
  const [blogCategory, setBlogCategory] = useState(blogCategoryOptions[0].value)
  const [sortedBlogArticles, setSortedBlogArticles] = useState(blogArticles)
  const [sortedBlogNews, setSortedBlogNews] = useState(blogNews)
  const [sortedBlogLibraries, setSortedBlogLibraries] = useState(blogLibraries)

  const slideBlog = {
    dots: true,
    arrows: true,
    autoplaySpeed: 10000,
    speed: 500,
    // nextArrow: <NextArrow />,
    // prevArrow: <PrevArrow />
    infinite: true,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  }

  const blogCategoryJSON = {
    all: [],
    articles: sortedBlogArticles,
    news: sortedBlogNews,
    libraries: sortedBlogLibraries
  }

  const SliderItems = itemList => {
    return itemList.map(item => (
      <div key={item.slug} className="cms-blog__blog">
        <div onClick={() => navigate(`/blog/${item.slug}`)}>
          <img src={item.image} alt={item.title} />
          <h6>{item.title}</h6>
          <p>
            <small>{item?.date}</small>
          </p>
        </div>
      </div>
    ))
  }

  const sortBlogTypes = useCallback((blogType, blogTypeState) => {
    //ex:  articles , news , libraries : blogType
    const sortedBlogs = blogType.sort(
      (a, b) => new Date(b.date) - new Date(a.date)
    )
    blogTypeState(sortedBlogs)
  }, [])

  useEffect(() => {
    sortBlogTypes(sortedBlogArticles, setSortedBlogArticles)
    sortBlogTypes(sortedBlogLibraries, setSortedBlogLibraries)
    sortBlogTypes(sortedBlogNews, setSortedBlogNews)
  }, [])

  return (
    <div className="cms-blog-page">
      <div className="cms-blog__dropdown">
        <span>Choose Category: </span>
        <Dropdown
          className="form-control apperrance-auto"
          options={blogCategoryOptions}
          handleChange={e => setBlogCategory(e.target.value)}
          value={blogCategory}
        />
      </div>

      {blogCategory === "all" ? (
        <>
          <div className="cms-blog__blogs">
            <h3>Articles</h3>
            <div className="cms-blog__allBlogs">
              <Slider {...slideBlog}>{SliderItems(sortedBlogArticles)}</Slider>
            </div>
          </div>
          <div className="cms-blog__blogs">
            <h3>News</h3>
            <div className="cms-blog__allBlogs">
              <Slider {...slideBlog}>{SliderItems(sortedBlogNews)}</Slider>
            </div>
          </div>
          <div className="cms-blog__blogs">
            <h3>Libraries</h3>
            <div className="cms-blog__allBlogs">
              <Slider {...slideBlog}>{SliderItems(sortedBlogLibraries)}</Slider>
            </div>
          </div>
        </>
      ) : (
        <>
          <h3>
            {blogCategoryOptions.find(cat => cat.value === blogCategory).label}
          </h3>
          <div className="cms-blog__grid">
            {blogCategoryJSON[blogCategory].map(blogItem => (
              <>
                <div key={blogItem.slug} className="cms-blog__blog">
                  <div onClick={() => navigate(`/blog/${blogItem.slug}`)}>
                    <img src={blogItem.image} alt={blogItem.title} />
                    <h6>{blogItem.title}</h6>
                    <p>
                      <small>{blogItem?.date}</small>
                    </p>
                  </div>
                </div>
              </>
            ))}
          </div>
        </>
      )}
    </div>
  )
}

export default WordPressBlogs
