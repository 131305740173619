import React, { useState, useEffect, useRef } from "react"
import { createGlobalStyle } from "styled-components"
import { Form, Input, Modal, Popconfirm, Checkbox, Tooltip } from "antd"
import { BaseUrl } from "../../utils/config"
import { useNavigate } from "@reach/router"
import { connect, useSelector, useDispatch } from "react-redux"
import { updateUserDetailsRedux, setConnectedWallet } from "../../redux/actions"
import {
  BrowserRouter as Router,
  Link,
  useHistory,
  useLocation
} from "react-router-dom"
import axios from "axios"
import swal from "sweetalert"
import * as FaIcons from "react-icons/fa"
import moment from "moment"
import Dropdown from "../components/dropdown"
import "../styles/table.css"
import "../../assets/css/myNest.css"
import { nestDurationOptions } from "../../Shared"
import useSelection from "antd/lib/table/hooks/useSelection"
import CustomModal from "../components/CustomModal"

const GlobalStyles = createGlobalStyle`
  header#myHeader.navbar.white {
    background: #212428;
  }
`

const customStyles = {
  option: (base, state) => ({
    ...base,
    background: "#212428",
    color: "#fff",
    borderRadius: state.isFocused ? "0" : 0,
    "&:hover": {
      background: "#16181b"
    }
  }),
  menu: base => ({
    ...base,
    background: "#212428 !important",
    borderRadius: 0,
    marginTop: 0
  }),
  menuList: base => ({
    ...base,
    padding: 0
  }),
  control: (base, state) => ({
    ...base,
    padding: 2
  })
}

const MyNest = ({ updateUserDetails }) => {
  const [allproducts, setAllproducts] = useState([])
  const [tableData, setTableData] = useState([])
  const [showModal, setShowModal] = useState(false)
  const dispatch = useDispatch()
  const selectedPlace = useRef(null)
  const [duration, setDuration] = useState(nestDurationOptions[3].value)

  const [tnc, setTNC] = useState(false)

  const location = useLocation()
  const navigate = useNavigate()

  const userDetails = useSelector(state => state.common.userDetails)

  const loginUserToken = localStorage.getItem("loginUserToken")

  useEffect(() => {
    loadTable()
  }, [])

  const KYCGuidelines = [
    "We accept JPEG, JPG, PNG and PDF files.",
    "All the fields in the application form should match the proof of documents you submit, such as first name, last name, and address proof.  ",
    "Do NOT  upload any copy/scanned copy of any document. Scanned documents or copies will NOT be accepted under any circumstances",
    "Low quality images such as images with low lighting, reflections, wrong angles, and poor resolution are unacceptable. The system will also not accept extraneous objects in pictures  Extraneous objects refer to a hand holding the document, any object placed across the document, background objects cutting into the document etc.",
    "The photo should highlight all corners of the document. Please make provision for the padding space (Padding space = a minimum of 5 mm).",
    "In case the document has two pages or two sides, please attach both sides/pages. While attaching your passport, make sure to upload the top and bottom pages of the particular document. The bottom page refers to the page with the picture, full name and additional personal information. The top page is the one above the document page that has the picture.",
    "While uploading any ID card, upload pictures of BOTH sides of the document. Please upload both sides, even if you see no information on any document sides.",
    "When uploading your  driving licence, make sure to upload BOTH sides. You are required to upload both sides, even if  there is no information on any of the sides of the licence.",
    "Make sure to switch off the option of embedding the picture watermark on the phone. This is because in some phones, upon clicking on the document, the device’s name is automatically embedded on the picture.  ",
    "The system will not accept documents corrected by any graphic editing. In addition, make sure you do NOT cover/hide any specific part of the documents.",
    "Do NOT upload the cover of your passport.",
    "Never upload more than one document simultaneously.  "
  ]

  useEffect(() => {
    if (allproducts && allproducts?.length) {
      let tempArr = [...allproducts]
      tempArr?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
      setAllproducts(tempArr)
    }
  }, [tableData])

  const loadTable = () => {
    const isTemp = userDetails?.loginUserId

    axios
      .get(`${BaseUrl}/getplaces/${isTemp}`, {
        headers: {
          // 'Content-Type': 'application/json',
          Authorization: `Bearer ${loginUserToken}`
        }
      })
      .then(response => {
        if (response.status == 200) {
          setAllproducts(response.data.data)
          setTableData(response.data.data)
        } else if (response.status == 404) {
          swal({
            title: "Error!",
            text: "Page not found. Please try again later.",
            icon: "error"
          })
        } else if (response.status == 500) {
          swal({
            title: "Error!",
            text: "Something went wrong. Please try again later.",
            icon: "error"
          })
        } else if (response.status == 503) {
          swal({
            title: "Error!",
            text: "Something went wrong. Please try again later.",
            icon: "error"
          })
        } else {
          swal({
            title: "Error",
            text: "There was an error. Please try again.",
            icon: "warning"
          })
        }
      })
      .catch(error => {
        if (error?.response?.status === 401) {
          dispatch(updateUserDetailsRedux({}))
          dispatch(setConnectedWallet(""))
          localStorage.clear()
          navigate("/login")
        }
      })
  }
  const toKycClick = item => {
    navigate({
      pathname: "/KycForm",
      kycProps: {
        placeId: item
      }
    })
  }

  const onDelete = item => {
    axios
      .delete(`${BaseUrl}/deletePlace/${item}`, {
        headers: {
          // 'Content-Type': 'application/json',
          Authorization: `Bearer ${loginUserToken}`
        }
      })
      .then(response => {
        if (response.status == 200) {
          swal({
            title: "Success!",
            text: "Your nest has been successfully deleted. You can continue adding new addresses.",
            icon: "success"
          })
          loadTable()
        } else if (response.status == 404) {
          swal({
            title: "Error!",
            text: "Page not found. Please try again later.",
            icon: "error"
          })
        } else if (response.status == 500) {
          swal({
            title: "Error!",
            text: "Something went wrong. Please try again later.",
            icon: "error"
          })
        } else if (response.status == 503) {
          swal({
            title: "Error!",
            text: "Something went wrong. Please try again later.",
            icon: "error"
          })
        }
      })
      .catch(error => {
        if (error?.response?.status === 401) {
          dispatch(updateUserDetailsRedux({}))
          dispatch(setConnectedWallet(""))
          localStorage.clear()
          navigate("/login")
        }
      })
  }

  const filtertabledates = value => {
    const startingdate =
      value == 1
        ? moment().subtract(1, "d").format("YYYY-MM-DD")
        : value == 2
        ? moment().subtract(7, "d").format("YYYY-MM-DD")
        : value == 3
        ? moment().subtract(30, "d").format("YYYY-MM-DD")
        : moment().format("YYYY-MM-DD")

    if (value != 4 && tableData) {
      let filteredData = tableData.filter(item => {
        return moment(item.createdAt).isAfter(startingdate)
      })
      return filteredData.length > 0 ? filteredData : []
    } else {
      return allproducts
    }
  }

  const toNestpage = () => {
    navigate("/nest")
  }

  return (
    <div className="less-padding-in-mobile My-nest-page">
      <div className="sorting">
        <Dropdown
          name="duration"
          id="duration"
          options={nestDurationOptions}
          handleChange={e => {
            setDuration(e.target.value)
            filtertabledates(e.target.value)
          }}
          value={duration}
        />
      </div>

      <div className="My-nest-page__table">
        <table className="table-scss table de-table table-rank table-font-size-reduce table-responsive">
          <thead>
            <tr>
              {/* <!-- Added new Class --> */}
              <th scope="col" className="first-head">
                Address{" "}
                <Tooltip title="Your registered nesting address">
                  <FaIcons.FaExclamationCircle />
                </Tooltip>
              </th>
              <th scope="col" className="right">
                Type of miner{" "}
                {/* <Tooltip title="Please specify the type of miner">
                      <FaIcons.FaExclamationCircle />
                    </Tooltip> */}
              </th>
              <th scope="col" className="right">
                Latitude{" "}
                {/* <Tooltip title="Enter the latitude of your location">
                      <FaIcons.FaExclamationCircle />
                    </Tooltip> */}
              </th>
              <th scope="col" className="right">
                Longitude{" "}
                {/* <Tooltip title="Enter the longitude of your location">
                      <FaIcons.FaExclamationCircle />
                    </Tooltip> */}
              </th>
              <th scope="col" className="right">
                {" "}
                Request date{" "}
                {/* <Tooltip title="Request Date">
                      <FaIcons.FaExclamationCircle />
                    </Tooltip> */}
              </th>
              {/* <th scope="col" className="right">
                    Status{" "}
                    <Tooltip title="Status indicates allocation decision from Nitro for the registered address">
                      <FaIcons.FaExclamationCircle />
                    </Tooltip>
                  </th> */}
              {/* <th scope="col" className="right">Status</th> */}

              {/* Temporrily Hidden */}
              {/* <th scope="col" className="right">
                KYC status{" "}
                <Tooltip title="Represents the combined final KYC status of the requester and the registered address">
                  <FaIcons.FaExclamationCircle />
                </Tooltip>
              </th> */}
              <th scope="col" className="right">
                ACTION{" "}
                <Tooltip title='Want to start again? Click "delete" '>
                  <FaIcons.FaExclamationCircle />
                </Tooltip>
              </th>
            </tr>
          </thead>
          <tbody>
            {(duration < 4 ? filtertabledates(duration) : allproducts).length >
            0 ? (
              (duration < 4 ? filtertabledates(duration) : allproducts).map(
                (item, i) => {
                  return (
                    <tr key={i}>
                      <td scope="row" data-label="Address">
                        <span
                          className="address-text-table"
                          style={{
                            wordWrap: "break-word"
                          }}>
                          {item.address}
                        </span>
                      </td>
                      {/* <!-- added class --> */}
                      <td className="right" data-label="Type of miner">
                        {item.miner_name}
                      </td>
                      <td className="d-plus right" data-label="LATITUDE">
                        <span className="green-text-lat-long">
                          {parseFloat(item.lat).toFixed(7)}
                        </span>
                      </td>
                      <td className="d-plus right" data-label="LONGITUDE">
                        <span className="green-text-lat-long">
                          {parseFloat(item.lang).toFixed(7)}
                        </span>
                      </td>
                      <td className="right max-time" data-label="timestamp">
                        <span className="time-font">
                          {moment(item.createdAt).format("DD-MMM-YYYY")}
                        </span>
                      </td>
                      {/* <td className="right" data-label="status">{item.status}</td> */}
                      {/* <td className="right" data-label="status">
                            {" "}
                            in Progress
                          </td> */}

                      {/* Temporarily Hidden */}
                      {/* <td className="right" data-label="KYC Status">
                        <div
                          id="submit"
                          className="paybuttonOnPayments"
                          onClick={
                            item?.kycEnableStatus
                              ? () => {
                                  selectedPlace.current = item
                                  item?.verification_status === "Approved"
                                    ? navigate("/kyc", {
                                        state: {
                                          place: item
                                        }
                                      })
                                    : setShowModal(true)
                                }
                              : () => {
                                  swal({
                                    text: "KYC is disabled for this city. Launching soon",
                                    icon: "info"
                                  })
                                }
                          }
                          style={{ cursor: "pointer" }}>
                          <input
                            type="submit"
                            id="send_message"
                            value={
                              item?.verification_status === "pending"
                                ? "Proceed"
                                : item?.verification_status ?? "Proceed"
                            }
                            disabled={!item?.kycEnableStatus}
                            className="btn-brand btn-active "
                          />
                        </div>
                      </td> */}
                      <td className="right" data-label="Action">
                        <div id="submit" className="paybuttonOnPayments">
                          <Popconfirm
                            title="Are you sure, you want to delete this address?"
                            onConfirm={() => onDelete(item.place_id)}
                            // onCancel={cancel}
                            okText="Yes"
                            cancelText="No">
                            <input
                              type="button"
                              id="send_message"
                              value="Delete"
                              className="btn btn-main-delete color-2"
                            />
                          </Popconfirm>
                        </div>
                      </td>
                    </tr>
                  )
                }
              )
            ) : (
              <td colSpan={8} className="text-center">
                No nesting requests. Please click{" "}
                <a onClick={toNestpage} className="purple-color-link">
                  {" "}
                  Nest
                </a>{" "}
                button to continue.
              </td>
            )}
          </tbody>
        </table>

        <div className="spacer-double"></div>
      </div>

      <CustomModal
        showModal={showModal}
        toggleModalView={() => setShowModal(!showModal)}>
        <div className="text-center">
          <p
            className="color-primary"
            style={{
              fontSize: "1.8rem",
              fontWeight: "bold"
            }}>
            Let’s get your verification process started.
          </p>
          <p className="color-primary" style={{ fontSize: "1rem" }}>
            Before you start, please ensure all your identification documents
            are valid and you have them in an accessible place.
          </p>
          <p className="color-primary" style={{ fontSize: "1rem" }}>
            You also need to accept our terms and conditions and give us
            permission to accessing your personal data before getting started.
          </p>
        </div>
        <div>
          <p
            className="color-dark-grey"
            style={{
              fontSize: "1.3rem",
              fontWeight: "bold",
              marginBottom: "0px"
            }}>
            KYC Document Guidelines:
          </p>
          <p>
            Please remember to follow these KYC guidelines when uploading your
            documents
          </p>
          <ol type="1">
            {KYCGuidelines.map((guideline, index) => (
              <li style={{ listStyle: "inherit" }} key={index}>
                {guideline}
              </li>
            ))}
          </ol>
        </div>
        <div
          style={{
            mainidth: "30vw",
            margin: "10px auto",
            paddingLeft: "5vw"
          }}>
          <div style={{ display: "flex" }}>
            <Checkbox
              id="tnc"
              onChange={() => setTNC(!tnc)}
              checked={tnc}
              style={{ marginRight: "15px" }}></Checkbox>
            <label style={{ color: "black" }} htmlFor="tnc">
              I accept{" "}
              <a target="_blank" className="color-primary" href="/terms">
                terms &amp; conditions
              </a>{" "}
              and{" "}
              <a href="/privacy" target="_blank" className="color-primary">
                privacy policy
              </a>
            </label>
          </div>
        </div>
        <button
          onClick={
            tnc
              ? () =>
                  navigate("/kyc", {
                    state: {
                      place: selectedPlace.current
                    }
                  })
              : () => {
                  swal({
                    title: "",
                    text: "Please accept terms & conditions and privacy policy",
                    icon: "warning"
                  })
                }
          }
          className="btn-brand btn-active mx-w"
          style={{ maxWidth: "30vw", margin: "10px auto" }}>
          Continue
        </button>
      </CustomModal>
    </div>
  )
}

const mapDispatchToProps = dispatch => {
  return {
    updateUserDetails: payload => dispatch(updateUserDetailsRedux(payload))
  }
}

export default connect(null, mapDispatchToProps)(MyNest)
