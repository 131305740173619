import React from "react"
import ReactDOM from "react-dom"

const ViewDetailPopup = ({ setshowViewDetail, orderItems }) => {
  return ReactDOM.createPortal(
    <ViewDetail
      orderItems={orderItems}
      setshowViewDetail={setshowViewDetail}
    />,
    document.getElementById("view-order")
  )
}
const ViewDetail = ({ setshowViewDetail, orderItems }) => {
  return (
    <>
      <div className={`view-details-backdrop`}>
        <div className="view-details-popup order-detail_popup">
          <div className="dfjb dfac  order-detail__head">
            <h6>Order Details</h6>

            <div>
              {/* <a
								href="#"
								target="_blank"
								rel="noreferrer"
								className="track-order-btn gradient-btn"
							>
								Track Order
							</a> */}
              <span
                className="close-btn"
                onClick={() => {
                  setshowViewDetail(false)
                }}>
                X
              </span>
            </div>
          </div>

          {orderItems.length ? (
            <div className="orders-lists mb-4">
              {orderItems.map((orderItem, i) => (
                <div className="order" key={i}>
                  <p className="serial-number">{i + 1}.</p>
                  <div className="order-list  ">
                    <div className="order-detail__image ">
                      <img src={orderItem?.kitImage} alt={orderItem?.kitName} />
                    </div>
                    <div className="order-list__item ">
                      <p className="text-big">{orderItem?.kitName}</p>
                      <ul className="list-unstyled text-sm  pt-2 mb-0">
                        {(orderItem?.products ?? []).map((product, j) => (
                          <li
                            key={product?.sensorId}
                            className="pt-2 font-root">
                            <span className="text-sm">
                              {product?.quantity} x {product?.name}
                            </span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <h4 className="mt-4 text-center">Order details not found.</h4>
          )}
        </div>
      </div>
    </>
  )
}

export default ViewDetailPopup
