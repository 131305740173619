import { React } from "react"
import { setActiveMenu } from "../../redux/actions"
import { useDispatch } from "react-redux"

function ThankYou() {
  const dispatch = useDispatch()

  return (
    <div>
      <div className="thank-you-page mt-120pxx container">
        <h1>Thank you for the purchase.</h1>
        <h2>Welcome to Nitro where everybody wins!</h2>
        <div>
          <p>
            Your NFM will be airdropped. You can check it in 'My Collection'.
          </p>
          <p>Please make sure you connect your wallet.</p>
          <p>
            Go to &nbsp;
            <a
              href="/myaccount/mycollection"
              className="purple-color-text"
              onClick={() => dispatch(setActiveMenu("NFM"))}>
              My Collection
            </a>{" "}
          </p>
        </div>
      </div>
    </div>
  )
}

export default ThankYou
