import React, { useState } from "react"
import { useSelector } from "react-redux"
import { Form, Input, Radio } from "antd"
import axios from "axios"
import { IoTAPIUrl } from "../../../utils/config"
import { useLogout } from "../../../hooks"
import Dropdown from "../../components/dropdown"
import swal from "sweetalert"
import { EUCountryOptions, USStateOptions } from "../../../Shared"

function ShippingDetails({
  country = null,
  addresses = [],
  requestOptions = null,
  getAddresses = () => {},
  selectedAddIdx = -1,
  setSelectedAddIdx = () => {},
  getAdditionalCharges = () => {}
}) {
  const logoutHandler = useLogout()
  const userDetails = useSelector(state => state.common.userDetails)

  const [form] = Form.useForm()

  const addNewAddress = async values => {
    const requestData = {
      ...values,
      userId: userDetails?.loginUserId,
      addressLine2: values.addressLine1,
      useAsBillingAddress: true
    }
    try {
      const response = await axios.post(
        `${IoTAPIUrl}/addAddress`,
        requestData,
        requestOptions
      )
      if (response.status === 200) {
        swal({
          title: "Success",
          text: response.data.message,
          icon: "success"
        })
        form.resetFields()
        getAddresses("newAddress")
        getAdditionalCharges(requestData?.state ?? null)
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        logoutHandler()
      } else {
        swal({
          title: "Error!",
          text: error?.response?.data?.message,
          icon: "error"
        })
      }
    }
  }

  const handleAddressSelection = async addressIndex => {
    if (addressIndex < 0) return
    const requestData = {
      ...addresses[addressIndex],
      userId: userDetails?.loginUserId,
      useAsBillingAddress: true
    }
    try {
      let response = await axios.post(
        `${IoTAPIUrl}/addAddress`,
        requestData,
        requestOptions
      )
      if (response.status === 200) {
        setSelectedAddIdx(addressIndex)
        getAdditionalCharges(requestData?.state ?? null)
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        logoutHandler()
      } else {
        swal({
          title: "Error!",
          text: error?.response?.data?.message,
          icon: "error"
        })
      }
    }
  }

  const removeAddress = async (index, addressId = -1) => {
    if (addressId < 0) return
    try {
      let response = await axios.delete(
        `${IoTAPIUrl}/deleteUserAddress/${userDetails?.loginUserId}/${addressId}`,
        requestOptions
      )
      if (response.status === 200) {
        swal({
          title: "Success!",
          text: response.data.message,
          icon: "success"
        })
        if (selectedAddIdx === index) setSelectedAddIdx(-1)

        getAddresses()
      } else {
        throw new Error("Something went wrong")
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        logoutHandler()
      } else {
        swal({
          title: "Error!",
          text: error?.response?.data?.message,
          icon: "error"
        })
      }
    }
  }

  return (
    <>
      {addresses.length
        ? addresses.map((address, i) => (
            <div className="card-ship" key={i}>
              <div className="row">
                <div className="col-md-12">
                  <div className="ship-address">
                    <label>
                      {/* Radio Check */}
                      <input
                        type="radio"
                        value={i}
                        checked={i === selectedAddIdx}
                        onChange={() => handleAddressSelection(i)}
                      />
                      <div className="address-information">
                        <div className="address-persnol">
                          <span className="text-md">{address?.name ?? ""}</span>
                          <span className="badge badge-primary-light ">
                            {address?.type ?? ""}
                          </span>
                          <span className="text-md ">
                            {`${address?.mobileNumber ?? ""}${
                              address?.alternateMobileNumber
                                ? ", " + address?.alternateMobileNumber
                                : ""
                            }`}
                          </span>
                        </div>
                        <p className="pt-2 text-sm address-field">
                          {address?.address ?? address?.addressLine1 ?? ""}{" "}
                          {address?.pincode ? `PIN: ${address?.pincode}` : ""}
                        </p>
                        <button
                          className="btn-brand btn-transparent px-05 delete-btn"
                          type="button"
                          onClick={() => {
                            removeAddress(i, address?.addressId)
                          }}>
                          Delete
                        </button>
                      </div>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          ))
        : null}

      <div className="card-ship">
        <div className="row">
          <div className="col-lg-12">
            <span className=""> Add a New Address </span>

            <Form
              name="newAddress"
              className="ship-add-new-address"
              form={form}
              onFinish={addNewAddress}>
              <div className="half-fields">
                <div className="form-field">
                  <label htmlFor="name">Name *</label>
                  <Form.Item
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: "Name is required"
                      }
                    ]}>
                    <Input
                      type="text"
                      placeholder="Name"
                      className="form-control"
                    />
                  </Form.Item>
                </div>
                <div className="form-field">
                  <label htmlFor="mobileNumber">Mobile number *</label>

                  <Form.Item
                    name="mobileNumber"
                    rules={[
                      {
                        required: true,
                        message: "Mobile Number is required"
                      },
                      {
                        validator: (_, value) => {
                          if (
                            String(value).length < 4 ||
                            String(value).length > 12
                          ) {
                            return Promise.reject(
                              "Mobile number should be between 4 to 12 numbers"
                            )
                          } else {
                            return Promise.resolve()
                          }
                        }
                      }
                    ]}>
                    <Input
                      type="number"
                      placeholder="10-digit mobile number"
                      className="form-control"
                    />
                  </Form.Item>
                </div>
              </div>

              <div className="half-fields">
                <div className="form-field">
                  <label htmlFor="pincode">Pincode *</label>

                  <Form.Item
                    name="pincode"
                    rules={[
                      {
                        required: true,
                        message: "Pincode is required"
                      },
                      {
                        validator: (_, value) =>
                          String(value).length >= 4
                            ? Promise.resolve()
                            : Promise.reject(
                                new Error(
                                  "Pincode should have atleast 4 letters/numbers"
                                )
                              )
                      }
                    ]}>
                    <Input
                      type="text"
                      placeholder="Pincode"
                      className="form-control"
                    />
                  </Form.Item>
                </div>
                <div className="form-field">
                  <label htmlFor="landmark">Landmark *</label>

                  <Form.Item
                    name="landmark"
                    rules={[
                      {
                        required: true,
                        message: "Landmark is required"
                      }
                    ]}>
                    <Input
                      type="text"
                      placeholder="Landmark"
                      className="form-control"
                    />
                  </Form.Item>
                </div>
              </div>

              <div className="half-fields">
                <div className="form-field">
                  <label htmlFor="addressLine1">Address *</label>

                  <Form.Item
                    name="addressLine1"
                    rules={[
                      {
                        required: true,
                        message: "Address is required"
                      }
                    ]}>
                    <Input.TextArea
                      placeholder="Address(House / Apartment / Area / Street)"
                      className="form-control"
                    />
                  </Form.Item>
                </div>
              </div>

              <div className="half-fields">
                <div className="form-field">
                  <label htmlFor="city">City *</label>

                  <Form.Item
                    name="city"
                    rules={[
                      {
                        required: true,
                        message: "City/Street/Town is required"
                      }
                    ]}>
                    <Input
                      type="text"
                      placeholder="City/Street/Town"
                      className="form-control"
                    />
                  </Form.Item>
                </div>
                <div className="form-field">
                  <label htmlFor="state">State *</label>

                  <Form.Item
                    name="state"
                    initialValue={form.getFieldValue("state")}
                    rules={[
                      {
                        required: true,
                        message: "State is required"
                      }
                    ]}>
                    {country?.value === "USA" ? (
                      <Dropdown
                        id="state"
                        className="form-control shipping-detail-dropdown"
                        options={USStateOptions}
                        handleChange={e => {
                          form.setFieldsValue({ state: e.target.value })
                        }}
                        value={form.getFieldValue("state")}
                      />
                    ) : (
                      <Input
                        type="text"
                        placeholder="State"
                        className="form-control"
                      />
                    )}
                  </Form.Item>
                </div>
              </div>

              <div className="half-fields">
                <div className="form-field">
                  <label htmlFor="country">Country *</label>

                  <Form.Item
                    name="country"
                    initialValue={
                      country?.value === "EU Region" ? "" : country?.value
                    }
                    rules={[
                      {
                        required: true,
                        message: "Country is required"
                      }
                    ]}>
                    {country?.value === "EU Region" ? (
                      <Dropdown
                        id="country"
                        initialValue={form.getFieldValue("country")}
                        className="form-control shipping-detail-dropdown"
                        options={EUCountryOptions}
                        handleChange={e => {
                          form.setFieldsValue({ country: e.target.value })
                        }}
                        value={form.getFieldValue("country")}
                      />
                    ) : (
                      <Input
                        type="text"
                        placeholder="Country"
                        className="form-control"
                        disabled={country}
                      />
                    )}
                  </Form.Item>
                </div>
                <div className="form-field">
                  <label htmlFor="alternatePhone">Alternate number </label>

                  <Form.Item
                    name="alternatePhone"
                    rules={[
                      {
                        validator: (_, value) => {
                          if (!String(value)) return Promise.resolve()
                          if (
                            String(value).length < 4 ||
                            String(value).length > 12
                          ) {
                            return Promise.reject(
                              "Mobile number should be between 4 to 12 numbers"
                            )
                          } else {
                            return Promise.resolve()
                          }
                        }
                      }
                    ]}>
                    <Input
                      type="number"
                      placeholder="10-digit alternate mobile number"
                      className="form-control"
                    />
                  </Form.Item>
                </div>
              </div>

              <div className="row py-2 ">
                <div className="form-group col-md-12 ">
                  <div className="iot-label-radio ps-2  d-flex flex-col w-100">
                    <Form.Item
                      label="Address Type"
                      name="type"
                      rules={[
                        {
                          required: true,
                          message: "Address type is required"
                        }
                      ]}>
                      <Radio.Group>
                        <Radio value="Home" className="">
                          {" "}
                          Home{" "}
                        </Radio>
                        <Radio value="Work" className="">
                          {" "}
                          Work{" "}
                        </Radio>
                      </Radio.Group>
                    </Form.Item>
                  </div>
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-12">
                  <div className="text-right">
                    <button
                      className="iot-cancel-button  text-uppercase py-2 px-3 text-sm"
                      type="button"
                      style={{ marginRight: "10px" }}
                      onClick={() => form.resetFields()}>
                      Cancel
                    </button>
                    <button
                      className="btn-brand btn-active mx-w  text-uppercase  text-sm"
                      type="submit">
                      Save Address
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </>
  )
}

export default ShippingDetails
