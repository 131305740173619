import React, { useState, useEffect } from "react"
import { Form, Input } from "antd"
import { BaseUrl } from "../../utils/config"
import {
  // useHistory,
  useLocation
} from "react-router-dom"
import axios from "axios"
import swal from "sweetalert"
import { useNavigate } from "@reach/router"
import {
  // useDispatch,
  useSelector
} from "react-redux"
// import {
//   setConnectedWallet,
//   updateUserDetailsRedux,
//   setActiveMenu
// } from "../../redux/actions"
import { useLogout } from "../../hooks"

const Signup2 = tcPropos => {
  // const history = useHistory()
  const navigate = useNavigate()
  const location = useLocation()
  // const dispatch = useDispatch()
  const logoutHandler = useLogout()

  let iotTokens = useSelector(state => state.common.iotTokens) || []
  iotTokens = iotTokens.length ? iotTokens.join(",") : ""

  const [passErrorMsg, setPassErrorMsg] = useState(null)
  const [isName, setIsName] = useState(null)
  const [isEmail, setIsEmail] = useState(null)
  const [checkedTerms, setCheckedTerms] = useState(false)
  const [isPass, setIsPass] = useState(null)
  const [isCPass, setIsCPass] = useState(null)
  const [form] = Form.useForm()
  useEffect(() => {
    window.scrollTo(0, 0)

    let temp = location.pathname.split("/signup/").pop()
    //  Base64.decode(this.props.match.params.id);

    if (location.state) {
      setIsEmail(location.state?.email1)
    }

    location.state &&
      form.setFieldsValue({
        email: location.state?.email1
      })

    callForPreParams(temp)
  }, [location])

  const verifyPassWord = () => {
    if (isPass != null && isCPass != null) {
      isPass != isCPass
        ? setPassErrorMsg("Passwords do not match")
        : setPassErrorMsg(null)
    }
  }
  const callForPreParams = temp => {
    axios
      .get(`${BaseUrl}/verifymail/${temp}`)
      .then(response => {
        if (response) {
          swal({
            title: "Success!",
            text: "Email verified successfully. Please continue with your sign up process.",
            icon: "success"
          })
          setIsEmail(response?.data?.data)
          form.setFieldsValue({
            email: response?.data?.data
          })
        } else {
          swal({
            title: "Token expired",
            text: "Authorisation error. Please try again later.",
            icon: "error"
          })
        }
      })
      .catch(error => {
        if (error?.response?.status == 401) {
        } else if (error?.response?.status == 404) {
          swal({
            title: "Error!",
            text: "Page not found. Please try again later.",
            icon: "error"
          })
        } else if (error?.response?.status == 500) {
          swal({
            title: "Error!",
            text: "Something went wrong. Please try again later.",
            icon: "error"
          })
        } else if (error?.response?.status == 503) {
          swal({
            title: "Error!",
            text: "Something went wrong. Please try again later.",
            icon: "error"
          })
        } else
          swal({
            title: "Token expired",
            text: "Please try again",
            icon: "error"
          })
      })
  }

  // const toLinks = item => {
  //   window.open(item)
  // }

  const onFinish = values => {
    const Fobj = {
      password: values.cPassword,
      email: values.email,
      name: values.name
    }
    if (
      values.password == null ||
      values.password == "" ||
      values.cPassword == null ||
      values.email == null ||
      isName == null ||
      values.cpassword == "" ||
      isPass != isCPass ||
      !checkedTerms
    ) {
      swal({
        title: "",
        text: "Please enter the Credentials",
        icon: "error"
      })
    } else {
      axios
        .post(`${BaseUrl}/signup`, Fobj)
        .then(response => {
          if (response.status == 200) {
            swal({
              title: "Congratulations!",
              text: "Your account has been created successfully.",
              icon: "success"
            })
            setTimeout(() => {
              navigate(
                `/login${iotTokens.length ? `?iotTokens=${iotTokens}` : ""}`
              )
            }, 3000)
          } else if (response.status == 401) {
            logoutHandler()
          } else if (response.status == 404) {
            swal({
              title: "Error!",
              text: "Page not found. Please try again later.",
              icon: "error"
            })
          } else if (response.status == 500) {
            swal({
              title: "Error!",
              text: "Something went wrong. Please try again later.",
              icon: "error"
            })
          } else if (response.status == 503) {
            swal({
              title: "Error!",
              text: "Something went wrong. Please try again later.",
              icon: "error"
            })
          } else {
            swal({
              title: "Invalid Credentials",
              text: "Invalid credential. Please try again.",
              icon: "error"
            })
          }
        })
        .catch(error => {
          if (error?.response?.status === 401) {
            logoutHandler()
          } else
            swal({
              title: "Error!",
              text: "Something went wrong. Please try again later.",
              icon: "warning"
            })
        })
    }
  }

  // const toLogin = () => {
  //   navigate("/login")
  // }

  // const toSignup = () => {
  //   navigate("/verifyEmail")
  // }
  // const toforgot = () => {
  //   navigate("/forgotPassword")
  // }

  return (
    <div className="Login-page">
      <div className="Login-page__box">
        <Form
          name="contactForm"
          id="contact_form"
          action=""
          className="forgetfinal"
          autoComplete="off"
          form={form}
          onFinish={onFinish}>
          <h3>Sign up</h3>

          <div className="field-set">
            <Form.Item
              name="email"
              rules={[
                {
                  type: "email",
                  message: "The input is not valid Email!"
                },
                {
                  required: true,
                  message: "Email is required."
                }
              ]}>
              <Input
                id="email"
                className="form-control"
                disabled
                placeholder="Enter email"
                onChange={e => {
                  setIsEmail(e.target.value)
                }}
              />
            </Form.Item>
          </div>
          <div className="field-set">
            <Form.Item
              name="name"
              rules={[
                {
                  required: true,
                  message: "Name is required."
                },
                {
                  validator: (_, value) => {
                    if (value.match(/^([A-Za-z]+ )+[A-Za-z]+$|^[A-Za-z]+$/)) {
                      return Promise.resolve()
                    } else
                      return Promise.reject(
                        new Error(
                          "Name should not contain digits/numbers or space at the ends"
                        )
                      )
                  }
                }
              ]}>
              <Input
                id="name"
                placeholder="Enter name"
                className="form-control"
                onChange={e => {
                  setIsName(e.target.value)
                }}
              />
            </Form.Item>
          </div>
          <div className="field-set pass-field">
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: "Enter the password."
                },
                {
                  validator: (_, value) => {
                    return !value.includes(" ")
                      ? Promise.resolve()
                      : Promise.reject("Password should not contain space")
                  }
                },
                {
                  max: 15,
                  message: "Password should be in between 6 and 15 characters."
                },
                {
                  min: 6,
                  message: "Password should be in between 6 and 15 characters."
                }
              ]}>
              <Input.Password
                className="form-control"
                type="password"
                placeholder="Enter password"
                onChange={e => {
                  setIsPass(e.target.value)
                }}
                onMouseOut={() => {
                  verifyPassWord()
                }}
              />
            </Form.Item>
          </div>
          <div className="field-set pass-field">
            <Form.Item
              name="cPassword"
              dependencies={["password"]}
              rules={[
                {
                  required: true,
                  message: "Please confirm your password!"
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value)
                      return Promise.resolve()
                    else
                      return Promise.reject(new Error("Passwords do not match"))
                  }
                })
              ]}>
              <Input.Password
                className="form-control"
                type="password"
                placeholder="Confirm Password"
                onChange={e => {
                  setIsCPass(e.target.value)
                }}
                onMouseOut={() => {
                  verifyPassWord()
                }}
              />
            </Form.Item>
            {/* <p className="passErrorMsg">{passErrorMsg}</p> */}
          </div>

          <div
            className="d-flex justify-content-flex-start align-items-center"
            style={{ width: "100%", marginBottom: "3vh" }}>
            <input
              style={{ minWidth: "min-content" }}
              type="checkbox"
              checked={checkedTerms}
              onChange={() => setCheckedTerms(prevState => !prevState)}
            />
            <p style={{ margin: "0 0 0 5px" }}>
              I agree to{" "}
              <a href="/terms" target="_blank" className="color-primary">
                Terms & Conditions
              </a>
            </p>
          </div>
          {/* <div className="checkbox mt-3">
                     <Checkbox
                       onChange={onChangeCheck}
                       checked={checkedTerms}
                     ></Checkbox>

                     <label for="terms">
                       {" "}
                       &nbsp;&nbsp;{" "}
                       <span className="colorWhite">I agree to </span>
                       <a
                         className="purple-color-text"
                         onClick={() =>
                           toLinks("https://nitro.network/terms-and-conditions")
                         }
                       >
                         {" "}
                         Terms & Conditions
                       </a>
                     </label>
                   </div> */}

          <div id="submit">
            <input
              type="submit"
              id="send_message"
              disabled={
                isEmail && isPass && isName && checkedTerms && isCPass
                  ? false
                  : true
              }
              value="Submit"
              className="btn-brand btn-active color-primary"
            />
          </div>
        </Form>
      </div>
    </div>
  )
}

export default Signup2
