import {
  createHistory,
  Location,
  LocationProvider,
  Redirect,
  Router
} from "@reach/router"
import React from "react"
import { Provider } from "react-redux"
import { BrowserRouter as Routing } from "react-router-dom"
import { PersistGate } from "redux-persist/lib/integration/react"
import { persistor, store } from "../redux/Store/"

import { createGlobalStyle } from "styled-components"
import Footer from "./components/footer"
import Loader from "./components/Loader"
import Header from "./menu/header"
import ScrollToTopBtn from "./menu/ScrollToTop"
import BuyNitro from "./pages/buyNitro"
import ChangePassword from "./pages/changepassword"
import EditProfile from "./pages/editProfile"
import ForgotPassword from "./pages/forgotPassword"
import ForgotPassword2 from "./pages/forgotPasswordFinal"
import HowitworksMarketplace from "./pages/howItWorksMarket"
import HowitworksNest from "./pages/howItWorksNest"
import HowItWorksTab from "./pages/howItWorksTab"
import HowitworksMiner from "./pages/howItWworksMiner"
import KYC from "./pages/KYC"
import KYCFormSuccess from "./pages/KYCFormSuccess"
import KYCSecond from "./pages/KYCSecond"
import Login from "./pages/loginTwo"
import MarketPlace from "./pages/marketPlace"
import MarketPlaceList from "./pages/MarketPlaceList"
import MyAccount from "./pages/myAccount"
import MyCollection from "./pages/myCollection"
import MyCollectionAll from "./pages/myCollectionAll"
import MyCollectionWithStake from "./pages/myCollectionDetailWithStake"
import MyCollectionTab from "./pages/MyCollectionTab"
import { ConfirmMiner, MinerLocation, Nest, SelectMiner } from "./pages/Nest"
import NewBuyNfmPage from "./pages/newBuyNfm"
import NitroActivity from "./pages/nitroActivity"
import PaymentPage from "./pages/paymentPage"
import Profile from "./pages/profile"
import Rewards from "./pages/rewards"
import Signup from "./pages/signup"
import Signup2 from "./pages/signup2"
import SignupSucess from "./pages/signupSuccess"
import SoldNFMDetailPage from "./pages/SoldNFMDetailPage"
import ThankYou from "./pages/thankYou"

import BurnPage from "./pages/BurnPage"
import HomepageNew from "./pages/homepageNew"
// HExagons screens
// import HeatMap from "./pages/HeatMap"
// import B2BHexagon from "./pages/B2BHexagon"
// import HotspotHexagon from "./pages/hotspotHexagon"
// import MarketPriceHeatMap from "./pages/marketPriceHexagon"
// import NesterHexagon from "./pages/NesterHexagon"
// import SuperNestHexagon from "./pages/SuperNestHexagon"

import { isNestEnabled } from "../utils/config"
import NcashPage from "./pages/NcashPage"
import Privacy from "./pages/Privacy"
import Terms from "./pages/Terms"

import Gateway from "./pages/Gateway"
import WPBlogDetail from "./pages/WPBlogDetail"
import WPBlogs from "./pages/WPBlogs"
import WPUseCaseDetail from "./pages/WPUseCaseDetail"
import WPUseCases from "./pages/WPUseCases"

const GlobalStyles = createGlobalStyle`
  :root {
    scroll-behavior: unset;
  }
`

export const ScrollTop = ({ children, location }) => {
  React.useEffect(() => window.scrollTo(0, 0), [location])
  return children
}

const PosedRouter = ({ children }) => (
  <Location>
    {({ location }) => (
      <div id="routerhang">
        <div key={location.key}>
          <Router location={location}>{children}</Router>
        </div>
      </div>
    )}
  </Location>
)

const App = () => {
  const history = createHistory(window)

  // const addIndexMetaTag = () => {
  //   const metaTag = document.createElement("meta")
  //   metaTag.setAttribute("name", "robots")
  //   metaTag.setAttribute("content", "index")
  //   document.getElementsByTagName("head")[0].appendChild(metaTag)
  // }

  // useLayoutEffect(() => {
  // const isQAEnv = process.env.REACT_APP_ENV === "qa"
  // isQAEnv && addIndexMetaTag()
  // }, [])

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <div className="wraper">
          <Routing>
            <GlobalStyles />
            <LocationProvider history={history}>
              {/* <Sidebar/> */}
              <Loader />
              <Header />
              {/* <Navbar/> */}
              <PosedRouter>
                <ScrollTop path="/">
                  <HomepageNew exact path="/">
                    <Redirect to="/home" />
                  </HomepageNew>

                  {/* Login's */}
                  <Login path="/login" />
                  <Signup path="/verifyEmail" />
                  <SignupSucess path="/signup-sucess" />
                  <Signup2 path="/signup/*" />
                  <ForgotPassword path="/forgotPassword" />
                  <ForgotPassword2 path="/forgotPasswordFinal/*" />
                  <ChangePassword path="/changepassword" />

                  {/* Profile */}
                  <Profile path="/settings" />
                  <EditProfile path="/editprofile" />
                  <Profile path="/profile" />

                  {/* Nest Routes Outer */}
                  {isNestEnabled && (
                    <>
                      <Nest path="/nest" />
                      <MinerLocation path="/miner" />
                      <SelectMiner path="/selectminer" />
                      <ConfirmMiner path="/confirm-miner" />
                    </>
                  )}

                  {/* My Account */}
                  <MyAccount path="/myaccount" />
                  <MyAccount path="/myaccount/:tab" />
                  {/* Below route changed to https://nitro.network's buy nfm page */}
                  <MarketPlaceList path="/buy-nfm" />
                  <NitroActivity path="/nitroactivity" />
                  <MyCollectionTab path="/collectionnew" />
                  <MyCollection path="/mycollection" />
                  {/* Temporarily removed */}
                  <MarketPlace path="/marketplace" />
                  <Rewards path="/rewards" />
                  {/* <MyNest path="/mynest" /> */}
                  <BuyNitro path="/buyNitro" />
                  <MyCollectionWithStake path="/mycollectionstake" />
                  <MyCollectionAll path="/mycollectionall" />
                  <SoldNFMDetailPage path="/soldnfmdetail" />
                  <PaymentPage path="/payment" />
                  <ThankYou path="/thank-you" />
                  <NewBuyNfmPage path="/guestcheckout" />
                  <KYC path="/kyc" />
                  <KYCSecond path="/kyc-step-2" />
                  <KYCFormSuccess path="/kyc-form-success" />
                  {/* <Navbar path="/navbar"/> */}
                  <BurnPage path="/burn" />
                  <NcashPage path="/ncash" />
                  <Gateway path="/gateway" />
                  {/*<Terms path="/terms" />*/}
                  <Privacy path="/privacy" />

                  {/* Hexagons screens ui */}
                  {/* <HeatMap path="/heatmap" />
                  <MarketPriceHeatMap path="/marketprice" />
                  <HotspotHexagon path="/hotspots" />
                  <SuperNestHexagon path="/supernest" />
                  <B2BHexagon path="/b2b" />
                  <NesterHexagon path="/nester" /> */}

                  {/* How It Works */}
                  <HowItWorksTab path="/howitworks" />
                  <HowItWorksTab path="/howitworks/:tab" />
                  <HowitworksNest path="/howitworksnest" />
                  <HowitworksMiner path="/howitworksminer" />
                  <HowitworksMarketplace path="/howitworksmarketplace" />

                  {/* Unused Route */}
                  {/* <Home1 path="/home1" />
                  <Home2 path="/home2" />
                  <Explore path="/explore" />
                  <Explore2 path="/explore2" />
                  <Helpcenter path="/helpcenter" />
                  <Rangking path="/rangking" />
                  <Howitworks path="/howitworks1" />
                  <LandingPageNew path="/landingpage" /> 
                  <Colection path="/colection" />
                  <ItemDetail path="/ItemDetail" /> */}
                  {/* <Author path="/Author" />
                  <Wallet path="/wallet" />
                  <Price path="/price" />
                  <Works path="/works" />
                  <News path="/news" />
                  <Create path="/create" />
                  <Auction path="/Auction" />
                  <Activity path="/activity" /> */}
                  {/* <Contact path="/contact" /> */}
                  {/* <ElegantIcons path="/elegantIcons" />
                  <EtlineIcons path="/etlineIcons" />
                  <FontAwesomeIcons path="/fontAwesomeIcons" />
                  <Accordion path="/accordion" />
                  <ProductDetails path="/productdetails" />
                  <Progressbar path="/progressbar" /> */}
                  {/* <Tabs path="/tabs" /> */}
                  <WPUseCases path="/usecases" />
                  <WPUseCaseDetail path="/usecase/:slug" />
                  <WPBlogs path="/blogs" />
                  <WPBlogDetail path="/blog/:slug" />

                  {/* Unused Route End */}
                </ScrollTop>
              </PosedRouter>
              <Footer />
              <ScrollToTopBtn />
            </LocationProvider>
          </Routing>
        </div>
      </PersistGate>
    </Provider>
  )
}

export default App
