import { ACTION_TYPES } from "./actionTypes"

export const updateUserDetailsRedux = payload => {
  return {
    type: ACTION_TYPES.UPDATE_USER_DETAILS,
    payload
  }
}

export const setPendingPayment = payload => {
  return {
    type: ACTION_TYPES.SET_PENDING_PAYMENT,
    payload
  }
}

export const setCollectionItems = payload => {
  return {
    type: ACTION_TYPES.SET_COLLECTION_ITEMS,
    payload
  }
}

export const updateOrderCount = payload => {
  return {
    type: ACTION_TYPES.UPDATE_ORDER_COUNT,
    payload
  }
}

export const setFilterChanged = payload => {
  return {
    type: ACTION_TYPES.SET_FILTER_CHANGED,
    payload
  }
}

export const setStyleType = payload => {
  return {
    type: ACTION_TYPES.SET_STYLE_TYPE,
    payload
  }
}

export const startLoader = () => {
  return {
    type: ACTION_TYPES.START_LOADER
  }
}

export const stopLoader = () => {
  return {
    type: ACTION_TYPES.STOP_LOADER
  }
}

export const saveKYCForm = payload => {
  return {
    type: ACTION_TYPES.SAVE_KYC_FORM,
    payload
  }
}

export const setIoTTokens = payload => {
  return {
    type: ACTION_TYPES.SET_IOT_TOKENS,
    payload
  }
}

export const setActiveMenu = payload => {
  return {
    type: ACTION_TYPES.SET_ACTIVE_MENU,
    payload
  }
}
