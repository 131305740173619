// import React, { useState, useEffect } from 'react';
// import Breakpoint, { BreakpointProvider, setDefaultBreakpoints } from "react-socks";
// import { createGlobalStyle } from 'styled-components';
// import { Form, Input, Modal, Select, Button } from "antd";
// import "../styles/style.css";

// // import "./css/style.css";
// setDefaultBreakpoints([
//   { xs: 0 },
//   { l: 1099 },
//   { xl: 1200 }
// ]);

// const Menu = ({ acteved, menuItems = [], clickHandler = () => { } }) => {
//   // const [isPass, SetIsPass] = useState(false);
//   const [isEmail, setIsEmail] = useState(false);
//   const [isTemp, setTemp] = useState(false);
//   const loginUserpending = localStorage.getItem('loginUserpending');

//   return (
//     <div>
//       {/* <GlobalStyles/> */}

//       <BreakpointProvider>
//         <Breakpoint xl>

//           <div className="top-links" style={{ overflowX: "auto" }}>
//             {true ?
//               (
//                 <div className="box-det">
//                   <div className="gradient-box">
//                     <div
//                       className={`field${acteved === "pendingpayments" ? " active" : ""}`}
//                       onClick={() => clickHandler(menuItems[0].value)}
//                     >
//                       <h5 className="border-field">{menuItems[0].label}</h5>
//                     </div>
//                   </div>
//                 </div>)
//               : null}

//             {menuItems.slice(1).map((menuItem, index) => (
//               <div className="box-det" key={menuItem.value + index}>
//                 <div className="gradient-box">
//                   <div
//                     className={`field${acteved === menuItem.value ? " active" : ""}`}
//                     onClick={() => clickHandler(menuItem.value)}
//                   >
//                     <h5 className="border-field">{menuItem.label}</h5>
//                   </div>
//                 </div>
//               </div>
//             ))}
//             {/* <li className={acteved === "myCollection" && "active"} onClick={() => toselectedTab("mycollection")}>
//               <a >My Collection  </a>  </li> */}
//             {/* <li className={acteved===""&&"active"}><img src="https://img.icons8.com/external-itim2101-fill-itim2101/25/48506D/external-reward-financial-itim2101-fill-itim2101.png"/> <a to="/pendingpayments">Rewards  </a></li> */}
//             {/* <li className={acteved === "activity" && "active"} onClick={() => toselectedTab("nitroactivity")}> <a >Activity</a></li>
//             <li className={acteved === "myNest" && "active"} onClick={() => toselectedTab("mynest")}> <a >My Nest</a></li> */}

//           </div>
//         </Breakpoint>
//       </BreakpointProvider>
//     </div>

//   )
// };
// export default Menu;

import React, { useState, useEffect } from "react"
import Breakpoint, {
  BreakpointProvider,
  setDefaultBreakpoints
} from "react-socks"
import { createGlobalStyle } from "styled-components"
import { Form, Input, Modal, Select, Button } from "antd"
import "../styles/style.css"
import { useNavigate, Link } from "@reach/router"

// import "./css/style.css";
setDefaultBreakpoints([{ xs: 0 }, { l: 1099 }, { xl: 1200 }])

const Menu = ({ acteved }) => {
  // const [isPass, SetIsPass] = useState(false);
  const [isEmail, setIsEmail] = useState(false)
  const [isTemp, setTemp] = useState(false)
  const navigate = useNavigate()
  const loginUserpending = localStorage.getItem("loginUserpending")

  const toselectedTab = item => {
    navigate(`/${item}`)
  }

  return (
    <div>
      {/* <GlobalStyles/> */}

      <BreakpointProvider>
        <Breakpoint xl>
          <div className="tabs-links" style={{ overflowX: "auto" }}>
            <ul>
              {loginUserpending == "true" ? (
                <li
                  className={acteved === "pendingpayments" && "active"}
                  onClick={() => toselectedTab("pendingpayments")}>
                  <a>Pending Payment </a>
                </li>
              ) : null}
              <li
                className={acteved === "marketPlace" && "active"}
                onClick={() => toselectedTab("marketplace")}>
                {" "}
                <a>Market Place </a>
              </li>

              <li
                className={acteved === "myCollection" && "active"}
                onClick={() => toselectedTab("mycollection")}>
                <a>My Collection </a>{" "}
              </li>
              <li
                className={acteved === "rewards" && "active"}
                onClick={() => toselectedTab("rewards")}>
                {" "}
                <a>Rewards </a>
              </li>
              <li
                className={acteved === "activity" && "active"}
                onClick={() => toselectedTab("nitroactivity")}>
                {" "}
                <a>Activity</a>
              </li>
              <li
                className={acteved === "myNest" && "active"}
                onClick={() => toselectedTab("mynest")}>
                {" "}
                <a>My Nest</a>
              </li>
            </ul>
          </div>
        </Breakpoint>
      </BreakpointProvider>
    </div>
  )
}
export default Menu
