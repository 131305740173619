import React from "react"
import { Images } from "../../Shared"
import JoinCommunity from "../components/JoinCommunity"

const Gateway = () => {
  return (
    <div className="gateway-page mt-90pxx">
      <div className="qr-banner pt-90px">
        <img src={Images.qrbanner} alt="Banner " />
        {/* <h1>We Knew You Would Be Here!</h1> */}
      </div>
      <div className="qr-information">
        <h2>Get Started </h2>
        <p>Discover amazing rewards as you set your gateway to a smart life</p>
        <div className="qr-infos">
          <a
            href="https://docsend.com/view/k9edfazfiv9sjmiw"
            target="_blank"
            rel="noreferrer"
            className="qr-info">
            <div className="qr-info__icon">
              <img src={Images.setup1} alt="QR info 1" />
            </div>
            <h3 className="text-center">Setup Guide</h3>
            <p>
              Check out the setup guide to get a detailed understanding how to
              set-up your device.
            </p>
          </a>
          <a
            href="https://iot.nitro.network/resources"
            target="_blank"
            rel="noreferrer"
            className="qr-info">
            <div className="qr-info__icon">
              <img src={Images.setup2} alt="QR info 1" />
            </div>
            <h3 className="text-center">Sensor Repository</h3>
            <p>
              Check out the Sensor Repository to see what you might have missed.
            </p>
          </a>
          <a href="mailto:info@nitro.network" className="qr-info">
            <div className="qr-info__icon">
              <img src={Images.setup3} alt="QR info 1" />
            </div>
            <h3 className="text-center">Contact us</h3>
            <p>If you need to speak to us, feel free to drop us a line.</p>
          </a>
        </div>

        <JoinCommunity />
      </div>
    </div>
  )
}

export default Gateway
