import React from "react"
import { useSelector } from "react-redux"
import loaderImg from "../../assets/images/loader.svg"

function Loader() {
  const loading = useSelector(state => state.common.loading) || false
  return loading ? (
    <div
      style={{
        minWidth: "100vw",
        minHeight: "100vh",
        position: "absolute",
        top: 0,
        left: 0,
        backgroundColor: "rgba(0,0,0,0.15)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 999999999
      }}>
      <img src={loaderImg} alt="loader" />
    </div>
  ) : null
}

export default Loader
