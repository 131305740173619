import React, { useState, useEffect } from "react"
import { Form, Input } from "antd"
import axios from "axios"
import swal from "sweetalert"
// import { createBrowserHistory } from "history"
// import { Link, useHistory, useLocation } from "react-router-dom"
import { useNavigate } from "@reach/router"
import { BaseUrl } from "../../utils/config"

// tftx test test

const ForgotPassword = () => {
  // const history = createBrowserHistory()
  const [isEmail, setIsEmail] = useState(false)
  const navigate = useNavigate()

  // useEffect(async () => {}, [])

  const onFinish = values => {
    const Fobj = {
      email: values.email
    }

    if (!isEmail) {
      swal({
        // title: "Success!",
        text: "Please enter the Credentials",
        icon: "warning"
      })
    } else {
      axios
        .post(`${BaseUrl}/forgotpassword`, Fobj)
        .then(response => {
          if (response.data.status) {
            swal({
              title: "Success!",
              text: " We have emailed your password reset link!",
              icon: "success"
            })
          } else {
            swal({
              title: "Invalid/Unregistered Email",
              text: "User does not exist.",
              icon: "error"
            })
            // message.error(`password changed  failed`);
          }
        })
        .catch(error => {
          if (error?.response?.status === 500) {
            swal({
              title: "Server Error",
              text: error.response.data.message,
              icon: "error"
            })
          } else if (error?.response?.status === 429) {
            swal({
              title: "Error!",
              text: error.response.data.message,
              icon: "error"
            })
          }
        })
    }
  }
  const toLogin = () => {
    navigate("/login")
  }

  // const toSignup = () => {
  //   navigate("/verifyEmail")
  // }
  // const toforgot = () => {
  //   navigate("/forgotPassword")
  // }
  return (
    <div className="Login-page">
      <div className="Login-page__box">
        <Form
          name="contactForm"
          id="contact_form"
          action=""
          autoComplete="off"
          onFinish={onFinish}>
          <h3>Forgot Password</h3>

          <div className="field-set">
            <label for="email">Email</label>
            <Form.Item
              name="email"
              rules={[
                {
                  type: "email",
                  message: "The input is not valid Email!"
                },
                {
                  required: true,
                  message: "Email is required."
                }
              ]}>
              <Input
                id="email"
                className="form-control"
                placeholder="Enter email"
                onChange={e => {
                  setIsEmail(e.target.value)
                }}
              />
            </Form.Item>
          </div>

          <div id="submit">
            <input
              type="submit"
              id="send_message"
              disabled={isEmail ? false : true}
              value="Send password reset link"
              className="btn-brand btn-active color-primary mb-4"
            />

            <input
              type="button"
              onClick={toLogin}
              id="send_message"
              value="Go back"
              className="btn-brand btn-active color-primary"
            />
          </div>
        </Form>
      </div>
    </div>
  )
}
export default ForgotPassword
