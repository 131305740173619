import React from "react"
function Terms() {
  return (
    <div className="term-page">
      <div className="container-term">
        <div className="terms">
          <h1>Terms & Conditions</h1>
          <div className="static-content one">
            <p>
              These Terms of Use (“Terms”) set forth the terms and conditions of
              your use of the website nitro.network (shall be collectively
              referred to as the “Website/Company”) and its content, products
              and services (“Services”). The terms “you”, “your”, “user” or
              “customer” shall refer to any individual or entity that accepts
              these Terms by using the Website or availing the services/
              providing services/ using buying or selling products of the
              Company. If you are agreeing to these Terms on behalf of a
              corporate entity, you represent and warrant that you have the
              legal authority to bind such corporate entities to these Terms, in
              which case the terms “you”, “your”, “user” or “customer” shall
              refer to such corporate entities. Nothing in these Terms shall be
              deemed to confer any third-party rights or benefits.
            </p>

            <p>
              The Company requires that all the visitors to our Website adhere
              to the following rules and regulations, which govern your access
              to, and use of, the Website. By accessing, browsing or using this
              Website, you, and the entity you are authorized to represent,
              acknowledge that you have read, understood and agree to be bound
              by these terms of use and to comply with all applicable laws and
              regulations.
            </p>

            <h3>Nester Disclaimer </h3>
            <p>
              Your application to be a Nester is subject to our review.
              Submission of an application to be a Nester does not guarantee
              acceptance of the application. It must be noted that the
              acceptance or rejection of the application is at the sole
              discretion of the Company and the Company is not responsible or
              liable in any manner whatsoever for any delay in processing or
              accepting or rejecting the application of any applicant. In the
              event that the location of the applicant is acceptable to the
              Company, the Company reserves the right to ask for further
              documentation to verify the location and the identity of the
              applicant.
            </p>
            <p>
              On being notified by the Company, the prospective Nester will be
              required to complete the Know Your Customer (KYC) form on the
              website by providing all necessary information to verify his/her
              identity and address. Submission of the KYC form does not
              guarantee the final selection of the applicant to be a Nester.
            </p>
            <p>
              All information collected by the Company through KYC form shall be
              subject to applicable laws for personal data and will be utilized
              for the purpose of processing the application. KYC information
              belonging to applicants that are not selected to be a Nester will
              be deleted regularly as per Company policy.
            </p>
            <h3 className="xs-mt-16">User Account and Obligations </h3>
            <p>
              To access and use certain features of the Website, you may need to
              create an account. If you create an account, you agree you will:
              (a) create only one account; (b) provide honest, accurate, current
              and complete information regarding yourself; (c) keep your
              information updated and accurate; (d) keep your account password
              private and not share it with others; and (d) notify us if you
              discover or suspect that your account has been hacked or its
              security breach.
            </p>
            <p>
              Further, you represent and warrant that: (i) you are the age of
              majority in your place of residence, and have the power and
              authority to accept these Terms of Use and to enter into this
              agreement (ii) you are capable of assuming, and do assume, any
              risks related to the use of the materials on the Website; and
              (iii) you understand and accept the terms, conditions and risks
              relating to the use of the content and materials on the Website.
            </p>
            <p>
              You agree to take responsibility for all activities on or under
              any account registered to you and you accept all risks for any
              unauthorized use of your account. You are responsible for
              providing the equipment and services that you need to access and
              use the Websites. We do not guarantee that the Website will be
              accessible on any particular equipment or device or with any
              particular software or service plan.
            </p>
            <p>
              We reserve the right, without notice and at our sole discretion at
              any time, to terminate your right to access and use the Website or
              any component of them and to block or prevent future access to and
              use of the Website and to delete your account and any related
              information. Any obligation or liability incurred prior to our
              termination of your access to the Website will survive such
              termination.
            </p>
            // <h3>$100 Refundable Deposit and Staking :</h3>
            // <p>
            //   In the event the Applicant is selected to be a Nester, he/she will
            //   be required to remit to the Company an amount of USD 100 (United
            //   States Dollars Hundred only) as performance security. The said
            //   performance security will be refunded as per the terms of the
            //   nesting agreement.{" "}
            // </p>

            <h3>Intellectual Property</h3>
            <p>
              Any and all intellectual property rights associated with the
              Website and its contents are the sole property of the Company and
              its affiliates. The material provided on this Website is protected
              by copyright and other intellectual property laws.
            </p>
            <p>
              All custom graphics, icons and other items that appear on the
              website are trademarks or service marks of the Company and may not
              be used or interfered with in any manner without the express
              written consent of the Company. Except as stated herein, none of
              the material set forth on this Website may be copied, reproduced,
              distributed, republished, downloaded, displayed, posted or
              transmitted in any form or by any means, including, but not
              limited to, electronic, mechanical, photocopying, recording or
              otherwise, without the prior written permission of the Company.
              Except as expressly provided herein, the Company does not grant to
              you any express or implied rights to the Company’s or any third
              party’s intellectual property.
            </p>
            <h3>Permissible Use</h3>

            <p>
              You agree that you shall not: (i) delete, modify, hack or attempt
              to change or alter any of the materials on the Website; (ii) use
              the Website for any unlawful purpose or to violate central or
              state regulations, rules and laws; (iii) place false or misleading
              information on the website; (iv) infringe or violate our
              intellectual property rights or the intellectual property rights
              of others; (v) input or upload to the Website any information
              which contains any form of computer virus that is intended to
              damage, interfere with, intercept or expropriate any software,
              computer system, this Website, or any of the information or
              content contained herein; (vi) spam, phish (email fraud), pharm
              (web traffic redirection fraud), pretext, spider, crawl, or
              scrape; (vii) collect, harvest, mine or engage in any other
              activity to obtain email addresses, phone numbers, personal
              information or any other information about others.
            </p>
            <p>
              In addition, except to the extent required by law or as expressly
              provided herein, none of the information provided in this Website
              may be reverse-engineered, modified, reproduced, republished,
              translated into any language or computer language, retransmitted
              in any form or by any means, resold, or redistributed without the
              prior written consent of the Company. You may not make, sell,
              offer for sale, modify, reproduce, display, publicly perform,
              import, distribute, retransmit or otherwise use any of the content
              or other information set forth in this website in any way, unless
              expressly permitted to do so by the Company.
            </p>
            <h3>Modifications</h3>

            <p>
              Any aspect of the Website may be changed, supplemented, deleted or
              updated without notice at the sole discretion of the Company. The
              Company has attempted to make sure all of the information on the
              Website is current and accurate, but no guarantee is made in that
              regard.
            </p>
            <p>
              We reserve the right to amend these Terms and such amendments
              shall become effective upon notice thereof, which may be provided
              to you by posting on the Website, via e-mail or any other means;
              provided, however, that any changes will not apply retroactively
              to any dispute arising prior to the time of the change. You agree
              to the new posted Terms by continuing your use of the Website. You
              agree to review these Terms periodically and your continued use of
              the Website following such modification shall be deemed to
              constitute acceptance of any modified Terms. If you do not agree
              with the modified Terms, your sole and exclusive remedy is to
              discontinue accessing and use of the Website.
            </p>
            <h3>Third Party Sites</h3>
            <p>
              This Website may contain links to third-party websites and
              resources. These links will let you leave our Website. The linked
              websites are not under the control of the Company, and the Company
              is not responsible for the contents of any linked website or any
              link contained in a linked website, or any changes or updates to
              such website. The Company makes no representations or warranties
              regarding the correctness, accuracy, performance or quality of any
              content, software, service or application found at any linked
              website. The Company is not responsible for the availability of
              the linked website or the content or activities of such website.
              If you decide to access a linked website, you do so at your own
              risk.
            </p>

            <h3>Disclaimers and Warranties </h3>
            <p>
              THIS WEBSITE, AND THE CONTENT PROVIDED HEREIN, ARE PROVIDED ON AN
              "AS IS" BASIS. YOU EXPRESSLY AGREE THAT YOUR USE OF THE WEBSITE IS
              AT YOUR SOLE RISK.
            </p>
            <p>
              THE COMPANY AND ITS AFFILIATES, TO THE FULLEST EXTENT PERMITTED BY
              LAW, DISCLAIM ALL WARRANTIES, EITHER EXPRESS OR IMPLIED, STATUTORY
              OR OTHERWISE, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED
              WARRANTIES OF NON-INFRINGEMENT OF THIRD PARTIES’ RIGHTS, AND
              FITNESS FOR A PARTICULAR PURPOSE.
            </p>
            <p>
              THE USE OF THIS WEBSITE IS DONE AT YOUR OWN DISCRETION AND RISK
              AND WITH YOUR AGREEMENT THAT YOU WILL BE SOLELY RESPONSIBLE FOR
              ANY DAMAGE TO YOUR COMPUTER SYSTEM, LOSS OF DATA OR OTHER HARM
              THAT RESULTS FROM SUCH ACTIVITIES. THE COMPANY ASSUMES NO
              LIABILITY FOR ANY COMPUTER VIRUS OR OTHER SIMILAR SOFTWARE CODE
              THAT IS DOWNLOADED TO YOUR COMPUTER FROM THE WEBSITE OR IN
              CONNECTION WITH ANY SERVICES OR MATERIALS OR PRODUCTS. NO
              INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM THE
              COMPANY OR VIA THIS WEBSITE, SHALL CREATE ANY WARRANTY NOT
              EXPRESSLY STATED IN THE TERMS OF USE. THE COMPANY WILL NOT BE
              LIABLE FOR ANY LOSS THAT YOU MAY INCUR AS A RESULT OF SOMEONE
              ELSE’S USE OF YOUR PASSWORD OR ACCOUNT WITH RESPECT TO THIS
              WEBSITE OR ANY SERVICES OR MATERIALS OR PRODUCTS, EITHER WITH OR
              WITHOUT YOUR KNOWLEDGE.
            </p>
            <p>
              THE COMPANY MAKES NO WARRANTY THAT: (i) THE WEBSITE WILL MEET YOUR
              REQUIREMENTS; (ii) ACCESS TO THE WEBSITE WILL BE UNINTERRUPTED,
              TIMELY, SECURE OR ERROR-FREE; (iii) THE RESULTS THAT MAY BE
              OBTAINED FROM THE USE OF THE WEBSITE WILL BE ACCURATE OR RELIABLE;
              AND (iv) ANY ERRORS IN THE SOFTWARE WILL BE CORRECTED (v)
              DESCRIPTIONS OF SERVICES OR PRODUCT OR OTHER CONTENT ON THE
              WEBSITE ARE ACCURATE, COMPLETE, RELIABLE, CURRENT, OR ERROR-FREE.
            </p>
            <h3>Limitation of Liability </h3>
            <p>
              UNDER NO CIRCUMSTANCES, INCLUDING, BUT NOT LIMITED TO, NEGLIGENCE,
              WILL WE OR OUR SUBSIDIARIES, OR AFFILIATES BE LIABLE FOR ANY
              DIRECT, INDIRECT, INCIDENTAL, SPECIAL OR CONSEQUENTIAL DAMAGES
              THAT RESULT FROM THE USE OF, OR THE INABILITY TO USE, THIS
              WEBSITE, INCLUDING ITS MATERIALS, OR SERVICES, OR THIRD-PARTY
              MATERIALS, PRODUCTS, OR SERVICES MADE AVAILABLE THROUGH THIS
              WEBSITE, EVEN IF WE ARE ADVISED BEFORE HAND OF THE POSSIBILITY OF
              SUCH DAMAGES. YOU SPECIFICALLY ACKNOWLEDGE AND AGREE THAT WE ARE
              NOT LIABLE FOR ANY DEFAMATORY, OFFENSIVE OR ILLEGAL CONDUCT OF ANY
              USER. IF YOU ARE DISSATISFIED WITH THE WEBSITE, OR ANY MATERIALS
              OR SERVICES OR PRODUCT ON THE WEBSITE, OR WITH ANY OF THE
              WEBSITE'S TERMS AND CONDITIONS, YOUR SOLE AND EXCLUSIVE REMEDY IS
              TO DISCONTINUE USING THE SITE.
            </p>
            <h3>Indemnification</h3>
            <p>
              You agree to indemnify and hold harmless us, our affiliates, and
              each of our and their respective directors, officers, managers,
              employees, shareholders, agents, representatives and licensors,
              from and against any and all losses, expenses, damages and costs,
              including reasonable attorneys' fees, that arise out of or in
              connection with: (i) your breach of any of these terms set forth
              herein; (ii) any of your activities conducted in connection with
              this Website; and/or (iii) your use of the Website or the use by
              any other person accessing the Website using your user name and
              password;{" "}
            </p>
            <h3>General</h3>
            <p>
              If any provision of these Terms is found to be invalid by any
              court having competent jurisdiction, the invalidity of such
              provision shall not affect the validity of the remaining
              provisions of these Terms, which shall remain in full force and
              effect.
            </p>
            <p>
              No waiver of any of these Terms shall be deemed a further or
              continuing waiver of such term or condition or any other term or
              condition. You hereby waive any right to receive these terms in a
              written format. The failure of the Company to exercise or enforce
              any right or provision of these Terms shall not constitute a
              waiver of such right or provision.
            </p>
            <p>
              These Terms constitute the entire agreement of the parties with
              respect to the subject matter hereof, and supersede all prior or
              contemporaneous communications and proposals, whether oral or
              written, between the parties with respect to such subject matter.
            </p>
          </div>
        </div>

        <div className="static-content two nft-terms">
          <h2>
            <>NFT and IoT Marketplace Terms</>
          </h2>

          <p>
            These terms and conditions constitute a legally binding agreement
            (the “Agreement”) between you (also referred to herein as “You”,
            “Your” or “User”) and Nitro Network(“Company” “we” or “us”),
            governing your use of www.nitro.network (the “Website”) and any
            transactions thereon. YOU AGREE TO BE BOUND BY THESE TERMS AND ALL
            OF THE TERMS INCORPORATED HEREIN. If you do not agree to the terms
            of this Agreement, you may not access the website.
          </p>

          <p>
            By entering into this Agreement, and/or by accessing the Website,
            you expressly acknowledge that you understand this Agreement and
            accept all of its terms. IF YOU DO NOT AGREE TO BE BOUND BY THE
            TERMS AND CONDITIONS OF THIS AGREEMENT, YOU MAY LEAVE THE WEBSITE.
          </p>
          <h3>Distribution Partner Marketplace Terms and Conditions </h3>
          <p>
            The Terms and Conditions of our partners will apply for all orders
            on the IoT Marketplace. Refunds for any orders are non applicable.
            All purchases on the IoT Marketplace, as well as associated charges,
            are non-refundable. This no-refund policy shall apply at all times
            regardless of Your decision to terminate usage of the website and/or
            the IoT Products or any other reason whatsoever. If any additional
            customs or any duty costs (across country, if applicable) occurs in
            the process of shipping and delivery, it has to be borne by the
            customer.
          </p>

          <h3>USA Distribution Partner - Choovio</h3>
          <ul>
            <li>
              Terms and Conditions:{" "}
              <a
                href="https://www.choovio.com/terms-and-conditions/"
                target="_blank"
                rel="noreferrer">
                https://www.choovio.com/terms-and-conditions
              </a>
            </li>
            <li>
              Shipping Policy -{" "}
              <a
                href="https://www.choovio.com/shipping-policy/"
                target="_blank"
                rel="noreferrer">
                https://www.choovio.com/shipping-policy/
              </a>
            </li>
            <li>
              All Prices are all inclusive, If any additional taxation or
              shipping cost occurs in the process of shipping and delivery, it
              has to be borne by the customer.
            </li>
          </ul>

          <h3>Europe Distribution Partner - AllIoT</h3>
          <ul>
            <li>
              Terms and Conditions:{" "}
              <a
                href="https://www.alliot.co.uk/wp-content/uploads/tech-support-policy.pdf"
                target="_blank"
                rel="noreferrer">
                https://www.alliot.co.uk/wp-content/uploads/tech-support-policy.pdf
              </a>
            </li>
            <li>
              Warranty and Returns:{" "}
              <a
                href="https://www.alliot.co.uk/returns/ "
                target="_blank"
                rel="noreferrer">
                https://www.alliot.co.uk/returns/
              </a>
            </li>
            <li>
              Shipping Policy{" "}
              <a
                href="https://www.choovio.com/shipping-policy/"
                target="_blank"
                rel="noreferrer">
                https://www.choovio.com/shipping-policy/
              </a>
            </li>
            <li>
              All Prices are all inclusive, If any additional customs, duty or
              shipping costs (across country, if applicable) occurs in the
              process of shipping and delivery, it has to be borne by the
              customer.
            </li>
          </ul>
          <h3>India Distribution Partner - Enthu Technologies</h3>
          <ul>
            <li>
              Terms and Conditions:{" "}
              <a
                href=" https://www.enthutech.in/terms-and-conditions"
                target="_blank">
                https://www.enthutech.in/terms-and-conditions
              </a>
            </li>
            <li>
              Shipping Policy{" "}
              <a
                href="https://www.enthutech.in/shipping-refund-policy"
                target="_blank"
                rel="noreferrer">
                https://www.enthutech.in/shipping-refund-policy
              </a>
            </li>
            <li>
              Warranty Policy -{" "}
              <a
                href="https://www.enthutech.in/warranty-policy  "
                target="_blank"
                rel="noreferrer">
                https://www.enthutech.in/warranty-policy
              </a>
            </li>

            <li>
              All Prices are all inclusive, If any additional taxation or
              shipping cost occurs in the process of shipping and delivery, it
              has to be borne by the customer.
            </li>
          </ul>

          <h3>1. Definitions.</h3>
          <p>
            “NFT” means any blockchain-tracked, non-fungible miner including
            art, graphics, images, designs, logos, taglines, and drawings and
            IPR that may be associated therewith.
          </p>
          <p>
            “IPR” means including, without limitation, patent applications and
            disclosures, copyrights, trade secrets, trademarks, know-how or any
            other intellectual property rights recognized in any country or
            jurisdiction in the world.
          </p>

          <h3 className="xs-mt-16">2. Ownership and Transfer. </h3>
          <p>
            You acknowledge and agree that the Company owns all legal rights,
            title and interest in and to the NFT. Once you purchase the NFT, the
            ownership rights get transferred to you. <br />
            All purchases of NFTs and/or products of IoT, as well as associated
            charges, are non-refundable. This no-refund policy shall apply at
            all times regardless of Your decision to terminate usage of the
            website and/or NFT and/or products of IoT or any other reason
            whatsoever. You have the right to transfer the NFT.
          </p>

          <h3>3. Disclaimer Of Warranties & Limitation Of Liability. Y</h3>
          <p>
            All NFT’s are provided “as is” and “as available” without warranties
            of any kind either express or implied. The Company disclaims all
            warranties, express or implied, including, but not limited to,
            implied warranties of non-infringement, merchantability and fitness
            for a particular purpose. In no event shall the Company be liable to
            you for any personal injury, property damage, lost profits, cost of
            substitute goods or services, loss of data, loss of goodwill, work
            stoppage, computer and/or device or technology failure or
            malfunction, or for any form of direct or indirect damages, and/or
            any special, incidental, consequential, exemplary or punitive
            damages based on any causes of action whatsoever related to any NFT
            and/or products of IoT, including but not limited to the NFT and/or
            products of IoT purchased on this Website, any technology and/or
            parties related to the sale/purchase of NFT and/or products of IoT,
            including but not limited to blockchain, Company networks. You agree
            that this limitation of liability applies whether such allegations
            are for breach of contract, tortious behaviour, negligence, or fall
            under any other cause of action, regardless of the basis upon which
            liability is claimed and even if a disclaiming party has been
            advised of the possibility of such loss or damage, and in any event,
            to the maximum extent permitted by applicable law, the Company’s
            total aggregate liability shall not exceed ten percent (10%) of the
            total sum paid directly by you to Company for the applicable NFT
            and/or products of IoT. You accept the inherent security risks of
            providing information and dealing online over the internet and agree
            that we have no liability or responsibility for any breach of
            security. Warranties on IoT Sensors and Products will be applicable
            according to the warranties and services provided by our
            Distribution partners.
          </p>

          <h3>4. Assumption Of Risk.</h3>
          <p>You agree that You assume the following risks</p>

          <p>
            (i) The price or market for a blockchain asset such as an NFT, is
            extremely volatile, and is subject to variations and there is no
            guarantee that Your NFTs will have or retain any value or have any
            market and you may lose money.
          </p>
          <p>
            (ii) The value of the NFT that You purchase may diminish in value as
            a result of a variety of reasons such as lack of use or public
            interest or negative publicity, risk of hardware, software and
            internet connections and/or failures, the risk of malicious software
            introduction, and the risk that third parties may obtain
            unauthorised access to information stored within your digital
            “wallet” or elsewhere, and the Company will not be responsible for
            any of these, however caused;
          </p>
          <p>
            (iii) the Company does not make any promises or guarantees about the
            availability of the NFT or on the Internet or that they will host
            the NFT at any specific location and/or for any specific period of
            time;
          </p>
          <p>
            (iv) the risk of losing access to NFT due to loss of private key(s),
            custodial error or purchaser error; mining attacks; hacking,
            security weaknesses, fraud, counterfeiting, cyber attacks and other
            technological difficulties;
          </p>
          <p>
            (v) the risk of changes to the regulatory regime governing
            blockchain technologies, cryptocurrencies, and tokens and new
            regulations, taxation, unfavourable regulatory intervention in one
            or more jurisdictions or policies any of which may materially
            adversely affect the use and value of the NFT;
          </p>
          <p>
            (vi) that NFTs are not legal tender and are not back by any
            government;
          </p>
          <p>
            (vii) the Company, its employees and Advisors are not responsible
            for any transaction between you and a third party (e.g., Your
            transfer of a NFT from a third party on the so-called “secondary
            market”), and the Company shall have no liability in connection with
            any such transaction.
          </p>
          <p>
            In addition to assuming all of the above risks, you acknowledge that
            You have obtained sufficient information to make an informed
            decision to access this website and /or buy the NFT and that You
            understand and agree that you are solely responsible for determining
            the nature, potential value, suitability and appropriateness of
            these risks for yourself.
          </p>
          <p>
            The Company cannot and does not represent or warrant that the NFT or
            the delivery mechanism for it are free of viruses or errors or other
            harmful components. You accept and acknowledge that the Company will
            not be responsible for any communication failures, disruptions,
            errors, distortions or delays You may experience related to the
            purchase/sale of NFT and/or usage of the website and IoT
            Marketplace.
          </p>
          <p>
            Company will not be liable for any loss of any kind from any action
            taken or taken in reliance on material or information contained on
            the website. Company does not represent or warrant that any content
            on the website is accurate, complete, reliable, current or
            error-free.
          </p>

          <h3>5. Governing Law. </h3>
          <p>
            This Agreement and all matters related to it and/or any Licensed NFT
            and/or IoT Marketplace shall be governed by, construed, and enforced
            in accordance with the laws of UAE
          </p>

          <h3>6. Changes to this Agreement.</h3>
          <p>
            Companies may make changes to this Agreement from time to time and
            we will make the updated Agreement available on this website and
            update the “Last Updated” date at the beginning of the Agreement
            accordingly. Please check this page periodically for changes. Any
            changes to this Agreement will apply on the date that they are made
            and, by way of example, Your continued access to or use of the
            website, IoT Marketplace and buying and selling of NFT there on
            after the Agreement has been updated will constitute your binding
            acceptance of the updates.
          </p>

          <h3>7. Eligibility.</h3>
          <p>
            Access and use of the Website is open only to individuals who are at
            least 18 years old, have the right and authority to enter into this
            Agreement, are fully able and competent to satisfy the terms,
            conditions, and obligations herein
          </p>
          <h3>8. Indemnity </h3>
          <p>
            You will defend, indemnify, and hold Company, including each of
            their respective affiliates, subsidiaries, parents, successors and
            assigns, and each of our respective officers, directors, employees,
            agents, or shareholders, harmless from any claims, actions, suits,
            losses, costs, liabilities and expenses (including reasonable
            attorneys’ fees) relating to or arising out including but not
            limited to: (a) Your breach of this Agreement or the documents it
            incorporates by reference; (b) Your violation of any law or the
            rights of a third party as a result of your own interaction with
            such third party; (c) any allegation that any materials that You
            submit to us or transmit, infringe or otherwise violate the
            copyright, trademark, trade secret or other intellectual property or
            other rights of any third party; (d) your use or misuse of the
            website, content, NFTs, and/or IoT Marketplace, or content linked to
            or associated with any NFTs and/or the IoT Marketplace; and / or (e)
            wilful misconduct. This indemnity shall be applicable without regard
            to the negligence of any party, including any indemnified person.
          </p>
          <p>
            You agree to promptly notify the Company of any claims and cooperate
            with the Company in defending such claims. You further agree that
            the Company shall have control of the defence or settlement of any
            claims.
          </p>
          <h3>9. Taxes.</h3>
          <p>
            You are responsible for all applicable tax including any GST, VAT,
            sales tax or equivalent tax wherever such taxes are applicable.
          </p>
          <h3>10. Severability.</h3>
          <p>
            If any term or provision of this Agreement is invalid, illegal, or
            unenforceable in any jurisdiction, such invalidity, illegality, or
            unenforceability shall not affect any other term or provision of
            this Agreement or invalidate or render unenforceable such term or
            provision in any other jurisdiction.
          </p>
          <h3>11. Entire Agreement .</h3>
          <p>
            These terms constitute the entire agreement between the parties
            regarding its subject matter and supersedes and invalidates all
            other prior representations, arrangements, understandings, and
            agreements relating to the same subject matter, (whether oral or in
            writing, express or implied). Each party acknowledges that in
            agreeing to these terms it does not rely on any statement,
            representation, warranty, or understanding other than those
            expressly set out in these terms.
          </p>
          <h3>12. Contact Us.</h3>
          <p>
            If you have any questions, concerns or need support , including if
            you need to access this Agreement in an alternative format, we
            encourage you to contact us via e-mail at{" "}
            <a href="mailto:info@nitro.network" className="link-color">
              info@nitro.network{" "}
            </a>
          </p>
          <h3>13.Privacy Policy</h3>
          <p>
            Please refer to our Privacy Policy for information about how we
            collect, use, and share personal data about you. By submitting
            personal data through our service, you agree to the terms of our
            Privacy Policy and you expressly consent to the collection, use, and
            disclosure of your personal data in accordance with the Privacy
            Policy.
          </p>
          <h3>14. Termination</h3>
          <p>
            If you breach any of the provisions of this Agreement, we reserve
            the right, with or without notice and in our sole discretion, to
            suspend, disable, terminate, or delete your Account and/or your
            ability to access or use this website (or any part of the foregoing)
            at any time and for any or no reason, and you acknowledge and agree
            that we shall have no liability or obligation to you in such event
            and that
            <b>
              {" "}
              you will not be entitled to a refund of any amounts that you have
              already paid to us.
            </b>
          </p>
          <br></br>
        </div>
      </div>
    </div>
  )
}

export default Terms
