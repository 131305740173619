import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "@reach/router"
import {
  setActiveMenu,
  setConnectedWallet,
  updateUserDetailsRedux,
  setTokenData,
  setStakeTokenData,
  setStyleType
} from "../redux/actions"
import { mapStyleOptions } from "../Shared"

export const useLogout = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  let iotTokens = useSelector(state => state.common.iotTokens)
  iotTokens = iotTokens.length ? iotTokens.join(",") : ""

  const logoutHandler = () => {
    dispatch(updateUserDetailsRedux({}))
    dispatch(setConnectedWallet(""))
    dispatch(setTokenData([]))
    dispatch(setStakeTokenData([]))
    dispatch(setStyleType(mapStyleOptions[0].value))
    dispatch(setActiveMenu(""))
    navigate(`/login${iotTokens.length ? `?iotTokens=${iotTokens}` : ""}`)
  }

  return logoutHandler
}
