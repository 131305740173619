import React, { useState, useEffect } from "react"
import ReactDOM from "react-dom"
import "../../assets/css/faq.css"
import Footer from "../components/footer"
import { createGlobalStyle } from "styled-components"
import Menu from "./menu"
import { Form, Input, Modal, Select, Button } from "antd"
import { BaseUrl } from "../../utils/config"
import { useNavigate } from "@reach/router"
import {
  BrowserRouter as Router,
  Link,
  useHistory,
  useLocation
} from "react-router-dom"
import axios, { Axios } from "axios"
import { useDispatch } from "react-redux"
import { updateUserDetailsRedux, setConnectedWallet } from "../../redux/actions"
import { isNestEnabled } from "../../utils/config"

const GlobalStyles = createGlobalStyle`
  header#myHeader.navbar.white {
    background: #212428;
  }
`

const HowItWorks = () => {
  const user = localStorage.getItem("loginUser")
  const [allproducts, setAllproducts] = useState([])
  const [viewProduct, setViewProduct] = useState(false)
  const [activeTab, SetActiveTab] = useState(-1)
  const loginUserMail = localStorage.getItem("loginUserMail")
  const history = useHistory()
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const panels = [
    {
      label: "1. What is a mystery box?",
      content: (
        <>
          A mystery box contains your NFM which represents the location that
          will determine where your Nitro miner will be hosted. NFMs are divided
          into Tier 1, Tier 2 &amp; Tier 3 categories based on location.
        </>
      )
    },
    {
      label:
        "2. Why are these mystery boxes  priced differently? What is the logic behind this pricing?",
      content: (
        <>
          Nitro's Digital Mining is location specific. Your location determines
          the amount of rewards you will get. For example, if your miner is at a
          prime location (Tier 1) you will earn more rewards. However, Mystery
          boxes containing TIer 2 and Tier 3 NFMs also earn significant rewards.
          Our intention at Nitro is to make everybody win!
        </>
      )
    },
    {
      label: "3. How many mystery boxes one can buy?",
      content: <>You can buy as many mystery boxes as you wish.</>
    },
    {
      label: "4. How can I purchase a Mystery Box?",
      content: (
        <>
          You can buy a Mystery Box from the Nitro Network{" "}
          <a href="/marketplace" className="faq-link">
            {" "}
            marketplace
          </a>{" "}
          which is live from 5th April, 2022.
        </>
      )
    },
    {
      label: "5. What are the payment steps for the Mystery Box?",
      content: (
        <>
          There are 2 simple steps to make your payment for the Mystery Box:
          <ol type="1" style={{ listStyle: "initial" }}>
            <li>Connect to the Metamask wallet on the Avalanche network.</li>
            <li>Pay with Fiat/Crypto.</li>
          </ol>
        </>
      )
    },
    {
      label: "6. Where can I view my payment receipt?",
      content: (
        <>
          Once you have completed your payment, you will receive an email with
          your payment receipt.
        </>
      )
    },
    {
      label: "7. What is the price of different Tier NFMs?",
      content: (
        <>
          NFMs are priced based on the location. Prime location NFMs are priced
          higher.
          <ul>
            <li>ICONIC Tier 1 - &nbsp;<strike>$750</strike>&nbsp;$375</li>
            <li>PREMIUM Tier 2 - &nbsp;<strike>$650</strike>&nbsp;$325</li>
            <li>TOP Tier 3 - &nbsp;<strike>$500</strike>&nbsp;$250</li>
          </ul>
        </>
      )
    },
    {
      label: "8. Can I host my own Miner?",
      content: (
        <>
          You can digitally mine with the NFM on the Nitro Network. Your NFM is
          your digital mining device with which you can start earning rewards!
          To host a miner, you have to register as a Nester, get your location
          &amp; KYC validated by Nitro Network, when the KYC process starts (We
          will inform the dates) and be certified as a nester. Check out the
          nesting article{" "}
          <a
            href="https://nitronetwork.medium.com/the-nesting-platform-is-live-a-step-by-step-guide-to-host-your-nest-on-the-nitro-platform-c1cfb9ad9720"
            target="_blank"
            className="faq-link" rel="noreferrer">
            here{" "}
          </a>
          .
        </>
      )
    },
    {
      label: "9. Can I be a host and a miner at the same time?",
      content: (
        <>
          Yes you can. To host the network you have to first become a Nester by
          registering your location on{" "}
          {isNestEnabled && (
            <a href="/nest" className="faq-link">
              here{" "}
            </a>
          )}
          . Once your address and KYC get validated by us, you will receive a
          Nitro ION miner with which you can host the miner from your location
          (home/office) and start earning rewards. Since Nitro follows a 1:1
          grid (only 1 nester in 1 km) you will not face any network congestion
          and there will be equal reward distribution. You can be a miner too on
          NItro Network. Go to the Nitro marketplace, buy your NFM (which is
          your digital miner), find your host (Nester) which is enabled by the
          Nitro platform and mine away rewards. Nitro is all about rewards.
          Nester, miner or both, you always win!
        </>
      )
    },
    {
      label:
        "10. Can you explain how an NFM connects to an IoT network and enables rewards?",
      content: (
        <>
          When you hold an NFM (your digital miner), Nitro will create the
          Nester (host) match for you. These nester or hosts will help you build
          the PCN for IoT devices. As a miner, since you are contributing to
          build the PCN, you earn infrastructre + mining rewards.
        </>
      )
    },
    {
      label: "11. What does the Nitro marketplace offer?",
      content: (
        <>
          The Nitro marketplace is your go-to place to buy your NFM. You can
          complete your NFM payments here, check your NFM collections (in My
          Collection). You can think of it as the hub for NFMs
        </>
      )
    },
    {
      label: "12. Is the NFM the same as the miner?",
      content: (
        <>
          Yes your NFM is your miner, only better! We have removed the physical
          aspect of the miner so that you can earn effortlessly from anywhere in
          the world! Your NFM is a NFT with utility (mining) and is a rare
          collectible too. Themed on Nitro bots, each Tier NFM locks in rarity
          value so you will want to hold on to them.{" "}
        </>
      )
    },
    {
      label: "13. How many ncash tokens per day each nft gets as reward?",
      content: (
        <>
          By being on Tier 1, or the ICONIC Tier, you earn 95.69* NCash per
          EPOCH. Tier 2, the PREMIUM tier, you earn 52.05* NCash per EPOCH. The
          last Tier 3, you earn 24.18* NCash per EPOCH. As long as you are
          signed up, you can just sit back, relax and earn rewards! It's that
          effortless. <br />* Reward per EPOCH is subject to change as Nitro
          cannot guarantee a specific earning. These numbers have been taken on
          an average that is reflected on our Tier system with a network size of
          20K miners.
        </>
      )
    },
    {
      label: "14. How long will NFM holders continue to get rewards?",
      content: (
        <>
          Your NFM has both utility (you can mine with it and earn rewards) + a
          rare collectible. So the longer you hold on to it the more it will
          earn for you. You have the option of selling it in the secondary
          market (we will update you more about this very soon) in which case
          the person who buys the NFM from you will start earning rewards.
        </>
      )
    },
    {
      label: "15. What are the Pros & Cons of physical vs digital miners?",
      content: (
        <>
          When it comes to advantages, Digital Miners score way better than
          physical miners. <br />
          <br />
          <strong>PHYSICAL MINER</strong>
          <br />
          <b>Pros:- </b>
          <ol>
            <li>You can see/touch the device.</li>
          </ol>
          <b>Cons:- </b>
          <ol>
            <li>
              To claim rewards, you have to first ensure all IoT devices in your
              area are connected to your miner. There is always a high
              possibility that there won't be many connected devices in our
              area.
            </li>
            <li>
              The presence of many miners in your area/location means rewards
              will not be distributed equally so your rewards may vary.
            </li>
            <li>
              You have to install the physical miner and there are maintenance
              costs.
            </li>
            <li>No resale value.</li>
            <li>
              You can own only a certain amount of miners. More number of miners
              will compete with one another to earn rewards and you may end up
              with fewer rewards and more devices!
            </li>
          </ol>
          <br />
          <strong>DIGITAL MINER</strong>
          <br />
          <b>Pros:- </b>
          <ol>
            <li>No hassles of installation.</li>
            <li>
              You can own as many miners as you wish as Nitro will be deploying
              the physical miners at strategic locations. And since Nitro
              follows the 1:1 grid (1 miner in 1 km radius) there will be no
              network conjestion and you get equitible distribution of rewards.
            </li>
            <li>Better rewards due to less competion.</li>
            <li>
              You can mine effortlessly form anywhere. You do not have to worry
              about IoT device connectivity in your area in order to earn
              rewards.
            </li>
            <li>You won an NFT with high resale value.</li>
          </ol>
        </>
      )
    }
  ]

  useEffect(() => {
    axios
      .get(`${BaseUrl}/product/get`)
      .then(response => {
        setAllproducts(response.data.data)
        if (response) {
          setAllproducts(response.data.data)
        }
      })
      .catch(error => {
        if (error?.response?.status === 401) {
          dispatch(updateUserDetailsRedux({}))
          localStorage.clear()
          dispatch(setConnectedWallet(""))
          navigate("/login")
        }
      })
  }, [])

  const onviewproduct = id => {
    setViewProduct(true)
    // SetSelectedId(id);
    navigate("/productdetails", { state: { selectedId: id } })
  }
  const toLogin = () => {
    navigate("/login")
  }
  const toChangePage = item => {
    navigate(`/${item}`)
  }
  const activateTab = index => {
    activeTab === index ? SetActiveTab(-1) : SetActiveTab(index)
  }
  return (
    <div className="">
      {/* <GlobalStyles/> */}

      <section className="backgroundColordark ">
        <div className=" row  pt-md-4 d-flex justify-content-center">
          <div className="accordion  w-100 remove-max-width" role="tablist">
            {panels.map((panel, index) => (
              <Panel
                key={index}
                activeTab={activeTab}
                index={index}
                {...panel}
                activateTab={activateTab.bind(null, index)}
              />
            ))}
          </div>
          {/* //------------------------------------------------------------------------------------------------------------ */}

          {/* <div className="accordion l-container">
                        <ul className="accordion-list">
                            <li className="accordion-item">
                                <div className="accordion-button"><span>1. What is Nesting?</span></div>
                                <div className="accordion-panel">
                                    <ul>
                                        <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Atque repellendus cupiditate corrupti, ratione odit velit obcaecati deleniti aspernatur! Explicabo eveniet nostrum numquam molestias delectus! Rem commodi dolor impedit assumenda dignissimos.</li>
                                    </ul>
                                </div>
                            </li>
                            <li className="accordion-item">
                                <div className="accordion-button"><span>2. Why Nesting?</span></div>
                                <div className="accordion-panel">
                                    <ul>
                                        <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Atque repellendus cupiditate corrupti, ratione odit velit obcaecati deleniti aspernatur! Explicabo eveniet nostrum numquam molestias delectus! Rem commodi dolor impedit assumenda dignissimos.</li>
                                    </ul>
                                </div>
                            </li>
                            <li className="accordion-item">
                                <div className="accordion-button"><span>3. Who is a Nester?</span></div>
                                <div className="accordion-panel">
                                    <ul>
                                        <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Atque repellendus cupiditate corrupti, ratione odit velit obcaecati deleniti aspernatur! Explicabo eveniet nostrum numquam molestias delectus! Rem commodi dolor impedit assumenda dignissimos.</li>
                                    </ul>
                                </div>
                            </li>
                            <li className="accordion-item">
                                <div className="accordion-button"><span>4. Why should you become a Nester?</span></div>
                                <div className="accordion-panel">
                                    <ul>
                                        <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Atque repellendus cupiditate corrupti, ratione odit velit obcaecati deleniti aspernatur! Explicabo eveniet nostrum numquam molestias delectus! Rem commodi dolor impedit assumenda dignissimos.</li>
                                    </ul>
                                </div>
                            </li>  <li className="accordion-item">
                                <div className="accordion-button"><span>5. What are the steps for becoming a Nester?</span></div>
                                <div className="accordion-panel">
                                    <ul>
                                        <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Atque repellendus cupiditate corrupti, ratione odit velit obcaecati deleniti aspernatur! Explicabo eveniet nostrum numquam molestias delectus! Rem commodi dolor impedit assumenda dignissimos.</li>
                                    </ul>
                                </div>
                            </li>  <li className="accordion-item">
                                <div className="accordion-button"><span>6. How will a Nester earn?</span></div>
                                <div className="accordion-panel">
                                    <ul>
                                        <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Atque repellendus cupiditate corrupti, ratione odit velit obcaecati deleniti aspernatur! Explicabo eveniet nostrum numquam molestias delectus! Rem commodi dolor impedit assumenda dignissimos.</li>
                                    </ul>
                                </div>
                            </li>  <li className="accordion-item">
                                <div className="accordion-button"><span>7. Do Nesters in specific locations have more benefits?</span></div>
                                <div className="accordion-panel">
                                    <ul>
                                        <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Atque repellendus cupiditate corrupti, ratione odit velit obcaecati deleniti aspernatur! Explicabo eveniet nostrum numquam molestias delectus! Rem commodi dolor impedit assumenda dignissimos.</li>
                                    </ul>
                                </div>
                            </li>  <li className="accordion-item">
                                <div className="accordion-button"><span>8. Can there be multiple Nesters in the same place?</span></div>
                                <div className="accordion-panel">
                                    <ul>
                                        <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Atque repellendus cupiditate corrupti, ratione odit velit obcaecati deleniti aspernatur! Explicabo eveniet nostrum numquam molestias delectus! Rem commodi dolor impedit assumenda dignissimos.</li>
                                    </ul>
                                </div>
                            </li>  <li className="accordion-item">
                                <div className="accordion-button"><span>9. How will I know my Tier?</span></div>
                                <div className="accordion-panel">
                                    <ul>
                                        <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Atque repellendus cupiditate corrupti, ratione odit velit obcaecati deleniti aspernatur! Explicabo eveniet nostrum numquam molestias delectus! Rem commodi dolor impedit assumenda dignissimos.</li>
                                    </ul>
                                </div>
                            </li>
                            <li className="accordion-item">
                                <div className="accordion-button"><span>10. What if I no longer want to be a Nester?</span></div>
                                <div className="accordion-panel">
                                    <ul>
                                        <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Atque repellendus cupiditate corrupti, ratione odit velit obcaecati deleniti aspernatur! Explicabo eveniet nostrum numquam molestias delectus! Rem commodi dolor impedit assumenda dignissimos.</li>
                                    </ul>
                                </div>
                            </li>
                            <li className="accordion-item">
                                <div className="accordion-button"><span>11. I live in a rented premises. In case I change my address, can I still be a Nester?</span></div>
                                <div className="accordion-panel">
                                    <ul>
                                        <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Atque repellendus cupiditate corrupti, ratione odit velit obcaecati deleniti aspernatur! Explicabo eveniet nostrum numquam molestias delectus! Rem commodi dolor impedit assumenda dignissimos.</li>
                                    </ul>
                                </div>
                            </li>
                        </ul>
        </div>
               

           
                    */}

          <div className="spacer-double"></div>

          {/* <ul className="pagination justify-content-center ">
                <li className="active"><span>1 - 20</span></li>
                <li><span>21 - 40</span></li>
                <li><span>41 - 60</span></li>
            </ul>  */}
        </div>
      </section>
    </div>
  )
}
export default HowItWorks

class Panel extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      height: 0
    }
  }

  componentDidMount() {
    window.setTimeout(() => {
      const el = ReactDOM.findDOMNode(this)
      const height = el.querySelector(".panel__inner").scrollHeight
      this.setState({
        height
      })
    }, 333)
  }

  render() {
    const { label, content, activeTab, index, activateTab } = this.props
    const { height } = this.state
    const isActive = activeTab === index
    const innerStyle = {
      height: `${isActive ? height : 0}px`
    }

    return (
      <div className="panel" role="tabpanel" aria-expanded={isActive}>
        <button className="panel__label" role="tab" onClick={activateTab}>
          {label}
        </button>
        <div
          className="panel__inner"
          style={innerStyle}
          aria-hidden={!isActive}>
          <p className="panel__content">{content}</p>
        </div>
      </div>
    )
  }
}

class Accordion extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      activeTab: 0
    }

    this.activateTab = this.activateTab.bind(this)
  }

  activateTab(index) {
    this.setState(prev => ({
      activeTab: prev.activeTab === index ? -1 : index
    }))
  }

  render() {
    const { panels } = this.props
    const { activeTab } = this.state
    return (
      <div className="accordion" role="tablist">
        {panels.map((panel, index) => (
          <Panel
            key={index}
            activeTab={activeTab}
            index={index}
            {...panel}
            activateTab={this.activateTab.bind(null, index)}
          />
        ))}
      </div>
    )
  }
}
