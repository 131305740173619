import { createStore } from "redux"
import { persistReducer, persistStore } from "redux-persist"
import RootReducer from "../reducers"
import storage from "redux-persist/lib/storage"

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["common", "collection", "marketplace"]
}

const persistedReducer = persistReducer(persistConfig, RootReducer)

export const store = createStore(
  persistedReducer,
  process.env.NODE_ENV !== "production" &&
    window.__REDUX_DEVTOOLS_EXTENSION__ &&
    window.__REDUX_DEVTOOLS_EXTENSION__()
)

export const persistor = persistStore(store)
