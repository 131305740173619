export default Object.freeze({
  MODE: "prod",
  NFT_CONTRACT_ADDRESS: "0x8FE9d8495f09e9EAD5eA9e59a1742c346F271346", // accepting pending Payments - not in use
  CHAIN_ID: 43114,
  EXPLORER_URL: " https://snowtrace.io",
  NETWORK_NAME: "Avalanche Network",
  TIER_1_NFT_CONTRACT_ADDRESS: "0x8FE9d8495f09e9EAD5eA9e59a1742c346F271346", // Tier 1 Contract Address
  TIER_2_NFT_CONTRACT_ADDRESS: "0x757B7f7a0B57aeE1Cb3EE39a249c997754DD019c", // Tier 2 Contract Address
  TIER_3_NFT_CONTRACT_ADDRESS: "0x87a7ce85788577979bE832818444A87BD09E2A44", // Tier 3 Contract Address
  TIER_1_COST: 450,
  TIER_2_COST: 650,
  TIER_3_COST: 850,
  NODE_RPC_URL: " https://api.avax.network/ext/bc/C/rpc",
  NITRO_PAYMENT_CONTRACT: "0x6d8ddc8e38B104633B78f4E2f377471fb0786931",
  WBTC: "0x50b7545627a5162F82A992c33b87aDc75187B218",
  WETH: "0x49D5c2BdFfac6CE2BFdB6640F4F80f226bc10bAB",
  WAVAX: "0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7",
  DAI: "0xd586E7F844cEa2F87f50152665BCbc2C279D8d70",
  USDC: "0xA7D7079b0FEaD91F3e65f86E8915Cb59c1a4C664",
  USDT: "0xc7198437980c041c805A1EDcbA50c1Ce5db95118",
  RPC: "https://api.avax.network/ext/bc/C/rpc",
  NFMSTAKING: "0xBA144221cd00277911e08fB4A61b88Ae4aD63Fa7",
  NFMCLAIMREWARDS: "0x0e11EfadC748A5DF4Fb63106328F584745B3591e"
});

